{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCDirections.Bing;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterBingDirectionsService;
procedure UnRegisterBingDirectionsService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCDirections,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCDirectionsBingService = class;

  TTMSFNCDirectionsBingDirectionsFactoryService = class(TTMSFNCDirectionsFactoryService, ITMSFNCDirectionsServiceBing);

  TTMSFNCDirectionsBingService = class(TTMSFNCDirectionsBingDirectionsFactoryService)
  protected
    function DoCreateDirections: ITMSFNCCustomDirections; override;
  end;

type
  TTMSFNCDirectionsBingDirections = class;

  TTMSFNCDirectionsBingDirections = class(TTMSFNCCustomDirectionsInterfacedObject, ITMSFNCCustomDirections)
  protected
    FTravelMode: TTMSFNCDirectionsTravelMode;
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; ATravelMode: TTMSFNCDirectionsTravelMode; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
    function GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
    function GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
    procedure Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyDirections;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  DirectionsService: ITMSFNCDirectionsServiceBing;

procedure RegisterBingDirectionsService;
begin
  if not TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceBing, IInterface(DirectionsService)) then
  begin
    DirectionsService := TTMSFNCDirectionsBingService.Create;
    TTMSFNCDirectionsPlatformServices.Current.AddPlatformService(ITMSFNCDirectionsServiceBing, DirectionsService);
  end;
end;

procedure UnregisterBingDirectionsService;
begin
  TTMSFNCDirectionsPlatformServices.Current.RemovePlatformService(ITMSFNCDirectionsServiceBing);
end;

{ TTMSFNCDirectionsBingService }

function TTMSFNCDirectionsBingService.DoCreateDirections: ITMSFNCCustomDirections;
begin
  Result := TTMSFNCDirectionsBingDirections.Create;
end;

procedure TTMSFNCDirectionsBingDirections.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCDirectionsBingDirections.Create;
begin
  inherited;
end;

destructor TTMSFNCDirectionsBingDirections.Destroy;
begin
  inherited;
end;

procedure TTMSFNCDirectionsBingDirections.DestroyDirections;
begin
  DirectionsService.DestroyDirections(Self);
end;

function TTMSFNCDirectionsBingDirections.GetHost: string;
begin
  Result := 'https://dev.virtualearth.net';
end;

function TTMSFNCDirectionsBingDirections.GetIdentifier: string;
begin
  Result := 'BingMaps';
end;

function TTMSFNCDirectionsBingDirections.GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
  ADestinationLatitude, ADestinationLongitude: Double; ATravelMode: TTMSFNCDirectionsTravelMode; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
var
  TravelMode: string;
begin

  if ATravelMode in [tmBicycling, tmTruck] then
    FTravelMode := tmDriving
  else
    FTravelMode := ATravelMode;

  case FTravelMode of
    tmDriving: TravelMode := 'driving';
    tmWalking: TravelMode := 'walking';
    tmPublicTransport: TravelMode := 'transit';
  end;

  Result := '/REST/v1/Routes/' + TravelMode + '/';
end;

function TTMSFNCDirectionsBingDirections.GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
begin
  Result := '';
end;

function TTMSFNCDirectionsBingDirections.GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
  ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
  AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
var
  WayPoints, Alternatives: string;
  I: Integer;
begin
  if AAlternatives then
    Alternatives := '3'
  else
    Alternatives := '1';

  if ATravelMode in [tmBicycling, tmTruck] then
    FTravelMode := tmDriving
  else
    FTravelMode := ATravelMode;

  if AOrigin = '' then
    AOrigin := FloatToStrDot(AOriginLatitude) + ',' + FloatToStrDot(AOriginLongitude);
  if ADestination = '' then
    ADestination := FloatToStrDot(ADestinationLatitude) + ',' + FloatToStrDot(ADestinationLongitude);

  if Assigned(AWaypoints) and (Length(AWayPoints) > 0) then
  begin
    for I := 0 to (Length(AWayPoints) - 1) do
      WayPoints := WayPoints + '&wayPoint.' + IntToStr(I+2) + '=' + FloatToStrDot(AWayPoints[I].Latitude) + ',' + FloatToStrDot(AWayPoints[I].Longitude);
    WayPoints := '&wayPoint.1=' + AOrigin + WayPoints + '&wayPoint.' + IntToStr(Length(AWayPoints)+2) + '=' + ADestination;
  end
  else
    WayPoints := '&wayPoint.1=' + AOrigin + '&wayPoint.2=' + ADestination;

  Result := 'key=' + DirectionsProperties.GetAPIKey
  + WayPoints
  + '&optimizeWaypoints=' + LowerCase(BoolToStr(AOptimizeWayPoints, True))
  + '&routeAttributes=routePath'
  + '&maxSolutions=' + Alternatives
  + '&c=' + ALocale;

  if FTravelMode = tmPublicTransport then
    Result := Result + '&timeType=Departure&dateTime=10:00:00AM';

  if AAVoidTolls then
    Result := Result + '&avoid=tolls';
end;

function TTMSFNCDirectionsBingDirections.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCDirectionsBingDirections.IsValid: Boolean;
begin
  Result := DirectionsProperties.GetAPIKey <> '';
end;

procedure TTMSFNCDirectionsBingDirections.Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
var
  d: TTMSFNCDirectionsItems;
  di: TTMSFNCDirectionsItem;
  co: TTMSFNCMapsCoordinateItem;
  lg: TTMSFNCDirectionsLeg;
  cor: TStringList;
  step, lstep: TTMSFNCDirectionsStep;

  o, jo, jol, jos, joc: TJSONValue;
  jar, jal, jas, jac, jak: TJSONArray;
  i, j, k, l: Integer;
  ob, os: TJSONObject;
  obs: TJSONValue;
  idxs, idxe: Integer;
  corlist, distance: string;
  JJ: Integer;
begin
  if Assigned(DirectionsProperties) then
  begin
    ARequest.TravelMode := FTravelMode;

    d := ARequest.Items;
    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'statusCode');
            ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(o, 'statusDescription');

            jar := TTMSFNCUtils.GetJSONValue(o, 'resourceSets') as TJSONArray;
            if Assigned(jar) then
            begin
              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
              begin
                jo := TTMSFNCUtils.GetJSONArrayItem(jar, i);

                jal := TTMSFNCUtils.GetJSONValue(jo, 'resources') as TJSONArray;
                if Assigned(jal) then
                begin
                  for j := 0 to TTMSFNCUtils.GetJSONArraySize(jal) - 1 do
                  begin
                    di := d.Add;
                    di.Summary := 'Route ' + IntToStr(j);

                    jol := TTMSFNCUtils.GetJSONArrayItem(jal, j);

                    distance := TTMSFNCUtils.GetJSONProp(jol, 'travelDistance');
                    if distance <> '' then
                      di.Distance := StrToFloatDot(StringReplace(distance, ',', '.', [])) * 1000;
                    di.Duration := TTMSFNCUtils.GetJSONIntegerValue(jol, 'travelDuration');

                    os := TTMSFNCUtils.GetJSONValue(jol, 'routePath') as TJSONObject;
                    if Assigned(os) then
                    begin
                      ob := TTMSFNCUtils.GetJSONValue(os, 'line') as TJSONObject;

                      jas := TTMSFNCUtils.GetJSONValue(ob, 'coordinates') as TJSONArray;
                      if Assigned(jas) then
                      begin
                        for k := 0 to TTMSFNCUtils.GetJSONArraySize(jas) - 1 do
                        begin
                          jos := TTMSFNCUtils.GetJSONArrayItem(jas, k);

                          co := di.Coordinates.Add;
                          cor := TStringList.Create;
                          cor.QuoteChar := '"';
                          corlist := StringReplace(jos.ToString, ',', '","', [rfReplaceAll]);
                          corlist := StringReplace(corlist, '[', '"', [rfReplaceAll]);
                          corlist := StringReplace(corlist, ']', '"', [rfReplaceAll]);
                          TTMSFNCUtils.Split(',', corlist, cor);
                          co.Latitude := StrToFloatDot(cor[0]);
                          co.Longitude := StrToFloatDot(cor[1]);
                          cor.Free;
                        end;
                      end;
                    end;

                    jak := TTMSFNCUtils.GetJSONValue(jol, 'routeLegs') as TJSONArray;
                    if Assigned(jak) then
                    begin
                      for l := 0 to TTMSFNCUtils.GetJSONArraySize(jak) - 1 do
                      begin
                        lg := di.Legs.Add;

                        jol := TTMSFNCUtils.GetJSONArrayItem(jak, l);

                        obs := TTMSFNCUtils.GetJSONValue(jol, 'actualStart');
                        if Assigned(obs) then
                        begin
                          jac := TTMSFNCUtils.GetJSONValue(obs, 'coordinates') as TJSONArray;
                          if Assigned(jac) then
                          begin
                            joc := TTMSFNCUtils.GetJSONArrayItem(jac, 0);
                            lg.StartLocation.Latitude := StrToFloatDot(joc.ToString);
                            joc := TTMSFNCUtils.GetJSONArrayItem(jac, 1);
                            lg.StartLocation.Longitude := StrToFloatDot(joc.ToString);
                          end;
                        end;

                        obs := TTMSFNCUtils.GetJSONValue(jol, 'actualEnd');
                        if Assigned(obs) then
                        begin
                          jac := TTMSFNCUtils.GetJSONValue(obs, 'coordinates') as TJSONArray;
                          if Assigned(jac) then
                          begin
                            joc := TTMSFNCUtils.GetJSONArrayItem(jac, 0);
                            lg.EndLocation.Latitude := StrToFloatDot(joc.ToString);
                            joc := TTMSFNCUtils.GetJSONArrayItem(jac, 1);
                            lg.EndLocation.Longitude := StrToFloatDot(joc.ToString);
                          end;
                        end;

                        di.Summary := TTMSFNCUtils.GetJSONProp(jol, 'description');

                        jas := TTMSFNCUtils.GetJSONValue(jol, 'routeSubLegs') as TJSONArray;
                        if Assigned(jas) then
                        begin
                          for k := 0 to TTMSFNCUtils.GetJSONArraySize(jas) - 1 do
                          begin
                            jos := TTMSFNCUtils.GetJSONArrayItem(jas, k);
                            obs := TTMSFNCUtils.GetJSONValue(jos, 'startWaypoint');
                            idxs := -1;
                            if Assigned(obs) then
                              idxs := TTMSFNCUtils.GetJSONIntegerValue(obs, 'routePathIndex');

                            obs := TTMSFNCUtils.GetJSONValue(jos, 'endWaypoint');
                            idxe := -1;
                            if Assigned(obs) then
                              idxe := TTMSFNCUtils.GetJSONIntegerValue(obs, 'routePathIndex');

                            for JJ := idxs to idxe do
                            begin
                              if (JJ >= 0) and (JJ <= di.Coordinates.Count - 1) then
                                lg.Coordinates.Add.Assign(di.Coordinates[JJ]);
                            end;
                          end;
                        end;

                        jas := TTMSFNCUtils.GetJSONValue(jol, 'itineraryItems') as TJSONArray;
                        if Assigned(jas) then
                        begin
                          for k := 0 to TTMSFNCUtils.GetJSONArraySize(jas) - 1 do
                          begin
                            jos := TTMSFNCUtils.GetJSONArrayItem(jas, k);

                            step := di.Steps.Add;

                            distance := TTMSFNCUtils.GetJSONProp(jos, 'travelDistance');
                            if distance <> '' then
                              step.Distance := Round(StrToFloatDot(StringReplace(distance, ',', '.', [])) * 1000);
                            step.Duration := TTMSFNCUtils.GetJSONIntegerValue(jos, 'travelDuration');

                            lg.Distance := lg.Distance + step.Distance;
                            lg.Duration := lg.Duration + step.Duration;

                            os := TTMSFNCUtils.GetJSONValue(jos, 'instruction') as TJSONObject;
                            if Assigned(os) then
                              step.Instructions := TTMSFNCUtils.GetJSONProp(os, 'text');

                            os := TTMSFNCUtils.GetJSONValue(jos, 'maneuverPoint') as TJSONObject;
                            if Assigned(os) then
                            begin
                              jac := TTMSFNCUtils.GetJSONValue(os, 'coordinates') as TJSONArray;
                              if Assigned(jac) then
                              begin
                                joc := TTMSFNCUtils.GetJSONArrayItem(jac, 0);
                                step.StartLocation.Latitude := StrToFloatDot(joc.ToString);
                                joc := TTMSFNCUtils.GetJSONArrayItem(jac, 1);
                                step.StartLocation.Longitude := StrToFloatDot(joc.ToString);
                              end;
                            end;
                            lstep := lg.Steps.Add;
                            lstep.Assign(step);
                          end;
                        end;
                      end;
                    end;
                  end;
                end;
              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

