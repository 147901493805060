{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCDirections.OpenRouteService;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterOpenRouteServiceDirectionsService;
procedure UnRegisterOpenRouteServiceDirectionsService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCDirections,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCDirectionsOpenRouteServiceService = class;

  TTMSFNCDirectionsOpenRouteServiceDirectionsFactoryService = class(TTMSFNCDirectionsFactoryService, ITMSFNCDirectionsServiceOpenRouteService);

  TTMSFNCDirectionsOpenRouteServiceService = class(TTMSFNCDirectionsOpenRouteServiceDirectionsFactoryService)
  protected
    function DoCreateDirections: ITMSFNCCustomDirections; override;
  end;

type
  TTMSFNCDirectionsOpenRouteServiceDirections = class;

  TTMSFNCDirectionsOpenRouteServiceDirections = class(TTMSFNCCustomDirectionsInterfacedObject, ITMSFNCCustomDirections)
  protected
    FTravelMode: TTMSFNCDirectionsTravelMode;
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
    function GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
    function GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
    procedure Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyDirections;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  DirectionsService: ITMSFNCDirectionsServiceOpenRouteService;

procedure RegisterOpenRouteServiceDirectionsService;
begin
  if not TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceOpenRouteService, IInterface(DirectionsService)) then
  begin
    DirectionsService := TTMSFNCDirectionsOpenRouteServiceService.Create;
    TTMSFNCDirectionsPlatformServices.Current.AddPlatformService(ITMSFNCDirectionsServiceOpenRouteService, DirectionsService);
  end;
end;

procedure UnregisterOpenRouteServiceDirectionsService;
begin
  TTMSFNCDirectionsPlatformServices.Current.RemovePlatformService(ITMSFNCDirectionsServiceOpenRouteService);
end;

{ TTMSFNCDirectionsOpenRouteServiceService }

function TTMSFNCDirectionsOpenRouteServiceService.DoCreateDirections: ITMSFNCCustomDirections;
begin
  Result := TTMSFNCDirectionsOpenRouteServiceDirections.Create;
end;

procedure TTMSFNCDirectionsOpenRouteServiceDirections.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin
  AHeaders.Add(TTMSFNCCloudBaseRequestHeader.Create('Content-Type', 'application/json'));
  AHeaders.Add(TTMSFNCCloudBaseRequestHeader.Create('Authorization', DirectionsProperties.GetAPIKey));
end;

constructor TTMSFNCDirectionsOpenRouteServiceDirections.Create;
begin
  inherited;
end;

destructor TTMSFNCDirectionsOpenRouteServiceDirections.Destroy;
begin
  inherited;
end;

procedure TTMSFNCDirectionsOpenRouteServiceDirections.DestroyDirections;
begin
  DirectionsService.DestroyDirections(Self);
end;

function TTMSFNCDirectionsOpenRouteServiceDirections.GetHost: string;
begin
  Result := 'https://api.openrouteservice.org';
end;

function TTMSFNCDirectionsOpenRouteServiceDirections.GetIdentifier: string;
begin
  Result := 'OpenRouteService';
end;

function TTMSFNCDirectionsOpenRouteServiceDirections.GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
var
  TravelMode: string;
begin

  if ATravelMode in [tmPublicTransport] then
    FTravelMode := tmDriving
  else
    FTravelMode := ATravelMode;

  case ATravelMode of
    tmDriving: TravelMode := 'driving-car';
    tmWalking: TravelMode := 'foot-walking';
    tmBicycling: TravelMode := 'cycling-regular';
    tmPublicTransport: TravelMode := 'driving-car';
    tmTruck: TravelMode := 'driving-hgv';
  end;

  Result := '/v2/directions/' + TravelMode;
end;

function TTMSFNCDirectionsOpenRouteServiceDirections.GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
var
  coords, tolls, alts, lang: string;
  I, wpcount: Integer;

  function CoordToStr(Latitude, Longitude: Double): string;
  begin
    Result := '[' + FloatToStrDot(Longitude) + ',' + FloatToStrDot(Latitude) + ']';
  end;

begin
  wpcount := 0;
  coords := CoordToStr(AOriginLatitude, AOriginLongitude);
  if Assigned(AWayPoints) then
  begin
    wpcount := Length(AWayPoints);
    for I := 0 to wpcount - 1 do
    begin
      coords := coords +  ', ' + CoordToStr(AWayPoints[I].Latitude, AWayPoints[I].Longitude);
    end;
  end;
  coords := coords + ', ' + CoordToStr(ADestinationLatitude, ADestinationLongitude);

  if ATravelMode in [tmDriving, tmTruck] then
  begin
    if AAvoidTolls then
      tolls := ',"options":{"avoid_features":["tollways"]}';
  end;

  if wpcount <= 0 then
  begin
    if AAlternatives then
      alts := ',"alternative_routes":{"target_count":2}';
  end;

  if ALocale <> '' then
    lang := ', "language": "' + ALocale + '"';

  Result := '{"coordinates":[' + coords + ']' + lang + alts + tolls + '}';
end;

function TTMSFNCDirectionsOpenRouteServiceDirections.GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
  ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
  ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
  AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
begin
  Result := '';
end;

function TTMSFNCDirectionsOpenRouteServiceDirections.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmPost;
end;

function TTMSFNCDirectionsOpenRouteServiceDirections.IsValid: Boolean;
begin
  Result := DirectionsProperties.GetAPIKey <> '';
end;

procedure TTMSFNCDirectionsOpenRouteServiceDirections.Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
var
  d: TTMSFNCDirectionsItems;
  di: TTMSFNCDirectionsItem;
  step, lstep: TTMSFNCDirectionsStep;
  l: TTMSFNCDirectionsLeg;
  it, lit: TTMSFNCMapsCoordinateItem;

  o, jo, jol, jos, joar: TJSONValue;
  jar, jal, jas, jab: TJSONArray;
  i, j, k, m, startindex, endindex: Integer;
  ol: TJSONObject;
begin
  if Assigned(DirectionsProperties) then
  begin
    ARequest.TravelMode := FTravelMode;

    d := ARequest.Items;
    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(o, 'error');
            if ARequest.ErrorMessage <> '' then
              ARequest.Status := 'error';

            jar := TTMSFNCUtils.GetJSONValue(o, 'routes') as TJSONArray;
            if Assigned(jar) then
            begin

              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
              begin
                jo := TTMSFNCUtils.GetJSONArrayItem(jar, i);

                di := d.Add;
                ol := TTMSFNCUtils.GetJSONValue(jo, 'summary') as TJSONObject;
                if Assigned(ol) then
                begin
                  di.Distance := TTMSFNCUtils.GetJSONDoubleValue(ol, 'distance');
                  di.Duration := Round(TTMSFNCUtils.GetJSONDoubleValue(ol, 'duration'));
                end;

                DecodeValues(TTMSFNCUtils.GetJSONProp(jo, 'geometry'), di.Coordinates);

                jal := TTMSFNCUtils.GetJSONValue(jo, 'segments') as TJSONArray;
                if Assigned(jal) then
                begin
                  for j := 0 to TTMSFNCUtils.GetJSONArraySize(jal) - 1 do
                  begin
                    jol := TTMSFNCUtils.GetJSONArrayItem(jal, j);

                    l := di.Legs.Add;
                    l.Distance := Round(TTMSFNCUtils.GetJSONDoubleValue(jol, 'distance'));
                    l.Duration := Round(TTMSFNCUtils.GetJSONDoubleValue(jol, 'duration'));

                    jas := TTMSFNCUtils.GetJSONValue(jol, 'steps') as TJSONArray;
                    if Assigned(jas) then
                    begin
                      for k := 0 to TTMSFNCUtils.GetJSONArraySize(jas) - 1 do
                      begin
                        jos := TTMSFNCUtils.GetJSONArrayItem(jas, k);

                        step := di.Steps.Add;
                        step.Distance := Round(TTMSFNCUtils.GetJSONDoubleValue(jos, 'distance'));
                        step.Duration := Round(TTMSFNCUtils.GetJSONDoubleValue(jos, 'duration'));
                        step.Instructions := TTMSFNCUtils.GetJSONProp(jos, 'instruction');

                        jab := TTMSFNCUtils.GetJSONValue(jos, 'way_points') as TJSONArray;
                        if Assigned(jar) then
                        begin
                          startindex := 0;
                          endindex := 0;
                          for m := 0 to TTMSFNCUtils.GetJSONArraySize(jab) - 1 do
                          begin
                            joar := TTMSFNCUtils.GetJSONArrayItem(jab, m);
                            if m = 0 then
                              startindex := Round(StrToFloatDot(joar.Value));
                            if m = 1 then
                              endindex := Round(StrToFloatDot(joar.Value));
                          end;
                          for m := startindex to endindex do
                          begin
                            it := step.Coordinates.Add;
                            it.Latitude := di.Coordinates[m].Latitude;
                            it.Longitude := di.Coordinates[m].Longitude;

                            lit := l.Coordinates.Add;
                            lit.Latitude := it.Latitude;
                            lit.Longitude := it.Longitude;
                          end;
                          if step.Coordinates.Count > 0 then
                          begin
                            step.StartLocation.Assign(step.Coordinates[0].Coordinate);
                            step.EndLocation.Assign(step.Coordinates[step.Coordinates.Count - 1].Coordinate);
                          end;
                          lstep := l.Steps.Add;
                          lstep.Assign(step);
                        end;
                      end;

                      if l.Coordinates.Count > 0 then
                      begin
                        l.StartLocation.Assign(l.Coordinates[0].Coordinate);
                        l.EndLocation.Assign(l.Coordinates[l.Coordinates.Count - 1].Coordinate);
                      end;

                    end;
                  end;
                end;
              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

