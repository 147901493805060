{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2023                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCMaps.Leaflet;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

const
  LeafletAPIVERSION = '1.9.4';
  LeafletAPIURL = 'https://unpkg.com/leaflet@';
  MAPSERVICEVAR = 'window.L';
  MAPSOURCEVAR = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';
  MAPBASELAYERVAR = 'baseLayer';
  MAPATTRIBUTIONPREFIX = '<a href=''https://www.leafletjs.com'' target=''_blank''>Leaflet</a>';
  MAPATTRIBUTIONTEXT = '&copy; <a href=''https://www.openstreetmap.org/copyright'' target=''_blank''>OpenStreetMap</a>';

  CLUSTERVAR = 'cluster';
  CLUSTERARRAYVAR = 'clusterarray';
  GETCLUSTERARRAYVAR = 'get' + CLUSTERARRAYVAR + '()';
  ADDORUPDATECLUSTERFUNCTION = 'addOrUpdateCluster';
  DELETECLUSTERFUNCTION = 'deleteCluster';

procedure RegisterLeafletService;
procedure UnRegisterLeafletService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCMaps, WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes
  {$IFDEF WEBLIB}
  ,Contnrs, Web
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCMapsLeafletService = class;

  TTMSFNCMapsLeafletFactoryService = class(TTMSFNCMapsFactoryService, ITMSFNCMapsServiceLeaflet);

  TTMSFNCMapsLeafletService = class(TTMSFNCMapsLeafletFactoryService)
  protected
    function DoCreateMaps: ITMSFNCCustomMaps; override;
  end;

type
  TTMSFNCMapsLeaflet = class;

  TTMSFNCMapsLeaflet = class(TTMSFNCCustomMapsInterfacedObject, ITMSFNCCustomMaps)
  protected
    procedure GetHeadLinks(const AList: TTMSFNCMapsLinksList);
    procedure RemoveScripts;
    function GetResetMap: string;
    function GetShowPopup: string;
    function GetClosePopup: string;
    function GetHeadStyle: string;
    function GetDelayLoadEvent: string;
    function GetMapsServiceCheck: string;
    function GetIdentifier: string;
    function GetInitializeMap: string;
    function GetInitializeEvents: string;
    function GetAddOrUpdateMarker: string;
    function GetDeleteMarker: string;
    function GetAddOrUpdatePolyElement: string;
    function GetDeletePolyElement: string;
    function GetZoomToBounds: string;
    function GetGetBounds: string;
    function GetGetCenterCoordinate: string;
    function GetGetZoomLevel: string;
    function GetLatLonToXY: string;
    function GetXYToLatLon: string;
    function GetSetCenterCoordinate: string;
    function GetSetZoomLevel: string;
    function GetUpdateOptions: string;
    function GetGlobalVariables: string;
    function GetAddCoordinateToArray: string;
    function GetAddHoleToArray: string;
    function GetInitializeCoordinateArray: string;
    function GetInitializeHolesArray: string;
    function IsValid: Boolean;
    procedure DestroyMaps;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  MapsService: ITMSFNCMapsServiceLeaflet;

const
  MAPZOOMCONTROLVAR = 'mapZoomControl';
  MAPCONTROLARRAYVAR = 'mapControls';
  MAPDOUBLECLICK = 'mapDoubleClick';
  MAPWHEELZOOM = 'mapWheelZoom';
  MAPPANNING = 'mapPanning';

procedure RegisterLeafletService;
begin
  if not TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceLeaflet, IInterface(MapsService)) then
  begin
    MapsService := TTMSFNCMapsLeafletService.Create;
    TTMSFNCMapsPlatformServices.Current.AddPlatformService(ITMSFNCMapsServiceLeaflet, MapsService);
  end;
end;

procedure UnregisterLeafletService;
begin
  TTMSFNCMapsPlatformServices.Current.RemovePlatformService(ITMSFNCMapsServiceLeaflet);
end;

{ TTMSFNCMapsLeafletService }

function TTMSFNCMapsLeafletService.DoCreateMaps: ITMSFNCCustomMaps;
begin
  Result := TTMSFNCMapsLeaflet.Create;
end;

constructor TTMSFNCMapsLeaflet.Create;
begin
  inherited;
end;

destructor TTMSFNCMapsLeaflet.Destroy;
begin
  inherited;
end;

procedure TTMSFNCMapsLeaflet.DestroyMaps;
begin
  MapsService.DestroyMaps(Self);
end;

function TTMSFNCMapsLeaflet.GetAddCoordinateToArray: string;
begin
  Result := '  ' + COORDINATEARRAYVAR + '.push([' + PARAMSNAME + '["Latitude"],' + PARAMSNAME + '["Longitude"]]);';
end;

function TTMSFNCMapsLeaflet.GetAddHoleToArray: string;
begin
  Result := 'var o = ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]];' + LB +
            'if (o){' + LB +
            '  ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]].push([' + PARAMSNAME + '[1]["Latitude"], ' + PARAMSNAME + '[1]["Longitude"]]);' + LB +
            '}else{' + LB +
            '  ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]] = [[' + PARAMSNAME + '[1]["Latitude"], ' + PARAMSNAME + '[1]["Longitude"]]];' + LB +
            '}';
end;

function TTMSFNCMapsLeaflet.GetAddOrUpdateMarker: string;
begin

  Result :=
    '  var pos = ' + MAPSERVICEVAR + '.latLng([' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"]]);' + LB +
    '  var ic = null;' + LB +
    '  if (' + PARAMSNAME + '["IconURL"] != ""){' + LB +

    '    ic = ' + MAPSERVICEVAR + '.icon({' + LB +
    '      iconUrl: ' + PARAMSNAME + '["IconURL"],' + LB +
    '      className: "tms_leaflet_iconImage",' + LB +
//    '      iconSize: sz' + LB +
//    '      iconAnchor: ac' + LB +
    '    });' + LB +

    '    var options = {' + LB +
    '        icon: ic,' + LB +
    '        title: ' + PARAMSNAME + '["Title"],' + LB +
    '        alt: ' + PARAMSNAME + '["Title"],' + LB +
//    '        interactive: ' + PARAMSNAME + '["Clickable"],' + LB +
//    '        zIndexOffset: ' + PARAMSNAME + '["ZIndex"],' + LB +
    '        draggable: ' + PARAMSNAME + '["Draggable"],' + LB +
    '        bubblingMouseEvents: false' + LB +
    '    }' + LB +

    '  } else {' + LB +

    '    var options = {' + LB +
    '        title: ' + PARAMSNAME + '["Title"],' + LB +
    '        alt: ' + PARAMSNAME + '["Title"],' + LB +
//    '        interactive: ' + PARAMSNAME + '["Clickable"],' + LB +
//    '        zIndexOffset: ' + PARAMSNAME + '["ZIndex"],' + LB +
    '        draggable: ' + PARAMSNAME + '["Draggable"],' + LB +
    '        bubblingMouseEvents: false' + LB +
    '    }' + LB +

    '  }' + LB + LB +

    '  if (' + MARKERVAR + ')' + LB +
    '    ' + MARKERVAR + '.remove();' + LB +

//    '  if (!' + MARKERVAR + '){' + LB +
    '    ' + MARKERVAR + ' = ' + MAPSERVICEVAR + '.marker(pos, options);' + LB +

    '    ' + MARKERVAR + '.on(''click'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.on(''contextmenu'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerRightClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.on(''dblclick'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.on(''mouseup'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.on(''mousedown'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.on(''mouseout'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.on(''mouseover'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.on(''dragend'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerDragEnd", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +

//    ' } else {' + LB +
//    '    ' + MARKERVAR + '.setLatLng(pos);' + LB +
//    ' }' + LB +

    '  if (' + PARAMSNAME + '["Visible"]) {' + LB +
    '    ' + MARKERVAR + '.addTo(' + MAPVAR + ');' + LB +
    '  } else { ' + LB +
    '    ' + MARKERVAR + '.remove();' + LB +
    '  }';
end;

function TTMSFNCMapsLeaflet.GetAddOrUpdatePolyElement: string;
begin

  Result :=
    '  var c = ' + PARAMSNAME + '["$type"];' + LB +
    '  var co = [];' + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '    case "TTMSFNCMapsLeafletRectangle":' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '    case "TTMSFNCLeafletCircle":' + LB +
    '    case "TTMSFNCMapsPolygon":' + LB +
    '    case "TTMSFNCLeafletPolygon":' + LB +
    '      var options = {' + LB +
    '        color: ' + PARAMSNAME + '["StrokeColor"],' + LB +
    '        weight: ' + PARAMSNAME + '["StrokeWidth"],' + LB +
    '        opacity: ' + PARAMSNAME + '["StrokeOpacity"],' + LB +
    '        fill: true,' + LB +
    '        fillColor: ' + PARAMSNAME + '["FillColor"],' + LB +
    '        fillOpacity: ' + PARAMSNAME + '["FillOpacity"],' + LB +
//    '        interactive: ' + PARAMSNAME + '["Clickable"],' + LB +
//    '        zIndex: ' + PARAMSNAME + '["ZIndex"],' + LB +
    '        radius: ' + PARAMSNAME + '["Radius"],' + LB +
    '        bubblingMouseEvents: false' + LB +
    '      }' + LB +
    '    break;' + LB +
    '    default:' + LB +
    '      var options = {' + LB +
    '        color: ' + PARAMSNAME + '["StrokeColor"],' + LB +
    '        weight: ' + PARAMSNAME + '["StrokeWidth"],' + LB +
    '        opacity: ' + PARAMSNAME + '["StrokeOpacity"],' + LB +
//    '        interactive: ' + PARAMSNAME + '["Clickable"],' + LB +
    '        bubblingMouseEvents: false' + LB +
    '      }' + LB +
    '    break;' + LB +
    '  }' + LB + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '    case "TTMSFNCMapsLeafletRectangle":' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"]]);' + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '    case "TTMSFNCLeafletCircle":' + LB +
    '      co = [' + PARAMSNAME + '["Center"]["Latitude"], ' + PARAMSNAME + '["Center"]["Longitude"]];' + LB +
    '    break;' + LB +
    '    default:' + LB +

    '      var pthArr = [];' + LB +
    '      pthArr.push(' + COORDINATEARRAYVAR + ');' + LB +
    '      for (var key in ' + HOLEARRAYVAR + '){' + LB +
    '        pthArr.push(' + HOLEARRAYVAR + '[key]);' + LB +
    '      }' + LB +

    '      co = pthArr;' + LB +
    '    break;' + LB +
    '  }' + LB + LB +

    '  if (' + POLYELEMENTVAR + ')' + LB +
    '    ' + POLYELEMENTVAR + '.remove();' + LB +

//    '  if (!' + POLYELEMENTVAR + '){' + LB +

    '    switch(c){' + LB +
    '      case "TTMSFNCMapsRectangle":' + LB +
    '      case "TTMSFNCMapsLeafletRectangle":' + LB +
    '        ' + POLYELEMENTVAR + ' = ' + MAPSERVICEVAR + '.rectangle(co, options).addTo(' + MAPVAR + ');' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsCircle":' + LB +
    '      case "TTMSFNCLeafletCircle":' + LB +
    '        ' + POLYELEMENTVAR + ' = ' + MAPSERVICEVAR + '.circle(co, options).addTo(' + MAPVAR + ');' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsPolygon":' + LB +
    '      case "TTMSFNCLeafletPolygon":' + LB +
    '        ' + POLYELEMENTVAR + ' = ' + MAPSERVICEVAR + '.polygon(co, options).addTo(' + MAPVAR + ');' + LB +
    '      break;' + LB +
    '      default:' + LB +
    '        ' + POLYELEMENTVAR + ' = ' + MAPSERVICEVAR + '.polyline(co, options).addTo(' + MAPVAR + ');' + LB +
    '      break;' + LB +
    '    }' + LB + LB +

    '    ' + POLYELEMENTVAR + '.on(''click'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.on(''rightclick'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementRightClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.on(''dblclick'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.on(''mouseup'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.on(''mousedown'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.on(''mouseout'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.on(''mouseover'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +

//    ' } else {' + LB +
//
//    '    switch(c){' + LB +
//    '      case "TTMSFNCMapsRectangle":' + LB +
//    '      case "TTMSFNCMapsLeafletRectangle":' + LB +
//    '        ' + POLYELEMENTVAR + ' = ' + MAPSERVICEVAR + '.setBounds(co);' + LB +
//    '      break;' + LB +
//    '      case "TTMSFNCMapsCircle":' + LB +
//    '      case "TTMSFNCLeafletCircle":' + LB +
//    '        ' + POLYELEMENTVAR + '.setCenter(co);' + LB +
//    '      break;' + LB +
//    '      default:' + LB +
//    '        ' + POLYELEMENTVAR + '.setLatLng(co);' + LB +
//    '      break;' + LB +
//    '    }' + LB + LB +
//
//    ' }' + LB +

    '  if (' + PARAMSNAME + '["Visible"]) {' + LB +
    '    ' + POLYELEMENTVAR + '.addTo(' + MAPVAR + ');' + LB +
    '  } else { ' + LB +
    '    ' + POLYELEMENTVAR + '.remove();' + LB +
    '  }';

end;

function TTMSFNCMapsLeaflet.GetDelayLoadEvent: string;
begin
  Result :=  '';
end;

function TTMSFNCMapsLeaflet.GetDeleteMarker: string;
begin
  Result := '  ' + MAPVAR + '.removeLayer(' + MARKERVAR + ');' + LB;
end;

function TTMSFNCMapsLeaflet.GetDeletePolyElement: string;
begin
  Result := '  ' + MAPVAR + '.removeLayer(' + POLYELEMENTVAR + ');' + LB;
end;

function TTMSFNCMapsLeaflet.GetGetBounds: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getBounds();' + LB +
    '  jsonObj["NorthEast"]["Latitude"] = loc._northEast.lat;' + LB +
    '  jsonObj["NorthEast"]["Longitude"] = loc._northEast.lng;' + LB +
    '  jsonObj["SouthWest"]["Latitude"] = loc._southWest.lat;' + LB +
    '  jsonObj["SouthWest"]["Longitude"] = loc._southWest.lng;';
end;

function TTMSFNCMapsLeaflet.GetGetCenterCoordinate: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getCenter();' + LB +
    '  jsonObj["Latitude"] = loc.lat;' + LB +
    '  jsonObj["Longitude"] = loc.lng;';
end;

function TTMSFNCMapsLeaflet.GetLatLonToXY: string;
begin
  Result :=
    '  var pixel = ' + MAPVAR + '.latLngToContainerPoint([parseFloat(' + PARAMSNAME + '["Latitude"]), parseFloat(' + PARAMSNAME + '["Longitude"])]);' + LB +
    '  jsonObj["X"] = parseFloat(pixel.x);' + LB +
    '  jsonObj["Y"] = parseFloat(pixel.y);' + LB +
   '';
end;

function TTMSFNCMapsLeaflet.GetXYToLatLon: string;
begin
  Result :=
    '  var co = ' + MAPVAR + '.containerPointToLatLng([parseInt(' + PARAMSNAME + '["X"]), parseInt(' + PARAMSNAME + '["Y"])]);' + LB +
    '  jsonObj["Latitude"] = parseFloat(co.lat);' + LB +
    '  jsonObj["Longitude"] = parseFloat(co.lng);' + LB +
   '';
end;

function TTMSFNCMapsLeaflet.GetGetZoomLevel: string;
begin
  Result := '  var z = ' + MAPVAR + '.getZoom();';
end;

function TTMSFNCMapsLeaflet.GetGlobalVariables: string;
begin
  Result :=
    'var activePolyElement = null;' + LB +
    'var activeMarker = null;' + LB +
    'var ' + MAPBASELAYERVAR + ';' + LB +
    'var ' + MAPZOOMCONTROLVAR + ';' + LB +
    'var ' + MAPCONTROLARRAYVAR + ' = [];' +
    'var oldZoom;' + LB +
    'var hoverFeature;' + LB +
    'var mouseLatLng = {''Latitude'': 0, ''Longitude'': 0};' + LB +
    'function parseEvent(event, eventname, id = ''''){' + LB +
    '  var loc = {''Latitude'': 0, ''Longitude'': 0};' + LB +
    '  var x = 0.0;' + LB +
    '  var y = 0.0;' + LB +
    '  if ((eventname == "MapMoveStart") || (eventname == "MapMoveEnd")){ ' +
    '    centerpt = ' + MAPVAR + '.getCenter();' + LB +
    '    loc = {''Latitude'': centerpt.lat, ''Longitude'': centerpt.lng};' + LB +
    '  } else if (event) {' + LB +
    '    if (event.latlng) {' + LB +
    '      loc = {''Latitude'': event.latlng.lat, ''Longitude'': event.latlng.lng};' + LB +
    '      mouseLatLng = loc;' + LB +
    '    }' + LB +
    '    if (event.layerPoint) {' + LB +
    '      x = event.layerPoint.x;' + LB +
    '      y = event.layerPoint.y;' + LB +
    '    }' + LB +
    '  }' + LB +

    '  var r = {''Coordinate'': loc, ''X'': x, ''Y'': y, ''ID'': id, ''EventName'': eventname};' + LB +
    '  return r;' + LB +
    '}' + LB + LB +

    'function MapMoveStart(event){' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMoveStart"));' + LB +
    '}' + LB + LB +
    'function MapMoveEnd(event){' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMoveEnd"));' + LB +
    '}' + LB + LB +
    'function MapClick(event){' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapClick"));' + LB +
    '}' + LB + LB +
    'function MapDblClick(event){' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapDblClick"));' + LB +
    '}' + LB + LB +
    'function MapMouseDown(event){' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMouseDown"));' + LB +
    '}' + LB + LB +
    'function MapMouseUp(event){' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMouseUp"));' + LB +
    '}' + LB + LB +
    'function MapMouseLeave(event){' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMouseLeave"));' + LB +
    '}' + LB + LB +
    'function MapMouseEnter(event){' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMousEnter"));' + LB +
    '}' + LB + LB +
    'function MapMouseMove(event){' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMouseMove"));' + LB +
    '}' + LB + LB +
    'function MapZoomChanged(event){' + LB +
    '    if (' + MAPVAR + '){' + LB +
    '      if (!oldZoom){' + LB +
    '        oldZoom = ' + MAPVAR + '.getZoom();' + LB +
    '      }' + LB +
    '      var newZoom = ' + MAPVAR + '.getZoom();' + LB +
    '      if(newZoom != oldZoom){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(null, "ZoomChanged"));' + LB +
    '      }' + LB +
    '      oldZoom = newZoom;' + LB +
    '    }' + LB +
    '}' + LB + LB;
end;

procedure TTMSFNCMapsLeaflet.GetHeadLinks(
  const AList: TTMSFNCMapsLinksList);
begin
  AList.Add(TTMSFNCMapsLink.CreateLink(LeafletAPIURL + LeafletAPIVERSION + '/dist/leaflet.css', 'text/css', 'stylesheet'));
  AList.Add(TTMSFNCMapsLink.CreateScript(LeafletAPIURL + LeafletAPIVERSION + '/dist/leaflet.js', 'text/javascript'));
end;

function TTMSFNCMapsLeaflet.GetHeadStyle: string;
begin
  Result := '.tms_leaflet_iconImage{' + LB +
      '  position: relative!important;' + LB +
      '  margin-top: -100%;' + LB +
      '  margin-left: -50%;' + LB +
      '}' + LB;
end;

function TTMSFNCMapsLeaflet.GetIdentifier: string;
begin
  Result := 'Leaflet';
end;

function TTMSFNCMapsLeaflet.GetInitializeCoordinateArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsLeaflet.GetInitializeHolesArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsLeaflet.GetInitializeEvents: string;
begin
  Result :=
  '  ' + MAPVAR + '.on(''movestart'', MapMoveStart);' + LB +
  '  ' + MAPVAR + '.on(''moveend'', MapMoveEnd);' + LB +
  '  ' + MAPVAR + '.on(''zoomend'', MapZoomChanged);' + LB +
  '  ' + MAPVAR + '.on(''click'', MapClick);' + LB +
  '  ' + MAPVAR + '.on(''dblclick'', MapDblClick);' + LB +
  '  ' + MAPVAR + '.on(''mouseup'', MapMouseUp);' + LB +
  '  ' + MAPVAR + '.on(''mousedown'', MapMouseDown);' + LB +
  '  ' + MAPVAR + '.on(''mousemove'', MapMouseMove);' + LB;
end;

function TTMSFNCMapsLeaflet.GetInitializeMap: string;
begin
  Result := Result +
    '  ' + MAPBASELAYERVAR + ' = ' + MAPSERVICEVAR + '.tileLayer("' + MAPSOURCEVAR + '", {' + LB +
    '    maxZoom: 19,' + LB +
    '  })' + LB +
    '';

  Result := Result +
    ' ' + MAPVAR + ' = ' + MAPSERVICEVAR + '.map("' + MAPID + '", {' + LB +
    '    center: [' + MapsProperties.GetDefaultLatitude + ', ' + MapsProperties.GetDefaultLongitude + '],' + LB +
    '    zoom: ' + MapsProperties.GetDefaultZoomLevel + ',' + LB +

    '    dragging: ' + LowerCase(BoolToStr(MapsProperties.GetPanning, True)) + ',' + LB +
    '    scrollWheelZoom: ' + LowerCase(BoolToStr(MapsProperties.GetZoomOnWheelScroll, True)) + ',' + LB +
    '    doubleClickZoom: ' + LowerCase(BoolToStr(MapsProperties.GetZoomOnDblClick, True)) + ',' + LB +
    '    zoomControl: ' + LowerCase(BoolToStr(MapsProperties.GetShowZoomControl, True)) + ',' + LB +
    '    layers: [' + MAPBASELAYERVAR + '],' + LB +
    '  })' + LB + LB;

  Result := Result +
    '  ' + MAPVAR + '.removeControl(' + MAPVAR + '.attributionControl);' + LB +
    '    var attr = ' + MAPSERVICEVAR + '.control.attribution();' + LB +
    '    if ("' + MAPATTRIBUTIONPREFIX + '" != "") {' + LB +
    '      attr.setPrefix("' + MAPATTRIBUTIONPREFIX + '");' + LB +
    '    }' + LB +
    '    if ("' + MAPATTRIBUTIONTEXT + '" != "") {' + LB +
    '      attr.addAttribution("' + MAPATTRIBUTIONTEXT + '");' + LB +
    '    }' + LB +
    '    attr.addTo(' + MAPVAR + ');' + LB +
      '';
end;

function TTMSFNCMapsLeaflet.GetMapsServiceCheck: string;
begin
  Result := '!' + MAPSERVICEVAR;
end;

function TTMSFNCMapsLeaflet.GetResetMap: string;
begin
  Result := '';
end;

function TTMSFNCMapsLeaflet.GetSetCenterCoordinate: string;
begin
  Result := '  ' + MAPVAR + '.setView([' + PARAMSNAME + '["Latitude"], '  + PARAMSNAME + '["Longitude"]]);';
end;

function TTMSFNCMapsLeaflet.GetSetZoomLevel: string;
begin
  Result := '  ' + MAPVAR + '.setZoom(' + PARAMSNAME + ');';
end;

function TTMSFNCMapsLeaflet.GetClosePopup: string;
begin
  Result := '  ' + MAPVAR + '.closePopup();' + LB;
end;

function TTMSFNCMapsLeaflet.GetShowPopup: string;
begin
  Result :=
  '  var lat = [' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"]];' + LB +
  '  ' + POPUPVAR + ' = ' + MAPSERVICEVAR + '.popup(lat, {' + LB +
  '    offset: ' + MAPSERVICEVAR + '.point(' + PARAMSNAME + '["OffsetX"], ' + PARAMSNAME + '["OffsetY"]),' + LB +
  '    content: ' + PARAMSNAME + '["Text"],' + LB +
  '    autoPan: ' + PARAMSNAME + '["PanMap"],' + LB +
  '  });' + LB +
  '  ' + POPUPVAR + '.openOn(' + MAPVAR + ');';
end;

function TTMSFNCMapsLeaflet.GetUpdateOptions: string;
begin
  Result := Result +
    '  if (' + PARAMSNAME + '["ShowZoomControl"]){' + LB +
    '    ' + MAPVAR + '.addControl(' + MAPVAR + '.zoomControl);' + LB +
    '  } else { ' + LB +
    '    ' + MAPVAR + '.removeControl(' + MAPVAR + '.zoomControl);' + LB +
    '  }';

  Result := Result +
    '  if (' + PARAMSNAME + '["ZoomOnWheelScroll"]){' + LB +
    '    ' + MAPVAR + '.scrollWheelZoom.enable();' + LB +
    '  } else { ' + LB +
    '    ' + MAPVAR + '.scrollWheelZoom.disable();' + LB +
    '  }';

  Result := Result +
    '  if (' + PARAMSNAME + '["ZoomOnDblClick"]){' + LB +
    '    ' + MAPVAR + '.doubleClickZoom.enable();' + LB +
    '  } else { ' + LB +
    '    ' + MAPVAR + '.doubleClickZoom.disable();' + LB +
    '  }';

  Result := Result +
    '  if (' + PARAMSNAME + '["Panning"]){' + LB +
    '    ' + MAPVAR + '.dragging.enable();' + LB +
    '  } else { ' + LB +
    '    ' + MAPVAR + '.dragging.disable();' + LB +
    '  }';
end;

function TTMSFNCMapsLeaflet.GetZoomToBounds: string;
begin
  Result := MAPVAR + '.fitBounds([[' + PARAMSNAME + '["SouthWest"]["Latitude"],' + PARAMSNAME + '["SouthWest"]["Longitude"]], ' +
   '[' + PARAMSNAME + '["NorthEast"]["Latitude"], ' + PARAMSNAME + '["NorthEast"]["Longitude"]]])';
end;

function TTMSFNCMapsLeaflet.IsValid: Boolean;
begin
  Result := True;
end;

procedure TTMSFNCMapsLeaflet.RemoveScripts;
begin
  {$IFDEF WEBLIB}
  asm
    if (window.L){
      window.L = undefined;
    }
  end;
  {$ENDIF}
end;

end.

