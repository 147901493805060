{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCDirections.Google;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterGoogleDirectionsService;
procedure UnRegisterGoogleDirectionsService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCDirections,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCDirectionsGoogleService = class;

  TTMSFNCDirectionsGoogleDirectionsFactoryService = class(TTMSFNCDirectionsFactoryService, ITMSFNCDirectionsServiceGoogle);

  TTMSFNCDirectionsGoogleService = class(TTMSFNCDirectionsGoogleDirectionsFactoryService)
  protected
    function DoCreateDirections: ITMSFNCCustomDirections; override;
  end;

type
  TTMSFNCDirectionsGoogleDirections = class;

  TTMSFNCDirectionsGoogleDirections = class(TTMSFNCCustomDirectionsInterfacedObject, ITMSFNCCustomDirections)
  protected
    FTravelMode: TTMSFNCDirectionsTravelMode;
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
    function GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
    function GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
    procedure Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyDirections;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  DirectionsService: ITMSFNCDirectionsServiceGoogle;

procedure RegisterGoogleDirectionsService;
begin
  if not TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceGoogle, IInterface(DirectionsService)) then
  begin
    DirectionsService := TTMSFNCDirectionsGoogleService.Create;
    TTMSFNCDirectionsPlatformServices.Current.AddPlatformService(ITMSFNCDirectionsServiceGoogle, DirectionsService);
  end;
end;

procedure UnregisterGoogleDirectionsService;
begin
  TTMSFNCDirectionsPlatformServices.Current.RemovePlatformService(ITMSFNCDirectionsServiceGoogle);
end;

{ TTMSFNCDirectionsGoogleService }

function TTMSFNCDirectionsGoogleService.DoCreateDirections: ITMSFNCCustomDirections;
begin
  Result := TTMSFNCDirectionsGoogleDirections.Create;
end;

procedure TTMSFNCDirectionsGoogleDirections.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCDirectionsGoogleDirections.Create;
begin
  inherited;
end;

destructor TTMSFNCDirectionsGoogleDirections.Destroy;
begin
  inherited;
end;

procedure TTMSFNCDirectionsGoogleDirections.DestroyDirections;
begin
  DirectionsService.DestroyDirections(Self);
end;

function TTMSFNCDirectionsGoogleDirections.GetHost: string;
begin
  Result := 'https://maps.googleapis.com';
end;

function TTMSFNCDirectionsGoogleDirections.GetIdentifier: string;
begin
  Result := 'Google';
end;

function TTMSFNCDirectionsGoogleDirections.GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
begin
  Result := '/maps/api/directions/json';
end;

function TTMSFNCDirectionsGoogleDirections.GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
begin
  Result := '';
end;

function TTMSFNCDirectionsGoogleDirections.GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
  ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
  ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
  AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
var
  TravelMode, WayPoints: string;
  I: Integer;
begin
  if ATravelMode in [tmTruck] then
    FTravelMode := tmDriving
  else
    FTravelMode := ATravelMode;

  case ATravelMode of
    tmDriving: TravelMode := '';
    tmWalking: TravelMode := 'walking';
    tmBicycling: TravelMode := 'bicycling';
    tmPublicTransport: TravelMode := 'transit';
  end;

  WayPoints := '';

  if Assigned(AWayPointList) and (AWayPointList.Count > 0) then
  begin
    WayPoints := '&waypoints=';
    WayPoints := WayPoints + 'optimize:' + LowerCase(BoolToStr(AOptimizeWayPoints, True));
    for I := 0 to (AWayPointList.Count - 1) do
      WayPoints := WayPoints + '|' + AWayPointList[I];
  end;

  if Assigned(AWaypoints) and (Length(AWayPoints) > 0) then
  begin
    WayPoints := '&waypoints=';
    WayPoints := WayPoints + 'optimize:' + LowerCase(BoolToStr(AOptimizeWayPoints, True));
    for I := 0 to (Length(AWayPoints) - 1) do
      WayPoints := WayPoints + '|' + FloatToStrDot(AWayPoints[I].Latitude) + ',' + FloatToStrDot(AWayPoints[I].Longitude);
  end;

  if AOrigin = '' then
    AOrigin := FloatToStrDot(AOriginLatitude) + ',' + FloatToStrDot(AOriginLongitude);
  if ADestination = '' then
    ADestination := FloatToStrDot(ADestinationLatitude) + ',' + FloatToStrDot(ADestinationLongitude);

  Result := 'key=' + DirectionsProperties.GetAPIKey
    + '&origin=' + AOrigin
    + '&destination=' + ADestination
    + '&alternatives=' + LowerCase(BoolToStr(AAlternatives, True))
    + '&mode=' + TravelMode
    + '&language=' + ALocale
    + WayPoints;

  if AAvoidTolls then
    Result := Result + '&avoid=tolls'
end;

function TTMSFNCDirectionsGoogleDirections.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCDirectionsGoogleDirections.IsValid: Boolean;
begin
  Result := DirectionsProperties.GetAPIKey <> '';
end;

procedure TTMSFNCDirectionsGoogleDirections.Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
var
  d: TTMSFNCDirectionsItems;
  di: TTMSFNCDirectionsItem;
  step, lstep: TTMSFNCDirectionsStep;
  wp: TTMSFNCDirectionsWayPoint;
  l: TTMSFNCDirectionsLeg;

  o, jo, jol, jos: TJSONValue;
  jar, jal, jas: TJSONArray;
  i, j, k, lm: Integer;
  op, ol, os: TJSONObject;
  wpIndex: array of integer;
begin
  if Assigned(DirectionsProperties) then
  begin
    ARequest.TravelMode := FTravelMode;

    d := ARequest.Items;
    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'status');
            ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(o, 'error_message');

            jar := TTMSFNCUtils.GetJSONValue(o, 'routes') as TJSONArray;
            if Assigned(jar) then
            begin

              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
              begin
                jo := TTMSFNCUtils.GetJSONArrayItem(jar, i);

                di := d.Add;
                di.Summary := TTMSFNCUtils.GetJSONProp(jo, 'summary');

//                ob := TTMSFNCUtils.GetJSONValue(jo, 'bounds') as TJSONObject;
//                if Assigned(ob) then
//                begin
//                  oc := TTMSFNCUtils.GetJSONValue(ob, 'northeast') as TJSONObject;
//                  if Assigned(oc) then
//                  begin
//                    di.Bounds.NorthEast.Latitude := TTMSFNCUtils.GetJSONDoubleValue(oc, 'lat');
//                    di.Bounds.NorthEast.Longitude := TTMSFNCUtils.GetJSONDoubleValue(oc, 'lng');
//                  end;
//                  oc := TTMSFNCUtils.GetJSONValue(ob, 'southwest') as TJSONObject;
//                  if Assigned(oc) then
//                  begin
//                    di.Bounds.SouthWest.Latitude := TTMSFNCUtils.GetJSONDoubleValue(oc, 'lat');
//                    di.Bounds.SouthWest.Longitude := TTMSFNCUtils.GetJSONDoubleValue(oc, 'lng');
//                  end;
//                end;

                op := TTMSFNCUtils.GetJSONValue(jo, 'overview_polyline') as TJSONObject;
                if Assigned(op) then
                  DecodeValues(TTMSFNCUtils.GetJSONProp(op, 'points'), di.Coordinates);

                SetLength(wpIndex, 1);
                wpIndex[0] := -1;

                jal := TTMSFNCUtils.GetJSONValue(jo, 'waypoint_order') as TJSONArray;
                if Assigned(jal) then
                begin
                  SetLength(wpIndex, TTMSFNCUtils.GetJSONArraySize(jal) + 1);

                  for j := 0 to TTMSFNCUtils.GetJSONArraySize(jal) - 1 do
                  begin
                    jol := TTMSFNCUtils.GetJSONArrayItem(jal, j);
                    if jol.Value <> '' then
                      wpIndex[j + 1] := StrToInt(jol.Value);
                  end;
                end;

                jal := TTMSFNCUtils.GetJSONValue(jo, 'legs') as TJSONArray;
                if Assigned(jal) then
                begin
                  for j := 0 to TTMSFNCUtils.GetJSONArraySize(jal) - 1 do
                  begin
                    jol := TTMSFNCUtils.GetJSONArrayItem(jal, j);

                    l := di.Legs.Add;

                    wp := di.WayPoints.Add;
                    if Length(wpIndex) > j then
                      wp.OptimizedIndex := wpIndex[j];

                    l.StartAddress := TTMSFNCUtils.GetJSONProp(jol, 'start_address');
                    l.EndAddress := TTMSFNCUtils.GetJSONProp(jol, 'end_address');

                    ol := TTMSFNCUtils.GetJSONValue(jol, 'distance') as TJSONObject;
                    if Assigned(ol) then
                    begin
                      l.Distance := TTMSFNCUtils.GetJSONIntegerValue(ol, 'value');
                      di.Distance := di.Distance + l.Distance;
                      wp.Distance := l.Distance;
                    end;

                    ol := TTMSFNCUtils.GetJSONValue(jol, 'duration') as TJSONObject;
                    if Assigned(ol) then
                    begin
                      l.Duration := TTMSFNCUtils.GetJSONIntegerValue(ol, 'value');
                      di.Duration := di.Duration + l.Duration;
                      wp.Duration := l.Duration;
                    end;

                    os := TTMSFNCUtils.GetJSONValue(jol, 'end_location') as TJSONObject;
                    if Assigned(os) then
                    begin
                      l.EndLocation.Latitude := TTMSFNCUtils.GetJSONDoubleValue(os, 'lat');
                      l.EndLocation.Longitude := TTMSFNCUtils.GetJSONDoubleValue(os, 'lng');
                    end;

                    os := TTMSFNCUtils.GetJSONValue(jol, 'start_location') as TJSONObject;
                    if Assigned(os) then
                    begin
                      l.StartLocation.Latitude := TTMSFNCUtils.GetJSONDoubleValue(os, 'lat');
                      l.StartLocation.Longitude := TTMSFNCUtils.GetJSONDoubleValue(os, 'lng');
                    end;

                    jas := TTMSFNCUtils.GetJSONValue(jol, 'steps') as TJSONArray;
                    if Assigned(jas) then
                    begin
                      for k := 0 to TTMSFNCUtils.GetJSONArraySize(jas) - 1 do
                      begin
                        jos := TTMSFNCUtils.GetJSONArrayItem(jas, k);

                        step := di.Steps.Add;

                        os := TTMSFNCUtils.GetJSONValue(jos, 'distance') as TJSONObject;
                        if Assigned(ol) then
                          step.Distance := TTMSFNCUtils.GetJSONIntegerValue(os, 'value');

                        os := TTMSFNCUtils.GetJSONValue(jos, 'duration') as TJSONObject;
                        if Assigned(ol) then
                          step.Duration := TTMSFNCUtils.GetJSONIntegerValue(os, 'value');

                        step.Instructions := TTMSFNCUtils.GetJSONProp(jos, 'html_instructions');

                        os := TTMSFNCUtils.GetJSONValue(jos, 'polyline') as TJSONObject;
                        if Assigned(os) then
                          DecodeValues(TTMSFNCUtils.GetJSONProp(os, 'points'), step.Coordinates);

                        for lm := 0 to step.Coordinates.Count - 1 do
                          l.Coordinates.Add.Assign(step.Coordinates[lm]);

                        os := TTMSFNCUtils.GetJSONValue(jos, 'end_location') as TJSONObject;
                        if Assigned(os) then
                        begin
                          step.EndLocation.Latitude := TTMSFNCUtils.GetJSONDoubleValue(os, 'lat');
                          step.EndLocation.Longitude := TTMSFNCUtils.GetJSONDoubleValue(os, 'lng');
                        end;

                        os := TTMSFNCUtils.GetJSONValue(jos, 'start_location') as TJSONObject;
                        if Assigned(os) then
                        begin
                          step.StartLocation.Latitude := TTMSFNCUtils.GetJSONDoubleValue(os, 'lat');
                          step.StartLocation.Longitude := TTMSFNCUtils.GetJSONDoubleValue(os, 'lng');
                        end;

                        lstep := l.Steps.Add;
                        lstep.Assign(step);
                      end;
                    end;
                  end;
                end;
              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

