{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGeocoding;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

{$IFNDEF LCLLIB}
{$HINTS OFF}
{$IF COMPILERVERSION > 23}
{$DEFINE UNREGISTER}
{$IFEND}
{$HINTS ON}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE UNREGISTER}
{$ENDIF}

interface

uses
  Classes, Types, WEBLib.Forms
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,web, Contnrs, js
  {$ENDIF}
  {$IFDEF CMNLIB}
  {$ENDIF}
  ,WEBLib.TMSFNCTypes
  ,WEBLib.TMSFNCMapsCommonTypes
  ,WEBLib.TMSFNCCloudBase;

const
  TTMSFNCGeocodingDocURL = TTMSFNCBaseDocURL + 'tmsfncmaps/components/ttmsfncmaps/#ttmsfncgeocoding';
  TTMSFNCGeocodingTipsURL = 'https://www.tmssoftware.com/site/tmsfncmaps.asp?s=faq';

  MAJ_VER = 1; // Major version nr.
  MIN_VER = 5; // Minor version nr.
  REL_VER = 3; // Release nr.
  BLD_VER = 1; // Build nr.

  //v1.0.0.0: First release
  //v1.0.1.0: New: (Reverse)Geocoding result Status and ErrorMessage added
  //v1.1.0.0: New: Here geocoding service updated to Geocoding API v1
  //v1.2.0.0: New: Optional Locale parameter added
  //          New: Province property added
  //v1.3.0.0: New: Support for OpenStreetMap Nominatim geocoding & reversegeocoding
  //v1.4.0.0: New: Support for OpenRouteService geocoding & reversegeocoding
  //          Improved: OpenStreetMap address details
  //v1.5.0.0: New: Support for GeoApify geocoding & reversegeocoding
  //v1.5.1.0: New : Added Intersection address field
  //v1.5.2.0: New: Added Precision property for Google
  //        : Fixed: Issue with returning postal code for Google
  //v1.5.2.1: Fixed: Issue with TomTom when no Locale specified
  //v1.5.2.2: Improved: OpenStreetMap error handling
  //v1.5.2.3: New: District address data added for Here maps
  //v1.5.3.0: New: StreetName property added
  //v1.5.3.1: Fixed: Issue with StreetName not properly implemented

type
  TTMSFNCCustomGeocoding = class;
  TTMSFNCGeocodingRequests = class;
  TTMSFNCGeocodingRequest = class;

  ITMSFNCCustomGeocodingProperties = interface(IInterface)
    ['{FE4F141C-69EA-4FBE-B5BF-1262F9EFBA70}']
    function GetAPIKey: string;
  end;

  ITMSFNCCustomGeocodingInstance = interface(IInterface)
    ['{25C43C12-543C-460C-A8EE-9010559E62AD}']
    procedure SetGeocodingProperties(const Value: ITMSFNCCustomGeocodingProperties);
  end;

  ITMSFNCCustomGeocoding = interface(IInterface)
  ['{FE8B5C56-279A-405B-94ED-ACDFC142CFEF}']
    function GetIdentifier: string;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetReverseHost: string;
    function GetPath(AAddress: string = ''): string;
    function GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
    function GetQuery(AAddress: string; ALocale: string): string; overload;
    function GetQuery(ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string; overload;
    function GetPostData: string;
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
    function IsValid: Boolean;
    procedure DestroyGeocoding;
  end;

  ITMSFNCGeocodingService = interface(IInterface)
  ['{7B450AC9-7EE3-4D61-AFEA-6D0D1FF2B392}']
    function CreateGeocoding: ITMSFNCCustomGeocoding;
    procedure DestroyGeocoding(AGeocoding: ITMSFNCCustomGeocoding);
  end;

  ITMSFNCGeocodingServiceGoogle = interface(ITMSFNCGeocodingService)
    ['{88D1B5B9-DA7F-44EB-97C7-93C5EBDDEA83}']
  end;

  ITMSFNCGeocodingServiceHere = interface(ITMSFNCGeocodingService)
    ['{C08CBDEE-6884-4C81-A241-3B8E8EE1D6F2}']
  end;

  ITMSFNCGeocodingServiceBing = interface(ITMSFNCGeocodingService)
    ['{E03F86CE-3E36-43D9-AD79-4D9D1BFB5C22}']
  end;

  ITMSFNCGeocodingServiceAzure = interface(ITMSFNCGeocodingService)
    ['{541F996E-F3A7-4750-8E12-D95B091DB936}']
  end;

  ITMSFNCGeocodingServiceMapBox = interface(ITMSFNCGeocodingService)
    ['{496E6828-08F0-4E28-AB11-577069815E99}']
  end;

  ITMSFNCGeocodingServiceTomTom = interface(ITMSFNCGeocodingService)
    ['{95D7749D-DA80-45D3-B0E5-D21E7654FABA}']
  end;

  ITMSFNCGeocodingServiceOpenStreetMap = interface(ITMSFNCGeocodingService)
    ['{E2D4A08F-03F2-4A23-83AA-27E599E01C5A}']
  end;

  ITMSFNCGeocodingServiceOpenRouteService = interface(ITMSFNCGeocodingService)
    ['{CE13AC60-6343-4A53-8577-4A11D98F9136}']
  end;

  ITMSFNCGeocodingServiceGeoApify = interface(ITMSFNCGeocodingService)
    ['{BEAAE65C-C97B-4668-8C94-413F8C7987B8}']
  end;

  TTMSFNCGeocodingService = (gsGoogle, gsHere, gsBing, gsAzure, gsMapBox, gsTomTom, gsOpenStreetMap, gsOpenRouteService, gsGeoApify);
  TTMSFNCGeocodingPrecision = (gpRoofTop, gpRangeInterpolated, gpGeometricCenter, gpApproximate, gpUnknown);

  TTMSFNCGeocodingItem = class(TCollectionItem)
  private
    FOwner: TTMSFNCCustomGeocoding;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FAddress: string;
    FCoordinate: TTMSFNCMapsCoordinate;
    FStreet: string;
    FPostalCode: string;
    FCountry: string;
    FCountryCode: string;
    FRegion: string;
    FCity: string;
    FProvince: string;
    FProvinceCode: string;
    FRegionCode: string;
    FDistrict: string;
    FStreetNumber: string;
    FIntersection: string;
    FPrecision: TTMSFNCGeocodingPrecision;
    FStreetName: string;
    procedure SetCoordinate(const Value: TTMSFNCMapsCoordinate);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
  published
    property Coordinate: TTMSFNCMapsCoordinate read FCoordinate write SetCoordinate;
    property Address: string read FAddress write FAddress;
    property City: string read FCity write FCity;
    property District: string read FDistrict write FDistrict;
    property Country: string read FCountry write FCountry;
    property CountryCode: string read FCountryCode write FCountryCode;
    property Province: string read FProvince write FProvince;
    property ProvinceCode: string read FProvinceCode write FProvinceCode;
    property Region: string read FRegion write FRegion;
    property RegionCode: string read FRegionCode write FRegionCode;
    property Street: string read FStreet write FStreet;
    property StreetName: string read FStreetName write FStreetName;
    property StreetNumber: string read FStreetNumber write FStreetNumber;
    property PostalCode: string read FPostalCode write FPostalCode;
    property Intersection: string read FIntersection write FIntersection;
    property Precision: TTMSFNCGeocodingPrecision read FPrecision write FPrecision;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGeocodingItems = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCGeocodingItems = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCGeocodingItem>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomGeocoding;
    function GetItem(Index: Integer): TTMSFNCGeocodingItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGeocodingItem);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomGeocoding); virtual;
    property Items[Index: Integer]: TTMSFNCGeocodingItem read GetItem write SetItem; default;
    function Add: TTMSFNCGeocodingItem;
    function Insert(Index: Integer): TTMSFNCGeocodingItem;
  end;

  TTMSFNCGeocodingRequest = class(TCollectionItem)
  private
    FOwner: TTMSFNCCustomGeocoding;
    FDataPointer: Pointer;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FItems: TTMSFNCGeocodingItems;
    FID: string;
    FStatus: string;
    FErrorMessage: string;
    procedure SetItems(const Value: TTMSFNCGeocodingItems);
    procedure SetErrorMessage(const Value: string);
    procedure SetStatus(const Value: string);
    function GetFirst: TTMSFNCGeocodingItem;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property First: TTMSFNCGeocodingItem read GetFirst;
  published
    property ID: string read FID;
    property Items: TTMSFNCGeocodingItems read FItems write SetItems;
    property Status: string read FStatus write SetStatus;
    property ErrorMessage: string read FErrorMessage write SetErrorMessage;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGeocodingRequests = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCGeocodingRequests = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCGeocodingRequest>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomGeocoding;
    function GetItem(Index: Integer): TTMSFNCGeocodingRequest;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGeocodingRequest);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomGeocoding); virtual;
    property Items[Index: Integer]: TTMSFNCGeocodingRequest read GetItem write SetItem; default;
    function Add: TTMSFNCGeocodingRequest;
    function Insert(Index: Integer): TTMSFNCGeocodingRequest;
  end;

  { TTMSFNCCustomGeocoding }

  TTMSFNCGeocodingGetGeocodingCallBack = {$IFNDEF LCLLIB}reference to {$ENDIF}procedure(const ARequest: TTMSFNCGeocodingRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult){$IFDEF LCLLIB} of object{$ENDIF};
  TTMSFNCGeocodingGetGeocodingEvent = procedure(Sender: TObject; const ARequest: TTMSFNCGeocodingRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult) of object;

  TTMSFNCGeocodingGetGeocodingResultCallBack = {$IFNDEF LCLLIB}reference to {$ENDIF}procedure(const AResult: TTMSFNCGeocodingRequest){$IFDEF LCLLIB} of object{$ENDIF};
  TTMSFNCGeocodingGetGeocodingResultEvent = procedure(Sender: TObject; const AResult: TTMSFNCGeocodingRequest) of object;

  TTMSFNCGeocodingGetReverseGeocodingCallBack = {$IFNDEF LCLLIB}reference to {$ENDIF}procedure(const ARequest: TTMSFNCGeocodingRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult){$IFDEF LCLLIB} of object{$ENDIF};
  TTMSFNCGeocodingGetReverseGeocodingEvent = procedure(Sender: TObject; const ARequest: TTMSFNCGeocodingRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult) of object;

  TTMSFNCGeocodingGetReverseGeocodingResultCallBack = {$IFNDEF LCLLIB}reference to {$ENDIF}procedure(const AResult: TTMSFNCGeocodingRequest){$IFDEF LCLLIB} of object{$ENDIF};
  TTMSFNCGeocodingGetReverseGeocodingResultEvent = procedure(Sender: TObject; const AResult: TTMSFNCGeocodingRequest) of object;

  TTMSFNCGeocodingCallBackWrapper = class
  private
    FCallback: TTMSFNCGeocodingGetGeocodingCallBack;
  public
    constructor Create(ACallback: TTMSFNCGeocodingGetGeocodingCallBack);
  end;

  TTMSFNCGeocodingResultCallBackWrapper = class
  private
    FCallback: TTMSFNCGeocodingGetGeocodingResultCallBack;
  public
    constructor Create(ACallback: TTMSFNCGeocodingGetGeocodingResultCallBack);
  end;

  TTMSFNCReverseGeocodingCallBackWrapper = class
  private
    FCallback: TTMSFNCGeocodingGetReverseGeocodingCallBack;
  public
    constructor Create(ACallback: TTMSFNCGeocodingGetReverseGeocodingCallBack);
  end;

  TTMSFNCReverseGeocodingResultCallBackWrapper = class
  private
    FCallback: TTMSFNCGeocodingGetReverseGeocodingResultCallBack;
  public
    constructor Create(ACallback: TTMSFNCGeocodingGetReverseGeocodingResultCallBack);
  end;

  TTMSFNCCustomGeocoding = class(TTMSFNCCloudBase, ITMSFNCCustomGeocodingProperties)
  private
    FGeocoding: ITMSFNCCustomGeocoding;
    FGeocodingInstance: ITMSFNCCustomGeocodingInstance;
    FGeocodingProperties: ITMSFNCCustomGeocodingProperties;
    FService: TTMSFNCGeocodingService;
    FAPIKey: string;
    FGeocodingRequests: TTMSFNCGeocodingRequests;
    FOnGetGeocoding: TTMSFNCGeocodingGetGeocodingEvent;
    FOnGetGeocodingInternal: TTMSFNCGeocodingGetGeocodingEvent;
    FOnGetReverseGeocodingInternal: TTMSFNCGeocodingGetReverseGeocodingEvent;
    FOnGetReverseGeocoding: TTMSFNCGeocodingGetReverseGeocodingEvent;
    FOnGetGeocodingResult: TTMSFNCGeocodingGetGeocodingResultEvent;
    FOnGetReverseGeocodingResultInternal: TTMSFNCGeocodingGetReverseGeocodingResultEvent;
    FOnGetReverseGeocodingResult: TTMSFNCGeocodingGetReverseGeocodingResultEvent;
    FOnGetGeocodingResultInternal: TTMSFNCGeocodingGetGeocodingResultEvent;
    procedure SetService(const Value: TTMSFNCGeocodingService);
    procedure SetAPIKey(const Value: string);
    procedure SetGeocodingRequests(const Value: TTMSFNCGeocodingRequests);
  protected
    procedure InternalGetGeocoding(AAddress: string; ACallback: TTMSFNCGeocodingGetGeocodingCallBack = nil; ACallback2: TTMSFNCGeocodingGetGeocodingResultCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault); overload; virtual;
    procedure InternalGetReverseGeocoding(ACoordinate: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCGeocodingGetReverseGeocodingCallBack = nil; ACallback2: TTMSFNCGeocodingGetReverseGeocodingResultCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault); overload; virtual;

    function InternalGetGeocodingSync(AAddress: string; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault): {$IFDEF WEBLIB}TJSPromise{$ELSE}TTMSFNCMapsCoordinateRec{$ENDIF}; virtual;
    function InternalGetReverseGeocodingSync(ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault): {$IFDEF WEBLIB}TJSPromise{$ELSE}string{$ENDIF}; virtual;
    procedure DoRequestGetGeocoding(const ARequestResult: TTMSFNCCloudBaseRequestResult);
    procedure DoGetGeocoding(const ARequest: TTMSFNCGeocodingRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult);
    procedure DoGetReverseGeocoding(const ARequest: TTMSFNCGeocodingRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult);
    property OnGetGeocoding: TTMSFNCGeocodingGetGeocodingEvent read FOnGetGeocoding write FOnGetGeocoding;
    property OnGetGeocodingInternal: TTMSFNCGeocodingGetGeocodingEvent read FOnGetGeocodingInternal write FOnGetGeocodingInternal;
    property OnGetReverseGeocoding: TTMSFNCGeocodingGetReverseGeocodingEvent read FOnGetReverseGeocoding write FOnGetReverseGeocoding;
    property OnGetReverseGeocodingInternal: TTMSFNCGeocodingGetReverseGeocodingEvent read FOnGetReverseGeocodingInternal write FOnGetReverseGeocodingInternal;

    property OnGetGeocodingResult: TTMSFNCGeocodingGetGeocodingResultEvent read FOnGetGeocodingResult write FOnGetGeocodingResult;
    property OnGetGeocodingResultInternal: TTMSFNCGeocodingGetGeocodingResultEvent read FOnGetGeocodingResultInternal write FOnGetGeocodingResultInternal;
    property OnGetReverseGeocodingResult: TTMSFNCGeocodingGetReverseGeocodingResultEvent read FOnGetReverseGeocodingResult write FOnGetReverseGeocodingResult;
    property OnGetReverseGeocodingResultInternal: TTMSFNCGeocodingGetReverseGeocodingResultEvent read FOnGetReverseGeocodingResultInternal write FOnGetReverseGeocodingResultInternal;

    procedure InitializeGeocoding; virtual;
    function GetInstance: NativeUInt; override;
    function GetGeocodingRequests: TTMSFNCGeocodingRequests;
    function GeocodingReady: Boolean;
    function GetAPIKey: string;
    function GetVersionNr: Integer;
    function GetDocURL: string; override;
    function GetTipsURL: string; override;
    function Geocoding: ITMSFNCCustomGeocoding;
    function GetVersion: string; override;
    property Service: TTMSFNCGeocodingService read FService write SetService default gsGoogle;
    property APIKey: string read FAPIKey write SetAPIKey;
    property GeocodingRequests: TTMSFNCGeocodingRequests read FGeocodingRequests write SetGeocodingRequests;

    property GeocodingInstance: ITMSFNCCustomGeocoding read FGeocoding;
    property GeocodingProperties: ITMSFNCCustomGeocodingProperties read FGeocodingProperties;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure GetGeocoding(AAddress: string; ACallback: TTMSFNCGeocodingGetGeocodingCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault); overload; virtual;
    procedure GetReverseGeocoding(ACoordinate: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCGeocodingGetReverseGeocodingCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault); overload; virtual;
    procedure GetGeocodingResult(AAddress: string; ACallback: TTMSFNCGeocodingGetGeocodingResultCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault); overload; virtual;
    procedure GetReverseGeocodingResult(ACoordinate: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCGeocodingGetReverseGeocodingResultCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault); overload; virtual;
    function GetGeocodingSync(AAddress: string; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault): TTMSFNCMapsCoordinateRec; virtual; {$IFDEF WEBLIB}async;{$ENDIF}
    function GetReverseGeocodingSync(ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault): string; virtual; {$IFDEF WEBLIB} async;{$ENDIF}
  end;

  TTMSFNCCustomGeocodingInterfacedObject = class(TInterfacedObject, ITMSFNCCustomGeocodingInstance)
  private
    FGeocodingProperties: ITMSFNCCustomGeocodingProperties;
    function GetGeocodingProperties: ITMSFNCCustomGeocodingProperties;
  protected
    procedure SetGeocodingProperties(const Value: ITMSFNCCustomGeocodingProperties);
  public
    property GeocodingProperties: ITMSFNCCustomGeocodingProperties read GetGeocodingProperties;
    destructor Destroy; override;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGeocodingList = class(TList)
  private
    function GetItem(Index: Integer): ITMSFNCCustomGeocoding;
    procedure SetItem(Index: Integer; const Value: ITMSFNCCustomGeocoding);
  public
    property Items[Index: Integer]: ITMSFNCCustomGeocoding read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCGeocodingList = class(TList<ITMSFNCCustomGeocoding>);
  {$ENDIF}

  TTMSFNCGeocodingFactoryService = class(TInterfacedObject, ITMSFNCGeocodingService)
  private
    FGeocoding: TTMSFNCGeocodingList;
  protected
    function DoCreateGeocoding: ITMSFNCCustomGeocoding; virtual; abstract;
    function CreateGeocoding: ITMSFNCCustomGeocoding;
    procedure DestroyGeocoding(AGeocoding: ITMSFNCCustomGeocoding);
  public
    constructor Create;
    destructor Destroy; override;
  end;

  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  TTMSFNCGeocoding = class(TTMSFNCCustomGeocoding)
  protected
    procedure RegisterRuntimeClasses; override;
  public
    property GeocodingInstance;
    property GeocodingProperties;
  published
    property OnGetReverseGeocoding;
    property OnGetGeocoding;
    property OnGetReverseGeocodingResult;
    property OnGetGeocodingResult;
    property APIKey;
    property GeocodingRequests;
    property Service;
    property Version;
  end;

  TTMSFNCGeocodingPlatformServicesService = class
  private
    FInterface: IInterface;
    FGUID: string;
  public
    constructor Create(AGUID: string; AInterface: IInterface);
    property GUID: string read FGUID;
    property &Interface: IInterface read FInterface;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGeocodingPlatformServicesList = class(TObjectList)
  private
    function GetItem(Index: Integer): TTMSFNCGeocodingPlatformServicesService;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGeocodingPlatformServicesService);
  public
    property Items[Index: Integer]: TTMSFNCGeocodingPlatformServicesService read GetItem write SetItem; default;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCGeocodingPlatformServicesList = class(TObjectList<TTMSFNCGeocodingPlatformServicesService>)
  {$ENDIF}
  private
    function GetValue(AGUID: string): IInterface;
  public
    function ContainsKey(AGUID: string): Boolean;
    procedure RemoveByGUID(AGUID: string);
    property Interfaces[AGUID: string]: IInterface read GetValue;
  end;

  TTMSFNCGeocodingPlatformServices = class
  private
    FServicesList: TTMSFNCGeocodingPlatformServicesList;
    class var FCurrent: TTMSFNCGeocodingPlatformServices;
    class var FCurrentReleased: Boolean;
{$IFNDEF AUTOREFCOUNT}
    class procedure ReleaseCurrent;
{$ENDIF}
  public
    constructor Create;
    destructor Destroy; override;
    procedure AddPlatformService(const AServiceGUID: TGUID; const AService: IInterface);
    procedure RemovePlatformService(const AServiceGUID: TGUID);
    function GetPlatformService(const AServiceGUID: TGUID): IInterface;
    function SupportsPlatformService(const AServiceGUID: TGUID): Boolean; overload;
    function SupportsPlatformService(const AServiceGUID: TGUID; var AService: IInterface): Boolean; overload;
    class function Current: TTMSFNCGeocodingPlatformServices;
  end;

implementation

uses
  {$IFDEF MSWINDOWS}
  Windows, Registry,
  {$ENDIF}
  {%H-}Math,
  WEBLib.TMSFNCUtils,
  WEBLib.TMSFNCGeocoding.Google,
  WEBLib.TMSFNCGeocoding.Here,
  WEBLib.TMSFNCGeocoding.Bing,
  WEBLib.TMSFNCGeocoding.Azure,
  WEBLib.TMSFNCGeocoding.MapBox,
  WEBLib.TMSFNCGeocoding.TomTom,
  WEBLib.TMSFNCGeocoding.OpenStreetMap,
  WEBLib.TMSFNCGeocoding.OpenRouteService,
  WEBLib.TMSFNCGeocoding.GeoApify,
  SysUtils
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  ;

{ TTMSFNCCustomGeocoding }

function TTMSFNCCustomGeocoding.GetAPIKey: string;
begin
  Result := APIKey;
end;

function TTMSFNCCustomGeocoding.GetDocURL: string;
begin
  Result := TTMSFNCGeocodingDocURL;
end;

function TTMSFNCCustomGeocoding.GetVersion: string;
var
  vn: Integer;
begin
  vn := GetVersionNr;
  Result := IntToStr(Hi(Hiword(vn)))+'.'+IntToStr(Lo(Hiword(vn)))+'.'+IntToStr(Hi(Loword(vn)))+'.'+IntToStr(Lo(Loword(vn)));
end;

function TTMSFNCCustomGeocoding.GetVersionNr: Integer;
begin
  Result := MakeLong(MakeWord(BLD_VER,REL_VER),MakeWord(MIN_VER,MAJ_VER));
end;

procedure TTMSFNCCustomGeocoding.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TTMSFNCCustomGeocoding) then
  begin
    FService := (Source as TTMSFNCCustomGeocoding).Service;
    FAPIKey := (Source as TTMSFNCCustomGeocoding).APIKey;
    FGeocodingRequests.Assign((Source as TTMSFNCCustomGeocoding).GeocodingRequests);
  end;
end;

procedure TTMSFNCCustomGeocoding.GetGeocoding(AAddress: string;
  ACallback: TTMSFNCGeocodingGetGeocodingCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil;
  ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault);
begin
  InternalGetGeocoding(AAddress, ACallBack, nil, AID, ADataPointer, ALocale, AMode);
end;

procedure TTMSFNCCustomGeocoding.InternalGetGeocoding(AAddress: string;
  ACallback: TTMSFNCGeocodingGetGeocodingCallBack = nil; ACallback2: TTMSFNCGeocodingGetGeocodingResultCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil;
  ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault);
begin
  if not GeocodingReady then
  begin
    raise Exception.Create('Please set API key!');
    Exit;
  end;

  Request.Clear;
  Request.ClearHeaders;
  FGeocoding.AddHeaders(Request.Headers);
  Request.Name := 'GET GEOCODING';
  Request.DataString := AID;
  Request.DataPointer := ADataPointer;

  if Assigned(ACallback) then
    Request.DataObject := TTMSFNCGeocodingCallBackWrapper.Create(ACallback)
  else if Assigned(ACallback2) then
    Request.DataObject := TTMSFNCGeocodingResultCallBackWrapper.Create(ACallback2);

  Request.Method := FGeocoding.GetRequestMethod;
  Request.Host := FGeocoding.GetHost;
  Request.Path := FGeocoding.GetPath(TTMSFNCUtils.URLEncode(AAddress));
  Request.Query := FGeocoding.GetQuery(TTMSFNCUtils.URLEncode(AAddress), ParseLocale(ALocale, AMode));
  Request.PostData := FGeocoding.GetPostData;

  ExecuteRequest({$IFDEF LCLWEBLIB}@{$ENDIF}DoRequestGetGeocoding);
end;

procedure TTMSFNCCustomGeocoding.GetGeocodingResult(AAddress: string;
  ACallback: TTMSFNCGeocodingGetGeocodingResultCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil;
  ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault);
begin
  InternalGetGeocoding(AAddress, nil, ACallBack, AID, ADataPointer, ALocale, AMode);
end;

function TTMSFNCCustomGeocoding.GetGeocodingRequests: TTMSFNCGeocodingRequests;
begin
  Result := GeocodingRequests;
end;

function TTMSFNCCustomGeocoding.GetGeocodingSync(AAddress, ALocale: string;
  AMode: TTMSFNCMapsLocaleMode): TTMSFNCMapsCoordinateRec; {$IFDEF WEBLIB}async;{$ENDIF}
begin
  Result := {$IFDEF WEBLIB}await(TTMSFNCMapsCoordinateRec,{$ENDIF} InternalGetGeocodingSync(AAddress, ALocale, AMode){$IFDEF WEBLIB}){$ENDIF};
end;

function TTMSFNCCustomGeocoding.InternalGetGeocodingSync(AAddress, ALocale: string;
  AMode: TTMSFNCMapsLocaleMode): {$IFDEF WEBLIB}TJSPromise{$ELSE}TTMSFNCMapsCoordinateRec{$ENDIF};
var
  req: TTMSFNCGeocodingRequest;
begin
  if not GeocodingReady then
  begin
    raise Exception.Create('Please set API key!');
    Exit;
  end;

  {$IFDEF WEBLIB}
  Result := TJSPromise.New(
    procedure(AResolve, AReject : TJSPromiseResolver)
    begin
      GetGeocoding(AAddress,
      procedure(const ARequest: TTMSFNCGeocodingRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult)
      var
        c: TTMSFNCMapsCoordinateRec;
      begin
        c := EmptyCoordinate;
        if ARequest.Items.Count > 0 then
          c := ARequest.Items[0].Coordinate.ToRec;

        AResolve(c);
      end, '', nil, ALocale, AMode);
    end);
  {$ELSE}
  Result := EmptyCoordinate;
  Request.Clear;
  Request.ClearHeaders;
  FGeocoding.AddHeaders(Request.Headers);
  Request.Name := 'GET GEOCODING SYNC';

  Request.Method := FGeocoding.GetRequestMethod;
  Request.Host := FGeocoding.GetHost;
  Request.Path := FGeocoding.GetPath(TTMSFNCUtils.URLEncode(AAddress));
  Request.Query := FGeocoding.GetQuery(TTMSFNCUtils.URLEncode(AAddress), ParseLocale(ALocale, AMode));
  Request.PostData := FGeocoding.GetPostData;

  ExecuteRequest(nil, nil, False);
  if Assigned(RequestResult) then
  begin
    req := GeocodingRequests.Add;
    FGeocoding.Parse(req, RequestResult.ResultString);
    DoGetGeocoding(req, RequestResult);
    if req.Items.Count > 0 then
      Result := req.Items[0].Coordinate.ToRec;
  end;
  {$ENDIF}
end;

function TTMSFNCCustomGeocoding.GetInstance: NativeUInt;
begin
  Result := HInstance;
end;

procedure TTMSFNCCustomGeocoding.GetReverseGeocoding(ACoordinate: TTMSFNCMapsCoordinateRec;
  ACallback: TTMSFNCGeocodingGetReverseGeocodingCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil;
  ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault);
begin
  InternalGetReverseGeocoding(ACoordinate, ACallback, nil, AID, ADataPointer, ALocale, AMode);
end;

procedure TTMSFNCCustomGeocoding.InternalGetReverseGeocoding(ACoordinate: TTMSFNCMapsCoordinateRec;
  ACallback: TTMSFNCGeocodingGetReverseGeocodingCallBack = nil; ACallback2: TTMSFNCGeocodingGetReverseGeocodingResultCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil;
  ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault);
begin
  if not GeocodingReady then
  begin
    raise Exception.Create('Please set API key!');
    Exit;
  end;

  Request.Clear;
  Request.ClearHeaders;
  FGeocoding.AddHeaders(Request.Headers);
  Request.Name := 'GET REVERSEGEOCODING';
  Request.DataString := AID;
  Request.DataPointer := ADataPointer;
  Request.DataBoolean := True;

  if Assigned(ACallback) then
    Request.DataObject := TTMSFNCReverseGeocodingCallBackWrapper.Create(ACallback)
  else if Assigned(ACallback2) then
    Request.DataObject := TTMSFNCReverseGeocodingResultCallBackWrapper.Create(ACallback2);

  Request.Method := FGeocoding.GetRequestMethod;
  Request.Host := FGeocoding.GetReverseHost;
  Request.Path := FGeocoding.GetReversePath(ACoordinate);
  Request.Query := FGeocoding.GetQuery(ACoordinate, ParseLocale(ALocale, AMode));
  Request.PostData := FGeocoding.GetPostData;
  ExecuteRequest({$IFDEF LCLWEBLIB}@{$ENDIF}DoRequestGetGeocoding);
end;

procedure TTMSFNCCustomGeocoding.GetReverseGeocodingResult(ACoordinate: TTMSFNCMapsCoordinateRec;
  ACallback: TTMSFNCGeocodingGetReverseGeocodingResultCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil;
  ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault);
begin
  InternalGetReverseGeocoding(ACoordinate, nil, ACallback, AID, ADataPointer, ALocale, AMode);
end;

function TTMSFNCCustomGeocoding.GetReverseGeocodingSync(
  ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string;
  AMode: TTMSFNCMapsLocaleMode): string;{$IFDEF WEBLIB}async;{$ENDIF}
begin
  Result := {$IFDEF WEBLIB}await(string,{$ENDIF} InternalGetReverseGeocodingSync(ACoordinate, ALocale, AMode){$IFDEF WEBLIB}){$ENDIF};
end;

function TTMSFNCCustomGeocoding.InternalGetReverseGeocodingSync(
  ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string;
  AMode: TTMSFNCMapsLocaleMode): {$IFDEF WEBLIB}TJSPromise{$ELSE}string{$ENDIF};
var
  req: TTMSFNCGeocodingRequest;
begin
  if not GeocodingReady then
  begin
    raise Exception.Create('Please set API key!');
    Exit;
  end;

  {$IFDEF WEBLIB}
  Result := TJSPromise.New(
    procedure(AResolve, AReject : TJSPromiseResolver)
    begin
      GetReverseGeocoding(ACoordinate,
      procedure(const ARequest: TTMSFNCGeocodingRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult)
      var
        s: string;
      begin
        s := '';
        if ARequest.Items.Count > 0 then
          s := ARequest.Items[0].Address;

        AResolve(s);
      end, '', nil, ALocale, AMode);
    end);

  {$ELSE}
  Result := '';
  Request.Clear;
  Request.ClearHeaders;
  FGeocoding.AddHeaders(Request.Headers);
  Request.Name := 'GET REVERSEGEOCODING SYNC';

  Request.Method := FGeocoding.GetRequestMethod;
  Request.Host := FGeocoding.GetReverseHost;
  Request.Path := FGeocoding.GetReversePath(ACoordinate);
  Request.Query := FGeocoding.GetQuery(ACoordinate, ParseLocale(ALocale, AMode));
  Request.PostData := FGeocoding.GetPostData;
  ExecuteRequest(nil, nil, False);
  if Assigned(RequestResult) then
  begin
    req := GeocodingRequests.Add;
    FGeocoding.Parse(req, RequestResult.ResultString);
    DoGetReverseGeocoding(req, RequestResult);
    if req.Items.Count > 0 then
      Result := req.Items[0].Address;
  end;
  {$ENDIF}
end;

function TTMSFNCCustomGeocoding.GetTipsURL: string;
begin
  Result := TTMSFNCGeocodingTipsURL;
end;

constructor TTMSFNCCustomGeocoding.Create(AOwner: TComponent);
begin
  inherited;
  Supports(Self, ITMSFNCCustomGeocodingProperties, FGeocodingProperties);
  FGeocodingRequests := TTMSFNCGeocodingRequests.Create(Self);
  InitializeGeocoding;
end;

procedure TTMSFNCCustomGeocoding.InitializeGeocoding;
var
  GeocodingServiceGoogleGeocoding: ITMSFNCGeocodingServiceGoogle;
  GeocodingServiceHereGeocoding: ITMSFNCGeocodingServiceHere;
  GeocodingServiceBingGeocoding: ITMSFNCGeocodingServiceBing;
  GeocodingServiceAzureGeocoding: ITMSFNCGeocodingServiceAzure;
  GeocodingServiceMapBoxGeocoding: ITMSFNCGeocodingServiceMapBox;
  GeocodingServiceTomTomGeocoding: ITMSFNCGeocodingServiceTomTom;
  GeocodingServiceOpenStreetMapGeocoding: ITMSFNCGeocodingServiceOpenStreetMap;
  GeocodingServiceOpenRouteServiceGeocoding: ITMSFNCGeocodingServiceOpenRouteService;
  GeocodingServiceGeoApifyGeocoding: ITMSFNCGeocodingServiceGeoApify;
begin
  if IsDestroying then
    Exit;

  if Assigned(FGeocoding) then
    FGeocoding.DestroyGeocoding;

  FGeocoding := nil;
  FGeocodingInstance := nil;

  case Service of
    gsGoogle:
    begin
      if TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceGoogle, IInterface(GeocodingServiceGoogleGeocoding)) then
        FGeocoding := GeocodingServiceGoogleGeocoding.CreateGeocoding;
    end;
    gsHere:
    begin
      if TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceHere, IInterface(GeocodingServiceHereGeocoding)) then
        FGeocoding := GeocodingServiceHereGeocoding.CreateGeocoding;
    end;
    gsBing:
    begin
      if TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceBing, IInterface(GeocodingServiceBingGeocoding)) then
        FGeocoding := GeocodingServiceBingGeocoding.CreateGeocoding;
    end;
    gsAzure:
    begin
      if TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceAzure, IInterface(GeocodingServiceAzureGeocoding)) then
        FGeocoding := GeocodingServiceAzureGeocoding.CreateGeocoding;
    end;
    gsMapBox:
    begin
      if TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceMapBox, IInterface(GeocodingServiceMapBoxGeocoding)) then
        FGeocoding := GeocodingServiceMapBoxGeocoding.CreateGeocoding;
    end;
    gsTomTom:
    begin
      if TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceTomTom, IInterface(GeocodingServiceTomTomGeocoding)) then
        FGeocoding := GeocodingServiceTomTomGeocoding.CreateGeocoding;
    end;
    gsOpenStreetMap:
    begin
      if TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceOpenStreetMap, IInterface(GeocodingServiceOpenStreetMapGeocoding)) then
        FGeocoding := GeocodingServiceOpenStreetMapGeocoding.CreateGeocoding;
    end;
    gsOpenRouteService:
    begin
      if TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceOpenRouteService, IInterface(GeocodingServiceOpenRouteServiceGeocoding)) then
        FGeocoding := GeocodingServiceOpenRouteServiceGeocoding.CreateGeocoding;
    end;
    gsGeoApify:
    begin
      if TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceGeoApify, IInterface(GeocodingServiceGeoApifyGeocoding)) then
        FGeocoding := GeocodingServiceGeoApifyGeocoding.CreateGeocoding;
    end;
  end;

  if Assigned(FGeocoding) and Supports(FGeocoding, ITMSFNCCustomGeocodingInstance, FGeocodingInstance) then
    FGeocodingInstance.SetGeocodingProperties(FGeocodingProperties);
end;

function TTMSFNCCustomGeocoding.GeocodingReady: Boolean;
begin
  Result := Assigned(FGeocoding) and Assigned(FGeocodingInstance) and Assigned(FGeocodingProperties) and FGeocoding.IsValid;
end;

procedure TTMSFNCCustomGeocoding.DoGetReverseGeocoding(const ARequest: TTMSFNCGeocodingRequest;
  const ARequestResult: TTMSFNCCloudBaseRequestResult);
begin
  if Assigned(OnGetReverseGeocodingInternal) then
    OnGetReverseGeocodingInternal(Self, ARequest, ARequestResult);
  if Assigned(OnGetReverseGeocoding) then
    OnGetReverseGeocoding(Self, ARequest, ARequestResult);
  if Assigned(OnGetReverseGeocodingResultInternal) then
    OnGetReverseGeocodingResultInternal(Self, ARequest);
  if Assigned(OnGetReverseGeocodingResult) then
    OnGetReverseGeocodingResult(Self, ARequest);
end;

procedure TTMSFNCCustomGeocoding.DoGetGeocoding(const ARequest: TTMSFNCGeocodingRequest;
  const ARequestResult: TTMSFNCCloudBaseRequestResult);
begin
  if Assigned(OnGetGeocodingInternal) then
    OnGetGeocodingInternal(Self, ARequest, ARequestResult);
  if Assigned(OnGetGeocoding) then
    OnGetGeocoding(Self, ARequest, ARequestResult);
  if Assigned(OnGetGeocodingResultInternal) then
    OnGetGeocodingResultInternal(Self, ARequest);
  if Assigned(OnGetGeocodingResult) then
    OnGetGeocodingResult(Self, ARequest);
end;

procedure TTMSFNCCustomGeocoding.DoRequestGetGeocoding(
  const ARequestResult: TTMSFNCCloudBaseRequestResult);
var
  req: TTMSFNCGeocodingRequest;
  obj: TTMSFNCGeocodingCallBackWrapper;
  obj2: TTMSFNCGeocodingResultCallBackWrapper;
  obj3: TTMSFNCReverseGeocodingResultCallBackWrapper;
begin
  if not GeocodingReady then
    Exit;

  //if ARequestResult.Success then
  //if there is an error, trigger the event and parse the error message instead
  begin
    req := GeocodingRequests.Add;
    req.FID := ARequestResult.DataString;
    req.DataPointer := ARequestResult.DataPointer;
    FGeocoding.Parse(req, ARequestResult.ResultString);

    if not ARequestResult.DataBoolean then
      DoGetGeocoding(req, ARequestResult)
    else
      DoGetReverseGeocoding(req, ARequestResult);

    if Assigned(ARequestResult.DataObject) then
    begin
      if ARequestResult.DataObject is TTMSFNCGeocodingCallBackWrapper then
      begin
        obj := TTMSFNCGeocodingCallBackWrapper(ARequestResult.DataObject);
        obj.FCallback(req, ARequestResult);
        obj.Free;
      end
      else if ARequestResult.DataObject is TTMSFNCGeocodingResultCallBackWrapper then
      begin
        obj2 := TTMSFNCGeocodingResultCallBackWrapper(ARequestResult.DataObject);
        obj2.FCallback(req);
        obj2.Free;
      end
      else if ARequestResult.DataObject is TTMSFNCReverseGeocodingCallBackWrapper then
      begin
        obj3 := TTMSFNCReverseGeocodingResultCallBackWrapper(ARequestResult.DataObject);
        obj3.FCallback(req);
        obj3.Free;
      end
      else if ARequestResult.DataObject is TTMSFNCReverseGeocodingResultCallBackWrapper then
      begin
        obj3 := TTMSFNCReverseGeocodingResultCallBackWrapper(ARequestResult.DataObject);
        obj3.FCallback(req);
        obj3.Free;
      end;
    end;
  end;
end;

function TTMSFNCCustomGeocoding.Geocoding: ITMSFNCCustomGeocoding;
begin
  Result := FGeocoding;
end;

procedure TTMSFNCCustomGeocoding.SetAPIKey(const Value: string);
begin
  if FAPIKey <> Value then
    FAPIKey := Value;
end;

procedure TTMSFNCCustomGeocoding.SetService(const Value: TTMSFNCGeocodingService);
begin
  if FService <> Value then
  begin
    FService := Value;
    InitializeGeocoding;
  end;
end;

destructor TTMSFNCCustomGeocoding.Destroy;
begin
  FGeocodingRequests.Free;

  if Assigned(FGeocoding) then
    FGeocoding.DestroyGeocoding;

  FGeocoding := nil;
  FGeocodingInstance := nil;
  FGeocodingProperties := nil;
  inherited;
end;

procedure TTMSFNCCustomGeocoding.SetGeocodingRequests(const Value: TTMSFNCGeocodingRequests);
begin
  FGeocodingRequests.Assign(Value);
end;

{ TTMSFNCGeocodingFactoryService }

constructor TTMSFNCGeocodingFactoryService.Create;
begin
  inherited Create;
  FGeocoding := TTMSFNCGeocodingList.Create;
end;

function TTMSFNCGeocodingFactoryService.CreateGeocoding: ITMSFNCCustomGeocoding;
begin
  Result := DoCreateGeocoding;
  FGeocoding.Add(Result);
end;

destructor TTMSFNCGeocodingFactoryService.Destroy;
begin
  FreeAndNil(FGeocoding);
  inherited Destroy;
end;

procedure TTMSFNCGeocodingFactoryService.DestroyGeocoding(AGeocoding: ITMSFNCCustomGeocoding);
begin
  FGeocoding.Remove(AGeocoding)
end;

{ TTMSFNCGeocodingPlatformServices }

procedure TTMSFNCGeocodingPlatformServices.AddPlatformService(const AServiceGUID: TGUID; const AService: IInterface);
var
  LService: IInterface;
begin
  if not FServicesList.ContainsKey(GUIDToString(AServiceGUID)) then
  begin
    if Supports(AService, AServiceGUID, LService) then
      FServicesList.Add(TTMSFNCGeocodingPlatformServicesService.Create(GUIDToString(AServiceGUID), AService));
  end;
end;

constructor TTMSFNCGeocodingPlatformServices.Create;
begin
  inherited;
  FServicesList := TTMSFNCGeocodingPlatformServicesList.Create;
end;

destructor TTMSFNCGeocodingPlatformServices.Destroy;
begin
  FreeAndNil(FServicesList);
  inherited;
end;

{$IFNDEF AUTOREFCOUNT}
class procedure TTMSFNCGeocodingPlatformServices.ReleaseCurrent;
begin
  FreeAndNil(FCurrent);
  FCurrentReleased := True;
end;
{$ENDIF}

class function TTMSFNCGeocodingPlatformServices.Current: TTMSFNCGeocodingPlatformServices;
begin
  if (FCurrent = nil) and not FCurrentReleased then
    FCurrent := TTMSFNCGeocodingPlatformServices.Create;
  Result := FCurrent;
end;

function TTMSFNCGeocodingPlatformServices.GetPlatformService(const AServiceGUID: TGUID): IInterface;
var
  k: IInterface;
begin
  k := FServicesList.Interfaces[GUIDToString(AServiceGUID)];
  Supports(k, AServiceGUID, Result);
end;

procedure TTMSFNCGeocodingPlatformServices.RemovePlatformService(const AServiceGUID: TGUID);
begin
  FServicesList.RemoveByGUID(GUIDToString(AServiceGUID));
end;

function TTMSFNCGeocodingPlatformServices.SupportsPlatformService(const AServiceGUID: TGUID;
  var AService: IInterface): Boolean;
begin
  if FServicesList.ContainsKey(GUIDToString(AServiceGUID)) then
    Result := Supports(FServicesList.Interfaces[GUIDToString(AServiceGUID)], AServiceGUID, AService)
  else
  begin
    AService := nil;
    Result := False;
  end;
end;

function TTMSFNCGeocodingPlatformServices.SupportsPlatformService(const AServiceGUID: TGUID): Boolean;
begin
  Result := FServicesList.ContainsKey(GUIDToString(AServiceGUID));
end;

{ TTMSFNCGeocodingPlatformServicesService }

constructor TTMSFNCGeocodingPlatformServicesService.Create(AGUID: string;
  AInterface: IInterface);
begin
  FGUID := AGUID;
  FInterface := AInterface;
end;

{ TTMSFNCGeocodingPlatformServicesList }

function TTMSFNCGeocodingPlatformServicesList.ContainsKey(AGUID: string): Boolean;
var
  I: Integer;
begin
  Result := False;
  for I := 0 to Count - 1 do
  begin
    if Items[I].GUID = AGUID then
    begin
      Result := True;
      Break;
    end;
  end;
end;

function TTMSFNCGeocodingPlatformServicesList.GetValue(AGUID: string): IInterface;
var
  I: Integer;
begin
  Result := nil;
  for I := 0 to Count - 1 do
  begin
    if Items[I].GUID = AGUID then
    begin
      Result := Items[I].&Interface;
      Break;
    end;
  end;
end;

procedure TTMSFNCGeocodingPlatformServicesList.RemoveByGUID(AGUID: string);
var
  I: Integer;
begin
  for I := Count - 1 downto 0 do
  begin
    if Items[I].GUID = AGUID then
    begin
      Remove(Items[I]);
      Break;
    end;
  end;
end;

{$IFDEF WEBLIB}
function TTMSFNCGeocodingPlatformServicesList.GetItem(Index: Integer): TTMSFNCGeocodingPlatformServicesService;
begin
  Result := TTMSFNCGeocodingPlatformServicesService(inherited Items[Index]);
end;

procedure TTMSFNCGeocodingPlatformServicesList.SetItem(Index: Integer; const Value: TTMSFNCGeocodingPlatformServicesService);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCGeocodingList.GetItem(Index: Integer): ITMSFNCCustomGeocoding;
begin
  Result := ITMSFNCCustomGeocoding(inherited Items[Index]);
end;

procedure TTMSFNCGeocodingList.SetItem(Index: Integer; const Value: ITMSFNCCustomGeocoding);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

{ TTMSFNCCustomGeocodingInterfacedObject }

destructor TTMSFNCCustomGeocodingInterfacedObject.Destroy;
begin
  FGeocodingProperties := nil;
  inherited;
end;

function TTMSFNCCustomGeocodingInterfacedObject.GetGeocodingProperties: ITMSFNCCustomGeocodingProperties;
begin
  Result := FGeocodingProperties;
end;

procedure TTMSFNCCustomGeocodingInterfacedObject.SetGeocodingProperties(
  const Value: ITMSFNCCustomGeocodingProperties);
begin
  FGeocodingProperties := Value;
end;

{ TTMSFNCGeocodingItem }

procedure TTMSFNCGeocodingItem.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCGeocodingItem then
  begin
    FCoordinate.Assign(TTMSFNCGeocodingItem(Source).Coordinate);
    FAddress := TTMSFNCGeocodingItem(Source).Address;
    FCity := TTMSFNCGeocodingItem(Source).City;
    FDistrict := TTMSFNCGeocodingItem(Source).District;
    FCountry := TTMSFNCGeocodingItem(Source).Country;
    FCountryCode := TTMSFNCGeocodingItem(Source).CountryCode;
    FProvince := TTMSFNCGeocodingItem(Source).Province;
    FProvinceCode := TTMSFNCGeocodingItem(Source).ProvinceCode;
    FRegion := TTMSFNCGeocodingItem(Source).Region;
    FRegionCode := TTMSFNCGeocodingItem(Source).RegionCode;
    FStreet := TTMSFNCGeocodingItem(Source).Street;
    FStreetNumber := TTMSFNCGeocodingItem(Source).StreetNumber;
    FStreetName := TTMSFNCGeocodingItem(Source).StreetName;
    FPostalCode := TTMSFNCGeocodingItem(Source).PostalCode;
    FIntersection := TTMSFNCGeocodingItem(Source).Intersection;
  end;
end;

constructor TTMSFNCGeocodingItem.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCGeocodingItems).FOwner;

  FCoordinate := TTMSFNCMapsCoordinate.Create;
  FAddress := '';
  FCity := '';
  FDistrict := '';
  FRegion := '';
  FRegionCode := '';
  FCountry := '';
  FCountryCode := '';
  FProvince := '';
  FProvinceCode := '';
  FStreet := '';
  FStreetNumber := '';
  FStreetName := '';
  FPostalCode := '';
  FIntersection := '';
end;

destructor TTMSFNCGeocodingItem.Destroy;
begin
  FCoordinate.Free;
  inherited;
end;

procedure TTMSFNCGeocodingItem.SetCoordinate(
  const Value: TTMSFNCMapsCoordinate);
begin
  FCoordinate.Assign(Value);
end;

{ TTMSFNCGeocodingItems }

function TTMSFNCGeocodingItems.Add: TTMSFNCGeocodingItem;
begin
  Result := TTMSFNCGeocodingItem(inherited Add);
end;

constructor TTMSFNCGeocodingItems.Create(AOwner: TTMSFNCCustomGeocoding);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCGeocodingItems.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGeocodingItem;
end;

function TTMSFNCGeocodingItems.GetItem(
  Index: Integer): TTMSFNCGeocodingItem;
begin
  Result := TTMSFNCGeocodingItem(inherited Items[Index]);
end;

function TTMSFNCGeocodingItems.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCGeocodingItems.Insert(
  Index: Integer): TTMSFNCGeocodingItem;
begin
  Result := TTMSFNCGeocodingItem(inherited Insert(Index));
end;

procedure TTMSFNCGeocodingItems.SetItem(Index: Integer;
  const Value: TTMSFNCGeocodingItem);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGeocodingRequest }

procedure TTMSFNCGeocodingRequest.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCGeocodingRequest then
  begin
    FItems.Assign((Source as TTMSFNCGeocodingRequest).Items);
  end;
end;

constructor TTMSFNCGeocodingRequest.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCGeocodingRequests).FOwner;

  FItems := TTMSFNCGeocodingItems.Create(FOwner);
  FID := '';
  FStatus := '';
  FErrorMessage := '';
end;

destructor TTMSFNCGeocodingRequest.Destroy;
begin
  FItems.Free;
  inherited;
end;

function TTMSFNCGeocodingRequest.GetFirst: TTMSFNCGeocodingItem;
begin
  Result := nil;
  if Items.Count > 0 then
    Result := Items[0];
end;

procedure TTMSFNCGeocodingRequest.SetErrorMessage(const Value: string);
begin
  FErrorMessage := Value;
end;

procedure TTMSFNCGeocodingRequest.SetItems(const Value: TTMSFNCGeocodingItems);
begin
  FItems.Assign(Value);
end;

procedure TTMSFNCGeocodingRequest.SetStatus(const Value: string);
begin
  FStatus := Value;
end;

{ TTMSFNCGeocodingRequests }

function TTMSFNCGeocodingRequests.Add: TTMSFNCGeocodingRequest;
begin
  Result := TTMSFNCGeocodingRequest(inherited Add);
end;

constructor TTMSFNCGeocodingRequests.Create(AOwner: TTMSFNCCustomGeocoding);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCGeocodingRequests.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGeocodingRequest;
end;

function TTMSFNCGeocodingRequests.GetItem(
  Index: Integer): TTMSFNCGeocodingRequest;
begin
  Result := TTMSFNCGeocodingRequest(inherited Items[Index]);
end;

function TTMSFNCGeocodingRequests.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCGeocodingRequests.Insert(
  Index: Integer): TTMSFNCGeocodingRequest;
begin
  Result := TTMSFNCGeocodingRequest(inherited Insert(Index));
end;

procedure TTMSFNCGeocodingRequests.SetItem(Index: Integer;
  const Value: TTMSFNCGeocodingRequest);
begin
  inherited Items[Index] := Value;
end;


{ TTMSFNCGeocodingCallBackWrapper }

constructor TTMSFNCGeocodingCallBackWrapper.Create(
  ACallback: TTMSFNCGeocodingGetGeocodingCallBack);
begin
  FCallback := ACallback;
end;

{ TTMSFNCGeocodingResultCallBackWrapper }

constructor TTMSFNCGeocodingResultCallBackWrapper.Create(
  ACallback: TTMSFNCGeocodingGetGeocodingResultCallBack);
begin
  FCallback := ACallback;
end;

{ TTMSFNCGeocoding }

procedure TTMSFNCGeocoding.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCGeocoding]);
end;

{ TTMSFNCReverseGeocodingResultCallBackWrapper }

constructor TTMSFNCReverseGeocodingResultCallBackWrapper.Create(
  ACallback: TTMSFNCGeocodingGetReverseGeocodingResultCallBack);
begin
  FCallback := ACallback;
end;

{ TTMSFNCReverseGeocodingCallBackWrapper }

constructor TTMSFNCReverseGeocodingCallBackWrapper.Create(
  ACallback: TTMSFNCGeocodingGetReverseGeocodingCallBack);
begin
  FCallback := ACallback;
end;

initialization
begin
  TTMSFNCGeocodingPlatformServices.FCurrentReleased := False;
  RegisterGoogleGeocodingService;
  RegisterHereGeocodingService;
  RegisterBingGeocodingService;
  RegisterAzureGeocodingService;
  RegisterMapBoxGeocodingService;
  RegisterTomTomGeocodingService;
  RegisterOpenStreetMapGeocodingService;
  RegisterOpenRouteServiceGeocodingService;
  RegisterGeoApifyGeocodingService;
end;

{$IFNDEF WEBLIB}
finalization
begin
  UnRegisterGoogleGeocodingService;
  UnRegisterHereGeocodingService;
  UnRegisterBingGeocodingService;
  UnRegisterAzureGeocodingService;
  UnRegisterMapBoxGeocodingService;
  UnRegisterTomTomGeocodingService;
  UnRegisterOpenStreetMapGeocodingService;
  UnRegisterOpenRouteServiceGeocodingService;
  UnRegisterGeoApifyGeocodingService;
  {$IFNDEF AUTOREFCOUNT}
  TTMSFNCGeocodingPlatformServices.ReleaseCurrent;
  {$ENDIF}
end;
{$ENDIF}

end.
