{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGeocoding.Here;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterHereGeocodingService;
procedure UnRegisterHereGeocodingService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCGeocoding,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCGeocodingHereService = class;

  TTMSFNCGeocodingHereGeocodingFactoryService = class(TTMSFNCGeocodingFactoryService, ITMSFNCGeocodingServiceHere);

  TTMSFNCGeocodingHereService = class(TTMSFNCGeocodingHereGeocodingFactoryService)
  protected
    function DoCreateGeocoding: ITMSFNCCustomGeocoding; override;
  end;

type
  TTMSFNCGeocodingHereGeocoding = class;

  TTMSFNCGeocodingHereGeocoding = class(TTMSFNCCustomGeocodingInterfacedObject, ITMSFNCCustomGeocoding)
  protected
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetReverseHost: string;
    function GetPath(AAddress: string): string;
    function GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
    function GetQuery(AAddress: string; ALocale: string): string; overload;
    function GetQuery(ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string; overload;
    function GetPostData: string;
    procedure Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyGeocoding;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  GeocodingService: ITMSFNCGeocodingServiceHere;

procedure RegisterHereGeocodingService;
begin
  if not TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceHere, IInterface(GeocodingService)) then
  begin
    GeocodingService := TTMSFNCGeocodingHereService.Create;
    TTMSFNCGeocodingPlatformServices.Current.AddPlatformService(ITMSFNCGeocodingServiceHere, GeocodingService);
  end;
end;

procedure UnregisterHereGeocodingService;
begin
  TTMSFNCGeocodingPlatformServices.Current.RemovePlatformService(ITMSFNCGeocodingServiceHere);
end;

{ TTMSFNCGeocodingHereService }

function TTMSFNCGeocodingHereService.DoCreateGeocoding: ITMSFNCCustomGeocoding;
begin
  Result := TTMSFNCGeocodingHereGeocoding.Create;
end;

procedure TTMSFNCGeocodingHereGeocoding.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCGeocodingHereGeocoding.Create;
begin
  inherited;
end;

destructor TTMSFNCGeocodingHereGeocoding.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGeocodingHereGeocoding.DestroyGeocoding;
begin
  GeocodingService.DestroyGeocoding(Self);
end;

function TTMSFNCGeocodingHereGeocoding.GetHost: string;
begin
  Result := 'https://geocode.search.hereapi.com';
end;

function TTMSFNCGeocodingHereGeocoding.GetReverseHost: string;
begin
  Result := 'https://revgeocode.search.hereapi.com';
end;

function TTMSFNCGeocodingHereGeocoding.GetIdentifier: string;
begin
  Result := 'Here';
end;

function TTMSFNCGeocodingHereGeocoding.GetPath(AAddress: string): string;
begin
  Result := '/v1/geocode';
end;

function TTMSFNCGeocodingHereGeocoding.GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
begin
  Result := '/v1/revgeocode';
end;

function TTMSFNCGeocodingHereGeocoding.GetPostData: string;
begin
  Result := '';
end;

function TTMSFNCGeocodingHereGeocoding.GetQuery(
  ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string;
begin
  Result := 'apiKey=' + GeocodingProperties.GetAPIKey
    + '&at=' + FloatToStrDot(ACoordinate.Latitude) + ',' + FloatToStrDot(ACoordinate.Longitude);
    ;

  if ALocale <> '' then
    Result := Result + '&lang=' + ALocale;
end;

function TTMSFNCGeocodingHereGeocoding.GetQuery(AAddress: string; ALocale: string): string;
begin
  Result := 'apiKey=' + GeocodingProperties.GetAPIKey
    + '&q=' + AAddress
    ;

  if ALocale <> '' then
    Result := Result + '&lang=' + ALocale;
end;

function TTMSFNCGeocodingHereGeocoding.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCGeocodingHereGeocoding.IsValid: Boolean;
begin
  Result := GeocodingProperties.GetAPIKey <> '';
end;

procedure TTMSFNCGeocodingHereGeocoding.Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
var
  d: TTMSFNCGeocodingItems;
  di: TTMSFNCGeocodingItem;

  o, jo: TJSONValue;
  jar: TJSONArray;
  i: Integer;
  oc: TJSONObject;
  street, number: string;
begin
  if Assigned(GeocodingProperties) then
  begin
    d := ARequest.Items;
    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'error');
            ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(o, 'error_description');

            if ARequest.Status = '' then
              ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'type') + ' ' + TTMSFNCUtils.GetJSONProp(o, 'subtype');

            if ARequest.ErrorMessage = '' then
              ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(o, 'Details');

            jar := TTMSFNCUtils.GetJSONValue(o, 'items') as TJSONArray;
            if Assigned(jar) then
            begin
              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
              begin
                jo := TTMSFNCUtils.GetJSONArrayItem(jar, i);

                di := d.Add;

                oc := TTMSFNCUtils.GetJSONValue(jo, 'address') as TJSONObject;
                if Assigned(oc) then
                begin
                  di.Address := TTMSFNCUtils.GetJSONProp(oc, 'label');
                  di.CountryCode := TTMSFNCUtils.GetJSONProp(oc, 'countryCode');
                  di.Country := TTMSFNCUtils.GetJSONProp(oc, 'countryName');
                  di.Province := TTMSFNCUtils.GetJSONProp(oc, 'county');
                  di.District := TTMSFNCUtils.GetJSONProp(oc, 'district');
                  di.Province := TTMSFNCUtils.GetJSONProp(oc, 'county');
                  di.Region := TTMSFNCUtils.GetJSONProp(oc, 'state');
                  di.City := TTMSFNCUtils.GetJSONProp(oc, 'city');
                  di.PostalCode := TTMSFNCUtils.GetJSONProp(oc, 'postalCode');
                  street := TTMSFNCUtils.GetJSONProp(oc, 'street');
                  number := TTMSFNCUtils.GetJSONProp(oc, 'houseNumber');
                  di.Street := Trim(street + ' ' + number);
                  di.StreetName := street;
                  di.StreetNumber := number;
                end;
                oc := TTMSFNCUtils.GetJSONValue(jo, 'position') as TJSONObject;
                if Assigned(oc) then
                begin
                  di.Coordinate.Latitude := TTMSFNCUtils.GetJSONDoubleValue(oc, 'lat');
                  di.Coordinate.Longitude := TTMSFNCUtils.GetJSONDoubleValue(oc, 'lng');
                end;
              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

