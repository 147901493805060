unit StufStoneMain;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, WEBLib.Buttons, VCL.TMSFNCTypes,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCMapsCommonTypes, VCL.TMSFNCCustomControl, VCL.TMSFNCWebBrowser,
  VCL.TMSFNCMaps;

type
  TForm2 = class(TForm)
    WebPanel2: TPanel;
    WebButton1: TButton;
    WebToggleButton1: TToggleButton;
    TMSFNCMaps1: TTMSFNCMaps;
    WebLabel1: TLabel;
    procedure WebToggleButton1Click(Sender: TObject);
  private
    FMapVisible: Boolean;
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  Form2: TForm2;

implementation

{$R *.dfm}

procedure TForm2.WebToggleButton1Click(Sender: TObject);
begin
  FMapVisible := Self.WebToggleButton1.Checked;

  TMSFNCMaps1.Visible := FMapVisible;

  if FMapVisible then
  begin
    //TMSFNCMaps1.Align := alClient;
    //WebPanel1.Align := alLeft;
  end
  else
  begin
    //TMSFNCMaps1.Align := alClient;
    //WebPanel1.Align := alClient;
  end;
end;

procedure TForm2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel2 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebToggleButton1 := TToggleButton.Create(Self);
  TMSFNCMaps1 := TTMSFNCMaps.Create(Self);

  WebPanel2.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebToggleButton1.BeforeLoadDFMValues;
  TMSFNCMaps1.BeforeLoadDFMValues;
  try
    Name := 'Form2';
    Width := 1229;
    Height := 1034;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 0;
    WebPanel2.Width := 1229;
    WebPanel2.Height := 81;
    WebPanel2.Align := alTop;
    WebPanel2.ChildOrder := 2;
    WebLabel1.SetParentComponent(WebPanel2);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 1137;
    WebLabel1.Top := 60;
    WebLabel1.Width := 61;
    WebLabel1.Height := 15;
    WebLabel1.Anchors := [akTop,akRight];
    WebLabel1.Caption := 'Map Visible';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 24;
    WebButton1.Top := 16;
    WebButton1.Width := 201;
    WebButton1.Height := 46;
    WebButton1.Caption := 'Upload Picture with Geodata';
    WebButton1.ChildOrder := 1;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    WebToggleButton1.SetParentComponent(WebPanel2);
    WebToggleButton1.Name := 'WebToggleButton1';
    WebToggleButton1.Left := 1129;
    WebToggleButton1.Top := 16;
    WebToggleButton1.Width := 81;
    WebToggleButton1.Height := 38;
    WebToggleButton1.Anchors := [akTop,akRight];
    WebToggleButton1.Checked := True;
    SetEvent(WebToggleButton1, Self, 'OnClick', 'WebToggleButton1Click');
    TMSFNCMaps1.SetParentComponent(Self);
    TMSFNCMaps1.Name := 'TMSFNCMaps1';
    TMSFNCMaps1.Left := 0;
    TMSFNCMaps1.Top := 81;
    TMSFNCMaps1.Width := 1229;
    TMSFNCMaps1.Height := 953;
    TMSFNCMaps1.Align := alClient;
    TMSFNCMaps1.ParentDoubleBuffered := False;
    TMSFNCMaps1.DoubleBuffered := True;
    TMSFNCMaps1.TabOrder := 1;
    TMSFNCMaps1.APIKey := 'AIzaSyDlKfOntA2C8rbdgRwTqMUjIwWNf35rLn8';
    TMSFNCMaps1.Options.DefaultZoomLevel := 12.000000000000000000;
  finally
    WebPanel2.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebToggleButton1.AfterLoadDFMValues;
    TMSFNCMaps1.AfterLoadDFMValues;
  end;
end;

end.