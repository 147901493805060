{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCDirections.GeoApify;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterGeoApifyDirectionsService;
procedure UnRegisterGeoApifyDirectionsService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCDirections,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCDirectionsGeoApifyService = class;

  TTMSFNCDirectionsGeoApifyDirectionsFactoryService = class(TTMSFNCDirectionsFactoryService, ITMSFNCDirectionsServiceGeoApify);

  TTMSFNCDirectionsGeoApifyService = class(TTMSFNCDirectionsGeoApifyDirectionsFactoryService)
  protected
    function DoCreateDirections: ITMSFNCCustomDirections; override;
  end;

type
  TTMSFNCDirectionsGeoApifyDirections = class;

  TTMSFNCDirectionsGeoApifyDirections = class(TTMSFNCCustomDirectionsInterfacedObject, ITMSFNCCustomDirections)
  protected
    FTravelMode: TTMSFNCDirectionsTravelMode;
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
    function GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
    function GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
    procedure Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyDirections;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  DirectionsService: ITMSFNCDirectionsServiceGeoApify;

procedure RegisterGeoApifyDirectionsService;
begin
  if not TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceGeoApify, IInterface(DirectionsService)) then
  begin
    DirectionsService := TTMSFNCDirectionsGeoApifyService.Create;
    TTMSFNCDirectionsPlatformServices.Current.AddPlatformService(ITMSFNCDirectionsServiceGeoApify, DirectionsService);
  end;
end;

procedure UnregisterGeoApifyDirectionsService;
begin
  TTMSFNCDirectionsPlatformServices.Current.RemovePlatformService(ITMSFNCDirectionsServiceGeoApify);
end;

{ TTMSFNCDirectionsGeoApifyService }

function TTMSFNCDirectionsGeoApifyService.DoCreateDirections: ITMSFNCCustomDirections;
begin
  Result := TTMSFNCDirectionsGeoApifyDirections.Create;
end;

procedure TTMSFNCDirectionsGeoApifyDirections.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin
  AHeaders.Add(TTMSFNCCloudBaseRequestHeader.Create('Content-Type', 'application/json'));
  AHeaders.Add(TTMSFNCCloudBaseRequestHeader.Create('Authorization', DirectionsProperties.GetAPIKey));
end;

constructor TTMSFNCDirectionsGeoApifyDirections.Create;
begin
  inherited;
end;

destructor TTMSFNCDirectionsGeoApifyDirections.Destroy;
begin
  inherited;
end;

procedure TTMSFNCDirectionsGeoApifyDirections.DestroyDirections;
begin
  DirectionsService.DestroyDirections(Self);
end;

function TTMSFNCDirectionsGeoApifyDirections.GetHost: string;
begin
  Result := 'https://api.geoapify.com';
end;

function TTMSFNCDirectionsGeoApifyDirections.GetIdentifier: string;
begin
  Result := 'GeoApify';
end;

function TTMSFNCDirectionsGeoApifyDirections.GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
begin
  Result := '/v1/routing';
end;

function TTMSFNCDirectionsGeoApifyDirections.GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
begin
  Result := '';
end;

function TTMSFNCDirectionsGeoApifyDirections.GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
  ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
  ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
  AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
var
  TravelMode, WayPoints: string;
  I: Integer;
begin
  case ATravelMode of
    tmDriving: TravelMode := 'drive';
    tmWalking: TravelMode := 'walk';
    tmBicycling: TravelMode := 'bicycle';
    tmPublicTransport: TravelMode := 'transit';
    tmTruck: TravelMode := 'truck';
  end;

  WayPoints := FloatToStrDot(AOriginLatitude) + ',' + FloatToStrDot(AOriginLongitude);     
  for I := 0 to Length(AWayPoints) - 1 do
  begin
    WayPoints := WayPoints +
      '|' + FloatToStrDot(AWayPoints[I].Latitude) + ',' + FloatToStrDot(AWayPoints[I].Longitude);      
  end;                                                                                     
  WayPoints := WayPoints +
    '|' + FloatToStrDot(ADestinationLatitude) + ',' + FloatToStrDot(ADestinationLongitude);

  Result := 'apiKey=' + DirectionsProperties.GetAPIKey +
   '&mode=' + TravelMode +
   '&waypoints=' + WayPoints;

  if ALocale <> '' then  
     Result := Result + '&lang=' + ALocale;
end;

function TTMSFNCDirectionsGeoApifyDirections.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCDirectionsGeoApifyDirections.IsValid: Boolean;
begin
  Result := DirectionsProperties.GetAPIKey <> '';
end;

procedure TTMSFNCDirectionsGeoApifyDirections.Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
var
  d: TTMSFNCDirectionsItems;
  di: TTMSFNCDirectionsItem;
  step, lstep: TTMSFNCDirectionsStep;
  l: TTMSFNCDirectionsLeg;
  it, lit: TTMSFNCMapsCoordinateItem;

  o, jo, jol, jos, joar: TJSONValue;
  jar, jal, jas, jac, jaa: TJSONArray;
  i, j, k, m, startindex, endindex: Integer;
  ob, ol: TJSONObject;
begin
  if Assigned(DirectionsProperties) then
  begin
    ARequest.TravelMode := FTravelMode;

    d := ARequest.Items;
    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'error');
            ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(o, 'message');

            jar := TTMSFNCUtils.GetJSONValue(o, 'features') as TJSONArray;
            if Assigned(jar) then
            begin                                            
              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
              begin
                jo := TTMSFNCUtils.GetJSONArrayItem(jar, i);

                di := d.Add;
                ol := TTMSFNCUtils.GetJSONValue(jo, 'geometry') as TJSONObject;
                if Assigned(ol) then
                begin
                  jac := TTMSFNCUtils.GetJSONValue(ol, 'coordinates') as TJSONArray;                
                  if Assigned(jac) then
                  begin
                    for k := 0 to TTMSFNCUtils.GetJSONArraySize(jac) - 1 do
                    begin
                      l := di.Legs.Add;
                      jaa := TTMSFNCUtils.GetJSONArrayItem(jac, k) as TJSONArray;
                      if Assigned(jaa) then
                      begin
                        for m := 0 to TTMSFNCUtils.GetJSONArraySize(jaa) - 1 do
                        begin
                          jas := TTMSFNCUtils.GetJSONArrayItem(jaa, m) as TJSONArray;
                          if Assigned(jas) then
                          begin
                            it := di.Coordinates.Add;
                            joar := TTMSFNCUtils.GetJSONArrayItem(jas, 0);
                            if joar.Value <> '' then
                              it.Longitude := StrToFloatDot(joar.Value);
                            joar := TTMSFNCUtils.GetJSONArrayItem(jas, 1);
                            if joar.Value <> '' then
                              it.Latitude := StrToFloatDot(joar.Value);
                            lit := l.Coordinates.Add;
                            lit.Latitude := it.Latitude;
                            lit.Longitude := it.Longitude;
                          end;
                        end;
                      end;

                      if l.Coordinates.Count > 0 then
                      begin
                        l.StartLocation.Assign(l.Coordinates[0].Coordinate);
                        l.EndLocation.Assign(l.Coordinates[l.Coordinates.Count - 1].Coordinate);
                      end;
                    end;
                  end;                                          
                end;

                ol := TTMSFNCUtils.GetJSONValue(jo, 'properties') as TJSONObject;
                if Assigned(ol) then
                begin
                  di.Distance := TTMSFNCUtils.GetJSONDoubleValue(ol, 'distance');
                  di.Duration := Round(TTMSFNCUtils.GetJSONDoubleValue(ol, 'time'));

                  jal := TTMSFNCUtils.GetJSONValue(ol, 'legs') as TJSONArray;
                  if Assigned(jal) then
                  begin
                    for j := 0 to TTMSFNCUtils.GetJSONArraySize(jal) - 1 do
                    begin
                      jol := TTMSFNCUtils.GetJSONArrayItem(jal, j);

                      l := di.legs[j];
                      l.Distance := Round(TTMSFNCUtils.GetJSONDoubleValue(jol, 'distance'));
                      l.Duration := Round(TTMSFNCUtils.GetJSONDoubleValue(jol, 'time'));

                      jas := TTMSFNCUtils.GetJSONValue(jol, 'steps') as TJSONArray;
                      if Assigned(jas) then
                      begin
                        for k := 0 to TTMSFNCUtils.GetJSONArraySize(jas) - 1 do
                        begin
                          jos := TTMSFNCUtils.GetJSONArrayItem(jas, k);

                          step := di.Steps.Add;
                          step.Distance := Round(TTMSFNCUtils.GetJSONDoubleValue(jos, 'distance'));
                          step.Duration := Round(TTMSFNCUtils.GetJSONDoubleValue(jos, 'time'));
                          ob := TTMSFNCUtils.GetJSONValue(jos, 'instruction') as TJSONObject;                        
                          if Assigned(ob) then
                          begin
                            step.Instructions := TTMSFNCUtils.GetJSONProp(ob, 'text');
                          end;

                          startindex := TTMSFNCUtils.GetJSONIntegerValue(jos, 'from_index');
                          endindex := TTMSFNCUtils.GetJSONIntegerValue(jos, 'to_index');
                          for m := startindex to endindex do
                          begin
                            it := step.Coordinates.Add;
                            it.Latitude := l.Coordinates[m].Latitude;
                            it.Longitude := l.Coordinates[m].Longitude;
                          end;
                          if step.Coordinates.Count > 0 then
                          begin
                            step.StartLocation.Assign(step.Coordinates[0].Coordinate);
                            step.EndLocation.Assign(step.Coordinates[step.Coordinates.Count - 1].Coordinate);
                          end;

                          lstep := l.Steps.Add;
                          lstep.Assign(step);
                        end;
                      end;
                    end;
                  end;
                end;
              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

