{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCLocation.Google;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterGoogleLocationService;
procedure UnRegisterGoogleLocationService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCLocation,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCLocationGoogleService = class;

  TTMSFNCLocationGoogleLocationFactoryService = class(TTMSFNCLocationFactoryService, ITMSFNCLocationServiceGoogle);

  TTMSFNCLocationGoogleService = class(TTMSFNCLocationGoogleLocationFactoryService)
  protected
    function DoCreateLocation: ITMSFNCCustomLocation; override;
  end;

type
  TTMSFNCLocationGoogleLocation = class;

  TTMSFNCLocationGoogleLocation = class(TTMSFNCCustomLocationInterfacedObject, ITMSFNCCustomLocation)
  protected
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetPath: string;
    function GetQuery: string; overload;
    function GetPostData: string;
    procedure Parse(ARequest: TTMSFNCLocationRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyLocation;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  LocationService: ITMSFNCLocationServiceGoogle;

procedure RegisterGoogleLocationService;
begin
  if not TTMSFNCLocationPlatformServices.Current.SupportsPlatformService(ITMSFNCLocationServiceGoogle, IInterface(LocationService)) then
  begin
    LocationService := TTMSFNCLocationGoogleService.Create;
    TTMSFNCLocationPlatformServices.Current.AddPlatformService(ITMSFNCLocationServiceGoogle, LocationService);
  end;
end;

procedure UnregisterGoogleLocationService;
begin
  TTMSFNCLocationPlatformServices.Current.RemovePlatformService(ITMSFNCLocationServiceGoogle);
end;

{ TTMSFNCLocationGoogleService }

function TTMSFNCLocationGoogleService.DoCreateLocation: ITMSFNCCustomLocation;
begin
  Result := TTMSFNCLocationGoogleLocation.Create;
end;

procedure TTMSFNCLocationGoogleLocation.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCLocationGoogleLocation.Create;
begin
  inherited;
end;

destructor TTMSFNCLocationGoogleLocation.Destroy;
begin
  inherited;
end;

procedure TTMSFNCLocationGoogleLocation.DestroyLocation;
begin
  LocationService.DestroyLocation(Self);
end;

function TTMSFNCLocationGoogleLocation.GetHost: string;
begin
  Result := 'https://www.googleapis.com';
end;

function TTMSFNCLocationGoogleLocation.GetIdentifier: string;
begin
  Result := 'Google';
end;

function TTMSFNCLocationGoogleLocation.GetPath: string;
begin
  Result := '/geolocation/v1/geolocate';
end;

function TTMSFNCLocationGoogleLocation.GetPostData: string;
begin
  Result := '';
end;

function TTMSFNCLocationGoogleLocation.GetQuery: string;
begin
  Result := 'key=' + LocationProperties.GetAPIKey;
end;

function TTMSFNCLocationGoogleLocation.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmPost;
end;

function TTMSFNCLocationGoogleLocation.IsValid: Boolean;
begin
  Result := LocationProperties.GetAPIKey <> '';
end;

procedure TTMSFNCLocationGoogleLocation.Parse(ARequest: TTMSFNCLocationRequest; ARequestResult: string);
var
  o: TJSONValue;
  b: TJSONObject;
begin
  if Assigned(LocationProperties) then
  begin
    if Assigned(ARequest) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            b := TTMSFNCUtils.GetJSONValue(o, 'location') as TJSONObject;
            if Assigned(b) then
            begin
              ARequest.Coordinate.Latitude := TTMSFNCUtils.GetJSONDoubleValue(b, 'lat');
              ARequest.Coordinate.Longitude := TTMSFNCUtils.GetJSONDoubleValue(b, 'lng');
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

