{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGeocoding.Azure;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterAzureGeocodingService;
procedure UnRegisterAzureGeocodingService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCGeocoding,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCGeocodingAzureService = class;

  TTMSFNCGeocodingAzureGeocodingFactoryService = class(TTMSFNCGeocodingFactoryService, ITMSFNCGeocodingServiceAzure);

  TTMSFNCGeocodingAzureService = class(TTMSFNCGeocodingAzureGeocodingFactoryService)
  protected
    function DoCreateGeocoding: ITMSFNCCustomGeocoding; override;
  end;

type
  TTMSFNCGeocodingAzureGeocoding = class;

  TTMSFNCGeocodingAzureGeocoding = class(TTMSFNCCustomGeocodingInterfacedObject, ITMSFNCCustomGeocoding)
  protected
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetReverseHost: string;
    function GetPath(AAddress: string): string;
    function GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
    function GetQuery(AAddress: string; ALocale: string): string; overload;
    function GetQuery(ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string; overload;
    function GetPostData: string;
    procedure Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyGeocoding;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  GeocodingService: ITMSFNCGeocodingServiceAzure;

procedure RegisterAzureGeocodingService;
begin
  if not TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceAzure, IInterface(GeocodingService)) then
  begin
    GeocodingService := TTMSFNCGeocodingAzureService.Create;
    TTMSFNCGeocodingPlatformServices.Current.AddPlatformService(ITMSFNCGeocodingServiceAzure, GeocodingService);
  end;
end;

procedure UnregisterAzureGeocodingService;
begin
  TTMSFNCGeocodingPlatformServices.Current.RemovePlatformService(ITMSFNCGeocodingServiceAzure);
end;

{ TTMSFNCGeocodingAzureService }

function TTMSFNCGeocodingAzureService.DoCreateGeocoding: ITMSFNCCustomGeocoding;
begin
  Result := TTMSFNCGeocodingAzureGeocoding.Create;
end;

procedure TTMSFNCGeocodingAzureGeocoding.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCGeocodingAzureGeocoding.Create;
begin
  inherited;
end;

destructor TTMSFNCGeocodingAzureGeocoding.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGeocodingAzureGeocoding.DestroyGeocoding;
begin
  GeocodingService.DestroyGeocoding(Self);
end;

function TTMSFNCGeocodingAzureGeocoding.GetHost: string;
begin
  Result := 'https://atlas.microsoft.com';
end;

function TTMSFNCGeocodingAzureGeocoding.GetReverseHost: string;
begin
  Result := GetHost;
end;

function TTMSFNCGeocodingAzureGeocoding.GetIdentifier: string;
begin
  Result := 'Azure';
end;

function TTMSFNCGeocodingAzureGeocoding.GetPath(AAddress: string): string;
begin
  Result := '/search/address/json';
end;

function TTMSFNCGeocodingAzureGeocoding.GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
begin
  Result := '/search/address/reverse/json';
end;

function TTMSFNCGeocodingAzureGeocoding.GetPostData: string;
begin
  Result := '';
end;

function TTMSFNCGeocodingAzureGeocoding.GetQuery(
  ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string;
begin
  Result := GetQuery(FloatToStrDot(ACoordinate.Latitude) + ',' + FloatToStrDot(ACoordinate.Longitude), ALocale);
end;

function TTMSFNCGeocodingAzureGeocoding.GetQuery(AAddress: string; ALocale: string): string;
begin
  Result := 'subscription-key=' + GeocodingProperties.GetAPIKey
    + '&api-version=1'
    + '&limit=1'
    + '&language=' + ALocale
    + '&query=' + AAddress
    ;
end;

function TTMSFNCGeocodingAzureGeocoding.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCGeocodingAzureGeocoding.IsValid: Boolean;
begin
  Result := GeocodingProperties.GetAPIKey <> '';
end;

procedure TTMSFNCGeocodingAzureGeocoding.Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
var
  d: TTMSFNCGeocodingItems;
  di: TTMSFNCGeocodingItem;

  o, jo: TJSONValue;
  jav: TJSONArray;
  i: Integer;
  oc, op: TJSONObject;
  isReverse: Boolean;
  position, street, number: string;
  sposition: TStringList;
begin
  if Assigned(GeocodingProperties) then
  begin
    d := ARequest.Items;
    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            oc := TTMSFNCUtils.GetJSONValue(o, 'error') as TJSONObject;
            if Assigned(oc) then
            begin
              ARequest.Status := TTMSFNCUtils.GetJSONProp(oc, 'code');
              ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(oc, 'message');
            end;

            //geocoding
            jav := TTMSFNCUtils.GetJSONValue(o, 'results') as TJSONArray;
            isReverse := False;

            //reversegeocoding
            if not Assigned(jav) then
            begin
              jav := TTMSFNCUtils.GetJSONValue(o, 'addresses') as TJSONArray;
              isReverse := True;
            end;

            if Assigned(jav) then
            begin
              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jav) - 1 do
              begin
                di := d.Add;
                jo := TTMSFNCUtils.GetJSONArrayItem(jav, i);

                oc := TTMSFNCUtils.GetJSONValue(jo, 'address') as TJSONObject;
                if Assigned(oc) then
                begin
                  di.Address := TTMSFNCUtils.GetJSONProp(oc, 'freeformAddress');
                  di.City := TTMSFNCUtils.GetJSONProp(oc, 'municipality');
                  di.Country := TTMSFNCUtils.GetJSONProp(oc, 'country');
                  di.CountryCode := TTMSFNCUtils.GetJSONProp(oc, 'countryCode');
                  di.Region := TTMSFNCUtils.GetJSONProp(oc, 'countrySecondarySubdivision');
                  di.Province := TTMSFNCUtils.GetJSONProp(oc, 'countrySubdivision');

                  street := TTMSFNCUtils.GetJSONProp(oc, 'streetName');
                  number := TTMSFNCUtils.GetJSONProp(oc, 'streetNumber');
                  di.Street := Trim(street + ' ' + number);
                  di.StreetName := street;
                  di.StreetNumber := number;

                  di.PostalCode := TTMSFNCUtils.GetJSONProp(oc, 'postalCode');
                end;

                if not isReverse then
                begin
                  op := TTMSFNCUtils.GetJSONValue(jo, 'position') as TJSONObject;
                  if Assigned(op) then
                  begin
                    di.Coordinate.Latitude := TTMSFNCUtils.GetJSONDoubleValue(op, 'lat');
                    di.Coordinate.Longitude := TTMSFNCUtils.GetJSONDoubleValue(op, 'lon');
                  end;
                end
                else
                begin
                  position := TTMSFNCUtils.GetJSONProp(jo, 'position');
                  if position <> '' then
                  begin
                    sposition := TStringList.Create;
                    TTMSFNCUtils.Split(',', position, sposition);
                    if sposition.Count > 1 then
                    begin
                      di.Coordinate.Latitude := StrToFloatDot(sposition[0]);
                      di.Coordinate.Longitude := StrToFloatDot(sposition[1]);
                    end;
                    sposition.Free;
                  end;
                end;
              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

