{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGeocoding.Bing;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterBingGeocodingService;
procedure UnRegisterBingGeocodingService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCGeocoding,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCGeocodingBingService = class;

  TTMSFNCGeocodingBingGeocodingFactoryService = class(TTMSFNCGeocodingFactoryService, ITMSFNCGeocodingServiceBing);

  TTMSFNCGeocodingBingService = class(TTMSFNCGeocodingBingGeocodingFactoryService)
  protected
    function DoCreateGeocoding: ITMSFNCCustomGeocoding; override;
  end;

type
  TTMSFNCGeocodingBingGeocoding = class;

  TTMSFNCGeocodingBingGeocoding = class(TTMSFNCCustomGeocodingInterfacedObject, ITMSFNCCustomGeocoding)
  protected
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetReverseHost: string;
    function GetPath(AAddress: string): string;
    function GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
    function GetQuery(AAddress: string; ALocale: string): string; overload;
    function GetQuery(ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string; overload;
    function GetPostData: string;
    procedure Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyGeocoding;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  GeocodingService: ITMSFNCGeocodingServiceBing;

procedure RegisterBingGeocodingService;
begin
  if not TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceBing, IInterface(GeocodingService)) then
  begin
    GeocodingService := TTMSFNCGeocodingBingService.Create;
    TTMSFNCGeocodingPlatformServices.Current.AddPlatformService(ITMSFNCGeocodingServiceBing, GeocodingService);
  end;
end;

procedure UnregisterBingGeocodingService;
begin
  TTMSFNCGeocodingPlatformServices.Current.RemovePlatformService(ITMSFNCGeocodingServiceBing);
end;

{ TTMSFNCGeocodingBingService }

function TTMSFNCGeocodingBingService.DoCreateGeocoding: ITMSFNCCustomGeocoding;
begin
  Result := TTMSFNCGeocodingBingGeocoding.Create;
end;

procedure TTMSFNCGeocodingBingGeocoding.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCGeocodingBingGeocoding.Create;
begin
  inherited;
end;

destructor TTMSFNCGeocodingBingGeocoding.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGeocodingBingGeocoding.DestroyGeocoding;
begin
  GeocodingService.DestroyGeocoding(Self);
end;

function TTMSFNCGeocodingBingGeocoding.GetHost: string;
begin
  Result := 'https://dev.virtualearth.net';
end;

function TTMSFNCGeocodingBingGeocoding.GetReverseHost: string;
begin
  Result := GetHost;
end;

function TTMSFNCGeocodingBingGeocoding.GetIdentifier: string;
begin
  Result := 'Bing';
end;

function TTMSFNCGeocodingBingGeocoding.GetPath(AAddress: string): string;
begin
  Result := '/REST/v1/Locations';
end;

function TTMSFNCGeocodingBingGeocoding.GetReversePath(
  ACoordinate: TTMSFNCMapsCoordinateRec): string;
begin
  Result := '/REST/v1/Locations/' + FloatToStrDot(ACoordinate.Latitude) + ',' + FloatToStrDot(ACoordinate.Longitude);
end;

function TTMSFNCGeocodingBingGeocoding.GetPostData: string;
begin
  Result := '';
end;

function TTMSFNCGeocodingBingGeocoding.GetQuery(
  ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string;
begin
  Result := 'key=' + GeocodingProperties.GetAPIKey;
end;

function TTMSFNCGeocodingBingGeocoding.GetQuery(AAddress: string; ALocale: string): string;
begin
  Result := 'key=' + GeocodingProperties.GetAPIKey
    + '&maxResults=1'
    + '&c=' + ALocale
    + '&query=' + AAddress
    ;
end;

function TTMSFNCGeocodingBingGeocoding.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCGeocodingBingGeocoding.IsValid: Boolean;
begin
  Result := GeocodingProperties.GetAPIKey <> '';
end;

procedure TTMSFNCGeocodingBingGeocoding.Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
var
  d: TTMSFNCGeocodingItems;
  di: TTMSFNCGeocodingItem;

  o, ro, jo: TJSONValue;
  jav, jar, jas: TJSONArray;
  i: Integer;
  oc: TJSONObject;
  position: string;
  sposition: TStringList;
begin
  if Assigned(GeocodingProperties) then
  begin
    d := ARequest.Items;
    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'statusCode');
            ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(o, 'statusDescription');

            jav := TTMSFNCUtils.GetJSONValue(o, 'resourceSets') as TJSONArray;
            if Assigned(jav) then
            begin

              ro := TTMSFNCUtils.GetJSONArrayItem(jav, 0);

              jar := TTMSFNCUtils.GetJSONValue(ro, 'resources') as TJSONArray;
              if Assigned(jar) then
              begin
                for i := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
                begin
                  di := d.Add;
                  jo := TTMSFNCUtils.GetJSONArrayItem(jar, i);

                  di.Address := TTMSFNCUtils.GetJSONProp(jo, 'name');
                  oc := TTMSFNCUtils.GetJSONValue(jo, 'address') as TJSONObject;
                  if Assigned(oc) then
                  begin
                    di.Street := TTMSFNCUtils.GetJSONProp(oc, 'addressLine');
                    di.City := TTMSFNCUtils.GetJSONProp(oc, 'locality');
                    di.PostalCode := TTMSFNCUtils.GetJSONProp(oc, 'postalCode');
                    di.Province := TTMSFNCUtils.GetJSONProp(oc, 'adminDistrict');
                    di.ProvinceCode := TTMSFNCUtils.GetJSONProp(oc, 'adminDistrict');
                    di.Region := TTMSFNCUtils.GetJSONProp(oc, 'adminDistrict2');
                    di.Country := TTMSFNCUtils.GetJSONProp(oc, 'countryRegion');
                  end;

                  oc := TTMSFNCUtils.GetJSONValue(jo, 'point') as TJSONObject;
                  if Assigned(oc) then
                  begin
                    jas := TTMSFNCUtils.GetJSONValue(oc, 'coordinates') as TJSONArray;
                    if Assigned(jas) then
                    begin
                        sposition := TStringList.Create;
                        position := StringReplace(jas.ToString, '[', '', [rfReplaceAll]);
                        position := StringReplace(position, ']', '', [rfReplaceAll]);
                        TTMSFNCUtils.Split(',', position, sposition);
                        if sposition.Count > 1 then
                        begin
                          di.Coordinate.Latitude := StrToFloatDot(sposition[0]);
                          di.Coordinate.Longitude := StrToFloatDot(sposition[1]);
                        end;
                        sposition.Free;
                      end;
                  end;
                end;
              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

