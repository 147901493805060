{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCLocation.IPStack;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterIPStackLocationService;
procedure UnRegisterIPStackLocationService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCLocation,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCLocationIPStackService = class;

  TTMSFNCLocationIPStackLocationFactoryService = class(TTMSFNCLocationFactoryService, ITMSFNCLocationServiceIPStack);

  TTMSFNCLocationIPStackService = class(TTMSFNCLocationIPStackLocationFactoryService)
  protected
    function DoCreateLocation: ITMSFNCCustomLocation; override;
  end;

type
  TTMSFNCLocationIPStackLocation = class;

  TTMSFNCLocationIPStackLocation = class(TTMSFNCCustomLocationInterfacedObject, ITMSFNCCustomLocation)
  protected
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetPath: string;
    function GetQuery: string; overload;
    function GetPostData: string;
    procedure Parse(ARequest: TTMSFNCLocationRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyLocation;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  LocationService: ITMSFNCLocationServiceIPStack;

procedure RegisterIPStackLocationService;
begin
  if not TTMSFNCLocationPlatformServices.Current.SupportsPlatformService(ITMSFNCLocationServiceIPStack, IInterface(LocationService)) then
  begin
    LocationService := TTMSFNCLocationIPStackService.Create;
    TTMSFNCLocationPlatformServices.Current.AddPlatformService(ITMSFNCLocationServiceIPStack, LocationService);
  end;
end;

procedure UnregisterIPStackLocationService;
begin
  TTMSFNCLocationPlatformServices.Current.RemovePlatformService(ITMSFNCLocationServiceIPStack);
end;

{ TTMSFNCLocationIPStackService }

function TTMSFNCLocationIPStackService.DoCreateLocation: ITMSFNCCustomLocation;
begin
  Result := TTMSFNCLocationIPStackLocation.Create;
end;

procedure TTMSFNCLocationIPStackLocation.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCLocationIPStackLocation.Create;
begin
  inherited;
end;

destructor TTMSFNCLocationIPStackLocation.Destroy;
begin
  inherited;
end;

procedure TTMSFNCLocationIPStackLocation.DestroyLocation;
begin
  LocationService.DestroyLocation(Self);
end;

function TTMSFNCLocationIPStackLocation.GetHost: string;
begin
  Result := 'https://api.ipstack.com';
end;

function TTMSFNCLocationIPStackLocation.GetIdentifier: string;
begin
  Result := 'IPStack';
end;

function TTMSFNCLocationIPStackLocation.GetPath: string;
begin
  Result := '/check';
end;

function TTMSFNCLocationIPStackLocation.GetPostData: string;
begin
  Result := '';
end;

function TTMSFNCLocationIPStackLocation.GetQuery: string;
begin
  Result := 'access_key=' + LocationProperties.GetAPIKey;
end;

function TTMSFNCLocationIPStackLocation.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCLocationIPStackLocation.IsValid: Boolean;
begin
  Result := LocationProperties.GetAPIKey <> '';
end;

procedure TTMSFNCLocationIPStackLocation.Parse(ARequest: TTMSFNCLocationRequest; ARequestResult: string);
var
  o: TJSONValue;
begin
  if Assigned(LocationProperties) then
  begin
    if Assigned(ARequest) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
             ARequest.Coordinate.Latitude := TTMSFNCUtils.GetJSONDoubleValue(o, 'latitude');
             ARequest.Coordinate.Longitude := TTMSFNCUtils.GetJSONDoubleValue(o, 'longitude');
             ARequest.IP := TTMSFNCUtils.GetJSONProp(o, 'ip');
             ARequest.IPType := TTMSFNCUtils.GetJSONProp(o, 'type');
             ARequest.Continent := TTMSFNCUtils.GetJSONProp(o, 'continent_name');
             ARequest.ContinentCode := TTMSFNCUtils.GetJSONProp(o, 'continent_code');
             ARequest.Country := TTMSFNCUtils.GetJSONProp(o, 'country_name');
             ARequest.CountryCode := TTMSFNCUtils.GetJSONProp(o, 'country_code');
             ARequest.Region := TTMSFNCUtils.GetJSONProp(o, 'region_name');
             ARequest.RegionCode := TTMSFNCUtils.GetJSONProp(o, 'region_code');
             ARequest.City := TTMSFNCUtils.GetJSONProp(o, 'city');
             ARequest.PostalCode := TTMSFNCUtils.GetJSONProp(o, 'zip');
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

