{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCMaps.Here;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterHereService;
procedure UnRegisterHereService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCMaps, WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes
  {$IFDEF WEBLIB}
  ,Contnrs, Web
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  ,UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 2; // Release nr.
  BLD_VER = 0; // Build nr.

  //v1.0.0.0: First release
  //v1.0.1.0: Improved : Support for base64 encoded marker icons
  //        : Improved : OnMapMouseMove event handling
  //v1.0.2.0: New : API version updated

type
  TTMSFNCMapsHereService = class;

  TTMSFNCMapsHereFactoryService = class(TTMSFNCMapsFactoryService, ITMSFNCMapsServiceHere);

  TTMSFNCMapsHereService = class(TTMSFNCMapsHereFactoryService)
  protected
    function DoCreateMaps: ITMSFNCCustomMaps; override;
  end;

type
  TTMSFNCMapsHere = class;

  TTMSFNCMapsHere = class(TTMSFNCCustomMapsInterfacedObject, ITMSFNCCustomMaps)
  protected
    procedure GetHeadLinks(const AList: TTMSFNCMapsLinksList);
    procedure RemoveScripts;
    function GetResetMap: string;
    function GetShowPopup: string;
    function GetClosePopup: string;
    function GetHeadStyle: string;
    function GetDelayLoadEvent: string;
    function GetMapsServiceCheck: string;
    function GetIdentifier: string;
    function GetInitializeMap: string;
    function GetInitializeEvents: string;
    function GetAddOrUpdateMarker: string;
    function GetDeleteMarker: string;
    function GetAddOrUpdatePolyElement: string;
    function GetDeletePolyElement: string;
    function GetZoomToBounds: string;
    function GetGetBounds: string;
    function GetGetCenterCoordinate: string;
    function GetGetZoomLevel: string;
    function GetLatLonToXY: string;
    function GetXYToLatLon: string;
    function GetSetCenterCoordinate: string;
    function GetSetZoomLevel: string;
    function GetUpdateOptions: string;
    function GetGlobalVariables: string;
    function GetAddCoordinateToArray: string;
    function GetAddHoleToArray: string;
    function GetInitializeCoordinateArray: string;
    function GetInitializeHolesArray: string;
    function IsValid: Boolean;
    procedure DestroyMaps;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  MapsService: ITMSFNCMapsServiceHere;

const
  HEREAPIVERSION = 'v3/3.1.42.2';
  HEREAPIURL = 'https://js.api.here.com/';
  MAPSERVICEVAR = 'window.H';
  MAPUI = 'mapUI';
  MAPBEHAVIOR = 'mapBehavior';

procedure RegisterHereService;
begin
  if not TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceHere, IInterface(MapsService)) then
  begin
    MapsService := TTMSFNCMapsHereService.Create;
    TTMSFNCMapsPlatformServices.Current.AddPlatformService(ITMSFNCMapsServiceHere, MapsService);
  end;
end;

procedure UnregisterHereService;
begin
  TTMSFNCMapsPlatformServices.Current.RemovePlatformService(ITMSFNCMapsServiceHere);
end;

{ TTMSFNCMapsHereService }

function TTMSFNCMapsHereService.DoCreateMaps: ITMSFNCCustomMaps;
begin
  Result := TTMSFNCMapsHere.Create;
end;

constructor TTMSFNCMapsHere.Create;
begin
  inherited;
end;

destructor TTMSFNCMapsHere.Destroy;
begin
  inherited;
end;

procedure TTMSFNCMapsHere.DestroyMaps;
begin
  MapsService.DestroyMaps(Self);
end;

function TTMSFNCMapsHere.GetDelayLoadEvent: string;
begin
  Result := '';
end;

function TTMSFNCMapsHere.GetDeleteMarker: string;
begin
  Result := '  ' + MAPVAR + '.removeObject(' + MARKERVAR + ')';
end;

function TTMSFNCMapsHere.GetDeletePolyElement: string;
begin
  Result := '  ' + MAPVAR + '.removeObject(' + POLYELEMENTVAR + ')';
end;

function TTMSFNCMapsHere.GetGetBounds: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getViewModel().getLookAtData().bounds.toGeoJSON();' + LB +
    '  jsonObj["NorthEast"]["Latitude"] = loc.coordinates[0][0][1];' + LB +
    '  jsonObj["NorthEast"]["Longitude"] = loc.coordinates[0][0][0];' + LB +
    '  jsonObj["SouthWest"]["Latitude"] = loc.coordinates[0][2][1];' + LB +
    '  jsonObj["SouthWest"]["Longitude"] = loc.coordinates[0][2][0];';
end;

function TTMSFNCMapsHere.GetGetCenterCoordinate: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getCenter();' + LB +
    '  jsonObj["Latitude"] = loc.lat;' + LB +
    '  jsonObj["Longitude"] = loc.lng;';
end;

function TTMSFNCMapsHere.GetGetZoomLevel: string;
begin
  Result := '  z = ' + MAPVAR + '.getZoom();';
end;

function TTMSFNCMapsHere.GetGlobalVariables: string;
begin
  Result :=
    'var ' + MAPUI + ';' + LB +
    'var ' + MAPBEHAVIOR + ';' + LB +
    'function parseEvent(event, eventname, id = ''''){' + LB +
    '  var loc = {''Latitude'': 0, ''Longitude'': 0};' + LB +
    '  var x = 0.0;' + LB +
    '  var y = 0.0;' + LB +
    '  if (event) {' + LB +
    '    event.stopPropagation();' + LB +
    '    if (event.currentPointer){' + LB +
    '      x = event.currentPointer.viewportX;' + LB +
    '      y = event.currentPointer.viewportY;' + LB +
    '      var l = ' + MAPVAR + '.screenToGeo(x, y);' + LB +
    '      var loc = {''Latitude'': l.lat, ''Longitude'': l.lng};' + LB +
    '    }' + LB +
    '  }' + LB +

    '  var r = {''Coordinate'': loc, ''X'': x, ''Y'': y, ''ID'': id, ''EventName'': eventname};' + LB +
    '  return r;' + LB +
    '}';
end;

procedure TTMSFNCMapsHere.GetHeadLinks(const AList: TTMSFNCMapsLinksList);
begin
  AList.Add(TTMSFNCMapsLink.CreateLink(HEREAPIURL + HEREAPIVERSION + '/mapsjs-ui.css', 'text/css', 'stylesheet'));
  AList.Add(TTMSFNCMapsLink.CreateScript(HEREAPIURL + HEREAPIVERSION + '/mapsjs-core.js', 'text/javascript', 'utf-8'));
  AList.Add(TTMSFNCMapsLink.CreateScript(HEREAPIURL + HEREAPIVERSION + '/mapsjs-service.js', 'text/javascript', 'utf-8'));
  AList.Add(TTMSFNCMapsLink.CreateScript(HEREAPIURL + HEREAPIVERSION + '/mapsjs-ui.js', 'text/javascript', 'utf-8'));
  AList.Add(TTMSFNCMapsLink.CreateScript(HEREAPIURL + HEREAPIVERSION + '/mapsjs-mapevents.js', 'text/javascript', 'utf-8'));
end;

function TTMSFNCMapsHere.GetHeadStyle: string;
begin
  Result := '';
end;

function TTMSFNCMapsHere.GetIdentifier: string;
begin
  Result := 'Here';
end;

function TTMSFNCMapsHere.GetInitializeCoordinateArray: string;
begin
  Result := 'new ' + MAPSERVICEVAR + '.geo.LineString();';
end;

function TTMSFNCMapsHere.GetInitializeHolesArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsHere.GetInitializeEvents: string;
begin
  Result :=
  '  var oldZoom = ' + MAPVAR + '.getZoom();' + LB +
  '  ' + MAPVAR + '.addEventListener(''mapviewchangeend'', function(){' + LB +
  '    var newZoom = ' + MAPVAR + '.getZoom();' + LB +
  '    if(newZoom != oldZoom){' + LB +
  '      ' + GETSENDEVENT + '(parseEvent(null, "ZoomChanged"));' + LB +
  '    }' + LB +
  '    oldZoom = newZoom;' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addEventListener(''baselayerchange'', function(){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(null, "MapTypeChanged"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addEventListener(''dragstart'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMoveStart"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addEventListener(''dragend'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMoveEnd"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addEventListener(''tap'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapClick"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addEventListener(''dbltap'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapDblClick"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addEventListener(''pointerup'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseUp"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addEventListener(''pointerdown'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseDown"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addEventListener(''pointerleave'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseLeave"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addEventListener(''pointerenter'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseEnter"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addEventListener(''pointermove'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseMove"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addEventListener(''drag'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseMove"));' + LB +
  '  })';
end;

function TTMSFNCMapsHere.GetInitializeMap: string;
var
  v: string;
begin
  v := MAPSERVICEVAR;
  Result :=
    '  ' + MAPOPTIONS + ' = {' + LB +
    '    zoom: ' + MapsProperties.GetDefaultZoomLevel + ',' + LB +
    '    center: { lng: ' + MapsProperties.GetDefaultLongitude + ', lat: ' + MapsProperties.GetDefaultLatitude + ' },' + LB +
    '    pixelRatio: window.devicePixelRatio || 1' + LB +
    '  };' + LB + LB +

    '  var platform = new ' + v + '.service.Platform({' + LB +
    '    "apikey": "' + MapsProperties.GetAPIKey + '", ' + LB +
    '  });' + LB + LB +

    '  var defaultLayers = platform.createDefaultLayers({' + LB +
    '    lg: ''' + MapsProperties.GetLocale(mlmCountry) + '''' + LB +
    '  });' + LB +
    {$IFDEF ANDROID}
    '  ' + MAPVAR + ' = new ' + v + '.Map(document.getElementById("' + MAPID + '"), defaultLayers.raster.normal.map, ' + MAPOPTIONS + ')' + LB + LB +
    {$ELSE}
    '  ' + MAPVAR + ' = new ' + v + '.Map(document.getElementById("' + MAPID + '"), defaultLayers.vector.normal.map, ' + MAPOPTIONS + ')' + LB + LB +
    {$ENDIF}
    '  window.addEventListener("resize", () => ' + MAPVAR + '.getViewPort().resize());' + LB + LB +
    '  ' + MAPBEHAVIOR + ' = new ' + v + '.mapevents.Behavior(new ' + v + '.mapevents.MapEvents(' + MAPVAR + '));' + LB;

    if not MapsProperties.GetZoomOnDblClick then
    begin
      Result := Result +
      '  ' + MAPBEHAVIOR + '.disable(' + MAPSERVICEVAR + '.mapevents.Behavior.DBLTAPZOOM)' + LB;
    end;

    if not MapsProperties.GetPanning then
    begin
      Result := Result +
      '  ' + MAPBEHAVIOR + '.disable(' + MAPSERVICEVAR + '.mapevents.Behavior.DRAGGING)' + LB;
    end;

    if not MapsProperties.GetZoomOnWheelScroll then
    begin
      Result := Result +
      '  ' + MAPBEHAVIOR + '.disable(' + MAPSERVICEVAR + '.mapevents.Behavior.WHEELZOOM)' + LB;
    end;

    Result := Result +
    '  ' + MAPUI + ' = ' + v + '.ui.UI.createDefault(' + MAPVAR + ', defaultLayers, ''' + MapsProperties.GetLocale + ''');' + LB +
    '  ' + MAPUI + '.getControl(''zoom'').setVisibility(' + LowerCase(BoolToStr(MapsProperties.GetShowZoomControl, True)) + ');' + LB +
    '  ' + MAPUI + '.getControl(''mapsettings'').setVisibility(' + LowerCase(BoolToStr(MapsProperties.GetShowMapTypeControl, True)) + ');';
end;

function TTMSFNCMapsHere.GetLatLonToXY: string;
begin
  Result :=
    '  var geopos = {' + LB +
    '    lat: ' + PARAMSNAME + '["Latitude"],' + LB +
    '    lng: ' + PARAMSNAME + '["Longitude"]' + LB +
    '  }' + LB +
    '  var point = ' + MAPVAR + '.geoToScreen(geopos);' + LB +
    '  jsonObj["X"] = parseFloat(point.x);' + LB +
    '  jsonObj["Y"] = parseFloat(point.y);';
end;

function TTMSFNCMapsHere.GetXYToLatLon: string;
begin
  Result :=
    '  var point = ' + MAPVAR + '.screenToGeo(' + PARAMSNAME + '["X"], ' + PARAMSNAME + '["Y"]);' + LB +
    '  jsonObj["Latitude"] = parseFloat(point.lat);' + LB +
    '  jsonObj["Longitude"] = parseFloat(point.lng);';
end;

function TTMSFNCMapsHere.GetMapsServiceCheck: string;
begin
  Result := '!' + MAPSERVICEVAR;
end;

function TTMSFNCMapsHere.GetResetMap: string;
begin
  Result := '';
end;

function TTMSFNCMapsHere.GetSetCenterCoordinate: string;
begin
  Result := '  ' + MAPVAR + '.setCenter({lat:' + PARAMSNAME + '["Latitude"], lng:' + PARAMSNAME + '["Longitude"]});';
end;

function TTMSFNCMapsHere.GetSetZoomLevel: string;
begin
  Result :=
    '  ' + MAPVAR + '.setZoom(' + PARAMSNAME + ');';
end;

function TTMSFNCMapsHere.GetShowPopup: string;
begin
  Result :=
    '  var pos = {' + LB +
    '    lat: ' + PARAMSNAME + '["Latitude"],' + LB +
    '    lng: ' + PARAMSNAME + '["Longitude"]' + LB +
    '  }' + LB +
    '  var xy = ' + MAPVAR + '.geoToScreen(pos);' + LB +
    '  var newpos = ' + MAPVAR + '.screenToGeo(xy.x + ' + PARAMSNAME + '["OffsetX"], xy.y + ' + PARAMSNAME + '["OffsetY"]);' + LB +
    '  ' + POPUPVAR + ' = new ' + MAPSERVICEVAR + '.ui.InfoBubble(newpos, {' + LB +
    '    content: ' + PARAMSNAME + '["Text"]' + LB +
    '  });' + LB +
    '  ' + POPUPVAR + '.close = function(){' + LB +
    '    ' + MAPUI + '.removeBubble(' + POPUPVAR + ');' + LB +
    '    ' + POPUPVAR + ' = null;' + LB +
    '  }' + LB +
    '  ' + MAPUI + '.addBubble(' + POPUPVAR + ');';
end;

function TTMSFNCMapsHere.GetUpdateOptions: string;
begin
  Result :=
    '  if (' + PARAMSNAME + '["ZoomOnDblClick"]){' + LB +
    '    ' + MAPBEHAVIOR + '.enable(' + MAPSERVICEVAR + '.mapevents.Behavior.DBLTAPZOOM)' + LB +
    '  }' + LB +
    '  else{' + LB +
    '    ' + MAPBEHAVIOR + '.disable(' + MAPSERVICEVAR + '.mapevents.Behavior.DBLTAPZOOM)' + LB +
    '  }' + LB +
    '  if (' + PARAMSNAME + '["Panning"]){' + LB +
    '    ' + MAPBEHAVIOR + '.enable(' + MAPSERVICEVAR + '.mapevents.Behavior.DRAGGING)' + LB +
    '  }' + LB +
    '  else{' + LB +
    '    ' + MAPBEHAVIOR + '.disable(' + MAPSERVICEVAR + '.mapevents.Behavior.DRAGGING)' + LB +
    '  }' + LB +
    '  if (' + PARAMSNAME + '["ZoomOnWheelScroll"]){' + LB +
    '    ' + MAPBEHAVIOR + '.enable(' + MAPSERVICEVAR + '.mapevents.Behavior.WHEELZOOM)' + LB +
    '  }' + LB +
    '  else{' + LB +
    '    ' + MAPBEHAVIOR + '.disable(' + MAPSERVICEVAR + '.mapevents.Behavior.WHEELZOOM)' + LB +
    '  }' + LB +
    '  ' + MAPUI + '.getControl(''zoom'').setVisibility('  + PARAMSNAME + '["ShowZoomControl"]);' + LB +
    '  ' + MAPUI + '.getControl(''mapsettings'').setVisibility(' + PARAMSNAME + '["ShowMapTypeControl"]);';
end;

function TTMSFNCMapsHere.GetZoomToBounds: string;
begin
  Result := '  ' + MAPVAR + '.getViewModel().setLookAtData({' + LB +
    '      bounds: new ' + MAPSERVICEVAR + '.geo.Rect(' + PARAMSNAME + '["NorthEast"]["Latitude"], ' + PARAMSNAME + '["SouthWest"]["Longitude"], ' +
      PARAMSNAME + '["SouthWest"]["Latitude"], ' + PARAMSNAME + '["NorthEast"]["Longitude"])' + LB +
    '  });';
end;

function TTMSFNCMapsHere.GetAddCoordinateToArray: string;
begin
  Result := '  ' + COORDINATEARRAYVAR + '.pushLatLngAlt(' + PARAMSNAME + '["Latitude"],' + PARAMSNAME + '["Longitude"]);';
end;

function TTMSFNCMapsHere.GetAddHoleToArray: string;
begin
  Result := 'var o = ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]];' + LB +
            'if (o){' + LB +
            '  ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]].pushLatLngAlt(' + PARAMSNAME + '[1]["Latitude"], ' + PARAMSNAME + '[1]["Longitude"]);' + LB +
            '}else{' + LB +
            '  ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]] = new ' + MAPSERVICEVAR + '.geo.LineString();' +
            '  ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]].pushLatLngAlt(' + PARAMSNAME + '[1]["Latitude"], ' + PARAMSNAME + '[1]["Longitude"]);' + LB +
            '}';
end;

function TTMSFNCMapsHere.GetAddOrUpdateMarker: string;
begin
  Result :=
    '  var pos = {' + LB +
    '    lat: ' + PARAMSNAME + '["Latitude"],' + LB +
    '    lng: ' + PARAMSNAME + '["Longitude"]' + LB +
    '  }' + LB +

    '  var opt;' + LB +
    '  if ((' + PARAMSNAME + '["IconURL"] != "") && ' +
    '   ((' + PARAMSNAME + '["IconURL"].toLowerCase().startsWith("http")) || (' + PARAMSNAME + '["IconURL"].toLowerCase().startsWith("data:image/")))){' + LB +
    '    opt = {' + LB +
    '      icon: new ' + MAPSERVICEVAR + '.map.Icon(' + PARAMSNAME + '["IconURL"]),' + LB +
    '      visibility: ' + PARAMSNAME + '["Visible"]' + LB +
    '      , volatility: true' + LB +
    '    }' + LB +
    '  }else{' + LB +
    '    opt = {' + LB +
    '      visibility: ' + PARAMSNAME + '["Visible"]' + LB +
    '      , volatility: true' + LB +
    '    }' + LB +
    '  }' + LB + LB +

    '  if (!' + MARKERVAR + '){' + LB +
    '    ' + MARKERVAR + ' = new ' + MAPSERVICEVAR + '.map.Marker(pos, opt);' + LB +
    '    ' + MARKERVAR + '.addEventListener(''tap'', function(event){' + LB +
    '      if (' + PARAMSNAME + '["Title"]){' + LB +
    '        var xy = ' + MAPVAR + '.geoToScreen(event.target.getGeometry());' + LB +
    '        var newpos = ' + MAPVAR + '.screenToGeo(xy.x, xy.y - 50);' + LB +
    '        var bubble =  new ' + MAPSERVICEVAR + '.ui.InfoBubble(newpos, {' + LB +
    '          content: event.target.getData()' + LB +
    '        });' + LB +
    '        ' + MAPUI + '.addBubble(bubble);' + LB +
    '      }' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.addEventListener(''dbltap'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.addEventListener(''pointerup'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.addEventListener(''pointerdown'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.addEventListener(''pointerleave'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.addEventListener(''pointerenter'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.setData(' + PARAMSNAME + '["Title"]);' + LB +

    '    ' + MAPVAR + '.addObject(' + MARKERVAR + ');' + LB +

    '  }else{' + LB +
    '    ' + MARKERVAR + '.setGeometry(pos);' + LB +
    '    ' + MARKERVAR + '.setData(' + PARAMSNAME + '["Title"]);' + LB +
    '    ' + MARKERVAR + '.setVisibility(' + PARAMSNAME + '["Visible"]);' + LB +
    '  }';
end;

function TTMSFNCMapsHere.GetAddOrUpdatePolyElement: string;
begin
  Result :=
    '  var c = ' + PARAMSNAME + '["$type"];' + LB +
    '  var options;' + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsPolyline":' + LB +
    '    options = {' + LB +
    '      visibility: ' + PARAMSNAME + '["Visible"],' + LB +
    '      style: {' + LB +
    '        strokeColor: toRGBA(' + PARAMSNAME + '["StrokeColor"], ' + PARAMSNAME + '["StrokeOpacity"]),' + LB +
    '        lineWidth: ' + PARAMSNAME + '["StrokeWidth"]' + LB +
    '      },' + LB +
    '    };' + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '    case "TTMSFNCMapsPolygon":' + LB +
    '    case "TTMSFNCHerePolygon":' + LB +
    '    options = {' + LB +
    '      visibility: ' + PARAMSNAME + '["Visible"],' + LB +
    '      style: {' + LB +
    '        fillColor: toRGBA(' + PARAMSNAME + '["FillColor"], ' + PARAMSNAME + '["FillOpacity"]),' + LB +
    '        strokeColor: toRGBA(' + PARAMSNAME + '["StrokeColor"], ' + PARAMSNAME + '["StrokeOpacity"]),' + LB +
    '        lineWidth: ' + PARAMSNAME + '["StrokeWidth"]' + LB +
    '      }' + LB +
    '    };' + LB +
    '    break;' + LB +
    '   }'+ LB + LB +

    '  if (!' + POLYELEMENTVAR + '){' + LB +
    '    switch(c){' + LB +
    '      case "TTMSFNCMapsCircle":' + LB +
    '      ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.map.Circle({lat: ' + PARAMSNAME + '["Center"]["Latitude"], lng: ' + PARAMSNAME + '["Center"]["Longitude"]}, ' + PARAMSNAME + '["Radius"], options);' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsRectangle":' + LB +
    '      ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.map.Rect(new ' + MAPSERVICEVAR + '.geo.Rect(' +
             PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"], ' +
             PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"]), options);' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsPolyline":' + LB +
    '      ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.map.Polyline(' + COORDINATEARRAYVAR + ', options);' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsPolygon":' + LB +
    '      case "TTMSFNCHerePolygon":' + LB +
    '        co = ' + COORDINATEARRAYVAR + ';' + LB +
    '        var pthArr = [];' + LB +
    '        for (var key in ' + HOLEARRAYVAR + '){' + LB +
    '          pthArr.push(' + HOLEARRAYVAR + '[key]);' + LB +
    '        }' + LB +
    '        co = new ' + MAPSERVICEVAR + '.geo.Polygon(co, pthArr);' +
    '        ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.map.Polygon(co, options);' + LB +
    '      break;' + LB +
    '    }' + LB +
    '    ' + POLYELEMENTVAR + '.addEventListener(''tap'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.addEventListener(''dbltap'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.addEventListener(''pointerup'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.addEventListener(''pointerdown'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.addEventListener(''pointerleave'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.addEventListener(''pointerenter'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPVAR + '.addObject(' + POLYELEMENTVAR + ');' + LB +
    '  }else{' + LB +
    '    ' + POLYELEMENTVAR + '.setStyle(options.style);' + LB +
    '    ' + POLYELEMENTVAR + '.setVisibility(options.visibility);' + LB +
    '    switch(c){' + LB +
    '      case "TTMSFNCMapsCircle":' + LB +
    '      ' + POLYELEMENTVAR + '.setCenter({lat: ' + PARAMSNAME + '["Center"]["Latitude"], lng: ' + PARAMSNAME + '["Center"]["Longitude"]});' + LB +
    '      ' + POLYELEMENTVAR + '.setRadius(' + PARAMSNAME + '["Radius"]);' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsRectangle":' + LB +
    '      ' + POLYELEMENTVAR + '.setBoundingBox(new ' + MAPSERVICEVAR + '.geo.Rect(' +
             PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"], ' +
             PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"]));' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsPolyline":' + LB +
    '      ' + POLYELEMENTVAR + '.setGeometry(' + COORDINATEARRAYVAR + ');' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsPolygon":' + LB +
    '      case "TTMSFNCHerePolygon":' + LB +
    '        co = ' + COORDINATEARRAYVAR + ';' + LB +
    '        var pthArr = [];' + LB +
    '        for (var key in ' + HOLEARRAYVAR + '){' + LB +
    '          pthArr.push(' + HOLEARRAYVAR + '[key]);' + LB +
    '        }' + LB +
    '        ' + POLYELEMENTVAR + '.setGeometry(new ' + MAPSERVICEVAR + '.geo.Polygon(co, pthArr));' + LB +
    '      break;' + LB +
    '    }' + LB +
    '  }';
end;

function TTMSFNCMapsHere.GetClosePopup: string;
begin
  Result := '  ' + POPUPVAR + '.close();' + LB +
            '  ' + MAPUI + '.removeBubble(' + POPUPVAR + ');' + LB +
            '  ' + POPUPVAR + ' = null;';
end;

function TTMSFNCMapsHere.IsValid: Boolean;
begin
  Result := (MapsProperties.GetAPIKey <> '');
end;

procedure TTMSFNCMapsHere.RemoveScripts;
begin
  {$IFDEF WEBLIB}
  asm
    if (window.H){
      window.H = undefined;
    }
  end;
  {$ENDIF}
end;

end.

