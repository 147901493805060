{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCMaps.AzureMaps;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterAzureMapsService;
procedure UnRegisterAzureMapsService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCMaps, WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes
  {$IFDEF WEBLIB}
  ,Contnrs, Web
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCMapsAzureMapsService = class;

  TTMSFNCMapsAzureMapsFactoryService = class(TTMSFNCMapsFactoryService, ITMSFNCMapsServiceAzureMaps);

  TTMSFNCMapsAzureMapsService = class(TTMSFNCMapsAzureMapsFactoryService)
  protected
    function DoCreateMaps: ITMSFNCCustomMaps; override;
  end;

type
  TTMSFNCMapsAzureMaps = class;

  TTMSFNCMapsAzureMaps = class(TTMSFNCCustomMapsInterfacedObject, ITMSFNCCustomMaps)
  protected
    procedure GetHeadLinks(const AList: TTMSFNCMapsLinksList);
    procedure RemoveScripts;
    function GetResetMap: string;
    function GetShowPopup: string;
    function GetClosePopup: string;
    function GetHeadStyle: string;
    function GetDelayLoadEvent: string;
    function GetMapsServiceCheck: string;
    function GetIdentifier: string;
    function GetInitializeMap: string;
    function GetInitializeEvents: string;
    function GetAddOrUpdateMarker: string;
    function GetDeleteMarker: string;
    function GetAddOrUpdatePolyElement: string;
    function GetDeletePolyElement: string;
    function GetZoomToBounds: string;
    function GetGetBounds: string;
    function GetSetCenterCoordinate: string;
    function GetSetZoomLevel: string;
    function GetLatLonToXY: string;
    function GetXYToLatLon: string;
    function GetGetCenterCoordinate: string;
    function GetGetZoomLevel: string;
    function GetUpdateOptions: string;
    function GetGlobalVariables: string;
    function GetAddCoordinateToArray: string;
    function GetAddHoleToArray: string;
    function GetInitializeCoordinateArray: string;
    function GetInitializeHolesArray: string;
    function IsValid: Boolean;
    procedure DestroyMaps;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  MapsService: ITMSFNCMapsServiceAzureMaps;

const
  AZUREMAPSAPIVERSION = '2';
  AZUREMAPSAPIURL = 'https://atlas.microsoft.com/sdk/javascript/mapcontrol/';
  MAPSERVICEVAR = 'window.atlas';
  MAPZOOMCONTROLVAR = 'mapZoomControl';
  MAPMAPTYPECONTROLVAR = 'mapMapTypeControl';
  MAPCONTROLARRAYVAR = 'mapControls';

procedure RegisterAzureMapsService;
begin
  if not TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceAzureMaps, IInterface(MapsService)) then
  begin
    MapsService := TTMSFNCMapsAzureMapsService.Create;
    TTMSFNCMapsPlatformServices.Current.AddPlatformService(ITMSFNCMapsServiceAzureMaps, MapsService);
  end;
end;

procedure UnregisterAzureMapsService;
begin
  TTMSFNCMapsPlatformServices.Current.RemovePlatformService(ITMSFNCMapsServiceAzureMaps);
end;

{ TTMSFNCMapsAzureMapsService }

function TTMSFNCMapsAzureMapsService.DoCreateMaps: ITMSFNCCustomMaps;
begin
  Result := TTMSFNCMapsAzureMaps.Create;
end;

constructor TTMSFNCMapsAzureMaps.Create;
begin
  inherited;
end;

destructor TTMSFNCMapsAzureMaps.Destroy;
begin
  inherited;
end;

procedure TTMSFNCMapsAzureMaps.DestroyMaps;
begin
  MapsService.DestroyMaps(Self);
end;

function TTMSFNCMapsAzureMaps.GetAddCoordinateToArray: string;
begin
  Result := '  ' + COORDINATEARRAYVAR + '.push([' + PARAMSNAME + '["Longitude"],' + PARAMSNAME + '["Latitude"]]);';
end;

function TTMSFNCMapsAzureMaps.GetAddHoleToArray: string;
begin
  Result := '';
end;

function TTMSFNCMapsAzureMaps.GetAddOrUpdateMarker: string;
begin
  Result :=
    '  var popupTemplate = ''<div style="padding:10px">{title}</div>'';' + LB +
    '  var options = {' + LB +
    '    visible: ' + PARAMSNAME + '["Visible"],' + LB +
    '    position: new ' + MAPSERVICEVAR + '.data.Position(' + PARAMSNAME + '["Longitude"], ' + PARAMSNAME + '["Latitude"]),' + LB +
    '    popup: new ' + MAPSERVICEVAR + '.Popup({' + LB +
    '      content: popupTemplate.replace(/{title}/g, ' + PARAMSNAME + '["Title"]),' + LB +
    '      pixelOffset: [0, -30]' + LB +
    '    }),' + LB +
    '  }' + LB +

    '  if (' + PARAMSNAME + '["IconURL"] != ""){' + LB +
    '    options.htmlContent = ''<img src='' + ' + PARAMSNAME + '["IconURL"] + ''></img>''' + LB +
    '  }' + LB + LB +

    '  if (!' + MARKERVAR + '){' + LB +
    '    ' + MARKERVAR + ' = new ' + MAPSERVICEVAR + '.HtmlMarker(options);' + LB +
    '    ' + MAPVAR + '.events.add(''click'',' + MARKERVAR + ', function(event){' + LB +
    '      if (' + PARAMSNAME + '["Title"]){' + LB +
    '        ' + MARKERVAR + '.togglePopup();' + LB +
    '      }' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPVAR + '.events.add(''dblclick'',' + MARKERVAR + ', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPVAR + '.events.add(''mouseup'',' + MARKERVAR + ', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPVAR + '.events.add(''mousedown'',' + MARKERVAR + ', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPVAR + '.events.add(''mouseleave'',' + MARKERVAR + ', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPVAR + '.events.add(''mouseover'',' + MARKERVAR + ', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPVAR + '.markers.add(' + MARKERVAR + ');' + LB +
    '  }else{' + LB +
    '    ' +  MARKERVAR + '.setOptions(options);' + LB +
    '  }';
end;

function TTMSFNCMapsAzureMaps.GetAddOrUpdatePolyElement: string;
begin
  Result :=
    '  var c = ' + PARAMSNAME + '["$type"];' + LB +
    '  var co = [];' + LB +
    '  var pgo, plo;' + LB + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"]]);' + LB +
    '    break;' + LB +
    '  }' + LB + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsPolygon":' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '      pgo = {' + LB +
    '        visible: ' + PARAMSNAME + '["Visible"],' + LB +
    '        fillColor: toRGBA(' + PARAMSNAME + '["FillColor"], ' + PARAMSNAME + '["FillOpacity"])' + LB +
    '      }' + LB +
    '    case "TTMSFNCMapsPolyline":' + LB +
    '      plo = {' + LB +
    '        visible: ' + PARAMSNAME + '["Visible"],' + LB +
    '        strokeColor: toRGBA(' + PARAMSNAME + '["StrokeColor"], ' + PARAMSNAME + '["StrokeOpacity"]),' + LB +
    '        strokeWidth: ' + PARAMSNAME + '["StrokeWidth"]' + LB +
    '      }' + LB +
    '    break;' + LB +
    '    }' + LB + LB +

    '  if (!' + POLYELEMENTVAR + '){' + LB +
    '    ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.source.DataSource();' + LB +
    '    ' + MAPVAR + '.sources.add(' + POLYELEMENTVAR + ');' + LB +

    '    switch(c){' + LB +
    '      case "TTMSFNCMapsPolyline":' + LB +
    '      ' + POLYELEMENTVAR + '.add(new ' + MAPSERVICEVAR + '.data.LineString(' + COORDINATEARRAYVAR + '));' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsCircle":' + LB +
    '      ' + POLYELEMENTVAR + '.add(new ' + MAPSERVICEVAR + '.data.Feature(new ' + MAPSERVICEVAR + '.data.Point([' + PARAMSNAME + '["Center"]["Longitude"], ' + PARAMSNAME + '["Center"]["Latitude"]]), {subType: "Circle", radius: ' + PARAMSNAME + '["Radius"]}));' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsPolygon":' + LB +
    '      ' + POLYELEMENTVAR + '.add(new ' + MAPSERVICEVAR + '.data.Polygon([' + COORDINATEARRAYVAR + ']));' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsRectangle":' + LB +
    '      ' + POLYELEMENTVAR + '.add(new ' + MAPSERVICEVAR + '.data.Polygon([co]));' + LB +
    '      break;' + LB +
    '    }' + LB +

    '    switch(c){' + LB +
    '      case "TTMSFNCMapsCircle":' + LB +
    '      case "TTMSFNCMapsRectangle":' + LB +
    '      case "TTMSFNCMapsPolygon":' + LB +
    '      var pg = new ' + MAPSERVICEVAR + '.layer.PolygonLayer(' + POLYELEMENTVAR + ', ' + PARAMSNAME + '["ID"] + "_polygon", pgo);' + LB +
    '      var pl = new ' + MAPSERVICEVAR + '.layer.LineLayer(' + POLYELEMENTVAR + ', ' + PARAMSNAME + '["ID"] + "_polyline", plo);' + LB +
    '      ' + MAPVAR + '.layers.add(pg);' + LB +
    '      case "TTMSFNCMapsPolyline":' + LB +
    '      var pl = new ' + MAPSERVICEVAR + '.layer.LineLayer(' + POLYELEMENTVAR + ', ' + PARAMSNAME + '["ID"] + "_polyline", plo);' + LB +
    '      ' + MAPVAR + '.layers.add(pl);' + LB +
    '      break;' + LB +
    '    }' + LB +

    '    if (pg) {' + LB +
    '      ' + MAPVAR + '.events.add(''click'', pg, function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.events.add(''dblclick'', pg, function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.events.add(''mouseup'', pg, function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.events.add(''mousedown'', pg, function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.events.add(''mouseleave'', pg, function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.events.add(''mouseover'', pg, function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB +
    '    }' + LB + LB +

    '    if (pl && !pg) {' + LB +
    '      ' + MAPVAR + '.events.add(''click'', pl, function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.events.add(''dblclick'', pl, function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.events.add(''mouseup'', pl, function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.events.add(''mousedown'', pl, function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.events.add(''mouseleave'', pl, function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.events.add(''mouseover'', pl, function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB +
    '    }' + LB + LB +

    '  }else{' + LB +
    '    ' + POLYELEMENTVAR + '.clear();' + LB +
    '    switch(c){' + LB +
    '      case "TTMSFNCMapsPolyline":' + LB +
    '      ' + POLYELEMENTVAR + '.add(new ' + MAPSERVICEVAR + '.data.LineString(' + COORDINATEARRAYVAR + '));' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsCircle":' + LB +
    '      ' + POLYELEMENTVAR + '.add(new ' + MAPSERVICEVAR + '.data.Feature(new ' + MAPSERVICEVAR + '.data.Point([' + PARAMSNAME + '["Center"]["Longitude"], ' + PARAMSNAME + '["Center"]["Latitude"]]), {subType: "Circle", radius: ' + PARAMSNAME + '["Radius"]}));' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsPolygon":' + LB +
    '      ' + POLYELEMENTVAR + '.add(new ' + MAPSERVICEVAR + '.data.Polygon([' + COORDINATEARRAYVAR + ']));' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsRectangle":' + LB +
    '      ' + POLYELEMENTVAR + '.add(new ' + MAPSERVICEVAR + '.data.Polygon([co]));' + LB +
    '      break;' + LB +
    '    }' + LB +
    '    var pl = ' + MAPVAR + '.layers.getLayerById(' + PARAMSNAME + '["ID"] + "_polyline");' + LB +
    '    var pg = ' + MAPVAR + '.layers.getLayerById(' + PARAMSNAME + '["ID"] + "_polygon");' + LB +
    '    if (pl) {' + LB +
    '      pl.setOptions(plo);' + LB +
    '    }' + LB +
    '    if (pg) {' + LB +
    '      pg.setOptions(pgo);' + LB +
    '    }' + LB +
    '  }';
end;

function TTMSFNCMapsAzureMaps.GetClosePopup: string;
begin
  Result := '  ' + POPUPVAR + '.remove();' + LB +
            '  ' + POPUPVAR + ' = null;';
end;

function TTMSFNCMapsAzureMaps.GetDelayLoadEvent: string;
begin
  Result := '  ' + MAPVAR + '.events.add(''ready'', function () {';
end;

function TTMSFNCMapsAzureMaps.GetDeleteMarker: string;
begin
  Result := '  ' + MAPVAR + '.markers.remove(' + MARKERVAR + ');';
end;

function TTMSFNCMapsAzureMaps.GetDeletePolyElement: string;
begin
  Result :=
    '  var pl = ' + MAPVAR + '.layers.getLayerById(' + PARAMSNAME + '["ID"] + "_polyline");' + LB +
    '  var pg = ' + MAPVAR + '.layers.getLayerById(' + PARAMSNAME + '["ID"] + "_polygon");' + LB +
    '  var s = ' + MAPVAR + '.sources.getById(' + PARAMSNAME + '["ID"]);' + LB +
    '  if (pl) {' + LB +
    '    ' + MAPVAR + '.layers.remove(' + PARAMSNAME + '["ID"] + "_polyline");' + LB +
    '  }' + LB +
    '  if (pg) {' + LB +
    '    ' + MAPVAR + '.layers.remove(' + PARAMSNAME + '["ID"] + "_polygon");' + LB +
    '  }' + LB +
    '  if (s) {' + LB +
    '    ' + MAPVAR + '.sources.remove(' + PARAMSNAME + '["ID"]);' + LB +
    '  }';
end;

function TTMSFNCMapsAzureMaps.GetGetBounds: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getCamera().bounds;' + LB +
    '  jsonObj["NorthEast"]["Latitude"] = loc[1];' + LB +
    '  jsonObj["NorthEast"]["Longitude"] = loc[0];' + LB +
    '  jsonObj["SouthWest"]["Latitude"] = loc[3];' + LB +
    '  jsonObj["SouthWest"]["Longitude"] = loc[2];';
end;

function TTMSFNCMapsAzureMaps.GetGetCenterCoordinate: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getCamera().center;' + LB +
    '  jsonObj["Latitude"] = loc[1];' + LB +
    '  jsonObj["Longitude"] = loc[0];';
end;

function TTMSFNCMapsAzureMaps.GetLatLonToXY: string;
begin
  Result :=
    '  var pos = [parseFloat(' + PARAMSNAME + '["Longitude"]), parseFloat(' + PARAMSNAME + '["Latitude"])];' + LB +

    '  var ts = 512;' + LB +
    '  var zo = ' + MAPVAR + '.getCamera().zoom;' + LB +
    '  var MinLatitude = -85.05112878;' + LB +
    '  var MaxLatitude = 85.05112878;' + LB +
    '  var MinLongitude = -180;' + LB +
    '  var MaxLongitude = 180;' + LB +

    '  function Clip(n, minValue, maxValue) {' + LB +
    '    return Math.min(Math.max(n, minValue), maxValue);' + LB +
    '  }' + LB +

    '  function MapSize(zoom, tileSize) {' + LB +
    '    return Math.ceil(tileSize * Math.pow(2, zoom));' + LB +
    '  }' + LB +

    '  function PositionToGlobalPixel(position, zoom, tileSize) {' + LB +

    '    var latitude = Clip(position[1], MinLatitude, MaxLatitude);' + LB +
    '    var longitude = Clip(position[0], MinLongitude, MaxLongitude);' + LB +

    '    var x = (longitude + 180) / 360;' + LB +
    '    var sinLatitude = Math.sin(latitude * Math.PI / 180);' + LB +
    '    var y = 0.5 - Math.log((1 + sinLatitude) / (1 - sinLatitude)) / (4 * Math.PI);' + LB +

    '    var ms = MapSize(zoom, tileSize);' + LB +

    '    return [' + LB +
    '       Clip(x * ms + 0.5, 0, ms - 1),' + LB +
    '       Clip(y * ms + 0.5, 0, ms - 1)' + LB +
    '    ];' + LB +
    '  }' + LB +

    '  var centerPixel = PositionToGlobalPixel(' + MAPVAR + '.getCamera().center, zo, ts);' + LB +

    '  var w = ' + MAPVAR + '.getCanvas().offsetWidth;' + LB +
    '  var h = ' + MAPVAR + '.getCanvas().offsetHeight;' + LB +

    '  var topLeftPixel = [' + LB +
    '     centerPixel[0] - w / 2,' + LB +
    '     centerPixel[1] - h / 2' + LB +
    '  ];' + LB +

    '  var transform = (position) => {' + LB +
    '      var pixel = PositionToGlobalPixel(position, zo, ts);' + LB +

    '      pixel[0] -= topLeftPixel[0];' + LB +
    '      pixel[1] -= topLeftPixel[1];' + LB +

    '      return pixel;' + LB +
    '  };' + LB +

    '  var pixel = transform(pos);' + LB +

    '  jsonObj["X"] = parseFloat(pixel[0]);' + LB +
    '  jsonObj["Y"] = parseFloat(pixel[1]);';
end;

function TTMSFNCMapsAzureMaps.GetXYToLatLon: string;
begin
  Result := '';
end;

function TTMSFNCMapsAzureMaps.GetGetZoomLevel: string;
begin
  Result := '  var z = ' + MAPVAR + '.getCamera().zoom;';
end;

function TTMSFNCMapsAzureMaps.GetGlobalVariables: string;
begin
  Result :=
    'var ' + MAPZOOMCONTROLVAR + ';' + LB +
    'var ' + MAPMAPTYPECONTROLVAR + ';' + LB +
    'var ' + MAPCONTROLARRAYVAR + ' = [];' + LB +
    'function parseEvent(event, eventname, id = ''''){' + LB +
    '  var loc = {''Latitude'': 0, ''Longitude'': 0};' + LB +
    '  var x = 0.0;' + LB +
    '  var y = 0.0;' + LB +
    '  if ((eventname == "MapMoveStart") || (eventname == "MapMoveEnd")){ ' +
    '    var centerpt = ' + MAPVAR + '.getCamera().center;' + LB +
    '    loc = {''Latitude'': centerpt[1], ''Longitude'': centerpt[0]};' + LB +
    '  } else if (event) {' + LB +
    '    if (event.position){' + LB +
    '      loc = {''Latitude'': event.position[1], ''Longitude'': event.position[0]};' + LB +
    '    }' + LB +
    '    if (event.pixel){' + LB +
    '      x = event.pixel[0];' + LB +
    '      y = event.pixel[1];' + LB +
    '    }' + LB +
    '  }' + LB +

    '  var r = {''Coordinate'': loc, ''X'': x, ''Y'': y, ''ID'': id, ''EventName'': eventname};' + LB +
    '  return r;' + LB +
    '}';
end;

procedure TTMSFNCMapsAzureMaps.GetHeadLinks(
  const AList: TTMSFNCMapsLinksList);
begin
  AList.Add(TTMSFNCMapsLink.CreateLink(AZUREMAPSAPIURL + AZUREMAPSAPIVERSION +'/atlas.min.css', 'text/css', 'stylesheet'));
  AList.Add(TTMSFNCMapsLink.CreateScript(AZUREMAPSAPIURL + AZUREMAPSAPIVERSION + '/atlas.min.js'));
  AList.Add(TTMSFNCMapsLink.CreateScript(AZUREMAPSAPIURL + AZUREMAPSAPIVERSION + '/atlas-service.min.js'));
end;

function TTMSFNCMapsAzureMaps.GetHeadStyle: string;
begin
  Result :=
    '.customInfobox {' + LB +
    '  white-space:normal;' + LB +
    '  padding: 10px;' + LB +
    '}';
end;

function TTMSFNCMapsAzureMaps.GetIdentifier: string;
begin
  Result := 'Azure Maps';
end;

function TTMSFNCMapsAzureMaps.GetInitializeCoordinateArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsAzureMaps.GetInitializeHolesArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsAzureMaps.GetInitializeEvents: string;
begin
  Result :=
  '  ' + MAPVAR + '.events.add(''zoom'', function(){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(null, "ZoomChanged"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.events.add(''styledata'', function(){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(null, "MapTypeChanged"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.events.add(''dragstart'', function(event){' + LB +
  '    if (event.originalEvent.target.tagName == "CANVAS")' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMoveStart"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.events.add(''dragend'', function(event){' + LB +
  '    if (event.originalEvent.target.tagName == "CANVAS")' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMoveEnd"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.events.add(''click'', function(event){' + LB +
  '    if (event.originalEvent.target.tagName == "CANVAS" && ((event.shapes.length > 0 && event.shapes[0].constructor.name == "Dp") || event.shapes.length == 0))' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapClick"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.events.add(''dblclick'', function(event){' + LB +
  '    if (event.originalEvent.target.tagName == "CANVAS" && ((event.shapes.length > 0 && event.shapes[0].constructor.name == "Dp") || event.shapes.length == 0))' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapDblClick"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.events.add(''mouseup'', function(event){' + LB +
  '    if (event.originalEvent.target.tagName == "CANVAS" && ((event.shapes.length > 0 && event.shapes[0].constructor.name == "Dp") || event.shapes.length == 0))' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseUp"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.events.add(''mousedown'', function(event){' + LB +
  '    if (event.originalEvent.target.tagName == "CANVAS" && ((event.shapes.length > 0 && event.shapes[0].constructor.name == "Dp") || event.shapes.length == 0))' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseDown"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.events.add(''mouseleave'', function(event){' + LB +
  '    if (event.originalEvent.target.tagName == "CANVAS" && ((event.shapes.length > 0 && event.shapes[0].constructor.name == "Dp") || event.shapes.length == 0))' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseLeave"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.events.add(''mouseover'', function(event){' + LB +
  '    if (event.originalEvent.target.tagName == "CANVAS")' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseEnter"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.events.add(''mousemove'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseMove"));' + LB +
  '  })';
end;

function TTMSFNCMapsAzureMaps.GetInitializeMap: string;
var
  v: string;
begin
  v := MAPSERVICEVAR;
  Result :=
    '  ' + MAPVAR + ' = new ' + v + '.Map("' + MAPID + '", {' + LB +
    '    language: ''' + MapsProperties.GetLocale(mlmCountry) + ''',' + LB +
    '    center: [' + MapsProperties.GetDefaultLongitude + ', ' + MapsProperties.GetDefaultLatitude +'],' + LB +
    '    zoom: ' + MapsProperties.GetDefaultZoomLevel + ',' + LB +
    '    authOptions: {' + LB +
    '      authType: "subscriptionKey",' + LB +
    '      subscriptionKey: "' + MapsProperties.GetAPIKey + '"' + LB +
    '    }' + LB +
    '  });' + LB + LB;

    Result := Result + '  ' + MAPZOOMCONTROLVAR + ' = new ' + MAPSERVICEVAR + '.control.ZoomControl();' + LB;
    Result := Result + '  ' + MAPMAPTYPECONTROLVAR + ' = new ' + MAPSERVICEVAR + '.control.StyleControl({mapStyles: ''all''});' + LB;

    if MapsProperties.GetShowZoomControl then
    begin
      Result := Result +
      '  ' + MAPVAR + '.controls.add(' + MAPZOOMCONTROLVAR + ', {' + LB +
      '     position: ''bottom-right''' + LB +
      '  });' + LB +

      '  ' + MAPCONTROLARRAYVAR + '.push(' + MAPZOOMCONTROLVAR + ');' + LB + LB;
    end;

    if not MapsProperties.GetZoomOnDblClick then
    begin
      Result := Result +
      '  ' + MAPVAR + '.setUserInteraction({dblClickZoomInteraction: false})' + LB + LB;
    end;

    if not MapsProperties.GetPanning then
    begin
      Result := Result +
      '  ' + MAPVAR + '.setUserInteraction({dragPanInteraction: false})' + LB + LB;
    end;

    if not MapsProperties.GetZoomOnWheelScroll then
    begin
      Result := Result +
      '  ' + MAPVAR + '.setUserInteraction({scrollZoomInteraction: false})' + LB + LB;
    end;

    if MapsProperties.GetShowMapTypeControl then
    begin
      Result := Result +
      '  ' + MAPVAR + '.controls.add(' + MAPMAPTYPECONTROLVAR + ', {' + LB +
      '     position: ''bottom-right''' + LB +
      '  });' + LB +

      '  ' + MAPCONTROLARRAYVAR + '.push(' + MAPMAPTYPECONTROLVAR + ');' + LB + LB;
    end;
end;

function TTMSFNCMapsAzureMaps.GetMapsServiceCheck: string;
begin
  Result := '!' + MAPSERVICEVAR;
end;

function TTMSFNCMapsAzureMaps.GetResetMap: string;
begin
  Result := '';
end;

function TTMSFNCMapsAzureMaps.GetSetCenterCoordinate: string;
begin
  Result :=
  '  ' + MAPVAR + '.setCamera({' + LB +
  '    center: [' + PARAMSNAME + '["Longitude"], ' + PARAMSNAME + '["Latitude"]] ' + LB +
  '  });';
end;

function TTMSFNCMapsAzureMaps.GetSetZoomLevel: string;
begin
  Result :=
  '  ' + MAPVAR + '.setCamera({' + LB +
  '    zoom: ' + PARAMSNAME + LB +
  '  });';
end;

function TTMSFNCMapsAzureMaps.GetShowPopup: string;
begin
  Result :=
  '  var popupTemplate = ''<div class="customInfobox">{text}</div>'';' + LB +
  '  ' + POPUPVAR + ' = new ' + MAPSERVICEVAR + '.Popup({' + LB +
  '    pixelOffset: [' + PARAMSNAME + '["OffsetX"], ' + PARAMSNAME + '["OffsetY"]],' + LB +
  '    content: popupTemplate.replace(/{text}/g, ' + PARAMSNAME + '["Text"]),' + LB +
  '    position: new ' + MAPSERVICEVAR + '.data.Position(' + PARAMSNAME + '["Longitude"], ' + PARAMSNAME + '["Latitude"]),' + LB +
  '  });' + LB +
  '  ' + MAPVAR + '.events.add(''close'',' + POPUPVAR + ', function(event){' + LB +
  '    ' + POPUPVAR + '.remove();' + LB +
  '    ' + POPUPVAR + ' = null' + LB +
  '  })' + LB + LB +
  '  ' + POPUPVAR + '.open(' + MAPVAR + ');';
end;

function TTMSFNCMapsAzureMaps.GetUpdateOptions: string;
begin
  Result := Result +
    '  ' + MAPCONTROLARRAYVAR + '.forEach(removeElement);' + LB +
    '  function removeElement(value){' + LB +
    '    ' + MAPVAR + '.controls.remove([value]);' + LB +
    '  }' + LB +
    '  ' + MAPCONTROLARRAYVAR + ' = [];' + LB + LB;

  Result := Result +
    '  ' + MAPVAR + '.setUserInteraction({dblClickZoomInteraction: ' + PARAMSNAME + '["ZoomOnDblClick"]})' + LB + LB;

  Result := Result +
    '  ' + MAPVAR + '.setUserInteraction({dragPanInteraction: ' + PARAMSNAME + '["Panning"]})' + LB + LB;

  Result := Result +
    '  ' + MAPVAR + '.setUserInteraction({scrollZoomInteraction: ' + PARAMSNAME + '["ZoomOnWheelScroll"]})' + LB + LB;

  Result := Result +
    '  if (' + PARAMSNAME + '["ShowZoomControl"]){' + LB +
    '    ' + MAPVAR + '.controls.add(' + MAPZOOMCONTROLVAR + ', {' + LB +
    '       position: ''bottom-right''' + LB +
    '    });' + LB +
    '    ' + MAPCONTROLARRAYVAR + '.push(' + MAPZOOMCONTROLVAR + ');' + LB +
    '  }' + LB + LB +

    '  if (' + PARAMSNAME + '["ShowMapTypeControl"]){' + LB +
    '    ' + MAPVAR + '.controls.add(' + MAPMAPTYPECONTROLVAR + ', {' + LB +
    '       position: ''bottom-right''' + LB +
    '    });' + LB +
    '    ' + MAPCONTROLARRAYVAR + '.push(' + MAPMAPTYPECONTROLVAR + ');' + LB +
    '  }';
end;

function TTMSFNCMapsAzureMaps.GetZoomToBounds: string;
begin
  Result :=
  '  ' + MAPVAR + '.setCamera({' + LB +
  '    bounds: [' + PARAMSNAME + '["SouthWest"]["Longitude"], ' + PARAMSNAME + '["SouthWest"]["Latitude"] , ' + PARAMSNAME + '["NorthEast"]["Longitude"], ' + PARAMSNAME + '["NorthEast"]["Latitude"]], ' + LB +
  '    padding: 10' + LB +
  '  });';
end;

function TTMSFNCMapsAzureMaps.IsValid: Boolean;
begin
  Result := (MapsProperties.GetAPIKey <> '');
end;

procedure TTMSFNCMapsAzureMaps.RemoveScripts;
begin
  {$IFDEF WEBLIB}
  asm
    if (window.atlas){
      window.atlas = undefined;
    }
  end;
  {$ENDIF}
end;

end.

