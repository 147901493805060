{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCMaps;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

{$IFNDEF LCLLIB}
{$HINTS OFF}
{$IF COMPILERVERSION > 23}
{$DEFINE UNREGISTER}
{$IFEND}
{$HINTS ON}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE UNREGISTER}
{$ENDIF}

{$IFDEF WEBLIB}
{$DEFINE NEEDSSTRINGIFY}
{$ENDIF}
{$IFDEF MACOS}
{$DEFINE NEEDSSTRINGIFY}
{$ENDIF}

interface

uses
  Classes, Types, DateUtils, WEBLib.Forms
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, XmlIntf, XMLDom, XMLDoc, Variants
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl, XMLRead, XMLWrite, DOM
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,web, Contnrs, WEBLib.Controls
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,WEBLIB.JSON
  {$ENDIF}
  ,WEBLib.TMSFNCRouteCalculator
  ,WEBLib.TMSFNCMapsCommonTypes
  ,WEBLib.TMSFNCTypes
  ,WEBLib.TMSFNCJSONReader
  ,WEBLib.TMSFNCGraphicsTypes
  ,WEBLib.TMSFNCWebBrowser;

const
  TTMSFNCMapsDocURL = TTMSFNCBaseDocURL + 'tmsfncmaps/';
  TTMSFNCMapsTipsURL = 'https://www.tmssoftware.com/site/tmsfncmaps.asp?s=faq';
  MAJ_VER = 2; // Major version nr.
  MIN_VER = 6; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 0; // Build nr.

  //v1.0.0.0: First release
  //v1.0.0.1: Improved : property LocalFileAccess to provide access to local files such as images for markers (HERE Maps not supported)
  //v1.0.1.0: New : GetZoomLevel, GetCenterCoordinate with OnGetZoomLevel and OnGetCenterCoordinate asynchronous events
  //        : New : Options.ZoomOnDblClick, Options.ZoomOnWheelScroll, Options.Panning
  //v1.0.1.1: Fixed : Issue with Openlayers being slow when having multiple elements such as markers and polygons, polylines
  //        : Fixed : Issue with Bing Maps ZoomOnDblClick also disabling ZoomOnWheelScroll
  //v1.1.0.0: New : LoadGPXFromFile/LoadGPXFromStream/LoadGPXFromText now also supports elevation and timestamp data
  //        : New : Events OnCreateGPXTrack, OnCreateGPXSegment
  //v1.1.0.1: Fixed : Issue with updating high number of poly elements
  //v1.2.0.0: New : Support for Apple MapKit JS
  //        : New : GetBounds
  //        : Improved : Event propagation across different services
  //        : Fixed : Issue with updating high number of markers
  //v1.2.1.0: New : SaveToGPXFile, SaveToGPXStream, SaveToGPXText support
  //        : New : Support for Plus Codes encoding/decoding via TTMSFNCMapsPlusCode (available in *TMSFNCMapsCommonTypes unit)
  //v1.2.2.0: New : OnCreateGeoJSONObject event
  //v1.2.2.1: Fixed : Issue with creating and destroying maps instance
  //v1.2.2.2: Fixed : Issue resizing OpenLayers in TMS WEB Core
  //v1.2.2.3: Fixed : Issue with LoadGPX for Elevation & Timestamp
  //        : Fixed : Issue with GetBounds not returning correct coordinates in OpenLayers
  //v1.2.2.4: Fixed : Issue with OnRouteCalculatorWayPointUpdated event not being triggered correctly for the end segment
  //v1.2.2.5: Improved : RouteCalculator custom Markers config
  //v1.2.3.0: New : OnMapMoveStart, OnMapMoveEnd events
  //        : Fixed : Issue with map events in Azure Maps
  //v1.2.4.0: New : LatLonToXY & XYToLatLon functions for Google Maps, MapBox and Here
  //v1.2.4.1: Fixed : Issue with displaying custom marker icons for Google Maps
  //v2.0.0.0: New : ElementContainers collection
  //        : New : HeadLinks collection
  //v2.0.1.0: New : MeasureArea: Calculate PolyElement area in square metres
  //        : Fixed : Issue in OpenLayers marker & poly element click detection
  //v2.0.1.1: Fixed : Issue with title popup being shown more than once
  //v2.0.2.0: New : IsPointInArea / PolyElement.ContainsPoint: Check if coordinate is inside Poly area
  //v2.0.3.0: New : PolyLine Distance property
  //v2.0.4.0: New : Route calculator extended with support for MapBox & TomTom
  //v2.0.4.1: Fixed : OpenLayers API URLs updated
  //v2.0.5.0: New : LoadGeoJSON complex polygon support added
  //        : Fixed : Issue with displaying polylines in Google Maps
  //v2.1.0.0: New : Labels
  //        : New : ElementContainers: Coordinate positioning support
  //        : New : LatLonToXY & XYToLatLon functions for MapKit, Azure, OpenLayers, Bing
  //        : Fixed : Azure Maps: Fixed issue with Map events due to changes in the API
  //v2.1.1.0: New : Bounds positioning for ElementContainers and Labels
  //v2.5.0.0: New : Leaflet mapping service support
  //v2.6.0.0: New : OpenLayers v8 API compatibilty
  //        : Fixed : MapKit: Fixed issue with adding polylines
  //v2.6.1.0: New : ShowPopup PanMap parameter added (Google, OpenLayers, Leaflet)

  LB = #13;
  STARTHTMLTAG = '<html>';
  STARTHEADTAG = '<head>';
  STARTBODYTAG = '<body>';
  STARTTITLETAG = '<title>';
  STARTSCRIPTTAG = '<script>';
  STARTSTYLETAG = '<style>';
  ENDHTMLTAG = '</html>';
  ENDHEADTAG = '</head>';
  ENDBODYTAG = '</body>';
  ENDTITLETAG = '</title>';
  ENDSCRIPTTAG = '</script>';
  ENDSTYLETAG = '</style>';
  MAPID = 'mapContainer';
  MARKERVAR = 'marker';
  ELEMENTCONTAINERVAR = 'elementContainer';
  POPUPVAR = 'popup';
  POLYELEMENTVAR = 'polyelement';
  MAPVAR = 'map';
  MAPOPTIONS = 'mapoptions';
  MAPBOUNDS = 'mapbounds';
  MAPCOMPONENT = 'mapcomponent';
  PARAMSNAME = 'params';
  MARKERARRAYVAR = 'markerarray';
  ELEMENTCONTAINERARRAYVAR = 'elementContainerArray';
  POPUPARRAYVAR = 'popuparray';
  GETMARKERARRAYVAR = 'get' + MARKERARRAYVAR + '()';
  GETELEMENTCONTAINERARRAYVAR = 'get' + ELEMENTCONTAINERARRAYVAR + '()';
  GETSENDEVENT = 'sendEvent';
  GETFOCUSMAP = 'focusMap';
  POLYELEMENTARRAYVAR = 'polyelementarray';
  COORDINATEARRAYVAR = 'coordinatearray';
  HOLEARRAYVAR = 'holearray';
  GETPOLYELEMENTARRAYVAR = 'get' + POLYELEMENTARRAYVAR + '()';
  GETPOPUPARRAYVAR = 'get' + POPUPARRAYVAR + '()';
  DEFAULT_ZOOMLEVEL = 12;
  DEFAULT_MARKERTEXT = 'Sample Marker';
  SHOWPOPUPFUNCTION = 'showPopup';
  CLOSEPOPUPFUNCTION = 'closePopup';
  ADDORUPDATEMARKERFUNCTION = 'addOrUpdateMarker';
  DELETEMARKERFUNCTION = 'deleteMarker';
  ADDORUPDATEELEMENTCONTAINERFUNCTION = 'addOrUpdateElementContainer';
  DELETEELEMENTCONTAINERFUNCTION = 'deleteElementContainer';
  ADDORUPDATEPOLYELEMENTFUNCTION = 'addOrUpdatePolyElement';
  INITIALIZECOORDINATEARRAY = 'initializeCoordinateArray';
  INITIALIZEHOLESARRAY = 'initializeHolesArray';
  ADDCOORDINATETOARRAY = 'addCoordinateToArray';
  ADDHOLETOARRAY = 'addHoleToArray';
  ADDCOORDINATESTOARRAY = 'addCoordinatesToArray';
  ADDHOLESTOARRAY = 'addHolesToArray';
  DELETEPOLYELEMENTFUNCTION = 'deletePolyElement';
  ZOOMTOBOUNDSFUNCTION = 'zoomToBounds';
  SETCENTERCOORDINATEFUNCTION = 'setCenterCoordinate';
  GETCENTERCOORDINATEFUNCTION = 'getCenterCoordinate';
  GETLATLONTOXYFUNCTION = 'getLatLonToXY';
  GETXYTOLATLONFUNCTION = 'getXYToLatLon';
  GETBOUNDSFUNCTION = 'getBounds';
  SETZOOMLEVELFUNCTION = 'setZoomLevel';
  GETZOOMLEVELFUNCTION = 'getZoomLevel';
  UPDATEOPTIONSFUNCTION = 'updateOptions';
  DEBUGCONSOLELINK = 'https://download.tmssoftware.com/webgmaps/firebug/firebug-lite.js#startOpened';
  ROUTECALCULATORROUTE = '#TTMSFNCMapsRouteCalculatorRoute';
  ROUTECALCULATORSEGMENT = '#TTMSFNCMapsRouteCalculatorSegment';
  ROUTECALCULATORMARKER = '#TTMSFNCRouteCalculatorMarker';
  ROUTECALCULATORSTARTMARKER = '#TTMSFNCRouteCalculatorStartMarker';
  ROUTECALCULATORENDMARKER = '#TTMSFNCRouteCalculatorEndMarker';
  ROUTECALCULATORDRAGMARKER = '#TMSFNCRouteCalculatorDragMarker';
  ACTIONCLICKEVENT = 'ActionClick';
  ACTIONDBLCLICKEVENT = 'ActionDblClick';
  ACTIONKEYPRESSEVENT = 'ActionKeyPress';
  ACTIONKEYDOWNEVENT = 'ActionKeyDown';
  ACTIONKEYUPEVENT = 'ActionKeyUp';
  ACTIONMOUSEDOWNEVENT = 'ActionMouseDown';
  ACTIONMOUSEMOVEEVENT = 'ActionMouseMove';
  ACTIONMOUSEUPEVENT = 'ActionMouseUp';
  ACTIONMOUSEENTEREVENT = 'ActionMouseEnter';
  ACTIONMOUSELEAVEEVENT = 'ActionMouseLeave';
  ACTIONBLUREVENT = 'ActionBlur';
  ACTIONFOCUSEVENT = 'ActionFocus';
  ACTIONCHANGEEVENT = 'ActionChange';
  ACTIONSELECTEVENT = 'ActionSelect';
  ACTIONCUSTOMEVENT = 'ActionCustomEvent';

type
  TTMSFNCCustomMaps = class;

  ITMSFNCCustomMapsProperties = interface(IInterface)
    ['{A081EF51-171D-4C04-9F2C-28A4EBE9E1DE}']
    function GetDefaultLatitude: string;
    function GetDefaultLongitude: string;
    function GetDefaultZoomLevel: string;
    function GetAPIKey: string;
    function GetLocale(AMode: TTMSFNCMapsLocaleMode = mlmDefault): string;
    function GetShowZoomControl: Boolean;
    function GetZoomOnDblClick: Boolean;
    function GetPanning: Boolean;
    function GetMapID: string;
    function GetAPIVersion: string;
    function GetHeading: Double;
    function GetTilt: Double;
    function GetZoomOnWheelScroll: Boolean;
    function GetShowMapTypeControl: Boolean;
    function GetBackgroundColor: TTMSFNCGraphicsColor;
  end;

  ITMSFNCCustomMapsInstance = interface(IInterface)
    ['{419FAD22-8164-47EB-8CFE-C60BBFF57751}']
    procedure SetMapsProperties(const Value: ITMSFNCCustomMapsProperties);
  end;

  TTMSFNCMapsLinkKind = (mlkLink, mlkScript, mlkStyle);

  TTMSFNCMapsLink = class
  private
    FCharSet: string;
    FType: string;
    FURL: string;
    FContent: string;
    FDefer: Boolean;
    FAsync: Boolean;
    FRel: string;
    FKind: TTMSFNCMapsLinkKind;
  public
    constructor CreateScript(AURL: string; AType: string = ''; ACharSet: string = ''; AContent: string = ''; ADefer: Boolean = False; AAsync: Boolean = False); virtual;
    constructor CreateLink(AURL: string; AType: string = ''; ARel: string = ''); virtual;
    constructor Create(AKind: TTMSFNCMapsLinkKind; AURL: string; AType: string; ACharSet: string; ARel: string; AContent: string; ADefer: Boolean; AAsync: Boolean); virtual;
    property URL: string read FURL write FURL;
    property &Type: string read FType write FType;
    property CharSet: string read FCharSet write FCharSet;
    property Content: string read FContent write FContent;
    property Rel: string read FRel write FRel;
    property Defer: Boolean read FDefer write FDefer;
    property Async: Boolean read FAsync write FAsync;
    property Kind: TTMSFNCMapsLinkKind read FKind write FKind;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCMapsLinksList = class(TObjectList)
  private
    function GetItem(Index: Integer): TTMSFNCMapsLink;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsLink);
  public
    property Items[Index: Integer]: TTMSFNCMapsLink read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCMapsLinksList = class(TObjectList<TTMSFNCMapsLink>);
  {$ENDIF}

  TTMSFNCMapsLibraryLocation = (llOffline, llOnline);
  TTMSFNCMapsService = (msGoogleMaps, msHere, msAzureMaps, msBingMaps, msTomTom, msMapBox, msOpenLayers, msMapKit, msLeaflet);

  ITMSFNCCustomMaps = interface(IInterface)
  ['{B305522A-852B-4A51-B9C0-C9D91B9A8C8B}']
    procedure GetHeadLinks(const AList: TTMSFNCMapsLinksList);
    procedure RemoveScripts;
    function GetResetMap: string;
    function GetHeadStyle: string;
    function GetDelayLoadEvent: string;
    function GetIdentifier: string;
    function GetMapsServiceCheck: string;
    function GetInitializeMap: string;
    function GetInitializeEvents: string;
    function GetAddOrUpdateMarker: string;
    function GetShowPopup: string;
    function GetClosePopup: string;
    function GetDeleteMarker: string;
    function GetAddOrUpdatePolyElement: string;
    function GetDeletePolyElement: string;
    function GetZoomToBounds: string;
    function GetSetCenterCoordinate: string;
    function GetGetCenterCoordinate: string;
    function GetLatLonToXY: string;
    function GetXYToLatLon: string;
    function GetGetBounds: string;
    function GetGetZoomLevel: string;
    function GetSetZoomLevel: string;
    function GetUpdateOptions: string;
    function GetGlobalVariables: string;
    function GetAddCoordinateToArray: string;
    function GetAddHoleToArray: string;
    function GetInitializeCoordinateArray: string;
    function GetInitializeHolesArray: string;
    function IsValid: Boolean;
    procedure DestroyMaps;
  end;

  ITMSFNCMapsService = interface(IInterface)
  ['{6E91727E-EFA6-4A74-B523-97DAF6605F09}']
    function CreateMaps: ITMSFNCCustomMaps;
    procedure DestroyMaps(AMaps: ITMSFNCCustomMaps);
  end;

  ITMSFNCMapsServiceGoogleMaps = interface(ITMSFNCMapsService)
    ['{71E5D88A-366B-4573-B54D-DE0B3848CB15}']
  end;

  ITMSFNCMapsServiceHere = interface(ITMSFNCMapsService)
    ['{646B2E30-657D-4D64-BDE7-13357E46255B}']
  end;

  ITMSFNCMapsServiceAzureMaps = interface(ITMSFNCMapsService)
    ['{CB46E849-C6D7-4B6C-AE8E-39A404FDE4BE}']
  end;

  ITMSFNCMapsServiceBingMaps = interface(ITMSFNCMapsService)
    ['{382BB281-E709-4028-9E10-3D3790C04C7B}']
  end;

  ITMSFNCMapsServiceTomTom = interface(ITMSFNCMapsService)
    ['{AFDD0113-F2BB-44FE-8DD3-4EAEC70BA851}']
  end;

  ITMSFNCMapsServiceMapBox = interface(ITMSFNCMapsService)
    ['{B141412A-AD1C-4C5F-9729-952BD04B9D6D}']
  end;

  ITMSFNCMapsServiceMapKit = interface(ITMSFNCMapsService)
    ['{3EAC77BF-DBDA-4328-910F-B2C23C5E9229}']
  end;

  ITMSFNCMapsServiceOpenLayers = interface(ITMSFNCMapsService)
    ['{424129BB-9C0E-44C5-BD30-E6399A5BC5CE}']
  end;

  ITMSFNCMapsServiceLeaflet = interface(ITMSFNCMapsService)
    ['{CEB938F6-48D3-4173-BB7C-7D5C56BFAA51}']
  end;

  TTMSFNCMapsGPXColorRec = record
    Color: TTMSFNCGraphicsColor;
  end;

  TTMSFNCMapsGPXColorRecArray = array of TTMSFNCMapsGPXColorRec;

  TTMSFNCMapsPointRec = record
    Coordinate: TTMSFNCMapsCoordinateRec;
    Name: string;
  end;

  TTMSFNCMapsPolyElementRec = record
    Coordinates: TTMSFNCMapsCoordinateRecArray;
    Holes: TTMSFNCMapsCoordinateRecArrayArray;
    Name: string;
  end;

  TTMSFNCMapsGeoJSONRec = record
    Points: array of TTMSFNCMapsPointRec;
    Polylines: array of TTMSFNCMapsPolyElementRec;
    Polygons: array of TTMSFNCMapsPolyElementRec;
  end;

  TTMSFNCMapsPopup = class(TPersistent)
  private
    FID: string;
    FText: string;
    FOffsetX, FOffsetY: Double;
    FCoordinate: TTMSFNCMapsCoordinate;
    FPanMap: Boolean;
    function GetID: string;
    function GetLatitude: Double;
    function GetLongitude: Double;
    procedure SetLatitude(const Value: Double);
    procedure SetLongitude(const Value: Double);
  public
    constructor Create; virtual;
    destructor Destroy; override;
  published
    property ID: string read GetID;
    property Text: string read FText write FText;
    property Coordinate: TTMSFNCMapsCoordinate read FCoordinate;
    property Longitude: Double read GetLongitude write SetLongitude;
    property Latitude: Double read GetLatitude write SetLatitude;
    property OffsetX: Double read FOffsetX write FOffsetX;
    property OffsetY: Double read FOffsetY write FOffsetY;
    property PanMap: Boolean read FPanMap write FPanMap;
  end;

  TTMSFNCMapsMarker = class(TCollectionItem)
  private
    FReload: Boolean;
    FID: string;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FTitle: string;
    FCoordinate: TTMSFNCMapsCoordinate;
    FIconURL: string;
    FRecreate: Boolean;
    FVisible: Boolean;
    procedure SetTitle(const Value: string);
    function GetID: string;
    function IsTitleStored: Boolean;
    procedure SetCoordinate(const Value: TTMSFNCMapsCoordinate);
    function GetLatitude: Double;
    function GetLongitude: Double;
    procedure SetLatitude(const Value: Double);
    procedure SetLongitude(const Value: Double);
    function IsLatitudeStored: Boolean;
    function IsLongitudeStored: Boolean;
    function IsIconURLStored: Boolean;
    procedure SetIconURL(const Value: string);
    procedure SetVisible(const Value: Boolean);
  protected
    FOwner: TTMSFNCCustomMaps;
    procedure UpdateMarker; virtual;
    procedure CoordinateChanged(Sender: TObject);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property Coordinate: TTMSFNCMapsCoordinate read FCoordinate write SetCoordinate;
    property Recreate: Boolean read FRecreate write FRecreate default False;
  published
    property ID: string read GetID;
    property Longitude: Double read GetLongitude write SetLongitude stored IsLongitudeStored nodefault;
    property Latitude: Double read GetLatitude write SetLatitude stored IsLatitudeStored nodefault;
    property Title: string read FTitle write SetTitle stored IsTitleStored nodefault;
    property IconURL: string read FIconURL write SetIconURL stored IsIconURLStored nodefault;
    property Visible: Boolean read FVisible write SetVisible default True;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCMapsMarkers = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCMapsMarkers = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCMapsMarker>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomMaps;
    function GetItem(Index: Integer): TTMSFNCMapsMarker;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsMarker);
    function GetItemByID(ID: string): TTMSFNCMapsMarker;
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomMaps); virtual;
    property Items[Index: Integer]: TTMSFNCMapsMarker read GetItem write SetItem; default;
    procedure Clear; virtual;
    procedure Recreate; virtual;
    function Add: TTMSFNCMapsMarker;
    function Insert(Index: Integer): TTMSFNCMapsMarker;
    function ToCoordinateArray: TTMSFNCMapsCoordinateRecArrayArray; virtual;
    property ItemByID[ID: string]: TTMSFNCMapsMarker read GetItemByID;
  end;

  TTMSFNCMapsPolyElement = class;

  TTMSFNCMapsPolyElementHole = class(TCollectionItem)
  private
    FID: string;
    FOwner: TTMSFNCMapsPolyElement;
    FCoordinates: TTMSFNCMapsCoordinates;
    function GetID: string;
    procedure SetCoordinates(const Value: TTMSFNCMapsCoordinates);
  protected
    procedure UpdatePolyElement;
    procedure DoBeginUpdate(Sender: TObject);
    procedure DoEndUpdate(Sender: TObject);
    procedure DoUpdateCoordinates(Sender: TObject);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property Coordinates: TTMSFNCMapsCoordinates read FCoordinates write SetCoordinates;
    property ID: string read GetID;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCMapsPolyElementHoles = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCMapsPolyElementHoles = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCMapsPolyElementHole>)
  {$ENDIF}
  private
    FOwner: TTMSFNCMapsPolyElement;
    function GetItem(Index: Integer): TTMSFNCMapsPolyElementHole;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsPolyElementHole);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCMapsPolyElement); virtual;
    property Items[Index: Integer]: TTMSFNCMapsPolyElementHole read GetItem write SetItem; default;
    procedure Clear; virtual;
    function Add: TTMSFNCMapsPolyElementHole;
    function Insert(Index: Integer): TTMSFNCMapsPolyElementHole;
  end;

  TTMSFNCMapsPolyElement = class(TCollectionItem)
  private
    FReload: Boolean;
    FID: string;
    FOwner: TTMSFNCCustomMaps;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FStrokeColor: TTMSFNCGraphicsColor;
    FCoordinates: TTMSFNCMapsCoordinates;
    FStrokeOpacity: Single;
    FStrokeWidth: Integer;
    FRecreate: Boolean;
    FVisible: Boolean;
    FHoles: TTMSFNCMapsPolyElementHoles;
    function GetID: string;
    procedure SetStrokeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetCoordinates(const Value: TTMSFNCMapsCoordinates);
    procedure SetHoles(const Value: TTMSFNCMapsPolyElementHoles);
    function IsStrokeOpacityStored: Boolean;
    procedure SetStrokeOpacity(const Value: Single);
    procedure SetStrokeWidth(const Value: Integer);
    procedure SetVisible(const Value: Boolean);
  protected
    procedure BeginUpdate;
    procedure EndUpdate;
    procedure UpdatePolyElement; virtual;
    procedure DoUpdateCoordinates(Sender: TObject); virtual;
    procedure DoBeginUpdate(Sender: TObject); virtual;
    procedure DoEndUpdate(Sender: TObject); virtual;
    property Coordinates: TTMSFNCMapsCoordinates read FCoordinates write SetCoordinates;
    property Holes: TTMSFNCMapsPolyElementHoles read FHoles write SetHoles;
    function AddHole(ACoordinates: TTMSFNCMapsCoordinateRecArray): TTMSFNCMapsPolyElementHole; reintroduce; virtual;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    function Area: Double; virtual;
    function ContainsPoint(ACoordinate: TTMSFNCMapsCoordinateRec): Boolean; virtual;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property Recreate: Boolean read FRecreate write FRecreate default False;
  published
    property ID: string read GetID;
    property StrokeColor: TTMSFNCGraphicsColor read FStrokeColor write SetStrokeColor default gcBlack;
    property StrokeOpacity: Single read FStrokeOpacity write SetStrokeOpacity stored IsStrokeOpacityStored nodefault;
    property StrokeWidth: Integer read FStrokeWidth write SetStrokeWidth default 2;
    property Visible: Boolean read FVisible write SetVisible default True;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCMapsPolyElements = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCMapsPolyElements = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCMapsPolyElement>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomMaps;
    function GetItem(Index: Integer): TTMSFNCMapsPolyElement;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsPolyElement);
    function GetItemByID(ID: string): TTMSFNCMapsPolyElement;
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomMaps); virtual;
    property Items[Index: Integer]: TTMSFNCMapsPolyElement read GetItem write SetItem; default;
    procedure Clear; virtual;
    procedure Recreate; virtual;
    function Add: TTMSFNCMapsPolyElement;
    function Insert(Index: Integer): TTMSFNCMapsPolyElement;
    function ToCoordinateArray: TTMSFNCMapsCoordinateRecArrayArray; virtual;
    property ItemByID[ID: string]: TTMSFNCMapsPolyElement read GetItemByID;
  end;

  TTMSFNCMapsPolyline = class(TTMSFNCMapsPolyElement)
  public
    function Distance: Double; virtual;
  published
    property Coordinates;
  end;

  TTMSFNCMapsPolylines = class(TTMSFNCMapsPolyElements)
  private
    function GetItem(Index: Integer): TTMSFNCMapsPolyline;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsPolyline);
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    property Items[Index: Integer]: TTMSFNCMapsPolyline read GetItem write SetItem; default;
    function Add: TTMSFNCMapsPolyline;
    function Insert(Index: Integer): TTMSFNCMapsPolyline;
  end;

  TTMSFNCMapsCustomPolygon = class(TTMSFNCMapsPolyElement)
  private
    FFillColor: TTMSFNCGraphicsColor;
    FFillOpacity: Single;
    procedure SetFillColor(const Value: TTMSFNCGraphicsColor);
    function IsFillOpacityStored: Boolean;
    procedure SetFillOpacity(const Value: Single);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
  published
    property FillColor: TTMSFNCGraphicsColor read FFillColor write SetFillColor default gcWhite;
    property FillOpacity: Single read FFillOpacity write SetFillOpacity stored IsFillOpacityStored nodefault;
  end;

  TTMSFNCMapsCustomPolygons = class(TTMSFNCMapsPolyElements)
  private
    function GetItem(Index: Integer): TTMSFNCMapsCustomPolygon;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsCustomPolygon);
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    property Items[Index: Integer]: TTMSFNCMapsCustomPolygon read GetItem write SetItem; default;
    function Add: TTMSFNCMapsCustomPolygon;
    function Insert(Index: Integer): TTMSFNCMapsCustomPolygon;
  end;

  TTMSFNCMapsPolygon = class(TTMSFNCMapsCustomPolygon)
  protected
    function AddHole(ACoordinates: TTMSFNCMapsCoordinateRecArray): TTMSFNCMapsPolyElementHole; override;
    property Holes;
  published
    property Coordinates;
  end;

  TTMSFNCMapsPolygons = class(TTMSFNCMapsPolyElements)
  private
    function GetItem(Index: Integer): TTMSFNCMapsPolygon;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsPolygon);
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    property Items[Index: Integer]: TTMSFNCMapsPolygon read GetItem write SetItem; default;
    function Add: TTMSFNCMapsPolygon;
    function Insert(Index: Integer): TTMSFNCMapsPolygon;
  end;

  TTMSFNCMapsRectangle = class(TTMSFNCMapsCustomPolygon)
  private
    FBounds: TTMSFNCMapsBounds;
    procedure SetBounds(const Value: TTMSFNCMapsBounds);
  protected
    procedure DoBoundsChanged(Sender: TObject);
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function Area: Double; override;
    function ContainsPoint(ACoordinate: TTMSFNCMapsCoordinateRec): Boolean; override;
  published
    property Bounds: TTMSFNCMapsBounds read FBounds write SetBounds;
  end;

  TTMSFNCMapsRectangles = class(TTMSFNCMapsCustomPolygons)
  private
    function GetItem(Index: Integer): TTMSFNCMapsRectangle;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsRectangle);
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    property Items[Index: Integer]: TTMSFNCMapsRectangle read GetItem write SetItem; default;
    function Add: TTMSFNCMapsRectangle;
    function Insert(Index: Integer): TTMSFNCMapsRectangle;
    function ToCoordinateArray: TTMSFNCMapsCoordinateRecArrayArray; override;
  end;

  TTMSFNCMapsCircle = class(TTMSFNCMapsCustomPolygon)
  private
    FRadius: Double;
    FCenter: TTMSFNCMapsCoordinate;
    procedure SetRadius(const Value: Double);
    procedure SetCenter(const Value: TTMSFNCMapsCoordinate);
    function IsRadiusStored: Boolean;
  protected
    procedure DoCenterChanged(Sender: TObject);
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function Area: Double; override;
    function ContainsPoint(ACoordinate: TTMSFNCMapsCoordinateRec): Boolean; override;
  published
    property Center: TTMSFNCMapsCoordinate read FCenter write SetCenter;
    property Radius: Double read FRadius write SetRadius stored IsRadiusStored nodefault;
  end;

  TTMSFNCMapsCircles = class(TTMSFNCMapsCustomPolygons)
  private
    function GetItem(Index: Integer): TTMSFNCMapsCircle;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsCircle);
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    property Items[Index: Integer]: TTMSFNCMapsCircle read GetItem write SetItem; default;
    function Add: TTMSFNCMapsCircle;
    function Insert(Index: Integer): TTMSFNCMapsCircle;
    function ToCoordinateArray: TTMSFNCMapsCoordinateRecArrayArray; override;
  end;

  TTMSFNCMapsHeadLink = class(TCollectionItem)
  private
    FOwner: TTMSFNCCustomMaps;
    FURL: string;
    FEnabled: Boolean;
    FRel: string;
    FDefer: Boolean;
    FCharSet: string;
    FKind: TTMSFNCMapsLinkKind;
    FType: string;
    FContent: TStringList;
    FAsync: Boolean;
    procedure SetURL(const Value: string);
    function IsURLStored: Boolean;
    procedure SetEnabled(const Value: Boolean);
    procedure SetContent(const Value: TStringList);
    procedure SetAsync(const Value: Boolean);
    procedure SetCharSet(const Value: string);
    procedure SetDefer(const Value: Boolean);
    procedure SetRel(const Value: string);
    procedure SetType(const Value: string);
    procedure SetKind(const Value: TTMSFNCMapsLinkKind);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    procedure HeadLinkChanged(Sender: TObject);
    procedure UpdateHeadLink; virtual;
  published
    property URL: string read FURL write SetURL stored IsURLStored nodefault;
    property Enabled: Boolean read FEnabled write SetEnabled default True;
    property &Type: string read FType write SetType;
    property CharSet: string read FCharSet write SetCharSet;
    property Content: TStringList read FContent write SetContent;
    property Rel: string read FRel write SetRel;
    property Defer: Boolean read FDefer write SetDefer;
    property Async: Boolean read FAsync write SetAsync;
    property Kind: TTMSFNCMapsLinkKind read FKind write SetKind;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCMapsHeadLinks = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCMapsHeadLinks = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCMapsHeadLink>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomMaps;
    function GetItem(Index: Integer): TTMSFNCMapsHeadLink;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsHeadLink);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomMaps); virtual;
    property Items[Index: Integer]: TTMSFNCMapsHeadLink read GetItem write SetItem; default;
    procedure Clear; virtual;
    function Add: TTMSFNCMapsHeadLink;
    function AddScript(AURL: string): TTMSFNCMapsHeadLink;
    function AddLink(AURL: string): TTMSFNCMapsHeadLink;
    function AddStyleSheetLink(AURL: string): TTMSFNCMapsHeadLink;
    function AddStyle(AStyle: TStrings): TTMSFNCMapsHeadLink;
    function Insert(Index: Integer): TTMSFNCMapsHeadLink;
  end;

  TTMSFNCMapsOptions = class(TPersistent)
  private
    FDefaultLongitude: Double;
    FDefaultLatitude: Double;
    FOnChange: TNotifyEvent;
    FConsole: Boolean;
    FDefaultZoomLevel: Double;
    FShowZoomControl: Boolean;
    FShowMapTypeControl: Boolean;
    FLocale: string;
    FZoomOnDblClick: Boolean;
    FZoomOnWheelScroll: Boolean;
    FPanning: Boolean;
    FBackgroundColor: TTMSFNCGraphicsColor;
    function IsDefaultLatitudeStored: Boolean;
    function IsDefaultLongitudeStored: Boolean;
    procedure SetDefaultLatitude(const Value: Double);
    procedure SetDefaultLongitude(const Value: Double);
    procedure SetConsole(const Value: Boolean);
    procedure SetDefaultZoomLevel(const Value: Double);
    procedure SetShowMapTypeControl(const Value: Boolean);
    procedure SetShowZoomControl(const Value: Boolean);
    function IsLocaleStored: Boolean;
    procedure SetLocale(const Value: string);
    procedure SetZoomOnDblClick(const Value: Boolean);
    procedure SetPanning(const Value: Boolean);
    procedure SetZoomOnWheelScroll(const Value: Boolean);
    procedure SetBackgroundColor(const Value: TTMSFNCGraphicsColor);
  protected
    procedure Changed;
    property BackgroundColor: TTMSFNCGraphicsColor read FBackgroundColor write SetBackgroundColor default gcSilver;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create; virtual;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Console: Boolean read FConsole write SetConsole default False;
    property Locale: string read FLocale write SetLocale stored IsLocaleStored nodefault;
    property DefaultLatitude: Double read FDefaultLatitude write SetDefaultLatitude stored IsDefaultLatitudeStored nodefault;
    property DefaultLongitude: Double read FDefaultLongitude write SetDefaultLongitude stored IsDefaultLongitudeStored nodefault;
    property DefaultZoomLevel: Double read FDefaultZoomLevel write SetDefaultZoomLevel;
    property ShowZoomControl: Boolean read FShowZoomControl write SetShowZoomControl default True;
    property ShowMapTypeControl: Boolean read FShowMapTypeControl write SetShowMapTypeControl default True;
    property ZoomOnDblClick: Boolean read FZoomOnDblClick write SetZoomOnDblClick default True;
    property ZoomOnWheelScroll: Boolean read FZoomOnWheelScroll write SetZoomOnWheelScroll default True;
    property Panning: Boolean read FPanning write SetPanning default True;
  end;

  TTMSFNCMapsGetDefaultHTMLMessageEvent = procedure(Sender: TObject; var ADefaultHTMLMessage: string) of object;
  TTMSFNCMapsCustomizePolyElementEvent = procedure(Sender: TObject; var ACustomizePolyElement: string) of object;
  TTMSFNCMapsCustomizeMarkerEvent = procedure(Sender: TObject; var ACustomizeMarker: string) of object;
  TTMSFNCMapsCustomizePopupEvent = procedure(Sender: TObject; var ACustomizePopup: string) of object;
  TTMSFNCMapsCustomizeOptionsEvent = procedure(Sender: TObject; var ACustomizeOptions: string) of object;
  TTMSFNCMapsCustomizeMapEvent = procedure(Sender: TObject; var ACustomizeMap: string) of object;
  TTMSFNCMapsCustomizeGlobalVariablesEvent = procedure(Sender: TObject; var ACustomizeGlobalVariables: string) of object;
  TTMSFNCMapsCustomizeJavaScriptEvent = procedure(Sender: TObject; var ACustomizeJavaScript: string) of object;
  TTMSFNCMapsCustomizeCSSEvent = procedure(Sender: TObject; var ACustomizeCSS: string) of object;
  TTMSFNCMapsCustomizeHeadLinksEvent = procedure(Sender: TObject; AList: TTMSFNCMapsLinksList) of object;
  TTMSFNCMapsCustomizeLocalAccessFileNameEvent = procedure(Sender: TObject; var AFileName: string) of object;

  TTMSFNCMapsMarkersArray = array of TTMSFNCMapsMarker;

  TTMSFNCMapsEventData = class(TPersistent)
  private
    FEvent: string;
    FCoordinate: TTMSFNCMapsCoordinate;
    FEventName: string;
    FX: Double;
    FY: Double;
    FID: string;
    FPolyElement: TTMSFNCMapsPolyElement;
    FMarker: TTMSFNCMapsMarker;
    FCustomData: string;
  public
    constructor Create; virtual;
    destructor Destroy; override;
    property Marker: TTMSFNCMapsMarker read FMarker;
    property PolyElement: TTMSFNCMapsPolyElement read FPolyElement;
    property CustomData: string read FCustomData write FCustomData;
  published
    property ID: string read FID write FID;
    property EventName: string read FEventName write FEventName;
    property Coordinate: TTMSFNCMapsCoordinate read FCoordinate;
    property X: Double read FX write FX;
    property Y: Double read FY write FY;
  end;

  TTMSFNCMapsGeoJSONEventData = class(TPersistent)
  private
    FJSONValue: TJSONValue;
    FPolygon: TTMSFNCMapsCoordinateRecArray;
    FPolyline: TTMSFNCMapsCoordinateRecArray;
    FPoint: TTMSFNCMapsCoordinateRec;
  public
    constructor Create; virtual;
    destructor Destroy; override;
    property Polygon: TTMSFNCMapsCoordinateRecArray read FPolygon write FPolygon;
    property Polyline: TTMSFNCMapsCoordinateRecArray read FPolyline write FPolyline;
    property Point: TTMSFNCMapsCoordinateRec read FPoint write FPoint;
    property JSONValue: TJSONValue read FJSONValue write FJSONValue;
  end;

  TTMSFNCMapsGeoJSONObjectEvent = procedure(Sender: TObject; AEventData: TTMSFNCMapsGeoJSONEventData) of object;
  TTMSFNCMapsBaseEvent = procedure(Sender: TObject; AEventData: TTMSFNCMapsEventData) of object;
  TTMSFNCMapsGetCenterCoordinateEvent = procedure(Sender: TObject; ACoordinate: TTMSFNCMapsCoordinateRec) of object;
  TTMSFNCMapsGetBoundsEvent = procedure(Sender: TObject; ABounds: TTMSFNCMapsBoundsRec) of object;
  TTMSFNCMapsGetZoomLevelEvent = procedure(Sender: TObject; AZoomLevel: Double) of object;
  TTMSFNCMapsRouteCalculatorWayPointEvent = procedure(Sender: TObject; AMarker: TTMSFNCMapsMarker; ASegment: TTMSFNCRouteCalculatorSegment) of object;
  TTMSFNCMapsRouteCalculatorPolylineEvent = procedure(Sender: TObject; APolyline: TTMSFNCMapsPolyline; ASegment: TTMSFNCRouteCalculatorSegment) of object;
  TTMSFNCMapsRouteCalculatorDeletePolylineEvent = procedure(Sender: TObject; var ACanDelete: Boolean; APolyline: TTMSFNCMapsPolyline; ASegment: TTMSFNCRouteCalculatorSegment) of object;
  TTMSFNCMapsRouteCalculatorDeleteMarkerEvent = procedure(Sender: TObject; var ACanDelete: Boolean; AMarker: TTMSFNCMapsMarker; ASegment: TTMSFNCRouteCalculatorSegment) of object;

  TTMSFNCMapsOptionsClass = class of TTMSFNCMapsOptions;
  TTMSFNCMapsMarkersClass = class of TTMSFNCMapsMarkers;
  TTMSFNCMapsElementContainersClass = class of TTMSFNCMapsElementContainers;
  TTMSFNCMapsLabelsClass = class of TTMSFNCMapsLabels;
  TTMSFNCMapsHeadLinksClass = class of TTMSFNCMapsHeadLinks;
  TTMSFNCMapsRectanglesClass = class of TTMSFNCMapsRectangles;
  TTMSFNCMapsCirclesClass = class of TTMSFNCMapsCircles;
  TTMSFNCMapsPolylinesClass = class of TTMSFNCMapsPolylines;
  TTMSFNCMapsPolygonsClass = class of TTMSFNCMapsPolygons;

  TTMSFNCMapsElementActions = class;
  TTMSFNCMapsElementContainer = class;

  TTMSFNCMapsHTMLEvent = (heClick,heDblClick,heKeyPress,heKeyDown,heKeyUp,heMouseDown,heMouseMove,heMouseUp,heMouseEnter,heMouseLeave,heBlur,heFocus,heChange,heSelect,heCustom,heNone);
  TTMSFNCMapsPosition = (poCustom, poTopLeft, poTopCenter, poTopRight, poCenterLeft, poCenterCenter, poBottomLeft, poBottomCenter, poBottomRight, poCoordinate, poBounds);
  TTMSFNCMapsPositionType = (ptCoordinate, ptBounds);
  TTMSFNCMapsReturnValue = (rvNone, rvValue, rvChecked, rvInnerHTML, rvInnerText, rvSelectedIndex);
  TTMSFNCMapsLabelType = (ltLabel, ltBalloon);

  TTMSFNCMapsElementAction = class(TCollectionItem)
  private
    FHTMLElementID: string;
    FElementEvent: TTMSFNCMapsHTMLEvent;
    FTag: integer;
    FEnabled: boolean;
    FName: string;
    FOnExecute: TTMSFNCMapsBaseEvent;
    FCustomEvent: string;
    FEventReturnValue: TTMSFNCMapsReturnValue;
    procedure SetHTMLElementID(const Value: string);
    procedure SetName(const Value: string);
    procedure SetElementEvent(const Value: TTMSFNCMapsHTMLEvent);
    procedure SetEnabled(const Value: boolean);
    procedure SetCustomEvent(const Value: string);
    procedure SetEventReturnValue(const Value: TTMSFNCMapsReturnValue);
  protected
    FOwner: TTMSFNCMapsElementContainer;
    procedure UpdateAction; virtual;
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property ElementContainer: TTMSFNCMapsElementContainer read FOwner;
  published
    property Enabled: Boolean read FEnabled write SetEnabled default True;
    property Event: TTMSFNCMapsHTMLEvent read FElementEvent write SetElementEvent default heClick;
    property CustomEvent: string read FCustomEvent write SetCustomEvent;
    property EventReturnValue: TTMSFNCMapsReturnValue read FEventReturnValue write SetEventReturnValue default rvNone;
    property HTMLElementID: string read FHTMLElementID write SetHTMLElementID;
    property Name: string read FName write SetName;
    property Tag: integer read FTag write FTag default 0;
    property OnExecute: TTMSFNCMapsBaseEvent read FOnExecute write FOnExecute;
  end;

  TTMSFNCMapsElementActions = class(TOwnedCollection)
  private
    FOwner: TTMSFNCMapsElementContainer;
    function GetItems(AIndex: Integer): TTMSFNCMapsElementAction;
    procedure SetItems(AIndex: Integer; const Value: TTMSFNCMapsElementAction);
    function GetAction(AName: string): TTMSFNCMapsElementAction;
  public
    constructor Create(AOwner: TTMSFNCMapsElementContainer); reintroduce;
    function Add: TTMSFNCMapsElementAction; reintroduce;
    function Insert(AIndex: Integer): TTMSFNCMapsElementAction; reintroduce;
    property Items[AIndex: Integer]: TTMSFNCMapsElementAction read GetItems write SetItems; default;
    property Action[AName: string]: TTMSFNCMapsElementAction Read GetAction;
    function GetActionByName(AName: string): TTMSFNCMapsElementAction;
    function GetByName(AName: string): TTMSFNCMapsElementAction;
    function FindByName(AName: string): TTMSFNCMapsElementAction;
  end;

  TTMSFNCMapsLabel = class(TCollectionItem)
  private
    FOwner: TTMSFNCCustomMaps;
    FReload: Boolean;
    FID: string;
    FText: string;
    FDataPointer: Pointer;
    FDataBoolean: Boolean;
    FDataObject: TObject;
    FDataString: string;
    FDataInteger: NativeInt;
    FRecreate: Boolean;
    FCoordinate: TTMSFNCMapsCoordinate;
    FVisible: Boolean;
    FBorderColor: TTMSFNCGraphicsColor;
    FFont: TTMSFNCGraphicsFont;
    FBackgroundColor: TTMSFNCGraphicsColor;
    FBorderWidth: Integer;
    FBounds: TTMSFNCMapsBounds;
    FPosition: TTMSFNCMapsPositionType;
    FLabelType: TTMSFNCMapsLabelType;
    function GetID: string;
    procedure SetText(const Value: string);
    procedure SetCoordinate(const Value: TTMSFNCMapsCoordinate);
    procedure SetVisible(const Value: Boolean);
    procedure SetBackgroundColor(const Value: TTMSFNCGraphicsColor);
    procedure SetBorderColor(const Value: TTMSFNCGraphicsColor);
    procedure SetBorderWidth(const Value: Integer);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetBounds(const Value: TTMSFNCMapsBounds);
    procedure SetPosition(const Value: TTMSFNCMapsPositionType);
    procedure SetLabelType(const Value: TTMSFNCMapsLabelType);
  protected
    procedure UpdateLabel; virtual;
    procedure LabelChanged(Sender: TObject);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property Maps: TTMSFNCCustomMaps read FOwner;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: string read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property Recreate: Boolean read FRecreate write FRecreate default False;
  published
    property ID: string read GetID;
    property Text: string read FText write SetText nodefault;
    property Coordinate: TTMSFNCMapsCoordinate read FCoordinate write SetCoordinate;
    property Bounds: TTMSFNCMapsBounds read FBounds write SetBounds;
    property Visible: Boolean read FVisible write SetVisible;
    property BackgroundColor: TTMSFNCGraphicsColor read FBackgroundColor write SetBackgroundColor default gcNull;
    property BorderColor: TTMSFNCGraphicsColor read FBorderColor write SetBorderColor default gcNull;
    property BorderWidth: Integer read FBorderWidth write SetBorderWidth default 2;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Position: TTMSFNCMapsPositionType read FPosition write SetPosition default ptCoordinate;
    property LabelType: TTMSFNCMapsLabelType read FLabelType write SetLabelType default ltLabel;

  end;

  {$IFDEF WEBLIB}
  TTMSFNCMapsLabels = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCMapsLabels = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCMapsLabel>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomMaps;
    function GetItem(Index: Integer): TTMSFNCMapsLabel;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsLabel);
    function GetItemByID(ID: string): TTMSFNCMapsLabel;
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomMaps); virtual;
    property Items[Index: Integer]: TTMSFNCMapsLabel read GetItem write SetItem; default;
    procedure Clear; virtual;
    procedure Recreate; virtual;
    function Add: TTMSFNCMapsLabel;
    function Insert(Index: Integer): TTMSFNCMapsLabel;
    property ItemByID[ID: string]: TTMSFNCMapsLabel read GetItemByID;
  end;

  TTMSFNCMapsElementContainer = class(TCollectionItem)
  private
    FReload: Boolean;
    FID: string;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FHTML: TStringList;
    FRecreate: Boolean;
    FVisible: Boolean;
    FActions: TTMSFNCMapsElementActions;
    FPosition: TTMSFNCMapsPosition;
    FHTMLElementID: string;
    FHTMLElementClassName: string;
    FUseDefaultStyle: Boolean;
    FScript: TStringList;
    FStyle: TStringList;
    FMargins: TTMSFNCMargins;
    FCoordinate: TTMSFNCMapsCoordinate;
    FBounds: TTMSFNCMapsBounds;
    FLabelType: TTMSFNCMapsLabelType;
    procedure SetHTML(const Value: TStringList);
    function GetID: string;
    procedure SetVisible(const Value: Boolean);
    procedure SetActions(const Value: TTMSFNCMapsElementActions);
    procedure SetPosition(const Value: TTMSFNCMapsPosition);
    procedure SetHTMLElementClassName(const Value: string);
    procedure SetHTMLElementID(const Value: string);
    procedure SetUseDefaultStyle(const Value: Boolean);
    function GetHTMLAsBase64: string;
    procedure SetStyle(const Value: TStringList);
    procedure SetScript(const Value: TStringList);
    function GetScriptAsBase64: string;
    function GetStyleAsBase64: string;
    procedure SetMargins(const Value: TTMSFNCMargins);
    procedure SetCoordinate(const Value: TTMSFNCMapsCoordinate);
    procedure SetBounds(const Value: TTMSFNCMapsBounds);
    procedure SetLabelType(const Value: TTMSFNCMapsLabelType);
  protected
    FOwner: TTMSFNCCustomMaps;
    procedure UpdateElementContainer; virtual;
    procedure ElementContainerChanged(Sender: TObject);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    function AddAction(AHTMLElementID: string; AEvent: TTMSFNCMapsHTMLEvent = heClick): TTMSFNCMapsElementAction; virtual;
    property Maps: TTMSFNCCustomMaps read FOwner;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property Recreate: Boolean read FRecreate write FRecreate default False;
  published
    property ID: string read GetID;
    property HTML: TStringList read FHTML write SetHTML nodefault;
    property HTMLAsBase64: string read GetHTMLAsBase64;
    property Visible: Boolean read FVisible write SetVisible default True;
    property Actions: TTMSFNCMapsElementActions read FActions write SetActions;
    property Coordinate: TTMSFNCMapsCoordinate read FCoordinate write SetCoordinate;
    property Bounds: TTMSFNCMapsBounds read FBounds write SetBounds;
    property Position: TTMSFNCMapsPosition read FPosition write SetPosition default poTopLeft;
    property HTMLElementID: string read FHTMLElementID write SetHTMLElementID;
    property HTMLElementClassName: string read FHTMLElementClassName write SetHTMLElementClassName;
    property UseDefaultStyle: Boolean read FUseDefaultStyle write SetUseDefaultStyle default True;
    property Style: TStringList read FStyle write SetStyle;
    property StyleAsBase64: string read GetStyleAsBase64;
    property Script: TStringList read FScript write SetScript;
    property ScriptAsBase64: string read GetScriptAsBase64;
    property Margins: TTMSFNCMargins read FMargins write SetMargins;
    property LabelType: TTMSFNCMapsLabelType read FLabelType write SetLabelType default ltLabel;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCMapsElementContainers = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCMapsElementContainers = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCMapsElementContainer>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomMaps;
    function GetItem(Index: Integer): TTMSFNCMapsElementContainer;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsElementContainer);
    function GetItemByID(ID: string): TTMSFNCMapsElementContainer;
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomMaps); virtual;
    property Items[Index: Integer]: TTMSFNCMapsElementContainer read GetItem write SetItem; default;
    procedure Clear; virtual;
    procedure Recreate; virtual;
    function Add: TTMSFNCMapsElementContainer;
    function Insert(Index: Integer): TTMSFNCMapsElementContainer;
    property ItemByID[ID: string]: TTMSFNCMapsElementContainer read GetItemByID;
  end;

  TTMSFNCCustomMaps = class(TTMSFNCCustomWebBrowser, ITMSFNCCustomMapsProperties)
  private
    {$IFDEF WEBLIB}
    FMapEventData: Pointer;
    {$ENDIF}
    FRouteCalculator: TTMSFNCRouteCalculator;
    FRouteCalculatorMarker: TTMSFNCMapsMarker;
    FRouteCalculatorDragMarker: TTMSFNCMapsMarker;
    FRouteCalculatorDragStarted: Boolean;
    FRouteCalculatorPolyline: TTMSFNCMapsPolyElement;
    FRouteCalculatorSelectedPolyline: TTMSFNCMapsPolyline;
    FRouteCalculatorDragX, FRouteCalculatorDragY, FRouteCalculatorDragLat, FRouteCalculatorDragLon: Double;
    FMapInitialized: Boolean;
    FUpdateCount: Integer;
    FMaps: ITMSFNCCustomMaps;
    FMapsInstance: ITMSFNCCustomMapsInstance;
    FMapsProperties: ITMSFNCCustomMapsProperties;
    FService: TTMSFNCMapsService;
    FAPIKey: string;
    FOptions: TTMSFNCMapsOptions;
    FOnCustomizeJavaScript: TTMSFNCMapsCustomizeJavaScriptEvent;
    FOnCustomizeMap: TTMSFNCMapsCustomizeMapEvent;
    FOnCustomizeGlobalVariables: TTMSFNCMapsCustomizeGlobalVariablesEvent;
    FOnCustomizeMarker: TTMSFNCMapsCustomizeMarkerEvent;
    FOnCustomizePopup: TTMSFNCMapsCustomizePopupEvent;
    FOnCustomizeOptions: TTMSFNCMapsCustomizeOptionsEvent;
    FOnCustomizePolyElement: TTMSFNCMapsCustomizePolyElementEvent;
    FOnMapTypeChanged: TTMSFNCMapsBaseEvent;
    FOnZoomChanged: TTMSFNCMapsBaseEvent;
    FOnMapClick: TTMSFNCMapsBaseEvent;
    FOnMapDblClick: TTMSFNCMapsBaseEvent;
    FOnMapMouseUp: TTMSFNCMapsBaseEvent;
    FOnMapMouseDown: TTMSFNCMapsBaseEvent;
    FOnMapMouseMove: TTMSFNCMapsBaseEvent;
    FOnMapMouseEnter: TTMSFNCMapsBaseEvent;
    FOnMapMouseLeave: TTMSFNCMapsBaseEvent;
    FOnMarkerClick: TTMSFNCMapsBaseEvent;
    FOnMarkerRightClick: TTMSFNCMapsBaseEvent;
    FOnMarkerDblClick: TTMSFNCMapsBaseEvent;
    FOnMarkerMouseUp: TTMSFNCMapsBaseEvent;
    FOnMarkerMouseDown: TTMSFNCMapsBaseEvent;
    FOnMarkerMouseEnter: TTMSFNCMapsBaseEvent;
    FOnMarkerMouseLeave: TTMSFNCMapsBaseEvent;
    FOnPolyElementClick: TTMSFNCMapsBaseEvent;
    FOnPolyElementRightClick: TTMSFNCMapsBaseEvent;
    FOnPolyElementDblClick: TTMSFNCMapsBaseEvent;
    FOnPolyElementMouseUp: TTMSFNCMapsBaseEvent;
    FOnPolyElementMouseDown: TTMSFNCMapsBaseEvent;
    FOnPolyElementMouseEnter: TTMSFNCMapsBaseEvent;
    FOnPolyElementMouseLeave: TTMSFNCMapsBaseEvent;
    FOnCustomEvent: TTMSFNCMapsBaseEvent;
    FOnMapInitialized: TNotifyEvent;
    FMarkers: TTMSFNCMapsMarkers;
    FPolygons: TTMSFNCMapsPolygons;
    FPolylines: TTMSFNCMapsPolylines;
    FCircles: TTMSFNCMapsCircles;
    FRectangles: TTMSFNCMapsRectangles;
    FOnCustomizeCSS: TTMSFNCMapsCustomizeCSSEvent;
    FOnGetDefaultHTMLMessage: TTMSFNCMapsGetDefaultHTMLMessageEvent;
    FLocalFileAccess: Boolean;
    FOnGetCenterCoordinate: TTMSFNCMapsGetCenterCoordinateEvent;
    FOnGetBoundsCoordinate: TTMSFNCMapsGetBoundsEvent;
    FOnGetZoomLevel: TTMSFNCMapsGetZoomLevelEvent;
    FOnCreateGPXTrack: TTMSFNCMapsGPXTrackEvent;
    FOnCreateGPXSegment: TTMSFNCMapsGPXSegmentEvent;
    FOnCreateGeoJSONObject: TTMSFNCMapsGeoJSONObjectEvent;
    FOnRouteCalculatorWayPointAdded: TTMSFNCMapsRouteCalculatorWayPointEvent;
    FOnRouteCalculatorWayPointUpdated: TTMSFNCMapsRouteCalculatorWayPointEvent;
    FOnRouteCalculatorPolylineAdded: TTMSFNCMapsRouteCalculatorPolylineEvent;
    FOnRouteCalculatorPolylineUpdated: TTMSFNCMapsRouteCalculatorPolylineEvent;
    FRouteCalculatorSelectedMarker: TTMSFNCMapsMarker;
    FOnRouteCalculatorDeletePolylineEvent: TTMSFNCMapsRouteCalculatorDeletePolylineEvent;
    FOnRouteCalculatorAfterDeletePolylineEvent: TNotifyEvent;
    FOnRouteCalculatorAfterDeleteMarkerEvent: TNotifyEvent;
    FOnRouteCalculatorDeleteMarkerEvent: TTMSFNCMapsRouteCalculatorDeleteMarkerEvent;
    FOnMapMoveEnd: TTMSFNCMapsBaseEvent;
    FOnMapMoveStart: TTMSFNCMapsBaseEvent;
    FOnCustomizeHeadLinks: TTMSFNCMapsCustomizeHeadLinksEvent;
    FOnMapRightClick: TTMSFNCMapsBaseEvent;
    FElementContainers: TTMSFNCMapsElementContainers;
    FHeadLinks: TTMSFNCMapsHeadLinks;
    FOnCustomizeLocalAccessFileName: TTMSFNCMapsCustomizeLocalAccessFileNameEvent;
    FLibraryLocation: TTMSFNCMapsLibraryLocation;
    FLabels: TTMSFNCMapsLabels;
//    FOnMapMove: TTMSFNCMapsBaseEvent;
//    FOnMapIdle: TTMSFNCMapsBaseEvent;
    procedure SetService(const Value: TTMSFNCMapsService);
    procedure SetAPIKey(const Value: string);
    procedure SetOptions(const Value: TTMSFNCMapsOptions);
    procedure SetMarkers(const Value: TTMSFNCMapsMarkers);
    procedure SetPolylines(const Value: TTMSFNCMapsPolylines);
    procedure SetPolygons(const Value: TTMSFNCMapsPolygons);
    procedure SetRectangles(const Value: TTMSFNCMapsRectangles);
    procedure SetCircles(const Value: TTMSFNCMapsCircles);
    procedure SetMapInitialized(const Value: Boolean);
    procedure SetLocalFileAccess(const Value: Boolean);
    procedure SetRouteCalculator(const Value: TTMSFNCRouteCalculator);
    procedure SetElementContainers(const Value: TTMSFNCMapsElementContainers);
    procedure SetHeadLinks(const Value: TTMSFNCMapsHeadLinks);
    procedure SetLibraryLocation(const Value: TTMSFNCMapsLibraryLocation);
    procedure SetLabels(const Value: TTMSFNCMapsLabels);
  protected
    function GetMarkersClass: TTMSFNCMapsMarkersClass; virtual;
    function GetElementContainersClass: TTMSFNCMapsElementContainersClass; virtual;
    function GetLabelsClass: TTMSFNCMapsLabelsClass; virtual;
    function GetHeadLinksClass: TTMSFNCMapsHeadLinksClass; virtual;
    function GetRectanglesClass: TTMSFNCMapsRectanglesClass; virtual;
    function GetCirclesClass: TTMSFNCMapsCirclesClass; virtual;
    function GetPolygonsClass: TTMSFNCMapsPolygonsClass; virtual;
    function GetPolylinesClass: TTMSFNCMapsPolylinesClass; virtual;
    function GetOptionsClass: TTMSFNCMapsOptionsClass; virtual;
    function ParseEvent(AValue: string): Boolean;
    function ParseScript(AValue: string): string;
    function InternalLoadGPX(AText: string; AAutoDisplay: Boolean = True; AZoomToBounds: Boolean = True;
      AStrokeWidth: Integer = 3; AStrokeColor: TTMSFNCGraphicsColor = gcBlack; ADisplayElevation: Boolean = False;
      ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec; overload; virtual;
    function InternalLoadGPX(AText: string; AAutoDisplay: Boolean = True; AZoomToBounds: Boolean = True;
      AStrokeWidth: Integer = 3; AElevationColors: TTMSFNCMapsGPXColorRecArray = nil; ADisplayElevation: Boolean = False;
      ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec; overload; virtual;
    function InternalLoadGeoJSON(AText: string; AAutoDisplay: Boolean = True; AZoomToBounds: Boolean = True): TTMSFNCMapsGeoJSONRec; virtual;
    function GetCustomGlobalVariables: string; virtual;
    function GetLocalAccessFileName: string; virtual;
    function GetCustomFunctions: string; virtual;
    function GetCustomOptions: string; virtual;
    function GetCustomMap: string; virtual;
    function GetAddCustomObjects: string; virtual;
    function RouteCalculatorCheck: Boolean;
    function GetRouteCalculatorStartMarker: string;
    function GetRouteCalculatorEndMarker: string;
    function GetRouteCalculatorWayPointMarker: string;
    function GetRouteCalculatorAddWayPointMarker: string;
    function GetRouteCalculatorSelectedWayPointMarker: string;
    procedure RouteCalculatorClearSelected;
    {$IFDEF WEBLIB}
    procedure SetName(const NewName: TComponentName); override;
    {$ENDIF}
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure Loaded; override;
    procedure DestroyMap;
    procedure DoMapInitialized; virtual;
    procedure DoCustomizeLocalAccessFileName(var AFileName: string); virtual;
    procedure DoGetZoomLevel(const AValue: string); virtual;
    procedure DoGetCenterCoordinate(const AValue: string); virtual;
    procedure DoGetBounds(const AValue: string); virtual;
    procedure DoZoomChanged(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMapTypeChanged(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMapMoveStart(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMapMoveEnd(AEventData: TTMSFNCMapsEventData); virtual;
//    procedure DoMapMove(AEventData: TTMSFNCMapsEventData); virtual;
//    procedure DoMapIdle(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMapClick(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMapRightClick(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMapKeyDown(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMapDblClick(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMapMouseUp(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMapMouseDown(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMapMouseMove(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMapMouseEnter(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMapMouseLeave(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMarkerClick(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMarkerRightClick(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMarkerDblClick(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMarkerMouseUp(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMarkerMouseDown(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMarkerMouseEnter(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoMarkerMouseLeave(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoPolyElementClick(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoPolyElementRightClick(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoPolyElementDblClick(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoPolyElementMouseUp(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoPolyElementMouseDown(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoPolyElementMouseEnter(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoPolyElementMouseLeave(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoCreateGPXTrack(AEventData: TTMSFNCMapsGPXTrackEventData); virtual;
    procedure DoCreateGPXSegment(AEventData: TTMSFNCMapsGPXSegmentEventData); virtual;
    procedure DoCreateGeoJSONObject(AEventData: TTMSFNCMapsGeoJSONEventData); virtual;
    procedure DoCustomEvent(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoCustomizeMap(var ACustomizeMap: string); virtual;
    procedure DoCustomizeGlobalVariables(var ACustomizeGlobalVariables: string); virtual;
    procedure DoCustomizeMarker(var ACustomizeMarker: string); virtual;
    procedure DoCustomizePopup(var ACustomizePopup: string); virtual;
    procedure DoCustomizeOptions(var ACustomizeOptions: string); virtual;
    procedure DoCustomizePolyElement(var ACustomizePolyElement: string); virtual;
    procedure DoCustomizeJavaScript(var ACustomizeJavaScript: string); virtual;
    procedure DoCustomizeHeadLinks(AList: TTMSFNCMapsLinksList); virtual;
    procedure DoCustomizeCSS(var ACustomizeCSS: string); virtual;
    procedure DoGetDefaultHTMLMessage(var AMessage: string); virtual;
    procedure DoRouteCalculatorWayPointAdded(AMarker: TTMSFNCMapsMarker; ASegment: TTMSFNCRouteCalculatorSegment);
    procedure DoRouteCalculatorWayPointUpdated(AMarker: TTMSFNCMapsMarker; ASegment: TTMSFNCRouteCalculatorSegment);
    procedure DoRouteCalculatorPolylineAdded(APolyline: TTMSFNCMapsPolyline; ASegment: TTMSFNCRouteCalculatorSegment);
    procedure DoRouteCalculatorPolylineUpdated(APolyline: TTMSFNCMapsPolyline; ASegment: TTMSFNCRouteCalculatorSegment);
    procedure DoRouteCalculatorBeforeDeletePolyline(var ACanDelete: Boolean; APolyline: TTMSFNCMapsPolyline; ASegment: TTMSFNCRouteCalculatorSegment);
    procedure DoRouteCalculatorAfterDeletePolyline;
    procedure DoRouteCalculatorBeforeDeleteMarker(var ACanDelete: Boolean; AMarker: TTMSFNCMapsMarker; ASegment: TTMSFNCRouteCalculatorSegment);
    procedure DoRouteCalculatorAfterDeleteMarker;
    procedure CallCustomEvent(AEventData: TTMSFNCMapsEventData); virtual;
    procedure BeforeNavigate(var Params: TTMSFNCCustomWebBrowserBeforeNavigateParams); override;
    procedure OptionsChanged(Sender: TObject);
    procedure Initialized; override;
    procedure InitializeMap; virtual;
    procedure InitializeHTML; virtual;
    {$IFDEF WEBLIB}
    procedure LoadScripts(AHead: Boolean); virtual;
    procedure RemoveScripts; virtual;
    procedure LoadStyles; virtual;
    procedure RemoveStyles; virtual;
    {$ENDIF}
    procedure CreateClasses; override;
    procedure GetLinks(AList: TTMSFNCMapsLinksList; AIncludeContent: Boolean = True; ACheckReady: Boolean = True); virtual;
    procedure GetBodyLinks(AList: TTMSFNCMapsLinksList; AIncludeContent: Boolean = True; ACheckReady: Boolean = True); virtual;
    procedure GetHeadLinks(AList: TTMSFNCMapsLinksList; ACheckReady: Boolean = True); virtual;

    procedure UpdateElementContainers; virtual;
    procedure DoUpdateElementContainers(const AValue: string); virtual;
    procedure UpdateMarkers; virtual;
    procedure DoUpdateMarkers(const AValue: string); virtual;
    procedure UpdatePolyElements; virtual;
    procedure DoUpdatePolyElements(const AValue: string); virtual;
    procedure DoCloseAllPopups(const AValue: string); virtual;
    procedure SyncLabels(ACollection: TTMSFNCMapsElementContainers);

    procedure UpdateOptions; virtual;

    procedure DoKeyPressed(var Key: Word); override;
    procedure RouteCalculatorMarkerMoved(ACoordinate: TTMSFNCMapsCoordinateRec); virtual;
    procedure RouteCalculatorPolylineMoved(ACoordinate: TTMSFNCMapsCoordinateRec); virtual;
    procedure RouteCalculatorDoCalculateRoute(Sender: TObject; const ARoute: TTMSFNCRouteCalculatorRoute); virtual;

    {$IFDEF WEBLIB}
    function HandleDoMapEventData(Event: TJSEvent): Boolean; virtual;
    procedure BindEvents; override;
    procedure UnbindEvents; override;
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
    {$ENDIF}

    procedure CheckInstances;

    function CanLoadDefaultHTML: Boolean; override;
    function GetDefaultLatitude: string;
    function GetDefaultLongitude: string;
    function GetDefaultZoomLevel: string;
    function GetAPIKey: string;
    function GetBackgroundColor: TTMSFNCGraphicsColor;
    function GetLocale(AMode: TTMSFNCMapsLocaleMode = mlmDefault): string;
    function GetShowZoomControl: Boolean;
    function GetShowMapTypeControl: Boolean;
    function GetZoomOnDblClick: Boolean;
    function GetPanning: Boolean;
    function GetMapID: string; virtual;
    function GetTilt: Double; virtual;
    function GetHeading: Double; virtual;
    function GetZoomOnWheelScroll: Boolean;

    function GetPolyElementByID(AID: string): TTMSFNCMapsPolyElement; virtual;
    function GetMarkerByID(AID: string): TTMSFNCMapsMarker; virtual;
    function GetElementContainerByID(AID: string): TTMSFNCMapsElementContainer; virtual;
    function GetCommunicationLayer: string; virtual;
    function GetZoomToBoundsFunction: string; virtual;
    function GetSetCenterCoordinateFunction: string; virtual;
    function GetGetCenterCoordinateFunction: string; virtual;
    function GetGetLatLonToXYFunction: string; virtual;
    function GetGetXYToLatLonFunction: string; virtual;
    function GetGetBoundsFunction: string; virtual;
    function GetSetZoomLevelFunction: string; virtual;
    function GetGetZoomLevelFunction: string; virtual;
    function GetUpdateOptions: string; virtual;
    function GetAddOrUpdateMarkerFunction: string; virtual;
    function GetAddOrUpdateElementContainerFunction: string; virtual;
    function GetShowPopupFunction: string; virtual;
    function GetClosePopupFunction: string; virtual;
    function GetDeleteMarkerFunction: string; virtual;
    function GetAddMarkers: string; virtual;
    function GetDeleteElementContainerFunction: string; virtual;
    function GetAddElementContainers: string; virtual;
    function GetAddOrUpdatePolyElementFunction: string; virtual;
    function GetDeletePolyElementFunction: string; virtual;
    function InternalGetAddPolyElements(AElements: TTMSFNCMapsPolyElements): string; virtual;
    function GetAddPolyElements: string; virtual;
    function GetGlobalVariables: string; virtual;
    function GetCallInitialize: string; virtual;
    function GetWaitForMapInitialized: string; virtual;
    function GetDefaultHTML: string; virtual;
    function MapReady: Boolean;
    function GetVersionNr: Integer; virtual;
    function GetDocURL: string; override;
    function GetTipsURL: string; override;
    function Maps: ITMSFNCCustomMaps;
    function ParseLinks(AList: TTMSFNCMapsLinksList): string; virtual;
    function GetVersion: string; override;
    function GetAPIVersion: string; virtual;
    function GetHeadStyle: string; virtual;
    function GetBody: string; virtual;

    property MapInitialized: Boolean read FMapInitialized write SetMapInitialized;
    property UpdateCount: Integer read FUpdateCount;
    property Service: TTMSFNCMapsService read FService write SetService default msGoogleMaps;
    property APIKey: string read FAPIKey write SetAPIKey;
    property LocalFileAccess: Boolean read FLocalFileAccess write SetLocalFileAccess default False;
    property LibraryLocation: TTMSFNCMapsLibraryLocation read FLibraryLocation write SetLibraryLocation default llOnline;
    property Options: TTMSFNCMapsOptions read FOptions write SetOptions;
    property Markers: TTMSFNCMapsMarkers read FMarkers write SetMarkers;
    property ElementContainers: TTMSFNCMapsElementContainers read FElementContainers write SetElementContainers;
    property Labels: TTMSFNCMapsLabels read FLabels write SetLabels;
    property HeadLinks: TTMSFNCMapsHeadLinks read FHeadLinks write SetHeadLinks;
    property Polylines: TTMSFNCMapsPolylines read FPolylines write SetPolylines;
    property Polygons: TTMSFNCMapsPolygons read FPolygons write SetPolygons;
    property Circles: TTMSFNCMapsCircles read FCircles write SetCircles;
    property Rectangles: TTMSFNCMapsRectangles read FRectangles write SetRectangles;

    property OnCustomizeLocalAccessFileName: TTMSFNCMapsCustomizeLocalAccessFileNameEvent read FOnCustomizeLocalAccessFileName write FOnCustomizeLocalAccessFileName;
    property OnCustomizeCSS: TTMSFNCMapsCustomizeCSSEvent read FOnCustomizeCSS write FOnCustomizeCSS;
    property OnCustomizeJavaScript: TTMSFNCMapsCustomizeJavaScriptEvent read FOnCustomizeJavaScript write FOnCustomizeJavaScript;
    property OnCustomizeHeadLinks: TTMSFNCMapsCustomizeHeadLinksEvent read FOnCustomizeHeadLinks write FOnCustomizeHeadLinks;
    property OnCustomizeMap: TTMSFNCMapsCustomizeMapEvent read FOnCustomizeMap write FOnCustomizeMap;
    property OnCustomizeGlobalVariables: TTMSFNCMapsCustomizeGlobalVariablesEvent read FOnCustomizeGlobalVariables write FOnCustomizeGlobalVariables;
    property OnCustomizeMarker: TTMSFNCMapsCustomizeMarkerEvent read FOnCustomizeMarker write FOnCustomizeMarker;
    property OnCustomizePopup: TTMSFNCMapsCustomizePopupEvent read FOnCustomizePopup write FOnCustomizePopup;
    property OnGetDefaultHTMLMessage: TTMSFNCMapsGetDefaultHTMLMessageEvent read FOnGetDefaultHTMLMessage write FOnGetDefaultHTMLMessage;
    property OnCustomizeOptions: TTMSFNCMapsCustomizeOptionsEvent read FOnCustomizeOptions write FOnCustomizeOptions;
    property OnCustomizePolyElement: TTMSFNCMapsCustomizePolyElementEvent read FOnCustomizePolyElement write FOnCustomizePolyElement;
    property OnZoomChanged: TTMSFNCMapsBaseEvent read FOnZoomChanged write FOnZoomChanged;
    property OnMapTypeChanged: TTMSFNCMapsBaseEvent read FOnMapTypeChanged write FOnMapTypeChanged;
    property OnMapMoveStart: TTMSFNCMapsBaseEvent read FOnMapMoveStart write FOnMapMoveStart;
    property OnMapMoveEnd: TTMSFNCMapsBaseEvent read FOnMapMoveEnd write FOnMapMoveEnd;
//    property OnMapMove: TTMSFNCMapsBaseEvent read FOnMapMove write FOnMapMove;
//    property OnMapIdle: TTMSFNCMapsBaseEvent read FOnMapIdle write FOnMapIdle;
    property OnMapClick: TTMSFNCMapsBaseEvent read FOnMapClick write FOnMapClick;
    property OnMapRightClick: TTMSFNCMapsBaseEvent read FOnMapRightClick write FOnMapRightClick;
    property OnMapDblClick: TTMSFNCMapsBaseEvent read FOnMapDblClick write FOnMapDblClick;
    property OnMapMouseUp: TTMSFNCMapsBaseEvent read FOnMapMouseUp write FOnMapMouseUp;
    property OnMapMouseDown: TTMSFNCMapsBaseEvent read FOnMapMouseDown write FOnMapMouseDown;
    property OnMapMouseMove: TTMSFNCMapsBaseEvent read FOnMapMouseMove write FOnMapMouseMove;
    property OnMapMouseEnter: TTMSFNCMapsBaseEvent read FOnMapMouseEnter write FOnMapMouseEnter;
    property OnMapMouseLeave: TTMSFNCMapsBaseEvent read FOnMapMouseLeave write FOnMapMouseLeave;
    property OnMarkerClick: TTMSFNCMapsBaseEvent read FOnMarkerClick write FOnMarkerClick;
    property OnMarkerRightClick: TTMSFNCMapsBaseEvent read FOnMarkerRightClick write FOnMarkerRightClick;
    property OnMarkerDblClick: TTMSFNCMapsBaseEvent read FOnMarkerDblClick write FOnMarkerDblClick;
    property OnMarkerMouseUp: TTMSFNCMapsBaseEvent read FOnMarkerMouseUp write FOnMarkerMouseUp;
    property OnMarkerMouseDown: TTMSFNCMapsBaseEvent read FOnMarkerMouseDown write FOnMarkerMouseDown;
    property OnMarkerMouseEnter: TTMSFNCMapsBaseEvent read FOnMarkerMouseEnter write FOnMarkerMouseEnter;
    property OnMarkerMouseLeave: TTMSFNCMapsBaseEvent read FOnMarkerMouseLeave write FOnMarkerMouseLeave;
    property OnPolyElementClick: TTMSFNCMapsBaseEvent read FOnPolyElementClick write FOnPolyElementClick;
    property OnPolyElementRightClick: TTMSFNCMapsBaseEvent read FOnPolyElementRightClick write FOnPolyElementRightClick;
    property OnPolyElementDblClick: TTMSFNCMapsBaseEvent read FOnPolyElementDblClick write FOnPolyElementDblClick;
    property OnPolyElementMouseUp: TTMSFNCMapsBaseEvent read FOnPolyElementMouseUp write FOnPolyElementMouseUp;
    property OnPolyElementMouseDown: TTMSFNCMapsBaseEvent read FOnPolyElementMouseDown write FOnPolyElementMouseDown;
    property OnPolyElementMouseEnter: TTMSFNCMapsBaseEvent read FOnPolyElementMouseEnter write FOnPolyElementMouseEnter;
    property OnPolyElementMouseLeave: TTMSFNCMapsBaseEvent read FOnPolyElementMouseLeave write FOnPolyElementMouseLeave;
    property OnGetCenterCoordinate: TTMSFNCMapsGetCenterCoordinateEvent read FOnGetCenterCoordinate write FOnGetCenterCoordinate;
    property OnGetBounds: TTMSFNCMapsGetBoundsEvent read FOnGetBoundsCoordinate write FOnGetBoundsCoordinate;
    property OnGetZoomLevel: TTMSFNCMapsGetZoomLevelEvent read FOnGetZoomLevel write FOnGetZoomLevel;
    property OnCreateGPXTrack: TTMSFNCMapsGPXTrackEvent read FOnCreateGPXTrack write FOnCreateGPXTrack;
    property OnCreateGPXSegment: TTMSFNCMapsGPXSegmentEvent read FOnCreateGPXSegment write FOnCreateGPXSegment;
    property OnCreateGeoJSONObject: TTMSFNCMapsGeoJSONObjectEvent read FOnCreateGeoJSONObject write FOnCreateGeoJSONObject;
    property OnCustomEvent: TTMSFNCMapsBaseEvent read FOnCustomEvent write FOnCustomEvent;
    property OnMapInitialized: TNotifyEvent read FOnMapInitialized write FOnMapInitialized;
    property OnRouteCalculatorWayPointAdded: TTMSFNCMapsRouteCalculatorWayPointEvent read FOnRouteCalculatorWayPointAdded write FOnRouteCalculatorWayPointAdded;
    property OnRouteCalculatorWayPointUpdated: TTMSFNCMapsRouteCalculatorWayPointEvent read FOnRouteCalculatorWayPointUpdated write FOnRouteCalculatorWayPointUpdated;
    property OnRouteCalculatorPolylineAdded: TTMSFNCMapsRouteCalculatorPolylineEvent read FOnRouteCalculatorPolylineAdded write FOnRouteCalculatorPolylineAdded;
    property OnRouteCalculatorPolylineUpdated: TTMSFNCMapsRouteCalculatorPolylineEvent read FOnRouteCalculatorPolylineUpdated write FOnRouteCalculatorPolylineUpdated;
    property OnRouteCalculatorBeforeDeletePolyline: TTMSFNCMapsRouteCalculatorDeletePolylineEvent read FOnRouteCalculatorDeletePolylineEvent write FOnRouteCalculatorDeletePolylineEvent;
    property OnRouteCalculatorAfterDeletePolyline: TNotifyEvent read FOnRouteCalculatorAfterDeletePolylineEvent write FOnRouteCalculatorAfterDeletePolylineEvent;
    property OnRouteCalculatorBeforeDeleteMarker: TTMSFNCMapsRouteCalculatorDeleteMarkerEvent read FOnRouteCalculatorDeleteMarkerEvent write FOnRouteCalculatorDeleteMarkerEvent;
    property OnRouteCalculatorAfterDeleteMarker: TNotifyEvent read FOnRouteCalculatorAfterDeleteMarkerEvent write FOnRouteCalculatorAfterDeleteMarkerEvent;

    property MapsInstance: ITMSFNCCustomMaps read FMaps;
    property MapsProperties: ITMSFNCCustomMapsProperties read FMapsProperties;

    property RouteCalculator: TTMSFNCRouteCalculator read FRouteCalculator write SetRouteCalculator;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure ReInitialize; virtual;
    procedure ConnectMarkers(AMarkersArray: TTMSFNCMapsMarkersArray);
    procedure ClearMarkers; virtual;
    procedure ClearElementContainers; virtual;
    procedure ClearLabels; virtual;
    procedure ClearHeadLinks; virtual;
    procedure Clear; virtual;
    procedure ClearPolylines; virtual;
    procedure ClearPolygons; virtual;
    procedure ClearCircles; virtual;
    procedure ClearRectangles; virtual;
    procedure UpdateControlAfterResize; override;
    procedure ZoomToBounds(ACoordinates: TTMSFNCMapsCoordinateRecArray); overload; virtual;
    procedure ZoomToBounds(ACoordinates: TTMSFNCMapsCoordinateRecArrayArray); overload; virtual;
    procedure ZoomToBounds(ACoordinates: TTMSFNCMapsCoordinateRecArrayArrayArray); overload; virtual;
    procedure ZoomToBounds(ABounds: TTMSFNCMapsBoundsRec); overload; virtual;
    procedure ZoomToBounds(ANorthEast: TTMSFNCMapsCoordinateRec; ASouthWest: TTMSFNCMapsCoordinateRec); overload; virtual;

    procedure GetZoomLevel; virtual;
    procedure GetCenterCoordinate; virtual;
    procedure GetBounds; virtual;
    procedure SetCenterCoordinate(ACoordinate: TTMSFNCMapsCoordinateRec); overload; virtual;
    procedure SetCenterCoordinate(ALatitude: Double; ALongitude: Double); overload; virtual;
    procedure SetZoomLevel(AZoomLevel: Double); virtual;
    function LatLonToXY(ACoordinate: TTMSFNCMapsCoordinateRec): TTMSFNCMapsAnchorPointRec; overload; virtual;
    function LatLonToXY(ALatitude: Double; ALongitude: Double): TTMSFNCMapsAnchorPointRec; overload; virtual;
    function CoordinateToXY(ACoordinate: TTMSFNCMapsCoordinateRec): TTMSFNCMapsAnchorPointRec; virtual;
    function XYToLatLon(AX: Single; AY: Single): TTMSFNCMapsCoordinateRec; virtual;
    function XYToCoordinate(AX: Single; AY: Single): TTMSFNCMapsCoordinateRec; virtual;
    function GetHTML: string; virtual;

    function LoadGPXFromStream(const AStream: TStream; AAutoDisplay: Boolean = True;
      AZoomToBounds: Boolean = True; AStrokeWidth: Integer = 3; AStrokeColor: TTMSFNCGraphicsColor = gcBlack; ADisplayElevation: Boolean = False;
      ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec; overload; virtual;

    function LoadGPXFromStream(const AStream: TStream; AAutoDisplay: Boolean;
      AZoomToBounds: Boolean; AStrokeWidth: Integer; AElevationColors: TTMSFNCMapsGPXColorRecArray; ADisplayElevation: Boolean;
      ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec; overload; virtual;

    {$IFNDEF WEBLIB}
    function LoadGPXFromFile(AFileName: string; AAutoDisplay: Boolean = True;
      AZoomToBounds: Boolean = True; AStrokeWidth: Integer = 3; AStrokeColor: TTMSFNCGraphicsColor = gcBlack; ADisplayElevation: Boolean = False;
      ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec; overload; virtual;

    function LoadGPXFromFile(AFileName: string; AAutoDisplay: Boolean;
      AZoomToBounds: Boolean; AStrokeWidth: Integer; AElevationColors: TTMSFNCMapsGPXColorRecArray; ADisplayElevation: Boolean;
      ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec; overload; virtual;
    {$ENDIF}

    function LoadGPXFromText(AText: string; AAutoDisplay: Boolean = True;
      AZoomToBounds: Boolean = True; AStrokeWidth: Integer = 3; AStrokeColor: TTMSFNCGraphicsColor = gcBlack; ADisplayElevation: Boolean = False;
      ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec; overload; virtual;

    function LoadGPXFromText(AText: string; AAutoDisplay: Boolean;
      AZoomToBounds: Boolean; AStrokeWidth: Integer; AElevationColors: TTMSFNCMapsGPXColorRecArray; ADisplayElevation: Boolean;
      ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec; overload; virtual;

    procedure SaveToGPXStream(ACoordinates: TTMSFNCMapsCoordinateRecArray; AStream: TStream); overload; virtual;
    procedure SaveToGPXStream(ACoordinates: TTMSFNCMapsCoordinateRecArray; AStream: TStream; AMetaData: TTMSFNCMapsGPXMetaData); overload; virtual;
    procedure SaveToGPXFile(ACoordinates: TTMSFNCMapsCoordinateRecArray; AFileName: string); overload; virtual;
    procedure SaveToGPXFile(ACoordinates: TTMSFNCMapsCoordinateRecArray; AFileName: string; AMetaData: TTMSFNCMapsGPXMetaData); overload; virtual;
    function SaveToGPXText(ACoordinates: TTMSFNCMapsCoordinateRecArray): string; overload; virtual;
    function SaveToGPXText(ACoordinates: TTMSFNCMapsCoordinateRecArray; AMetaData: TTMSFNCMapsGPXMetaData): string; overload; virtual;

    function LoadGeoJSONFromStream(const AStream: TStream; AAutoDisplay: Boolean = True; AZoomToBounds: Boolean = True): TTMSFNCMapsGeoJSONRec; virtual;
    {$IFNDEF WEBLIB}
    function LoadGeoJSONFromFile(AFileName: string; AAutoDisplay: Boolean = True; AZoomToBounds: Boolean = True): TTMSFNCMapsGeoJSONRec; virtual;
    {$ENDIF}
    function LoadGeoJSONFromText(AText: string; AAutoDisplay: Boolean = True; AZoomToBounds: Boolean = True): TTMSFNCMapsGeoJSONRec; virtual;
    function AddMarker(ACoordinate: TTMSFNCMapsCoordinateRec; ATitle: string = ''; AIconURL: string = ''): TTMSFNCMapsMarker; overload; virtual;
    function AddMarker(ALatitude: Double; ALongitude: Double; ATitle: string = ''; AIconURL: string = ''): TTMSFNCMapsMarker; overload; virtual;
    function AddPolyline(ACoordinates: TTMSFNCMapsCoordinateRecArray; AClose: Boolean = False): TTMSFNCMapsPolyline; virtual;
    function AddPolygon(ACoordinates: TTMSFNCMapsCoordinateRecArray; AClose: Boolean = False): TTMSFNCMapsPolygon; virtual;
    function AddCircle(ACenter: TTMSFNCMapsCoordinateRec; ARadius: Double = 10000): TTMSFNCMapsCircle; virtual;
    function AddRectangle(ABounds: TTMSFNCMapsBoundsRec): TTMSFNCMapsRectangle; virtual;
    function ShowPopup(ACoordinate: TTMSFNCMapsCoordinateRec; AText: string; AOffsetX: Double = 0; AOffsetY: Double = 0; APanMap: Boolean = True): string; overload; virtual;
    function ShowPopup(ALatitude: Double; ALongitude: Double; AText: string; AOffsetX: Double = 0; AOffsetY: Double = 0; APanMap: Boolean = True): string; overload; virtual;
    function AddElementContainer(AHTML: TStrings; AStyle: TStrings = nil; AScript: TStrings = nil; APosition: TTMSFNCMapsPosition = poTopLeft; AHTMLElementID: string = ''): TTMSFNCMapsElementContainer; virtual;
    function AddHeadLink(AURL: string; AKind: TTMSFNCMapsLinkKind = mlkScript; ARel: string = ''): TTMSFNCMapsHeadLink; virtual;
    function AddLabel(ACoordinate: TTMSFNCMapsCoordinateRec; AText: string; AFontColor: TTMSFNCGraphicsColor = gcNull; ABackgroundColor: TTMSFNCGraphicsColor = gcNull): TTMSFNCMapsLabel; overload; virtual;
    function AddLabel(ABounds: TTMSFNCMapsBoundsRec; AText: string; AFontColor: TTMSFNCGraphicsColor = gcNull; ABackgroundColor: TTMSFNCGraphicsColor = gcNull): TTMSFNCMapsLabel; overload; virtual;
    function AddLabel(ANorthEastLatitude: Double; ANorthEastLongitude: Double; ASouthWestLatitude: Double; ASouthWestLongitude: Double; AText: string; AFontColor: TTMSFNCGraphicsColor = gcNull; ABackgroundColor: TTMSFNCGraphicsColor = gcNull): TTMSFNCMapsLabel; overload; virtual;
    function AddLabel(ALatitude: Double; ALongitude: Double; AText: string; AFontColor: TTMSFNCGraphicsColor = gcNull; ABackgroundColor: TTMSFNCGraphicsColor = gcNull): TTMSFNCMapsLabel; overload; virtual;
    procedure ClosePopup(AID: string); virtual;
    procedure CloseAllPopups; virtual;
    procedure RouteCalculatorPlotRoute(ARoute: TTMSFNCRouteCalculatorRoute); virtual;
    procedure RouteCalculatorPlotRoutes; virtual;
    procedure RouteCalculatorClearRoutes; virtual;
    procedure RouteCalculatorDeletePolyline(APolyline: TTMSFNCMapsPolyline; AUpdateRoute: Boolean = True);
    procedure RouteCalculatorDeleteMarker(AMarker: TTMSFNCMapsMarker; AUpdateRoute: Boolean = True);
    procedure RouteCalculatorDeleteSelectedPolyline(AUpdateRoute: Boolean = True);
    procedure RouteCalculatorDeleteSelectedMarker(AUpdateRoute: Boolean = True);
    property RouteCalculatorSelectedPolyline: TTMSFNCMapsPolyline read FRouteCalculatorSelectedPolyline write FRouteCalculatorSelectedPolyline;
    property RouteCalculatorSelectedMarker: TTMSFNCMapsMarker read FRouteCalculatorSelectedMarker write FRouteCalculatorSelectedMarker;
  end;

  TTMSFNCCustomMapsInterfacedObject = class(TInterfacedObject, ITMSFNCCustomMapsInstance)
  private
    FMapsProperties: ITMSFNCCustomMapsProperties;
    function GetMapsProperties: ITMSFNCCustomMapsProperties;
  protected
    procedure SetMapsProperties(const Value: ITMSFNCCustomMapsProperties);
  public
    property MapsProperties: ITMSFNCCustomMapsProperties read GetMapsProperties;
    destructor Destroy; override;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCMapsList = class(TList)
  private
    function GetItem(Index: Integer): ITMSFNCCustomMaps;
    procedure SetItem(Index: Integer; const Value: ITMSFNCCustomMaps);
  public
    property Items[Index: Integer]: ITMSFNCCustomMaps read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCMapsList = class(TList<ITMSFNCCustomMaps>);
  {$ENDIF}

  TTMSFNCMapsFactoryService = class(TInterfacedObject, ITMSFNCMapsService)
  private
    FMaps: TTMSFNCMapsList;
  protected
    function DoCreateMaps: ITMSFNCCustomMaps; virtual; abstract;
    function CreateMaps: ITMSFNCCustomMaps;
    procedure DestroyMaps(AMaps: ITMSFNCCustomMaps);
  public
    constructor Create;
    destructor Destroy; override;
  end;

  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  TTMSFNCMaps = class(TTMSFNCCustomMaps)
  protected
    procedure RegisterRuntimeClasses; override;
  public
    procedure Navigate; overload; override;
    procedure Navigate(const AURL: string); overload; override;
    procedure ExecuteJavaScript(AScript: String; ACompleteEvent: TTMSFNCWebBrowserJavaScriptCompleteEvent = nil; AImmediate: Boolean = False); override;
    function ExecuteJavaScriptSync(AScript: String): string; override;
    procedure LoadHTML(AHTML: String); override;
    procedure LoadFile(AFile: String); override;
    procedure Initialize; override;
    procedure DeInitialize; override;
    procedure GoForward; override;
    procedure GoBack; override;
    procedure Reload; override;
    procedure StopLoading; override;
    procedure AddBridge(ABridgeName: string; ABridgeObject: TObject); override;
    procedure RemoveBridge(ABridgeName: string); override;
    procedure CaptureScreenShot; override;
    function GetBridgeCommunicationLayer(ABridgeName: string): string; override;
    function NativeEnvironment: Pointer; override;
    function NativeBrowser: Pointer; override;
    function IsFMXBrowser: Boolean; override;
    function CanGoBack: Boolean; override;
    function CanGoForward: Boolean; override;
    {$IFDEF ANDROID}
    function NativeDialog: Pointer; override;
    {$ENDIF}
    {$IFDEF MSWINDOWS}
    function GetWebBrowserInstance: IInterface; override;
    {$ENDIF}
    property OnCloseForm;
    property EnableContextMenu;
    property EnableShowDebugConsole;
    property EnableAcceleratorKeys;
    property CacheFolder;
    property CacheFolderName;
    property AutoClearCache;
    procedure ClearCache; override;
    procedure ShowDebugConsole; override;

    property MapsInstance;
    property MapsProperties;
  published
    property OnCustomizeLocalAccessFileName;
    property RouteCalculator;
    property OnCaptureScreenShot;
    property OnCustomizeCSS;
    property OnCustomizeJavaScript;
    property OnCustomizeMap;
    property OnCustomizeGlobalVariables;
    property OnCustomizeMarker;
    property OnCustomizePopup;
    property OnCustomizeHeadLinks;
    property OnCustomizeOptions;
    property OnCustomizePolyElement;
    property OnGetDefaultHTMLMessage;
    property OnZoomChanged;
    property OnMapTypeChanged;
    property OnMapMoveStart;
    property OnMapMoveEnd;
//    property OnMapMove;
//    property OnMapIdle;
    property OnMapClick;
    property OnMapDblClick;
    property OnMapMouseUp;
    property OnMapMouseDown;
    property OnMapMouseMove;
    property OnMapMouseEnter;
    property OnMapMouseLeave;
    property OnMarkerClick;
    property OnMarkerRightClick;
    property OnMarkerDblClick;
    property OnMarkerMouseUp;
    property OnMarkerMouseDown;
    property OnMarkerMouseEnter;
    property OnMarkerMouseLeave;
    property OnPolyElementClick;
    property OnPolyElementDblClick;
    property OnPolyElementMouseUp;
    property OnPolyElementMouseDown;
    property OnPolyElementMouseEnter;
    property OnPolyElementMouseLeave;
    property OnCustomEvent;
    property OnMapInitialized;
    property OnGetCenterCoordinate;
    property OnGetBounds;
    property OnGetZoomLevel;
    property OnCreateGPXTrack;
    property OnCreateGPXSegment;
    property OnCreateGeoJSONObject;
    property OnRouteCalculatorWayPointAdded;
    property OnRouteCalculatorWayPointUpdated;
    property OnRouteCalculatorPolylineAdded;
    property OnRouteCalculatorPolylineUpdated;
    property OnRouteCalculatorBeforeDeletePolyline;
    property OnRouteCalculatorAfterDeletePolyline;
    property OnRouteCalculatorBeforeDeleteMarker;
    property OnRouteCalculatorAfterDeleteMarker;

    property APIKey;
    property Polylines;
    property Polygons;
    property Circles;
    property Rectangles;
    property Markers;
    property ElementContainers;
    property Labels;
    property HeadLinks;
    property Options;
    property Service;
    property Version;
    property LocalFileAccess;
    property DesigntimeEnabled;
  end;

  TTMSFNCMapsPlatformServicesService = class
  private
    FInterface: IInterface;
    FGUID: string;
  public
    constructor Create(AGUID: string; AInterface: IInterface);
    property GUID: string read FGUID;
    property &Interface: IInterface read FInterface;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCMapsPlatformServicesList = class(TObjectList)
  private
    function GetItem(Index: Integer): TTMSFNCMapsPlatformServicesService;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsPlatformServicesService);
  public
    property Items[Index: Integer]: TTMSFNCMapsPlatformServicesService read GetItem write SetItem; default;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCMapsPlatformServicesList = class(TObjectList<TTMSFNCMapsPlatformServicesService>)
  {$ENDIF}
  private
    function GetValue(AGUID: string): IInterface;
  public
    function ContainsKey(AGUID: string): Boolean;
    procedure RemoveByGUID(AGUID: string);
    property Interfaces[AGUID: string]: IInterface read GetValue;
  end;

  TTMSFNCMapsPlatformServices = class
  private
    FServicesList: TTMSFNCMapsPlatformServicesList;
    class var FCurrent: TTMSFNCMapsPlatformServices;
    class var FCurrentReleased: Boolean;
{$IFNDEF AUTOREFCOUNT}
    class procedure ReleaseCurrent;
{$ENDIF}
  public
    constructor Create;
    destructor Destroy; override;
    procedure AddPlatformService(const AServiceGUID: TGUID; const AService: IInterface);
    procedure RemovePlatformService(const AServiceGUID: TGUID);
    function GetPlatformService(const AServiceGUID: TGUID): IInterface;
    function SupportsPlatformService(const AServiceGUID: TGUID): Boolean; overload;
    function SupportsPlatformService(const AServiceGUID: TGUID; var AService: IInterface): Boolean; overload;
    class function Current: TTMSFNCMapsPlatformServices;
  end;

implementation

uses
  {%H-}Math,
  WEBLib.TMSFNCPersistence,
  WEBLib.TMSFNCUtils,
  WEBLib.TMSFNCGraphics,
  WEBLib.TMSFNCMaps.GoogleMaps,
  WEBLib.TMSFNCMaps.Here,
  WEBLib.TMSFNCMaps.BingMaps,
  WEBLib.TMSFNCMaps.TomTom,
  WEBLib.TMSFNCMaps.AzureMaps,
  WEBLib.TMSFNCMaps.OpenLayers,
  WEBLib.TMSFNCMaps.Leaflet,
  WEBLib.TMSFNCMaps.MapBox,
  WEBLib.TMSFNCMaps.MapKit,
  SysUtils
  {$IFNDEF LCLWEBLIB}
  ,UITypes
  {$ENDIF}
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  ;

const
  EVENTDATAPREFIX = 'jsevent://';
  CUSTOMDATAPREFIX = 'customdata://';
  LOADMAPFUNC = 'loadMap';
  WAITFORMAPINITIALIZEDFUNC = 'waitForMapInitialized';
  MAPEVENTDATA = 'JSEVENT';

type
  TTMSFNCMapsRouteCalculatorOpen = class(TTMSFNCRouteCalculator);

{ TTMSFNCMapsEventData }

constructor TTMSFNCMapsEventData.Create;
begin
  FEvent := '';
  FCoordinate := TTMSFNCMapsCoordinate.Create;
end;

destructor TTMSFNCMapsEventData.Destroy;
begin
  FCoordinate.Free;
  inherited;
end;

{ TTMSFNCMapsGeoJSONEventData }

constructor TTMSFNCMapsGeoJSONEventData.Create;
begin
  FJSONValue := nil;
end;

destructor TTMSFNCMapsGeoJSONEventData.Destroy;
begin
  inherited;
end;

{ TTMSFNCCustomMaps }

function TTMSFNCCustomMaps.GetShowZoomControl: Boolean;
begin
  Result := Options.ShowZoomControl;
end;

function TTMSFNCCustomMaps.GetTilt: Double;
begin
  Result := 0;
end;

function TTMSFNCCustomMaps.GetTipsURL: string;
begin
  Result := TTMSFNCMapsTipsURL;
end;

function TTMSFNCCustomMaps.GetShowMapTypeControl: Boolean;
begin
  Result := Options.ShowMapTypeControl;
end;

function TTMSFNCCustomMaps.GetLocalAccessFileName: string;
begin
  Result := TTMSFNCUtils.AddBackslash(TTMSFNCUtils.GetTempPath) + CreateNewGUID + '.html';
  DoCustomizeLocalAccessFileName(Result);
end;

function TTMSFNCCustomMaps.GetLocale(AMode: TTMSFNCMapsLocaleMode = mlmDefault): string;
begin
  Result := ParseLocale(Options.Locale, AMode);
end;

function TTMSFNCCustomMaps.GetAPIKey: string;
begin
  Result := APIKey;
end;

function TTMSFNCCustomMaps.GetAPIVersion: string;
begin
  Result := '';
end;

function TTMSFNCCustomMaps.GetBackgroundColor: TTMSFNCGraphicsColor;
begin
  Result := Options.BackgroundColor;
end;

function TTMSFNCCustomMaps.GetBody: string;
begin
  {$IFDEF WEBLIB}
  Result := '<div id="' + MAPID + '" style="height:100%; width:100%" tabindex="0"></div>';
  {$ELSE}
  Result := '<div id="' + MAPID + '"></div>';
  {$ENDIF}
end;

function TTMSFNCCustomMaps.GetHeadStyle: string;
var
  m: string;
  hs: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  hs := '';
  DoCustomizeCSS(hs);

  {$IFDEF WEBLIB}
  Result := '#' + MAPID + ' {' + LB +
  {$ELSE}
  Result := '#' + MAPID + ', html, body {' + LB +
  {$ENDIF}
    '  height: 100%;' + LB +
    '  margin: 0;' + LB +
    '  padding: 0;' + LB +
    '  overflow: hidden;' + LB +
    '}';

    Result := Result +
    '  .tmsfncmaps_sb:after {' + LB +
    '   content: "";' + LB +
    '  	position: absolute;' + LB +
    '   left: 50%;' + lB +
    '  	width: 10px;' + LB +
    '  	height: 10px;' + LB +
    '   margin-left: -5px;' +
    '  	border-left: inherit;' + LB +
    '  	border-top: inherit;' + LB +
    '  	background-color: inherit;' + LB +
    '  	display: block;' + LB +
    '  	transform: rotate(-135deg);' + LB +
    '  }';

  if hs <> '' then
    Result := Result + LB + hs;

  m := FMaps.GetHeadStyle;
  if m <> '' then
    Result := Result + LB + m;
end;

function TTMSFNCCustomMaps.GetDefaultHTML: string;
var
  m: string;
begin
  Result :=
  {$IFNDEF WEBLIB}
  '<!DOCTYPE html>'+
  '<html lang="en">'+

  '<head>'+
  '  <meta charset="utf-8">' + LB +
  '  <meta http-equiv="X-UA-Compatible" content="IE=edge">' + LB +
  '  <meta name="viewport" content="width=device-width, initial-scale=1">' + LB +
  '  <title>404 HTML Template by Colorlib</title>' + LB +
  '  <link href="https://fonts.googleapis.com/css?family=Montserrat:200,400,700" rel="stylesheet">' + LB +
  '  <link type="text/css" rel="stylesheet" href="css/style.css" />' + LB +
  {$ENDIF}
  '  <style>' + LB +
//  {$IFNDEF WEBLIB}
//  '  * {'+ LB +
//  {$ELSE}
//  '  * {' + LB +
//  {$ENDIF}
//  '    -webkit-box-sizing: border-box;'+ LB +
//  '            box-sizing: border-box;'+ LB +
//  '  }'+ LB +

  {$IFDEF WEBLIB}
  '  #notfound {'+ LB +
  {$ELSE}
  '  html, body {'+ LB +
  {$ENDIF}
  '    background: #d7ebf6;' + LB +
  '    padding: 0;'+ LB +
  '    border: #000000;' + LB +
  '    width: 100%;' + LB +
  '    height: 100%;' + LB +
  '    margin: 0;' + LB +
  '    padding: 0;' + LB +
  '    border: solid #211b19;' + LB +
  '    border-width: thin;' + LB +
  '    overflow:hidden;' + LB +
  '    display:block;' + LB +
  '    -webkit-box-sizing: border-box;'+ LB +
  '            box-sizing: border-box;'+ LB +
  {$IFNDEF WEBLIB}
  '  }'+ LB +

  '  #notfound {'+ LB +
  {$ENDIF}
  '    position: relative;'+ LB +
  {$IFNDEF WEBLIB}
  '    height: 100vh;'+ LB +
  {$ENDIF}
  '  }'+ LB +

  '  #notfound .notfound {'+ LB +
  '    position: absolute;'+ LB +
  '    left: 50%;'+ LB +
  '    top: 50%;'+ LB +
  '    -webkit-transform: translate(-50%, -50%);'+ LB +
  '        -ms-transform: translate(-50%, -50%);'+ LB +
  '            transform: translate(-50%, -50%);'+ LB +
  '  }'+ LB +

  '  .notfound {'+ LB +
  '    max-width: 520px;'+ LB +
  '    width: 100%;'+ LB +
  '    line-height: 1.4;'+ LB +
  '    text-align: center;'+ LB +
  '  }'+ LB +

  '  .notfound .notfound-404 {'+ LB +
  '    position: relative;'+ LB +
  '    height: 200px;'+ LB +
  '    margin: 0px auto 20px;'+ LB +
  '    z-index: -1;'+ LB +
  '  }' + LB +

  '  .notfound .notfound-404 h2 {'+ LB +
  '    font-family: ''Montserrat'', sans-serif;'+ LB +
  '    font-size: 28px;'+ LB +
  '    font-weight: 400;'+ LB +
  '    text-transform: uppercase;'+ LB +
  '    color: #211b19;'+ LB +
  '    background: #d7ebf6;'+ LB +
  '    padding: 10px 5px;'+ LB +
  '    margin: auto;'+ LB +
  '    display: inline-block;'+ LB +
  '    position: absolute;'+ LB +
  '    bottom: 0px;'+ LB +
  '    left: 0;'+ LB +
  '    right: 0;'+ LB +
  '  }'+ LB +

  '  @media only screen and (max-width: 767px) {'+ LB +
  '    .notfound .notfound-404 h1 {'+ LB +
  '      font-size: 148px;'+ LB +
  '    }'+ LB +
  '  }'+ LB +

  '  @media only screen and (max-width: 480px) {'+ LB +
  '    .notfound .notfound-404 {'+ LB +
  '      height: 148px;'+ LB +
  '      margin: 0px auto 10px;'+ LB +
  '    }'+ LB +
  '    .notfound .notfound-404 h1 {'+ LB +
  '      font-size: 86px;'+ LB +
  '    }'+ LB +
  '    .notfound .notfound-404 h2 {'+ LB +
  '      font-size: 16px;'+ LB +
  '    }'+ LB +
  '    .notfound a {'+ LB +
  '      padding: 7px 15px;'+ LB +
  '      font-size: 14px;'+ LB +
  '    }'+ LB +
  '  }'+ LB +

  ' </style>' + LB +
  {$IFNDEF WEBLIB}
  '</head>' + LB +
  {$ENDIF}

  {$IFNDEF WEBLIB}
  '<body>' + LB +
  {$ENDIF}
  '  <div id="notfound">' + LB +
  '    <div class="notfound">' + LB +
  '      <div class="notfound-404">' + LB;


  m := '';
  if not MapReady then
  begin
    if Assigned(FMaps) then
      m := FMaps.GetIdentifier + ' can''t be displayed - please verify if API key is set!'
    else
      m := 'Map can''t be initialized - please verify if correct Service is set!';
  end;

  DoGetDefaultHTMLMessage(m);

  Result := Result + '        <h2>' + m + '</h2>' + LB;

  Result := Result +
  '      </div>' + LB +
  '    </div>' + LB +
  '  </div>' + LB
  {$IFNDEF WEBLIB}
  +'</body>' + LB +
  '</html>'
  {$ENDIF}
  ;
end;

function TTMSFNCCustomMaps.GetDocURL: string;
begin
  Result := TTMSFNCMapsDocURL;
end;

function TTMSFNCCustomMaps.GetElementContainerByID(
  AID: string): TTMSFNCMapsElementContainer;
begin
  Result := ElementContainers.ItemByID[AID];
end;

function TTMSFNCCustomMaps.GetElementContainersClass: TTMSFNCMapsElementContainersClass;
begin
  Result := TTMSFNCMapsElementContainers;
end;

function TTMSFNCCustomMaps.GetHeadLinksClass: TTMSFNCMapsHeadLinksClass;
begin
  Result := TTMSFNCMapsHeadLinks;
end;

function TTMSFNCCustomMaps.GetHTML: string;
var
  hs, bs: string;
  l: TTMSFNCMapsLinksList;
begin
  Result := '';
  if not MapReady then
  begin
    Result :=
    {$IFNDEF WEBLIB}
    StartHTMLTag + LB +
    StartBODYTag + LB +
    {$ENDIF}
    GetDefaultHTML
    {$IFNDEF WEBLIB}
     + LB + EndBODYTag + LB +
    EndHTMLTag
    {$ENDIF}
    ;
    Exit;
  end;

  {$IFDEF WEBLIB}
  Result := GetBody;
  {$ELSE}
  Result :=
    StartHTMLTag + LB +
    StartHEADTag + LB +
    '<meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no"/>'  + LB +
    '<meta http-equiv="content-type" content="text/html; charset=UTF-8"/>'  + LB +
    '<meta http-equiv="X-UA-Compatible" content="IE=11"/>'  + LB;

  if Options.Console then
    Result := Result + '<script type="text/javascript" src="' + DEBUGCONSOLELINK +'"></script>' + LB;

  l := TTMSFNCMapsLinksList.Create;
  try
    GetHeadLinks(l);
    hs := ParseLinks(l);
    l.Clear;
    GetBodyLinks(l);
    bs := ParseLinks(l);
  finally
    l.Free;
  end;

  Result := Result +
    hs + LB +
    StartSTYLETag + LB +
    GetHeadStyle + LB +
    EndStyleTag + LB +
    StartTITLETag + LB +
    FMaps.GetIdentifier + LB +
    EndTITLETag + LB +
    EndHEADTag + LB +
    StartBODYTag + LB +
    GetBody + LB +
    bs + LB +
    EndBODYTag + LB +
    EndHTMLTag + LB;
  {$ENDIF}
end;

function TTMSFNCCustomMaps.GetVersion: string;
var
  vn: Integer;
begin
  vn := GetVersionNr;
  Result := IntToStr(Hi(Hiword(vn)))+'.'+IntToStr(Lo(Hiword(vn)))+'.'+IntToStr(Hi(Loword(vn)))+'.'+IntToStr(Lo(Loword(vn)));
end;

function TTMSFNCCustomMaps.GetVersionNr: Integer;
begin
  Result := MakeLong(MakeWord(BLD_VER,REL_VER),MakeWord(MIN_VER,MAJ_VER));
end;

procedure TTMSFNCCustomMaps.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TTMSFNCCustomMaps) then
  begin
    FService := (Source as TTMSFNCCustomMaps).Service;
    FOptions.Assign((Source as TTMSFNCCustomMaps).Options);
    FAPIKey := (Source as TTMSFNCCustomMaps).APIKey;
    FMarkers.Assign((Source as TTMSFNCCustomMaps).Markers);
    FElementContainers.Assign((Source as TTMSFNCCustomMaps).ElementContainers);
    FLabels.Assign((Source as TTMSFNCCustomMaps).Labels);
    FPolylines.Assign((Source as TTMSFNCCustomMaps).Polylines);
    FPolygons.Assign((Source as TTMSFNCCustomMaps).Polygons);
    FCircles.Assign((Source as TTMSFNCCustomMaps).Circles);
    FRectangles.Assign((Source as TTMSFNCCustomMaps).Rectangles);
    FHeadLinks.Assign((Source as TTMSFNCCustomMaps).HeadLinks);
  end;
end;

procedure TTMSFNCCustomMaps.BeginUpdate;
begin
  inherited;
  Inc(FUpdateCount);
end;

constructor TTMSFNCCustomMaps.Create(AOwner: TComponent);
begin
  inherited;
  {$IFDEF WEBLIB}
  if (ElementID = '') and IsDesigning then
    ElementID := GetNewName;
  {$ENDIF}

  FMarkers := GetMarkersClass.Create(Self);
  FElementContainers := GetElementContainersClass.Create(Self);
  FLabels := GetLabelsClass.Create(Self);
  FPolygons := GetPolygonsClass.Create(Self);
  FRectangles := GetRectanglesClass.Create(Self);
  FCircles := GetCirclesClass.Create(Self);
  FPolylines := GetPolylinesClass.Create(Self);
  FHeadLinks := GetHeadLinksClass.Create(Self);

  CheckInstances;
end;

procedure TTMSFNCCustomMaps.CreateClasses;
begin
  inherited;

  Supports(Self, ITMSFNCCustomMapsProperties, FMapsProperties);

  FOptions := GetOptionsClass.Create;
  FOptions.OnChange := @OptionsChanged;
end;

procedure TTMSFNCCustomMaps.Initialized;
begin
  inherited;
  FMapInitialized := False;
  InitializeMap;
end;

procedure TTMSFNCCustomMaps.InitializeHTML;
{$IFNDEF WEBLIB}
{$IFNDEF ANDROID}
var
  s: TStringList;
  fn: string;
{$ENDIF}
{$ENDIF}
{$IFDEF WEBLIB}
var
  v: Boolean;
{$ENDIF}
begin
  if (FUpdateCount > 0) or IsDestroying or MapInitialized then
    Exit;

  {$IFDEF WEBLIB}
  LoadStyles;
  v := False;
  LoadScripts(True);
  LoadScripts(False);
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFNDEF ANDROID}
  if (Service = msHere) or LocalFileAccess then
  begin
    s := TStringList.Create;
    try
      s.Text := GetHTML;
      fn := GetLocalAccessFileName;
      s.SaveToFile(fn{$IFNDEF LCLWEBLIB}, TEncoding.UTF8{$ENDIF});
      LoadFile(fn);
    finally
      s.Free;
    end;
  end
  else
  {$ENDIF}
  {$ENDIF}
  begin
    LoadHTML(GetHTML);
  end;
end;

procedure TTMSFNCCustomMaps.InitializeMap;
var
  MapsServiceGoogleMaps: ITMSFNCMapsServiceGoogleMaps;
  MapsServiceHere: ITMSFNCMapsServiceHere;
  MapsServiceTomTom: ITMSFNCMapsServiceTomTom;
  MapsServiceAzureMaps: ITMSFNCMapsServiceAzureMaps;
  MapsServiceBingMaps: ITMSFNCMapsServiceBingMaps;
  MapsServiceOpenLayers: ITMSFNCMapsServiceOpenLayers;
  MapsServiceLeaflet: ITMSFNCMapsServiceLeaflet;
  MapsServiceMapBox: ITMSFNCMapsServiceMapBox;
  MapsServiceMapKit: ITMSFNCMapsServiceMapKit;
begin
  if (FUpdateCount > 0) or IsDestroying or MapInitialized then
    Exit;

  {$IFNDEF WEBLIB}
  if Assigned(FMaps) then
    FMaps.DestroyMaps;
  {$ENDIF}

  FMaps := nil;
  FMapsInstance := nil;

  case Service of
    msGoogleMaps:
    begin
      if TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceGoogleMaps, IInterface(MapsServiceGoogleMaps)) then
        FMaps := MapsServiceGoogleMaps.CreateMaps;
    end;
    msHere:
    begin
      if TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceHere, IInterface(MapsServiceHere)) then
        FMaps := MapsServiceHere.CreateMaps;
    end;
    msTomTom:
    begin
      if TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceTomTom, IInterface(MapsServiceTomTom)) then
        FMaps := MapsServiceTomTom.CreateMaps;
    end;
    msAzureMaps:
    begin
      if TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceAzureMaps, IInterface(MapsServiceAzureMaps)) then
        FMaps := MapsServiceAzureMaps.CreateMaps;
    end;
    msBingMaps:
    begin
      if TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceBingMaps, IInterface(MapsServiceBingMaps)) then
        FMaps := MapsServiceBingMaps.CreateMaps;
    end;
    msOpenLayers:
    begin
      if TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceOpenLayers, IInterface(MapsServiceOpenLayers)) then
        FMaps := MapsServiceOpenLayers.CreateMaps;
    end;
    msLeaflet:
    begin
      if TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceLeaflet, IInterface(MapsServiceLeaflet)) then
        FMaps := MapsServiceLeaflet.CreateMaps;
    end;
    msMapBox:
    begin
      if TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceMapBox, IInterface(MapsServiceMapBox)) then
        FMaps := MapsServiceMapBox.CreateMaps;
    end;
    msMapKit:
    begin
      if TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceMapKit, IInterface(MapsServiceMapKit)) then
        FMaps := MapsServiceMapKit.CreateMaps;
    end;
  end;

  if Assigned(FMaps) and Supports(FMaps, ITMSFNCCustomMapsInstance, FMapsInstance) then
    FMapsInstance.SetMapsProperties(FMapsProperties);

  InitializeHTML;
end;

procedure TTMSFNCCustomMaps.GetBodyLinks(AList: TTMSFNCMapsLinksList; AIncludeContent: Boolean = True; ACheckReady: Boolean = True);
begin
  if ACheckReady and not MapReady then
    Exit;

  GetLinks(AList, AIncludeContent, ACheckReady);
end;

function TTMSFNCCustomMaps.GetHeading: Double;
begin
  Result := 0;
end;

procedure TTMSFNCCustomMaps.GetHeadLinks(AList: TTMSFNCMapsLinksList; ACheckReady: Boolean = True);
var
  I: Integer;
begin
  if ACheckReady and not MapReady then
    Exit;

  for I := 0 to HeadLinks.Count - 1 do
  begin
    if HeadLinks[I].Enabled then
    begin
      case HeadLinks[I].Kind of
        mlkLink: AList.Add(TTMSFNCMapsLink.CreateLink(HeadLinks[I].URL, HeadLinks[I].&Type, HeadLinks[I].Rel));
        mlkScript: AList.Add(TTMSFNCMapsLink.CreateScript(HeadLinks[I].URL, HeadLinks[I].&Type, HeadLinks[I].CharSet, HeadLinks[I].Content.Text, HeadLinks[I].Defer, HeadLinks[I].Async));
        mlkStyle: AList.Add(TTMSFNCMapsLink.Create(HeadLinks[I].Kind, HeadLinks[I].URL, '', '', '', HeadLinks[I].Content.Text, False, False));
      end;
    end;
  end;

  FMaps.GetHeadLinks(AList);
  DoCustomizeHeadLinks(AList);
end;

function TTMSFNCCustomMaps.GetWaitForMapInitialized: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  Result :=
    'function ' + WAITFORMAPINITIALIZEDFUNC + '(){' + LB +
    '  if (' + FMaps.GetMapsServiceCheck + ') {' + LB +
    '    setTimeout(' + WAITFORMAPINITIALIZEDFUNC + ', 200);' + LB +
    '  } else if (!' + MAPVAR + ') {' + LB +
    '    ' + LOADMAPFUNC + '();' + LB +
    '  }' + LB +
    '};' + LB +
    WAITFORMAPINITIALIZEDFUNC + '();';
end;

function TTMSFNCCustomMaps.GetDeleteMarkerFunction: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  Result :=
    'function ' + DELETEMARKERFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + MARKERVAR + ' = ' + MARKERARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    FMaps.GetDeleteMarker + LB + LB +
    '  if (' + MARKERVAR + '){' + LB +
    '    delete ' + MARKERARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '    ' + MARKERVAR + ' = null;' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomMaps.GetAddOrUpdateElementContainerFunction: string;
begin
  Result :=
    'function updateElementPositions(){' + LB +
    '  for (var key in ' + ELEMENTCONTAINERARRAYVAR + '){' + LB +
    '    var v = key;' + LB +
    '    var it = ' + ELEMENTCONTAINERARRAYVAR + '[v];' + LB +
    '    var obj = it[0];' + LB +
    '    var posType = obj.getAttribute("data-position");' + LB +

    //Label positioned at coordinate
    '    if (posType == 9){ ' + LB +

    //LabelType
    //0 = Label (default)
    //1 = Balloon

    '      var lat = parseFloat(obj.getAttribute("data-latitude"));' + LB +
    '      var lon = parseFloat(obj.getAttribute("data-longitude"));' + LB +
    '      var xy = ' + GETLATLONTOXYFUNCTION + '({"Latitude": lat, "Longitude": lon})' + LB +
    '      var labeltype = obj.getAttribute("data-labeltype");' + LB +
    '      var tadapt = 0;' + LB +
    '      var tailheight = 0;' + LB +

    '      if (labeltype == 1) {' + LB +
    '        tailheight = 10;' + LB +
    '        tadapt = (obj.offsetHeight + tailheight);' + LB +
    '        obj.classList.add("tmsfncmaps_sb");' + LB +
    '      } else { ' + LB +
    '        obj.classList.remove("tmsfncmaps_sb");' + LB +
    '      }' + LB +

    '      var xyObj;' + LB +
    '      if (typeof xy == "string")' + LB +
    '        xyObj = JSON.parse(xy);' + LB +
    '      else' + LB +
    '        xyObj = xy;' + LB +

//    '      obj.style.margin = xyObj.Y + "px 0px 0px " + xyObj.X + "px";' + LB +
    '      obj.style.margin = (xyObj.Y - tadapt) + "px 0px 0px " + (xyObj.X - (obj.offsetWidth / 2)) + "px";' + LB +
    '      obj.style.cursor = "default";' + LB +

    '    }' + LB +

    //Label positioned at bounds
    '    if (posType == 10){ ' + LB +

    '      var nelat = parseFloat(obj.getAttribute("data-ne-latitude"));' + LB +
    '      var nelon = parseFloat(obj.getAttribute("data-ne-longitude"));' + LB +

    '      var swlat = parseFloat(obj.getAttribute("data-sw-latitude"));' + LB +
    '      var swlon = parseFloat(obj.getAttribute("data-sw-longitude"));' + LB +

    '      var xyne = ' + GETLATLONTOXYFUNCTION + '({"Latitude": nelat, "Longitude": nelon})' + LB +
    '      var xysw = ' + GETLATLONTOXYFUNCTION + '({"Latitude": swlat, "Longitude": swlon})' + LB +

    '      var xyneObj;' + LB +
    '      if (typeof xyne == "string")' + LB +
    '        xyneObj = JSON.parse(xyne);' + LB +
    '      else' + LB +
    '        xyneObj = xyne;' + LB +

    '      var xyswObj;' + LB +
    '      if (typeof xysw == "string")' + LB +
    '        xyswObj = JSON.parse(xysw);' + LB +
    '      else' + LB +
    '        xyswObj = xysw;' + LB +

    '      obj.style.margin = xyneObj.Y + "px 0px 0px " + xyswObj.X + "px";' + LB +

    '      var top = xyneObj.Y;' + LB +
    '      var right = xyneObj.X;' + LB +
    '      var bottom = xyswObj.Y;' + LB +
    '      var left = xyswObj.X;' + LB +

    '      var width = right - left;' + LB +
    '      var height = bottom - top;' + LB +
    '      if (left > right) {' + LB +
    '        width = right - left;' + LB +
    '      }' + LB +
    '      if (top > bottom) {' + LB +
    '        height = bottom - top;' + LB +
    '      }' + LB +

    '      obj.style.width = width;' + LB +
    '      obj.style.height = height;' + LB +

    '      obj.style.overflow = "hidden";' + LB +
    '      obj.style.cursor = "default";' + LB +

    '    }' + LB +

    '  }' + LB +
    '}' + LB +

    'setInterval(updateElementPositions, 1);' + LB +

    'function ' + ADDORUPDATEELEMENTCONTAINERFUNCTION + '(' + PARAMSNAME + '){' + LB +

    '  var ' + ELEMENTCONTAINERVAR + ' = ' + ELEMENTCONTAINERARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +

    '  if (!' + ELEMENTCONTAINERVAR + '){' + LB +
    '    ' + ELEMENTCONTAINERVAR + ' = [];' + LB +
    '    ' + ELEMENTCONTAINERVAR + '.push(document.createElement("div"));' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[0].id = ' + PARAMSNAME + '["HTMLElementID"];' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[0].className = ' + PARAMSNAME + '["HTMLElementClassName"];' + LB +

    '    ' + ELEMENTCONTAINERVAR + '.push(document.createElement("style"));' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[1].id = ' + PARAMSNAME + '["HTMLElementID"] + "Style";' + LB +
    '    var elementStyle = atob(' + PARAMSNAME + '["StyleAsBase64"]);' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[1].innerHTML = elementStyle;' + LB +

    '    ' + ELEMENTCONTAINERVAR + '.push(document.createElement("script"));' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[2].id = ' + PARAMSNAME + '["HTMLElementID"] + "Script";' + LB +
    '    var elementScript = atob(' + PARAMSNAME + '["ScriptAsBase64"]);' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[2].innerHTML = elementScript;' + LB +

    '    document.head.appendChild(' + ELEMENTCONTAINERVAR + '[2]);' + LB +
    '    document.head.appendChild(' + ELEMENTCONTAINERVAR + '[1]);' + LB +
    {$IFDEF WEBLIB}
    '    ' + ElementID + '.appendChild(' + ELEMENTCONTAINERVAR + '[0]);' + LB +
    {$ELSE}
    '    document.body.appendChild(' + ELEMENTCONTAINERVAR + '[0]);' + LB +
    {$ENDIF}


    '  }' + LB +

    '    var  elementPosition = "";' + LB +

    '    switch (' + PARAMSNAME + '["Position"]){' + LB +
    '      case 1:' + LB +
    '        elementPosition = "top: 0; left: 0;";' + LB +
    '        break;' + LB +
    '      case 2:' + LB +
    '        elementPosition = "top: 0; left: 50%; transform: translate(-50%, 0);";' + LB +
    '        break;' + LB +
    '      case 3:' + LB +
    '        elementPosition = "top: 0; right: 0;";' + LB +
    '        break;' + LB +
    '      case 4:' + LB +
    '        elementPosition = "top: 50%; left: 0;";' + LB +
    '        break;' + LB +
    '      case 5:' + LB +
    '        elementPosition = "position: fixed; top: 50%; left: 50%; transform: translate(-50%,-50%);";' + LB +
    '        break;' + LB +
    '      case 6:' + LB +
    '        elementPosition = "bottom: 0; left: 0;";' + LB +
    '        break;' + LB +
    '      case 7:' + LB +
    '        elementPosition = "bottom: 0; left: 50%; transform: translate(-50%, 0);"' + LB +
    '        break;' + LB +
    '      case 8:' + LB +
    '        elementPosition = "bottom: 0; right: 0;";' + LB +
    '        break;' + LB +
    '      case 9:' + LB +
    '        elementPosition = "top: 0; left: 0;";' + LB +
    '        break;' + LB +
    '      case 10:' + LB +
    '        elementPosition = "top: 0; left: 0;";' + LB +
    '        break;' + LB +

    '    }' + LB +

    '    ' + ELEMENTCONTAINERVAR + '[0].setAttribute("data-latitude", ' + PARAMSNAME + '["Coordinate"]["Latitude"]);' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[0].setAttribute("data-longitude", ' + PARAMSNAME + '["Coordinate"]["Longitude"]);' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[0].setAttribute("data-position", ' + PARAMSNAME + '["Position"]);' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[0].setAttribute("data-labeltype", ' + PARAMSNAME + '["LabelType"]);' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[0].setAttribute("data-alignment", ' + PARAMSNAME + '["Alignment"]);' + LB +

    '    ' + ELEMENTCONTAINERVAR + '[0].setAttribute("data-ne-latitude", ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"]);' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[0].setAttribute("data-ne-longitude", ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"]);' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[0].setAttribute("data-sw-latitude", ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"]);' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[0].setAttribute("data-sw-longitude", ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"]);' + LB +

    '    elementPosition += "margin-top: " + ' + PARAMSNAME + '["Margins"]["Top"] + "px;";' + LB +
    '    elementPosition += "margin-left: " + ' + PARAMSNAME + '["Margins"]["Left"] + "px;";' + LB +
    '    elementPosition += "margin-bottom: " + ' + PARAMSNAME + '["Margins"]["Bottom"] + "px;";' + LB +
    '    elementPosition += "margin-right: " + ' + PARAMSNAME + '["Margins"]["Right"] + "px;";' + LB +

    '    var cssText = "z-index: 9999;";' + LB +
    '    if (' + PARAMSNAME + '["UseDefaultStyle"]){' + LB +
    '      cssText += "border-radius: 5px; padding: 5px; min-height: 50px; min-width: 50px;" + '+
		'        "background-color: white; font-size: 14px; font-family: Roboto;" +'+
		'        "text-align: center; color: grey;line-height: 18px; overflow: hidden;" + elementPosition;' + LB +
    '    }' + LB +
    '    cssText += "position: absolute;" + elementPosition;' + LB +
  	'    ' + ELEMENTCONTAINERVAR + '[0].style.cssText = cssText;' + LB +

    '    if (! ' + PARAMSNAME + '["Visible"])' + LB +
    '      ' + ELEMENTCONTAINERVAR + '[0].style.display = "none";' + LB +
    '    else' + LB +
    '      ' + ELEMENTCONTAINERVAR + '[0].style.display = "";' + LB +

    '    var elementHTML = atob(' + PARAMSNAME + '["HTMLAsBase64"]);' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[0].innerHTML = elementHTML;' + LB +

    '    var elementStyle = atob(' + PARAMSNAME + '["StyleAsBase64"]);' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[1].innerHTML = elementStyle;' + LB +

    '    var elementScript = atob(' + PARAMSNAME + '["ScriptAsBase64"]);' + LB +
    '    ' + ELEMENTCONTAINERVAR + '[2].innerHTML = elementScript;' + LB;

  Result := Result +
    '    var customControl = null;' + LB +
    '    for (i = 0; i < ' + PARAMSNAME + '["Actions"].length; i++){' + LB +
    '      if (' + PARAMSNAME + '["Actions"][i]["Enabled"]){' + LB +
    '        customControl = document.getElementById(' + PARAMSNAME + '["Actions"][i]["HTMLElementID"]);' + LB +
    '        if (customControl){' + LB +
    '          switch (' + PARAMSNAME + '["Actions"][i]["Event"]){' + LB +
    '            case 0:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("click", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCLICKEVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("click", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCLICKEVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("click", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCLICKEVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("click", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCLICKEVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("click", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCLICKEVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("click", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCLICKEVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 1:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("dblclick", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONDBLCLICKEVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("dblclick", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONDBLCLICKEVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("dblclick", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONDBLCLICKEVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("dblclick", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONDBLCLICKEVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("dblclick", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONDBLCLICKEVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("dblclick", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONDBLCLICKEVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 2:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("keypress", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYPRESSEVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("keypress", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYPRESSEVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("keypress", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYPRESSEVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("keypress", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYPRESSEVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("keypress", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYPRESSEVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("keypress", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYPRESSEVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 3:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("keydown", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYDOWNEVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("keydown", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYDOWNEVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("keydown", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYDOWNEVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("keydown", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYDOWNEVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("keydown", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYDOWNEVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("keydown", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYDOWNEVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 4:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("keyup", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYUPEVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("keyup", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYUPEVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("keyup", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYUPEVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("keyup", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYUPEVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("keyup", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYUPEVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("keyup", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONKEYUPEVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 5:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("mousedown", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEDOWNEVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("mousedown", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEDOWNEVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("mousedown", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEDOWNEVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("mousedown", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEDOWNEVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("mousedown", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEDOWNEVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("mousedown", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEDOWNEVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 6:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("mousemove", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEMOVEEVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("mousemove", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEMOVEEVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("mousemove", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEMOVEEVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("mousemove", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEMOVEEVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("mousemove", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEMOVEEVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("mousemove", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEMOVEEVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 7:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("mouseup", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEUPEVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("mouseup", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEUPEVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("mouseup", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEUPEVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("mouseup", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEUPEVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("mouseup", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEUPEVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("mouseup", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEUPEVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 8:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("mouseenter", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEENTEREVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("mouseenter", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEENTEREVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("mouseenter", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEENTEREVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("mouseenter", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEENTEREVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("mouseenter", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEENTEREVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("mouseenter", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSEENTEREVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 9:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("mouseleave", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSELEAVEEVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("mouseleave", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSELEAVEEVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("mouseleave", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSELEAVEEVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("mouseleave", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSELEAVEEVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("mouseleave", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSELEAVEEVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("mouseleave", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONMOUSELEAVEEVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 10:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("blur", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONBLUREVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("blur", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONBLUREVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("blur", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONBLUREVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("blur", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONBLUREVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("blur", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONBLUREVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("blur", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONBLUREVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 11:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("focus", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONFOCUSEVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("focus", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONFOCUSEVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("focus", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONFOCUSEVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("focus", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONFOCUSEVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("focus", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONFOCUSEVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("focus", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONFOCUSEVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 12:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("change", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCHANGEEVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("change", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCHANGEEVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("change", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCHANGEEVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("change", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCHANGEEVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("change", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCHANGEEVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("change", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCHANGEEVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 13:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener("select", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONSELECTEVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener("select", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONSELECTEVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener("select", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONSELECTEVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener("select", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONSELECTEVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener("select", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONSELECTEVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener("select", function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONSELECTEVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '            case 14:' + LB +
    '              switch (' + PARAMSNAME + '["Actions"][i]["EventReturnValue"]){' + LB +
    '                case 0:' + LB +
    '                  customControl.addEventListener(' + PARAMSNAME + '["Actions"][i]["CustomEvent"], function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCUSTOMEVENT + '", this.id), null);});' + LB +
    '                  break;' + LB +
    '                case 1:' + LB +
    '                  customControl.addEventListener(' + PARAMSNAME + '["Actions"][i]["CustomEvent"], function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCUSTOMEVENT + '", this.id), this.value);});' + LB +
    '                  break;' + LB +
    '                case 2:' + LB +
    '                  customControl.addEventListener(' + PARAMSNAME + '["Actions"][i]["CustomEvent"], function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCUSTOMEVENT + '", this.id), this.checked);});' + LB +
    '                  break;' + LB +
    '                case 3:' + LB +
    '                  customControl.addEventListener(' + PARAMSNAME + '["Actions"][i]["CustomEvent"], function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCUSTOMEVENT + '", this.id), this.innerHTML);});' + LB +
    '                  break;' + LB +
    '                case 4:' + LB +
    '                  customControl.addEventListener(' + PARAMSNAME + '["Actions"][i]["CustomEvent"], function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCUSTOMEVENT + '", this.id), this.innerText);});' + LB +
    '                  break;' + LB +
    '                case 5:' + LB +
    '                  customControl.addEventListener(' + PARAMSNAME + '["Actions"][i]["CustomEvent"], function(event){' + GETSENDEVENT + '(parseEvent(event, "' + ACTIONCUSTOMEVENT + '", this.id), this.selectedIndex);});' + LB +
    '                  break;' + LB +
    '              }' + LB +
    '              break;' + LB +
    '          }' + LB +
    '        }' + LB +
    '      }' + LB +
    '    }' + LB;

  Result := Result +
    '  if (' + ELEMENTCONTAINERVAR + '){' + LB +
    '    ' + ELEMENTCONTAINERARRAYVAR + '[' + PARAMSNAME + '["ID"]] = ' + ELEMENTCONTAINERVAR + ';' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomMaps.GetDeleteElementContainerFunction: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  Result :=
    'function ' + DELETEELEMENTCONTAINERFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + ELEMENTCONTAINERVAR + ' = ' + ELEMENTCONTAINERARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '  if (' + ELEMENTCONTAINERVAR + '){' + LB +
    {$IFDEF WEBLIB}
    '    ' + ElementID + '.removeChild(' + ELEMENTCONTAINERVAR + '[0]);' + LB +
    {$ELSE}
    '    document.body.removeChild(' + ELEMENTCONTAINERVAR + '[0]);' + LB +
    {$ENDIF}
    '    document.head.removeChild(' + ELEMENTCONTAINERVAR + '[1]);' + LB +
    '    document.head.removeChild(' + ELEMENTCONTAINERVAR + '[2]);' + LB +
    '    delete ' + ELEMENTCONTAINERARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '    ' + ELEMENTCONTAINERVAR + ' = null;' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomMaps.GetAddOrUpdateMarkerFunction: string;
var
  m: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  m := '';
  DoCustomizeMarker(m);

  Result :=
    'function ' + ADDORUPDATEMARKERFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + MARKERVAR + ' = ' + MARKERARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '  if (' + MARKERVAR + '){' + LB +
    '   }' +
    FMaps.GetAddOrUpdateMarker + LB + LB;

  if m <> '' then
    Result := Result + m + LB + LB;

  Result := Result +
    '  if (' + MARKERVAR + '){' + LB +
    '    ' + MARKERARRAYVAR + '[' + PARAMSNAME + '["ID"]] = ' + MARKERVAR + ';' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomMaps.GetClosePopupFunction: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  Result :=
    'function ' + CLOSEPOPUPFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + POPUPVAR + ' = ' + POPUPARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    FMaps.GetClosePopup + LB + LB +
    '  if (' + POPUPVAR + '){' + LB +
    '    delete ' + POPUPARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '    ' + POPUPVAR + ' = null;' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomMaps.GetShowPopupFunction: string;
var
  m: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  m := '';
  DoCustomizePopup(m);

  Result :=
    'function ' + SHOWPOPUPFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + POPUPVAR + ' = ' + POPUPARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    FMaps.GetShowPopup + LB + LB;

  if m <> '' then
    Result := Result + m + LB + LB;

  Result := Result +
    '  if (' + POPUPVAR + '){' + LB +
    '    ' + POPUPARRAYVAR + '[' + PARAMSNAME + '["ID"]] = ' + POPUPVAR + ';' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomMaps.GetUpdateOptions: string;
var
  m, cm: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  m := '';
  DoCustomizeOptions(m);

  Result :=
    'function ' + UPDATEOPTIONSFUNCTION + '(' + PARAMSNAME + '){' + LB;

  if m <> '' then
    Result := Result + m + LB + LB;

  cm := GetCustomOptions;
  if cm <> '' then
    Result := Result + cm + LB + LB;

  Result := Result +
      FMaps.GetUpdateOptions + LB +
    '}';
end;

procedure TTMSFNCCustomMaps.GetZoomLevel;
begin
  if MapInitialized and MapReady then
    ExecuteJavaScript(GETZOOMLEVELFUNCTION + '()', {$IFDEF LCLWEBLIB}@{$ENDIF}DoGetZoomLevel);
end;

function TTMSFNCCustomMaps.GetZoomOnDblClick: Boolean;
begin
  Result := Options.ZoomOnDblClick;
end;

function TTMSFNCCustomMaps.GetZoomOnWheelScroll: Boolean;
begin
  Result := Options.ZoomOnWheelScroll;
end;

function TTMSFNCCustomMaps.GetZoomToBoundsFunction: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  Result :=
    'function ' + ZOOMTOBOUNDSFUNCTION + '(' + PARAMSNAME + '){' + LB +
      FMaps.GetZoomToBounds + LB +
    '}';
end;

function TTMSFNCCustomMaps.GetSetCenterCoordinateFunction: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  Result :=
    'function ' + SETCENTERCOORDINATEFUNCTION + '(' + PARAMSNAME + '){' + LB +
      FMaps.GetSetCenterCoordinate + LB +
    '}';
end;

function TTMSFNCCustomMaps.GetSetZoomLevelFunction: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  Result :=
    'function ' + SETZOOMLEVELFUNCTION + '(' + PARAMSNAME + '){' + LB +
      FMaps.GetSetZoomLevel + LB +
    '}';
end;

function TTMSFNCCustomMaps.GetAddMarkers: string;
var
  I: Integer;
begin
  Result := '';
  for I := 0 to Markers.Count - 1 do
    Result := Result + '  ' + ADDORUPDATEMARKERFUNCTION + '(' + Markers[I].ToJSON + ');' + LB;
end;

function TTMSFNCCustomMaps.GetDeletePolyElementFunction: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  Result :=
    'function ' + DELETEPOLYELEMENTFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + POLYELEMENTVAR + ' = ' + POLYELEMENTARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    FMaps.GetDeletePolyElement + LB + LB +
    '  if (' + POLYELEMENTVAR + '){' + LB +
    '    delete ' + POLYELEMENTARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '    ' + POLYELEMENTVAR + ' = null;' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomMaps.GetAddOrUpdatePolyElementFunction: string;
var
  p: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  p  := '';
  DoCustomizePolyElement(p);

  Result :=
    'function ' + ADDORUPDATEPOLYELEMENTFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + POLYELEMENTVAR + ' = ' + POLYELEMENTARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    FMaps.GetAddOrUpdatePolyElement + LB + LB;

    if p <> '' then
      Result := Result + p + LB + LB;

  Result := Result +
    '  if (' + POLYELEMENTVAR + '){' + LB +
    '    ' + POLYELEMENTARRAYVAR + '[' + PARAMSNAME + '["ID"]] = ' + POLYELEMENTVAR + ';' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomMaps.InternalGetAddPolyElements(AElements: TTMSFNCMapsPolyElements): string;
var
  I, K, C: Integer;
  arr: TTMSFNCObjectExcludePropertyListArray;
begin
  Result := '';
  SetLength(arr, 1);
  arr[0] := 'Coordinates';

  for I := 0 to AElements.Count - 1 do
  begin
    Result := Result + INITIALIZECOORDINATEARRAY + '();' + LB;
    for C := 0 to AElements[I].Coordinates.Count - 1 do
      Result := Result + ADDCOORDINATETOARRAY + '(' + AElements[I].Coordinates[C].ToJSON + ');' + LB;

    Result := Result + INITIALIZEHOLESARRAY + '();' + LB;
    for C := 0 to AElements[I].Holes.Count - 1 do
    begin
      for K := AElements[I].Holes[C].Coordinates.Count - 1 downto 0 do
        Result := Result + ADDHOLETOARRAY + '(["' + AElements[I].Holes[C].ID + '",' + AElements[I].Holes[C].Coordinates[K].ToJSON + ']);' + LB;
    end;

    Result := Result + '  ' + ADDORUPDATEPOLYELEMENTFUNCTION + '(' + AElements[I].ToJSON(arr) + ');' + LB;
  end;
end;

function TTMSFNCCustomMaps.GetAddPolyElements: string;
var
  apl, apg, apc, apr: string;
begin
  Result := '';
  apl := InternalGetAddPolyElements(Polygons);
  if apl <> '' then
    Result := Result + apl + LB;

  apr := InternalGetAddPolyElements(Rectangles);
  if apr <> '' then
    Result := Result + apr + LB;

  apc := InternalGetAddPolyElements(Circles);
  if apc <> '' then
    Result := Result + apc + LB;

  apg := InternalGetAddPolyElements(Polylines);
  if apg <> '' then
    Result := Result + apg + LB;
end;

function TTMSFNCCustomMaps.GetCallInitialize: string;
begin
  Result :=
  '  var jsonObj = getDefaultEventDataObject();' + LB +
  '  jsonObj["EventName"] = "Initialized";' + LB +
  '  sendObjectMessage(JSON.stringify(jsonObj));';
end;

procedure TTMSFNCCustomMaps.GetCenterCoordinate;
begin
  if MapInitialized and MapReady then
    ExecuteJavaScript(GETCENTERCOORDINATEFUNCTION + '()', {$IFDEF LCLWEBLIB}@{$ENDIF}DoGetCenterCoordinate);
end;

function TTMSFNCCustomMaps.GetGetCenterCoordinateFunction: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  Result :=
    'function ' + GETCENTERCOORDINATEFUNCTION + '(){' + LB +
    '  var jsonObj = getDefaultCoordinateObject();' + LB +
      FMaps.GetGetCenterCoordinate + LB +
      {$IFDEF NEEDSSTRINGIFY}
    '  return JSON.stringify(jsonObj);' + LB +
      {$ELSE}
    '  return jsonObj;' + LB +
      {$ENDIF}
    '}';
end;

function TTMSFNCCustomMaps.LatLonToXY(ALatitude: Double; ALongitude: Double): TTMSFNCMapsAnchorPointRec;
begin
  Result := LatLonToXY(CreateCoordinate(ALatitude, ALongitude));
end;

function TTMSFNCCustomMaps.LatLonToXY(
  ACoordinate: TTMSFNCMapsCoordinateRec): TTMSFNCMapsAnchorPointRec;
var
  b: TTMSFNCMapsCoordinate;
  p: TTMSFNCMapsAnchorPoint;
  s: String;
begin
  Result.X := 0;
  Result.Y := 0;
  if MapInitialized and MapReady then
  begin
    b := TTMSFNCMapsCoordinate.Create(ACoordinate.Latitude, ACoordinate.Longitude);
    p := TTMSFNCMapsAnchorPoint.Create;
    p.X := 0;
    p.Y := 0;
    try
      s := ExecuteJavaScriptSync(GETLATLONTOXYFUNCTION + '(' + b.ToJSON + ')');
      if (s <> '') and (s <> 'null') then
      begin
        p.json := s;
        Result.X := p.X;
        Result.Y := p.Y;
      end;
    finally
      b.Free;
      p.Free;
    end;
  end;
end;

function TTMSFNCCustomMaps.GetGetLatLonToXYFunction: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  Result :=
    'function ' + GETLATLONTOXYFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var jsonObj = getDefaultPointObject();' + LB +
    FMaps.GetLatLonToXY + LB +
      {$IFDEF NEEDSSTRINGIFY}
    '  return JSON.stringify(jsonObj);' + LB +
      {$ELSE}
    '  return jsonObj;' + LB +
      {$ENDIF}
    '}';
end;

function TTMSFNCCustomMaps.XYToCoordinate(AX,
  AY: Single): TTMSFNCMapsCoordinateRec;
begin
  Result := XYToLatLon(AX, AY);
end;

function TTMSFNCCustomMaps.XYToLatLon(AX: Single; AY: Single): TTMSFNCMapsCoordinateRec;
var
  b: TTMSFNCMapsAnchorPoint;
  c: TTMSFNCMapsCoordinate;
  s: String;
begin
  Result := DefaultCoordinate;
  if MapInitialized and MapReady then
  begin
    c := TTMSFNCMapsCoordinate.Create;
    b := TTMSFNCMapsAnchorPoint.Create;
    b.X := AX;
    b.Y := AY;
    try
      s := ExecuteJavaScriptSync(GETXYTOLATLONFUNCTION + '(' + b.ToJSON + ')');
      if (s <> '') and (s <> 'null') then
      begin
        c.JSON := s;
        Result.Latitude := c.Latitude;
        Result.Longitude := c.Longitude;
      end;
    finally
      b.Free;
      c.Free;
    end;
  end;
end;

function TTMSFNCCustomMaps.GetGetXYToLatLonFunction: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  Result :=
    'function ' + GETXYTOLATLONFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var jsonObj = getDefaultCoordinateObject();' + LB +
    FMaps.GetXYToLatLon + LB +
      {$IFDEF NEEDSSTRINGIFY}
    '  return JSON.stringify(jsonObj);' + LB +
      {$ELSE}
    '  return jsonObj;' + LB +
      {$ENDIF}
    '}';
end;

procedure TTMSFNCCustomMaps.GetBounds;
begin
  if MapInitialized and MapReady then
    ExecuteJavaScript(GETBOUNDSFUNCTION + '()', {$IFDEF LCLWEBLIB}@{$ENDIF}DoGetBounds);
end;

function TTMSFNCCustomMaps.GetGetBoundsFunction: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  Result :=
    'function ' + GETBOUNDSFUNCTION + '(){' + LB +
    '  var jsonObj = getDefaultBoundsObject();' + LB +
      FMaps.GetGetBounds + LB +
      {$IFDEF NEEDSSTRINGIFY}
    '  return JSON.stringify(jsonObj);' + LB +
      {$ELSE}
    '  return jsonObj;' + LB +
      {$ENDIF}
    '}';
end;

function TTMSFNCCustomMaps.GetGetZoomLevelFunction: string;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  Result :=
    'function ' + GETZOOMLEVELFUNCTION + '(){' + LB +
    '  var z;' + LB +
       FMaps.GetGetZoomLevel + LB +
    '  return z;' + LB +
    '}';
end;

function TTMSFNCCustomMaps.GetGlobalVariables: string;
var
  m: string;
  o: string;
  c: string;
  b: string;
  ci: string;
  p: string;
  co: TTMSFNCMapsCoordinate;
  obj: TTMSFNCMapsEventData;
  bo: TTMSFNCMapsBounds;
  cir: TTMSFNCMapsCircle;
  po: TTMSFNCMapsBasePoint;
begin
  Result := '';
  if not Assigned(FMaps) then
    Exit;

  co := TTMSFNCMapsCoordinate.Create(0, 0);
  try
    c := co.JSON;
  finally
    co.Free;
  end;

  po := TTMSFNCMapsBasePoint.Create;
  try
    p := po.JSON;
  finally
    po.Free;
  end;

  obj := TTMSFNCMapsEventData.Create;
  try
    o := obj.JSON;
  finally
    obj.Free;
  end;

  bo := TTMSFNCMapsBounds.Create(CreateCoordinate(0 , 0), CreateCoordinate(0 , 0));
  try
    b := bo.JSON;
  finally
    bo.Free;
  end;

  cir := TTMSFNCMapsCircle.Create(nil);
  try
    ci := cir.JSON;
  finally
    cir.Free;
  end;

  m := '';
  DoCustomizeGlobalVariables(m);
  if m <> '' then
    Result := Result + m + LB;

  Result := Result + FMaps.GetGlobalVariables + LB;

  Result := Result +

    {$IFDEF WEBLIB}
    'var ' + MAPCOMPONENT + ' = null;' + LB +
    {$ENDIF}
    'var ' + MAPBOUNDS + ';' + LB +
    'var ' + MAPOPTIONS + ';' + LB +
    'var ' + MAPVAR + ' = null;' + LB +
    'var ' + MARKERARRAYVAR + ' = {};' + LB +
    'var ' + ELEMENTCONTAINERARRAYVAR + ' = {};' + LB +
    'var ' + POPUPARRAYVAR + ' = {};' + LB +
    'var ' + POLYELEMENTARRAYVAR + ' = {};' + LB +
    'var ' + COORDINATEARRAYVAR + ';' + LB +
    'var ' + HOLEARRAYVAR + ' = {};' + LB +
    'var MapCallBackFlag = false;' + LB + LB +
    'function ' + INITIALIZECOORDINATEARRAY + '(){' + LB +
    '  ' + COORDINATEARRAYVAR + ' = ' + FMaps.GetInitializeCoordinateArray + LB +
    '}' + LB + LB +
    'function ' + INITIALIZEHOLESARRAY + '(){' + LB +
    '  ' + HOLEARRAYVAR + ' = ' + FMaps.GetInitializeHolesArray + LB +
    '}' + LB + LB +
    'function ' + ADDCOORDINATETOARRAY + '(' + PARAMSNAME + '){' + LB +
    '  ' + FMaps.GetAddCoordinateToArray + LB +
    '}' + LB + LB +
    'function ' + ADDHOLETOARRAY + '(' + PARAMSNAME + '){' + LB +
    '  ' + FMaps.GetAddHoleToArray + LB +
    '}' + LB + LB +
    'function ' + ADDCOORDINATESTOARRAY + '(' + PARAMSNAME + '){' + LB +
    '  for (var i=0; i < ' + PARAMSNAME + '.length; i++){' + LB +
    '    ' + ADDCOORDINATETOARRAY + '({"Latitude": ' + PARAMSNAME + '[i]["Latitude"], "Longitude": ' + PARAMSNAME + '[i]["Longitude"]});' + LB +
    '  }' + LB +
    '}' + LB + LB +
    'function ' + ADDHOLESTOARRAY + '(' + PARAMSNAME + '){' + LB +
    '  for (var i=' + PARAMSNAME + '[1].length - 1; i >= 0; i--){' + LB +
    '    ' + ADDHOLETOARRAY + '([' + PARAMSNAME + '[0]' + ', {"Latitude": ' + PARAMSNAME + '[1][i]["Latitude"], "Longitude": ' + PARAMSNAME + '[1][i]["Longitude"]}]);' + LB +
    '  }' + LB +
    '}' + LB + LB +
    {$IFDEF WEBLIB}
    'function MapKeyDown(event){' + LB +
    '  var r = {''EventName'': ''MapKeyDown''};' + LB +
    '  ' + GETSENDEVENT + '(r, event.keyCode);' + LB +
    '}' + LB +
    {$ENDIF}
    'function ResetMap(){' + LB +
    {$IFDEF WEBLIB}
    MAPID + '.removeEventListener(''keydown'', MapKeyDown)' + LB +
    {$ENDIF}
    FMaps.GetResetMap + LB +
    '  ' + MAPVAR + ' = null;' + LB +
    '}' + LB +
    'function MapCallBack(){' + LB +
    '  MapCallBackFlag = true;' + LB +
    '}' + LB + LB +
    'function ' + GETMARKERARRAYVAR + '{' + LB +
    '  var arr = [];' + LB +
    '  for (var key in ' + MARKERARRAYVAR + '){' + LB +
    '    var v = key;' + LB +
    '    arr.push(v);' + LB +
    '  }' + LB +
    '  return arr.toString();' + LB +
    '}' + LB + LB +
    'function ' + GETELEMENTCONTAINERARRAYVAR + '{' + LB +
    '  var arr = [];' + LB +
    '  for (var key in ' + ELEMENTCONTAINERARRAYVAR + '){' + LB +
    '    var v = key;' + LB +
    '    arr.push(v);' + LB +
    '  }' + LB +
    '  return arr.toString();' + LB +
    '}' + LB + LB +
    'function ' + GETPOPUPARRAYVAR + '{' + LB +
    '  var arr = [];' + LB +
    '  for (var key in ' + POPUPARRAYVAR + '){' + LB +
    '    var v = key;' + LB +
    '    arr.push(v);' + LB +
    '  }' + LB +
    '  return arr.toString();' + LB +
    '}' + LB + LB +
    'function ' + GETPOLYELEMENTARRAYVAR + '{' + LB +
    '  var arr = [];' + LB +
    '  for (var key in ' + POLYELEMENTARRAYVAR + '){' + LB +
    '    var v = key;' + LB +
    '    arr.push(v);' + LB +
    '  }' + LB +
    '  return arr.toString();' + LB +
    '}' + LB + LB +
    'function ' + GETFOCUSMAP + '(){' + LB +
    '  if (document.activeElement != ' + MAPID + '){' + LB +
    '    ' + MAPID + '.focus();' + LB +
    '  }' + LB +
    '}' + LB + LB +
    'function ' + GETSENDEVENT + '(' + PARAMSNAME + ', customdata = undefined){' + LB +
    '  var jsonObj = getDefaultEventDataObject();' + LB +
    '  if (' + PARAMSNAME + '["ID"]){' + LB +
    '    jsonObj["ID"] = ' + PARAMSNAME + '["ID"];' + LB +
    '  }' + LB +
    '  if (' + PARAMSNAME + '["X"]){' + LB +
    '    jsonObj["X"] = ' + PARAMSNAME + '["X"];' + LB +
    '  }' + LB +
    '  if (' + PARAMSNAME + '["Y"]){' + LB +
    '    jsonObj["Y"] = ' + PARAMSNAME + '["Y"];' + LB +
    '  }' + LB +
    '  if (' + PARAMSNAME + '["EventName"]){' + LB +
    '    jsonObj["EventName"] = ' + PARAMSNAME + '["EventName"];' + LB +
    '  }' + LB +
    '  if (' + PARAMSNAME + '["Coordinate"]){' + LB +
    '    jsonObj["Coordinate"]["Latitude"] = ' + PARAMSNAME + '["Coordinate"]["Latitude"];' + LB +
    '    jsonObj["Coordinate"]["Longitude"] = ' + PARAMSNAME + '["Coordinate"]["Longitude"];' + LB +
    '  }' + LB +
    '  var cd = undefined;' + LB +
    '  if (customdata) {' + LB +
    '    cd = encodeURIComponent(JSON.stringify(customdata));' + LB +
    '  }' + LB +
    '  sendObjectMessage(JSON.stringify(jsonObj), cd); ' + LB +
    '}' + LB + LB +
    'function toRGBA(c, o){' + LB +
    '  c = c.replace(''#'','''');' + LB +
    '  r = parseInt(c.substring(0,2), 16);' + LB +
    '  g = parseInt(c.substring(2,4), 16);' + LB +
    '  b = parseInt(c.substring(4,6), 16);' + LB +

    '  result = ''rgba(''+r+'',''+g+'',''+b+'',''+o+'')'';' + LB +
    '  return result;' + LB +
    '}' + LB + LB +
    'function getDefaultEventDataObject(){' + LB +
    '  return ' + o + LB +
    '}' + LB + LB +
    'function getDefaultCoordinateObject(){' + LB +
    '  return ' + c + LB +
    '}' + LB + LB +
    'function getDefaultPointObject(){' + LB +
    '  return ' + p + LB +
    '}' + LB + LB +
    'function getDefaultBoundsObject(){' + LB +
    '  return ' + b + LB +
    '}' + LB + LB +
    'function getDefaultCircleObject(){' + LB +
    '  return ' + ci + LB +
    '}' + LB + LB +
    'function calculateCoordinate(ACoordinate, ABearing, ADistance, AReverse) {' + LB +
    '  var a = 0.0;' + LB +
    '  var ad = 0.0;' + LB +
    '  var r = 0.0;' + LB +
    '  var a1 = 0.0;' + LB +
    '  var sina2 = 0.0;' + LB +
    '  var a2 = 0.0;' + LB +
    '  var y = 0.0;' + LB +
    '  var x = 0.0;' + LB +
    '  var d1 = 0.0;' + LB +
    '  var d2 = 0.0;' + LB +
    '  r = 6371000;' + LB +
    '  a = ADistance / r;' + LB +
    '  ad = ABearing * (Math.PI / 180);' + LB +
    '  var lat1 = ACoordinate["Latitude"];' + LB +
    '  var lng1 = ACoordinate["Longitude"];' + LB +
    '  a1 = lat1 * (Math.PI / 180);' + LB +
    '  d1 = lng1 * (Math.PI / 180);' + LB +
    '  sina2 = (Math.sin(a1) * Math.cos(a)) + (Math.cos(a1) * Math.sin(a) * Math.cos(ad));' + LB +
    '  a2 = Math.asin(sina2);' + LB +
    '  y = Math.sin(ad) * Math.sin(a) * Math.cos(a1);' + LB +
    '  x = Math.cos(a) - (Math.sin(a1) * sina2);' + LB +
    '  d2 = d1 + Math.atan2(y,x);' + LB +
    '  var lat = a2 * (180 / Math.PI);' + LB +
    '  var lon = d2 * (180 / Math.PI);' + LB +
    '  if (AReverse == true) {' + LB +
    '    return [lon, lat];' + LB +
    '  }else{' + LB +
    '    return [lat, lon];' + LB +
    '  }' + LB +
    '};' + LB + LB +
    'function createCircle(ACenter, ARadius, AReverse) {' + LB +
    '  var Result = [];' + LB +
    '  for (var I = 0; I <= 100; I++) {' + LB +
    '    Result.push(calculateCoordinate(ACenter, (I * -360) / 100, ARadius, AReverse));' + LB +
    '  };' + LB +
    '  return Result;' + LB +
    '};';
end;

{$IFDEF WEBLIB}
procedure TTMSFNCCustomMaps.ClearMethodPointers;
begin
  inherited;
  FMapEventData := nil;
end;

procedure TTMSFNCCustomMaps.GetMethodPointers;
begin
  inherited;
  if FMapEventData = nil then
    FMapEventData := @HandleDoMapEventData;
end;

function TTMSFNCCustomMaps.HandleDoMapEventData(Event: TJSEvent): Boolean;
var
  s: string;
begin
  asm
    s = Event.detail;
  end;
  ParseEvent(s);
  Result := True;
end;

procedure TTMSFNCCustomMaps.BindEvents;
var
  eh: TJSEventTarget;
begin
  if Assigned(ElementBindHandle) then
  begin
    GetMethodPointers;
    eh := ElementBindHandle;
    eh.addEventListener(MAPEVENTDATA, FMapEventData);
  end;
end;

procedure TTMSFNCCustomMaps.UnbindEvents;
var
  eh: TJSEventTarget;
begin
  if Assigned(ElementBindHandle) then
  begin
    eh := ElementBindHandle;
    eh.removeEventListener(MAPEVENTDATA, FMapEventData);
  end;
end;
{$ENDIF}

function TTMSFNCCustomMaps.GetCommunicationLayer: string;
  function GetWindowsComm: string;
  begin
    if IsDesignTime then
    begin
      Result := 'window.location = v;';
    end
    else
    begin
      Result :=
      '  if (!window.chrome || !window.chrome.webview || !window.chrome.webview.hostObjects || !window.chrome.webview.hostObjects.sync) {' + LB +
      '    return;' + LB +
      '  }' + LB +
      '  var obj = window.chrome.webview.hostObjects.sync.bridge;' + LB +
      '  if (obj) {' + LB +
      '    obj.ObjectMessage = v;' + LB +
      '  }else{' + #13 +
      '    window.location = v;' + LB +
      '  }';
    end;
  end;
begin
  Result :=
    'var sendObjectMessage = function(parameters, customdata = undefined) {' + LB +
    '  var v = "' + EVENTDATAPREFIX + '" + parameters;' + LB +
    '  if (customdata) {' + LB +
    '    v = v + "' + CUSTOMDATAPREFIX + '" + customdata;' + LB +
    '  }' + LB +
    {$IFDEF ANDROID}
    '  if (!injectedObject) {' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  injectedObject.Setjsvalue(v); ' + LB +
    '  injectedObject.performClick();' + LB +
    {$ENDIF}
    {$IFDEF LINUX}
    '  if (!window.webkit || !window.webkit.messageHandlers || !window.webkit.messageHandlers.bridge) {' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  window.webkit.messageHandlers.bridge.postMessage(v);' + LB +
    {$ENDIF}
    {$IFDEF MACOS}
    '  if (!window.webkit || !window.webkit.messageHandlers || !window.webkit.messageHandlers.bridge) {' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  window.webkit.messageHandlers.bridge.postMessage(v);' + LB +
    {$ENDIF}
    {$IFDEF MSWINDOWS}
    GetWindowsComm + LB +
    {$ENDIF}
    {$IFNDEF LINUX}
    {$IFNDEF MSWINDOWS}
    {$IFNDEF MACOS}
    {$IFNDEF ANDROID}
    {$IFDEF WEBLIB}
    '  var event = new CustomEvent("' + MAPEVENTDATA +'", {detail: v});' + LB +
    '  if (!' + MAPCOMPONENT + '){' + LB +
    '  ' + MAPCOMPONENT + ' = document.getElementById("' + ElementID + '");' + LB +
    '  }' + LB +
    '  ' + MAPCOMPONENT + '.dispatchEvent(event);' + LB +
    {$ELSE}
    '  window.location = v;' + LB +
    {$ENDIF}
    {$ENDIF}
    {$ENDIF}
    {$ENDIF}
    {$ENDIF}
    '};';
end;

function TTMSFNCCustomMaps.CoordinateToXY(
  ACoordinate: TTMSFNCMapsCoordinateRec): TTMSFNCMapsAnchorPointRec;
begin
  Result := LatLonToXY(ACoordinate);
end;

function TTMSFNCCustomMaps.GetLabelsClass: TTMSFNCMapsLabelsClass;
begin
  Result := TTMSFNCMapsLabels;
end;

procedure TTMSFNCCustomMaps.GetLinks(AList: TTMSFNCMapsLinksList; AIncludeContent: Boolean = True; ACheckReady: Boolean = True);
var
  acf, cf, s, a, m, cm, cmm, gv, am, em, apl,
  dl, c: string;
begin
  if ACheckReady and not MapReady then
    Exit;

  if AIncludeContent then
  begin
    s := s + GetGlobalVariables + LB + LB;

    gv := GetCustomGlobalVariables;
    if gv <> '' then
      s := s + gv + LB + LB;

    s := s + GetCommunicationLayer + LB + LB;

    cf := GetCustomFunctions;
    if cf <> '' then
      s := s + cf + LB + LB;

    s := s + GetShowPopupFunction + LB + LB;
    s := s + GetClosePopupFunction + LB + LB;

    s := s + GetAddOrUpdateMarkerFunction + LB + LB;
    s := s + GetDeleteMarkerFunction + LB + LB;

    s := s + GetAddOrUpdateElementContainerFunction + LB + LB;
    s := s + GetDeleteElementContainerFunction + LB + LB;

    s := s + GetAddOrUpdatePolyElementFunction + LB + LB;
    s := s + GetDeletePolyElementFunction + LB + LB;

    s := s + GetZoomToBoundsFunction + LB + LB;
    s := s + GetSetCenterCoordinateFunction + LB + LB;
    s := s + GetSetZoomLevelFunction + LB + LB;
    s := s + GetGetCenterCoordinateFunction + LB + LB;
    s := s + GetGetLatLonToXYFunction + LB + LB;
    s := s + GetGetXYToLatLonFunction + LB + LB;
    s := s + GetGetBoundsFunction + LB + LB;
    s := s + GetGetZoomLevelFunction + LB + LB;

    s := s + GetUpdateOptions + LB + LB;

    s := s + GetWaitForMapInitialized + LB + LB;

    //begin mapload
    s := s + 'function ' + LOADMAPFUNC + '(){' + LB;

    m := FMaps.GetInitializeMap;

    if m <> '' then
      s := s + m + LB + LB;

    a := FMaps.GetInitializeEvents;
    {$IFDEF WEBLIB}
    a := a + LB + LB + MAPID + '.addEventListener(''keydown'', MapKeyDown);' + LB;
    {$ENDIF}

    if a <> ''  then
      s := s + a + LB + LB;

    dl := FMaps.GetDelayLoadEvent;
    if dl <> '' then
      s := s + dl + LB + LB;

    cm := '';
    DoCustomizeMap(cm);
    if cm <> '' then
      s := s + cm + LB + LB;

    cmm := GetCustomMap;
    if cmm <> '' then
      s := s + cmm + LB + LB;

    am := GetAddMarkers;
    if am <> '' then
      s := s + am + LB;

    em := GetAddElementContainers;
    if em <> '' then
      s := s + em + LB;

    apl := GetAddPolyElements;
    if apl <> '' then
      s := s + apl + LB;

    acf := GetAddCustomObjects;
    if acf <> '' then
      s := s + acf + LB;

    s := s + GetCallInitialize + LB;

    if dl <> '' then
      s := s + '})' + LB;

    //end map load
    s := s + '}' + LB + LB;

    c := '';
    DoCustomizeJavaScript(c);

    if c <> '' then
      s := s + c + LB;
  end
  else
    s := 'Include';

  if s <> '' then
    AList.Add(TTMSFNCMapsLink.CreateScript('', 'text/javascript', '', s));
end;

function TTMSFNCCustomMaps.RouteCalculatorCheck: Boolean;
begin
  if (Assigned(RouteCalculator) and (RouteCalculator.Active)
    and (Service in [msGoogleMaps, msOpenLayers, msHere, msMapBox, msTomTom, msBingMaps])) then
      Result := True
  else
    Result := False;
end;

procedure TTMSFNCCustomMaps.DoCustomizeOptions(var ACustomizeOptions: string);
begin
  if Assigned(OnCustomizeOptions) then
    OnCustomizeOptions(Self, ACustomizeOptions);
end;

procedure TTMSFNCCustomMaps.DoCustomizeMarker(var ACustomizeMarker: string);
begin
  if Assigned(OnCustomizeMarker) then
    OnCustomizeMarker(Self, ACustomizeMarker);
end;

procedure TTMSFNCCustomMaps.DoCustomizePopup(var ACustomizePopup: string);
begin
  if Assigned(OnCustomizePopup) then
    OnCustomizePopup(Self, ACustomizePopup);
end;

procedure TTMSFNCCustomMaps.DoGetCenterCoordinate(const AValue: string);
var
  c: TTMSFNCMapsCoordinate;
  v: string;
begin
  if AValue = 'null' then
    Exit;

  v := TTMSFNCUtils.URLDecode(AValue);
  c := TTMSFNCMapsCoordinate.Create;
  try
    c.JSON := v;
    if Assigned(OnGetCenterCoordinate) then
      OnGetCenterCoordinate(Self, c.ToRec);
  finally
    c.Free;
  end;
end;

procedure TTMSFNCCustomMaps.DoGetBounds(const AValue: string);
var
  c: TTMSFNCMapsBounds;
  v: string;
begin
  if AValue = 'null' then
    Exit;

  v := TTMSFNCUtils.URLDecode(AValue);
  c := TTMSFNCMapsBounds.Create;
  try
    c.JSON := v;
    if Assigned(OnGetBounds) then
      OnGetBounds(Self, c.ToRec);
  finally
    c.Free;
  end;
end;

procedure TTMSFNCCustomMaps.DoGetDefaultHTMLMessage(var AMessage: string);
begin
  if Assigned(OnGetDefaultHTMLMessage) then
    OnGetDefaultHTMLMessage(Self, AMessage);
end;

procedure TTMSFNCCustomMaps.DoGetZoomLevel(const AValue: string);
var
  z: Double;
begin
  if AValue = 'null' then
    Exit;

  if TryStrToFloatDot(AValue, z) then
  begin
    if Assigned(OnGetZoomLevel) then
      OnGetZoomLevel(Self, z);
  end;
end;

procedure TTMSFNCCustomMaps.DoKeyPressed(var Key: Word);
var
  ASegment: TTMSFNCRouteCalculatorSegment;
  CanDelete: Boolean;
begin
  if Key = KEY_DELETE then
  begin
   if RouteCalculatorCheck then
    begin
      CanDelete := True;

      if Assigned(RouteCalculatorSelectedMarker) then
      begin
        if Assigned(RouteCalculatorSelectedMarker.DataPointer) and (TObject(RouteCalculatorSelectedMarker.DataPointer) is TTMSFNCRouteCalculatorSegment) then
        begin
          ASegment := (TObject(RouteCalculatorSelectedMarker.DataPointer) as TTMSFNCRouteCalculatorSegment);
          if Assigned(ASegment) then
          begin
            DoRouteCalculatorBeforeDeleteMarker(CanDelete, RouteCalculatorSelectedMarker, ASegment);
            if CanDelete then
            begin
              RouteCalculatorDeleteMarker(RouteCalculatorSelectedMarker);
              DoRouteCalculatorAfterDeleteMarker;
            end;
          end;
          RouteCalculatorClearSelected;
        end;
      end
      else if Assigned(RouteCalculatorSelectedPolyline) then
      begin
        if Assigned(RouteCalculatorSelectedPolyline.DataPointer) and (TObject(RouteCalculatorSelectedPolyline.DataPointer) is TTMSFNCRouteCalculatorSegment) then
        begin
          ASegment := (TObject(RouteCalculatorSelectedPolyline.DataPointer) as TTMSFNCRouteCalculatorSegment);
          if Assigned(ASegment) then
          begin
            DoRouteCalculatorBeforeDeletePolyline(CanDelete, RouteCalculatorSelectedPolyline, ASegment);
            if CanDelete then
            begin
              RouteCalculatorDeletePolyline(RouteCalculatorSelectedPolyline);
              DoRouteCalculatorAfterDeletePolyline;
            end;
          end;
          RouteCalculatorClearSelected;
        end;
      end;
    end;
  end;
end;

function TTMSFNCCustomMaps.GetAddCustomObjects: string;
begin
  Result := '';
end;

function TTMSFNCCustomMaps.GetAddElementContainers: string;
var
  I: Integer;
  arr: TTMSFNCObjectExcludePropertyListArray;
  c: TTMSFNCMapsElementContainers;
begin
  Result := '';
  SetLength(arr, 3);
  arr[0] := 'HTML';
  arr[1] := 'Script';
  arr[2] := 'Style';

  c := TTMSFNCMapsElementContainers.Create(nil);
  try
    c.Assign(ElementContainers);

    SyncLabels(c);

    for I := 0 to c.Count - 1 do
    begin
      Result := Result + '  ' + ADDORUPDATEELEMENTCONTAINERFUNCTION + '(' + c[I].ToJSON(arr) + ');' + LB;
    end;
  finally
    c.Free;
  end;
end;

function TTMSFNCCustomMaps.GetCustomOptions: string;
begin
  Result := '';
end;

function TTMSFNCCustomMaps.GetCustomMap: string;
begin
  Result := '';
end;

function TTMSFNCCustomMaps.GetCustomGlobalVariables: string;
begin
  Result := '';
end;

function TTMSFNCCustomMaps.GetCustomFunctions: string;
begin
  Result := '';
end;

function TTMSFNCCustomMaps.GetOptionsClass: TTMSFNCMapsOptionsClass;
begin
  Result := TTMSFNCMapsOptions;
end;

//procedure TTMSFNCCustomMaps.DoMapIdle(AEventData: TTMSFNCMapsEventData);
//begin
//  if Assigned(OnMapIdle) then
//    OnMapIdle(Self, AEventData);
//end;

procedure TTMSFNCCustomMaps.DoMapInitialized;
begin
  if Assigned(OnMapInitialized) then
    OnMapInitialized(Self);
end;

procedure TTMSFNCCustomMaps.DoMapKeyDown(AEventData: TTMSFNCMapsEventData);
var
  key: Integer;
  k: Word;
begin
  key := 0;
  if TryStrToInt(AEventData.CustomData, key) then
  begin
    k := key;
    DoKeyPressed(k);
  end;
end;

procedure TTMSFNCCustomMaps.DoZoomChanged(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnZoomChanged) then
    OnZoomChanged(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMapTypeChanged(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnMapTypeChanged) then
    OnMapTypeChanged(Self, AEventData);
end;

function TTMSFNCCustomMaps.GetRouteCalculatorSelectedWayPointMarker: string;
begin
  if RouteCalculatorCheck then
  begin
    if RouteCalculator.Options.Polyline.AddWayPointMarker = '' then
      Result := DEFAULTSELECTEDWAYPOINTMARKER
    else
      Result := RouteCalculator.Options.Polyline.SelectedWayPointMarker;
  end
  else
    Result := '';
end;

function TTMSFNCCustomMaps.GetRouteCalculatorStartMarker: string;
begin
  if RouteCalculatorCheck then
  begin
    if RouteCalculator.Options.Polyline.StartMarker = '' then
      Result := DEFAULTSTARTMARKER
    else
      Result := RouteCalculator.Options.Polyline.StartMarker;
  end
  else
    Result := '';
end;

function TTMSFNCCustomMaps.GetRouteCalculatorEndMarker: string;
begin
  if RouteCalculatorCheck then
  begin
    if RouteCalculator.Options.Polyline.EndMarker = '' then
      Result := DEFAULTENDMARKER
    else
      Result := RouteCalculator.Options.Polyline.EndMarker;
  end
  else
    Result := '';
end;

function TTMSFNCCustomMaps.GetRouteCalculatorWayPointMarker: string;
begin
  if RouteCalculatorCheck then
  begin
    if RouteCalculator.Options.Polyline.WayPointMarker = '' then
      Result := DEFAULTWAYPOINTMARKER
    else
      Result := RouteCalculator.Options.Polyline.WayPointMarker;
  end
  else
    Result := '';
end;

function TTMSFNCCustomMaps.GetRouteCalculatorAddWayPointMarker: string;
begin
  if RouteCalculatorCheck then
  begin
    if RouteCalculator.Options.Polyline.AddWayPointMarker = '' then
      Result := DEFAULTADDWAYPOINTMARKER
    else
      Result := RouteCalculator.Options.Polyline.AddWayPointMarker;
  end
  else
    Result := '';
end;

procedure TTMSFNCCustomMaps.DoMapClick(AEventData: TTMSFNCMapsEventData);

  function GetRouteStartMarker: TTMSFNCMapsMarker;
  var
    I: Integer;
  begin
    Result := nil;
    for I := 0 to Markers.Count - 1 do
    begin
      if Markers[I].DataString = ROUTECALCULATORSTARTMARKER then
      begin
        Result := Markers[I];
        Exit;
      end;
    end;
  end;

  function GetRouteMarkerCount: Integer;
  var
    I: Integer;
  begin
    Result := 0;
    for I := 0 to Markers.Count - 1 do
    begin
      if ((Markers[I].DataString = ROUTECALCULATORMARKER)
        or (Markers[I].DataString = ROUTECALCULATORSTARTMARKER)
        or (Markers[I].DataString = ROUTECALCULATORENDMARKER)) then
        Result := Result + 1;
    end;
  end;

  function GetRoute: TTMSFNCRouteCalculatorRoute;
  var
    I: Integer;
  begin
    Result := nil;
    for I := 0 to RouteCalculator.Routes.Count - 1 do
    begin
      if RouteCalculator.Routes[I].DataString = ROUTECALCULATORROUTE then
      begin
        Result := RouteCalculator.Routes[I];
        Exit;
      end;
    end;
  end;

var
  m: TTMSFNCMapsMarker;
  r: TTMSFNCRouteCalculatorRoute;
begin
  if RouteCalculatorCheck then
  begin
    RouteCalculatorClearSelected;

    m := GetRouteStartMarker;
    if not Assigned(m) then
    begin
      m := AddMarker(AEventData.Coordinate.ToRec);
      m.IconURL := GetRouteCalculatorStartMarker;
      m.DataString := ROUTECALCULATORSTARTMARKER;
    end
    else if GetRouteMarkerCount = 1 then
    begin
      RouteCalculator.CalculateRoute(m.Coordinate.ToRec, AEventData.Coordinate.ToRec);
    end
    else
    begin
      r := GetRoute;
      if Assigned(r) then
        RouteCalculator.AddRouteSegment(r, AEventData.Coordinate.ToRec);
    end;
  end;

  if Assigned(OnMapClick) then
    OnMapClick(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMapDblClick(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnMapDblClick) then
    OnMapDblClick(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMapMouseUp(AEventData: TTMSFNCMapsEventData);
begin
  if RouteCalculatorCheck then
  begin
    RouteCalculatorMarkerMoved(AEventData.Coordinate.ToRec);
    RouteCalculatorPolylineMoved(AEventData.Coordinate.ToRec);
  end;

  if Assigned(OnMapMouseUp) then
    OnMapMouseUp(Self, AEventData);
end;

//procedure TTMSFNCCustomMaps.DoMapMove(AEventData: TTMSFNCMapsEventData);
//begin
//  if Assigned(OnMapMove) then
//    OnMapMove(Self, AEventData);
//end;

procedure TTMSFNCCustomMaps.DoMapMoveEnd(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnMapMoveEnd) then
    OnMapMoveEnd(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMapMoveStart(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnMapMoveStart) then
    OnMapMoveStart(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMapRightClick(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnMapRightClick) then
    OnMapRightClick(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMapMouseDown(AEventData: TTMSFNCMapsEventData);
begin
  ExecuteJavaScript(GETFOCUSMAP + '()');

  if RouteCalculatorCheck then
    RouteCalculatorClearSelected;

  if Assigned(OnMapMouseDown) then
    OnMapMouseDown(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMapMouseMove(AEventData: TTMSFNCMapsEventData);
begin
  if RouteCalculatorCheck then
  begin
    if (AEventData.X <> FRouteCalculatorDragX) or (AEventData.Y <> FRouteCalculatorDragY) or
      (AEventData.Coordinate.Latitude <> FRouteCalculatorDragLat) or (AEventData.Coordinate.Longitude <> FRouteCalculatorDragLon) then
    begin
      FRouteCalculatorDragX := AEventData.X;
      FRouteCalculatorDragY := AEventData.Y;
      FRouteCalculatorDragLat := AEventData.Coordinate.Latitude;
      FRouteCalculatorDragLon := AEventData.Coordinate.Longitude;

      if Assigned(FRouteCalculatorPolyline) then
      begin
        Options.Panning := False;
        FRouteCalculatorDragStarted := True;
        if Assigned(FRouteCalculatorDragMarker) then
        begin
          BeginUpdate;
          FRouteCalculatorDragMarker.Coordinate.Latitude := AEventData.Coordinate.Latitude;
          FRouteCalculatorDragMarker.Coordinate.Longitude := AEventData.Coordinate.Longitude;
          EndUpdate;
        end
        else
        begin
          BeginUpdate;
          FRouteCalculatorDragMarker := AddMarker(AEventData.Coordinate.ToRec);
          FRouteCalculatorDragMarker.IconURL := GetRouteCalculatorAddWayPointMarker;
          FRouteCalculatorDragMarker.DataString := ROUTECALCULATORDRAGMARKER;
          EndUpdate;
        end;
      end;

      if Assigned(FRouteCalculatorMarker) then
      begin
        Options.Panning := False;
        FRouteCalculatorDragStarted := True;
        BeginUpdate;
        FRouteCalculatorMarker.Coordinate.Latitude := AEventData.Coordinate.Latitude;
        FRouteCalculatorMarker.Coordinate.Longitude := AEventData.Coordinate.Longitude;
        EndUpdate;
      end;
    end;
  end;

  if Assigned(OnMapMouseMove) then
    OnMapMouseMove(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMapMouseEnter(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnMapMouseEnter) then
    OnMapMouseEnter(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMapMouseLeave(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnMapMouseLeave) then
    OnMapMouseLeave(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMarkerRightClick(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnMarkerRightClick) then
    OnMarkerRightClick(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMarkerClick(AEventData: TTMSFNCMapsEventData);
begin
  if RouteCalculatorCheck then
  begin
    RouteCalculatorClearSelected;
    if Assigned(AEventData.Marker) then
    begin
      if (AEventData.Marker.DataString = ROUTECALCULATORMARKER)
        or (AEventData.Marker.DataString = ROUTECALCULATORSTARTMARKER)
        or (AEventData.Marker.DataString = ROUTECALCULATORENDMARKER) then
      begin
        FRouteCalculatorSelectedMarker := AEventData.Marker;
        FRouteCalculatorSelectedMarker.IconURL := GetRouteCalculatorSelectedWayPointMarker;
        FRouteCalculatorSelectedMarker.Recreate := True;
      end;
    end;
  end;

  if Assigned(OnMarkerClick) then
    OnMarkerClick(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMarkerDblClick(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnMarkerDblClick) then
    OnMarkerDblClick(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMarkerMouseUp(AEventData: TTMSFNCMapsEventData);
begin
  if RouteCalculatorCheck then
  begin
    RouteCalculatorMarkerMoved(AEventData.Coordinate.ToRec);
    RouteCalculatorPolylineMoved(AEventData.Coordinate.ToRec);
  end;

  if Assigned(OnMarkerMouseUp) then
    OnMarkerMouseUp(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMarkerMouseDown(AEventData: TTMSFNCMapsEventData);
begin
  if RouteCalculatorCheck then
  begin
    RouteCalculatorClearSelected;
    if Assigned(AEventData.Marker) then
    begin
      Options.Panning := False;
      FRouteCalculatorMarker := AEventData.Marker;
      FRouteCalculatorPolyline := nil;
      FRouteCalculatorDragStarted := False;
    end;
  end;

  if Assigned(OnMarkerMouseDown) then
    OnMarkerMouseDown(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMarkerMouseEnter(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnMarkerMouseEnter) then
    OnMarkerMouseEnter(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoMarkerMouseLeave(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnMarkerMouseLeave) then
    OnMarkerMouseLeave(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoPolyElementRightClick(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnPolyElementRightClick) then
    OnPolyElementRightClick(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoPolyElementClick(AEventData: TTMSFNCMapsEventData);
begin
  if RouteCalculatorCheck then
  begin
    if AEventData.PolyElement.DataString = ROUTECALCULATORSEGMENT  then
    begin
      RouteCalculatorClearSelected;
      if Assigned(AEventData.PolyElement) then
      begin
        FRouteCalculatorSelectedPolyline := TTMSFNCMapsPolyline(AEventData.PolyElement);
        FRouteCalculatorSelectedPolyline.StrokeColor := FRouteCalculator.Options.Polyline.SelectedStrokeColor;
        FRouteCalculatorSelectedPolyline.Recreate := True;
      end;
    end;
  end;

  if Assigned(OnPolyElementClick) then
    OnPolyElementClick(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoPolyElementDblClick(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnPolyElementDblClick) then
    OnPolyElementDblClick(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoPolyElementMouseUp(AEventData: TTMSFNCMapsEventData);
begin
  if RouteCalculatorCheck then
  begin
    RouteCalculatorMarkerMoved(AEventData.Coordinate.ToRec);
    RouteCalculatorPolylineMoved(AEventData.Coordinate.ToRec);
  end;

  if Assigned(OnPolyElementMouseUp) then
    OnPolyElementMouseUp(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoRouteCalculatorAfterDeleteMarker;
begin
  if Assigned(OnRouteCalculatorAfterDeleteMarker) then
    OnRouteCalculatorAfterDeleteMarker(Self);
end;

procedure TTMSFNCCustomMaps.DoRouteCalculatorAfterDeletePolyline;
begin
  if Assigned(OnRouteCalculatorAfterDeletePolyline) then
    OnRouteCalculatorAfterDeletePolyline(Self);
end;

procedure TTMSFNCCustomMaps.DoRouteCalculatorBeforeDeleteMarker(
  var ACanDelete: Boolean; AMarker: TTMSFNCMapsMarker;
  ASegment: TTMSFNCRouteCalculatorSegment);
begin
  if Assigned(OnRouteCalculatorBeforeDeleteMarker) then
    OnRouteCalculatorBeforeDeleteMarker(Self, ACanDelete, AMarker, ASegment);
end;

procedure TTMSFNCCustomMaps.DoRouteCalculatorBeforeDeletePolyline(
  var ACanDelete: Boolean; APolyline: TTMSFNCMapsPolyline; ASegment: TTMSFNCRouteCalculatorSegment);
begin
  if Assigned(OnRouteCalculatorBeforeDeletePolyline) then
    OnRouteCalculatorBeforeDeletePolyline(Self, ACanDelete, APolyline, ASegment);
end;

procedure TTMSFNCCustomMaps.DoRouteCalculatorPolylineAdded(
  APolyline: TTMSFNCMapsPolyline; ASegment: TTMSFNCRouteCalculatorSegment);
begin
  if Assigned(OnRouteCalculatorPolylineAdded) then
    OnRouteCalculatorPolylineAdded(Self, APolyline, ASegment);
end;

procedure TTMSFNCCustomMaps.DoRouteCalculatorPolylineUpdated(
  APolyline: TTMSFNCMapsPolyline; ASegment: TTMSFNCRouteCalculatorSegment);
begin
  if Assigned(OnRouteCalculatorPolylineUpdated) then
    OnRouteCalculatorPolylineUpdated(Self, APolyline, ASegment);
end;

procedure TTMSFNCCustomMaps.DoRouteCalculatorWayPointAdded(
  AMarker: TTMSFNCMapsMarker; ASegment: TTMSFNCRouteCalculatorSegment);
begin
  if Assigned(OnRouteCalculatorWayPointAdded) then
    OnRouteCalculatorWayPointAdded(Self, AMarker, ASegment);
end;

procedure TTMSFNCCustomMaps.DoRouteCalculatorWayPointUpdated(
  AMarker: TTMSFNCMapsMarker; ASegment: TTMSFNCRouteCalculatorSegment);
begin
  if Assigned(OnRouteCalculatorWayPointUpdated) then
    OnRouteCalculatorWayPointUpdated(Self, AMarker, ASegment);
end;

procedure TTMSFNCCustomMaps.DoPolyElementMouseDown(AEventData: TTMSFNCMapsEventData);
begin
  if RouteCalculatorCheck then
  begin
    RouteCalculatorClearSelected;
    if Assigned(AEventData.PolyElement) then
    begin
      Options.Panning := False;
      FRouteCalculatorPolyline := AEventData.PolyElement;
      FRouteCalculatorMarker := nil;
      FRouteCalculatorDragStarted := False;
    end;
  end;

  if Assigned(OnPolyElementMouseDown) then
    OnPolyElementMouseDown(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoPolyElementMouseEnter(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnPolyElementMouseEnter) then
    OnPolyElementMouseEnter(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoPolyElementMouseLeave(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnPolyElementMouseLeave) then
    OnPolyElementMouseLeave(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.CallCustomEvent(AEventData: TTMSFNCMapsEventData);
var
  I, J: Integer;
  ActionEventFound: Boolean;
begin
  ActionEventFound := False;

  for I := 0 to ElementContainers.Count - 1 do
  begin
    for J := 0 to ElementContainers[I].Actions.Count - 1 do
    begin
      if Assigned(ElementContainers[I].Actions[J].OnExecute) then
      begin
        if ElementContainers[I].Actions[J].HTMLElementID = AEventData.ID then
        begin
          if ((ElementContainers[I].Actions[J].Event = heClick) and (AEventData.EventName = ACTIONCLICKEVENT))
              or ((ElementContainers[I].Actions[J].Event = heDblClick) and (AEventData.EventName = ACTIONDBLCLICKEVENT))
              or ((ElementContainers[I].Actions[J].Event = heKeyPress) and (AEventData.EventName = ACTIONKEYPRESSEVENT))
              or ((ElementContainers[I].Actions[J].Event = heKeyDown) and (AEventData.EventName = ACTIONKEYDOWNEVENT))
              or ((ElementContainers[I].Actions[J].Event = heKeyUp) and (AEventData.EventName = ACTIONKEYUPEVENT))
              or ((ElementContainers[I].Actions[J].Event = heMouseDown) and (AEventData.EventName = ACTIONMOUSEDOWNEVENT))
              or ((ElementContainers[I].Actions[J].Event = heMouseMove) and (AEventData.EventName = ACTIONMOUSEMOVEEVENT))
              or ((ElementContainers[I].Actions[J].Event = heMouseUp) and (AEventData.EventName = ACTIONMOUSEUPEVENT))
              or ((ElementContainers[I].Actions[J].Event = heMouseEnter) and (AEventData.EventName = ACTIONMOUSEENTEREVENT))
              or ((ElementContainers[I].Actions[J].Event = heMouseLeave) and (AEventData.EventName = ACTIONMOUSELEAVEEVENT))
              or ((ElementContainers[I].Actions[J].Event = heBlur) and (AEventData.EventName = ACTIONBLUREVENT))
              or ((ElementContainers[I].Actions[J].Event = heFocus) and (AEventData.EventName = ACTIONFOCUSEVENT))
              or ((ElementContainers[I].Actions[J].Event = heChange) and (AEventData.EventName = ACTIONCHANGEEVENT))
              or ((ElementContainers[I].Actions[J].Event = heSelect) and (AEventData.EventName = ACTIONSELECTEVENT))
              or ((ElementContainers[I].Actions[J].Event = heCustom) and (AEventData.EventName = ACTIONCUSTOMEVENT))
          then
          begin
            ActionEventFound := True;
            ElementContainers[I].Actions[J].OnExecute(Self, AEventData);
          end;
        end;
      end;
    end;
  end;

  if not ActionEventFound then
    DoCustomEvent(AEventData);
end;

procedure TTMSFNCCustomMaps.CheckInstances;
{$IFDEF WEBLIB}
var
  I, Instances: Integer;
{$ENDIF}
begin
  {$IFDEF WEBLIB}
  Instances := 0;
  for I := 0 to Owner.ComponentCount - 1 do
  begin
    if (Owner.Components[I] is TTMSFNCCustomMaps) then
    begin
      if (Owner.Components[I] as TTMSFNCCustomMaps).Service = Service then
        Inc(Instances);
    end;
  end;

  if (Instances > 1) then
    raise Exception.Create('Only one instance of ' + ClassName + ' with service ' + TTMSFNCPersistence.GetEnumName(TypeInfo(TTMSFNCMapsService), Integer(Service)) +' allowed!');
  {$ENDIF}
end;

procedure TTMSFNCCustomMaps.DoCloseAllPopups(const AValue: string);
var
  I: Integer;
  sl: TStringList;
  ma: string;
begin
  ma := ParseScript(AValue);
  sl := TStringList.Create;
  try
    TTMSFNCUtils.Split(',', ma, sl);
    for I := 0 to sl.Count - 1 do
      ClosePopup(sl[I]);
  finally
    sl.Free;
  end;
end;

procedure TTMSFNCCustomMaps.DoCreateGeoJSONObject(
  AEventData: TTMSFNCMapsGeoJSONEventData);
begin
  if Assigned(OnCreateGeoJSONObject) then
    OnCreateGeoJSONObject(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoCreateGPXSegment(
  AEventData: TTMSFNCMapsGPXSegmentEventData);
begin
  if Assigned(OnCreateGPXSegment) then
    OnCreateGPXSegment(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoCreateGPXTrack(
  AEventData: TTMSFNCMapsGPXTrackEventData);
begin
  if Assigned(OnCreateGPXTrack) then
    OnCreateGPXTrack(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoCustomEvent(AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnCustomEvent) then
    OnCustomEvent(Self, AEventData);
end;

procedure TTMSFNCCustomMaps.DoCustomizeMap(var ACustomizeMap: string);
begin
  if Assigned(OnCustomizeMap) then
    OnCustomizeMap(Self, ACustomizeMap);
end;

procedure TTMSFNCCustomMaps.DoCustomizeCSS(var ACustomizeCSS: string);
begin
  if Assigned(OnCustomizeCSS) then
    OnCustomizeCSS(Self, ACustomizeCSS);
end;

procedure TTMSFNCCustomMaps.DoCustomizeGlobalVariables(var ACustomizeGlobalVariables: string);
begin
  if Assigned(OnCustomizeGlobalVariables) then
    OnCustomizeGlobalVariables(Self, ACustomizeGlobalVariables);
end;

procedure TTMSFNCCustomMaps.DoCustomizeHeadLinks(AList: TTMSFNCMapsLinksList);
begin
  if Assigned(OnCustomizeHeadLinks) then
    OnCustomizeHeadLinks(Self, AList);
end;

procedure TTMSFNCCustomMaps.DoCustomizePolyElement(var ACustomizePolyElement: string);
begin
  if Assigned(OnCustomizePolyElement) then
    OnCustomizePolyElement(Self, ACustomizePolyElement);
end;

procedure TTMSFNCCustomMaps.DoCustomizeJavaScript(var ACustomizeJavaScript: string);
begin
  if Assigned(OnCustomizeJavaScript) then
    OnCustomizeJavaScript(Self, ACustomizeJavaScript);
end;

procedure TTMSFNCCustomMaps.DoCustomizeLocalAccessFileName(
  var AFileName: string);
begin
  if Assigned(OnCustomizeLocalAccessFileName) then
    OnCustomizeLocalAccessFileName(Self, AFileName);
end;

procedure TTMSFNCCustomMaps.BeforeNavigate(var Params: TTMSFNCCustomWebBrowserBeforeNavigateParams);
begin
  Params.Cancel := ParseEvent(Params.URL);
  inherited;
end;

procedure TTMSFNCCustomMaps.DoUpdatePolyElements(const AValue: string);
var
  I, J, K: Integer;
  sl: TStringList;
  ma: string;
  arr: TTMSFNCObjectExcludePropertyListArray;
  s: string;
begin
  if not MapInitialized or IsDestroying or (FUpdateCount > 0) then
    Exit;

  SetLength(arr, 1);
  arr[0] := 'Coordinates';

  ma := ParseScript(AValue);

  sl := TStringList.Create;
  try
    TTMSFNCUtils.Split(',', ma, sl);

    s := 'function updateAllPolygons(){' + LB;
    for I := 0 to Polygons.Count - 1 do
    begin
      j := sl.IndexOf(Polygons[I].ID);
      if (j = -1) or (Polygons[I].FReload) then
      begin
        Polygons[I].FReload := False;
        s := s + INITIALIZECOORDINATEARRAY + '();' + LB;
        s := s + ADDCOORDINATESTOARRAY + '(' + Polygons[I].Coordinates.ToJSON + ');' + LB;
        s := s + INITIALIZEHOLESARRAY + '();' + LB;
        for K := 0 to Polygons[I].Holes.Count - 1 do
          s := s + ADDHOLESTOARRAY + '(["' + Polygons[I].Holes[K].ID + '",' + Polygons[I].Holes[K].Coordinates.ToJSON + ']);' + LB;
        s := s + ADDORUPDATEPOLYELEMENTFUNCTION + '(' + Polygons[I].ToJSON(arr) + ');' + LB;
        s := s + INITIALIZECOORDINATEARRAY + '();' + LB;
      end;

      if j <> -1 then
        sl.Delete(j)
    end;

    s := s + '}updateAllPolygons();';
    ExecuteJavaScript(s, nil, True);

    s := 'function updateAllRectangles(){' + LB;

    for I := 0 to Rectangles.Count - 1 do
    begin
      j := sl.IndexOf(Rectangles[I].ID);
      if (j = -1) or (Rectangles[I].FReload) then
      begin
        Rectangles[I].FReload := False;
        s := s + INITIALIZECOORDINATEARRAY + '();' + LB;
        s := s + ADDCOORDINATESTOARRAY + '(' + Rectangles[I].Coordinates.ToJSON + ');' + LB;
        s := s + ADDORUPDATEPOLYELEMENTFUNCTION + '(' + Rectangles[I].ToJSON(arr) + ');' + LB;
        s := s + INITIALIZECOORDINATEARRAY + '();' + LB;
      end;

      if j <> -1 then
        sl.Delete(j)
    end;

    s := s + '}updateAllRectangles();';
    ExecuteJavaScript(s, nil, True);

    s := 'function updateAllCircles(){' + LB;

    for I := 0 to Circles.Count - 1 do
    begin
      j := sl.IndexOf(Circles[I].ID);
      if (j = -1) or (Circles[I].FReload) then
      begin
        Circles[I].FReload := False;
        s := s + INITIALIZECOORDINATEARRAY + '();' + LB;
        s := s + ADDCOORDINATESTOARRAY + '(' + Circles[I].Coordinates.ToJSON + ');' + LB;
        s := s + ADDORUPDATEPOLYELEMENTFUNCTION + '(' + Circles[I].ToJSON(arr) + ');' + LB;
        s := s + INITIALIZECOORDINATEARRAY + '();' + LB;
      end;

      if j <> -1 then
        sl.Delete(j)
    end;

    s := s + '}updateAllCircles();';
    ExecuteJavaScript(s, nil, True);

    s := 'function updateAllPolylines(){' + LB;

    for I := 0 to Polylines.Count - 1 do
    begin
      j := sl.IndexOf(Polylines[I].ID);
      if (j = -1) or (Polylines[I].FReload) then
      begin
        PolyLines[I].FReload := False;
        s := s + INITIALIZECOORDINATEARRAY + '();' + LB;
        s := s + ADDCOORDINATESTOARRAY + '(' + Polylines[I].Coordinates.ToJSON + ');' + LB;
        s := s + ADDORUPDATEPOLYELEMENTFUNCTION + '(' + Polylines[I].ToJSON(arr) + ');' + LB;
        s := s + INITIALIZECOORDINATEARRAY + '();' + LB;
      end;

      if j <> -1 then
        sl.Delete(j)
    end;

    s := s + '}updateAllPolylines();';
    ExecuteJavaScript(s, nil, True);

    s := 'function deleteAllPolyElements(){' + LB;
    for I := 0 to sl.Count - 1 do
      s := s + DELETEPOLYELEMENTFUNCTION + '({"ID": "' + sl[I] + '"});' + LB;

    s := s + '}deleteAllPolyElements();';
    ExecuteJavaScript(s, nil, True);

  finally
    sl.Free;
  end;
end;

procedure TTMSFNCCustomMaps.UpdatePolyElements;
begin
  if not MapInitialized or IsDestroying or (FUpdateCount > 0) then
    Exit;

  ExecuteJavaScript(GETPOLYELEMENTARRAYVAR, {$IFDEF LCLWEBLIB}@{$ENDIF}DoUpdatePolyElements, True);
end;

procedure TTMSFNCCustomMaps.UpdateControlAfterResize;
begin
  inherited;
  {$IFDEF WEBLIB}
  if not MapInitialized or IsDestroying or (FUpdateCount > 0) then
    Exit;

  if Service = msOpenLayers then
    ExecuteJavaScript(MAPVAR + '.updateSize()', {$IFDEF LCLWEBLIB}@{$ENDIF}DoUpdateMarkers);
  {$ENDIF}
end;

procedure TTMSFNCCustomMaps.UpdateElementContainers;
begin
  if not MapInitialized or IsDestroying or (FUpdateCount > 0) then
    Exit;

  ExecuteJavaScript(GETELEMENTCONTAINERARRAYVAR, {$IFDEF LCLWEBLIB}@{$ENDIF}DoUpdateElementContainers, True);
end;

procedure TTMSFNCCustomMaps.UpdateMarkers;
begin
  if not MapInitialized or IsDestroying or (FUpdateCount > 0) then
    Exit;

  ExecuteJavaScript(GETMARKERARRAYVAR, {$IFDEF LCLWEBLIB}@{$ENDIF}DoUpdateMarkers, True);
end;

procedure TTMSFNCCustomMaps.DoUpdateElementContainers(const AValue: string);
var
  I, j: Integer;
  ma: string;
  sl: TStringList;
  s: string;
  c: TTMSFNCMapsElementContainers;
begin
  if not MapInitialized or IsDestroying or (FUpdateCount > 0) then
    Exit;

  ma := ParseScript(AValue);

  sl := TStringList.Create;
  c := TTMSFNCMapsElementContainers.Create(nil);
  c.Assign(ElementContainers);
  SyncLabels(c);

  try
    TTMSFNCUtils.Split(',', ma, sl);

    s := 'function updateAllElementContainers(){' + LB;

    for I := 0 to c.Count - 1 do
    begin
      j := sl.IndexOf(c[I].ID);
      if (j = -1) or (c[I].FReload) then
      begin
        c[I].FReload := False;
        s := s + ADDORUPDATEELEMENTCONTAINERFUNCTION + '(' + c[I].ToJSON + ');' + LB;
      end;

      if j <> -1 then
        sl.Delete(j);
    end;

    s := s + '}updateAllElementContainers();';
    ExecuteJavaScript(s, nil, True);

    s := 'function deleteAllElementContainers(){' + LB;

    for I := 0 to sl.Count - 1 do
      s := s + DELETEELEMENTCONTAINERFUNCTION + '({"ID": "' + sl[I] + '"});' + LB;

    s := s + '}deleteAllElementContainers();';
    ExecuteJavaScript(s, nil, True);

  finally
    c.Free;
    sl.Free;
  end;
end;

procedure TTMSFNCCustomMaps.DoUpdateMarkers(const AValue: string);
var
  I, j: Integer;
  ma: string;
  sl: TStringList;
  s: string;
begin
  if not MapInitialized or IsDestroying or (FUpdateCount > 0) then
    Exit;

  ma := ParseScript(AValue);

  sl := TStringList.Create;
  try
    TTMSFNCUtils.Split(',', ma, sl);

    s := 'function updateAllMarkers(){' + LB;

    for I := 0 to Markers.Count - 1 do
    begin
      j := sl.IndexOf(Markers[I].ID);
      if (j = -1) or (Markers[I].FReload) then
      begin
        Markers[I].FReload := False;
        s := s + ADDORUPDATEMARKERFUNCTION + '(' + Markers[I].ToJSON + ');' + LB;
      end;

      if j <> -1 then
        sl.Delete(j);
    end;

    s := s + '}updateAllMarkers();';
    ExecuteJavaScript(s, nil, True);

    s := 'function deleteAllMarkers(){' + LB;

    for I := 0 to sl.Count - 1 do
      s := s + DELETEMARKERFUNCTION + '({"ID": "' + sl[I] + '"});' + LB;

    s := s + '}deleteAllMarkers();';
    ExecuteJavaScript(s, nil, True);

  finally
    sl.Free;
  end;
end;

procedure TTMSFNCCustomMaps.UpdateOptions;
begin
  if not MapInitialized or IsDestroying or (FUpdateCount > 0) then
    Exit;

  ExecuteJavaScript(UPDATEOPTIONSFUNCTION + '(' + Options.ToJSON + ')');
end;

function TTMSFNCCustomMaps.MapReady: Boolean;
begin
  Result := Assigned(FMaps) and Assigned(FMapsInstance) and Assigned(FMapsProperties) and FMaps.IsValid;
end;

function TTMSFNCCustomMaps.Maps: ITMSFNCCustomMaps;
begin
  Result := FMaps;
end;

procedure TTMSFNCCustomMaps.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FRouteCalculator) then
    FRouteCalculator := nil;
end;

procedure TTMSFNCCustomMaps.OptionsChanged(Sender: TObject);
begin
  InitializeHTML;
  UpdateOptions;
end;

function TTMSFNCCustomMaps.ParseLinks(AList: TTMSFNCMapsLinksList): string;
var
  I: Integer;
  lk: TTMSFNCMapsLink;
begin
  Result := '';
  for I := 0 to AList.Count - 1 do
  begin
    lk := AList[I];
    case lk.Kind of
      mlkLink: Result := Result + '<link';
      mlkScript: Result := Result + '<script';
      mlkStyle: Result := Result + '<style';
    end;

    if lk.URL <> '' then
    begin
      case lk.Kind of
        mlkLink: Result := Result + ' href="';
        mlkScript: Result := Result + ' src="';
      end;

      Result := Result + lk.URL + '"';
    end;

    if lk.&Type <> '' then
      Result := Result + ' type="' + lk.&Type + '"';

    if lk.CharSet <> '' then
      Result := Result + ' charset="' + lk.CharSet + '"';

    if lk.Rel <> '' then
      Result := Result + ' rel="' + lk.Rel +'"';

    if lk.Async then
      Result := Result + ' async';

    if lk.Defer then
      Result := Result + ' defer';

    Result := Result + '>';

    if lk.Content <> '' then
      Result := Result + LB + lk.Content;

    case lk.Kind of
      mlkLink: Result := Result + LB + '</link>';
      mlkScript: Result := Result + LB + '</script>';
      mlkStyle: Result := Result + LB + '</style>';
    end;

    if (I < AList.Count - 1) then
      Result := Result + LB;
  end;
end;

procedure TTMSFNCCustomMaps.SaveToGPXFile(
  ACoordinates: TTMSFNCMapsCoordinateRecArray; AFileName: string);
begin
  SaveToGPXFile(ACoordinates, AFileName, DefaultGPXMetaData);
end;

procedure TTMSFNCCustomMaps.SaveToGPXFile(
  ACoordinates: TTMSFNCMapsCoordinateRecArray; AFileName: string;
  AMetaData: TTMSFNCMapsGPXMetaData);
var
  sl: TStringList;
begin
  sl := TStringList.Create;
  try
    sl.Text := SaveToGPXText(ACoordinates, AMetaData);
    sl.SaveToFile(AFileName);
  finally
    sl.Free;
  end;
end;

procedure TTMSFNCCustomMaps.SaveToGPXStream(
  ACoordinates: TTMSFNCMapsCoordinateRecArray; AStream: TStream);
begin
  SaveToGPXStream(ACoordinates, AStream, DefaultGPXMetaData);
end;

procedure TTMSFNCCustomMaps.SaveToGPXStream(
  ACoordinates: TTMSFNCMapsCoordinateRecArray; AStream: TStream;
  AMetaData: TTMSFNCMapsGPXMetaData);
var
  ss: TStringStream;
begin
  ss := TStringStream.Create(SaveToGPXText(ACoordinates, AMetaData));
  try
    AStream.CopyFrom(ss, ss.Size);
  finally
    ss.Free;
  end;
end;

function TTMSFNCCustomMaps.SaveToGPXText(
  ACoordinates: TTMSFNCMapsCoordinateRecArray): string;
begin
  Result := SaveToGPX(ACoordinates, DefaultGPXMetaData);
end;

function TTMSFNCCustomMaps.SaveToGPXText(
  ACoordinates: TTMSFNCMapsCoordinateRecArray;
  AMetaData: TTMSFNCMapsGPXMetaData): string;
begin
  Result := SaveToGPX(ACoordinates, AMetaData);
end;

procedure TTMSFNCCustomMaps.SetAPIKey(const Value: string);
begin
  if FAPIKey <> Value then
  begin
    FAPIKey := Value;
    MapInitialized := False;
    InitializeHTML;
  end;
end;

{$IFDEF WEBLIB}
procedure TTMSFNCCustomMaps.SetName(const NewName: TComponentName);
begin
  DestroyMap;
  FMapInitialized := False;
  inherited;

  if FUpdateCount > 0 then
    Exit;

  InitializeMap;
end;
{$ENDIF}

procedure TTMSFNCCustomMaps.SetHeadLinks(const Value: TTMSFNCMapsHeadLinks);
begin
  FHeadLinks.Assign(Value);
end;

procedure TTMSFNCCustomMaps.SetService(const Value: TTMSFNCMapsService);
begin
  if FService <> Value then
  begin
    DestroyMap;
    FService := Value;
    if (Assigned(RouteCalculator) and (not (Service in [msGoogleMaps, msOpenLayers, msHere, msMapBox, msTomTom, msBingMaps]))) then
      raise Exception.Create('The TTMSFNCRouteCalculator is not compatible with the selected mapping service.');
    MapInitialized := False;
    InitializeMap;
  end;
end;

procedure TTMSFNCCustomMaps.SetOptions(const Value: TTMSFNCMapsOptions);
begin
  FOptions.Assign(Value);
end;

destructor TTMSFNCCustomMaps.Destroy;
begin
  DestroyMap;
  MapInitialized := False;
  FOptions.Free;
  FMarkers.Free;
  FElementContainers.Free;
  FLabels.Free;
  FPolygons.Free;
  FCircles.Free;
  FRectangles.Free;
  FPolylines.Free;
  FHeadLinks.Free;

  {$IFNDEF WEBLIB}
  if Assigned(FMaps) then
    FMaps.DestroyMaps;
  {$ENDIF}

  FMaps := nil;
  FMapsInstance := nil;
  inherited;
end;

procedure TTMSFNCCustomMaps.DestroyMap;
begin
  {$IFDEF WEBLIB}
  RemoveStyles;
  RemoveScripts;
  {$ENDIF}
end;

procedure TTMSFNCCustomMaps.EndUpdate;
begin
  inherited;
  Dec(FUpdateCount);
  if FUpdateCount = 0 then
  begin
    if not MapInitialized then
      InitializeMap;
    UpdatePolyElements;
    UpdateMarkers;
    UpdateElementContainers;
    UpdateOptions;
  end;
end;

procedure TTMSFNCCustomMaps.SetPolylines(const Value: TTMSFNCMapsPolylines);
begin
  FPolylines.Assign(Value);
end;

procedure TTMSFNCCustomMaps.SetPolygons(const Value: TTMSFNCMapsPolygons);
begin
  FPolygons.Assign(Value);
end;

procedure TTMSFNCCustomMaps.SetRectangles(const Value: TTMSFNCMapsRectangles);
begin
  FRectangles.Assign(Value);
end;

procedure TTMSFNCCustomMaps.SetRouteCalculator(
  const Value: TTMSFNCRouteCalculator);
begin
  FRouteCalculator := Value;
  if Assigned(FRouteCalculator) and not Assigned(TTMSFNCMapsRouteCalculatorOpen(RouteCalculator).OnCalculateRouteInternal) then
    TTMSFNCMapsRouteCalculatorOpen(RouteCalculator).OnCalculateRouteInternal := {$IFDEF LCLLIB}@{$ENDIF}RouteCalculatorDoCalculateRoute;
end;

procedure TTMSFNCCustomMaps.SetCircles(const Value: TTMSFNCMapsCircles);
begin
  FCircles.Assign(Value);
end;

procedure TTMSFNCCustomMaps.SetElementContainers(
  const Value: TTMSFNCMapsElementContainers);
begin
  FElementContainers.Assign(Value);
end;

procedure TTMSFNCCustomMaps.SetLabels(const Value: TTMSFNCMapsLabels);
begin
  FLabels.Assign(Value);
end;

procedure TTMSFNCCustomMaps.SetLibraryLocation(
  const Value: TTMSFNCMapsLibraryLocation);
begin
  if FLibraryLocation <> Value then
  begin
    FLibraryLocation := Value;
    FMapInitialized := False;
    InitializeHTML;
  end;
end;

procedure TTMSFNCCustomMaps.SetLocalFileAccess(const Value: Boolean);
begin
  if FLocalFileAccess <> Value then
  begin
    FLocalFileAccess := Value;
    FMapInitialized := False;
    InitializeHTML;
  end;
end;

procedure TTMSFNCCustomMaps.SetMapInitialized(const Value: Boolean);
begin
  {$IFDEF WEBLIB}
  if MapInitialized and not Value then
    ExecuteJavaScript('ResetMap();');
  {$ENDIF}

  FMapInitialized := Value;
end;

procedure TTMSFNCCustomMaps.SetMarkers(const Value: TTMSFNCMapsMarkers);
begin
  FMarkers.Assign(Value);
end;

{ TTMSFNCMapsFactoryService }

constructor TTMSFNCMapsFactoryService.Create;
begin
  inherited Create;
  FMaps := TTMSFNCMapsList.Create;
end;

function TTMSFNCMapsFactoryService.CreateMaps: ITMSFNCCustomMaps;
begin
  Result := DoCreateMaps;
  FMaps.Add(Result);
end;

destructor TTMSFNCMapsFactoryService.Destroy;
begin
  FreeAndNil(FMaps);
  inherited Destroy;
end;

procedure TTMSFNCMapsFactoryService.DestroyMaps(AMaps: ITMSFNCCustomMaps);
begin
  FMaps.Remove(AMaps);
end;

{ TTMSFNCMapsPlatformServices }

procedure TTMSFNCMapsPlatformServices.AddPlatformService(const AServiceGUID: TGUID; const AService: IInterface);
var
  LService: IInterface;
begin
  if not FServicesList.ContainsKey(GUIDToString(AServiceGUID)) then
  begin
    if Supports(AService, AServiceGUID, LService) then
      FServicesList.Add(TTMSFNCMapsPlatformServicesService.Create(GUIDToString(AServiceGUID), AService));
  end;
end;

constructor TTMSFNCMapsPlatformServices.Create;
begin
  inherited;
  FServicesList := TTMSFNCMapsPlatformServicesList.Create;
end;

destructor TTMSFNCMapsPlatformServices.Destroy;
begin
  FreeAndNil(FServicesList);
  inherited;
end;

{$IFNDEF AUTOREFCOUNT}
class procedure TTMSFNCMapsPlatformServices.ReleaseCurrent;
begin
  FreeAndNil(FCurrent);
  FCurrentReleased := True;
end;
{$ENDIF}

class function TTMSFNCMapsPlatformServices.Current: TTMSFNCMapsPlatformServices;
begin
  if (FCurrent = nil) and not FCurrentReleased then
    FCurrent := TTMSFNCMapsPlatformServices.Create;
  Result := FCurrent;
end;

function TTMSFNCMapsPlatformServices.GetPlatformService(const AServiceGUID: TGUID): IInterface;
var
  k: IInterface;
begin
  k := FServicesList.Interfaces[GUIDToString(AServiceGUID)];
  Supports(k, AServiceGUID, Result);
end;

procedure TTMSFNCMapsPlatformServices.RemovePlatformService(const AServiceGUID: TGUID);
begin
  FServicesList.RemoveByGUID(GUIDToString(AServiceGUID));
end;

function TTMSFNCMapsPlatformServices.SupportsPlatformService(const AServiceGUID: TGUID;
  var AService: IInterface): Boolean;
begin
  if FServicesList.ContainsKey(GUIDToString(AServiceGUID)) then
    Result := Supports(FServicesList.Interfaces[GUIDToString(AServiceGUID)], AServiceGUID, AService)
  else
  begin
    AService := nil;
    Result := False;
  end;
end;

function TTMSFNCMapsPlatformServices.SupportsPlatformService(const AServiceGUID: TGUID): Boolean;
begin
  Result := FServicesList.ContainsKey(GUIDToString(AServiceGUID));
end;

{ TTMSFNCMapsPlatformServicesService }

constructor TTMSFNCMapsPlatformServicesService.Create(AGUID: string;
  AInterface: IInterface);
begin
  FGUID := AGUID;
  FInterface := AInterface;
end;

{ TTMSFNCMapsPlatformServicesList }

function TTMSFNCMapsPlatformServicesList.ContainsKey(AGUID: string): Boolean;
var
  I: Integer;
begin
  Result := False;
  for I := 0 to Count - 1 do
  begin
    if Items[I].GUID = AGUID then
    begin
      Result := True;
      Break;
    end;
  end;
end;

function TTMSFNCMapsPlatformServicesList.GetValue(AGUID: string): IInterface;
var
  I: Integer;
begin
  Result := nil;
  for I := 0 to Count - 1 do
  begin
    if Items[I].GUID = AGUID then
    begin
      Result := Items[I].&Interface;
      Break;
    end;
  end;
end;

procedure TTMSFNCMapsPlatformServicesList.RemoveByGUID(AGUID: string);
var
  I: Integer;
begin
  for I := Count - 1 downto 0 do
  begin
    if Items[I].GUID = AGUID then
    begin
      Remove(Items[I]);
      Break;
    end;
  end;
end;

{$IFDEF WEBLIB}
function TTMSFNCMapsPlatformServicesList.GetItem(Index: Integer): TTMSFNCMapsPlatformServicesService;
begin
  Result := TTMSFNCMapsPlatformServicesService(inherited Items[Index]);
end;

procedure TTMSFNCMapsPlatformServicesList.SetItem(Index: Integer; const Value: TTMSFNCMapsPlatformServicesService);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCMapsList.GetItem(Index: Integer): ITMSFNCCustomMaps;
begin
  Result := ITMSFNCCustomMaps(inherited Items[Index]);
end;

procedure TTMSFNCMapsList.SetItem(Index: Integer; const Value: ITMSFNCCustomMaps);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCMapsLinksList.GetItem(Index: Integer): TTMSFNCMapsLink;
begin
  Result := TTMSFNCMapsLink(inherited Items[Index]);
end;

procedure TTMSFNCMapsLinksList.SetItem(Index: Integer; const Value: TTMSFNCMapsLink);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

{ TTMSFNCMapsOptions }

procedure TTMSFNCMapsOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCMapsOptions) then
  begin
    FDefaultLongitude := (Source as TTMSFNCMapsOptions).DefaultLongitude;
    FDefaultLatitude := (Source as TTMSFNCMapsOptions).DefaultLatitude;
    FConsole := (Source as TTMSFNCMapsOptions).Console;
    FShowZoomControl := (Source as TTMSFNCMapsOptions).ShowZoomControl;
    FShowMapTypeControl := (Source as TTMSFNCMapsOptions).ShowMapTypeControl;
    FZoomOnDblClick := (Source as TTMSFNCMapsOptions).ZoomOnDblClick;
    FZoomOnWheelScroll := (Source as TTMSFNCMapsOptions).ZoomOnWheelScroll;
    FPanning := (Source as TTMSFNCMapsOptions).Panning;
  end
  else
    inherited;
end;

procedure TTMSFNCMapsOptions.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCMapsOptions.Create;
begin
  FZoomOnDblClick := True;
  FDefaultLatitude := DEFAULT_LATITUDE;
  FDefaultLongitude := DEFAULT_LONGITUDE;
  FDefaultZoomLevel := DEFAULT_ZOOMLEVEL;
  FShowZoomControl := True;
  FShowMapTypeControl := True;
  FLocale := DEFAULT_LOCALE;
  FZoomOnWheelScroll := True;
  FPanning := True;
end;

function TTMSFNCMapsOptions.IsDefaultLatitudeStored: Boolean;
begin
  Result := DefaultLatitude <> DEFAULT_LATITUDE;
end;

function TTMSFNCMapsOptions.IsDefaultLongitudeStored: Boolean;
begin
  Result := DefaultLongitude <> DEFAULT_LONGITUDE;
end;

function TTMSFNCMapsOptions.IsLocaleStored: Boolean;
begin
  Result := Locale <> DEFAULT_LOCALE;
end;

procedure TTMSFNCMapsOptions.SetBackgroundColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FBackGroundColor <> Value then
  begin
    FBackgroundColor := Value;
    Changed;
  end;
end;

procedure TTMSFNCMapsOptions.SetConsole(const Value: Boolean);
begin
  if FConsole <> Value then
  begin
    FConsole := Value;
    Changed;
  end;
end;

procedure TTMSFNCMapsOptions.SetDefaultLatitude(const Value: Double);
begin
  if FDefaultLatitude <> Value then
  begin
    FDefaultLatitude := Value;
    Changed;
  end;
end;

procedure TTMSFNCMapsOptions.SetDefaultLongitude(const Value: Double);
begin
  if FDefaultLongitude <> Value then
  begin
    FDefaultLongitude := Value;
    Changed;
  end;
end;

procedure TTMSFNCMapsOptions.SetDefaultZoomLevel(const Value: Double);
begin
  if FDefaultZoomLevel <> Value then
  begin
    FDefaultZoomLevel := Value;
    Changed;
  end;
end;

procedure TTMSFNCMapsOptions.SetLocale(const Value: string);
begin
  if FLocale <> Value then
  begin
    FLocale := Value;
    Changed;
  end;
end;

procedure TTMSFNCMapsOptions.SetPanning(const Value: Boolean);
begin
  if FPanning <> Value then
  begin
    FPanning := Value;
    Changed;
  end;
end;

procedure TTMSFNCMapsOptions.SetShowMapTypeControl(const Value: Boolean);
begin
  if FShowMapTypeControl <> Value then
  begin
    FShowMapTypeControl := Value;
    Changed;
  end;
end;

procedure TTMSFNCMapsOptions.SetShowZoomControl(const Value: Boolean);
begin
  if FShowZoomControl <> Value then
  begin
    FShowZoomControl := Value;
    Changed;
  end;
end;

procedure TTMSFNCMapsOptions.SetZoomOnDblClick(const Value: Boolean);
begin
  if FZoomOnDblClick <> Value then
  begin
    FZoomOnDblClick := Value;
    Changed;
  end;
end;

procedure TTMSFNCMapsOptions.SetZoomOnWheelScroll(const Value: Boolean);
begin
  if FZoomOnWheelScroll <> Value then
  begin
    FZoomOnWheelScroll := Value;
    Changed;
  end;
end;

{ TTMSFNCCustomMapsInterfacedObject }

destructor TTMSFNCCustomMapsInterfacedObject.Destroy;
begin
  FMapsProperties := nil;
  inherited;
end;

function TTMSFNCCustomMapsInterfacedObject.GetMapsProperties: ITMSFNCCustomMapsProperties;
begin
  Result := FMapsProperties;
end;

procedure TTMSFNCCustomMapsInterfacedObject.SetMapsProperties(
  const Value: ITMSFNCCustomMapsProperties);
begin
  FMapsProperties := Value;
end;

{ TTMSFNCCustomMaps }

function TTMSFNCCustomMaps.CanLoadDefaultHTML: Boolean;
begin
  Result := False;
end;

function TTMSFNCCustomMaps.GetDefaultLatitude: string;
begin
  Result := FloatToStrDot(Options.DefaultLatitude);
end;

function TTMSFNCCustomMaps.GetDefaultLongitude: string;
begin
  Result := FloatToStrDot(Options.DefaultLongitude);
end;

function TTMSFNCCustomMaps.GetDefaultZoomLevel: string;
begin
  Result := FloatToStr(Options.DefaultZoomLevel);
end;

function TTMSFNCCustomMaps.AddPolyline(ACoordinates: TTMSFNCMapsCoordinateRecArray; AClose: Boolean = False): TTMSFNCMapsPolyline;
var
  I: Integer;
  co: TTMSFNCMapsCoordinateItem;
begin
  Result := nil;
  if Length(ACoordinates) = 0 then
    Exit;

  BeginUpdate;
  Result := Polylines.Add;
  for I := 0 to Length(ACoordinates) - 1 do
  begin
    co := Result.Coordinates.Add;
    co.Coordinate.Longitude := ACoordinates[I].Longitude;
    co.Coordinate.Latitude := ACoordinates[I].Latitude;
  end;

  if AClose then
  begin
    co := Result.Coordinates.Add;
    co.Assign(Result.Coordinates[0]);
  end;
  EndUpdate;
end;

function TTMSFNCCustomMaps.AddPolygon(ACoordinates: TTMSFNCMapsCoordinateRecArray; AClose: Boolean = False): TTMSFNCMapsPolygon;
var
  I: Integer;
  co: TTMSFNCMapsCoordinateItem;
begin
  Result := nil;
  if Length(ACoordinates) = 0 then
    Exit;

  BeginUpdate;
  Result := Polygons.Add;
  for I := 0 to Length(ACoordinates) - 1 do
  begin
    co := Result.Coordinates.Add;
    co.Coordinate.Longitude := ACoordinates[I].Longitude;
    co.Coordinate.Latitude := ACoordinates[I].Latitude;
  end;

  if AClose then
  begin
    co := Result.Coordinates.Add;
    co.Assign(Result.Coordinates[0]);
  end;
  EndUpdate;
end;

function TTMSFNCCustomMaps.AddCircle(ACenter: TTMSFNCMapsCoordinateRec; ARadius: Double = 10000): TTMSFNCMapsCircle;
begin
  BeginUpdate;
  Result := Circles.Add;
  Result.Center.ToRec := ACenter;
  Result.Radius := ARadius;
  EndUpdate;
end;

function TTMSFNCCustomMaps.ShowPopup(ACoordinate: TTMSFNCMapsCoordinateRec; AText: string; AOffsetX: Double = 0; AOffsetY: Double = 0; APanMap: Boolean = True): string;
var
  p: TTMSFNCMapsPopup;
begin
  p := TTMSFNCMapsPopup.Create;
  try
    Result := p.ID;
    p.Text := AText;
    p.OffsetX := AOffsetX;
    p.OffsetY := AOffsetY;
    p.Coordinate.ToRec := ACoordinate;
    p.PanMap := APanMap;
    ExecuteJavaScript(SHOWPOPUPFUNCTION + '(' + p.JSON + ')');
  finally
    p.Free;
  end;
end;

function TTMSFNCCustomMaps.ShowPopup(ALatitude: Double; ALongitude: Double; AText: string; AOffsetX: Double = 0; AOffsetY: Double = 0; APanMap: Boolean = True): string;
begin
  Result := ShowPopup(CreateCoordinate(ALatitude, ALongitude), AText, AOffsetX, AOffsetY, APanMap);
end;

procedure TTMSFNCCustomMaps.SyncLabels(
  ACollection: TTMSFNCMapsElementContainers);
var
  l: TTMSFNCMapsLabel;
  el: TTMSFNCMapsElementContainer;
  I: Integer;
  style: string;
begin
  for I := 0 to Labels.Count - 1 do
  begin
    l := Labels[I];
    el := ACollection.Add;
    el.HTMLElementID := 'ElementContainerLabel' + IntToStr(I);
    el.Visible := l.Visible;
    el.HTML.Text := l.Text;
    el.LabelType := l.LabelType;

    el.Coordinate.Assign(l.Coordinate);
    el.Bounds.Assign(l.Bounds);

    if l.Position = ptCoordinate then
      el.Position := poCoordinate
    else if l.Position = ptBounds then
      el.Position := poBounds;

    style := '';

    if l.BackgroundColor <> gcNull then
    begin
      style := style + 'background-color:' + TTMSFNCGraphics.ColorToHTML(l.BackgroundColor) + ';';
      style := style + 'padding:5px;border-radius:5px;';
    end
    else
    begin
      style := style + 'text-shadow:-1px -1px 0 rgba(255,255,255, 0.5),1px -1px 0 rgba(255,255,255, 0.5),-1px 1px 0 rgba(255,255,255, 0.5),1px 1px 0 rgba(255,255,255, 0.5);';
    end;

    if l.BorderColor <> gcNull then
    begin
      style := style + 'border-color: ' + TTMSFNCGraphics.ColorToHTML(l.BorderColor) + ';';
      if l.BorderWidth > 0 then
      begin
        style := style + 'border-style:solid;';
        style := style + 'border-width:' + IntToStr(l.BorderWidth) + 'px;';
      end;
    end;

    if l.Font.Color <> gcNull then
    begin
      style := style + 'color:' + TTMSFNCGraphics.ColorToHTML(l.Font.Color) + ';';
    end;

    if l.Font.Name <> '' then
    begin
      style := style + 'font-family:"' + l.Font.Name + '";';
    end
    else
    begin
      style := style + 'font-family:"Roboto";';
    end;

    {$IFNDEF LCLWEBLIB}
    if TFontStyle.fsBold in l.Font.Style then
    begin
      style := style + 'font-weight:bold;'
    end;

    if TFontStyle.fsItalic in l.Font.Style then
    begin
      style := style + 'font-style:italic;'
    end;

    if TFontStyle.fsUnderline in l.Font.Style then
    begin
      style := style + 'text-decoration:underline;'
    end;

    if TFontStyle.fsStrikeOut in l.Font.Style then
    begin
      style := style + 'text-decoration-line:line-through;'
    end;
    {$ENDIF}

    style := style + 'font-size:' + TTMSFNCUtils.FloatToStrDot(l.Font.Size) + 'px;';

    el.Style.Text := '.' + el.HTMLElementID + '{' + style + '}';
    el.HTMLElementClassName := el.HTMLElementID;
    el.UseDefaultStyle := False;
  end;
end;

procedure TTMSFNCCustomMaps.CloseAllPopups;
begin
  ExecuteJavaScript(GETPOPUPARRAYVAR, {$IFDEF LCLWEBLIB}@{$ENDIF}DoCloseAllPopups);
end;

procedure TTMSFNCCustomMaps.ClosePopup(AID: string);
begin
  ExecuteJavaScript(CLOSEPOPUPFUNCTION + '({"ID": "' + AID + '"})');
end;

function TTMSFNCCustomMaps.AddRectangle(ABounds: TTMSFNCMapsBoundsRec): TTMSFNCMapsRectangle;
begin
  BeginUpdate;
  Result := Rectangles.Add;
  Result.Bounds.ToRec := ABounds;
  EndUpdate;
end;

procedure TTMSFNCCustomMaps.ConnectMarkers(AMarkersArray: TTMSFNCMapsMarkersArray);
var
  I: Integer;
  arr: TTMSFNCMapsCoordinateRecArray;
begin
  BeginUpdate;
  if Length(AMarkersArray) <= 1 then
    Exit;

  for I := 0 to Length(AMarkersArray) - 2 do
  begin
    SetLength(arr, Length(arr) + 1);
    arr[Length(arr) - 1] := AMarkersArray[I].Coordinate.ToRec;
    SetLength(arr, Length(arr) + 1);
    arr[Length(arr) - 1] := AMarkersArray[I + 1].Coordinate.ToRec;
  end;

  SetLength(arr, Length(arr) + 1);
  arr[Length(arr) - 1] := AMarkersArray[Length(AMarkersArray) - 1].Coordinate.ToRec;
  SetLength(arr, Length(arr) + 1);
  arr[Length(arr) - 1] := AMarkersArray[0].Coordinate.ToRec;

  AddPolyline(arr);

  EndUpdate;
end;

function TTMSFNCCustomMaps.AddElementContainer(AHTML, AStyle, AScript: TStrings;
  APosition: TTMSFNCMapsPosition; AHTMLElementID: string): TTMSFNCMapsElementContainer;
begin
  BeginUpdate;
  Result := ElementContainers.Add;
  if Assigned(AHTML) then
    Result.HTML.Assign(AHTML);
  if Assigned(AStyle) then
    Result.Style.Assign(AStyle);
  if Assigned(AScript) then
    Result.Script.Assign(AScript);
  Result.Position := APosition;
  if AHTMLElementID <> '' then
    Result.HTMLElementID := AHTMLElementID;
  EndUpdate;
end;

function TTMSFNCCustomMaps.AddHeadLink(AURL: string; AKind: TTMSFNCMapsLinkKind;
  ARel: string): TTMSFNCMapsHeadLink;
begin
  BeginUpdate;
  Result := HeadLinks.Add;
  Result.URL := AURL;
  Result.Kind := AKind;
  Result.Rel := ARel;
  EndUpdate;
end;

function TTMSFNCCustomMaps.AddLabel(ALatitude, ALongitude: Double;
  AText: string; AFontColor: TTMSFNCGraphicsColor = gcNull; ABackgroundColor: TTMSFNCGraphicsColor = gcNull): TTMSFNCMapsLabel;
begin
  BeginUpdate;
  Result := Labels.Add;
  Result.Text := AText;
  Result.Coordinate.Longitude := ALongitude;
  Result.Coordinate.Latitude := ALatitude;
  Result.BackgroundColor := ABackgroundColor;
  Result.Font.Color := AFontColor;
  Result.Position := ptCoordinate;
  EndUpdate;
end;

function TTMSFNCCustomMaps.AddLabel(ANorthEastLatitude, ANorthEastLongitude,
  ASouthWestLatitude, ASouthWestLongitude: Double; AText: string; AFontColor,
  ABackgroundColor: TTMSFNCGraphicsColor): TTMSFNCMapsLabel;
begin
  BeginUpdate;
  Result := Labels.Add;
  Result.Text := AText;
  Result.Bounds.NorthEast.Longitude := ANorthEastLongitude;
  Result.Bounds.NorthEast.Latitude := ANorthEastLatitude;
  Result.Bounds.SouthWest.Longitude := ASouthWestLongitude;
  Result.Bounds.SouthWest.Latitude := ASouthWestLatitude;
  Result.BackgroundColor := ABackgroundColor;
  Result.Font.Color := AFontColor;
  Result.Position := ptBounds;
  EndUpdate;
end;

function TTMSFNCCustomMaps.AddLabel(ABounds: TTMSFNCMapsBoundsRec; AText: string;
  AFontColor, ABackgroundColor: TTMSFNCGraphicsColor): TTMSFNCMapsLabel;
begin
  Result := AddLabel(ABounds.NorthEast.Latitude, ABounds.NorthEast.Longitude, ABounds.SouthWest.Latitude, ABounds.SouthWest.Longitude, AText, AFontColor, ABackgroundColor);
end;

function TTMSFNCCustomMaps.AddLabel(ACoordinate: TTMSFNCMapsCoordinateRec;
  AText: string; AFontColor: TTMSFNCGraphicsColor = gcNull; ABackgroundColor: TTMSFNCGraphicsColor = gcNull): TTMSFNCMapsLabel;
begin
  Result := AddLabel(ACoordinate.Latitude, ACoordinate.Longitude, AText, AFontColor, ABackgroundColor);
end;

function TTMSFNCCustomMaps.AddMarker(ALatitude: Double; ALongitude: Double; ATitle: string = ''; AIconURL: string = ''): TTMSFNCMapsMarker;
begin
  BeginUpdate;
  Result := Markers.Add;
  Result.Title := ATitle;
  Result.Coordinate.Longitude := ALongitude;
  Result.Coordinate.Latitude := ALatitude;
  Result.IconURL := AIconURL;
  EndUpdate;
end;

function TTMSFNCCustomMaps.AddMarker(ACoordinate: TTMSFNCMapsCoordinateRec; ATitle: string = ''; AIconURL: string = ''): TTMSFNCMapsMarker;
begin
  Result := AddMarker(ACoordinate.Latitude, ACoordinate.Longitude, ATitle, AIconURL);
end;

{ TTMSFNCMapsMarker }

procedure TTMSFNCMapsMarker.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMapsMarker then
  begin
    FCoordinate.Assign((Source as TTMSFNCMapsMarker).Coordinate);
    FTitle := (Source as TTMSFNCMapsMarker).Title;
    FIconURL := (Source as TTMSFNCMapsMarker).IconURL;
    FVisible := (Source as TTMSFNCMapsMarker).Visible;
  end;
end;

constructor TTMSFNCMapsMarker.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCMapsMarkers).FOwner;

  FTitle := DEFAULT_MARKERTEXT;
  FCoordinate := TTMSFNCMapsCoordinate.Create;
  FCoordinate.OnChange := @CoordinateChanged;
  FVisible := True;

  UpdateMarker;
end;

destructor TTMSFNCMapsMarker.Destroy;
begin
  FCoordinate.Free;
  inherited;
  UpdateMarker;
end;

function TTMSFNCMapsMarker.GetID: string;
begin
  if (FID = '') or FRecreate then
    FID := CreateNewGUID;

  FRecreate := False;
  Result := FID;
end;

function TTMSFNCMapsMarker.GetLatitude: Double;
begin
  Result := Coordinate.Latitude;
end;

function TTMSFNCMapsMarker.GetLongitude: Double;
begin
  Result := Coordinate.Longitude;
end;

function TTMSFNCMapsMarker.IsIconURLStored: Boolean;
begin
  Result := IconURL <> '';
end;

function TTMSFNCMapsMarker.IsLatitudeStored: Boolean;
begin
  Result := Latitude <> DEFAULT_LATITUDE;
end;

function TTMSFNCMapsMarker.IsLongitudeStored: Boolean;
begin
  Result := Longitude <> DEFAULT_LONGITUDE;
end;

function TTMSFNCMapsMarker.IsTitleStored: Boolean;
begin
  Result := Title <> DEFAULT_MARKERTEXT;
end;

procedure TTMSFNCMapsMarker.CoordinateChanged(Sender: TObject);
begin
  UpdateMarker;
end;

procedure TTMSFNCMapsMarker.SetCoordinate(const Value: TTMSFNCMapsCoordinate);
begin
  if FCoordinate <> Value then
  begin
    FCoordinate.Assign(Value);
    UpdateMarker;
  end;
end;

procedure TTMSFNCMapsMarker.SetIconURL(const Value: string);
begin
  if FIconURL <> Value then
  begin
    FIconURL := Value;
    UpdateMarker;
  end;
end;

procedure TTMSFNCMapsMarker.SetLatitude(const Value: Double);
begin
  Coordinate.Latitude := Value;
end;

procedure TTMSFNCMapsMarker.SetLongitude(const Value: Double);
begin
  Coordinate.Longitude := Value;
end;

procedure TTMSFNCMapsMarker.SetTitle(const Value: string);
begin
  if FTitle <> Value then
  begin
    FTitle := Value;
    UpdateMarker;
  end;
end;

procedure TTMSFNCMapsMarker.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdateMarker;
  end;
end;

procedure TTMSFNCMapsMarker.UpdateMarker;
begin
  FReload := True;
  if Assigned(FOwner) then
    FOwner.UpdateMarkers;
end;

{ TTMSFNCMapsMarkers }

function TTMSFNCMapsMarkers.Add: TTMSFNCMapsMarker;
begin
  Result := TTMSFNCMapsMarker(inherited Add);
end;

procedure TTMSFNCMapsMarkers.Clear;
var
  l: TTMSFNCCustomMaps;
  ci: TCollectionItem;
begin
  l := FOwner;
  if Assigned(l) then
    l.BeginUpdate;

  if Count > 0 then
  begin
    while Count > 0 do
    begin
      ci := TCollectionItem(Items[Count - 1]);
      ci.Free;
    end;
  end;

  if Assigned(l) then
    l.EndUpdate;
end;

constructor TTMSFNCMapsMarkers.Create(AOwner: TTMSFNCCustomMaps);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCMapsMarkers.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCMapsMarker;
end;

function TTMSFNCMapsMarkers.GetItem(
  Index: Integer): TTMSFNCMapsMarker;
begin
  Result := TTMSFNCMapsMarker(inherited Items[Index]);
end;

function TTMSFNCMapsMarkers.GetItemByID(ID: string): TTMSFNCMapsMarker;
var
  I: Integer;
  m: TTMSFNCMapsMarker;
begin
  Result := nil;
  for I := 0 to Count - 1 do
  begin
    m := Items[I];
    if m.ID = ID then
    begin
      Result := m;
      Break;
    end;
  end;
end;

function TTMSFNCMapsMarkers.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCMapsMarkers.Insert(
  Index: Integer): TTMSFNCMapsMarker;
begin
  Result := TTMSFNCMapsMarker(inherited Insert(Index));
end;

procedure TTMSFNCMapsMarkers.Recreate;
var
  I: Integer;
begin
  for I := 0 to Count - 1 do
    Items[I].Recreate := True;
end;

procedure TTMSFNCMapsMarkers.SetItem(Index: Integer;
  const Value: TTMSFNCMapsMarker);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCMapsMarkers.ToCoordinateArray: TTMSFNCMapsCoordinateRecArrayArray;
var
  I: Integer;
  arr: TTMSFNCMapsCoordinateRecArray;
begin
  SetLength(Result, Count);
  for I := 0 to Count - 1 do
  begin
    SetLength(arr, 1);
    arr[0] := Items[I].Coordinate.ToRec;
    Result[I] := arr;
  end;
end;

{ TTMSFNCMapsPolyline }

function TTMSFNCMapsPolyline.Distance: Double;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to Coordinates.Count - 2 do
  begin
    Result := Result + MeasureDistance(Coordinates[I].Coordinate.ToRec, Coordinates[I + 1].Coordinate.ToRec);
  end;
end;

{ TTMSFNCMapsPolyElement }

function TTMSFNCMapsPolyElement.AddHole(
  ACoordinates: TTMSFNCMapsCoordinateRecArray): TTMSFNCMapsPolyElementHole;
var
  I: Integer;
  co: TTMSFNCMapsCoordinateItem;
begin
  Result := nil;
  if Length(ACoordinates) = 0 then
    Exit;

  BeginUpdate;
  Result := Holes.Add;
  for I := 0 to Length(ACoordinates) - 1 do
  begin
    co := Result.Coordinates.Add;
    co.Coordinate.Longitude := ACoordinates[I].Longitude;
    co.Coordinate.Latitude := ACoordinates[I].Latitude;
  end;
  EndUpdate;
end;

function TTMSFNCMapsPolyElement.Area: Double;
begin
  Result := MeasureArea(FCoordinates.ToArray);
end;

procedure TTMSFNCMapsPolyElement.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMapsPolyElement then
  begin
    FCoordinates.Assign((Source as TTMSFNCMapsPolyElement).Coordinates);
    FStrokeColor := (Source as TTMSFNCMapsPolyElement).StrokeColor;
    FStrokeOpacity := (Source as TTMSFNCMapsPolyElement).StrokeOpacity;
    FStrokeWidth := (Source as TTMSFNCMapsPolyElement).StrokeWidth;
    FVisible := (Source as TTMSFNCMapsPolyElement).Visible;
  end;
end;

procedure TTMSFNCMapsPolyElement.BeginUpdate;
begin
  if Assigned(FOwner) then
    FOwner.BeginUpdate;
end;

function TTMSFNCMapsPolyElement.ContainsPoint(
  ACoordinate: TTMSFNCMapsCoordinateRec): Boolean;
begin
  Result := IsPointInArea(ACoordinate.Latitude, ACoordinate.Longitude, FCoordinates.ToArray);
end;

constructor TTMSFNCMapsPolyElement.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCMapsPolyElements).FOwner;

  FCoordinates := TTMSFNCMapsCoordinates.Create(Self);
  FCoordinates.OnBeginUpdate := DoBeginUpdate;
  FCoordinates.OnEndUpdate := DoEndUpdate;
  FCoordinates.OnUpdateCoordinates := DoUpdateCoordinates;

  FStrokeColor := gcBlack;
  FStrokeOpacity := 1.0;
  FStrokeWidth := 2;
  FVisible := True;

  FHoles := TTMSFNCMapsPolyElementHoles.Create(Self);

  UpdatePolyElement;
end;

destructor TTMSFNCMapsPolyElement.Destroy;
begin
  FCoordinates.Free;
  FHoles.Free;
  inherited;
  UpdatePolyElement;
end;

procedure TTMSFNCMapsPolyElement.DoBeginUpdate(Sender: TObject);
begin
  if Assigned(FOwner) then
    FOwner.BeginUpdate;
end;

procedure TTMSFNCMapsPolyElement.DoEndUpdate(Sender: TObject);
begin
  if Assigned(FOwner) then
    FOwner.EndUpdate;
end;

procedure TTMSFNCMapsPolyElement.DoUpdateCoordinates(Sender: TObject);
begin
  if Assigned(FOwner) then
    FOwner.UpdatePolyElements;
end;

procedure TTMSFNCMapsPolyElement.EndUpdate;
begin
  if Assigned(FOwner) then
    FOwner.EndUpdate;
end;

function TTMSFNCMapsPolyElement.GetID: string;
begin
  if (FID = '') or FRecreate then
    FID := CreateNewGUID;
  FRecreate := False;
  Result := FID;
end;

function TTMSFNCMapsPolyElement.IsStrokeOpacityStored: Boolean;
begin
  Result := StrokeOpacity <> 1;
end;

procedure TTMSFNCMapsPolyElement.SetStrokeColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FStrokeColor <> Value then
  begin
    FStrokeColor := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCMapsPolyElement.SetStrokeOpacity(const Value: Single);
begin
  if FStrokeOpacity <> Value then
  begin
    FStrokeOpacity := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCMapsPolyElement.SetStrokeWidth(const Value: Integer);
begin
  if FStrokeWidth <> Value then
  begin
    FStrokeWidth := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCMapsPolyElement.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCMapsPolyElement.UpdatePolyElement;
begin
  if Assigned(FOwner) then
  begin
    FReload := True;
    FOwner.UpdatePolyElements;
  end;
end;

procedure TTMSFNCMapsPolyElement.SetCoordinates(
  const Value: TTMSFNCMapsCoordinates);
begin
  if FCoordinates <> Value then
  begin
    FCoordinates.Assign(Value);
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCMapsPolyElement.SetHoles(
  const Value: TTMSFNCMapsPolyElementHoles);
begin
  if FHoles <> Value then
  begin
    FHoles.Assign(Value);
    UpdatePolyElement;
  end;
end;

{ TTMSFNCMapsPolyElementHoles }

function TTMSFNCMapsPolyElementHoles.Add: TTMSFNCMapsPolyElementHole;
begin
  Result := TTMSFNCMapsPolyElementHole(inherited Add);
end;

procedure TTMSFNCMapsPolyElementHoles.Clear;
begin
//
end;

constructor TTMSFNCMapsPolyElementHoles.Create(AOwner: TTMSFNCMapsPolyElement);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCMapsPolyElementHoles.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCMapsPolyElementHole;
end;

function TTMSFNCMapsPolyElementHoles.GetItem(
  Index: Integer): TTMSFNCMapsPolyElementHole;
begin
  Result := TTMSFNCMapsPolyElementHole(inherited Items[Index]);
end;

function TTMSFNCMapsPolyElementHoles.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCMapsPolyElementHoles.Insert(Index: Integer): TTMSFNCMapsPolyElementHole;
begin
  Result := TTMSFNCMapsPolyElementHole(inherited Insert(Index));
end;

procedure TTMSFNCMapsPolyElementHoles.SetItem(Index: Integer;
  const Value: TTMSFNCMapsPolyElementHole);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCMapsPolyElementHole }

procedure TTMSFNCMapsPolyElementHole.Assign(Source: TPersistent);
begin
  FID := (Source as TTMSFNCMapsPolyElementHole).ID;
  FCoordinates.Assign((Source as TTMSFNCMapsPolyElementHole).Coordinates);
end;

constructor TTMSFNCMapsPolyElementHole.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCMapsPolyElementHoles).FOwner;

  FCoordinates := TTMSFNCMapsCoordinates.Create(Self);
  FCoordinates.OnBeginUpdate := DoBeginUpdate;
  FCoordinates.OnEndUpdate := DoEndUpdate;
  FCoordinates.OnUpdateCoordinates := DoUpdateCoordinates;
end;

destructor TTMSFNCMapsPolyElementHole.Destroy;
begin
  FCoordinates.Free;
  inherited;
end;

function TTMSFNCMapsPolyElementHole.GetID: string;
begin
  if (FID = '') or FOwner.Recreate then
    FID := CreateNewGUID;

  Result := FID;
end;

procedure TTMSFNCMapsPolyElementHole.DoBeginUpdate(Sender: TObject);
begin
  if Assigned(FOwner) then
    FOwner.BeginUpdate;
end;

procedure TTMSFNCMapsPolyElementHole.DoEndUpdate(Sender: TObject);
begin
  if Assigned(FOwner) then
    FOwner.EndUpdate;
end;

procedure TTMSFNCMapsPolyElementHole.DoUpdateCoordinates(Sender: TObject);
begin
  if Assigned(FOwner) then
    FOwner.UpdatePolyElement;
end;

procedure TTMSFNCMapsPolyElementHole.UpdatePolyElement;
begin
  if Assigned(FOwner) then
    FOwner.UpdatePolyElement;
end;

procedure TTMSFNCMapsPolyElementHole.SetCoordinates(
  const Value: TTMSFNCMapsCoordinates);
begin
  if FCoordinates <> Value then
  begin
    FCoordinates.Assign(Value);
    UpdatePolyElement;
  end;
end;

{ TTMSFNCMapsRectangle }

function TTMSFNCMapsRectangle.Area: Double;
begin
  Result := MeasureArea(BoundsRectangle(Bounds.ToRec));
end;

procedure TTMSFNCMapsRectangle.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCMapsRectangle then
  begin
    FBounds.Assign((Source as TTMSFNCMapsRectangle).Bounds);
  end;
end;

function TTMSFNCMapsRectangle.ContainsPoint(
  ACoordinate: TTMSFNCMapsCoordinateRec): Boolean;
begin
  Result := IsPointInArea(ACoordinate.Latitude, ACoordinate.Longitude, BoundsRectangle(Bounds.ToRec));
end;

constructor TTMSFNCMapsRectangle.Create(ACollection: TCollection);
begin
  inherited;
  FBounds := TTMSFNCMapsBounds.Create;
  FBounds.OnChange := @DoBoundsChanged;
end;

destructor TTMSFNCMapsRectangle.Destroy;
begin
  FBounds.Free;
  inherited;
end;

procedure TTMSFNCMapsRectangle.DoBoundsChanged(Sender: TObject);
begin
  UpdatePolyElement;
end;

procedure TTMSFNCMapsRectangle.SetBounds(const Value: TTMSFNCMapsBounds);
begin
  if FBounds <> Value then
  begin
    FBounds.Assign(Value);
    UpdatePolyElement;
  end;
end;

{ TTMSFNCMapsCircle }

function TTMSFNCMapsCircle.Area: Double;
begin
  Result := MeasureArea(CreateCircle(Center.ToRec, Radius));
end;

procedure TTMSFNCMapsCircle.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCMapsCircle then
  begin
    FRadius := (Source as TTMSFNCMapsCircle).Radius;
    FCenter.Assign((Source as TTMSFNCMapsCircle).Center);
  end;
end;

function TTMSFNCMapsCircle.ContainsPoint(
  ACoordinate: TTMSFNCMapsCoordinateRec): Boolean;
begin
  Result := IsPointInArea(ACoordinate.Latitude, ACoordinate.Longitude, CreateCircle(Center.ToRec, Radius));
end;

constructor TTMSFNCMapsCircle.Create(ACollection: TCollection);
begin
  inherited;
  FRadius := 10000;
  FCenter := TTMSFNCMapsCoordinate.Create;
  FCenter.OnChange := @DoCenterChanged;
end;

destructor TTMSFNCMapsCircle.Destroy;
begin
  FCenter.Free;
  inherited;
end;

procedure TTMSFNCMapsCircle.DoCenterChanged(Sender: TObject);
begin
  UpdatePolyElement;
end;

function TTMSFNCMapsCircle.IsRadiusStored: Boolean;
begin
  Result := Radius <> 10000;
end;

procedure TTMSFNCMapsCircle.SetCenter(const Value: TTMSFNCMapsCoordinate);
begin
  if FCenter <> Value then
  begin
    FCenter.Assign(Value);
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCMapsCircle.SetRadius(const Value: Double);
begin
  if FRadius <> Value then
  begin
    FRadius := Value;
    UpdatePolyElement;
  end;
end;

{ TTMSFNCMapsCustomPolygon }

procedure TTMSFNCMapsCustomPolygon.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCMapsPolygon then
  begin
    FFillColor := (Source as TTMSFNCMapsPolygon).FillColor;
    FFillOpacity := (Source as TTMSFNCMapsPolygon).FillOpacity;
  end;
end;

constructor TTMSFNCMapsCustomPolygon.Create(ACollection: TCollection);
begin
  inherited;
  FFillColor := gcWhite;
  FFillOpacity := 1.0;
end;

function TTMSFNCMapsCustomPolygon.IsFillOpacityStored: Boolean;
begin
  Result := FillOpacity <> 1.0;
end;

procedure TTMSFNCMapsCustomPolygon.SetFillColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FFillColor <> Value then
  begin
    FFillColor := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCMapsCustomPolygon.SetFillOpacity(const Value: Single);
begin
  if FFillOpacity <> Value then
  begin
    FFillOpacity := Value;
    UpdatePolyElement;
  end;
end;

{ TTMSFNCMapsPolyElements }

function TTMSFNCMapsPolyElements.Add: TTMSFNCMapsPolyElement;
begin
  Result := TTMSFNCMapsPolyElement(inherited Add);
end;

procedure TTMSFNCMapsPolyElements.Clear;
var
  l: TTMSFNCCustomMaps;
  ci: TCollectionItem;
begin
  l := FOwner;
  if Assigned(l) then
    l.BeginUpdate;

  if Count > 0 then
  begin
    while Count > 0 do
    begin
      ci := TCollectionItem(Items[Count - 1]);
      ci.Free;
    end;
  end;

  if Assigned(l) then
    l.EndUpdate;
end;

constructor TTMSFNCMapsPolyElements.Create(AOwner: TTMSFNCCustomMaps);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCMapsPolyElements.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCMapsPolyElement;
end;

function TTMSFNCMapsPolyElements.GetItem(
  Index: Integer): TTMSFNCMapsPolyElement;
begin
  Result := TTMSFNCMapsPolyElement(inherited Items[Index]);
end;

function TTMSFNCMapsPolyElements.GetItemByID(
  ID: string): TTMSFNCMapsPolyElement;
var
  I: Integer;
  m: TTMSFNCMapsPolyElement;
begin
  Result := nil;
  for I := 0 to Count - 1 do
  begin
    m := Items[I];
    if m.ID = ID then
    begin
      Result := m;
      Break;
    end;
  end;
end;

function TTMSFNCMapsPolyElements.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCMapsPolyElements.Insert(
  Index: Integer): TTMSFNCMapsPolyElement;
begin
  Result := TTMSFNCMapsPolyElement(inherited Insert(Index));
end;

procedure TTMSFNCMapsPolyElements.Recreate;
var
  I: Integer;
begin
  for I := 0 to Count - 1 do
    Items[I].Recreate := True;
end;

procedure TTMSFNCMapsPolyElements.SetItem(Index: Integer;
  const Value: TTMSFNCMapsPolyElement);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCMapsPolyElements.ToCoordinateArray: TTMSFNCMapsCoordinateRecArrayArray;
var
  I: Integer;
begin
  SetLength(Result, Count);
  for I := 0 to Count - 1 do
    Result[I] := Items[I].Coordinates.ToArray;
end;

{ TTMSFNCMapsPolylines }

function TTMSFNCMapsPolylines.Add: TTMSFNCMapsPolyline;
begin
  Result := TTMSFNCMapsPolyline(inherited Add);
end;

function TTMSFNCMapsPolylines.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCMapsPolyline;
end;

function TTMSFNCMapsPolylines.GetItem(Index: Integer): TTMSFNCMapsPolyline;
begin
  Result := TTMSFNCMapsPolyline(inherited Items[Index]);
end;

function TTMSFNCMapsPolylines.Insert(Index: Integer): TTMSFNCMapsPolyline;
begin
  Result := TTMSFNCMapsPolyline(inherited Insert(Index));
end;

procedure TTMSFNCMapsPolylines.SetItem(Index: Integer;
  const Value: TTMSFNCMapsPolyline);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCMapsPolygons }

function TTMSFNCMapsPolygons.Add: TTMSFNCMapsPolygon;
begin
  Result := TTMSFNCMapsPolygon(inherited Add);
end;

function TTMSFNCMapsPolygons.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCMapsPolygon;
end;

function TTMSFNCMapsPolygons.GetItem(Index: Integer): TTMSFNCMapsPolygon;
begin
  Result := TTMSFNCMapsPolygon(inherited Items[Index]);
end;

function TTMSFNCMapsPolygons.Insert(Index: Integer): TTMSFNCMapsPolygon;
begin
  Result := TTMSFNCMapsPolygon(inherited Insert(Index));
end;

procedure TTMSFNCMapsPolygons.SetItem(Index: Integer;
  const Value: TTMSFNCMapsPolygon);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCMapsPolygons }

function TTMSFNCMapsCustomPolygons.Add: TTMSFNCMapsCustomPolygon;
begin
  Result := TTMSFNCMapsCustomPolygon(inherited Add);
end;

function TTMSFNCMapsCustomPolygons.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCMapsCustomPolygon;
end;

function TTMSFNCMapsCustomPolygons.GetItem(Index: Integer): TTMSFNCMapsCustomPolygon;
begin
  Result := TTMSFNCMapsCustomPolygon(inherited Items[Index]);
end;

function TTMSFNCMapsCustomPolygons.Insert(Index: Integer): TTMSFNCMapsCustomPolygon;
begin
  Result := TTMSFNCMapsCustomPolygon(inherited Insert(Index));
end;

procedure TTMSFNCMapsCustomPolygons.SetItem(Index: Integer;
  const Value: TTMSFNCMapsCustomPolygon);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCMapsRectangles }

function TTMSFNCMapsRectangles.Add: TTMSFNCMapsRectangle;
begin
  Result := TTMSFNCMapsRectangle(inherited Add);
end;

function TTMSFNCMapsRectangles.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCMapsRectangle;
end;

function TTMSFNCMapsRectangles.GetItem(Index: Integer): TTMSFNCMapsRectangle;
begin
  Result := TTMSFNCMapsRectangle(inherited Items[Index]);
end;

function TTMSFNCMapsRectangles.Insert(Index: Integer): TTMSFNCMapsRectangle;
begin
  Result := TTMSFNCMapsRectangle(inherited Insert(Index));
end;

procedure TTMSFNCMapsRectangles.SetItem(Index: Integer;
  const Value: TTMSFNCMapsRectangle);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCMapsRectangles.ToCoordinateArray: TTMSFNCMapsCoordinateRecArrayArray;
var
  I: Integer;
  arr: TTMSFNCMapsCoordinateRecArray;
begin
  SetLength(Result, Count);
  for I := 0 to Count - 1 do
  begin
    SetLength(arr, 2);
    arr[0] := Items[I].Bounds.NorthEast.ToRec;
    arr[1] := Items[I].Bounds.SouthWest.ToRec;
    Result[I] := arr;
  end;
end;

{ TTMSFNCMapsCircles }

function TTMSFNCMapsCircles.Add: TTMSFNCMapsCircle;
begin
  Result := TTMSFNCMapsCircle(inherited Add);
end;

function TTMSFNCMapsCircles.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCMapsCircle;
end;

function TTMSFNCMapsCircles.GetItem(Index: Integer): TTMSFNCMapsCircle;
begin
  Result := TTMSFNCMapsCircle(inherited Items[Index]);
end;

function TTMSFNCMapsCircles.Insert(Index: Integer): TTMSFNCMapsCircle;
begin
  Result := TTMSFNCMapsCircle(inherited Insert(Index));
end;

procedure TTMSFNCMapsCircles.SetItem(Index: Integer;
  const Value: TTMSFNCMapsCircle);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCMapsCircles.ToCoordinateArray: TTMSFNCMapsCoordinateRecArrayArray;
var
  I: Integer;
  arr: TTMSFNCMapsCoordinateRecArray;
begin
  SetLength(Result, Count);
  for I := 0 to Count - 1 do
  begin
    SetLength(arr, 1);
    arr[0] := Items[I].Center.ToRec;
    Result[I] := arr;
  end;
end;

{ TTMSFNCMapsLink }

constructor TTMSFNCMapsLink.CreateScript(AURL: string; AType: string = ''; ACharSet: string = ''; AContent: string = ''; ADefer: Boolean = False; AAsync: Boolean = False);
begin
  Create(mlkScript, AURL, AType, ACharSet, '', AContent, ADefer, AASync);
end;

constructor TTMSFNCMapsLink.CreateLink(AURL: string; AType: string = ''; ARel: string = '');
begin
  Create(mlkLink, AURL, AType, '', ARel, '', False, False);
end;

constructor TTMSFNCMapsLink.Create(AKind: TTMSFNCMapsLinkKind; AURL: string; AType: string; ACharSet: string; ARel: string; AContent: string; ADefer: Boolean; AAsync: Boolean);
begin
  FKind := AKind;
  FURL := AURL;
  FType := AType;
  FCharSet := ACharSet;
  FDefer := ADefer;
  FRel := ARel;
  FAsync := AAsync;
  FContent := AContent;
end;

procedure TTMSFNCCustomMaps.ReInitialize;
begin
  MapInitialized := False;
  InitializeHTML;
end;

procedure TTMSFNCCustomMaps.RouteCalculatorDeleteMarker(
  AMarker: TTMSFNCMapsMarker; AUpdateRoute: Boolean);
var
  Segment, ps: TTMSFNCRouteCalculatorSegment;
  ec: TTMSFNCMapsCoordinateRec;
begin
  if not RouteCalculatorCheck then
    Exit;

  if not Assigned(AMarker) then
    Exit;

  if Assigned(AMarker.DataPointer) and (TObject(AMarker.DataPointer) is TTMSFNCRouteCalculatorSegment) then
  begin
    Segment := (TObject(AMarker.DataPointer) as TTMSFNCRouteCalculatorSegment);
    if Assigned(Segment) then
    begin
      if (AMarker.DataString <> ROUTECALCULATORMARKER) and (AMarker.DataString <> '') then
        RouteCalculator.DeleteRouteSegment(Segment)
      else
      begin
        ps := Segment.PreviousSegment;
        if Assigned(ps) then
        begin
          ec := Segment.EndCoordinate.ToRec;
          Segment.Free;
          RouteCalculator.UpdateRouteSegment(ps, ps.StartCoordinate.ToRec, ec);
        end;
      end;
      if AUpdateRoute then
      begin
        RouteCalculatorClearRoutes;
        RouteCalculatorPlotRoutes;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomMaps.RouteCalculatorDeletePolyline(
  APolyline: TTMSFNCMapsPolyline; AUpdateRoute: Boolean);
var
  Segment: TTMSFNCRouteCalculatorSegment;
begin
  if not RouteCalculatorCheck then
    Exit;

  if not Assigned(APolyline) then
    Exit;

  if Assigned(APolyline.DataPointer) and (TObject(APolyline.DataPointer) is TTMSFNCRouteCalculatorSegment) then
  begin
    Segment := (TObject(APolyline.DataPointer) as TTMSFNCRouteCalculatorSegment);
    if Assigned(Segment) then
    begin
      RouteCalculator.DeleteRouteSegment(Segment);
      if AUpdateRoute then
      begin
        RouteCalculatorClearRoutes;
        RouteCalculatorPlotRoutes;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomMaps.RouteCalculatorDeleteSelectedMarker(
  AUpdateRoute: Boolean);
begin
  if Assigned(RouteCalculatorSelectedMarker) then
  begin
    RouteCalculatorDeleteMarker(RouteCalculatorSelectedMarker, AUpdateRoute);
    RouteCalculatorSelectedMarker := nil;
  end;
end;

procedure TTMSFNCCustomMaps.RouteCalculatorDeleteSelectedPolyline(
  AUpdateRoute: Boolean);
begin
  if Assigned(RouteCalculatorSelectedPolyline) then
  begin
    RouteCalculatorDeletePolyline(RouteCalculatorSelectedPolyline, AUpdateRoute);
    RouteCalculatorSelectedPolyline := nil;
  end;
end;

procedure TTMSFNCCustomMaps.RouteCalculatorDoCalculateRoute(Sender: TObject;
  const ARoute: TTMSFNCRouteCalculatorRoute);
begin
  RouteCalculatorPlotRoute(ARoute);
end;

procedure TTMSFNCCustomMaps.RouteCalculatorMarkerMoved(
  ACoordinate: TTMSFNCMapsCoordinateRec);
var
  ASegment, ps: TTMSFNCRouteCalculatorSegment;
begin
  if RouteCalculatorCheck then
  begin
    Options.Panning := True;
    if FRouteCalculatorDragStarted and Assigned(FRouteCalculatorMarker) then
    begin
      FRouteCalculatorDragStarted := False;
      Options.Panning := True;
      FRouteCalculatorMarker.Coordinate.Latitude := ACoordinate.Latitude;
      FRouteCalculatorMarker.Coordinate.Longitude := ACoordinate.Longitude;
      if Assigned(FRouteCalculatorMarker.DataPointer) and (TObject(FRouteCalculatorMarker.DataPointer) is TTMSFNCRouteCalculatorSegment) then
      begin
        ASegment := (TObject(FRouteCalculatorMarker.DataPointer) as TTMSFNCRouteCalculatorSegment);
        if Assigned(ASegment) then
        begin
          if not FRouteCalculatorMarker.DataBoolean then
            RouteCalculator.UpdateRouteSegment(ASegment, ASegment.StartCoordinate.ToRec, FRouteCalculatorMarker.Coordinate.ToRec)
          else
          begin
            RouteCalculator.UpdateRouteSegment(ASegment, FRouteCalculatorMarker.Coordinate.ToRec, ASegment.EndCoordinate.ToRec);
            ps := ASegment.PreviousSegment;
            if Assigned(ps) then
              RouteCalculator.UpdateRouteSegment(ps, ps.StartCoordinate.ToRec, FRouteCalculatorMarker.Coordinate.ToRec);
          end;
        end;
      end;
    end;
    FRouteCalculatorMarker := nil;
  end;
end;

procedure TTMSFNCCustomMaps.RouteCalculatorPlotRoute(
  ARoute: TTMSFNCRouteCalculatorRoute);
  function SegmentExists(ASegment: TTMSFNCRouteCalculatorSegment): TTMSFNCMapsPolyline;
  var
    I: Integer;
  begin
    Result := nil;
    for I := 0 to Polylines.Count - 1 do
    begin
      if TTMSFNCRouteCalculatorSegment(Polylines[I].DataPointer) = ASegment then
      begin
        Result := Polylines[I];
        Break;
      end;
    end;
  end;

  function WayPointExists(ASegment: TTMSFNCRouteCalculatorSegment): TTMSFNCMapsMarker;
  var
    I: Integer;
  begin
    Result := nil;
    for I := 0 to Markers.Count - 1 do
    begin
      if ((TTMSFNCRouteCalculatorSegment(Markers[I].DataPointer) = ASegment)
        and (Markers[I].DataString <> ROUTECALCULATORENDMARKER)) then
      begin
        Result := Markers[I];
        Break;
      end;
    end;
  end;

  function GetStartMarker: TTMSFNCMapsMarker;
  var
    I: Integer;
  begin
    Result := nil;
    for I := 0 to Markers.Count - 1 do
    begin
      if Markers[I].DataString = ROUTECALCULATORSTARTMARKER then
      begin
        Result := Markers[I];
        Break;
      end;
    end;
  end;

  function GetEndMarker: TTMSFNCMapsMarker;
  var
    I: Integer;
  begin
    Result := nil;
    for I := 0 to Markers.Count - 1 do
    begin
      if Markers[I].DataString = ROUTECALCULATORENDMARKER then
      begin
        Result := Markers[I];
        Break;
      end;
    end;
  end;

  procedure ConfigMarker(AMarker: TTMSFNCMapsMarker; ARoute: TTMSFNCRouteCalculatorRoute; AIndex: Integer);
  begin
    if AIndex = 0 then
    begin
      AMarker.IconURL := GetRouteCalculatorStartMarker;
      AMarker.DataString := ROUTECALCULATORSTARTMARKER;
      AMarker.DataBoolean := True;
      AMarker.DataPointer := ARoute.Segments[AIndex];
    end
    else if AIndex = ARoute.Segments.Count then
    begin
      AMarker.IconURL := GetRouteCalculatorEndMarker;
      AMarker.DataString := ROUTECALCULATORENDMARKER;
      AMarker.DataBoolean := False;
      AMarker.DataPointer := ARoute.Segments[AIndex - 1];
    end
    else
    begin
      AMarker.IconURL := GetRouteCalculatorWayPointMarker;
      AMarker.DataString := ROUTECALCULATORMARKER;
      AMarker.DataBoolean := True;
      AMarker.DataPointer := ARoute.Segments[AIndex];
    end;
  end;

var
  p: TTMSFNCMapsPolyline;
  ci: TTMSFNCMapsCoordinateItem;
  pl: TTMSFNCMapsCoordinateRecArray;
  wp: TTMSFNCMapsCoordinateRecArray;
  m: TTMSFNCMapsMarker;
  I, K: Integer;
  pRecreate: Boolean;
begin
  BeginUpdate;

  ARoute.DataString := ROUTECALCULATORROUTE;
  wp := ARoute.WayPoints;

  for I := 0 to ARoute.Segments.Count - 1 do
  begin
    p := SegmentExists(ARoute.Segments[I]);
    pl := ARoute.Segments[I].Polyline;

    if Assigned(p) then
    begin
      pRecreate := False;
      if (p.Coordinates.Count <> Length(pl)) then
        pRecreate := True
      else
        for K := 0 to Length(pl) - 1 do
        begin
          if ((p.Coordinates[K].Latitude <> pl[K].Latitude)
            or (p.Coordinates[K].Longitude <> pl[K].Longitude)) then
          begin
            pRecreate := True;
            break;
        end;
      end;

      if pRecreate then
      begin
        p.Coordinates.Clear;
        for K := 0 to Length(pl) - 1 do
        begin
          ci := p.Coordinates.Add;
          ci.Latitude := pl[K].Latitude;
          ci.Longitude := pl[K].Longitude;
        end;
        p.Recreate := True;
        DoRouteCalculatorPolylineUpdated(p, ARoute.Segments[I]);
      end;
    end
    else
    begin
      p := AddPolyline(pl);
      p.DataString := ROUTECALCULATORSEGMENT;
      p.StrokeWidth := RouteCalculator.Options.Polyline.StrokeWidth;
      p.StrokeColor := RouteCalculator.Options.Polyline.StrokeColor;
      DoRouteCalculatorPolylineAdded(p, ARoute.Segments[I]);
    end;
    p.DataPointer := ARoute.Segments[I];

    if I = 0 then
      m := GetStartMarker
    else
      m := WayPointExists(ARoute.Segments[I]);

    if Assigned(m) then
    begin
      if ((m.Latitude <> wp[I].Latitude) or (m.Longitude <> wp[I].Longitude)) then
      begin
        m.Latitude := wp[I].Latitude;
        m.Longitude := wp[I].Longitude;
        m.Recreate := True;
        ConfigMarker(m, ARoute, I);
        DoRouteCalculatorWayPointUpdated(m, ARoute.Segments[I]);
      end;
    end
    else
    begin
      m := AddMarker(ARoute.Segments[I].StartCoordinate.ToRec);
      ConfigMarker(m, ARoute, I);
      DoRouteCalculatorWayPointAdded(m, ARoute.Segments[I]);
    end;
  end;

  if ARoute.Segments.Count > 0 then
  begin
    //add end marker
    m := GetEndMarker;
    I := Length(wp) - 1;

    if Assigned(m) then
    begin
      if ((m.Latitude <> wp[I].Latitude) or (m.Longitude <> wp[I].Longitude)) then
      begin
        m.Latitude := wp[I].Latitude;
        m.Longitude := wp[I].Longitude;
        m.Recreate := True;
        ConfigMarker(m, ARoute, ARoute.Segments.Count);
        DoRouteCalculatorWayPointUpdated(m, ARoute.Segments[ARoute.Segments.Count - 1]);
      end;
    end
    else
    begin
      m := AddMarker(ARoute.Segments[ARoute.Segments.Count - 1].EndCoordinate.ToRec);
      ConfigMarker(m, ARoute, ARoute.Segments.Count);
      DoRouteCalculatorWayPointAdded(m, ARoute.Segments[ARoute.Segments.Count - 1]);
    end;
  end;

  EndUpdate;
end;

procedure TTMSFNCCustomMaps.RouteCalculatorPlotRoutes;
var
  I: Integer;
begin
  if RouteCalculatorCheck then
  begin
    BeginUpdate;
    for I := 0 to RouteCalculator.Routes.Count - 1 do
      RouteCalculatorPlotRoute(RouteCalculator.Routes[I]);
    EndUpdate;
  end;
end;

procedure TTMSFNCCustomMaps.RouteCalculatorClearRoutes;
var
  I: Integer;
  p: TTMSFNCMapsPolyline;
  m: TTMSFNCMapsMarker;
begin
  if RouteCalculatorCheck then
  begin
    BeginUpdate;
    for I := Polylines.Count - 1 downto 0 do
    begin
      p := Polylines[I];
      if p.DataString = ROUTECALCULATORSEGMENT then
        p.Free;
    end;

    for I := Markers.Count - 1 downto 0 do
    begin
      m := Markers[I];
      if (m.DataString = ROUTECALCULATORMARKER)
        or (m.DataString = ROUTECALCULATORSTARTMARKER)
        or (m.DataString = ROUTECALCULATORENDMARKER) then
        m.Free;
    end;
    EndUpdate;
  end;
end;

procedure TTMSFNCCustomMaps.RouteCalculatorClearSelected;
begin
  if Assigned(RouteCalculatorSelectedPolyline) then
  begin
    RouteCalculatorSelectedPolyline.StrokeColor := RouteCalculator.Options.Polyline.StrokeColor;
    RouteCalculatorSelectedPolyline := nil;
  end;

  if Assigned(RouteCalculatorSelectedMarker) then
  begin
    if (RouteCalculatorSelectedMarker.DataString = ROUTECALCULATORMARKER) then
      RouteCalculatorSelectedMarker.IconURL := GetRouteCalculatorWayPointMarker
    else if (RouteCalculatorSelectedMarker.DataString = ROUTECALCULATORSTARTMARKER) then
      RouteCalculatorSelectedMarker.IconURL := GetRouteCalculatorStartMarker
    else if (RouteCalculatorSelectedMarker.DataString = ROUTECALCULATORENDMARKER) then
      RouteCalculatorSelectedMarker.IconURL := GetRouteCalculatorEndMarker;
    RouteCalculatorSelectedMarker := nil;
  end;
end;

procedure TTMSFNCCustomMaps.RouteCalculatorPolylineMoved(
  ACoordinate: TTMSFNCMapsCoordinateRec);
var
  ASegment: TTMSFNCRouteCalculatorSegment;
  ar: TTMSFNCMapsCoordinateRecArray;
begin
  if RouteCalculatorCheck then
  begin
    Options.Panning := True;
    if FRouteCalculatorDragStarted and Assigned(FRouteCalculatorPolyline) then
    begin
      FRouteCalculatorDragStarted := False;
      Options.Panning := True;
      if Assigned(FRouteCalculatorPolyline.DataPointer) and (TObject(FRouteCalculatorPolyline.DataPointer) is TTMSFNCRouteCalculatorSegment) then
      begin
        ASegment := (TObject(FRouteCalculatorPolyline.DataPointer) as TTMSFNCRouteCalculatorSegment);
        if Assigned(ASegment) then
        begin
          SetLength(ar, 1);
          ar[0] := ACoordinate;
          RouteCalculator.AddWayPointsToSegment(ASegment, ar);
        end;
      end;
    end;

    FRouteCalculatorPolyline := nil;
    if Assigned(FRouteCalculatorDragMarker) then
    begin
      FRouteCalculatorDragMarker.Free;
      FRouteCalculatorDragMarker := nil
    end;

  end;
end;

function TTMSFNCCustomMaps.ParseScript(AValue: string): string;
begin
  Result := AValue;
  if Result = 'null' then
  begin
    Result := '';
    Exit;
  end;

  Result := StringReplace(Result, '[', '', [rfReplaceAll]);
  Result := StringReplace(Result, ']', '', [rfReplaceAll]);
  Result := StringReplace(Result, '"', '', [rfReplaceAll]);
end;

function TTMSFNCCustomMaps.ParseEvent(AValue: string): Boolean;
var
  s, cd: string;
  b: Boolean;
  ed: TTMSFNCMapsEventData;
  p, pcd: Integer;
begin
  s := AValue;
  b := (Pos(EVENTDATAPREFIX, s) = 1);
  Result := b;
  if b then
  begin
    p := Pos(EVENTDATAPREFIX, s);
    pcd := Pos(CUSTOMDATAPREFIX, s);
    if pcd > 0 then
    begin
      cd := Copy(s, pcd + Length(CUSTOMDATAPREFIX), Length(s) - pcd - 1);
      s := Copy(s, p + Length(EVENTDATAPREFIX), pcd - Length(EVENTDATAPREFIX) - 1);
    end
    else
    begin
      s := Copy(s, p + Length(EVENTDATAPREFIX), Length(s) - 1);
      cd := '';
    end;

    if cd <> '' then
      cd := TTMSFNCUtils.URLDecode(cd);

    s := TTMSFNCUtils.URLDecode(s);

    ed := TTMSFNCMapsEventData.Create;
    try
      ed.JSON := s;
      ed.CustomData := cd;

      if ed.ID <> '' then
      begin
        if Pos('Marker', ed.EventName) > 0 then
          ed.FMarker := GetMarkerByID(ed.ID);

        if Pos('PolyElement', ed.EventName) > 0 then
          ed.FPolyElement := GetPolyElementByID(ed.ID);
      end;

      if ed.EventName = 'Initialized' then
      begin
        MapInitialized := True;
        DoMapInitialized;
      end
      else if ed.EventName = 'MapTypeChanged' then
        DoMapTypeChanged(ed)
      else if ed.EventName = 'ZoomChanged' then
        DoZoomChanged(ed)
      else if ed.EventName = 'MapMoveStart' then
        DoMapMoveStart(ed)
      else if ed.EventName = 'MapMoveEnd' then
        DoMapMoveEnd(ed)
//      else if ed.EventName = 'MapMove' then
//        DoMapMove(ed)
      else if ed.EventName = 'MapClick' then
        DoMapClick(ed)
      else if ed.EventName = 'MapRightClick' then
        DoMapRightClick(ed)
      else if ed.EventName = 'MapKeyDown' then
        DoMapKeyDown(ed)
      else if ed.EventName = 'MapDblClick' then
        DoMapDblClick(ed)
      else if ed.EventName = 'MapMouseMove' then
        DoMapMouseMove(ed)
      else if ed.EventName = 'MapMouseDown' then
        DoMapMouseDown(ed)
      else if ed.EventName = 'MapMouseUp' then
        DoMapMouseUp(ed)
      else if ed.EventName = 'MapMouseEnter' then
        DoMapMouseEnter(ed)
      else if ed.EventName = 'MapMouseLeave' then
        DoMapMouseLeave(ed)
      else if ed.EventName = 'MarkerClick' then
        DoMarkerClick(ed)
      else if ed.EventName = 'MarkerRightClick' then
        DoMarkerRightClick(ed)
      else if ed.EventName = 'MarkerDblClick' then
        DoMarkerDblClick(ed)
      else if ed.EventName = 'MarkerMouseDown' then
        DoMarkerMouseDown(ed)
      else if ed.EventName = 'MarkerMouseUp' then
        DoMarkerMouseUp(ed)
      else if ed.EventName = 'MarkerMouseEnter' then
        DoMarkerMouseEnter(ed)
      else if ed.EventName = 'MarkerMouseLeave' then
        DoMarkerMouseLeave(ed)
      else if ed.EventName = 'PolyElementClick' then
        DoPolyElementClick(ed)
      else if ed.EventName = 'PolyElementRightClick' then
        DoPolyElementRightClick(ed)
      else if ed.EventName = 'PolyElementDblClick' then
        DoPolyElementDblClick(ed)
      else if ed.EventName = 'PolyElementMouseDown' then
        DoPolyElementMouseDown(ed)
      else if ed.EventName = 'PolyElementMouseUp' then
        DoPolyElementMouseUp(ed)
      else if ed.EventName = 'PolyElementMouseEnter' then
        DoPolyElementMouseEnter(ed)
      else if ed.EventName = 'PolyElementMouseLeave' then
        DoPolyElementMouseLeave(ed)
      else
      begin
        CallCustomEvent(ed);
      end;
    finally
      ed.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMaps.Clear;
begin
  BeginUpdate;
  ClearMarkers;
  ClearPolygons;
  ClearCircles;
  ClearLabels;
  ClearElementContainers;
  ClearRectangles;
  ClearPolylines;
  EndUpdate;
end;

procedure TTMSFNCCustomMaps.ClearMarkers;
begin
  BeginUpdate;
  Markers.Clear;
  EndUpdate;
end;

procedure TTMSFNCCustomMaps.ClearPolylines;
begin
  BeginUpdate;
  Polylines.Clear;
  EndUpdate;
end;

procedure TTMSFNCCustomMaps.ClearPolygons;
begin
  BeginUpdate;
  Polygons.Clear;
  EndUpdate;
end;

procedure TTMSFNCCustomMaps.ClearRectangles;
begin
  BeginUpdate;
  Rectangles.Clear;
  EndUpdate;
end;

procedure TTMSFNCCustomMaps.ClearCircles;
begin
  BeginUpdate;
  Circles.Clear;
  EndUpdate;
end;

procedure TTMSFNCCustomMaps.ClearElementContainers;
begin
  BeginUpdate;
  ElementContainers.Clear;
  EndUpdate;
end;

procedure TTMSFNCCustomMaps.ClearHeadLinks;
begin
  BeginUpdate;
  HeadLinks.Clear;
  EndUpdate;
end;

procedure TTMSFNCCustomMaps.ClearLabels;
begin
  BeginUpdate;
  Labels.Clear;
  EndUpdate;
end;

procedure TTMSFNCCustomMaps.SetCenterCoordinate(ALatitude: Double; ALongitude: Double);
begin
  SetCenterCoordinate(CreateCoordinate(ALatitude, ALongitude));
end;

procedure TTMSFNCCustomMaps.SetCenterCoordinate(ACoordinate: TTMSFNCMapsCoordinateRec);
var
  b: TTMSFNCMapsCoordinate;
begin
  if MapInitialized and MapReady then
  begin
    b := TTMSFNCMapsCoordinate.Create(ACoordinate);
    try
      ExecuteJavaScript(SETCENTERCOORDINATEFUNCTION + '(' + b.ToJSON + ')');
    finally
      b.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMaps.ZoomToBounds(ANorthEast: TTMSFNCMapsCoordinateRec; ASouthWest: TTMSFNCMapsCoordinateRec);
var
  b: TTMSFNCMapsBounds;
begin
  if MapInitialized and MapReady then
  begin
    b := TTMSFNCMapsBounds.Create(ANorthEast, ASouthWest);
    try
      ExecuteJavaScript(ZOOMTOBOUNDSFUNCTION + '(' + b.ToJSON + ')');
    finally
      b.Free;
    end;
  end;
end;

{ TTMSFNCMaps }

procedure TTMSFNCMaps.RegisterRuntimeClasses;
begin
  RegisterClass(TTMSFNCMaps);
  RegisterClass(TTMSFNCMapsMarker);
  RegisterClass(TTMSFNCMapsPolyElement);
  RegisterClass(TTMSFNCMapsPolyline);
  RegisterClass(TTMSFNCMapsPolygon);
end;

function GetFirstChildNode(const ANode: TTMSFNCMapsXMLNode): TTMSFNCMapsXMLDomNode;
begin
  {$IFNDEF LCLWEBLIB}
  Result := ANode.DOMNode.firstChild;
  {$ELSE}
  Result := ANode.firstChild;
  {$ENDIF}
end;

function FindNodeAttribute(const ANode: TTMSFNCMapsXMLDOMNode; const ANodeName: string): TTMSFNCMapsXMLDomNode;
begin
  {$IFDEF WEBLIB}
  asm
    if (ANode.attributes){
      return ANode.attributes.getNamedItem(ANodeName);
    }else{
      return undefined;
    }
  end;
  {$ELSE}
  Result := ANode.Attributes.GetNamedItem(ANodeName);
  {$ENDIF}
end;

function FindNode(const ANode: TTMSFNCMapsXMLNode; const ANodeName: string): TTMSFNCMapsXMLNode;
begin
  {$IFNDEF LCLLIB}
  Result := ANode.ChildNodes.FindNode(ANodeName);
  {$ELSE}
  Result := ANode.FindNode(ANodeName);
  {$ENDIF}
end;

function FindDOMNode(const ANode: TTMSFNCMapsXMLDOMNode; const ANodeName: string): TTMSFNCMapsXMLDOMNode;
var
  I: Integer;
  rNode: TTMSFNCMapsXMLDOMNode;
begin
  {$IFNDEF LCLLIB}
  Result := nil;
  for I := 0 to ANode.childNodes.length - 1 do
  begin
    rNode := ANode.childNodes[I];
    if rNode.NodeName = ANodeName then
      Result := rNode;
  end;
  {$ELSE}
  Result := ANode.FindNode(ANodeName);
  {$ENDIF}
end;

function NodeToString(ANode: TTMSFNCMapsXMLDOMNode): string;
begin
  Result := '';
  if not Assigned(ANode) then
    Exit;

  Result := ANode.firstChild.nodeValue;
end;

{$IFNDEF WEBLIB}
function TTMSFNCCustomMaps.LoadGPXFromFile(AFileName: string; AAutoDisplay: Boolean = True;
  AZoomToBounds: Boolean = True; AStrokeWidth: Integer = 3; AStrokeColor: TTMSFNCGraphicsColor = gcBlack; ADisplayElevation: Boolean = False;
  ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec;
var
  ms: TMemoryStream;
begin
  ms := TMemoryStream.Create;
  try
    ms.LoadFromFile(AFileName);
    Result := LoadGPXFromStream(ms, AAutoDisplay, AZoomToBounds, AStrokeWidth, AStrokeColor, ADisplayElevation, ADisplayTimeStamps, AMinSecondsBetweenTimeStamps);
  finally
    ms.Free;
  end;
end;

function TTMSFNCCustomMaps.LoadGPXFromFile(AFileName: string; AAutoDisplay: Boolean;
  AZoomToBounds: Boolean; AStrokeWidth: Integer; AElevationColors: TTMSFNCMapsGPXColorRecArray; ADisplayElevation: Boolean;
  ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec;
var
  ms: TMemoryStream;
begin
  ms := TMemoryStream.Create;
  try
    ms.LoadFromFile(AFileName);
    Result := LoadGPXFromStream(ms, AAutoDisplay, AZoomToBounds, AStrokeWidth, AElevationColors, ADisplayElevation, ADisplayTimeStamps, AMinSecondsBetweenTimeStamps);
  finally
    ms.Free;
  end;
end;

{$ENDIF}

function TTMSFNCCustomMaps.LoadGPXFromStream(const AStream: TStream; AAutoDisplay: Boolean = True;
  AZoomToBounds: Boolean = True; AStrokeWidth: Integer = 3; AStrokeColor: TTMSFNCGraphicsColor = gcBlack; ADisplayElevation: Boolean = False;
  ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec;
var
  s: TStringStream;
begin
  s := TStringStream.Create('');
  try
    AStream.Position := 0;
    s.CopyFrom(AStream, AStream.Size);
    Result := LoadGPXFromText(s.DataString, AAutoDisplay, AZoomToBounds, AStrokeWidth, AStrokeColor, ADisplayElevation, ADisplayTimeStamps, AMinSecondsBetweenTimeStamps);
  finally
    s.Free;
  end;
end;

function TTMSFNCCustomMaps.LoadGPXFromStream(const AStream: TStream; AAutoDisplay: Boolean;
  AZoomToBounds: Boolean; AStrokeWidth: Integer; AElevationColors: TTMSFNCMapsGPXColorRecArray; ADisplayElevation: Boolean;
  ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec;
var
  s: TStringStream;
begin
  s := TStringStream.Create('');
  try
    AStream.Position := 0;
    s.CopyFrom(AStream, AStream.Size);
    Result := LoadGPXFromText(s.DataString, AAutoDisplay, AZoomToBounds, AStrokeWidth, AElevationColors, ADisplayElevation, ADisplayTimeStamps, AMinSecondsBetweenTimeStamps);
  finally
    s.Free;
  end;
end;

function TTMSFNCCustomMaps.LoadGPXFromText(AText: string; AAutoDisplay: Boolean = True;
  AZoomToBounds: Boolean = True; AStrokeWidth: Integer = 3; AStrokeColor: TTMSFNCGraphicsColor = gcBlack; ADisplayElevation: Boolean = False;
  ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec;
begin
  Result := InternalLoadGPX(AText, AAutoDisplay, AZoomToBounds, AStrokeWidth, AStrokeColor, ADisplayElevation, ADisplayTimeStamps, AMinSecondsBetweenTimeStamps);
end;

function TTMSFNCCustomMaps.LoadGPXFromText(AText: string; AAutoDisplay: Boolean;
  AZoomToBounds: Boolean; AStrokeWidth: Integer; AElevationColors: TTMSFNCMapsGPXColorRecArray; ADisplayElevation: Boolean;
  ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec;
begin
  Result := InternalLoadGPX(AText, AAutoDisplay, AZoomToBounds, AStrokeWidth, AElevationColors, ADisplayElevation, ADisplayTimeStamps, AMinSecondsBetweenTimeStamps);
end;

procedure TTMSFNCCustomMaps.Loaded;
begin
  inherited;
  BeginUpdate;
  EndUpdate;
end;

{$IFNDEF WEBLIB}
function TTMSFNCCustomMaps.LoadGeoJSONFromFile(
  AFileName: string; AAutoDisplay: Boolean = True; AZoomToBounds: Boolean = True): TTMSFNCMapsGeoJSONRec;
var
  ms: TMemoryStream;
begin
  ms := TMemoryStream.Create;
  try
    ms.LoadFromFile(AFileName);
    Result := LoadGeoJSONFromStream(ms, AAutoDisplay, AZoomToBounds);
  finally
    ms.Free;
  end;
end;
{$ENDIF}

function TTMSFNCCustomMaps.LoadGeoJSONFromStream(
  const AStream: TStream; AAutoDisplay: Boolean = True; AZoomToBounds: Boolean = True): TTMSFNCMapsGeoJSONRec;
var
  s: TStringStream;
begin
  s := TStringStream.Create('');
  try
    AStream.Position := 0;
    s.CopyFrom(AStream, AStream.Size);
    Result := LoadGeoJSONFromText(s.DataString, AAutoDisplay, AZoomToBounds);
  finally
    s.Free;
  end;
end;

function TTMSFNCCustomMaps.LoadGeoJSONFromText(
  AText: string; AAutoDisplay: Boolean = True; AZoomToBounds: Boolean = True): TTMSFNCMapsGeoJSONRec;
begin
  Result := InternalLoadGeoJSON(AText, AAutoDisplay, AZoomToBounds);
end;

function TTMSFNCCustomMaps.InternalLoadGeoJSON(
  AText: string; AAutoDisplay: Boolean = True; AZoomToBounds: Boolean = True): TTMSFNCMapsGeoJSONRec;
var
  jv, jvv: TJSONValue;
  ja: TJSONArray;
  f, I, J: Integer;
  coords: TTMSFNCMapsCoordinateRecArrayArray;
  minlon, maxlon, minlat, maxlat, lon, lat: double;
  typeid: string;
  featname: string;
  path, allpath: TTMSFNCMapsCoordinateRecArray;
  p: TTMSFNCMapsPolygon;

  procedure AddCoords(arr: TJSONArray);
  begin
    SetLength(coords[Length(coords) - 1], Length(coords[Length(coords) - 1]) + 1);

    if TTMSFNCUtils.GetJSONArraySize(arr) in [2,3] then
    begin
      lon := TTMSFNCUtils.GetJSONValueAsDouble(TTMSFNCUtils.GetJSONArrayItem(arr, 0));
      lat := TTMSFNCUtils.GetJSONValueAsDouble(TTMSFNCUtils.GetJSONArrayItem(arr, 1));
      coords[Length(coords) - 1][Length(coords[Length(coords) - 1]) - 1].Longitude := lon;
      coords[Length(coords) - 1][Length(coords[Length(coords) - 1]) - 1].Latitude := lat;

      if lon > maxlon then
        maxlon := lon;
      if lon < minlon then
        minlon := lon;
      if lat > maxlat then
        maxlat := lat;
      if lat < minlat then
        minlat := lat;
    end;
  end;

  procedure AddCoordArray;
  begin
    SetLength(coords, Length(coords) + 1);
  end;

  procedure ParseFeature(AValue: TJSONValue);
  var
    l, j, k, m: integer;
    jp, geojsondata: TJSONValue;
    jaf, jac, jacc: TJSONArray;
    geoJSONEventData: TTMSFNCMapsGeoJSONEventData;
  begin
    SetLength(coords, 0);

    jp := TTMSFNCUtils.GetJSONValue(AValue, 'name');
    if not Assigned(jp) then
      jp := TTMSFNCUtils.GetJSONValue(AValue, 'properties');

    if Assigned(jp) then
      featname := TTMSFNCUtils.GetJSONProp(jp, 'name');

    // get geometry of each feature
    jp := TTMSFNCUtils.GetJSONValue(AValue, 'geometry');
    if Assigned(jp) then
    begin
      geojsondata := jp;
      typeid := Uppercase(TTMSFNCUtils.GetJSONProp(jp, 'type'));

      if (typeid = 'POLYGON') or (typeid = 'MULTIPOLYGON') or (typeid = 'LINESTRING') or (typeid = 'MULTILINESTRING') or (typeid = 'POINT') then
      begin
        jp := TTMSFNCUtils.GetJSONValue(jp, 'coordinates');

        if Assigned(jp) and (jp is TJSONArray) then
        begin
          jaf := jp as TJSONArray;

          if (TTMSFNCUtils.GetJSONArraySize(jaf) in [2,3]) and not (TTMSFNCUtils.GetJSONArrayItem(jaf, 0) is TJSONArray) then
          begin
            AddCoords(jaf)
          end
          else
          begin
            for l := 0 to TTMSFNCUtils.GetJSONArraySize(jaf) - 1 do
            begin
              jac := TTMSFNCUtils.GetJSONArrayItem(jaf, l) as TJSONArray;
              AddCoordArray;

              if (TTMSFNCUtils.GetJSONArraySize(jac) in [2,3]) and not (TTMSFNCUtils.GetJSONArrayItem(jac, 0) is TJSONArray) then
              begin
                AddCoords(jac);
              end
              else
              begin
                for j := 0 to TTMSFNCUtils.GetJSONArraySize(jac) - 1 do
                begin
                  if TTMSFNCUtils.GetJSONArrayItem(jac, j) is TJSONArray then
                  begin
                    jacc := TTMSFNCUtils.GetJSONArrayItem(jac, j) as TJSONArray;
                    AddCoords(jacc);
                  end;
                end;
              end;
            end;
          end;
        end;

        SetLength(path, 0);
        for k := 0 to Length(coords[0]) - 1 do
        begin
          SetLength(path, Length(path) + 1);
          path[Length(path) - 1] := CreateCoordinate(coords[0][k].Latitude, coords[0][k].Longitude);

          SetLength(allpath, Length(allpath) + 1);
          allpath[Length(allpath) - 1] := CreateCoordinate(coords[0][k].Latitude, coords[0][k].Longitude);
        end;

        geoJSONEventData := TTMSFNCMapsGeoJSONEventData.Create;
        geoJSONEventData.JSONValue := geojsondata;

        if typeid = 'POINT' then
        begin
          SetLength(Result.Points, Length(Result.Points) + 1);
          Result.Points[Length(Result.Points) - 1].Coordinate := path[0];
          Result.Points[Length(Result.Points) - 1].Name := featname;
          geoJSONEventData.Point := Result.Points[Length(Result.Points) - 1].Coordinate;
        end
        else if (typeid = 'LINESTRING') or (typeid = 'MULTILINESTRING') then
        begin
          SetLength(Result.Polylines, Length(Result.Polylines) + 1);
          Result.Polylines[Length(Result.Polylines) - 1].Coordinates := path;
          Result.Polylines[Length(Result.Polylines) - 1].Name := featname;
          geoJSONEventData.Polyline := Result.Polylines[Length(Result.Polylines) - 1].Coordinates;
        end
        else
        begin
          SetLength(Result.Polygons, Length(Result.Polygons) + 1);
          Result.Polygons[Length(Result.Polygons) - 1].Coordinates := path;
          Result.Polygons[Length(Result.Polygons) - 1].Name := featname;
          for m := 1 to Length(coords) - 1 do
          begin
            SetLength(Result.Polygons[Length(Result.Polygons) - 1].Holes, Length(Result.Polygons[Length(Result.Polygons) - 1].Holes) + 1);
            Result.Polygons[Length(Result.Polygons) - 1].Holes[m - 1] := coords[m];
          end;
          geoJSONEventData.Polygon := Result.Polygons[Length(Result.Polygons) - 1].Coordinates;
        end;

        try
          DoCreateGeoJSONObject(geoJSONEventData);
        finally
          geoJSONEventData.Free;
        end;

      end;
    end;
  end;

begin
  jv := TTMSFNCUtils.ParseJSON(AText);

  if Assigned(jv) then
  begin
    try
      jvv := TTMSFNCUtils.GetJSONValue(jv, 'features');

      minlon := +1000;
      maxlon := -1000;
      minlat := +1000;
      maxlat := -1000;

      if Assigned(jvv) and (jvv is TJSONArray) then
      begin
        ja := jvv as TJSONArray;
        for f := 0 to TTMSFNCUtils.GetJSONArraySize(ja) - 1 do
          ParseFeature(TTMSFNCUtils.GetJSONArrayItem(ja, f));
      end
      else
        ParseFeature(jv);
    finally
      jv.Free;
    end;

    if AAutoDisplay then
    begin
      for I := 0 to Length(Result.Points) - 1 do
        AddMarker(Result.Points[I].Coordinate);
      for I := 0 to Length(Result.Polylines) - 1 do
        AddPolyline(Result.Polylines[I].Coordinates);
      for I := 0 to Length(Result.Polygons) - 1 do
      begin
        p := AddPolygon(Result.Polygons[I].Coordinates);
        for J := 0 to Length(Result.Polygons[I].Holes) - 1 do
        begin
          p.AddHole(Result.Polygons[I].Holes[J]);
        end;
      end;
      if AZoomToBounds then
        ZoomToBounds(allpath);
    end;
  end;
end;

function TTMSFNCCustomMaps.InternalLoadGPX(AText: string; AAutoDisplay: Boolean = True;
  AZoomToBounds: Boolean = True; AStrokeWidth: Integer = 3; AStrokeColor: TTMSFNCGraphicsColor = gcBlack; ADisplayElevation: Boolean = False;
  ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec;
var
  AElevationColors: TTMSFNCMapsGPXColorRecArray;
begin
  SetLength(AElevationColors, 1);
  AElevationColors[0].Color := AStrokeColor;

  Result := InternalLoadGPX(AText, AAutoDisplay, AZoomToBounds, AStrokeWidth, AElevationColors, ADisplayElevation, ADisplayTimeStamps, AMinSecondsBetweenTimeStamps);
end;

function TTMSFNCCustomMaps.InternalLoadGPX(AText: string; AAutoDisplay: Boolean = True;
  AZoomToBounds: Boolean = True; AStrokeWidth: Integer = 3; AElevationColors: TTMSFNCMapsGPXColorRecArray = nil; ADisplayElevation: Boolean = False;
  ADisplayTimeStamps: Boolean = False; AMinSecondsBetweenTimeStamps: Integer = 600): TTMSFNCMapsGPXRec;
var
  xmldoc: TTMSFNCMapsXMLDocument;
  I, J, K, L: Integer;
  iNode, nNode, sNode: TTMSFNCMapsXMLNode;
  snlat, snlng, cNode, pNode, eNode: TTMSFNCMapsXMLDomNode;
  v: Double;
  parentNode: TTMSFNCMapsXMLNode;
  gpx11: Boolean;
  cnt, cntTr, cntSeg: Integer;
  {$IFDEF LCLLIB}
  ss: TStringStream;
  {$ENDIF}
  allpath, arr: TTMSFNCMapsCoordinateRecArray;
  nodeName, timeStamp: string;
  ele, maxHeight, minHeight, divHeight: Double;
  heightFound: Boolean;
  p: TTMSFNCMapsPolyline;
  prevColor: TTMSFNCGraphicsColor;
  clr: TTMSFNCMapsGPXColorRec;
  clrs: TTMSFNCMapsGPXColorRecArray;
  startTime, endTime: TDateTime;
  grade, prevGrade, gradeCount: Integer;
  trackEventData: TTMSFNCMapsGPXTrackEventData;
  segmentEventData: TTMSFNCMapsGPXSegmentEventData;
begin
  {$IFDEF LCLLIB}
  ss := TStringStream.Create(AText);
  try
    ReadXMLFile(xmldoc, ss);
  finally
    ss.Free;
  end;
  {$ELSE}
  {$IFDEF WEBLIB}
  asm
    var parser = new DOMParser();
    xmldoc = parser.parseFromString(AText, 'text/xml');
  end;
  {$ELSE}
  xmldoc := TXmlDocument.Create(nil);
  xmldoc.LoadFromXML(AText);
  {$ENDIF}
  {$ENDIF}

  parentNode := xmldoc.DocumentElement;

  gpx11 := false;
  cntTr := -1;
  minHeight := 0;
  maxHeight := 0;
  endTime := 0;

  if Assigned(parentNode) then
  begin
    iNode := FindNode(parentNode, 'trk');

    if not Assigned(iNode) then
    begin
      iNode := FindNode(parentNode, 'rte');
      gpx11 := true;
    end;

    repeat
      if Assigned(iNode) then
      begin
        cntSeg := -1;
        inc(cntTr);

        SetLength(Result.Tracks, cntTr + 1);

        nNode := FindNode(iNode, 'name');
        if Assigned(nNode) {$IFNDEF LCLWEBLIB} and Assigned(nNode.DOMNode){$ENDIF} then
          Result.Tracks[cntTr].Name := nNode{$IFNDEF LCLWEBLIB}.DOMNode{$ENDIF}.nodeName;

        trackEventData := TTMSFNCMapsGPXTrackEventData.Create;
        trackEventData.Node := iNode{$IFNDEF LCLWEBLIB}.DOMNode{$ENDIF};
        trackEventData.Data := Result.Tracks[cntTr];
        try
        DoCreateGPXTrack(trackEventData);
        finally
          trackEventData.Free;
        end;

        if not gpx11 then
          sNode := FindNode(iNode, 'trkseg')
        else
          sNode := iNode;

        repeat
          if Assigned(sNode) and sNode.HasChildNodes then
          begin
            cnt := -1;
            inc(cntSeg);
            SetLength(Result.Tracks[cntTr].Segments, cntSeg + 1);
            heightFound := false;

            cNode := GetFirstChildNode(sNode);

            while Assigned(cNode) do
            begin
              pNode := cNode;
              nodeName := pNode.NodeName;

              if not gpx11 or (gpx11 and (pNode.NodeName = 'rtept')) then
              begin
                snlat := FindNodeAttribute(pNode, 'lat');
                snlng := FindNodeAttribute(pNode, 'lon');

                if Assigned(snlat) and Assigned(snlng) then
                begin
                  inc(cnt);
                  SetLength(Result.Tracks[cntTr].Segments[cntSeg], cnt + 1);

                  if nodeName = 'trkpt' then
                  begin
                    eNode := FindDomNode(pNode, 'ele');
                    if Assigned(eNode) then
                    begin
                      ele := 0;
                      if TryStrToFloatDot(eNode.firstChild.nodeValue, ele) then
                      begin
                        if heightFound then
                        begin
                          if ele > maxHeight then
                            maxHeight := ele;
                          if ele < minHeight then
                            minHeight := ele;
                        end
                        else
                        begin
                          heightFound := true;
                          maxHeight := ele;
                          minHeight := ele;
                        end;

                        Result.Tracks[cntTr].Segments[cntSeg][cnt].Elevation := ele;
                        Result.Tracks[cntTr].Segments[cntSeg][cnt].HasElevation := True;
                      end;
                    end;

                    eNode := FindDOMNode(pNode, 'time');
                    if Assigned(eNode) then
                    begin
                      timestamp := eNode.firstChild.nodeValue;
                      if timestamp <> '' then
                      begin
                        Result.Tracks[cntTr].Segments[cntSeg][cnt].TimeStamp := TTMSFNCUtils.ISOToDateTime(timestamp, True);
                        Result.Tracks[cntTr].Segments[cntSeg][cnt].HasTimeStamp := True;
                      end;
                    end;
                  end;

                  v := 0;
                  if TryStrToFloatDot(snlat.nodeValue, v) then
                    Result.Tracks[cntTr].Segments[cntSeg][cnt].Latitude := v;

                  v := 0;
                  if TryStrToFloatDot(snlng.nodeValue, v) then
                    Result.Tracks[cntTr].Segments[cntSeg][cnt].Longitude := v;

                  SetLength(allpath, Length(allpath) + 1);
                  allpath[Length(allpath) - 1] := CreateCoordinate(Result.Tracks[cntTr].Segments[cntSeg][cnt].Latitude, Result.Tracks[cntTr].Segments[cntSeg][cnt].Longitude);

                  segmentEventData := TTMSFNCMapsGPXSegmentEventData.Create;
                  segmentEventData.Node := pNode;
                  segmentEventData.Data := Result.Tracks[cntTr].Segments[cntSeg][cnt];
                  try
                    DoCreateGPXSegment(segmentEventData);
                  finally
                    segmentEventData.Free;
                  end;
                end;
              end;

              cNode := cNode.nextSibling;
            end;
          end;

        if Assigned(sNode) then
          sNode := sNode.NextSibling;

        until (not Assigned(sNode));
      end;

      if Assigned(iNode) then
        iNode := iNode.NextSibling;
    until (not Assigned(iNode));
  end;

  if AAutoDisplay then
  begin
    if Assigned(AElevationColors) then
    begin
      SetLength(clrs, Length(AElevationColors));
      clrs := AElevationColors;
    end
    else
    begin
      SetLength(clrs, 5);
      clr.Color := gcBlack;
      clrs[0] := clr;
      clr.Color := gcGreen;
      clrs[1] := clr;
      clr.Color := gcYellow;
      clrs[2] := clr;
      clr.Color := gcOrange;
      clrs[3] := clr;
      clr.Color := gcRed;
      clrs[4] := clr;
    end;
    color := clrs[0].Color;
    prevColor := color;
    grade := 0;
    prevGrade := grade;

    BeginUpdate;
    for I := 0 to Length(Result.Tracks) - 1 do
    begin
      for J := 0 to Length(Result.Tracks[I].Segments) - 1 do
      begin

        if ADisplayElevation or ADisplayTimeStamps then
        begin
          for K := 0 to Length(Result.Tracks[I].Segments[J]) - 1 do
          begin

            if ADisplayTimeStamps and (AMinSecondsBetweenTimeStamps > 0) then
            begin
              startTime := Result.Tracks[I].Segments[J][K].TimeStamp;
              if (startTime <> endTime) and (starttime <> 0) and (SecondsBetween(startTime, endTime) > AMinSecondsBetweenTimeStamps) then
              begin
                endTime := startTime;
                AddMarker(Result.Tracks[I].Segments[J][K + 1], DateTimeToStr(endTime));
              end;
            end;

            if ADisplayElevation then
            begin
              gradeCount := 5;
              if Length(clrs) > 1 then
                gradeCount := Length(clrs);

              divHeight := (maxHeight - minHeight) / gradeCount;

              grade := 0;
              for L := 0 to gradeCount - 1 do
              begin
                if Result.Tracks[I].Segments[J][K].Elevation > (minHeight + (divHeight * L)) then
                  grade := L;
              end;

              SetLength(arr, Length(arr) + 1);
              arr[Length(arr) - 1] := Result.Tracks[I].Segments[J][K];

              if Length(clrs) > 1 then
                color := clrs[grade].Color
              else
                color := Lighter(clrs[0].Color, (grade * Round(100 / gradeCount)));

              if (grade <> prevGrade) or (K = Length(Result.Tracks[I].Segments[J]) - 2) then
              begin
                prevGrade := grade;

                SetLength(arr, Length(arr) + 1);
                arr[Length(arr) - 1] := Result.Tracks[I].Segments[J][K + 1];

                p := AddPolyline(arr);
                p.StrokeColor := color;
                p.StrokeWidth := AStrokeWidth;
                SetLength(arr, 0);
                //WEB workaround
                arr := nil;
              end;

            end;
          end;
        end
        else
        begin
          p := AddPolyline(Result.Tracks[I].Segments[J]);
          p.StrokeColor := color;
          p.StrokeWidth := AStrokeWidth;
        end;
      end;
    end;
    EndUpdate;

    if AZoomToBounds then
      ZoomToBounds(allpath);
  end;
end;

procedure TTMSFNCCustomMaps.ZoomToBounds(ACoordinates: TTMSFNCMapsCoordinateRecArray);
begin
  ZoomToBounds(CreateBounds(ACoordinates));
end;

procedure TTMSFNCCustomMaps.ZoomToBounds(ACoordinates: TTMSFNCMapsCoordinateRecArrayArrayArray);
begin
  ZoomToBounds(CreateBounds(ACoordinates));
end;

procedure TTMSFNCCustomMaps.ZoomToBounds(ACoordinates: TTMSFNCMapsCoordinateRecArrayArray);
begin
  ZoomToBounds(CreateBounds(ACoordinates));
end;

procedure TTMSFNCCustomMaps.ZoomToBounds(ABounds: TTMSFNCMapsBoundsRec);
begin
  ZoomToBounds(ABounds.NorthEast, ABounds.SouthWest);
end;

procedure TTMSFNCCustomMaps.SetZoomLevel(AZoomLevel: Double);
begin
  if MapInitialized and MapReady then
    ExecuteJavaScript(SETZOOMLEVELFUNCTION + '(' + FloatToStrDot(AZoomLevel) + ')');
end;

function TTMSFNCCustomMaps.GetPolylinesClass: TTMSFNCMapsPolylinesClass;
begin
  Result := TTMSFNCMapsPolylines;
end;

function TTMSFNCCustomMaps.GetPolygonsClass: TTMSFNCMapsPolygonsClass;
begin
  Result := TTMSFNCMapsPolygons;
end;

function TTMSFNCCustomMaps.GetCirclesClass: TTMSFNCMapsCirclesClass;
begin
  Result := TTMSFNCMapsCircles;
end;

function TTMSFNCCustomMaps.GetRectanglesClass: TTMSFNCMapsRectanglesClass;
begin
  Result := TTMSFNCMapsRectangles;
end;

function TTMSFNCCustomMaps.GetMarkerByID(AID: string): TTMSFNCMapsMarker;
begin
  Result := Markers.ItemByID[AID];
end;

function TTMSFNCCustomMaps.GetMapID: string;
begin
  Result := '';
end;

function TTMSFNCCustomMaps.GetPanning: Boolean;
begin
  Result := Options.Panning;
end;

function TTMSFNCCustomMaps.GetPolyElementByID(AID: string): TTMSFNCMapsPolyElement;
var
  pl, pg, r, c: TTMSFNCMapsPolyElement;
begin
  Result := nil;
  pl := Polylines.ItemByID[AID];
  pg := Polygons.ItemByID[AID];
  r := Rectangles.ItemByID[AID];
  c := Circles.ItemByID[AID];

  if Assigned(pl) then
    Result := pl;
  if Assigned(pg) then
    Result := pg;
  if Assigned(r) then
    Result := r;
  if Assigned(c) then
    Result := c;
end;

function TTMSFNCCustomMaps.GetMarkersClass: TTMSFNCMapsMarkersClass;
begin
  Result := TTMSFNCMapsMarkers;
end;

{ TTMSFNCMaps }

procedure TTMSFNCMaps.AddBridge(ABridgeName: string; ABridgeObject: TObject);
begin
  inherited AddBridge(ABridgeName, ABridgeObject);
end;

function TTMSFNCMaps.CanGoBack: Boolean;
begin
  Result := inherited CanGoBack;
end;

function TTMSFNCMaps.CanGoForward: Boolean;
begin
  Result := inherited CanGoForward;
end;

procedure TTMSFNCMaps.CaptureScreenShot;
begin
  inherited CaptureScreenShot;
end;

procedure TTMSFNCMaps.ClearCache;
begin
  inherited ClearCache;
end;

procedure TTMSFNCMaps.DeInitialize;
begin
  inherited DeInitialize;
end;

procedure TTMSFNCMaps.ExecuteJavaScript(AScript: String;
  ACompleteEvent: TTMSFNCWebBrowserJavaScriptCompleteEvent = nil; AImmediate: Boolean = False);
begin
  inherited ExecuteJavaScript(AScript, ACompleteEvent, AImmediate);
end;

function TTMSFNCMaps.ExecuteJavaScriptSync(AScript: String): string;
begin
  Result := inherited ExecuteJavaScriptSync(AScript);
end;

function TTMSFNCMaps.GetBridgeCommunicationLayer(ABridgeName: string): string;
begin
  Result := inherited GetBridgeCommunicationLayer(ABridgeName);
end;

{$IFDEF ANDROID}
function TTMSFNCMaps.NativeDialog: Pointer;
begin
  Result := inherited NativeDialog;
end;
{$ENDIF}

{$IFDEF MSWINDOWS}
function TTMSFNCMaps.GetWebBrowserInstance: IInterface;
begin
  Result := inherited GetWebBrowserInstance;
end;
{$ENDIF}

procedure TTMSFNCMaps.GoBack;
begin
  inherited GoBack;
end;

procedure TTMSFNCMaps.GoForward;
begin
  inherited GoForward;
end;

procedure TTMSFNCMaps.Initialize;
begin
  inherited Initialize;
end;

function TTMSFNCMaps.IsFMXBrowser: Boolean;
begin
  Result := inherited IsFMXBrowser;
end;

procedure TTMSFNCMaps.LoadFile(AFile: String);
begin
  inherited LoadFile(AFile);
end;

procedure TTMSFNCMaps.LoadHTML(AHTML: String);
begin
  inherited LoadHTML(AHTML);
end;

function TTMSFNCMaps.NativeBrowser: Pointer;
begin
  Result := inherited NativeBrowser;
end;

function TTMSFNCMaps.NativeEnvironment: Pointer;
begin
  Result := inherited NativeEnvironment;
end;

procedure TTMSFNCMaps.Navigate;
begin
  inherited Navigate;
end;

procedure TTMSFNCMaps.Navigate(const AURL: string);
begin
  inherited Navigate(AURL);
end;

procedure TTMSFNCMaps.Reload;
begin
  inherited Reload;
end;

procedure TTMSFNCMaps.RemoveBridge(ABridgeName: string);
begin
  inherited RemoveBridge(ABridgeName);
end;

procedure TTMSFNCMaps.ShowDebugConsole;
begin
  inherited ShowDebugConsole;
end;

procedure TTMSFNCMaps.StopLoading;
begin
  inherited StopLoading;
end;

{ TTMSFNCMapsElementAction }

procedure TTMSFNCMapsElementAction.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCMapsElementAction) then
  begin
    FEnabled := (Source as TTMSFNCMapsElementAction).Enabled;
    FElementEvent := (Source as TTMSFNCMapsElementAction).Event;
    FHTMLElementID := (Source as TTMSFNCMapsElementAction).HTMLElementID;
    FTag := (Source as TTMSFNCMapsElementAction).Tag;
    FCustomEvent := (Source as TTMSFNCMapsElementAction).CustomEvent;
    FEventReturnValue := (Source as TTMSFNCMapsElementAction).EventReturnValue;
    FName := (Source as TTMSFNCMapsElementAction).Name;
    FTag := (Source as TTMSFNCMapsElementAction).Tag;
    FOnExecute := (Source as TTMSFNCMapsElementAction).OnExecute;
  end;
end;

constructor TTMSFNCMapsElementAction.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCMapsElementActions).FOwner;

  FTag := 0;
  FName := 'Action' + IntToStr(Index);
  FEnabled := true;
  FElementEvent := heClick;
  FHTMLElementID := '';
  FCustomEvent := '';
  FEventReturnValue := rvNone;
end;

destructor TTMSFNCMapsElementAction.Destroy;
begin
  inherited;
end;

procedure TTMSFNCMapsElementAction.SetCustomEvent(const Value: string);
begin
  if FCustomEvent <> Value then
  begin
    FCustomEvent := Value;
    UpdateAction;
  end;
end;

procedure TTMSFNCMapsElementAction.SetElementEvent(const Value: TTMSFNCMapsHTMLEvent);
begin
  if FElementEvent <> Value then
  begin
    FElementEvent := Value;
    UpdateAction;
  end;
end;

procedure TTMSFNCMapsElementAction.SetHTMLElementID(const Value: string);
begin
  if FHTMLElementID <> Value then
  begin
    FHTMLElementID := Value;
    UpdateAction;
  end;
end;

procedure TTMSFNCMapsElementAction.SetEnabled(const Value: boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;
    UpdateAction;
  end;
end;

procedure TTMSFNCMapsElementAction.SetEventReturnValue(
  const Value: TTMSFNCMapsReturnValue);
begin
  if FEventReturnValue <> Value then
  begin
    FEventReturnValue := Value;
    UpdateAction;
  end;
end;

procedure TTMSFNCMapsElementAction.SetName(const Value: string);
begin
  FName := Value;
end;

procedure TTMSFNCMapsElementAction.UpdateAction;
begin
  if Assigned(FOwner) then
    FOwner.UpdateElementContainer;
end;

{ TTMSFNCMapsElementActions }

function TTMSFNCMapsElementActions.Add: TTMSFNCMapsElementAction;
begin
  Result := TTMSFNCMapsElementAction(inherited Add);
end;

constructor TTMSFNCMapsElementActions.Create(AOwner: TTMSFNCMapsElementContainer);
begin
  inherited Create(AOwner, TTMSFNCMapsElementAction);
  FOwner := AOwner;
end;

function TTMSFNCMapsElementActions.FindByName(AName: string): TTMSFNCMapsElementAction;
begin
  Result := GetActionByName(AName);
end;

function TTMSFNCMapsElementActions.GetAction(AName: string): TTMSFNCMapsElementAction;
begin
  Result := GetActionByName(AName);
end;

function TTMSFNCMapsElementActions.GetActionByName(AName: string): TTMSFNCMapsElementAction;
var
  i: integer;
begin
  Result := nil;

  for I := 0 to Count - 1 do
  begin
    if CompareText(AName, Items[i].Name) = 0 then
    begin
      Result := Items[i];
      break;
    end;
  end;
end;

function TTMSFNCMapsElementActions.GetByName(AName: string): TTMSFNCMapsElementAction;
begin
  Result := GetActionByName(AName);
  if not Assigned(Result) then
    raise Exception.Create('Action ' + AName + ' not found');
end;

function TTMSFNCMapsElementActions.GetItems(AIndex: integer): TTMSFNCMapsElementAction;
begin
  Result := TTMSFNCMapsElementAction(inherited Items[AIndex]);
end;

function TTMSFNCMapsElementActions.Insert(AIndex: integer): TTMSFNCMapsElementAction;
begin
  Result := TTMSFNCMapsElementAction(inherited Insert(AIndex));
end;

procedure TTMSFNCMapsElementActions.SetItems(AIndex: integer;
  const Value: TTMSFNCMapsElementAction);
begin
  inherited Items[AIndex] := Value;
end;

{ TTMSFNCMapsElementContainers }

function TTMSFNCMapsElementContainers.Add: TTMSFNCMapsElementContainer;
begin
  Result := TTMSFNCMapsElementContainer(inherited Add);
end;

procedure TTMSFNCMapsElementContainers.Clear;
var
  l: TTMSFNCCustomMaps;
  ci: TCollectionItem;
begin
  l := FOwner;
  if Assigned(l) then
    l.BeginUpdate;

  if Count > 0 then
  begin
    while Count > 0 do
    begin
      ci := TCollectionItem(Items[Count - 1]);
      ci.Free;
    end;
  end;

  if Assigned(l) then
    l.EndUpdate;
end;

constructor TTMSFNCMapsElementContainers.Create(AOwner: TTMSFNCCustomMaps);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCMapsElementContainers.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCMapsElementContainer;
end;

function TTMSFNCMapsElementContainers.GetItem(
  Index: Integer): TTMSFNCMapsElementContainer;
begin
  Result := TTMSFNCMapsElementContainer(inherited Items[Index]);
end;

function TTMSFNCMapsElementContainers.GetItemByID(
  ID: string): TTMSFNCMapsElementContainer;
var
  I: Integer;
  c: TTMSFNCMapsElementContainer;
begin
  Result := nil;
  for I := 0 to Count - 1 do
  begin
    c := Items[I];
    if c.ID = ID then
    begin
      Result := c;
      Break;
    end;
  end;
end;

function TTMSFNCMapsElementContainers.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCMapsElementContainers.Insert(
  Index: Integer): TTMSFNCMapsElementContainer;
begin
  Result := TTMSFNCMapsElementContainer(inherited Insert(Index));
end;

procedure TTMSFNCMapsElementContainers.Recreate;
var
  I: Integer;
begin
  for I := 0 to Count - 1 do
    Items[I].Recreate := True;
end;

procedure TTMSFNCMapsElementContainers.SetItem(Index: Integer;
  const Value: TTMSFNCMapsElementContainer);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCMapsElementContainer }

function TTMSFNCMapsElementContainer.AddAction(AHTMLElementID: string;
  AEvent: TTMSFNCMapsHTMLEvent): TTMSFNCMapsElementAction;
begin
  FOwner.BeginUpdate;
  Result := Actions.Add;
  Result.HTMLElementID := AHTMLElementID;
  Result.Event := AEvent;
  FOwner.EndUpdate;
end;

procedure TTMSFNCMapsElementContainer.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMapsElementContainer then
  begin
    FID := (Source as TTMSFNCMapsElementContainer).ID;
    FHTML.Assign((Source as TTMSFNCMapsElementContainer).HTML);
    FVisible := (Source as TTMSFNCMapsElementContainer).Visible;
    FPosition := (Source as TTMSFNCMapsElementContainer).Position;
    FActions.Assign((Source as TTMSFNCMapsElementContainer).Actions);
    FHTMLElementID := (Source as TTMSFNCMapsElementContainer).HTMLElementID;
    FHTMLElementClassName := (Source as TTMSFNCMapsElementContainer).HTMLElementClassName;
    FUseDefaultStyle := (Source as TTMSFNCMapsElementContainer).UseDefaultStyle;
    FStyle.Assign((Source as TTMSFNCMapsElementContainer).Style);
    FScript.Assign((Source as TTMSFNCMapsElementContainer).Script);
    FMargins.Assign((Source as TTMSFNCMapsElementContainer).Margins);
    FCoordinate.Assign((Source as TTMSFNCMapsElementContainer).Coordinate);
    FBounds.Assign((Source as TTMSFNCMapsElementContainer).Bounds);
    FLabelType := (Source as TTMSFNCMapsElementContainer).LabelType;
  end;
end;

constructor TTMSFNCMapsElementContainer.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCMapsElementContainers).FOwner;

  FCoordinate := TTMSFNCMapsCoordinate.Create;
  FCoordinate.OnChange := @ElementContainerChanged;
  FBounds := TTMSFNCMapsBounds.Create;
  FBounds.OnChange := @ElementContainerChanged;
  FHTML := TStringList.Create;
  FHTML.OnChange := @ElementContainerChanged;
  FVisible := True;
  FActions := TTMSFNCMapsElementActions.Create(Self);
  FPosition := poTopLeft;
  FHTMLElementID := 'ElementContainer' + IntToStr(Index);
  FHTMLElementClassName := '';
  FUseDefaultStyle := True;
  FStyle := TStringList.Create;
  FStyle.OnChange := @ElementContainerChanged;
  FScript := TStringList.Create;
  FScript.OnChange := @ElementContainerChanged;
  FMargins := TTMSFNCMargins.Create;
  FMargins.Top := 10;
  FMargins.Left := 10;
  FMargins.Right := 10;
  FMargins.Bottom := 10;
  FMargins.OnChange := @ElementContainerChanged;
  FLabelType := ltLabel;

  UpdateElementContainer;
end;

destructor TTMSFNCMapsElementContainer.Destroy;
begin
  FHTML.Free;
  FActions.Free;
  FStyle.Free;
  FScript.Free;
  FMargins.Free;
  FCoordinate.Free;
  FBounds.Free;
  UpdateElementContainer;
  inherited;
end;

procedure TTMSFNCMapsElementContainer.ElementContainerChanged(Sender: TObject);
begin
  UpdateElementContainer;
end;

function TTMSFNCMapsElementContainer.GetHTMLAsBase64: string;
begin
  Result := TTMSFNCUtils.Encode64(FHTML.Text);
end;

function TTMSFNCMapsElementContainer.GetID: string;
begin
  if (FID = '') or FRecreate then
    FID := CreateNewGUID;

  FRecreate := False;
  Result := FID;
end;

function TTMSFNCMapsElementContainer.GetScriptAsBase64: string;
begin
  Result := TTMSFNCUtils.Encode64(FScript.Text);
end;

function TTMSFNCMapsElementContainer.GetStyleAsBase64: string;
begin
  Result := TTMSFNCUtils.Encode64(FStyle.Text);
end;

procedure TTMSFNCMapsElementContainer.SetActions(
  const Value: TTMSFNCMapsElementActions);
begin
  if FActions <> Value then
  begin
    FActions.Assign(Value);
  end;
end;

procedure TTMSFNCMapsElementContainer.SetBounds(const Value: TTMSFNCMapsBounds);
begin
  if FBounds <> Value then
  begin
    FBounds := Value;
    UpdateElementContainer;
  end;
end;

procedure TTMSFNCMapsElementContainer.SetCoordinate(
  const Value: TTMSFNCMapsCoordinate);
begin
  if FCoordinate <> Value then
  begin
    FCoordinate.Assign(Value);
    UpdateElementContainer;
  end;
end;

procedure TTMSFNCMapsElementContainer.SetStyle(const Value: TStringList);
begin
  if FStyle <> Value then
  begin
    FStyle.Assign(Value);
    UpdateElementContainer;
  end;
end;

procedure TTMSFNCMapsElementContainer.SetHTMLElementClassName(const Value: string);
begin
  if FHTMLElementClassName <> Value then
  begin
    FHTMLElementClassName := Value;
    UpdateElementContainer;
  end;
end;

procedure TTMSFNCMapsElementContainer.SetHTMLElementID(const Value: string);
begin
  if FHTMLElementID <> Value then
  begin
    FHTMLElementID := Value;
    UpdateElementContainer;
  end;
end;

procedure TTMSFNCMapsElementContainer.SetLabelType(
  const Value: TTMSFNCMapsLabelType);
begin
  if FLabelType <> Value then
  begin
    FLabelType := Value;
    UpdateElementContainer;
  end;
end;

procedure TTMSFNCMapsElementContainer.SetHTML(const Value: TStringList);
begin
  if FHTML <> Value then
  begin
    FHTML.Assign(Value);
    UpdateElementContainer;
  end;
end;

procedure TTMSFNCMapsElementContainer.SetMargins(const Value: TTMSFNCMargins);
begin
  if FMargins <> Value then
  begin
    FMargins.Assign(Value);
    UpdateElementContainer;
  end;
end;

procedure TTMSFNCMapsElementContainer.SetPosition(
  const Value: TTMSFNCMapsPosition);
begin
  if FPosition <> Value then
  begin
    FPosition := Value;
    UpdateElementContainer;
  end;
end;

procedure TTMSFNCMapsElementContainer.SetScript(const Value: TStringList);
begin
  if FScript <> Value then
  begin
    FScript.Assign(Value);
    UpdateElementContainer;
  end;
end;

procedure TTMSFNCMapsElementContainer.SetUseDefaultStyle(const Value: Boolean);
begin
  if FUseDefaultStyle <> Value then
  begin
    FUseDefaultStyle := Value;
    UpdateElementContainer;
  end;
end;

procedure TTMSFNCMapsElementContainer.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdateElementContainer;
  end;
end;

procedure TTMSFNCMapsElementContainer.UpdateElementContainer;
begin
  FReload := True;
  if Assigned(FOwner) then
    FOwner.UpdateElementContainers;
end;


{ TTMSFNCMapsHeadLink }

procedure TTMSFNCMapsHeadLink.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMapsHeadLink then
  begin
    FURL := (Source as TTMSFNCMapsHeadLink).URL;
    FEnabled := (Source as TTMSFNCMapsHeadLink).Enabled;
    FType := (Source as TTMSFNCMapsHeadLink).&Type;
    FCharSet := (Source as TTMSFNCMapsHeadLink).CharSet;
    FContent.Assign((Source as TTMSFNCMapsHeadLink).Content);
    FRel := (Source as TTMSFNCMapsHeadLink).Rel;
    FDefer := (Source as TTMSFNCMapsHeadLink).Defer;
    FAsync := (Source as TTMSFNCMapsHeadLink).Async;
    FKind := (Source as TTMSFNCMapsHeadLink).Kind;
  end;
end;

constructor TTMSFNCMapsHeadLink.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCMapsHeadLinks).FOwner;

  FURL := '';
  FEnabled := True;
  FType := '';
  FCharSet := '';
  FContent := TStringList.Create;
  FContent.OnChange := @HeadLinkChanged;
  FRel := '';
  FDefer := False;
  FAsync := False;
  FKind := mlkScript;

  UpdateHeadLink;
end;

destructor TTMSFNCMapsHeadLink.Destroy;
begin
  FContent.Free;
  inherited;

  UpdateHeadLink;
end;

procedure TTMSFNCMapsHeadLink.HeadLinkChanged(Sender: TObject);
begin
  UpdateHeadLink;
end;

function TTMSFNCMapsHeadLink.IsURLStored: Boolean;
begin
  Result := FURL <> '';
end;

procedure TTMSFNCMapsHeadLink.SetAsync(const Value: Boolean);
begin
  if FAsync <> Value then
  begin
    FAsync := Value;
    UpdateHeadLink;
  end;
end;

procedure TTMSFNCMapsHeadLink.SetCharSet(const Value: string);
begin
  if FCharSet <> Value then
  begin
    FCharSet := Value;
    UpdateHeadLink;
  end;
end;

procedure TTMSFNCMapsHeadLink.SetContent(const Value: TStringList);
begin
  if FContent <> Value then
  begin
    FContent.Assign(Value);
    UpdateHeadLink;
  end;
end;

procedure TTMSFNCMapsHeadLink.SetDefer(const Value: Boolean);
begin
  if FDefer <> Value then
  begin
    FDefer := Value;
    UpdateHeadLink;
  end;
end;

procedure TTMSFNCMapsHeadLink.SetEnabled(const Value: Boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;
    UpdateHeadLink;
  end;
end;

procedure TTMSFNCMapsHeadLink.SetKind(const Value: TTMSFNCMapsLinkKind);
begin
  if FKind <> Value then
  begin
    FKind := Value;
    UpdateHeadLink;
  end;
end;

procedure TTMSFNCMapsHeadLink.SetRel(const Value: string);
begin
  if FRel <> Value then
  begin
    FRel := Value;
    UpdateHeadLink;
  end;
end;

procedure TTMSFNCMapsHeadLink.SetType(const Value: string);
begin
  if FType <> Value then
  begin
    FType := Value;
    UpdateHeadLink;
  end;
end;

procedure TTMSFNCMapsHeadLink.SetURL(const Value: string);
begin
  if FURL <> Value then
  begin
    FURL := Value;
    UpdateHeadLink;
  end;
end;

procedure TTMSFNCMapsHeadLink.UpdateHeadLink;
begin
  if FOwner.IsDesignTime then
    FOwner.ReInitialize;
end;

{ TTMSFNCMapsScriptLinks }

function TTMSFNCMapsHeadLinks.Add: TTMSFNCMapsHeadLink;
begin
  Result := TTMSFNCMapsHeadLink(inherited Add);
end;

function TTMSFNCMapsHeadLinks.AddLink(AURL: string): TTMSFNCMapsHeadLink;
begin
  Result := TTMSFNCMapsHeadLink(inherited Add);
  Result.Kind := mlkLink;
  Result.URL := AURL;
end;

function TTMSFNCMapsHeadLinks.AddScript(AURL: string): TTMSFNCMapsHeadLink;
begin
  Result := TTMSFNCMapsHeadLink(inherited Add);
  Result.Kind := mlkScript;
  Result.URL := AURL;
end;

function TTMSFNCMapsHeadLinks.AddStyle(AStyle: TStrings): TTMSFNCMapsHeadLink;
begin
  Result := TTMSFNCMapsHeadLink(inherited Add);
  Result.Kind := mlkStyle;
  Result.Content.Assign(AStyle);
end;

function TTMSFNCMapsHeadLinks.AddStyleSheetLink(
  AURL: string): TTMSFNCMapsHeadLink;
begin
  Result := TTMSFNCMapsHeadLink(inherited Add);
  Result.Kind := mlkLink;
  Result.URL := AURL;
  Result.Rel := 'stylesheet';
end;

procedure TTMSFNCMapsHeadLinks.Clear;
var
  l: TTMSFNCCustomMaps;
  ci: TCollectionItem;
begin
  l := FOwner;
  if Assigned(l) then
    l.BeginUpdate;

  if Count > 0 then
  begin
    while Count > 0 do
    begin
      ci := TCollectionItem(Items[Count - 1]);
      ci.Free;
    end;
  end;

  if Assigned(l) then
    l.EndUpdate;
end;

constructor TTMSFNCMapsHeadLinks.Create(AOwner: TTMSFNCCustomMaps);
begin
  inherited Create(AOwner, TTMSFNCMapsHeadLink);
  FOwner := AOwner;
end;

function TTMSFNCMapsHeadLinks.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCMapsHeadLink;
end;

function TTMSFNCMapsHeadLinks.GetItem(Index: Integer): TTMSFNCMapsHeadLink;
begin
  Result := TTMSFNCMapsHeadLink(inherited Items[Index]);
end;

function TTMSFNCMapsHeadLinks.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCMapsHeadLinks.Insert(Index: Integer): TTMSFNCMapsHeadLink;
begin
  Result := TTMSFNCMapsHeadLink(inherited Insert(Index));
end;

procedure TTMSFNCMapsHeadLinks.SetItem(Index: Integer;
  const Value: TTMSFNCMapsHeadLink);
begin
  inherited Items[Index] := Value;
end;


{ TTMSFNCMapsPopup }

function TTMSFNCMapsPopup.GetLatitude: Double;
begin
  Result := Coordinate.Latitude;
end;

function TTMSFNCMapsPopup.GetLongitude: Double;
begin
  Result := Coordinate.Longitude;
end;

procedure TTMSFNCMapsPopup.SetLatitude(const Value: Double);
begin
  Coordinate.Latitude := Value;
end;

procedure TTMSFNCMapsPopup.SetLongitude(const Value: Double);
begin
  Coordinate.Longitude := Value;
end;

constructor TTMSFNCMapsPopup.Create;
begin
  FCoordinate := TTMSFNCMapsCoordinate.Create;
end;

destructor TTMSFNCMapsPopup.Destroy;
begin
  FCoordinate.Free;
  inherited;
end;

function TTMSFNCMapsPopup.GetID: string;
begin
  if FID = '' then
    FID := CreateNewGUID;

  Result := FID;
end;

{$IFDEF WEBLIB}
procedure TTMSFNCCustomMaps.RemoveScripts;
var
  I: Integer;
  t, id: string;
  l: TTMSFNCMapsLinksList;
  lk: TTMSFNCMapsLink;
begin
  if Assigned(FMaps) then
  begin
    l := TTMSFNCMapsLinksList.Create;

    GetHeadLinks(l, False);

    try
      for I := 0 to l.Count - 1 do
      begin
        lk := l[I];
        id := 'WEBLib.TMSFNCMaps.' + StringReplace(FMaps.GetIdentifier, ' ', '', [rfReplaceAll]) + '.';

        case lk.Kind of
          mlkLink: t := 'LINK';
          mlkScript: t := 'SCRIPT';
        end;

        id := id + t + 'HEAD' + IntToStr(I);

        asm
          var exScript = document.getElementById(id);
          if (exScript){
            exScript.parentNode.removeChild(exScript);
          }
        end;
      end;

      l.Clear;

      GetBodyLinks(l, False, False);

      for I := 0 to l.Count - 1 do
      begin
        lk := l[I];
        id := 'WEBLib.TMSFNCMaps.' + StringReplace(FMaps.GetIdentifier, ' ', '', [rfReplaceAll]) + '.';

        case lk.Kind of
          mlkLink: t := 'LINK';
          mlkScript: t := 'SCRIPT';
        end;

        id := id + t + 'BODY' + IntToStr(I);

        asm
          var exScript = document.getElementById(id);
          if (exScript){
            exScript.parentNode.removeChild(exScript);
          }
        end;
      end;

      FMaps.RemoveScripts;

    finally
      l.Free;
    end;
  end;

  asm
    var exScript = document.getElementById("WEBLib.TMSFNCMaps.Console");
    if (exScript){
      exScript.parentNode.removeChild(exScript);
    }
  end;
end;

procedure TTMSFNCCustomMaps.RemoveStyles;
begin
  if Assigned(FMaps) then
  begin
    asm
      var exScript = document.getElementById("WEBLib.TMSFNCMaps.Styles");
      if (exScript){
         exScript.parentNode.removeChild(exScript);
      }
    end;
  end;
end;

procedure TTMSFNCCustomMaps.LoadScripts(AHead: Boolean);
var
  l: TTMSFNCMapsLinksList;
  I: Integer;
  lk: TTMSFNCMapsLink;
  dcl, id, src, tp, t, cs, rl, ct: string;
  asc, def: Boolean;
  h: Boolean;
  e: TJSHTMLElement;
begin
  if MapReady then
  begin
    dcl := DEBUGCONSOLELINK;
    h := AHead;

    e := ElementHandle;

    l := TTMSFNCMapsLinksList.Create;
    try
      if h then
        GetHeadLinks(l)
      else
        GetBodyLinks(l);

      for I := 0 to l.Count - 1 do
      begin
        lk := l[I];
        id := 'WEBLib.TMSFNCMaps.' + StringReplace(FMaps.GetIdentifier, ' ', '', [rfReplaceAll]) + '.';
        src := lk.URL;
        tp := lk.&Type;
        cs := lk.CharSet;
        def := lk.Defer;
        asc := lk.Async;
        rl := lk.Rel;
        ct := lk.Content;

        case lk.Kind of
          mlkLink: t := 'LINK';
          mlkScript: t := 'SCRIPT';
        end;

        if h then
          id := id + t + 'HEAD' + IntToStr(I)
        else
          id := id + t + 'BODY' + IntToStr(I);

        asm
          var exScript = document.getElementById(id) || document.querySelector('script[src="' + src + '"]') || document.querySelector('link[href="' + src + '"]');
        end;

        asm
          if (!exScript){
            var s = document.createElement(t);
            s.id = id;

            if (tp != ''){
              s.type = tp;
            }

            if (t == 'LINK'){
              if (rl != ''){
                s.rel = rl;
              }
              if (src != ''){
                s.href = src;
              }
            }
            else{
              s.defer = def;
              s.async = asc;
              if (src != ''){
                s.src = src;
              }
            }

            s.innerHTML = ct;

            if (e && e.ownerDocument) {
              if (h){
                e.ownerDocument.head.appendChild(s);
              }
              else{
                e.ownerDocument.body.appendChild(s);
              }
            }else{
              if (h){
                document.head.appendChild(s);
              }
              else{
                document.body.appendChild(s);
              }
            }
          }
        end;
      end;
    finally
      l.Free;
    end;

    if Options.Console and h then
    begin
      asm
        var exScript = document.getElementById("WEBLib.TMSFNCMaps.Console");
        if (exScript){
          return;
        }

        var s = document.createElement("script");
        s.id = "WEBLib.TMSFNCMaps.Console";
        s.src = dcl;
        document.head.appendChild(s);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomMaps.LoadStyles;
var
  sc: string;
  e: TJSHTMLElement;
begin
  if MapReady then
  begin
    e := ElementHandle;

    sc := GetHeadStyle;
    asm
      var exScript = document.getElementById("WEBLib.TMSFNCMaps.Styles");
      if (exScript){
        return;
      }

      var s = document.createElement("style");
      s.id = "WEBLib.TMSFNCMaps.Styles";
      s.innerHTML = sc;

      if (e && e.ownerDocument){
        e.ownerDocument.head.appendChild(s);
      }else{
        document.head.appendChild(s);
      }
    end;
  end;
end;
{$ENDIF}

{ TTMSFNCMapsLabel }

procedure TTMSFNCMapsLabel.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCMapsLabel then
  begin
    FText := (Source as TTMSFNCMapsLabel).Text;
    FCoordinate.Assign((Source as TTMSFNCMapsLabel).Coordinate);
    FBounds.Assign((Source as TTMSFNCMapsLabel).Bounds);
    FVisible := (Source as TTMSFNCMapsLabel).Visible;
    FBackgroundColor := (Source as TTMSFNCMapsLabel).BackgroundColor;
    FBorderColor := (Source as TTMSFNCMapsLabel).BorderColor;
    FBorderWidth := (Source as TTMSFNCMapsLabel).BorderWidth;
    FFont.Assign((Source as TTMSFNCMapsLabel).Font);
    FPosition := (Source as TTMSFNCMapsLabel).Position;
    FLabelType := (Source as TTMSFNCMapsLabel).LabelType;
  end;
end;

constructor TTMSFNCMapsLabel.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCMapsLabels).FOwner;

  FText := '';
  FCoordinate := TTMSFNCMapsCoordinate.Create;
  FCoordinate.OnChange := @LabelChanged;
  FBounds := TTMSFNCMapsBounds.Create;
  FBounds.OnChange := @LabelChanged;
  FVisible := True;
  FBackgroundColor := gcNull;
  FBorderColor := gcNull;
  FBorderWidth := 2;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.Size := 14;
  FFont.Name := 'Roboto';
  FFont.OnChanged := @LabelChanged;
  FPosition := ptCoordinate;
  FLabelType := ltLabel;

  UpdateLabel;
end;

destructor TTMSFNCMapsLabel.Destroy;
begin
  FCoordinate.Free;
  FBounds.Free;
  FFont.Free;
  inherited;
end;

function TTMSFNCMapsLabel.GetID: string;
begin
  if (FID = '') or FRecreate then
    FID := CreateNewGUID;

  FRecreate := False;
  Result := FID;
end;

procedure TTMSFNCMapsLabel.LabelChanged(Sender: TObject);
begin
  UpdateLabel;
end;

procedure TTMSFNCMapsLabel.SetBackgroundColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FBackgroundColor <> Value then
  begin
    FBackgroundColor := Value;
    UpdateLabel;
  end;
end;

procedure TTMSFNCMapsLabel.SetBorderColor(const Value: TTMSFNCGraphicsColor);
begin
  if FBorderColor <> Value then
  begin
    FBorderColor := Value;
    UpdateLabel;
  end;
end;

procedure TTMSFNCMapsLabel.SetBorderWidth(const Value: Integer);
begin
  if FBorderWidth <> Value then
  begin
    FBorderWidth := Value;
    UpdateLabel;
  end;
end;

procedure TTMSFNCMapsLabel.SetBounds(const Value: TTMSFNCMapsBounds);
begin
  if FBounds <> Value then
  begin
    FBounds.Assign(Value);
    UpdateLabel;
  end;
end;

procedure TTMSFNCMapsLabel.SetCoordinate(const Value: TTMSFNCMapsCoordinate);
begin
  if FCoordinate <> Value then
  begin
    FCoordinate.Assign(Value);
    UpdateLabel;
  end;
end;

procedure TTMSFNCMapsLabel.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
end;

procedure TTMSFNCMapsLabel.SetLabelType(const Value: TTMSFNCMapsLabelType);
begin
  if FLabelType <> Value then
  begin
    FLabelType := Value;
    UpdateLabel;
  end;
end;

procedure TTMSFNCMapsLabel.SetPosition(const Value: TTMSFNCMapsPositionType);
begin
  if FPosition <> Value then
  begin
    FPosition := Value;
    UpdateLabel;
  end;
end;

procedure TTMSFNCMapsLabel.SetText(const Value: string);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateLabel;
  end;
end;

procedure TTMSFNCMapsLabel.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdateLabel;
  end;
end;

procedure TTMSFNCMapsLabel.UpdateLabel;
begin
  FReload := True;
  if Assigned(FOwner) then
    FOwner.UpdateElementContainers;
end;

{ TTMSFNCMapsLabels }

function TTMSFNCMapsLabels.Add: TTMSFNCMapsLabel;
begin
  Result := TTMSFNCMapsLabel(inherited Add);
end;

procedure TTMSFNCMapsLabels.Clear;
var
  l: TTMSFNCCustomMaps;
  ci: TCollectionItem;
begin
  l := FOwner;
  if Assigned(l) then
    l.BeginUpdate;

  if Count > 0 then
  begin
    while Count > 0 do
    begin
      ci := TCollectionItem(Items[Count - 1]);
      ci.Free;
    end;
  end;

  if Assigned(l) then
    l.EndUpdate;
end;

constructor TTMSFNCMapsLabels.Create(AOwner: TTMSFNCCustomMaps);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCMapsLabels.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCMapsLabel;
end;

function TTMSFNCMapsLabels.GetItem(Index: Integer): TTMSFNCMapsLabel;
begin
  Result := TTMSFNCMapsLabel(inherited Items[Index]);
end;

function TTMSFNCMapsLabels.GetItemByID(ID: string): TTMSFNCMapsLabel;
var
  I: Integer;
  c: TTMSFNCMapsLabel;
begin
  Result := nil;
  for I := 0 to Count - 1 do
  begin
    c := Items[I];
    if c.ID = ID then
    begin
      Result := c;
      Break;
    end;
  end;
end;

function TTMSFNCMapsLabels.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCMapsLabels.Insert(Index: Integer): TTMSFNCMapsLabel;
begin
  Result := TTMSFNCMapsLabel(inherited Insert(Index));
end;

procedure TTMSFNCMapsLabels.Recreate;
var
  I: Integer;
begin
  for I := 0 to Count - 1 do
    Items[I].Recreate := True;
end;

procedure TTMSFNCMapsLabels.SetItem(Index: Integer;
  const Value: TTMSFNCMapsLabel);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCMapsPolygon }

function TTMSFNCMapsPolygon.AddHole(
  ACoordinates: TTMSFNCMapsCoordinateRecArray): TTMSFNCMapsPolyElementHole;
begin
  Result := inherited AddHole(ACoordinates);
end;

initialization
begin
  TTMSFNCMapsPlatformServices.FCurrentReleased := False;
  RegisterGoogleMapsService;
  RegisterHereService;
  RegisterBingMapsService;
  RegisterAzureMapsService;
  RegisterTomTomService;
  RegisterOpenLayersService;
  RegisterLeafletService;
  RegisterMapBoxService;
  RegisterMapKitService;
end;

{$IFNDEF WEBLIB}
finalization
begin
  {$IFDEF UNREGISTER}
  UnRegisterMapKitService;
  UnRegisterMapBoxService;
  UnRegisterOpenLayersService;
  UnRegisterLeafletService;
  UnRegisterTomTomService;
  UnRegisterAzureMapsService;
  UnRegisterBingMapsService;
  UnRegisterHereService;
  UnRegisterGoogleMapsService;
  {$ENDIF}
  {$IFNDEF AUTOREFCOUNT}
  TTMSFNCMapsPlatformServices.ReleaseCurrent;
  {$ENDIF}
end;
{$ENDIF}

end.

