{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCDirections.Here;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterHereDirectionsService;
procedure UnRegisterHereDirectionsService;

implementation

uses
  Classes, Math, DateUtils, Types, WEBLib.TMSFNCDirections,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase, SysUtils
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCDirectionsHereService = class;

  TTMSFNCDirectionsHereDirectionsFactoryService = class(TTMSFNCDirectionsFactoryService, ITMSFNCDirectionsServiceHere);

  TTMSFNCDirectionsHereService = class(TTMSFNCDirectionsHereDirectionsFactoryService)
  protected
    function DoCreateDirections: ITMSFNCCustomDirections; override;
  end;

const
 DECODING_TABLE: array[0..77] of Integer = (
                    62, -1, -1, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, -1, -1, -1, -1,
                    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
                    22, 23, 24, 25, -1, -1, -1, -1, 63, -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
                    36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51

                  );

type
  TTMSFNCDirectionsHereDirections = class;

  TTMSFNCDirectionsHereDirectionsThirdDimensionValue = (tdAbsent, tdLevel, tdAltitude, tdElevation, tdReserved1, tdReserved2, tdCustom1, tdCustom2);
  {$IFNDEF WEBLIB}
  TTMSFNCDirectionsHereDirectionsThirdDimensionValueEnum = TTMSFNCDirectionsHereDirectionsThirdDimensionValue;
  {$ELSE}
  TTMSFNCDirectionsHereDirectionsThirdDimensionValueEnum = set of TTMSFNCDirectionsHereDirectionsThirdDimensionValue;
  {$ENDIF}

  TTMSFNCDirectionsHereDirectionsThirdDimension = class
  public
    class function fromNum(value: Integer): TTMSFNCDirectionsHereDirectionsThirdDimensionValue;
  end;

  TTMSFNCDirectionsHereDirectionsConverter = class
  private
    multiplier, lastValue: Integer;
  protected
    procedure setPrecision(precision: Integer);
  public
    constructor Create(precision: Integer);
    class function decodeUnsignedVarInt(enc: string; var idx: integer; var res: Integer): Boolean;
    class function decodeChar(charValue: Char): Integer;
    function decodeValue(enc: string; var idx: Integer; var c: Double): Boolean;
  end;

  TTMSFNCDirectionsHereDirectionsDecoder = class
  private
    encoded: string;
    index: Integer;
    latConverter: TTMSFNCDirectionsHereDirectionsConverter;
    lngConverter: TTMSFNCDirectionsHereDirectionsConverter;
    zConverter: TTMSFNCDirectionsHereDirectionsConverter;
    precision: Integer;
    thirdDimPrecision: Integer;
    thirdDimension: TTMSFNCDirectionsHereDirectionsThirdDimensionValue;
  protected
    procedure decodeHeaderFromString(enc: string; var idx: Integer; var h: Integer);
    procedure decodeHeader;
    function decodeOne(var lat, lng, z: Double): Boolean;
    function hasThirdDimension: Boolean;
  public
    constructor Create(AEncoded: string);
    destructor Destroy; override;
    class function Decode(AEncoded: string): TTMSFNCMapsCoordinateRecArray;
  end;

  TTMSFNCDirectionsHereDirections = class(TTMSFNCCustomDirectionsInterfacedObject, ITMSFNCCustomDirections)
  protected
    FTravelMode: TTMSFNCDirectionsTravelMode;
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; ATravelMode: TTMSFNCDirectionsTravelMode; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
    function GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
    function GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
    procedure Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyDirections;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  DirectionsService: ITMSFNCDirectionsServiceHere;

procedure RegisterHereDirectionsService;
begin
  if not TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceHere, IInterface(DirectionsService)) then
  begin
    DirectionsService := TTMSFNCDirectionsHereService.Create;
    TTMSFNCDirectionsPlatformServices.Current.AddPlatformService(ITMSFNCDirectionsServiceHere, DirectionsService);
  end;
end;

procedure UnregisterHereDirectionsService;
begin
  TTMSFNCDirectionsPlatformServices.Current.RemovePlatformService(ITMSFNCDirectionsServiceHere);
end;

{ TTMSFNCDirectionsHereService }

function TTMSFNCDirectionsHereService.DoCreateDirections: ITMSFNCCustomDirections;
begin
  Result := TTMSFNCDirectionsHereDirections.Create;
end;

procedure TTMSFNCDirectionsHereDirections.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCDirectionsHereDirections.Create;
begin
  inherited;
end;

destructor TTMSFNCDirectionsHereDirections.Destroy;
begin
  inherited;
end;

procedure TTMSFNCDirectionsHereDirections.DestroyDirections;
begin
  DirectionsService.DestroyDirections(Self);
end;

function TTMSFNCDirectionsHereDirections.GetHost: string;
begin
  Result := 'https://router.hereapi.com';
end;

function TTMSFNCDirectionsHereDirections.GetIdentifier: string;
begin
  Result := 'Here';
end;

function TTMSFNCDirectionsHereDirections.GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; ATravelMode: TTMSFNCDirectionsTravelMode; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
begin
  Result := '/v8/routes';
end;

function TTMSFNCDirectionsHereDirections.GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
begin
  Result := '';
end;

function TTMSFNCDirectionsHereDirections.GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
  ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
  AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
var
  TravelMode, WayPoints, Alternatives: string;
  I: Integer;
begin
  if AAlternatives then
    Alternatives := '3'
  else
    Alternatives := '0';

  if ATravelMode in [tmPublicTransport] then
    FTravelMode := tmDriving
  else
    FTravelMode := ATravelMode;

  case FTravelMode of
    tmDriving: TravelMode := 'car';
    tmWalking: TravelMode := 'pedestrian';
    tmBicycling: TravelMode := 'bicycle';
    tmTruck: TravelMode := 'truck';
  end;

  AOrigin := FloatToStrDot(AOriginLatitude) + ',' + FloatToStrDot(AOriginLongitude);
  ADestination := FloatToStrDot(ADestinationLatitude) + ',' + FloatToStrDot(ADestinationLongitude);

  WayPoints := '';

  if Assigned(AWaypoints) and (Length(AWayPoints) > 0) then
  begin
    for I := 0 to (Length(AWayPoints) - 1) do
      WayPoints := WayPoints + '&via=' + FloatToStrDot(AWayPoints[I].Latitude) + ',' + FloatToStrDot(AWayPoints[I].Longitude);
  end;

  Result := 'apiKey=' + DirectionsProperties.GetAPIKey
    + '&transportMode=' + TravelMode
    + '&origin=' + AOrigin
    + '&destination=' + ADestination
    + '&return=polyline,actions,instructions,summary'
    + WayPoints
    + '&alternatives=' + Alternatives;

  if ALocale <> '' then
    Result := Result + '&lang=' + ALocale;

  if ATravelMode = tmTruck then
  begin
    Result := DirectionsProperties.GetTruckOptions(Result, AAvoidTolls);
  end
  else
  begin
    Result := Result + '&routingMode=fast';
    if AAvoidTolls then
      Result := Result + '&avoid[features]=tollRoad';
  end;
end;

function TTMSFNCDirectionsHereDirections.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCDirectionsHereDirections.IsValid: Boolean;
begin
  Result := DirectionsProperties.GetAPIKey <> '';
end;

procedure TTMSFNCDirectionsHereDirections.Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
var
  d: TTMSFNCDirectionsItems;
  di: TTMSFNCDirectionsItem;
  co: TTMSFNCMapsCoordinateItem;
  step, lstep: TTMSFNCDirectionsStep;

  o, jo, jol, jos: TJSONValue;
  jar, jal, jas: TJSONArray;
  i, j, k: Integer;
  l: TTMSFNCDirectionsLeg;
  ol, old, ola: TJSONObject;
  corlist: string;
  coarr: TTMSFNCMapsCoordinateRecArray;
  offset: Integer;
begin
  if Assigned(DirectionsProperties) then
  begin
    ARequest.TravelMode := FTravelMode;

    d := ARequest.Items;
    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'status');
            ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(o, 'title');

            if ARequest.Status = '' then
              ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'type') + ' ' + TTMSFNCUtils.GetJSONProp(o, 'subtype');

            if ARequest.ErrorMessage = '' then
              ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(o, 'Details');

            jar := TTMSFNCUtils.GetJSONValue(o, 'routes') as TJSONArray;
            if Assigned(jar) then
            begin
              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
              begin
                jo := TTMSFNCUtils.GetJSONArrayItem(jar, i);

                di := d.Add;
                di.Summary := 'Route ' + IntToStr(i);

                jal := TTMSFNCUtils.GetJSONValue(jo, 'sections') as TJSONArray;
                if Assigned(jal) then
                begin
                  for j := 0 to TTMSFNCUtils.GetJSONArraySize(jal) - 1 do
                  begin
                    l := di.Legs.Add;

                    jol := TTMSFNCUtils.GetJSONArrayItem(jal, j);

                    old := TTMSFNCUtils.GetJSONValue(jol, 'arrival') as TJSONObject;
                    if Assigned(old) then
                    begin
                      ol := TTMSFNCUtils.GetJSONValue(old, 'place') as TJSONObject;
                      if Assigned(ol) then
                      begin
                        ol := TTMSFNCUtils.GetJSONValue(ol, 'location') as TJSONObject;
                        if Assigned(ol) then
                        begin
                          l.EndLocation.Latitude := TTMSFNCUtils.GetJSONDoubleValue(ol, 'lat');
                          l.EndLocation.Longitude := TTMSFNCUtils.GetJSONDoubleValue(ol, 'lng');
                        end;
                      end;
                    end;

                    ola := TTMSFNCUtils.GetJSONValue(jol, 'departure') as TJSONObject;
                    if Assigned(ola) then
                    begin
                      ol := TTMSFNCUtils.GetJSONValue(ola, 'place') as TJSONObject;
                      if Assigned(ol) then
                      begin
                        ol := TTMSFNCUtils.GetJSONValue(ol, 'location') as TJSONObject;
                        if Assigned(ol) then
                        begin
                          l.StartLocation.Latitude := TTMSFNCUtils.GetJSONDoubleValue(ol, 'lat');
                          l.StartLocation.Longitude := TTMSFNCUtils.GetJSONDoubleValue(ol, 'lng');
                        end;
                      end;
                    end;

                    corlist := TTMSFNCUtils.GetJSONProp(jol, 'polyline');
                    coarr := TTMSFNCDirectionsHereDirectionsDecoder.Decode(corlist);

                    for k := 0 to Length(coarr) - 1 do
                    begin
                      co := di.Coordinates.Add;
                      co.Latitude := coarr[k].Latitude;
                      co.Longitude := coarr[k].Longitude;
                      l.Coordinates.Add.Assign(co);
                    end;

                    jas := TTMSFNCUtils.GetJSONValue(jol, 'actions') as TJSONArray;
                    if Assigned(jas) then
                    begin
                      for k := 0 to TTMSFNCUtils.GetJSONArraySize(jas) - 1 do
                      begin
                        jos := TTMSFNCUtils.GetJSONArrayItem(jas, k);

                        step := di.Steps.Add;

                        offset := TTMSFNCUtils.GetJSONIntegerValue(jos, 'offset');
                        if (di.Coordinates.Count > offset) and (offset >= 0) then
                        begin
                          step.StartLocation.Latitude := di.Coordinates[offset].Latitude;
                          step.StartLocation.Longitude := di.Coordinates[offset].Longitude;
                        end;

                        step.Instructions := TTMSFNCUtils.GetJSONProp(jos, 'instruction');
                        step.Distance := TTMSFNCUtils.GetJSONIntegerValue(jos, 'length');
                        step.Duration := TTMSFNCUtils.GetJSONIntegerValue(jos, 'duration');

                        lstep := l.Steps.Add;
                        lstep.Assign(step);
                      end;
                    end;

                    ol := TTMSFNCUtils.GetJSONValue(jol, 'summary') as TJSONObject;
                    if Assigned(ol) then
                    begin
                      l.Distance := TTMSFNCUtils.GetJSONIntegerValue(ol, 'length');
                      di.Distance := di.Distance + l.Distance;
                      l.Duration := TTMSFNCUtils.GetJSONIntegerValue(ol, 'duration');
                      di.Duration := di.Duration + l.Duration;
                    end;
                  end;
                end;
              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

{ TTMSFNCDirectionsHereDirectionsDecoder }

constructor TTMSFNCDirectionsHereDirectionsDecoder.Create(AEncoded: string);
begin
  encoded := AEncoded;
  index := 0;
  decodeHeader;
  latConverter := TTMSFNCDirectionsHereDirectionsConverter.Create(precision);
  lngConverter := TTMSFNCDirectionsHereDirectionsConverter.Create(precision);
  zConverter := TTMSFNCDirectionsHereDirectionsConverter.Create(thirdDimPrecision);
end;

class function TTMSFNCDirectionsHereDirectionsDecoder.Decode(
  AEncoded: string): TTMSFNCMapsCoordinateRecArray;
var
  d: TTMSFNCDirectionsHereDirectionsDecoder;
  a: TTMSFNCMapsCoordinateRecArray;
  lat, lng, z: Double;
begin
  if AEncoded = '' then
    Exit;

  d := TTMSFNCDirectionsHereDirectionsDecoder.Create(AEncoded);
  try
    lat := 0;
    lng := 0;
    z := 0;
    while d.decodeOne(lat, lng, z) do
    begin
      SetLength(a, Length(a) + 1);
      a[Length(a) - 1] := CreateCoordinate(lat, lng);
    end;

    Result := a;

  finally
    d.Free;
  end;
end;

procedure TTMSFNCDirectionsHereDirectionsDecoder.decodeHeader;
var
  td, header: Integer;
begin
  decodeHeaderFromString(encoded, index, header);
  precision := header and 15;
  header := header shr 4;
  td := (header and 7);
  thirdDimension := TTMSFNCDirectionsHereDirectionsThirdDimension.fromNum(td);
end;

procedure TTMSFNCDirectionsHereDirectionsDecoder.decodeHeaderFromString(enc: string; var idx: Integer; var h: Integer);
var
  value: Integer;
begin
  if not (TTMSFNCDirectionsHereDirectionsConverter.decodeUnsignedVarInt(enc, idx, value)) then
    Exit;

  if value <> 1 then
    Exit;

  if not (TTMSFNCDirectionsHereDirectionsConverter.decodeUnsignedVarInt(enc, index, value)) then
    Exit;

  h := value;
end;

function TTMSFNCDirectionsHereDirectionsDecoder.decodeOne(var lat, lng, z: Double): Boolean;
begin
  if index = Length(encoded) then
  begin
    Result := False;
    Exit;
  end;

  if not latConverter.decodeValue(encoded, index, lat) then
    raise Exception.Create('Invalid encoding');

  if not lngConverter.decodeValue(encoded, index, lng) then
    raise Exception.Create('Invalid encoding');

  if hasThirdDimension then
  begin
    if not zConverter.decodeValue(encoded, index, z) then
      raise Exception.Create('Invalid encoding');
  end;

  Result := True;
end;

destructor TTMSFNCDirectionsHereDirectionsDecoder.Destroy;
begin
  latConverter.Free;
  lngConverter.Free;
  zConverter.Free;
  inherited;
end;

function TTMSFNCDirectionsHereDirectionsDecoder.hasThirdDimension: Boolean;
begin
  Result := thirdDimension <> tdAbsent;
end;

{ TTMSFNCDirectionsHereDirectionsConverter }

constructor TTMSFNCDirectionsHereDirectionsConverter.Create(precision: Integer);
begin
  setPrecision(precision);
end;

class function TTMSFNCDirectionsHereDirectionsConverter.decodeChar(charValue: Char): Integer;
var
  pos: Integer;
begin
  pos := Ord(charValue) - 45;
  if (pos < 0) or (pos > 77) then
  begin
    Result := -1;
    Exit;
  end;

  Result := DECODING_TABLE[pos];
end;

class function TTMSFNCDirectionsHereDirectionsConverter.decodeUnsignedVarInt(enc: string; var idx: integer;
  var res: Integer): Boolean;
var
  shift, delta, value: Integer;
begin
  shift := 0;
  delta := 0;

  while idx < Length(enc) do
  begin
    value := decodeChar(enc[idx{$IFNDEF ZEROSTRINGINDEX}+ 1{$ENDIF}]);
    if value < 0 then
    begin
      Result := False;
      Exit;
    end;

    Inc(idx);
    delta := delta or (value and $1F) shl shift;
    if (value and $20) = 0 then
    begin
      res := delta;
      Result := True;
      Exit;
    end
    else
      Inc(shift, 5);
  end;

  if shift > 0 then
  begin
    Result := False;
    Exit;
  end;

  Result := True;
end;

function TTMSFNCDirectionsHereDirectionsConverter.decodeValue(enc: string; var idx: Integer;
  var c: Double): Boolean;
var
  delta: Integer;
begin
  if not decodeUnsignedVarInt(enc, idx, delta) then
  begin
    Result := False;
    Exit;
  end;

  if (delta and 1) <> 0 then
    delta := not delta;

  delta := delta div (1 shl 1);
  Inc(lastValue, delta);
  c := lastValue / multiplier;

  Result := True;
end;

procedure TTMSFNCDirectionsHereDirectionsConverter.setPrecision(precision: Integer);
begin
  multiplier := Round(Power(10, precision));
end;

{ TTMSFNCDirectionsHereDirectionsThirdDimension }

class function TTMSFNCDirectionsHereDirectionsThirdDimension.fromNum(value: Integer): TTMSFNCDirectionsHereDirectionsThirdDimensionValue;
var
  I: TTMSFNCDirectionsHereDirectionsThirdDimensionValue;
begin
  Result := tdAbsent;
  for I := Low(TTMSFNCDirectionsHereDirectionsThirdDimensionValueEnum) to High(TTMSFNCDirectionsHereDirectionsThirdDimensionValueEnum) do
  begin
    if value = Integer(I) then
    begin
      Result := I;
      Break;
    end;
  end;
end;

end.

