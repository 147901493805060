{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCDirections.MapBox;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterMapBoxDirectionsService;
procedure UnRegisterMapBoxDirectionsService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCDirections,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCDirectionsMapBoxService = class;

  TTMSFNCDirectionsMapBoxDirectionsFactoryService = class(TTMSFNCDirectionsFactoryService, ITMSFNCDirectionsServiceMapBox);

  TTMSFNCDirectionsMapBoxService = class(TTMSFNCDirectionsMapBoxDirectionsFactoryService)
  protected
    function DoCreateDirections: ITMSFNCCustomDirections; override;
  end;

type
  TTMSFNCDirectionsMapBoxDirections = class;

  TTMSFNCDirectionsMapBoxDirections = class(TTMSFNCCustomDirectionsInterfacedObject, ITMSFNCCustomDirections)
  protected
    FTravelMode: TTMSFNCDirectionsTravelMode;
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; ATravelMode: TTMSFNCDirectionsTravelMode; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
    function GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
    function GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
    procedure Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyDirections;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  DirectionsService: ITMSFNCDirectionsServiceMapBox;

procedure RegisterMapBoxDirectionsService;
begin
  if not TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceMapBox, IInterface(DirectionsService)) then
  begin
    DirectionsService := TTMSFNCDirectionsMapBoxService.Create;
    TTMSFNCDirectionsPlatformServices.Current.AddPlatformService(ITMSFNCDirectionsServiceMapBox, DirectionsService);
  end;
end;

procedure UnregisterMapBoxDirectionsService;
begin
  TTMSFNCDirectionsPlatformServices.Current.RemovePlatformService(ITMSFNCDirectionsServiceMapBox);
end;

{ TTMSFNCDirectionsMapBoxService }

function TTMSFNCDirectionsMapBoxService.DoCreateDirections: ITMSFNCCustomDirections;
begin
  Result := TTMSFNCDirectionsMapBoxDirections.Create;
end;

procedure TTMSFNCDirectionsMapBoxDirections.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCDirectionsMapBoxDirections.Create;
begin
  inherited;
end;

destructor TTMSFNCDirectionsMapBoxDirections.Destroy;
begin
  inherited;
end;

procedure TTMSFNCDirectionsMapBoxDirections.DestroyDirections;
begin
  DirectionsService.DestroyDirections(Self);
end;

function TTMSFNCDirectionsMapBoxDirections.GetHost: string;
begin
  Result := 'https://api.mapbox.com';
end;

function TTMSFNCDirectionsMapBoxDirections.GetIdentifier: string;
begin
  Result := 'MapBox';
end;

function TTMSFNCDirectionsMapBoxDirections.GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
  ADestinationLatitude, ADestinationLongitude: Double; ATravelMode: TTMSFNCDirectionsTravelMode; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
var
  TravelMode, WayPoints: string;
  I: Integer;
begin

  if ATravelMode in [tmTruck, tmPublicTransport] then
    FTravelMode := tmDriving
  else
    FTravelMode := ATravelMode;

  case ATravelMode of
    tmDriving: TravelMode := 'driving';
    tmWalking: TravelMode := 'walking';
    tmBicycling: TravelMode := 'cycling';
  end;

  AOrigin := FloatToStrDot(AOriginLongitude) + ',' + FloatToStrDot(AOriginLatitude);
  ADestination := FloatToStrDot(ADestinationLongitude) + ',' + FloatToStrDot(ADestinationLatitude);

  WayPoints := AOrigin;
  if Assigned(AWaypoints) and (Length(AWayPoints) > 0) then
  begin
    for I := 0 to (Length(AWayPoints) - 1) do
      WayPoints := WayPoints + ';' + FloatToStrDot(AWayPoints[I].Longitude) + ',' + FloatToStrDot(AWayPoints[I].Latitude);
  end;
  WayPoints := WayPoints + ';' + ADestination;

  Result := '/directions/v5/mapbox/' + TravelMode + '/' + WayPoints;
end;

function TTMSFNCDirectionsMapBoxDirections.GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
begin
  Result := '';
end;

function TTMSFNCDirectionsMapBoxDirections.GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
  ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
  ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
  AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
begin
  Result := 'geometries=polyline'
  + '&alternatives=' + LowerCase(BoolToStr(AAlternatives, True))
  + '&overview=full'
  + '&steps=true'
  + '&access_token=' + DirectionsProperties.GetAPIKey
  + '&language=' + ALocale;

  if AAVoidTolls then
    Result := Result + '&exclude=toll';
end;

function TTMSFNCDirectionsMapBoxDirections.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCDirectionsMapBoxDirections.IsValid: Boolean;
begin
  Result := DirectionsProperties.GetAPIKey <> '';
end;

procedure TTMSFNCDirectionsMapBoxDirections.Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
var
  d: TTMSFNCDirectionsItems;
  di: TTMSFNCDirectionsItem;
  l: TTMSFNCDirectionsLeg;
  step, lstep: TTMSFNCDirectionsStep;

  o, jo, jol, jos, joc: TJSONValue;
  jar, jal, jas, jac: TJSONArray;
  i, j, k: Integer;
  os: TJSONObject;
  lm: Integer;
begin
  if Assigned(DirectionsProperties) then
  begin
    ARequest.TravelMode := FTravelMode;

    d := ARequest.Items;
    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'message');
            ARequest.ErrorMessage := ARequest.Status;

            jar := TTMSFNCUtils.GetJSONValue(o, 'routes') as TJSONArray;
            if Assigned(jar) then
            begin
              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
              begin
                di := d.Add;
                di.Summary := 'Route ' + IntToStr(i);

                jo := TTMSFNCUtils.GetJSONArrayItem(jar, i);

                di.Distance := Round(TTMSFNCUtils.GetJSONDoubleValue(jo, 'distance'));
                di.Duration := Round(TTMSFNCUtils.GetJSONDoubleValue(jo, 'duration'));

                DecodeValues(TTMSFNCUtils.GetJSONProp(jo, 'geometry'), di.Coordinates);

                jal := TTMSFNCUtils.GetJSONValue(jo, 'legs') as TJSONArray;
                if Assigned(jal) then
                begin
                  for j := 0 to TTMSFNCUtils.GetJSONArraySize(jal) - 1 do
                  begin
                    jol := TTMSFNCUtils.GetJSONArrayItem(jal, j);

                    l := di.Legs.Add;

                    l.Distance := Round(TTMSFNCUtils.GetJSONDoubleValue(jol, 'distance'));
                    l.Duration := Round(TTMSFNCUtils.GetJSONDoubleValue(jol, 'duration'));

                    jas := TTMSFNCUtils.GetJSONValue(jol, 'steps') as TJSONArray;
                    if Assigned(jas) then
                    begin
                      for k := 0 to TTMSFNCUtils.GetJSONArraySize(jas) - 1 do
                      begin
                        jos := TTMSFNCUtils.GetJSONArrayItem(jas, k);

                        step := di.Steps.Add;

                        step.Distance := Round(TTMSFNCUtils.GetJSONDoubleValue(jos, 'distance'));
                        step.Duration := Round(TTMSFNCUtils.GetJSONDoubleValue(jos, 'duration'));

                        DecodeValues(TTMSFNCUtils.GetJSONProp(jos, 'geometry'), step.Coordinates);

                        for lm := 0 to step.Coordinates.Count - 1 do
                          l.Coordinates.Add.Assign(step.Coordinates[lm]);

                        if l.Coordinates.Count > 0 then
                        begin
                          l.StartLocation.Assign(l.Coordinates[0].Coordinate);
                          l.EndLocation.Assign(l.Coordinates[l.Coordinates.Count - 1].Coordinate);
                        end;

                        os := TTMSFNCUtils.GetJSONValue(jos, 'maneuver') as TJSONObject;
                        if Assigned(os) then
                        begin
                          step.Instructions := TTMSFNCUtils.GetJSONProp(os, 'instruction');

                          jac := TTMSFNCUtils.GetJSONValue(os, 'location') as TJSONArray;
                          if Assigned(jac) then
                          begin
                            joc := TTMSFNCUtils.GetJSONArrayItem(jac, 0);
                            step.StartLocation.Longitude := StrToFloatDot(joc.ToString);
                            joc := TTMSFNCUtils.GetJSONArrayItem(jac, 1);
                            step.StartLocation.Latitude := StrToFloatDot(joc.ToString);
                          end;
                          lstep := l.Steps.Add;
                          lstep.Assign(step);
                        end;
                      end;
                    end;
                  end;
                end;
              end;

            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

