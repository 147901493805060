{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCMaps.BingMaps;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterBingMapsService;
procedure UnRegisterBingMapsService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCMaps, WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes
  {$IFDEF WEBLIB}
  ,Contnrs, Web
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCMapsBingMapsService = class;

  TTMSFNCMapsBingMapsFactoryService = class(TTMSFNCMapsFactoryService, ITMSFNCMapsServiceBingMaps);

  TTMSFNCMapsBingMapsService = class(TTMSFNCMapsBingMapsFactoryService)
  protected
    function DoCreateMaps: ITMSFNCCustomMaps; override;
  end;

type
  TTMSFNCMapsBingMaps = class;

  TTMSFNCMapsBingMaps = class(TTMSFNCCustomMapsInterfacedObject, ITMSFNCCustomMaps)
  protected
    procedure GetHeadLinks(const AList: TTMSFNCMapsLinksList);
    procedure RemoveScripts;
    function GetResetMap: string;
    function GetShowPopup: string;
    function GetClosePopup: string;
    function GetHeadStyle: string;
    function GetDelayLoadEvent: string;
    function GetMapsServiceCheck: string;
    function GetIdentifier: string;
    function GetInitializeMap: string;
    function GetInitializeEvents: string;
    function GetAddOrUpdateMarker: string;
    function GetDeleteMarker: string;
    function GetAddOrUpdatePolyElement: string;
    function GetDeletePolyElement: string;
    function GetZoomToBounds: string;
    function GetGetBounds: string;
    function GetGetCenterCoordinate: string;
    function GetGetZoomLevel: string;
    function GetLatLonToXY: string;
    function GetXYToLatLon: string;
    function GetSetCenterCoordinate: string;
    function GetSetZoomLevel: string;
    function GetUpdateOptions: string;
    function GetGlobalVariables: string;
    function GetAddCoordinateToArray: string;
    function GetAddHoleToArray: string;
    function GetInitializeCoordinateArray: string;
    function GetInitializeHolesArray: string;
    function IsValid: Boolean;
    procedure DestroyMaps;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  MapsService: ITMSFNCMapsServiceBingMaps;

const
  BINGMAPSAPIURL = 'https://www.bing.com/api/maps/';
  MAPSERVICEVAR = 'window.Microsoft.Maps';
  DOUBLECLICKZOOM = 'dblclickZoom';
  SCROLLZOOM = 'scrollZoom';

procedure RegisterBingMapsService;
begin
  if not TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceBingMaps, IInterface(MapsService)) then
  begin
    MapsService := TTMSFNCMapsBingMapsService.Create;
    TTMSFNCMapsPlatformServices.Current.AddPlatformService(ITMSFNCMapsServiceBingMaps, MapsService);
  end;
end;

procedure UnregisterBingMapsService;
begin
  TTMSFNCMapsPlatformServices.Current.RemovePlatformService(ITMSFNCMapsServiceBingMaps);
end;

{ TTMSFNCMapsBingMapsService }

function TTMSFNCMapsBingMapsService.DoCreateMaps: ITMSFNCCustomMaps;
begin
  Result := TTMSFNCMapsBingMaps.Create;
end;

constructor TTMSFNCMapsBingMaps.Create;
begin
  inherited;
end;

destructor TTMSFNCMapsBingMaps.Destroy;
begin
  inherited;
end;

procedure TTMSFNCMapsBingMaps.DestroyMaps;
begin
  MapsService.DestroyMaps(Self);
end;

function TTMSFNCMapsBingMaps.GetAddCoordinateToArray: string;
begin
  Result := '  ' + COORDINATEARRAYVAR + '.push(new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["Latitude"],' + PARAMSNAME + '["Longitude"]));';
end;

function TTMSFNCMapsBingMaps.GetAddHoleToArray: string;
begin
  Result := 'var o = ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]];' + LB +
    'if (o){' + LB +
    '  ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]].push(new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '[1]["Latitude"], ' + PARAMSNAME + '[1]["Longitude"]));' + LB +
    '}else{' + LB +
    '  ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]] = [new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '[1]["Latitude"], ' + PARAMSNAME + '[1]["Longitude"])];' + LB +
    '}';
end;

function TTMSFNCMapsBingMaps.GetAddOrUpdateMarker: string;
begin
  Result :=
    '  var options = {' + LB +
    '    title: ' + PARAMSNAME + '["Title"],' + LB +
    '    icon: ' + PARAMSNAME + '["IconURL"],' + LB +
    '    visible: ' + PARAMSNAME + '["Visible"]' + LB +
    '  }' + LB + LB +

    '  var loc = new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"])' + LB + LB +

    '  if (!' + MARKERVAR + '){' + LB +
    '    ' + MARKERVAR + ' = new ' + MAPSERVICEVAR + '.Pushpin(loc, options);' + LB +
    '    ' + MAPVAR + '.entities.push(' + MARKERVAR + ');' + LB +
    '    ' + MAPSERVICEVAR + '.Events.addHandler(' + MARKERVAR + ', ''click'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPSERVICEVAR + '.Events.addHandler(' + MARKERVAR + ', ''dblclick'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPSERVICEVAR + '.Events.addHandler(' + MARKERVAR + ', ''mouseup'', function(event){' + LB +
    '      activeElement = ' + MARKERVAR + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPSERVICEVAR + '.Events.addHandler(' + MARKERVAR + ', ''mousedown'', function(event){' + LB +
    '      activeElement = ' + MARKERVAR + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPSERVICEVAR + '.Events.addHandler(' + MARKERVAR + ', ''mouseout'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPSERVICEVAR + '.Events.addHandler(' + MARKERVAR + ', ''mouseover'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +

    '    if (mouseUpId != -1) {' + LB +
    '      ' + MAPSERVICEVAR + '.Events.removeHandler(mouseUpId);' + LB +
    '    }' + LB +
    '    if (mouseDownId != -1) {' + LB +
    '      ' + MAPSERVICEVAR + '.Events.removeHandler(mouseDownId);' + LB +
    '    }' + LB +
    '    mouseUpId = ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''mouseup'', function(event){' + LB +
    '      if (activeElement){' + LB +
    '        activeElement = null; ' + LB +
    '        return;' + LB +
    '      }' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MapMouseUp"));' + LB +
    '    })' + LB + LB +
    '    mouseDownId = ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''mousedown'', function(event){' + LB +
    '      if (activeElement){' + LB +
    '        activeElement = null; ' + LB +
    '        return;' + LB +
    '      }' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MapMouseDown"));' + LB +
    '    })' + LB + LB +

    '  }else{' + LB +
    '    ' + MARKERVAR + '.setOptions(options);' + LB +
    '    ' + MARKERVAR + '.setLocation(loc);' + LB +
    '  }';
end;

function TTMSFNCMapsBingMaps.GetAddOrUpdatePolyElement: string;
begin
  Result :=
    '  var c = ' + PARAMSNAME + '["$type"];' + LB +
    '  var options;' + LB +
    '  var co = [];' + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '      co.push(new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"]));' + LB +
    '      co.push(new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"]));' + LB +
    '      co.push(new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"]));' + LB +
    '      co.push(new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"]));' + LB +
    '      co.push(new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"]));' + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '      if (' + MAPSERVICEVAR + '.SpatialMath){' + LB +
    '        co = ' + MAPSERVICEVAR + '.SpatialMath.getRegularPolygon(new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["Center"]["Latitude"], ' + PARAMSNAME + '["Center"]["Longitude"]), ' + PARAMSNAME + '["Radius"], 100);' + LB +
    '      }else{' + LB +
    '        co.push(new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["Center"]["Latitude"], ' + PARAMSNAME + '["Center"]["Latitude"]));' + LB +
    '      }' + LB +
    '    break;' + LB +
    '  }' + LB + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsPolyline":' + LB +
    '    options = {' + LB +
    '      visible: ' + PARAMSNAME + '["Visible"],' + LB +
    '      strokeColor: toRGBA(' + PARAMSNAME + '["StrokeColor"], ' + PARAMSNAME + '["StrokeOpacity"]),' + LB +
    '      strokeThickness: ' + PARAMSNAME + '["StrokeWidth"]' + LB +
    '    }' + LB + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsPolygon":' + LB +
    '    case "TTMSFNCBingMapsPolygon":' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '    options = {' + LB +
    '      visible: ' + PARAMSNAME + '["Visible"],' + LB +
    '      fillColor: toRGBA(' + PARAMSNAME + '["FillColor"], ' + PARAMSNAME + '["FillOpacity"]),' + LB +
    '      strokeColor: toRGBA(' + PARAMSNAME + '["StrokeColor"], ' + PARAMSNAME + '["StrokeOpacity"]),' + LB +
    '      strokeThickness: ' + PARAMSNAME + '["StrokeWidth"]' + LB +
    '    }' + LB +
    '    break;' + LB +
    '  }' + LB + LB +

    '  if (!' + POLYELEMENTVAR + '){' + LB +
    '    switch(c){' + LB +
    '      case "TTMSFNCMapsPolyline":' + LB +
    '      ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.Polyline(' + COORDINATEARRAYVAR + ', options);' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsCircle":' + LB +
    '      case "TTMSFNCMapsRectangle":' + LB +
    '      ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.Polygon(co, options);' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsPolygon":' + LB +
    '      case "TTMSFNCBingMapsPolygon":' + LB +

    '      var pthArr = [];' + LB +
    '      pthArr.push(' + COORDINATEARRAYVAR + ');' + LB +
    '      for (var key in ' + HOLEARRAYVAR + '){' + LB +
    '        pthArr.push(' + HOLEARRAYVAR + '[key]);' + LB +
    '      }' + LB +

    '      ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.Polygon(pthArr, options);' + LB +
    '      break;' + LB +
    '    }' + LB +
    '    ' + MAPVAR + '.entities.push(' + POLYELEMENTVAR + ');' + LB +
    '    ' + MAPSERVICEVAR + '.Events.addHandler(' + POLYELEMENTVAR + ', ''click'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPSERVICEVAR + '.Events.addHandler(' + POLYELEMENTVAR + ', ''dblclick'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPSERVICEVAR + '.Events.addHandler(' + POLYELEMENTVAR + ', ''mouseup'', function(event){' + LB +
    '      activeElement = ' + POLYELEMENTVAR + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPSERVICEVAR + '.Events.addHandler(' + POLYELEMENTVAR + ', ''mousedown'', function(event){' + LB +
    '      activeElement = ' + POLYELEMENTVAR + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPSERVICEVAR + '.Events.addHandler(' + POLYELEMENTVAR + ', ''mouseout'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPSERVICEVAR + '.Events.addHandler(' + POLYELEMENTVAR + ', ''mouseover'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +

    '    if (mouseUpId != -1) {' + LB +
    '      ' + MAPSERVICEVAR + '.Events.removeHandler(mouseUpId);' + LB +
    '    }' + LB +
    '    if (mouseDownId != -1) {' + LB +
    '      ' + MAPSERVICEVAR + '.Events.removeHandler(mouseDownId);' + LB +
    '    }' + LB +
    '    mouseUpId = ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''mouseup'', function(event){' + LB +
    '      if (activeElement){' + LB +
    '        activeElement = null; ' + LB +
    '        return;' + LB +
    '      }' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MapMouseUp"));' + LB +
    '    })' + LB + LB +
    '    mouseDownId = ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''mousedown'', function(event){' + LB +
    '      if (activeElement){' + LB +
    '        activeElement = null; ' + LB +
    '        return;' + LB +
    '      }' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MapMouseDown"));' + LB +
    '    })' + LB + LB +

    '  }else{' + LB +
    '    switch(c){' + LB +
    '      case "TTMSFNCMapsPolyline":' + LB +
    '      case "TTMSFNCMapsPolygon":' + LB +
    '      ' + POLYELEMENTVAR + '.setLocations(' + COORDINATEARRAYVAR + ');' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsRectangle":' + LB +
    '      case "TTMSFNCMapsCircle":' + LB +
    '      ' + POLYELEMENTVAR + '.setLocations(co);' + LB +
    '      break;' + LB +
    '    }' + LB +
    '    ' + POLYELEMENTVAR + '.setOptions(options);' + LB +
    '  }';
end;

function TTMSFNCMapsBingMaps.GetClosePopup: string;
begin
  Result := '  ' + POPUPVAR + '.setMap(null);';
end;

function TTMSFNCMapsBingMaps.GetDelayLoadEvent: string;
begin
  Result := '  ' + MAPSERVICEVAR + '.loadModule(''Microsoft.Maps.SpatialMath'', function () {';
end;

function TTMSFNCMapsBingMaps.GetDeleteMarker: string;
begin
  Result := '  ' + MAPVAR + '.entities.remove(' + MARKERVAR + ')';
end;

function TTMSFNCMapsBingMaps.GetDeletePolyElement: string;
begin
  Result := '  ' + MAPVAR + '.entities.remove(' + POLYELEMENTVAR + ')';
end;

function TTMSFNCMapsBingMaps.GetGetBounds: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getBounds();' + LB +
    '  jsonObj["NorthEast"]["Latitude"] = loc.getNorth();' + LB +
    '  jsonObj["NorthEast"]["Longitude"] = loc.getEast();' + LB +
    '  jsonObj["SouthWest"]["Latitude"] = loc.getSouth();' + LB +
    '  jsonObj["SouthWest"]["Longitude"] = loc.getWest();';
end;

function TTMSFNCMapsBingMaps.GetGetCenterCoordinate: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getCenter();' + LB +
    '  jsonObj["Latitude"] = loc.latitude;' + LB +
    '  jsonObj["Longitude"] = loc.longitude;';
end;

function TTMSFNCMapsBingMaps.GetLatLonToXY: string;
begin
  Result :=
    '  var loc = new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"]);' + LB +
    '  point = ' + MAPVAR + '.tryLocationToPixel(loc, ' + MAPSERVICEVAR + '.PixelReference.control);' + LB +
    '  jsonObj["X"] = parseFloat(point.x);' + LB +
    '  jsonObj["Y"] = parseFloat(point.y);';
end;

function TTMSFNCMapsBingMaps.GetXYToLatLon: string;
begin
  Result :=
    '  var point = new ' + MAPSERVICEVAR + '.Point(' + PARAMSNAME + '["X"], ' + PARAMSNAME + '["Y"]);' + LB +
    '  var loc = map.tryPixelToLocation(point, ' + MAPSERVICEVAR + '.PixelReference.control);' + LB +
    '  jsonObj["Latitude"] = parseFloat(loc.lat);' + LB +
    '  jsonObj["Longitude"] = parseFloat(loc.lng);';
end;

function TTMSFNCMapsBingMaps.GetGetZoomLevel: string;
begin
  Result := '  var z = ' + MAPVAR + '.getZoom();';
end;

function TTMSFNCMapsBingMaps.GetGlobalVariables: string;
begin
   Result :=
    'var activeElement = null;' + LB +
    'var mouseDownId = -1;' + LB +
    'var mouseUpId = -1;' + LB +
    'var ' + DOUBLECLICKZOOM + ';' + LB +
    'var ' + SCROLLZOOM + ';' + LB + LB +
    'function parseEvent(event, eventname, id = ''''){' + LB +
    '  var loc = {''Latitude'': 0, ''Longitude'': 0};' + LB +
    '  var x = 0.0;' + LB +
    '  var y = 0.0;' + LB +
    '  if (event) {' + LB +
    '    if (event.location){' + LB +
    '      loc = {''Latitude'': event.location.latitude, ''Longitude'': event.location.longitude};' + LB +
    '    }' + LB +
    '    if (event.pageX && event.pageY){' + LB +
    '      x = event.pageX;' + LB +
    '      y = event.pageY;' + LB +
    '    }' + LB +
    '  }' + LB +

    '  var r = {''Coordinate'': loc, ''X'': x, ''Y'': y, ''ID'': id, ''EventName'': eventname};' + LB +
    '  return r;' + LB +
    '}';
end;

procedure TTMSFNCMapsBingMaps.GetHeadLinks(
  const AList: TTMSFNCMapsLinksList);
begin
  AList.Add(TTMSFNCMapsLink.CreateScript(BINGMAPSAPIURL + 'mapcontrol?callback=MapCallBack&setMkt=' + MapsProperties.GetLocale + '&setLang=' +
    MapsProperties.GetLocale(mlmCountry) + '&key=' + MapsProperties.GetAPIKey, 'text/javascript'));
end;

function TTMSFNCMapsBingMaps.GetHeadStyle: string;
begin
  Result :=
    '.customInfobox {' + LB +
    '  background:white;' + LB +
    '  white-space:normal;' + LB +
    '  padding: 10px;' + LB +
    '}';
end;

function TTMSFNCMapsBingMaps.GetIdentifier: string;
begin
  Result := 'Bing Maps';
end;

function TTMSFNCMapsBingMaps.GetInitializeCoordinateArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsBingMaps.GetInitializeHolesArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsBingMaps.GetInitializeEvents: string;
begin
  Result :=
  '  var oldZoom = ' + MAPVAR + '.getZoom();' + LB +
  '  ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ',''viewchangeend'', function(){' + LB +
  '    var newZoom = ' + MAPVAR + '.getZoom();' + LB +
  '    if(newZoom != oldZoom){' + LB +
  '      ' + GETSENDEVENT + '(parseEvent(null, "ZoomChanged"));' + LB +
  '    }' + LB +
  '    oldZoom = newZoom;' + LB +
  '  })' + LB + LB +
  '  ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''maptypechanged'', function(){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(null, "MapTypeChanged"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''viewchangestart'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMoveStart"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''viewchangeend'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMoveEnd"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''click'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapClick"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''dblclick'', function(event){' + LB +
  '    if (!' + DOUBLECLICKZOOM + '){' + LB +
  '      ' + MAPVAR + '.setOptions({ disableZooming: true });' + LB +
  '      setTimeout(function () { return ' + MAPVAR + '.setOptions({ disableZooming: false}); });' + LB +
  '    }' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapDblClick"));' + LB +
  '  })' + LB + LB +
  '  mouseUpId = ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''mouseup'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseUp"));' + LB +
  '  })' + LB + LB +
  '  mouseDownId = ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''mousedown'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseDown"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''mouseout'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseLeave"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''mouseover'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseEnter"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''mousemove'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseMove"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPSERVICEVAR + '.Events.addHandler(' + MAPVAR + ', ''mousewheel'', function(event){' + LB +
  '    if (' + SCROLLZOOM + '){' + LB +
  '      var zoom = ' + MAPVAR + '.getZoom();' + LB +

  '      if (event.wheelDelta > 0 || event.detail < 0) {' + LB +
  '        zoom++;' + LB +
  '      } else {' + LB +
  '        zoom--;' + LB +
  '      }' + LB +

  '      if (zoom <= 0) {' + LB +
  '        zoom = 1;' + LB +
  '      } else if (zoom >= 21) {' + LB +
  '        zoom = 21;' + LB +
  '      }' + LB +

  '      ' + MAPVAR + '.setView({ zoom: zoom });' + LB +
  '    }' + LB +
  '  })';
end;

function TTMSFNCMapsBingMaps.GetInitializeMap: string;
begin
  Result :=
    '  ' + DOUBLECLICKZOOM + ' = ' + LowerCase(BoolToStr(MapsProperties.GetZoomOnDblClick, True)) + ';' + LB +
    '  ' + SCROLLZOOM + ' = ' + LowerCase(BoolToStr(MapsProperties.GetZoomOnWheelScroll, True)) + ';' + LB + LB +

    '  ' + MAPVAR + ' = new ' + MAPSERVICEVAR + '.Map("#' + MAPID + '", {' + LB +
    '    disableScrollWheelZoom: true,' + LB +
    '    disablePanning: ' + LowerCase(BoolToStr(not MapsProperties.GetPanning, True)) + ',' + LB +
    '    showZoomButtons: ' + LowerCase(BoolToStr(MapsProperties.GetShowZoomControl, True)) + ',' + LB +
    '    showMapTypeSelector: ' + LowerCase(BoolToStr(MapsProperties.GetShowMapTypeControl, True)) + LB +
    '  });' + LB + LB +

    '  ' + MAPVAR + '.setView({' + LB +
    '    zoom: ' + MapsProperties.GetDefaultZoomLevel +',' + LB +
    '    center: new ' + MAPSERVICEVAR + '.Location(' + MapsProperties.GetDefaultLatitude +', ' + MapsProperties.GetDefaultLongitude + ')' + LB +
    '  });';
end;

function TTMSFNCMapsBingMaps.GetMapsServiceCheck: string;
begin
  Result := '!(MapCallBackFlag == true) || !window.Microsoft || !' + MAPSERVICEVAR + ' || !' + MAPSERVICEVAR + '.Map';
end;

function TTMSFNCMapsBingMaps.GetResetMap: string;
begin
  Result := '';
end;

function TTMSFNCMapsBingMaps.GetSetCenterCoordinate: string;
begin
  Result :=
    '  var lat = new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"])' + LB +
    '  ' + MAPVAR + '.setView({ center: lat});';
end;

function TTMSFNCMapsBingMaps.GetSetZoomLevel: string;
begin
  Result :=
    '  ' + MAPVAR + '.setView({ zoom: ' + PARAMSNAME + '});';
end;

function TTMSFNCMapsBingMaps.GetShowPopup: string;
begin
  Result :=
  '  var popupTemplate = ''<div class="customInfobox">{text}</div>'';' + LB +
  '  var loc = new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"]);' + LB +
  '  ' + POPUPVAR + ' = new ' + MAPSERVICEVAR + '.Infobox(loc, {' + LB +
  '    showCloseButton: true,' + LB +
  '    offset: new ' + MAPSERVICEVAR + '.Point(-' + PARAMSNAME + '["OffsetX"], -' + PARAMSNAME + '["OffsetY"]),' + LB +
  '    maxHeight: 10000,' + LB +
  '    maxWidth: 10000,' + LB +
  '    description: popupTemplate.replace(/{text}/g, ' + PARAMSNAME + '["Text"])' + LB +
  '  });' + LB +
  '  ' + POPUPVAR + '.setMap(' + MAPVAR + ');' + LB +
  '  ' + MAPSERVICEVAR + '.Events.addHandler(' + POPUPVAR + ', ''click'', handleClickInfoBox);' + LB +
  '  function handleClickInfoBox(e){' + LB +
  '      var isCloseAction = e.originalEvent.target.className == "infobox-close-img";' + LB +
  '      if(isCloseAction){' + LB +
  '        ' + POPUPVAR + '.setMap(null);' + LB +
  '      }' + LB +
  '    }';

end;

function TTMSFNCMapsBingMaps.GetUpdateOptions: string;
begin
  Result :=
    '  ' + DOUBLECLICKZOOM + ' = ' + PARAMSNAME + '["ZoomOnDblClick"];' + LB +
    '  ' + SCROLLZOOM + ' = ' + PARAMSNAME + '["ZoomOnWheelScroll"];' + LB + LB +

    '  var opt = {' + LB +
    '    disablePanning: !' + PARAMSNAME + '["Panning"],' + LB +
    '    showZoomButtons: ' + PARAMSNAME + '["ShowZoomControl"],' + LB +
    '    showMapTypeSelector: '  + PARAMSNAME + '["ShowMapTypeControl"]' + LB +
    '  };' + LB + LB +

    '  ' + MAPVAR + '.setOptions(opt);';
end;

function TTMSFNCMapsBingMaps.GetZoomToBounds: string;
begin
  Result :=
    '  var sw = new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["SouthWest"]["Latitude"], ' + PARAMSNAME + '["SouthWest"]["Longitude"])' + LB +
    '  var ne = new ' + MAPSERVICEVAR + '.Location(' + PARAMSNAME + '["NorthEast"]["Latitude"], ' + PARAMSNAME + '["NorthEast"]["Longitude"])' + LB +
    '  var locs = [sw, ne];' + LB +
    '  var rect = ' + MAPSERVICEVAR + '.LocationRect.fromLocations(locs);' + LB +
    '  ' + MAPVAR + '.setView({ bounds: rect, padding: 0 });';
end;

function TTMSFNCMapsBingMaps.IsValid: Boolean;
begin
  Result := (MapsProperties.GetAPIKey <> '');
end;

procedure TTMSFNCMapsBingMaps.RemoveScripts;
begin
  {$IFDEF WEBLIB}
  asm
    let keywords = ['www.bing.com/rb/3A', 'www.bing.com/rp'];

    if (window.Microsoft){
      window.Microsoft.Maps = undefined;
    }

    let scripts = document.querySelectorAll('script,link');
    for (let i = scripts.length - 1; i >= 0; i--) {
        let scriptSource = scripts[i].getAttribute('src');
        if (!scriptSource){
          scriptSource = scripts[i].getAttribute('href');
        }
        if (scriptSource != null) {
            if (keywords.filter(item => scriptSource.includes(item)).length) {
                scripts[i].remove();
                // scripts[i].parentNode.removeChild(scripts[i]);
            }
        }
    }
  end;
  {$ENDIF}
end;

end.

