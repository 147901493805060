{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCMaps.MapKit;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

const
  MAPSERVICEVAR = 'window.mapkit';

procedure RegisterMapKitService;
procedure UnRegisterMapKitService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCMaps, WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes
  {$IFDEF WEBLIB}
  ,Contnrs, Web
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCMapsMapKitService = class;

  TTMSFNCMapsMapKitFactoryService = class(TTMSFNCMapsFactoryService, ITMSFNCMapsServiceMapKit);

  TTMSFNCMapsMapKitService = class(TTMSFNCMapsMapKitFactoryService)
  protected
    function DoCreateMaps: ITMSFNCCustomMaps; override;
  end;

type
  TTMSFNCMapsMapKit = class;

  TTMSFNCMapsMapKit = class(TTMSFNCCustomMapsInterfacedObject, ITMSFNCCustomMaps)
  protected
    procedure GetHeadLinks(const AList: TTMSFNCMapsLinksList);
    procedure RemoveScripts;
    function GetResetMap: string;
    function GetShowPopup: string;
    function GetClosePopup: string;
    function GetHeadStyle: string;
    function GetDelayLoadEvent: string;
    function GetMapsServiceCheck: string;
    function GetIdentifier: string;
    function GetInitializeMap: string;
    function GetInitializeEvents: string;
    function GetAddOrUpdateMarker: string;
    function GetDeleteMarker: string;
    function GetAddOrUpdatePolyElement: string;
    function GetDeletePolyElement: string;
    function GetZoomToBounds: string;
    function GetGetBounds: string;
    function GetSetCenterCoordinate: string;
    function GetGetCenterCoordinate: string;
    function GetGetZoomLevel: string;
    function GetSetZoomLevel: string;
    function GetLatLonToXY: string;
    function GetXYToLatLon: string;
    function GetUpdateOptions: string;
    function GetGlobalVariables: string;
    function GetAddCoordinateToArray: string;
    function GetAddHoleToArray: string;
    function GetInitializeCoordinateArray: string;
    function GetInitializeHolesArray: string;
    function IsValid: Boolean;
    procedure DestroyMaps;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  MapsService: ITMSFNCMapsServiceMapKit;

const
  MAPKITAPIVERSION = '5.x.x';
  MAPKITAPIURL = 'https://cdn.apple-mapkit.com/mk/';
  MAPMAXZOOMLEVEL = 51200000;

procedure RegisterMapKitService;
begin
  if not TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceMapKit, IInterface(MapsService)) then
  begin
    MapsService := TTMSFNCMapsMapKitService.Create;
    TTMSFNCMapsPlatformServices.Current.AddPlatformService(ITMSFNCMapsServiceMapKit, MapsService);
  end;
end;

procedure UnregisterMapKitService;
begin
  TTMSFNCMapsPlatformServices.Current.RemovePlatformService(ITMSFNCMapsServiceMapKit);
end;

{ TTMSFNCMapsMapKitService }

function TTMSFNCMapsMapKitService.DoCreateMaps: ITMSFNCCustomMaps;
begin
  Result := TTMSFNCMapsMapKit.Create;
end;

constructor TTMSFNCMapsMapKit.Create;
begin
  inherited;
end;

destructor TTMSFNCMapsMapKit.Destroy;
begin
  inherited;
end;

procedure TTMSFNCMapsMapKit.DestroyMaps;
begin
  MapsService.DestroyMaps(Self);
end;

function TTMSFNCMapsMapKit.GetMapsServiceCheck: string;
begin
  Result := '!' + MAPSERVICEVAR;
end;

function TTMSFNCMapsMapKit.GetResetMap: string;
begin
  Result := '';
end;

function TTMSFNCMapsMapKit.GetSetCenterCoordinate: string;
begin
  Result :=
    '  var co = new ' + MAPSERVICEVAR + '.Coordinate(' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"]);' + LB +
    '  ' + MAPVAR + '.setCenterAnimated(co, true);';
end;

function TTMSFNCMapsMapKit.GetSetZoomLevel: string;
begin
  Result :=
  'var zoomlevel = ' + IntToStr(MAPMAXZOOMLEVEL) + ' / (Math.pow(2, ' + PARAMSNAME + '));' + LB +
  '  ' + MAPVAR + '.setCameraDistanceAnimated(zoomlevel);';
end;

function TTMSFNCMapsMapKit.GetShowPopup: string;
begin
  Result :=
    '  var pid = ' + PARAMSNAME + '["ID"];' + LB +
    '  var co = new ' + MAPSERVICEVAR + '.Coordinate(' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"]);' + LB +
    '  var custompopup = function(coordinate, options) {' + LB +

    '    var div = document.createElement("div");' + LB +
    '    div.className = ''bubble'';' + LB +
    '    var t = ' + PARAMSNAME + '["Text"];' + LB +
    '    div.innerHTML = ''<a href="#" id="popup-closer-popup'' + pid + ''" class="ol-popup-closer"></a><div>'' + t + ''</div>''' + LB +
    '    return div;' + LB +
    '    };' + LB +
    '    ' + POPUPVAR + ' = new ' + MAPSERVICEVAR + '.Annotation(co, custompopup);' + LB +
    '    ' + MAPVAR + '.addAnnotation(' + POPUPVAR + ');' + LB +

    '    var closer = document.getElementById(''popup-closer-popup'' + pid);' + LB +
    '    closer.onclick = function() {' + LB +
    '      ' + MAPVAR + '.removeAnnotation(' + POPUPVAR + ');' + LB +
    '      closer.blur();' + LB +
    '      return false;' + LB +
    '    };'
    ;
end;

function TTMSFNCMapsMapKit.GetUpdateOptions: string;
begin
  Result :=
   '  ' + MAPVAR + '.isScrollEnabled = ' + PARAMSNAME + '["Panning"];' + LB +
   '  ' + MAPVAR + '.showsMapTypeControl = ' + PARAMSNAME + '["ShowMapTypeControl"];' + LB +
   '  ' + MAPVAR + '.showsZoomControl = ' + PARAMSNAME + '["ShowZoomControl"];' + LB +
   '  ' + MAPVAR + '.isZoomEnabled = ((' + PARAMSNAME + '["ZoomOnDblClick"]) && (' + PARAMSNAME + '["ZoomOnWheelScroll"]));';
end;

function TTMSFNCMapsMapKit.GetZoomToBounds: string;
begin
  Result :=
    '  var def = new ' + MAPSERVICEVAR + '.BoundingRegion(' + PARAMSNAME + '["NorthEast"]["Latitude"],' + PARAMSNAME + '["NorthEast"]["Longitude"],' + PARAMSNAME + '["SouthWest"]["Latitude"],' + PARAMSNAME + '["SouthWest"]["Longitude"]);' + LB + LB +
    '  ' + MAPVAR + '.region = def.toCoordinateRegion();';
end;

function TTMSFNCMapsMapKit.GetAddCoordinateToArray: string;
begin
  Result := '  ' + COORDINATEARRAYVAR + '.push([' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"]]);';
end;

function TTMSFNCMapsMapKit.GetAddHoleToArray: string;
begin
  Result := 'var o = ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]];' + LB +
            'if (o){' + LB +
            '  ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]].push([' + PARAMSNAME + '[1]["Latitude"], ' + PARAMSNAME + '[1]["Longitude"]]);' + LB +
            '}else{' + LB +
            '  ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]] = [[' + PARAMSNAME + '[1]["Latitude"], ' + PARAMSNAME + '[1]["Longitude"]]];' + LB +
            '}';
end;

function TTMSFNCMapsMapKit.GetAddOrUpdateMarker: string;
begin
  Result :=
    '  var co = new ' + MAPSERVICEVAR + '.Coordinate(' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"]);' + LB +

    '  if (!' + MARKERVAR + '){' + LB +
    '    if(' + PARAMSNAME + '["IconURL"] == "") {' + LB +
    '    ' + MARKERVAR + ' = new ' + MAPSERVICEVAR + '.MarkerAnnotation(co, { title: ' + PARAMSNAME + '["Title"], visible: ' + PARAMSNAME + '["Visible"], data: { id: ' + PARAMSNAME + '["ID"] } } );' + LB +
    '    } else { ' + LB +
    '    ' + MARKERVAR + ' = new ' + MAPSERVICEVAR + '.ImageAnnotation(co, { title: ' + PARAMSNAME + '["Title"], visible: ' + PARAMSNAME + '["Visible"], data: { id: ' + PARAMSNAME + '["ID"] }, url: { 1: ' + PARAMSNAME + '["IconURL"]}});' + LB +
    '    }' + LB +
    '    ' + MARKERVAR + '.addEventListener(''select'', function(event){' + LB +
    '    ' + GETSENDEVENT + '(parseEvent(event, "MarkerClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPVAR + '.addAnnotation(' + MARKERVAR + ');' + LB +
    '  } else {' + LB +
    '    ' + MARKERVAR + '.coordinate = co;' + LB +
    '    ' + MARKERVAR + '.title = ' + PARAMSNAME + '["Title"];' + LB +
    '    ' + MARKERVAR + '.visible = ' + PARAMSNAME + '["Visible"];' + LB +
    '    if(' + PARAMSNAME + '["IconURL"] != "")' + LB +
    '      ' + MARKERVAR + '.url = { 1: ' + PARAMSNAME + '["IconURL"] };' + LB +
    '  }';
end;

function TTMSFNCMapsMapKit.GetAddOrUpdatePolyElement: string;
begin
  Result :=
    '  var c = ' + PARAMSNAME + '["$type"];' + LB +
    '  var options;'+ LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '    case "TTMSFNCMapsPolygon":' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '      options = new ' + MAPSERVICEVAR  + '.Style({' + LB +
    '        strokeColor: ' + PARAMSNAME + '["StrokeColor"],' + LB +
    '        strokeOpacity: ' + PARAMSNAME + '["StrokeOpacity"],' + LB +
    '        lineWidth: ' + PARAMSNAME + '["StrokeWidth"],' + LB +
    '        fillColor: ' + PARAMSNAME + '["FillColor"],' + LB +
    '        fillOpacity: ' + PARAMSNAME + '["FillOpacity"],' + LB +
    '        fillRule: "evenodd",' + LB +
    '      });' + LB + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsPolyline":' + LB +
    '      options = new ' + MAPSERVICEVAR  + '.Style({' + LB +
    '        strokeColor: ' + PARAMSNAME + '["StrokeColor"],' + LB +
    '        strokeOpacity: ' + PARAMSNAME + '["StrokeOpacity"],' + LB +
    '        lineWidth: ' + PARAMSNAME + '["StrokeWidth"],' + LB +
    '      });' + LB + LB +
    '    break;' + LB +
    '  }' + LB + LB +

    '  function toCoordinates(array) {' + LB +
    '    return array.map(function(element) {' + LB +
    '        return new ' + MAPSERVICEVAR  + '.Coordinate(element[0], element[1]);' + LB +
    '    });' + LB +
    '  }' + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '      var points = ' + COORDINATEARRAYVAR + ';' + lB +
    '      points.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"]]);' + LB +
    '      points.push([' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"]]);' + LB +
    '      points.push([' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"]]);' + LB +
    '      points.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"]]);' + LB +
    '      var coords = points.map(function(point) {' + lB +
    '        return new ' + MAPSERVICEVAR  + '.Coordinate(point[0], point[1]);' + lB +
    '      });' + lB +
    '    break;' + LB +
    '    case "TTMSFNCMapsPolyline":' + LB +
    '      var coords = toCoordinates(' + COORDINATEARRAYVAR + ');' + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsPolygon":' + LB +
    '      var coords = [];' + LB +
    '      coords.push(toCoordinates(' + COORDINATEARRAYVAR + '));' + LB +
    '      for (var key in ' + HOLEARRAYVAR + '){' + LB +
    '        coords.push(toCoordinates(' + HOLEARRAYVAR + '[key]));' + LB +
    '      }' + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '      var coord = new ' + MAPSERVICEVAR  + '.Coordinate(' + PARAMSNAME + '["Center"]["Latitude"], ' + PARAMSNAME + '["Center"]["Longitude"]);' +
    '    break;' + LB +
    '  }' + LB + LB +

    '  if (!' + POLYELEMENTVAR + '){' + LB +
    '    switch(c){' + LB +
    '      case "TTMSFNCMapsPolyline":' + LB +
    '        ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.PolylineOverlay(coords, { style: options });' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsPolygon":' + LB +
    '      case "TTMSFNCMapsRectangle":' + LB +
    '        ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.PolygonOverlay(coords, { style: options });' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsCircle":' + LB +
    '        ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.CircleOverlay(coord, ' + PARAMSNAME + '["Radius"], { style: options });' + LB +
    '      break;' + LB +
    '    }' + LB +
    '    ' + POLYELEMENTVAR + '.data = { id: ' + PARAMSNAME + '["ID"] };' + LB +
    '    ' + POLYELEMENTVAR + '.addEventListener(''select'', function(event){' + LB +
    '    ' + GETSENDEVENT + '(parseEvent(event, "PolyElementClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MAPVAR + '.addOverlay(' + POLYELEMENTVAR + ');' + LB +

    '  }else{' + LB +
    '    ' + POLYELEMENTVAR + '.style = options;' + LB +
    '  }' + LB + LB +

    '  ' + POLYELEMENTVAR + '.visible = ' + PARAMSNAME + '["Visible"];' + LB + LB;
end;

function TTMSFNCMapsMapKit.GetClosePopup: string;
begin
  Result := '  ' + MAPVAR + '.removeAnnotation(' + POPUPVAR + ');';
end;

function TTMSFNCMapsMapKit.GetDelayLoadEvent: string;
begin
  Result := '';
end;

function TTMSFNCMapsMapKit.GetDeleteMarker: string;
begin
  Result := '  ' + MAPVAR + '.removeAnnotation(' + MARKERVAR + ');';
end;

function TTMSFNCMapsMapKit.GetDeletePolyElement: string;
begin
  Result := '  ' + MAPVAR + '.removeOverlay(' + POLYELEMENTVAR + ');';
end;

function TTMSFNCMapsMapKit.GetGetBounds: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.region;' +
    '  var bounds = loc.toBoundingRegion(loc);' +
    '  jsonObj["NorthEast"]["Latitude"] = bounds.northLatitude;' + LB +
    '  jsonObj["NorthEast"]["Longitude"] = bounds.eastLongitude;' + LB +
    '  jsonObj["SouthWest"]["Latitude"] = bounds.southLatitude;' + LB +
    '  jsonObj["SouthWest"]["Longitude"] = bounds.westLongitude;';
end;

function TTMSFNCMapsMapKit.GetGetCenterCoordinate: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.center;' + LB +
    '  jsonObj["Latitude"] = loc.latitude;' + LB +
    '  jsonObj["Longitude"] = loc.longitude;';
end;

function TTMSFNCMapsMapKit.GetLatLonToXY: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.region;' +
    '  var bounds = loc.toBoundingRegion(loc);' +

    '  var coordinate = new ' + MAPSERVICEVAR + '.Coordinate(' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"]);' + LB +
    '  var point = coordinate.toMapPoint();' + LB +

    '  var coordinateNE = new ' + MAPSERVICEVAR + '.Coordinate(bounds.northLatitude, bounds.westLongitude);' + LB +
    '  var pointNE = coordinateNE.toMapPoint();' + LB +

    '  var coordinateSW = new ' + MAPSERVICEVAR + '.Coordinate(bounds.southLatitude, bounds.eastLongitude);' + LB +
    '  var pointSW = coordinateSW.toMapPoint();' + LB +

    '  var heightMap = pointSW.y - pointNE.y;' + LB +
    '  var widthMap = pointSW.x - pointNE.x;' + LB +

    '  var heightPoint = point.y - pointNE.y;' + LB +
    '  var widthPoint = point.x - pointNE.x;' + LB +

    '  var heightResult = heightMap / heightPoint;' + LB +
    '  var widthResult = widthMap / widthPoint;' + LB +

    '  var heightDIV = document.getElementById("' + MAPID + '").offsetHeight;' + LB +
    '  var widthDIV = document.getElementById("' + MAPID + '").offsetWidth;' + LB +

    '  jsonObj["Y"] = Math.ceil(parseFloat(heightDIV / heightResult));' + LB +
    '  jsonObj["X"] = Math.ceil(parseFloat(widthDIV / widthResult));';
end;

function TTMSFNCMapsMapKit.GetXYToLatLon: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.region;' +
    '  var bounds = loc.toBoundingRegion(loc);' +

    '  var pointX = ' + PARAMSNAME + '["X"];' + LB +
    '  var pointY = ' + PARAMSNAME + '["Y"];' + LB +

    '  var heightDIV = document.getElementById("' + MAPID + '").offsetHeight;' + LB +
    '  var widthDIV = document.getElementById("' + MAPID + '").offsetWidth;' + LB +

    '  var coordinateNE = new ' + MAPSERVICEVAR + '.Coordinate(bounds.northLatitude, bounds.westLongitude);' + LB +
    '  var pointNE = coordinateNE.toMapPoint();' + LB +

    '  var coordinateSW = new ' + MAPSERVICEVAR + '.Coordinate(bounds.southLatitude, bounds.eastLongitude);' + LB +
    '  var pointSW = coordinateSW.toMapPoint();' + LB +

    '  var heightMap = pointSW.y - pointNE.y;' + LB +
    '  var widthMap = pointSW.x - pointNE.x;' + LB +

    '  var resultY = (heightMap / heightDIV) * pointY;' + LB +
    '  var resultX = (widthMap / widthDIV) * pointX;' + LB +

    '  var point = new ' + MAPSERVICEVAR + '.MapPoint(pointNE.x + resultX, pointNE.y + resultY);' + LB +
    '  var coordinate = point.toCoordinate();' + LB +

    '  jsonObj["Latitude"] = parseFloat(coordinate.latitude);' + LB +
    '  jsonObj["Longitude"] = parseFloat(coordinate.longitude);';
end;

function TTMSFNCMapsMapKit.GetGetZoomLevel: string;
begin
  Result :=
    '  var maxzoomlevel = ' + IntToStr(MAPMAXZOOMLEVEL) + ';' + LB +
    '  var z = -1;' + LB +
    '  while (maxzoomlevel >= ' + MAPVAR + '.cameraDistance){' + LB +
    '    maxzoomlevel = maxzoomlevel / 2;' + LB +
    '    z++;' + LB +
    ' }';
end;

function TTMSFNCMapsMapKit.GetGlobalVariables: string;
begin
   Result :=
    'function parseEvent(event, eventname, id = ''''){' + LB +
    '  var loc = {''Latitude'': 0, ''Longitude'': 0};' + LB +
    '  var x = 0.0;' + LB +
    '  var y = 0.0;' + LB +
    '  if ((eventname == "MapMoveStart") || (eventname == "MapMoveEnd")){ ' +
    '    var centerpt = ' + MAPVAR + '.center;' + LB +
    '    loc = {''Latitude'': centerpt.latitude, ''Longitude'': centerpt.longitude};' + LB +
    '  } else if (event) {' + LB +
    '    if (event.pointOnPage){' + LB +
    '      x = event.pointOnPage.x;' + LB +
    '      y = event.pointOnPage.y;' + LB +
    '      var dp = new DOMPoint(x, y);' + LB +
    '      var l = ' + MAPVAR + '.convertPointOnPageToCoordinate(dp);' + LB +
    '      loc = {''Latitude'': l.latitude, ''Longitude'': l.longitude};' + LB +
    '    }else if ((event.x) && (event.y)){' + LB +
    '      x = event.x;' + LB +
    '      y = event.y;' + LB +
    '      var dp = new DOMPoint(x, y);' + LB +
    '      var l = ' + MAPVAR + '.convertPointOnPageToCoordinate(dp);' + LB +
    '      loc = {''Latitude'': l.latitude, ''Longitude'': l.longitude};' + LB +
    '    }else{' + LB +
    '      if (event.target){' +
    '        if (event.target.coordinate){' + LB +
    '          loc = {''Latitude'': event.target.coordinate.latitude, ''Longitude'': event.target.coordinate.longitude};' + LB +
    '        }' +
    '        else if (event.target.points){' + LB +
    '          if (event.target.points.length > 0){' + LB +
    '            var p = event.target.points[0];' + LB +
    '            if (Array.isArray(p) && (p.length > 0)){' + LB +
    '              p = p[0]' + LB +
    '            }' + LB +
    '            loc = {''Latitude'': p.latitude, ''Longitude'': p.longitude};' + LB +
    '          }' + LB +
    '        }' + LB +
    '      }' + LB +
    '    }' + LB +
    '  }' + LB +

    '  var r = {''Coordinate'': loc, ''X'': x, ''Y'': y, ''ID'': id, ''EventName'': eventname};' + LB +
    '  return r;' + LB +
    '}';
end;

procedure TTMSFNCMapsMapKit.GetHeadLinks(
  const AList: TTMSFNCMapsLinksList);
begin
  AList.Add(TTMSFNCMapsLink.CreateScript(MAPKITAPIURL + MAPKITAPIVERSION + '/mapkit.js', 'text/javascript', 'utf-8'));
end;

function TTMSFNCMapsMapKit.GetHeadStyle: string;
begin
  Result :=

    '.bubble {' + LB +
    '  background-color: #FAFAFA;' + LB +
		'  border-radius: 5px;' + LB +
		'  box-shadow: 0 0 6px #B2B2B2;' + LB +
		'  display: inline-block;' + LB +
		'  padding: 10px 28px 10px 18px;' + LB +
		'  position: relative;' + LB +
		'  vertical-align: top;' + LB +
		'  margin: 20px 10px;' + LB +
    '  font-family: sans-serif;' + LB +
    '}' + LB +
    '.bubble::before {' + LB +
    '  background-color: #FAFAFA; ' + LB +
    '  content: "";' + LB +
    '  display: block;' + LB +
    '  height: 16px;' + LB +
    '  width: 16px;' + LB +
    '  position: absolute;' + LB +
    '  bottom: -7.5px;' + LB +
    '  left: 50%;' + LB +
    '  right: 50%;' + LB +
    '  transform: rotate(47deg) skew(5deg);' + LB +
    '	 -moz-transform: rotate(47deg) skew(5deg);' + LB +
    '  -ms-transform: rotate(47deg) skew(5deg);' + LB +
    '  -o-transform: rotate(47deg) skew(5deg);' + LB +
    '  -webkit-transform: rotate(47deg) skew(5deg);' + LB +
    '  box-shadow: 2px 2px 2px 0 rgba( 178, 178, 178, .4 );' + LB +
    '}' + LB +
    '.ol-popup:before {' + LB +
    '  border-top-color: #cccccc;' + LB +
    '  border-width: 11px;' + LB +
    '  left: 48px;' + LB +
    '  margin-left: -11px;' + LB +
    '}' + LB +
    '.ol-popup-closer {' + LB +
    '  text-decoration: none;' + LB +
    '  position: absolute;' + LB +
    '  top: 2px;' + LB +
    '  right: 8px;' + LB +
    '}' + LB +
    '.ol-popup-closer:after {' + LB +
    '  content: "✖";' + LB +
    '}';
end;

function TTMSFNCMapsMapKit.GetIdentifier: string;
begin
  Result := 'Apple MapKit';
end;

function TTMSFNCMapsMapKit.GetInitializeCoordinateArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsMapKit.GetInitializeHolesArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsMapKit.GetInitializeEvents: string;
begin
  Result :=
    '  ' + MAPVAR + '.addEventListener(''zoom-end'', function(){' + LB +
    '    ' + GETSENDEVENT + '(parseEvent(null, "ZoomChanged"));' + LB +
    '  })' + LB + LB +
    '  ' + MAPVAR + '.addEventListener(''map-type-change'', function(){' + LB +
    '    ' + GETSENDEVENT + '(parseEvent(null, "MapTypeChanged"));' + LB +
    '  })' + LB + LB +
    '  ' + MAPVAR + '.addEventListener(''rotation-end'', function(event){' + LB +
    '    ' + GETSENDEVENT + '(parseEvent(null, "MapRotationChanged"), ' + MAPVAR +'.rotation);' + LB +
    '  })' + LB + LB +
    '  ' + MAPVAR + '.addEventListener(''scroll-start'', function(event){' + LB +
    '    ' + GETSENDEVENT + '(parseEvent(event, "MapMoveStart"));' + LB +
    '  })' + LB + LB +
    '  ' + MAPVAR + '.addEventListener(''scroll-end'', function(event){' + LB +
    '    ' + GETSENDEVENT + '(parseEvent(event, "MapMoveEnd"));' + LB +
    '  })' + LB + LB +
    '  ' + MAPVAR + '.addEventListener(''single-tap'', function(event){' + LB +
    '    ' + GETSENDEVENT + '(parseEvent(event, "MapClick"));' + LB +
    '  })' + LB + LB +
    '  ' + MAPVAR + '.element.addEventListener(''click'', function(event){' + LB +
    '  ' + MAPVAR + '.selectedAnnotation = null;' + LB +
    '  ' + MAPVAR + '.selectedOverlay = null;' + LB +
    '  })' + LB + LB +
    '  ' + MAPVAR + '.addEventListener(''double-tap'', function(event){' + LB +
    '    ' + GETSENDEVENT + '(parseEvent(event, "MapDblClick"));' + LB +
    '  })' + LB + LB +
    '  ' + MAPVAR + '.element.addEventListener(''mousemove'', function(event){' + LB +
    '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseMove"));' + LB +
    '  })' + LB + LB +
    '  ' + MAPVAR + '.element.addEventListener(''mouseover'', function(event){' + LB +
    '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseEnter"));' + LB +
    '  })' + LB + LB +
    '  ' + MAPVAR + '.element.addEventListener(''mouseout'', function(event){' + LB +
    '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseLeave"));' + LB +
    '  })';
end;

function TTMSFNCMapsMapKit.GetInitializeMap: string;
var
  v: string;
begin
  v := MAPSERVICEVAR;
  Result :=
    '  ' + v + '.init({' + LB +
    '    language: ''' + MapsProperties.GetLocale + ''',' + LB +
    '    authorizationCallback: function(done) {' + LB +
    '      done("' + MapsProperties.GetAPIKey + '");' + LB +
    '    },' + LB +
    '  });' + LB + LB +

    '  ' + MAPVAR + ' = new ' + MAPSERVICEVAR + '.Map("' + MAPID + '");' + LB +
    '  var co = new ' + MAPSERVICEVAR + '.Coordinate(' + MapsProperties.GetDefaultLatitude + ', ' + MapsProperties.GetDefaultLongitude + ');' + LB +
    '  ' + MAPVAR + '.center = co;' + LB +
    '  var zoomlevel = ' + IntToStr(MAPMAXZOOMLEVEL) + ' / (Math.pow(2, ' + MapsProperties.GetDefaultZoomLevel + '));' + LB +
    '  ' + MAPVAR + '.cameraDistance = zoomlevel;' + LB +
    '  ' + MAPVAR + '.isScrollEnabled = ' + LowerCase(BoolToStr(MapsProperties.GetPanning, True)) + ';' + LB +
    '  ' + MAPVAR + '.showsMapTypeControl = ' + LowerCase(BoolToStr(MapsProperties.GetShowMapTypeControl, True)) + ';' + LB +
    '  ' + MAPVAR + '.showsZoomControl = ' + LowerCase(BoolToStr(MapsProperties.GetShowZoomControl, True)) + ';' + LB +
    '  ' + MAPVAR + '.isZoomEnabled = ((' + LowerCase(BoolToStr(MapsProperties.GetZoomOnDblClick, True)) + ') && (' + LowerCase(BoolToStr(MapsProperties.GetZoomOnWheelScroll, True)) + '));';
end;

function TTMSFNCMapsMapKit.IsValid: Boolean;
begin
  Result := MapsProperties.GetAPIKey <> '';
end;

procedure TTMSFNCMapsMapKit.RemoveScripts;
begin
  {$IFDEF WEBLIB}
  asm
    let keywords = ['cdn.apple-mapkit'];

    if (window.mapkit){
      window.mapkit = undefined;
    }

    let scripts = document.querySelectorAll('script,link');
    for (let i = scripts.length - 1; i >= 0; i--) {
        let scriptSource = scripts[i].getAttribute('src');
        if (!scriptSource){
          scriptSource = scripts[i].getAttribute('href');
        }
        if (scriptSource != null) {
            if (keywords.filter(item => scriptSource.includes(item)).length) {
                scripts[i].remove();
                // scripts[i].parentNode.removeChild(scripts[i]);
            }
        }
    }
  end;
  {$ENDIF}
end;

end.

