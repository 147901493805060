{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2021 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCRouteCalculator;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

{$IFNDEF LCLLIB}
{$HINTS OFF}
{$IF COMPILERVERSION > 23}
{$DEFINE UNREGISTER}
{$IFEND}
{$HINTS ON}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE UNREGISTER}
{$ENDIF}


{$IFNDEF LCLLIB}
{$IFNDEF WEBLIB}
{$IFNDEF UNIX}
{$HINTS OFF}
{$IF COMPILERVERSION > 26}
{$DEFINE SENSORSUPPORT}
{$IFEND}
{$HINTS ON}
{$ENDIF}
{$ENDIF}
{$ENDIF}

interface

uses
  Classes, Types, WEBLib.Forms

  {$IFNDEF LCLWEBLIB}
  , XmlIntf, XMLDom, XMLDoc, Variants
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl, XMLRead, XMLWrite, DOM
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,web, Contnrs
  {$ENDIF}
  {$IFDEF CMNLIB}
  {$ENDIF}
  ,WEBLib.TMSFNCCustomComponent
  ,WEBLib.TMSFNCTypes
  ,WEBLib.TMSFNCMapsCommonTypes
  ,WEBLib.TMSFNCCloudBase
  ,WEBLib.TMSFNCGeocoding
  ,WEBLib.TMSFNCDirections
  ,WEBLib.TMSFNCLocation
  ,WEBLib.TMSFNCUndo
  ,WEBLib.TMSFNCGraphicsTypes
  {$IFDEF SENSORSUPPORT}
  , System.Sensors
  , System.Sensors.Components
  {$ENDIF}
  ;

const
  TTMSFNCRouteCalculatorDocURL = TTMSFNCBaseDocURL + 'tmsfncmaps/components/ttmsfncmaps/#ttmsfncroutecalculator';
  TTMSFNCRouteCalculatorTipsURL = 'https://www.tmssoftware.com/site/tmsfncmaps.asp?s=faq';

  MAJ_VER = 1; // Major version nr.
  MIN_VER = 1; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 0; // Build nr.

  //v1.0.0.0: First release
  //v1.0.0.1: Fixed: Issue with Route Instructions after updating segment
  //        : Fixed: Issue with Route ID
  //v1.0.1.0: New: Support for OpenRouteService added
  //v1.0.2.0: New: Support for GeoApify added
  //v1.0.3.0: New: Options.Locale & Options.LocaleMode added
  //v1.1.0.0: New: SaveToGPXFile, SaveToGPXStream, SaveToGPXText added
  //        : New: Google: Segment StartAddress and EndAddress value

  DEFAULTSTARTMARKER = 'data:image/png;base64,'
  + 'iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADdYAAA3WAZBveZwAAAcrSURBVGhDzZldbBRVFMfPndmPlra0paVABdr'
  + 'S0i8aQoIxGgli/IoPSGgi+oJUE21abEx8kvBACQ9GHjQGhRRFhZgQ8YnEFzXRCJgoNKJpgXYF20Ip9osSoLTd3ZnrOXfu7M7uzLT7VeqPTvaeszvnnv+9Z+6dGRhkglOn1PKRdY8CY1vweAyArWXAH+'
  + 'HAculrbN/H9k0A/jd+/zto2i/9JZc6YccOTZyfBmkJWNPRvZrr3hbgfBdGWiHdicHhFmf8uKp7Dl/bvfaG9CZNSgKqO3qKgxrfj803GWNew5sanPMQMOVoVljb19NWPy7dCZO0gIqOnpe5xg9j4sXSF'
  + 'YEpXlA9flAUH7Y9WC0YntMf/tPDoOtB0MIz2A7JM6KgkDEGrLWvtfZb6UqIhAU8te9nz/VlKz7EM9qkS8CYAqo3Fzy+XEw8scnQUUA4eB+00H1MXJdeCdcP9Q0Pvwv7nw5Lz6wkJKDq44Bf8/NvsLdt'
  + '0iXw+PLA6y8QIlKBkg/N3EEx96RHwvlpNaS+cvWd6hnpcWXunk9xNezVTlqTp/LIylkOvqwlKSdP0LkUg2JRzAiMbRN9Yt/S48qcvZeP9RzEWt4uTVBUn+hQUf3Skz4Uy4jpkx7SwLaXj/d+IE1XZi2'
  + 'hik+vbAWFncam+B114F+0LK1Rnw0qqZkHw6BrwYgH/17qa63/TjpsuApY09GZz/Xcy9gsJZsxFbJyV4jPdPDdGQAlOCna4ZwSPGIXM841mL5/S3waDhhSQ0odXg93DUcsrkOp6znv4YdInvBlFyeVfP'
  + 'bUKFT1nhQHtU2KLh6H0p/axZE7cEZ6o1Af1FcEBqVhn7ZHWjYcZ6D00OUiv8oGMFoO2R5cJn3ZReK7uciZHIKV13+EotGLwLACCI4lN750Awyufg4Wn/8csoe7hf/2+lfhTn2jaMcTnBqHMC6zAs4nZ'
  + 'zReNuSw0TnOgM/L3jCTJ43erAKjOQt5d/uhrvsz2NB5EIpH/ogkTzCsbfLRdyu0UfD5oherG0afcnwxF5GTA84lxNku2cLRz5m1dAomAtDw1yew/uJHsGScRtZIvLKyEpqamsRBbQMO2V4FioqKxLGI'
  + 'mxerHeqT+o7A2WuyFYOthFYf7alQNfhHmri8LcPVJ0taJhyTvYSl8gOO/ID0UacMampqYPPmzVBaGrl8BENDQ3DmzBno7e3FiojOzr3FZVhaz8PtonVoxaaja9MwPTksLbSBVwy01PVLU2ATUHbkSpM'
  + 'C7Etq03KZnbdK+E2KR/+ElQPfi1o3URQFGhoaYNOmTVBSUiK9zoyMjMC5c+egu7sbF4robcRkTikMlr0AY3itWJm6dwMFG79DAa+jgK+EIbGVEONso2w6blYFEz2R5FWPBzZu3AhtbW3Q2Ng4Z/IE/Y'
  + 'Z+S+fQuRSDoJgUOx5rDtbcTOwCGKyVzZid0eTmqmdAw5IaWrkF1Bf3wNatW6GwsFB+mzh0Dp1LMSiW5smCwVXPym+jxO7O0dxM7Bcx58tlS1xI8UxlL4ULTxyAvsrtEPTnS2/qUAyKdf7xAzBtXf8lM'
  + 'TlYcjOxCcDLSzwGClxuGTSHmUkX3S2mJYeY3CS2i7j8SE8vOqupTZsXbWJuPLk0BO0NcrOxcOLECbh7N3bnz8/Ph507d0orSnt3Lvw66v4cQZsZbWoECgj0t9TWCEPiNMTRm3PLcpcMgUAAurq6Yg5a'
  + 'PlMiNoe4BwcHAQx45AGbnpwWGmsO1txM7DPAISBbuJH8DwRYc7DkZmIToDPlN9nEk+mJLrUyyghYPkYOBtbcTGwC/Ao/izlHbsa10JTRXAC0MPUdGcBwKKzZ7r9tAgLNtWP4cdawjFVgoYjr+2zCt9O'
  + 'o+QvZFKMQfcR7eFCfxgwYYDWJ+7N4bPsAQa9Rwl7tKt5driSb7kbprjSeQp8O1Xn215tXA70QDMaKpmeAquqYJVwQuKfCRNA+jtOT/0bqH+9eBz0htcrpNYujAKL88JW3UECHNMXrD3oP9DCg90TB6d'
  + 'vSEgKa+1vrjkozBscSIvqLa4/htHVKEwNOxKwI8wX1QX2ZcOAX+ou7jknThqsA2ME0VdeaMMIDw8Fh5sHIvG5uFJv6oL4EnD/QAXOY5TW8uwDk2tvrLmGsZopEtnhvg09I8yFCJI+xo+9KsU/Omq+3N'
  + 'NCrHVdmFUD07a79Gn+2V5oYVsu4iGjy1oHme42+Z2dOAURfS837GJD+P0CQSRFOyeMstPe11GOfc+O6CjlRceRKO56yT5riYcNPD/0JvlaPxzl5aO9vrY0M1lwkJYDIlIhMJE8kVEJW+lrqUEB65eRW'
  + 'NskmTyQ9AyapzkSmRt4kZQFEsiIynTyRdAlZSaacMlk2VtKaAZO5ZmI+Rt4kIwIINxHEfCVPZEwA4SSCsJdNfUaSJzIqgIgXYSWTI2+ScQGEk4j5SJ5IaxVyw746pb/aLAg0E+WHexzLKTMA/AdvKV0'
  + 'lo28WnwAAAABJRU5ErkJggg==';

  DEFAULTENDMARKER = 'data:image/png;base64,'
  + 'iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADdYAAA3WAZBveZwAAAj/SURBVGhDtZh9bBTHGcbfuTv7zviITbASJza'
  + 'EONQfNVVUNSD6JSfFYCREU7Uy/BEUQqQ2qlFRE6F+0D+SqKIOqGmRIltqkIqIIoUIqSJVkRyiJKJppTRYRBEKcYxDofbFBIxl/H2+u52+z+zMefZuzz7b55902pm53ZnnmXnnY1dQAZCtFBwYaXiE'
  + 'hHxUkNwkSHyN01UkKar+FzQupIhJkleECPzHceh8dfln3eI0pVQFS2BJBmLNX18rgqmfO5L2CiHu08V5IaUc5NZPkgx1Vp/7tF8XL5hFGYg11VY4YfEiP/xTFl6kixcFG0lwPa/KlHi++t2e27o4b'
  + 'xZsYHB7XWvKEZ1CUIUuShMMEBUF3St+fI9CSqKU4/4SHDS4ZsL3DFFAtlV3fX5aF+VF3gZkU1MoFvnqT/zAL3SRAiIjPAb4QXQ+wMB0wv3BnI106JUrM/c+99j580ldNCd5Gejdvj4cdUJv8mR8XB'
  + 'cpSoqJVvDP9PRCgfjJGaIp/tlw+VuTgeTu2q6+uC7Kybx9hhVmhRN8wxYf4KfKVhCVhv3FJ7mH49x/ppeRRlkmeBZ1oC7UaeDyx1Wb3LYuysm8fde/re7lgBDP6SyFuMq7SrjBjCeTHNtTLHaGxWa'
  + 'GhQGCi0M8chxuqMeGVzIanXLrsXi56u2egzrty5wGBrbW7WSlb/FN6r6Q7nm71xHPEzzQEO4hwApL1TbAN4yzQq8yGEHv2/MGxu9Mzo4WZ3nroB9Wn+v5h1uSTU4DXzTXlEWCxZc5eT/y6PHyUm/PI'
  + 'zTGp60ev7eKxPe2E31jE1HVg+yYVYIk3xj7L9Glj0j+q4voq5gqRkdEI0RhfRvASIxMuFfAly8jItlQ0dU36pZ4yWlgoKWunXfU3+is6nkskQbENsQroneR2PUM0XdbvMHsB2/DxCbk6Ve5AlcTTGA'
  + 'VM2CpxUgYeBheqn7789/qrAdfAwNb6ldTSF5nA9znbuVoxIBwQbwq1jxE4peHie6+RxdkU1xcTGVlZdzjgmZmZmhiYoISPAry2O94kn2h7sG8QlgZ0DnoJMAGJigpHvDb6Py7KySfNuLhcAXHqgFDO'
  + '6Z7Plm5hmaefWlO8QDiQxxOwWCQSkpKqKKigqJra3h8/0xUXaPuQZ0mbADaNL2rtLAmnfXga0Dw2UYnKcy9b8f9hI55GS6h4SeepaKyVfqf3EB4JitXrqToPZUkDvyel6VSVSfqNqBNtG3xpL56yDI'
  + 'wuLX2QR7rRp31VIIVBxMXjDX/hGRFJYf8PDHPIGxsRkdHaWpqSpkornqAxI+eUuWo2z5m2G3zKGwYbKlbp7NpslpPBUSTTrKP7IkLnGgZTW7akpd4MD7Oy6gGZjo6OujIkSN069YtikZ5qX1sJ08Cd'
  + 'yRNGwBte5Zsokd1Mo2fgm/pq0c8MGv91MPf4VOwd3znwkxcPnnSiRMn6Nq1azQ4OEhnzpxRE5yK+Ld5i3uvbsOQoSGtzZBtQPLLiMbeZDDBzPDG129QVwdLYp4gbE6dOkUXL15U+crKStqzZ48yBcS'
  + 'GR9QVbdiT2XNAtLQZsgzwkFXqpOdhOzax+gAYSKXcHXZycjKd9uPChQvKwPDwMIXDYdq/fz9FIhEaGxtzb8DGp7HbsjXY2gxZBrhH9P7PD+grSO+2XEuK54AhHo/zRptUcX3s2LFZQRZXr16lkyf55Y'
  + 'srgendu3crszdu3FDGFbwZmhbTbTFeDbPaDNkjoObKHHDtwhpjrCZnz56lvr4+6u3tpfb2durvn31DvH37NnV2dlIikWDvgvbu3Us1Ne7a70GptpT74KfNJ4REugvtWLT3guCdIZ1yR6CpqYkaGhpUH'
  + 'oKPHj1K3d3dND09rUbGjMqOHTto48aNKp3FyGyddlu2BlubIcuAQzLdfbkmU9GX13TKBSF04MABam5uVnmsOsePH6fDhw9TLOYe3CB8505eLnNx/YpOeNuyNdjaDFkGeKb36pTnbI712Jzhwz0fuwmN'
  + 'WSZbW1tp3759VFTkLrE3b95UV4QMQmcu5CcfqivaQFsGz/uBpc2QHUIBcmti8LAdlebYG/m0mwIT3tMtwgQTcvPmzXTw4EFatcrdmFavXk1tbW1pU76MjhB1/1Ml7aM12vZ0oqXNkD0CIecDflI9hgr'
  + 'sjQVbO3pHJOIUfe+MLp1lZGREGVm3bh0dOnSIGhsb1XKJI8NcyL+/xg3FVd328QFtz3agTMoEuS4trMGaZWBb/ftcmdq2ccTFUdeAty/1Es7dMbzv1xR/KH1sSoPexglU7bLz8dlFkn/8FQe4oz4S4C'
  + '3NgCO71YHv8+vlD3Q6TfYIMFKIv+qkqsAeRnyFUJNMOlT+xisUuvE/9w8LLJlDQ0PqrINzEPJmxwVIo2y67zLJjheVeNSJug1o0x59h8QJnfTgOwL4jFLqhPr432rkcR7BG5kB76x4Y4Imh4/CI7vaK'
  + 'F77sP43N9gHAAyEez+h8jc7KDA9qUKnnOu3Vx/UjzczhaSBiUByvd9nFl8DoH9b/c94Pf6Lzvq+9mGIVceygqlvfp/GtvyYUuVZH+w8BHm9X/nu36jk4w/UwxCPEM089aZfVxleSp9Zc66H30GzyWlA'
  + 'trYGY6OXPuQb1CkLN2IU7M8hOLPARPrsEgiqgx5GI3HfWnJK3SNHYPwOFXGoodfDfZdUyAD0OMTbPY/QUaOr85y48O+ynm/vyvElO6cBcL2loTEo5UfcSyqAsEPChN0gGsKkxk+NRh6g1zFh8bMFoCM'
  + 'g3mxeHGqTgUBw4/1dl/F1xJc5DYD+rbV7RCDwGt+o7vUzASAeb1SYeAivTDMQjTDBqoa1HnmbLPGownGeXPNO7+tuiT8Z1fgTa6nHJ40/uLncJmwgxJiAWPt8k0mmeM0hXjbbdToneRkAsZa6F/j253'
  + 'U2LxP54Ceejb9Qfa6H19f5ydsAKLSJpYoHCzIACmWiEOLBgg2ApZoolHiwKANgsSYKKR4s2gBYqIlCiwdLMgDyNbEc4sGSDYD5TCyXeFAQAyCXCbBc4kHBDAA/E2C5xIOCGgCZJmwKLR4U3ADwM7Ec4'
  + 'sGyGAC2ieUSv+zAxMC2et9wKgxE/wfU9O7YdUzoAgAAAABJRU5ErkJggg==';

  DEFAULTSELECTEDWAYPOINTMARKER = 'data:image/png;base64,'
  + 'iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADdYAAA3WAZBveZwAAAnTSURBVGhDtVhrjFVXFV77nHPnzn3MgxleA0P'
  + 'HUmBqi0VCFBUT0mAd+vhhfxht6g9j/GPaGIn9ISRCibE+o/FHjbWCGhOjhaRFGwPBaMbIo0asUNNSZmhhZoDymAEuc9/nnu369jn7zj5z3zPDl+x7zlnnnL3X41trr3MFzROXntgUdwq3VhSF9SGS9q'
  + 'dI0kYSci3fWiFIJkkKTwqREoIu8713PSGOS8990yMxPjDVMSFOnSr6M80NczZA7tljXTnxhyc9oqdZufWs3AAJ0RbcrgspZZ7fOc9nJ0lYf1yx+Qt/FXv38lStY04GjH927QZB1k/47c08RZwnmdM8U'
  + 'hIHQmaEpNcLeXvHvcNvfxDcahotLXz+M6u7ohR5iizxHfbg4kCsYFlENs9m89HhgWt+RoEVpRL7F8Pl4WGwzARH5R1L0m67YB9eNvz2dCBuiKYNGNs2+BHbkd9lTg+ZVInYRO0RVpqPSulAXgvaGLdE'
  + 'lGX241zDp5b8veW1f6vv6JlrgbgumjLgxuNrV+aL1gFOxk/wC+V3ElFfee3pVoEoZAv+mIF0+Wd/qSP9jXsOTmR9WW00XHric/f3WhnxshTyyUCkvJ5o96lSD5oljRYpcjSmc+FocF7sy8eyz9176OK'
  + 'tQFQVdVWY2HZ/r8h5+03l4fGOWGPlgTxTpAB/NgAc0slztjmBgOEJ+oqTa3/h31ymA1FV1FRjD98TFu3gmbYHImoLPG81QRlwPZMnyjE9cN4ISP4OnhvGALyEsKR4qq+Q3uJfVkdNA7726OCDUtDTOm'
  + 'HhHXi+Cd0Vcuz9csIa1KgH5FKSjYAxvoC6eey89MS63kBSgZoGuCXxDL88gHNUlzgnbLPJiuQEfeB487wZQPk4u6xcgom2egXxdf+qElVVGt++pt+S9jm+zT5n3vOESTagWYD3d7h+FJf1k3CL5Exep'
  + 'e6E4dkmkOL3df540stE3OLq5X97/6ovmUHFlGwxtzDOLq08PAGPtAKURc+JUGroizS95TGSlk1pzodWgFzTUeBkjLtO21f9qzAqDLgwNDgg5EzVibHyzSStBuiCsph7YBMVVn+Y8oMbyF3Sp7zZTEXS'
  + 'wK6OiqfBjn38/a0buoPLMioMaCPxST504hweMCdpBFV5UHXaopTbsIVkJEqlrl7K3/cgr2T5iR082wxQOLTvWJeVtpNbFVyWETIA9JGexw0aKcajpFVzPhRFcuqWAJ4Fbe4Em5G7bBUVVt3HOltk2TZ'
  + 'lPvYweYlOFZk0P4PSWuR38C6ex1zVSi0ijwKiIGkx19X+4KqMkAEXtg5EhRBreKhqjP5G8xCLYbdEcmHczvDAMbgGx2GIdBxKf3ybUrirq4uSySS5S/s5Fx7lXHBUFKb5Wf2ufl8PzAODAGVAsD7rFJ'
  + 'cWrX7l8xTsFD5CDuZ6u1gWrddYiM1D1f1osDtizmleIG/w2GuPk7t8lVKwiGPfALk9S0nGkhSJxainp4dKpRJNTU2RV8iTlU6Rc+0yj0sUuTpGzuWL5Fy/TKLIIQmAnFMlO7iGUTp3OEo/zFju7nWHR'
  + '8slIWTAGDdtdtH6M5u7ETdggLm9A4gCEhXKT31pBxUGBstxBmXYU+oIz8fYCO4wKZVKUT6fV+cYHvpphii51DbyFi165UWyCjmVb2gQTWA9RC0AN3nTz5pNXohCDuIvyE9btkDTxwQWiGKjyWWo++Av'
  + 'KDr6P+UaKA3K9Pb2Ks+3t3MdZMCgzs7OsjyRSCgZlE8cO6zmgPKYd7byQFgHGbdzy0KSkAGea3n4hg0uoVcFMKFuo+1bk9T96ssUufSe8momkyGbkxYDSmrgXMuy2ayKQvTsfyg5fIisbFrRpplqx/H'
  + 'jTAwjZIALtwhShKymvAZUQ8+Che07N6n31z+g6LunKZ/N0OTkZJkiJiC7efMmlXJZip05yZ7/Jdn5rOJ7vY3S1ENI607hzo3Q5CEDksJN8yHlX81Ug1rAwvCcxXTqev03FOHkLBQK5LpGpgcoFotqtL'
  + '/1BnUe2k920adNXeV5mDpwGZ2YnF4cikLIgN6OjWnJjgouVY2uZwNYUm68oHTgeYdTCdBJC2iZ8EqK/+iL2hrQBq9qA/ggS9Ia23TqVMg7IQPEgQMllpzmNxWNsPHUtYChNiE+Sq5KXqJDcR1Kp9Npu'
  + 'nbtGt24cYNyuZziPxLdXdzHq+IZfqfB3MqAgDDc3tziD6sx9lXorZABgGWLYUlC1VlsXo16eX0fZdWLdyjlUfdv375NcjpFJR7gPmSAu2QFSdvxjW5gABxYjoAQVx1LXPCvZlBhwHKv+AYrcSW4nPXB'
  + 'XQlFM3iqs4d7nzaVrEX2eOzNf1Lvz79NPb96gSJjI1TgfQD3vHiSSp2LlGImv6sB+42G9ORoX2L9RHBZRmhbBvaOTpW+uW4J/PoYh0tAwQjTt1Yvj0UQhdz6zbwr91Nk/Dwn9O8oeewvZGemuUrdoth'
  + '/j6kE9zq6lQGRq+MUuXxB7fK15i2w97Xz2E7pCGt3x5+GedMJA+lXgSuPPJQoicJxpu1DuFafk0Z/rgHPp3inRGOW/einyU5NsQGjZHFrAKNRofAM/v8BHb1YglvsB1ibNoqdPqaqEErxbMBpaCFwVJ'
  + 'DyBEdt2z0HT5Z3YI2qBgATjwx+mTXeh9zDQ0mjL9IABbAQlJPYqHhFS0j1MYI/ALTBMAI9FBo1zi/1gYNKpFoHOMZ/rAyzfWA6u5b0tq04OvIPXxJGjQCyApY4wjOfQPh4qH8YoKgJxX3cZDi8SSaik'
  + 'hbxpyMMNaOFcyi7KM5HR/KzfiVUHg7e10DjVm4YJZWERS/1FQr/CiQVqGnAkiNnP+AlnpfSU+VDhZU9Y35VwQB8OWFXxv86oMNsmplAz6efBX2UZwwgn+B9Lee27x2HSj8VwxdZWh01DWA95PHOkb+T'
  + 'sHfwfGpK1GTQQJdOJCA6Vni3lQ92PAtj1d80gcFKeZ5bR5SX4A5R7Fp6ePQ9X1Iddfw1g/GhwV38IA/BBPE/dPAvBY4LAUQVntfKcwSuC8vbufLwuX2+pDaa8pvjRX/GBvyYfVLe4GbTaa7QtDGVJ0s'
  + '+cy7b91tfUh9NGdB39Ew6LUrf4/38+4Gogk5zwWzaKAjaO5Jd/urDw8NNuacpCpm4NDT4PB+e41fnRadqtIHy/UfOvuhLmkMLqefD8qI/4sO86FSNNjbTZiS37CVf0jxaNmC+dKpFm7Mt0MZEyxQy0S'
  + 'qdFoo2JlqOgIlW6LSQtDExLwOapdNC08bEvChkohadoPRC08bEvCJgohad7gZtTCxYBIBz29dEE9LeydPuCUQhsB3PQvn50sbEghqgMZtOC00bEwtGIRMmne4GbUzclQgAmk78BXZ9oWkzA6L/A+Dav'
  + 'xFhPzMaAAAAAElFTkSuQmCC';

  DEFAULTADDWAYPOINTMARKER = 'data:image/png;base64,'
  + 'iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADdYAAA3WAZBveZwAAAloSURBVGhDvZlrbFxHFcfP3Ltr73rXjl2C28Q'
  + 'mTaGxHbs1DS1SWkoDgjoPoqZQMIJWIEFUvqRSPiAIIGFCRRWExEMKX3iIV9QPKW1p1TYvKkgoNFBKaFq7tZsCSZPYsU3W79317t7hf+bOXc8+7r5s8ZNXd+bevWfOa87MrAWtEJc+2r3OsTPbLSnulC'
  + 'S7SYh1JGWTeijEDNoXBIkhDPgn6VhH234/dEE9WybLMkAOkHX5xY33SHL2Qsm7IKwieZL/pDwlyPrh2ttff1rsJ0c/qpqaDbjY13kHXj4IxTfpW7Uh5RkYtKf9+PBf9J2qqNoAuWVLYLT+yn64bJ+AC'
  + '/VthY1eXYAoYKPN4dDSJTTM4JPOEC2m0c7zN57znQNtyWsHxMmT+EblVGXA6N29EcdKHsZrO/QtRT2UDte5ilcCGxJfJErmqyrlc5as719z4uy8vlOWig24sLk9bDVFjgkhPqhvKY83hipXPB82ZDaR'
  + 'GxFMjlOpRGLrDSfP40l5clLAD0Rf2KuivzGVZ683NxRXnhVKplwvK0+jnZ82DL/LMliWByrVXcFQ+BCPqW+VpKIvvd3XtdcS9APdpVCQKArPm3Ces7IJKOugXQzIoBBSLYz3vfnhMQd/87tLyL1tx4Z'
  + '/pDu+lDVgdGvnegf1G80w93mSNqnWEuzhuaRrBFkI6o09RO/BUtCyWj2XsUmityDi3CCsgzSMGq2H52GIyUzcneSauIX1ZM2x4f/oflHKGnCpr+swvvUpbrMHWyK53puH4ux5spAPH9pJ4mOfIbqm1X'
  + '2Yz9Vxks8+SvTHZ5QhPPEjMMSDHRDD9PUiiP5j7cff6Hd7xSlpwMWtnZ0ksXrqctkIz5v5ugDlF6C8jK4i66H9RB29+kkZRs6SPPgtzOApaoARDYYRXJlmEQmFRPW1qbv9yBsj+k4BJScxPPCgpzxXH'
  + 'FP5FAZi5Z1whOb3PFxW+UgkQo2NjRQMIm/wXbHv+7jZqGSwLA8eg8dSCLJlRn5J94riawBvEywhPq27KtwmnPPM9H0Pkmhb73Z8YKWbmpooGo3S6tWrlTG0dj2JL3xFPfdkeZhjWST6kVG+meJrwPm/'
  + 'dnfh0ub2cr3PYValsuO9lNh4K9l26YUgk0HBN2Bj6uqg5fs+QHTz+11ZRhS4UGQR1H65r6NT9wrwNSDgZDbrpqrX5sTlqsPM37HVbZTBwYSdn89dXFUUgOj7pLp6MhkuFgFDM0Thdt0swNcACNmgmzn'
  + 'CmBQcKutClHw3yiVgBcsxOztL8bg3O9kh2iMbsRcMhZVME3OBzAjq0M0CfA3A5muNbiqPeHCJ43KXvrYds80dJZ024u+DxEtTU1M0OTlJ09PTqq2wkS+YQyzTXADNMYUU1+lmAf4RIIFF3sVMHx6IcR'
  + 'qibgMkEhVtWxSpVIoWFhZyo4YyzHiyGXNM4OZbEXwNkIJ4eVIYcpdILyUtT9JkMq+UVEMqO1SWvDF9hfsaABEx3cjxDO8UmMDVK25DMzMzo1s1MH5ZXTzZjDkmnJnVJR9fA5B3b+kmpY1oc2R5UtvY3'
  + '9ixCfcm4NSoyYiJUaLJMSXTzBpzTBjzL90swNcAR8h/6qbat5t4dbrh5ZNuQzM3N1dQLsshXziqrjm1H+SMaeiSj68BVnDmb7Bc5R6H09glqi0x0/DicbLmpt2OhisMf7jqlGX6KtGJJ5TnPZkMj5V9'
  + 'XVI8nUic1r0CfA1oe2Z0AW8/q7sFCw0v91ZigZof/wkvBPqJC0dhfHxcXf3WCJlJk/z5d7GVnVfKm2XTHAsntCOlTme+Bigk/VK3stsHD95Fct7Wj7yijBB52wWuTByJsbExmpiYoFgspmo/XyevjFH'
  + '8x98mevUlJYNleeRvK0wdimHOmwLU7z6nu15DcyP3g8jTVcZhhp07hcWVr4vrNtD0x3dTujW7fSpKYPwSrXryp1R34ZyqOs2QZ1afacjL7k6lHFp7fPgmKOmbjyUNYHAmuAfn1Kd0Vx1AzN0ir558kl'
  + 'KTDpokum+j+M2bKbXuRso0tqjv2LMxCkLh8KunKTT4d6jjqK0Cn+zM1OGDER+QPJA+u9qPDT+tu0UpawBzsa/rd1gZd+muGji/angH+JzzsLecGhPamz/523OeuOwID7zyFE5j9+quL6XngEYknd1wx'
  + '0XdVT+F5G++WKEW7C7YOD70s4dtIdWH23yPn/F38pVnWdlTmEK+TRn6ou6UpKIIMGPbOnrT0jqFF9TGhZ3LCgWNXWMtsPLseSNIU45wtrzrKM6dFVBRBJjrIFAIZyciMct9HpAHzo9ENeQrj+ssWc7O'
  + 'SpVnKo6Ax6VtHXdKKY5gYqvtaK2RUMrzSqP7rLyQzva2EyN/1rcqouIIeLQdHXlBCLkdFWKO+7VEIut53a9VeaZqAxjPiFrSqVjaCMvZUYvyTNUpZFJtOq1U2pjUFAGPatJpJdPGZFkGMJWk00qnjcm'
  + 'yUsiE04mk9Ryajdz30olZ6bQxWXYEPDgSsYaWezOWrba+XiTMtEkLOxELXbNrpZRnqo5A92FZZ6VTvU4mdauwrG6otwFiroeSayGs+abxM/Sd5/dQOAW3G8SDDfSNjxyk11o3sUFT+C4OwvI83n1TOs'
  + '6QZQdfdgLBs0P9ovCEX4KKDOj8mdNoh5P8E9on8PkwXvL9mYPJN2IhGFHKD7beovp+wDCcR+UfoNYTmXj9b4d3W2pelaKkAVsGZGCiI/llbH/3CSHcH28qpOfKP+iR5x/iXxS08tX9NxZH0mlod6AnG'
  + 'P7eY/2iSF1zKWlA96H4rzAZP6e7SyDBHWeRkEbqKp0MbuGIiH2+lBhLl5ve/76urmffoc5DGA0rhrDxsfAJkLACOEIECemDK7aoPPPzgKhfDz0Q/rzuFuBvwMCA1dPxVYRU5Pw3LJOOUyo5BeWrStWy'
  + 'WHYdBeubyQ7o0uUh5dzg/eEmGOfVghzKRGDha0idR3Q3Fy8KTsqNgMMR4EjgfMkf/oquP/C7usLl2vuIArwvLNuNgI/3eRBJ1teH7g8d0DcKKGkA0/1ofBuOWQ/DkNv0rf8LMP0laPfNoc+G3R+OfCh'
  + 'rgEf3oblbSNj3IZB3Q/wmeCzvXLVMpEROijOY9CdIZh4feiDq+2OWScUGmFz/i3+HInWtvcjcHgjYgJRZL4VYA+PeCZEtJGQUHgzjmdrWoZ1BO46ShD2TjEHJCaQV/yB6Hs/exEl6cLEl/Mq5HVaVvx'
  + 'AT/Q+niwvEyza8sQAAAABJRU5ErkJggg==';

  DEFAULTWAYPOINTMARKER = 'data:image/png;base64,'
  + 'iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADdUAAA3VAT3WWPEAAAnJSURBVGhDtVhbbBxXGf7PmdmL12s79joXX4J'
  + 'pSOKWNo2iPLSlVVGVBwo89QFBlAdKhRRBECJSHkgk0lbQcqtIW6klSZWIi6iAPhQED62EhIpKmiJaaEFJiN36njSub7G9673NHL7/zFnvzN48vuSTZmfmnznn/Jfv/88/K2id2P+ricRcxu6WTuGTVo'
  + 'Q+Q4L2KZd24VG3UiIpLHKFUvNE4hqe/U8o90KxqP4lyB1rua9v/J19ouDNtDas2YDHR5T87evXHyHlHsI0d2GmPkwWNY8bQimVw5gPYOhFKel3X364+y9P9gnXPF4V1mTArhfH9kpL/IwU3SMEJUjgd'
  + '01QLozIkBR/tqL5o5ceve0j8yA0VrXwjlMftEWSsYNC0fcxtNOIPShJJC1c4BA2zrhXZnoMIOXgAocq4szODjocUblMUp60ktZrlw5uXTTiFRHagP6zo3uUYz0FTn8Og8pUEREccVxAaTZixWDAGDaE'
  + 'YIi7ZK49MLWUK16Ot8rvvn+oa9KIGyKUAbtOT/VImXsFyXhvgC6i2Sgf2g8VQBQUjODDQCm2jM6nx5zvjP/gE+UHdbDiyrc/O54SLfIl5ahHjAij4HVi5ZkqDQBtNFaKikIhUsyacjSQF+eWruWOjZy'
  + '8bc5IagIxr4/bz42n3Kg4H1QeHhctKyuvgWJDee+yEdghshVnXxFz3Mfim+2n95/6Z8JIaqK+AV97XCIJj0LPh40Eb/MC8Hxjuw04cTM4Z73rFcHJz47h6AKgqrDlwXSy636+84TVqKtJ/73fuJNcdQ'
  + 'gjPbdo7/ACIfmuoHip+ujKEwaYWyRx5moGKNqE4/ju5ydSnqAadQ0QongEg/v0DVcXlHu9QChwiQR9dA74r8MAykvfWkp9VkTdb3s31aip0c7nx3qtmLwKZzd5EvBesmfCArxXC9QbK1ABe8GNPPJFb'
  + 'ILceDYMuPtQXv64jpspyMiOocPbbmiBD9UReFMJO0onysrDRu2RVQBlMQLPf6Vnnr6APcnSVEqbh2HBueb5V1oyEaXi1/VNBaoM6P/3cJ8Swld12I66TKsBpkuB9rdl6Y5knva25qgrihzQ3gxRkUrA'
  + 'juntMQZKfXHvy0McxgCqNBPR6H04oabpu+AkKwKedjMUk4ru78xSzFKUijp0Z0vOW4gTO3QuMFA4louG6MkuWtvNzTKCBoA+rlL34Cqm73VJq5UmpeQ0LYHmPO+oC1q2valIn2rKo7WRZOF4qDNDrRH'
  + 'edblzZipxaeVrHstz8Fw1DBNQTxcQvqZOtBm93k0ZAQP63h2OofruxGGyjTerkgFMA+yWOrlwuDexNg4+uyyDYqCJDUUObE5rhdva2iiZTFIvDPr8lkWyERkdBRfz6HGlOcyc+mAD2SAG1GMjAOiUIE'
  + 'ftoD2/D1SCgHtRbztlTP0Bb/Pmgadc971geKFnA3h39ZCwXNoeL1Jvoohzgfpw3gK+JyOKmqIR6ujoIMdxaGZmhnJFl+YLkq7lbJrI2jSaidDIkk3XcJ13fWronEPRKFHHV41w8ZPifObk4LHdy0oED'
  + 'Nj17GiPTFh/gnCfnoANqPxG0T1LjhLSpaM7Zqi/BVTxnmjKwFP6zJ5vamqC3Yrm5+cpl8vpaz5c1/NwESX2P/NRemGonbIue5vbFK4+PuioM+U0zi+OOt/yN3kBCtlNNjPA28vZ4aV+3g9eQMUo4wg6'
  + 'PbyJ/rsQ06+y0kyZVCqlPR+Pe8nPBrW2ti7Lm5ubtYyVf22yWc/hKc/NYYXyGmUdYHti624roFTAABlxXVCuRECMrZFYPKFpo6cLFr0EBT5Mo+rDq5lMhizL0gcrWQJfl2RLS0s6Cu/OxeiP15OUdlh'
  + '50CZEtcP3m69d9RAwwMkWi3CnIVwt5Q1YOe5ZsPAsjPjxYIrem0dUlnI0PT29TBE/WDY7O0tLBYcuzjXR2dFNtORyrWe+N9ooy3pIWyxMzeUDkwcMKFpJLgHIGgN8sDaEXjxOGVDgFyNtNJGLUD6fpy'
  + 'L8UIlCAW0Fjrdn43R+pJWyDpSXHMkGyiNS5YqEW0eMd26ZDkQhYMC+B1Np0GbW3ALcSTaIhKYTFACfWWXXvGojlRilpGWUZA6/y7nFlVpVFIhK6BbEGICJpHRG3+ndH/BOwIBX7hAODH4Pwzwa8cZTp'
  + 'nId8ALILuy6Lbaruc5Kp9NpmpycpKmpKcpms5r/nOhdKLteGmKVmjnmBz/3DEB7M4cPq1F6IDgoYAADy7whlos9b17VdAjAPOc9gQ1g5bnu37x5k+bzKKFZR3OfZYxuGGBrz7IeKxigd27PAHyP3xDS'
  + 'HtY3PlQZUGjd9jaUuG5ugZW+q5mSijpiLkWx03KyZvMFenOmib53OUVPD3bQwGKEcsgNfpaEoe0R0z6slGP6k9QDyDh414Nd4+Z2GVUGDB6UOaHEc8vk5V1Qe6IePCV64NmFoqRLC1E6NdRBZ1FeJws'
  + 'RGspE6amBFL043E5X01HKYdfdkeD5MH0jCvnXZf7b9q81xStQk+F3/+Z6cz7tXMDju7WAPyf1rlz5OhTgPgaN2QOpJZrJWzSIPSGvGzDsh7q28zscxSI1w/ufxs4dRb78fZprP1ch89kRAPTU83r6Yo'
  + 'a32m3nwMXHqv9mqZui/WfHH8XDc3iF+wNIuO6bvmgZ8L5eCImJZVy8B18ZxbjClKaHCpxWaNTwhv7A8SoRG8jvVqjhax/gfHRR8sDA4e6/aUEFqihUAj7LXsdab2kqMZv0PwyVCc1yj0KO7kOhjGzHY'
  + 'DbUr5RR1mpHNYlD+dJfMvBwhe5ea264L/CCEmfyHV3/8ATVqGvAld2bP3KK9ITrKq988GIOe9ts1BowgP8P5V1Z/6/DdKjSqAwdndK7MJbHBwDFudU2ctdRlx1lnxr5klju5ipR1wCutwOnL/wVZf2o'
  + 'jgKD/wF3ue8vRYKV55abqYDr0OBxnAP+vCopbyoTPAemnRjcs+VDT1AbDdxVRv/Px06ApyeELLWLoID+l6JEhfUCUfUrT+pjtyiOX/1mD3KwMepHwIdYq/0cDHgG5hpyIgJVdForKjwP5dFzHulqvvp'
  + 'LI2iIUAa8f6gr7Sykf4h8/ZERIXaVdFoLKpUHFD25LTHw6htffSjUxKEo5Ef/mYkncDqGyrdOOlXThpW/crj3BSMIhVAR8COWlD/FaZ10qkEbZR3ZGh84YwShsWoD1k+nOrSJXwlNGz9WTSE/Vk+nja'
  + 'GNH6uOgB+ro9PG0caPdRkQnk4bSxs/1kUhP+rTiQ3aWNr4sa4I+FGXTreANn5smAF16XQLaOPHhlHIj2o6bSxt/NiwCPgRpNPG08aPWxIBxs5nrsaslubj+AL7mJXfSNqUQfR/fzph4tKG32YAAAAAS'
  + 'UVORK5CYII=';

type
  TTMSFNCCustomRouteCalculator = class;
  TTMSFNCRouteCalculatorRoute = class;
  TTMSFNCRouteCalculatorSegments = class;
  TTMSFNCRouteCalculatorAlternativeSegments = class;
  TTMSFNCRouteCalculatorSegment = class;
  TTMSFNCRouteCalculatorAlternativeSegment = class;

  TTMSFNCRouteCalculatorService = (csAzure, csBing, csGoogle, csHere, csMapBox, csOpenRouteService, csGeoApify);
  TTMSFNCRouteCalculatorLocationMode = (lmDevice, lmService);

  TTMSFNCRouteCalculatorPolylineOptions = class(TPersistent)
  private
    FWayPointMarker: string;
    FStrokeWidth: Integer;
    FStrokeColor: TTMSFNCGraphicsColor;
    FEndMarker: string;
    FStrokeOpacity: Single;
    FStartMarker: string;
    FAddWayPointMarker: string;
    FSelectedStrokeColor: TTMSFNCGraphicsColor;
    FSelectedWayPointMarker: string;
    function IsStrokeOpacityStored: Boolean;
    procedure SetStrokeOpacity(const Value: Single);
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property StartMarker: string read FStartMarker write FStartMarker;
    property EndMarker: string read FEndMarker write FEndMarker;
    property WayPointMarker: string read FWayPointMarker write FWayPointMarker;
    property AddWayPointMarker: string read FAddWayPointMarker write FAddWayPointMarker;
    property SelectedWayPointMarker: string read FSelectedWayPointMarker write FSelectedWayPointMarker;
    property StrokeColor: TTMSFNCGraphicsColor read FStrokeColor write FStrokeColor default gcBlue;
    property StrokeWidth: Integer read FStrokeWidth write FStrokeWidth default 5;
    property StrokeOpacity: Single read FStrokeOpacity write SetStrokeOpacity stored IsStrokeOpacityStored nodefault;
    property SelectedStrokeColor: TTMSFNCGraphicsColor read FSelectedStrokeColor write FSelectedStrokeColor default gcRed;
  end;

  TTMSFNCRouteCalculatorHistoryManager = class(TTMSFNCUndoManager);

  TTMSFNCRouteCalculatorOptions = class(TPersistent)
  private
    FIncludeAlternativeRoutes: Boolean;
    FTravelMode: TTMSFNCDirectionsTravelMode;
    FAvoidTolls: Boolean;
    FPolyline: TTMSFNCRouteCalculatorPolylineOptions;
    FHistoryEnabled: Boolean;
    FLocale: string;
    FlocaleMode: TTMSFNCMapsLocaleMode;
    procedure SetPolyline(const Value: TTMSFNCRouteCalculatorPolylineOptions);
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property AvoidTolls: Boolean read FAvoidTolls write FAvoidTolls default False;
    property IncludeAlternativeRoutes: Boolean read FIncludeAlternativeRoutes write FIncludeAlternativeRoutes default False;
    property TravelMode: TTMSFNCDirectionsTravelMode read FTravelMode write FTravelMode default tmDriving;
    property Polyline: TTMSFNCRouteCalculatorPolylineOptions read FPolyline write SetPolyline;
    property HistoryEnabled: Boolean read FHistoryEnabled write FHistoryEnabled default False;
    property Locale: string read FLocale write FLocale;
    property LocaleMode: TTMSFNCMapsLocaleMode read FlocaleMode write FLocaleMode default mlmDefault;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCRouteCalculatorDirectionsItems = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCRouteCalculatorDirectionsItems = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCDirectionsItem>)
  {$ENDIF}
  private
    FOwner: TTMSFNCRouteCalculatorSegment;
    function GetItem(Index: Integer): TTMSFNCDirectionsItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCDirectionsItem);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCRouteCalculatorSegment); virtual;
    property Items[Index: Integer]: TTMSFNCDirectionsItem read GetItem write SetItem; default;
    function Add: TTMSFNCDirectionsItem;
    function Insert(Index: Integer): TTMSFNCDirectionsItem;
  end;

  TTMSFNCRouteCalculatorInfoMode = (cimDefault, cimUpdateDirections, cimAddDirections);

  TTMSFNCRouteCalculatorInfo = class
  private
    FID: string;
    FStartAddress: string;
    FEndAddress: string;
    FStartFound: Boolean;
    FEndFound: Boolean;
    FStartGeocoded: Boolean;
    FEndGeocoded: Boolean;
    FStartCoordinate: TTMSFNCMapsCoordinateRec;
    FEndCoordinate: TTMSFNCMapsCoordinateRec;
    FCallBack: Pointer;
    FMode: TTMSFNCRouteCalculatorInfoMode;
    FWayPoints: TTMSFNCMapsCoordinateRecArray;
    FUpdateRoute: TTMSFNCRouteCalculatorRoute;
    FUpdateSegments: array of TTMSFNCRouteCalculatorSegment;
  public
    constructor Create;
    destructor Destroy; override;
  end;

  TTMSFNCRouteCalculatorAlternativeSegment = class(TCollectionItem)
  private
    FOwner: TTMSFNCRouteCalculatorAlternativeSegments;
    FDataPointer: Pointer;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FStartCoordinate: TTMSFNCMapsCoordinate;
    FEndCoordinate: TTMSFNCMapsCoordinate;
    FCoordinates: TTMSFNCMapsCoordinates;
    FDistance: Double;
    FDuration: Integer;
    function GetPolyline: TTMSFNCMapsCoordinateRecArray;
    procedure SetCoordinates(const Value: TTMSFNCMapsCoordinates);
    function GetSegment: TTMSFNCRouteCalculatorSegment;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property Polyline: TTMSFNCMapsCoordinateRecArray read GetPolyline;
    property Segment: TTMSFNCRouteCalculatorSegment read GetSegment;
  published
    property Coordinates: TTMSFNCMapsCoordinates read FCoordinates write SetCoordinates;
    property Distance: Double read FDistance write FDistance;
    property Duration: Integer read FDuration write FDuration;

    property StartCoordinate: TTMSFNCMapsCoordinate read FStartCoordinate write FStartCoordinate;
    property EndCoordinate: TTMSFNCMapsCoordinate read FEndCoordinate write FEndCoordinate;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCRouteCalculatorAlternativeSegments = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCRouteCalculatorAlternativeSegments = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCRouteCalculatorAlternativeSegment>)
  {$ENDIF}
  private
    FOwner: TTMSFNCRouteCalculatorSegment;
    function GetItem(Index: Integer): TTMSFNCRouteCalculatorAlternativeSegment;
    procedure SetItem(Index: Integer; const Value: TTMSFNCRouteCalculatorAlternativeSegment);
    function GetSegment: TTMSFNCRouteCalculatorSegment;
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCRouteCalculatorSegment); virtual;
    property Items[Index: Integer]: TTMSFNCRouteCalculatorAlternativeSegment read GetItem write SetItem; default;
    function Add: TTMSFNCRouteCalculatorAlternativeSegment;
    function Insert(Index: Integer): TTMSFNCRouteCalculatorAlternativeSegment;
    property Segment: TTMSFNCRouteCalculatorSegment read GetSegment;
  end;


  TTMSFNCRouteCalculatorSegment = class(TCollectionItem)
  private
    FOwner: TTMSFNCRouteCalculatorSegments;
    FDataPointer: Pointer;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FID: string;
    FStartAddress: string;
    FEndAddress: string;
    FStartCoordinate: TTMSFNCMapsCoordinate;
    FEndCoordinate: TTMSFNCMapsCoordinate;
    FCoordinates: TTMSFNCMapsCoordinates;
    FDistance: Double;
    FDuration: Integer;
    FAlternativeSegments: TTMSFNCRouteCalculatorAlternativeSegments;
    function GetPolyline: TTMSFNCMapsCoordinateRecArray;
    function GetSegments: TTMSFNCRouteCalculatorSegments;
    procedure SetCoordinates(const Value: TTMSFNCMapsCoordinates);
    function GetNextSegment: TTMSFNCRouteCalculatorSegment;
    function GetPreviousSegment: TTMSFNCRouteCalculatorSegment;
    function GetRoute: TTMSFNCRouteCalculatorRoute;
    procedure SetAlternativeSegments(
      const Value: TTMSFNCRouteCalculatorAlternativeSegments);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property NextSegment: TTMSFNCRouteCalculatorSegment read GetNextSegment;
    property PreviousSegment: TTMSFNCRouteCalculatorSegment read GetPreviousSegment;
    property Segments: TTMSFNCRouteCalculatorSegments read GetSegments;
    property Polyline: TTMSFNCMapsCoordinateRecArray read GetPolyline;
    property Route: TTMSFNCRouteCalculatorRoute read GetRoute;
  published
    property ID: string read FID write FID;

    property AlternativeSegments: TTMSFNCRouteCalculatorAlternativeSegments read FAlternativeSegments write SetAlternativeSegments;

    property Coordinates: TTMSFNCMapsCoordinates read FCoordinates write SetCoordinates;
    property Distance: Double read FDistance write FDistance;
    property Duration: Integer read FDuration write FDuration;

    property StartAddress: string read FStartAddress write FStartAddress;
    property EndAddress: string read FEndAddress write FEndAddress;
    property StartCoordinate: TTMSFNCMapsCoordinate read FStartCoordinate write FStartCoordinate;
    property EndCoordinate: TTMSFNCMapsCoordinate read FEndCoordinate write FEndCoordinate;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCRouteCalculatorSegments = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCRouteCalculatorSegments = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCRouteCalculatorSegment>)
  {$ENDIF}
  private
    FOwner: TTMSFNCRouteCalculatorRoute;
    function GetItem(Index: Integer): TTMSFNCRouteCalculatorSegment;
    procedure SetItem(Index: Integer; const Value: TTMSFNCRouteCalculatorSegment);
    function GetRoute: TTMSFNCRouteCalculatorRoute;
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCRouteCalculatorRoute); virtual;
    property Items[Index: Integer]: TTMSFNCRouteCalculatorSegment read GetItem write SetItem; default;
    function Add: TTMSFNCRouteCalculatorSegment;
    function Insert(Index: Integer): TTMSFNCRouteCalculatorSegment;
    property Route: TTMSFNCRouteCalculatorRoute read GetRoute;
  end;

  TTMSFNCRouteCalculatorRoute = class(TCollectionItem)
  private
    FOwner: TTMSFNCCustomRouteCalculator;
    FDataPointer: Pointer;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FSegments: TTMSFNCRouteCalculatorSegments;
    FID: string;
    FStartAddress: string;
    FEndAddress: string;
    FStartCoordinate: TTMSFNCMapsCoordinate;
    FEndCoordinate: TTMSFNCMapsCoordinate;
    FRouteName: string;
    FActive: Boolean;
    FSteps: TTMSFNCDirectionsSteps;
    function GetPolyline: TTMSFNCMapsCoordinateRecArray;
    function GetWayPoints: TTMSFNCMapsCoordinateRecArray;
    function GetDistance: Double;
    function GetDuration: Double;
    function GetRouteCalculator: TTMSFNCCustomRouteCalculator;
    procedure SetSteps(const Value: TTMSFNCDirectionsSteps);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    function FirstSegment: TTMSFNCRouteCalculatorSegment;
    function LastSegment: TTMSFNCRouteCalculatorSegment;
    property Duration: Double read GetDuration;
    property Distance: Double read GetDistance;
    property Polyline: TTMSFNCMapsCoordinateRecArray read GetPolyline;
    property WayPoints: TTMSFNCMapsCoordinateRecArray read GetWayPoints;
    property RouteCalculator: TTMSFNCCustomRouteCalculator read GetRouteCalculator;
  published
    property ID: string read FID write FID;
    property RouteName: string read FRouteName write FRouteName;
    property Steps: TTMSFNCDirectionsSteps read FSteps write SetSteps;
    property Segments: TTMSFNCRouteCalculatorSegments read FSegments write FSegments;
    property Active: Boolean read FActive write FActive;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCRouteCalculatorRoutes = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCRouteCalculatorRoutes = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCRouteCalculatorRoute>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomRouteCalculator;
    function GetItem(Index: Integer): TTMSFNCRouteCalculatorRoute;
    procedure SetItem(Index: Integer; const Value: TTMSFNCRouteCalculatorRoute);
    function GetRouteCalculator: TTMSFNCCustomRouteCalculator;
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomRouteCalculator); virtual;
    property Items[Index: Integer]: TTMSFNCRouteCalculatorRoute read GetItem write SetItem; default;
    function Add: TTMSFNCRouteCalculatorRoute;
    function Insert(Index: Integer): TTMSFNCRouteCalculatorRoute;
    property RouteCalculator: TTMSFNCCustomRouteCalculator read GetRouteCalculator;
  end;

  { TTMSFNCCustomRouteCalculator }

  TTMSFNCRouteCalculatorCalculateRouteCallback = {$IFNDEF LCLLIB}reference to {$ENDIF}procedure(const ARoute: TTMSFNCRouteCalculatorRoute){$IFDEF LCLLIB} of object{$ENDIF};
  TTMSFNCRouteCalculatorCalculateRouteEvent = procedure(Sender: TObject; const ARoute: TTMSFNCRouteCalculatorRoute) of object;
  TTMSFNCRouteCalculatorCalculateRoutesCompleteEvent = procedure(Sender: TObject) of object;
  TTMSFNCRouteCalculatorLocationEvent = procedure(Sender: TObject; const ACoordinate: TTMSFNCMapsCoordinateRec) of object;
  TTMSFNCRouteCalculatorGPXEvent = procedure(Sender: TObject; const ARoute: TTMSFNCRouteCalculatorRoute) of object;

  TTMSFNCRouteCalculatorRouteCallBackWrapper = class
  private
    FCallback: TTMSFNCRouteCalculatorCalculateRouteCallback;
  public
    constructor Create(ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback);
  end;

  TTMSFNCCustomRouteCalculator = class(TTMSFNCCustomComponent)
  private
    FAPIKey: string;
    FLocation: TTMSFNCLocation;
    {$IFDEF SENSORSUPPORT}
    FLocationSensor: TLocationSensor;
    {$ENDIF}
    FGeocoding: TTMSFNCGeocoding;
    FDirections: TTMSFNCDirections;
    FService: TTMSFNCRouteCalculatorService;
    FOnGetGeocoding: TTMSFNCGeocodingGetGeocodingEvent;
    FOnGetDirections: TTMSFNCDirectionsGetDirectionsEvent;
    FOnCalculateRoute: TTMSFNCRouteCalculatorCalculateRouteEvent;
    FRoutes: TTMSFNCRouteCalculatorRoutes;
    FOnGetRouteGeocoding: TTMSFNCGeocodingGetGeocodingEvent;
    FOnGetRouteDirections: TTMSFNCDirectionsGetDirectionsEvent;
    FOnGetLocation: TTMSFNCRouteCalculatorLocationEvent;
    FOnCreateGPXSegment: TTMSFNCMapsGPXSegmentEvent;
    FOnCreateGPXTrack: TTMSFNCMapsGPXTrackEvent;
    FOnLoadGPXRouteComplete: TTMSFNCRouteCalculatorGPXEvent;
    FOptions: TTMSFNCRouteCalculatorOptions;
    FActive: Boolean;
    FHistoryManager: TTMSFNCRouteCalculatorHistoryManager;
    procedure SetService(const Value: TTMSFNCRouteCalculatorService);
    procedure SetAPIKey(const Value: string);
    procedure SetRoutes(const Value: TTMSFNCRouteCalculatorRoutes);
    function GetHistoryManager: TTMSFNCRouteCalculatorHistoryManager;
  protected
    procedure DoGetGeocoding(Sender: TObject; const ARequest: TTMSFNCGeocodingRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult);
    property OnGetGeocoding: TTMSFNCGeocodingGetGeocodingEvent read FOnGetGeocoding write FOnGetGeocoding;
    property OnGetRouteGeocoding: TTMSFNCGeocodingGetGeocodingEvent read FOnGetRouteGeocoding write FOnGetRouteGeocoding;
    procedure DoGetDirections(Sender: TObject; const ARequest: TTMSFNCDirectionsRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult);
    property OnGetDirections: TTMSFNCDirectionsGetDirectionsEvent read FOnGetDirections write FOnGetDirections;
    property OnGetRouteDirections: TTMSFNCDirectionsGetDirectionsEvent read FOnGetRouteDirections write FOnGetRouteDirections;
    procedure DoCalculateRoute(const ARoute: TTMSFNCRouteCalculatorRoute; const ARouteInfo: TTMSFNCRouteCalculatorInfo);
    property OnCalculateRoute: TTMSFNCRouteCalculatorCalculateRouteEvent read FOnCalculateRoute write FOnCalculateRoute;
    property OnCalculateRouteInternal: TTMSFNCRouteCalculatorCalculateRouteEvent read FOnCalculateRoute write FOnCalculateRoute;
    procedure DoGetLocation(Sender: TObject; const ARequest: TTMSFNCLocationRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult);
    property OnGetLocation: TTMSFNCRouteCalculatorLocationEvent read FOnGetLocation write FOnGetLocation;
	{$IFDEF SENSORSUPPORT}  
    procedure DoLocationChanged(Sender: TObject; const OldLocation, NewLocation: TLocationCoord2D);
    {$ENDIF}
    procedure DoCreateGPXTrack(AEventData: TTMSFNCMapsGPXTrackEventData); virtual;
    procedure DoCreateGPXSegment(AEventData: TTMSFNCMapsGPXSegmentEventData); virtual;
    property OnCreateGPXTrack: TTMSFNCMapsGPXTrackEvent read FOnCreateGPXTrack write FOnCreateGPXTrack;
    property OnCreateGPXSegment: TTMSFNCMapsGPXSegmentEvent read FOnCreateGPXSegment write FOnCreateGPXSegment;
    procedure DoLoadGPXRouteComplete(ARoute: TTMSFNCRouteCalculatorRoute); virtual;
    property OnLoadGPXRouteComplete: TTMSFNCRouteCalculatorGPXEvent read FOnLoadGPXRouteComplete write FOnLoadGPXRouteComplete;

    function GetInstance: NativeUInt; override;
    function GeocodingReady: Boolean;
    function DirectionsReady: Boolean;
    function LocationReady: Boolean;
    function GetAPIKey: string;
    function GetVersionNr: Integer;
    function GetDocURL: string; override;
    function GetTipsURL: string; override;
    function GetVersion: string; override;

    function InternalSaveGPX(ARoute: TTMSFNCRouteCalculatorRoute; AMetaData: TTMSFNCMapsGPXMetaData): string;
    function InternalLoadGPX(AText: string; ADoGeocoding: Boolean = False): TTMSFNCMapsGPXRec;

    property Service: TTMSFNCRouteCalculatorService read FService write SetService default csGoogle;
    property Version: string read GetVersion;
    property APIKey: string read FAPIKey write SetAPIKey;
    property Routes: TTMSFNCRouteCalculatorRoutes read FRoutes write SetRoutes;
    property Options: TTMSFNCRouteCalculatorOptions read FOptions write FOptions;

  public
    procedure Assign(Source: TPersistent); override;
    constructor Create; reintroduce; overload; virtual;
    constructor Create(AOwner: TComponent); overload; override;
    destructor Destroy; override;
    procedure GetGeocoding(AAddress: string; ACallback: TTMSFNCGeocodingGetGeocodingCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil); virtual;
    procedure GetReverseGeocoding(ACoordinates: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCGeocodingGetReverseGeocodingCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil); virtual;
    procedure GetDirections(AOrigin, ADestination: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCDirectionsGetDirectionsCallBack = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil; AID: string = ''; ADataPointer: Pointer = nil); virtual;

    procedure CalculateRoute(AStartAddress, AEndAddress: string; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = ''; ADataPointer: Pointer = nil); overload; virtual;
    procedure CalculateRoute(AStartCoordinate, AEndCoordinate: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil; AID: string = ''; ADataPointer: Pointer = nil); overload; virtual;

    procedure AddRouteSegment(ARoute: TTMSFNCRouteCalculatorRoute; AEndAddress: string; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = ''; ADataPointer: Pointer = nil); overload; virtual;
    procedure AddRouteSegment(ARoute: TTMSFNCRouteCalculatorRoute; AEndCoordinate: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = ''; ADataPointer: Pointer = nil); overload; virtual;

    procedure AddWayPointsToSegment(ASegment: TTMSFNCRouteCalculatorSegment; AWayPoints: TTMSFNCMapsCoordinateRecArray; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = ''; ADataPointer: Pointer = nil); virtual;

    procedure UpdateRouteSegment(ASegment: TTMSFNCRouteCalculatorSegment; AStartAddress, AEndAddress: string; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = ''; ADataPointer: Pointer = nil); overload; virtual;
    procedure UpdateRouteSegment(ASegment: TTMSFNCRouteCalculatorSegment; AStartCoordinate, AEndCoordinate: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = ''; ADataPointer: Pointer = nil); overload; virtual;
    procedure UpdateRouteSegmentStartAddress(ASegment: TTMSFNCRouteCalculatorSegment; AStartAddress: string; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = ''; ADataPointer: Pointer = nil); overload; virtual;
    procedure UpdateRouteSegmentEndAddress(ASegment: TTMSFNCRouteCalculatorSegment; AEndAddress: string; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = ''; ADataPointer: Pointer = nil); overload; virtual;
    procedure UpdateRouteSegmentStartCoordinate(ASegment: TTMSFNCRouteCalculatorSegment; AStartCoordinate: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = ''; ADataPointer: Pointer = nil); overload; virtual;
    procedure UpdateRouteSegmentEndCoordinate(ASegment: TTMSFNCRouteCalculatorSegment; AEndCoordinate: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = ''; ADataPointer: Pointer = nil); overload; virtual;

    procedure DeleteRoute(ARoute: TTMSFNCRouteCalculatorRoute);
    procedure DeleteRouteSegment(ASegment: TTMSFNCRouteCalculatorSegment; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil);
    procedure GetCurrentLocation(AMode: TTMSFNCRouteCalculatorLocationMode = lmDevice);

    function LoadGPXFromStream(const AStream: TStream; ADoGeocoding: Boolean = False): TTMSFNCMapsGPXRec;
    {$IFNDEF WEBLIB}
    function LoadGPXFromFile(AFileName: string; ADoGeocoding: Boolean = False): TTMSFNCMapsGPXRec;
    {$ENDIF}
    function LoadGPXFromText(AText: string; ADoGeocoding: Boolean = False): TTMSFNCMapsGPXRec;

    procedure SaveToGPXStream(ARoute: TTMSFNCRouteCalculatorRoute; AStream: TStream); overload; virtual;
    procedure SaveToGPXStream(ARoute: TTMSFNCRouteCalculatorRoute; AStream: TStream; AMetaData: TTMSFNCMapsGPXMetaData); overload; virtual;
    procedure SaveToGPXFile(ARoute: TTMSFNCRouteCalculatorRoute; AFileName: string); overload; virtual;
    procedure SaveToGPXFile(ARoute: TTMSFNCRouteCalculatorRoute; AFileName: string; AMetaData: TTMSFNCMapsGPXMetaData); overload; virtual;
    function SaveToGPXText(ARoute: TTMSFNCRouteCalculatorRoute): string; overload; virtual;
    function SaveToGPXText(ARoute: TTMSFNCRouteCalculatorRoute; AMetaData: TTMSFNCMapsGPXMetaData): string; overload; virtual;

    procedure SaveRoutesToStream(AStream: TStream); virtual;
    function SaveRoutesToText: string; virtual;
    procedure SaveRoutesToFile(AFileName: string); virtual;

    procedure LoadRoutesFromStream(AStream: TStream); virtual;
    procedure LoadRoutesFromText(AText: string); virtual;
    {$IFNDEF WEBLIB}
    procedure LoadRoutesFromFile(AFileName: string); virtual;
    {$ENDIF}

    function RunnningRequestCount: Integer;

    procedure Clear; virtual;
    procedure ClearRoutes; virtual;
    procedure SaveToGPXStream(ACoordinates: TTMSFNCMapsCoordinateRecArray; AStream: TStream); overload; virtual;
    procedure SaveToGPXStream(ACoordinates: TTMSFNCMapsCoordinateRecArray; AStream: TStream; AMetaData: TTMSFNCMapsGPXMetaData); overload; virtual;
    procedure SaveToGPXFile(ACoordinates: TTMSFNCMapsCoordinateRecArray; AFileName: string); overload; virtual;
    procedure SaveToGPXFile(ACoordinates: TTMSFNCMapsCoordinateRecArray; AFileName: string; AMetaData: TTMSFNCMapsGPXMetaData); overload; virtual;
    function SaveToGPXText(ACoordinates: TTMSFNCMapsCoordinateRecArray): string; overload; virtual;
    function SaveToGPXText(ACoordinates: TTMSFNCMapsCoordinateRecArray; AMetaData: TTMSFNCMapsGPXMetaData): string; overload; virtual;
    function HasRoutes: Boolean; virtual;
    property Geocoding: TTMSFNCGeocoding read FGeocoding;
    property Directions: TTMSFNCDirections read FDirections;
    property Active: Boolean read FActive write FActive default False;
    property HistoryManager: TTMSFNCRouteCalculatorHistoryManager read GetHistoryManager;
  end;

  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  TTMSFNCRouteCalculator = class(TTMSFNCCustomRouteCalculator)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property OnGetGeocoding;
    property OnGetDirections;
    property OnGetRouteGeocoding;
    property OnGetRouteDirections;
    property OnCalculateRoute;
    property OnCreateGPXTrack;
    property OnCreateGPXSegment;
    property OnLoadGPXRouteComplete;
    property OnGetLocation;
    property APIKey;
    property Service;
    property Version;
    property Routes;
    property Options;
  end;

implementation

uses
  {$IFDEF MSWINDOWS}
  Windows,
  {$ENDIF}
  {%H-}Math,
  WEBLib.TMSFNCUtils,
  WEBLib.TMSFNCPersistence,
  SysUtils
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  ;
  
 {$R TMSFNCRouteCalculator.res}

{ TTMSFNCCustomRouteCalculator }

function TTMSFNCCustomRouteCalculator.GetAPIKey: string;
begin
  Result := APIKey;
end;

function GetFirstChildNode(const ANode: TTMSFNCMapsXMLNode): TTMSFNCMapsXMLDomNode;
begin
  {$IFNDEF LCLWEBLIB}
  Result := ANode.DOMNode.firstChild;
  {$ELSE}
  Result := ANode.firstChild;
  {$ENDIF}
end;

function FindNodeAttribute(const ANode: TTMSFNCMapsXMLDOMNode; const ANodeName: string): TTMSFNCMapsXMLDomNode;
begin
  {$IFDEF WEBLIB}
  asm
    if (ANode.attributes){
      return ANode.attributes.getNamedItem(ANodeName);
    }else{
      return undefined;
    }
  end;
  {$ELSE}
  Result := ANode.Attributes.GetNamedItem(ANodeName);
  {$ENDIF}
end;

function FindNode(const ANode: TTMSFNCMapsXMLNode; const ANodeName: string): TTMSFNCMapsXMLNode;
begin
  {$IFNDEF LCLLIB}
  Result := ANode.ChildNodes.FindNode(ANodeName);
  {$ELSE}
  Result := ANode.FindNode(ANodeName);
  {$ENDIF}
end;

function NodeToString(ANode: TTMSFNCMapsXMLDOMNode): string;
begin
  Result := '';
  if not Assigned(ANode) then
    Exit;

  Result := ANode.nodeValue;
end;


{$IFNDEF WEBLIB}
function TTMSFNCCustomRouteCalculator.LoadGPXFromFile(AFileName: string; ADoGeocoding: Boolean = False): TTMSFNCMapsGPXRec;
var
  ms: TMemoryStream;
begin
  ms := TMemoryStream.Create;
  try
    ms.LoadFromFile(AFileName);
    Result := LoadGPXFromStream(ms, ADoGeocoding);
  finally
    ms.Free;
  end;
end;

{$ENDIF}

function TTMSFNCCustomRouteCalculator.LoadGPXFromStream(const AStream: TStream; ADoGeocoding: Boolean = False): TTMSFNCMapsGPXRec;
var
  s: TStringStream;
begin
  s := TStringStream.Create('');
  try
    AStream.Position := 0;
    s.CopyFrom(AStream, AStream.Size);
    Result := LoadGPXFromText(s.DataString, ADoGeocoding);
  finally
    s.Free;
  end;
end;

function TTMSFNCCustomRouteCalculator.LoadGPXFromText(AText: string; ADoGeocoding: Boolean = False): TTMSFNCMapsGPXRec;
begin
  Result := InternalLoadGPX(AText, ADoGeocoding);
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomRouteCalculator.LoadRoutesFromFile(AFileName: string);
var
  ms: TMemoryStream;
begin
  ms := TMemoryStream.Create;
  try
    ms.LoadFromFile(AFileName);
    ms.Position := 0;
    LoadRoutesFromStream(ms);
  finally
    ms.Free;
  end;
end;
{$ENDIF}

procedure TTMSFNCCustomRouteCalculator.LoadRoutesFromStream(AStream: TStream);
begin
  TTMSFNCPersistence.LoadSettingsFromStream(Routes, AStream);
end;

procedure TTMSFNCCustomRouteCalculator.LoadRoutesFromText(AText: string);
var
  s: TStringStream;
begin
  s := TStringStream.Create(AText);
  try
    s.Position := 0;
    LoadRoutesFromStream(s);
  finally
    s.Free;
  end;
end;

function TTMSFNCCustomRouteCalculator.InternalLoadGPX(AText: string; ADoGeocoding: Boolean = False): TTMSFNCMapsGPXRec;
var
  xmldoc: TTMSFNCMapsXMLDocument;
  iNode, nNode, sNode: TTMSFNCMapsXMLNode;
  snlat, snlng, cNode, eNode, pNode: TTMSFNCMapsXMLDomNode;
  v: Double;
  parentNode: TTMSFNCMapsXMLNode;
  gpx11: Boolean;
  cnt, cntTr, cntSeg: Integer;
  {$IFDEF LCLLIB}
  ss: TStringStream;
  {$ENDIF}
  allpath: TTMSFNCMapsCoordinateRecArray;
  nodeName, timeStamp: string;
  ele, maxHeight, minHeight: Double;
  heightFound: Boolean;
  trackEventData: TTMSFNCMapsGPXTrackEventData;
  segmentEventData: TTMSFNCMapsGPXSegmentEventData;
  I, J, K: Integer;
  r: TTMSFNCRouteCalculatorRoute;
  s: TTMSFNCRouteCalculatorSegment;
  c: TTMSFNCMapsCoordinateItem;
  distance: double;
  o: TTMSFNCRouteCalculatorInfo;
begin
  {$IFDEF LCLLIB}
  ss := TStringStream.Create(AText);
  try
    ReadXMLFile(xmldoc, ss);
  finally
    ss.Free;
  end;
  {$ELSE}
  {$IFDEF WEBLIB}
  asm
    var parser = new DOMParser();
    xmldoc = parser.parseFromString(AText, 'text/xml');
  end;
  {$ELSE}
  xmldoc := TXmlDocument.Create(nil);
  xmldoc.LoadFromXML(AText);
  {$ENDIF}
  {$ENDIF}

  parentNode := xmldoc.DocumentElement;

  gpx11 := false;
  cntTr := -1;
  minHeight := 0;
  maxHeight := 0;

  if Assigned(parentNode) then
  begin
    iNode := FindNode(parentNode, 'trk');

    if not Assigned(iNode) then
    begin
      iNode := FindNode(parentNode, 'rte');
      gpx11 := true;
    end;

    repeat
      if Assigned(iNode) then
      begin
        cntSeg := -1;
        inc(cntTr);

        SetLength(Result.Tracks, cntTr + 1);

        nNode := FindNode(iNode, 'name');
        if Assigned(nNode) then
        begin
          {$IFNDEF LCLWEBLIB}
          Result.Tracks[cntTr].Name := nNode.Text;
          {$ENDIF}
          {$IFDEF LCLWEBLIB}
          Result.Tracks[cntTr].Name := nNode.nodeValue;
          {$ENDIF}
        end;

        trackEventData := TTMSFNCMapsGPXTrackEventData.Create;
        trackEventData.Node := iNode{$IFNDEF LCLWEBLIB}.DOMNode{$ENDIF};
        trackEventData.Data := Result.Tracks[cntTr];
        try
        DoCreateGPXTrack(trackEventData);
        finally
          trackEventData.Free;
        end;

        if not gpx11 then
          sNode := FindNode(iNode, 'trkseg')
        else
          sNode := iNode;

        repeat
          if Assigned(sNode) and sNode.HasChildNodes then
          begin
            cnt := -1;
            inc(cntSeg);
            SetLength(Result.Tracks[cntTr].Segments, cntSeg + 1);
            heightFound := false;

            cNode := GetFirstChildNode(sNode);

            while Assigned(cNode) do
            begin
              pNode := cNode;
              nodeName := pNode.NodeName;

              if not gpx11 or (gpx11 and (pNode.NodeName = 'rtept')) then
              begin
                snlat := FindNodeAttribute(pNode, 'lat');
                snlng := FindNodeAttribute(pNode, 'lon');

                if Assigned(snlat) and Assigned(snlng) then
                begin
                  inc(cnt);
                  SetLength(Result.Tracks[cntTr].Segments[cntSeg], cnt + 1);

                  if nodeName = 'trkpt' then
                  begin
                    eNode := FindNodeAttribute(pNode, 'ele');
                    if Assigned(eNode) then
                    begin
                      ele := 0;
                      if TryStrToFloatDot(eNode.nodeValue, ele) then
                      begin
                        if heightFound then
                        begin
                          if ele > maxHeight then
                            maxHeight := ele;
                          if ele < minHeight then
                            minHeight := ele;
                        end
                        else
                        begin
                          heightFound := true;
                          maxHeight := ele;
                          minHeight := ele;
                        end;

                        Result.Tracks[cntTr].Segments[cntSeg][cnt].Elevation := ele;
                        Result.Tracks[cntTr].Segments[cntSeg][cnt].HasElevation := True;
                      end;
                    end;

                    eNode := FindNodeAttribute(pNode, 'time');
                    if Assigned(eNode) then
                    begin
                      timestamp := eNode.nodeValue;
                      if timestamp <> '' then
                      begin
                        Result.Tracks[cntTr].Segments[cntSeg][cnt].TimeStamp := TTMSFNCUtils.ISOToDateTime(timestamp, True);
                        Result.Tracks[cntTr].Segments[cntSeg][cnt].HasTimeStamp := True;
                      end;
                    end;
                  end;

                  v := 0;
                  if TryStrToFloatDot(snlat.nodeValue, v) then
                    Result.Tracks[cntTr].Segments[cntSeg][cnt].Latitude := v;

                  v := 0;
                  if TryStrToFloatDot(snlng.nodeValue, v) then
                    Result.Tracks[cntTr].Segments[cntSeg][cnt].Longitude := v;

                  SetLength(allpath, Length(allpath) + 1);
                  allpath[Length(allpath) - 1] := CreateCoordinate(Result.Tracks[cntTr].Segments[cntSeg][cnt].Latitude, Result.Tracks[cntTr].Segments[cntSeg][cnt].Longitude);

                  segmentEventData := TTMSFNCMapsGPXSegmentEventData.Create;
                  segmentEventData.Node := pNode;
                  segmentEventData.Data := Result.Tracks[cntTr].Segments[cntSeg][cnt];
                  try
                    DoCreateGPXSegment(segmentEventData);
                  finally
                    segmentEventData.Free;
                  end;
                end;
              end;

              cNode := cNode.nextSibling;
            end;
          end;

          if Assigned(sNode) then
            sNode := sNode.NextSibling;

        until (not Assigned(sNode));
      end;

      if Assigned(iNode) then
        iNode := iNode.NextSibling;
    until (not Assigned(iNode));
  end;

  for I := 0 to Length(Result.Tracks) - 1 do
  begin
    r := Routes.Add;
    r.RouteName := Result.Tracks[I].Name;
    for J := 0 to Length(Result.Tracks[I].Segments) - 1 do
    begin
      distance := 0;
      s := r.Segments.Add;
      for K := 0 to Length(Result.Tracks[I].Segments[J]) - 1 do
      begin
        c := s.Coordinates.Add;
        c.Latitude := Result.Tracks[I].Segments[J][K].Latitude;
        c.Longitude := Result.Tracks[I].Segments[J][K].Longitude;
        if K = 0 then
        begin
          if J = 0 then
            r.FStartCoordinate.Assign(c.Coordinate);
          s.FStartCoordinate.Assign(c.Coordinate);
        end;
        if K = Length(Result.Tracks[I].Segments[J]) - 1 then
        begin
          if J = Length(Result.Tracks[I].Segments) - 1 then
            r.FEndCoordinate.Assign(c.Coordinate);
          s.FEndCoordinate.Assign(c.Coordinate);
        end;
        if K > 0 then
          distance := distance + MeasureDistance(c.Coordinate.ToRec, s.Coordinates[K - 1].Coordinate.ToRec);
      end;
      s.Distance := distance;
    end;
    if not ADoGeocoding then
      DoLoadGPXRouteComplete(r)
    else
    begin
      for J := 0 to r.Segments.Count - 1 do
      begin
        s := r.Segments[J];
        o := TTMSFNCRouteCalculatorInfo.Create;
        o.FStartGeocoded := False;
        o.FEndGeocoded := True;
        GetReverseGeocoding(s.FStartCoordinate.ToRec, nil, 'GPXStart', o);
        GetReverseGeocoding(s.FEndCoordinate.ToRec, nil, 'GPXEnd', o);
      end;
    end;
  end;
end;

function TTMSFNCCustomRouteCalculator.InternalSaveGPX(
  ARoute: TTMSFNCRouteCalculatorRoute;
  AMetaData: TTMSFNCMapsGPXMetaData): string;
var
  ca: TTMSFNCMapsCoordinateRecArray;
var
  I, J: integer;
  hdr,ftr, wpts: string;
  sl: TStringList;
  {$IFDEF WEBLIB}
  d, t: string;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  d, t: Char;
  {$ENDIF}
begin
  t := FormatSettings.ThousandSeparator;
  d := FormatSettings.DecimalSeparator;
  FormatSettings.DecimalSeparator := '.';
  FormatSettings.ThousandSeparator := ',';

  ca := ARoute.GetWayPoints;
  wpts := '';
  for I := 0 to Length(ca) - 1 do
  begin
    wpts := wpts + Format('<wpt lat="%.7f" lon="%.7f">', [ca[I].Latitude, ca[I].Longitude]);
    if ca[I].HasElevation then
      wpts := wpts + Format('<ele>%.7f</ele>', [ARoute.GetWayPoints[I].Elevation]);
    if ca[I].Description <> '' then
      wpts := wpts + '<name>' + ca[I].Description + '</name>';
    wpts := wpts + '</wpt>';
  end;

  hdr :=
    '<?xml version="1.0"?>'+
    '<gpx xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" '+
    'xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd" version="1.1" creator="TMS FNC Maps" xmlns="http://www.topografix.com/GPX/1/1">'+
    '<metadata>'+
    '<author>'+
    '<name>%s</name>'+
    '<link href="%s" />'+
    '</author>'+
    '</metadata>'+
    wpts +
    '<trk>'+
    '<name>%s</name>'+
    '<type>%s</type>';

  ftr :=
    '</trk>'+
    '</gpx>';

  hdr := Format(hdr,[AMetaData.AuthorName, AMetaData.AuthorLink, AMetaData.TrackName, AMetaData.TrackType]);

  sl := TStringList.Create;
  try
    sl.Add(hdr);

    for I := 0 to ARoute.Segments.Count - 1 do
    begin
      sl.Add('<trkseg>');
      for J := 0 to ARoute.Segments[I].Coordinates.Count - 1 do
        sl.Add(Format('<trkpt lat="%.7f" lon="%.7f" />', [ARoute.Segments[I].Coordinates[J].Latitude, ARoute.Segments[I].Coordinates[J].Longitude]));
      sl.Add('</trkseg>');
    end;

    sl.Add(ftr);

    Result := sl.Text;
  finally
    sl.Free;
  end;

  FormatSettings.ThousandSeparator := t;
  FormatSettings.DecimalSeparator := d;
end;

procedure TTMSFNCCustomRouteCalculator.GetCurrentLocation(
  AMode: TTMSFNCRouteCalculatorLocationMode);
begin
  if AMode = lmService then
  begin
    if not LocationReady then
    begin
      raise Exception.Create('Please set API key!');
      Exit;
    end;

    if (Service = csGoogle) then
      FLocation.GetLocation;
  end
  else
  begin
    {$IFDEF SENSORSUPPORT}  
    FLocationSensor.Active := True;
    {$ENDIF}
  end;
end;

procedure TTMSFNCCustomRouteCalculator.GetDirections(AOrigin,
  ADestination: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCDirectionsGetDirectionsCallback = nil;
  AWayPoints: TTMSFNCMapsCoordinateRecArray = nil; AID: string = ''; ADataPointer: Pointer = nil);
begin
  if not DirectionsReady then
  begin
    raise Exception.Create('Please set API key!');
    Exit;
  end;

  FDirections.GetDirections(AOrigin, ADestination, ACallBack, AID, ADataPointer,
    Options.IncludeAlternativeRoutes, Options.TravelMode, AWayPoints, False, Options.Locale, Options.LocaleMode, Options.AvoidTolls);
end;

function TTMSFNCCustomRouteCalculator.GetDocURL: string;
begin
  Result := TTMSFNCRouteCalculatorDocURL;
end;

function TTMSFNCCustomRouteCalculator.GetVersion: string;
var
  vn: Integer;
begin
  vn := GetVersionNr;
  Result := IntToStr(Hi(Hiword(vn)))+'.'+IntToStr(Lo(Hiword(vn)))+'.'+IntToStr(Hi(Loword(vn)))+'.'+IntToStr(Lo(Loword(vn)));
end;

function TTMSFNCCustomRouteCalculator.GetVersionNr: Integer;
begin
  Result := MakeLong(MakeWord(BLD_VER,REL_VER),MakeWord(MIN_VER,MAJ_VER));
end;

procedure TTMSFNCCustomRouteCalculator.SaveToGPXFile(
  ACoordinates: TTMSFNCMapsCoordinateRecArray; AFileName: string);
begin
  SaveToGPXFile(ACoordinates, AFileName, DefaultGPXMetaData);
end;

procedure TTMSFNCCustomRouteCalculator.SaveToGPXFile(
  ACoordinates: TTMSFNCMapsCoordinateRecArray; AFileName: string;
  AMetaData: TTMSFNCMapsGPXMetaData);
var
  sl: TStringList;
begin
  sl := TStringList.Create;
  try
    sl.Text := SaveToGPXText(ACoordinates, AMetaData);
    sl.SaveToFile(AFileName);
  finally
    sl.Free;
  end;
end;

procedure TTMSFNCCustomRouteCalculator.SaveToGPXStream(
  ACoordinates: TTMSFNCMapsCoordinateRecArray; AStream: TStream);
begin
  SaveToGPXStream(ACoordinates, AStream, DefaultGPXMetaData);
end;

procedure TTMSFNCCustomRouteCalculator.SaveToGPXStream(
  ACoordinates: TTMSFNCMapsCoordinateRecArray; AStream: TStream;
  AMetaData: TTMSFNCMapsGPXMetaData);
var
  ss: TStringStream;
begin
  ss := TStringStream.Create(SaveToGPXText(ACoordinates, AMetaData));
  try
    AStream.CopyFrom(ss, ss.Size);
  finally
    ss.Free;
  end;
end;

function TTMSFNCCustomRouteCalculator.SaveToGPXText(
  ACoordinates: TTMSFNCMapsCoordinateRecArray): string;
begin
  Result := SaveToGPX(ACoordinates, DefaultGPXMetaData);
end;

function TTMSFNCCustomRouteCalculator.SaveToGPXText(
  ACoordinates: TTMSFNCMapsCoordinateRecArray;
  AMetaData: TTMSFNCMapsGPXMetaData): string;
begin
  Result := SaveToGPX(ACoordinates, AMetaData);
end;

function TTMSFNCCustomRouteCalculator.HasRoutes: Boolean;
begin
  Result := False;
  if Routes.Count <= 0 then
    Exit;

  if Routes[0].Segments.Count <= 0 then
    Exit;

  if Routes[0].Segments[0].Coordinates.Count <= 0 then
    Exit;

  Result := True;
end;

function TTMSFNCCustomRouteCalculator.LocationReady: Boolean;
begin
  if Assigned(FLocation) then
    FLocation.APIKey := APIKey;
  Result := Assigned(FLocation) and (FLocation.APIKey <> '');
end;

function TTMSFNCCustomRouteCalculator.RunnningRequestCount: Integer;
begin
  Result := FDirections.GetRequestCount(True);
end;

procedure TTMSFNCCustomRouteCalculator.AddRouteSegment(
  ARoute: TTMSFNCRouteCalculatorRoute; AEndCoordinate: TTMSFNCMapsCoordinateRec;
  ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = '';
  ADataPointer: Pointer = nil);
var
  o: TTMSFNCRouteCalculatorInfo;
  sg: TTMSFNCRouteCalculatorSegment;
begin
  if not Assigned(ARoute) or (ARoute.Segments.Count = 0) then
    Exit;

  o := TTMSFNCRouteCalculatorInfo.Create;
  o.FID := AID;

  sg := ARoute.LastSegment;

  o.FStartAddress := sg.EndAddress;
  o.FStartCoordinate := sg.EndCoordinate.ToRec;
  o.FEndCoordinate := AEndCoordinate;
  o.FUpdateRoute := ARoute;
  o.FMode := cimAddDirections;

  if Assigned(ACallback) then
    o.FCallBack := TTMSFNCRouteCalculatorRouteCallBackWrapper.Create(ACallback)
  else
    o.FCallBack := nil;

  GetDirections(o.FStartCoordinate, o.FEndCoordinate, nil, nil, AID, o);
end;

procedure TTMSFNCCustomRouteCalculator.AddWayPointsToSegment(
  ASegment: TTMSFNCRouteCalculatorSegment;
  AWayPoints: TTMSFNCMapsCoordinateRecArray;
  ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback; AID: string;
  ADataPointer: Pointer);
var
  o: TTMSFNCRouteCalculatorInfo;
begin
  if not Assigned(ASegment) then
    Exit;

  o := TTMSFNCRouteCalculatorInfo.Create;
  o.FID := AID;
  o.FUpdateRoute := ASegment.Route;
  o.FMode := cimUpdateDirections;

  o.FStartCoordinate := ASegment.StartCoordinate.ToRec;
  o.FEndCoordinate := ASegment.EndCoordinate.ToRec;
  SetLength(o.FUpdateSegments, 1);
  o.FUpdateSegments[0] := ASegment;

  if Assigned(ACallback) then
    o.FCallBack := TTMSFNCRouteCalculatorRouteCallBackWrapper.Create(ACallback)
  else
    o.FCallBack := nil;

  GetDirections(o.FStartCoordinate, o.FEndCoordinate, nil, AWayPoints, AID, o);
end;

procedure TTMSFNCCustomRouteCalculator.AddRouteSegment(
  ARoute: TTMSFNCRouteCalculatorRoute; AEndAddress: string;
  ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = '';
  ADataPointer: Pointer = nil);
var
  o: TTMSFNCRouteCalculatorInfo;
  sg: TTMSFNCRouteCalculatorSegment;
begin
  if not Assigned(ARoute) or (ARoute.Segments.Count = 0) then
    Exit;

  o := TTMSFNCRouteCalculatorInfo.Create;
  o.FID := AID;

  sg := ARoute.LastSegment;

  o.FStartAddress := sg.EndAddress;
  o.FStartCoordinate := sg.EndCoordinate.ToRec;
  o.FEndAddress := AEndAddress;
  o.FStartFound := True;
  o.FUpdateRoute := ARoute;
  o.FMode := cimAddDirections;

  if Assigned(ACallback) then
    o.FCallBack := TTMSFNCRouteCalculatorRouteCallBackWrapper.Create(ACallback)
  else
    o.FCallBack := nil;

  GetGeocoding(o.FEndAddress, nil, 'End', o);
end;

procedure TTMSFNCCustomRouteCalculator.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TTMSFNCCustomRouteCalculator) then
  begin
    FActive := (Source as TTMSFNCCustomRouteCalculator).Active;
    FService := (Source as TTMSFNCCustomRouteCalculator).Service;
    FAPIKey := (Source as TTMSFNCCustomRouteCalculator).APIKey;
    FRoutes.Assign((Source as TTMSFNCCustomRouteCalculator).Routes);
  end;
end;

procedure TTMSFNCCustomRouteCalculator.GetGeocoding(AAddress: string; ACallback: TTMSFNCGeocodingGetGeocodingCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil);
begin
  if not GeocodingReady then
  begin
    raise Exception.Create('Please set API key!');
    Exit;
  end;

  FGeocoding.GetGeocoding(AAddress, ACallBack, AID, ADataPointer);
end;

function TTMSFNCCustomRouteCalculator.GetHistoryManager: TTMSFNCRouteCalculatorHistoryManager;
begin
  if not Assigned(FHistoryManager) then
    FHistoryManager := TTMSFNCRouteCalculatorHistoryManager.Create(Routes);

  Result := FHistoryManager;
end;

function TTMSFNCCustomRouteCalculator.GetInstance: NativeUInt;
begin
  Result := HInstance;
end;

procedure TTMSFNCCustomRouteCalculator.GetReverseGeocoding(ACoordinates: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCGeocodingGetReverseGeocodingCallBack = nil; AID: string = ''; ADataPointer: Pointer = nil);
begin
  if not GeocodingReady then
  begin
    raise Exception.Create('Please set API key!');
    Exit;
  end;

  FGeocoding.GetReverseGeocoding(ACoordinates, ACallBack, AID, ADataPointer);
end;

function TTMSFNCCustomRouteCalculator.GetTipsURL: string;
begin
  Result := TTMSFNCGeocodingTipsURL;
end;

procedure TTMSFNCCustomRouteCalculator.CalculateRoute(AStartCoordinate,
  AEndCoordinate: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil;
  AWayPoints: TTMSFNCMapsCoordinateRecArray = nil; AID: string = ''; ADataPointer: Pointer = nil);
var
  o: TTMSFNCRouteCalculatorInfo;
begin
  o := TTMSFNCRouteCalculatorInfo.Create;
  o.FID := AID;
  o.FStartCoordinate.Latitude := AStartCoordinate.Latitude;
  o.FEndCoordinate.Latitude := AEndCoordinate.Latitude;
  o.FStartCoordinate.Longitude := AStartCoordinate.Longitude;
  o.FEndCoordinate.Longitude := AEndCoordinate.Longitude;

  if Assigned(ACallback) then
    o.FCallBack := TTMSFNCRouteCalculatorRouteCallBackWrapper.Create(ACallback)
  else
    o.FCallBack := nil;

  GetDirections(o.FStartCoordinate, o.FEndCoordinate, nil, AWayPoints, o.FID, o);
end;

procedure TTMSFNCCustomRouteCalculator.CalculateRoute(AStartAddress,
  AEndAddress: string; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil;
  AID: string = ''; ADataPointer: Pointer = nil);
var
  o: TTMSFNCRouteCalculatorInfo;
begin
  o := TTMSFNCRouteCalculatorInfo.Create;
  o.FID := AID;
  o.FStartAddress := AStartAddress;
  o.FEndAddress := AEndAddress;

  o.FMode := cimAddDirections;

  if Assigned(ACallback) then
    o.FCallBack := TTMSFNCRouteCalculatorRouteCallBackWrapper.Create(ACallback)
  else
    o.FCallBack := nil;

  GetGeocoding(o.FStartAddress, nil, 'Start', o);
  GetGeocoding(o.FEndAddress, nil, 'End', o);
end;

procedure TTMSFNCCustomRouteCalculator.Clear;
begin
  BeginUpdate;
  ClearRoutes;
  if Assigned(FGeocoding) then
    FGeocoding.GeocodingRequests.Clear;
  if Assigned(FDirections) then
    FDirections.DirectionsRequests.Clear;
  if Assigned(FLocation) then
    FLocation.LocationRequests.Clear;
  EndUpdate;
end;

procedure TTMSFNCCustomRouteCalculator.ClearRoutes;
begin
  BeginUpdate;
  Routes.Clear;
  EndUpdate;
end;

constructor TTMSFNCCustomRouteCalculator.Create;
begin
  Create(nil);
end;

constructor TTMSFNCCustomRouteCalculator.Create(AOwner: TComponent);
begin
  inherited;
  FActive := False;
  FService := csGoogle;

  FGeocoding := TTMSFNCGeocoding.Create(Self);

  FGeocoding.OnGetGeocoding := {$IFDEF LCLLIB}@{$ENDIF}DoGetGeocoding;
  FGeocoding.OnGetReverseGeocoding := {$IFDEF LCLLIB}@{$ENDIF}DoGetGeocoding;

  FDirections := TTMSFNCDirections.Create(Self);
  FDirections.OnGetDirections:= {$IFDEF LCLLIB}@{$ENDIF}DoGetDirections;

  FLocation := TTMSFNCLocation.Create(Self);
  FLocation.OnGetLocation := {$IFDEF LCLLIB}@{$ENDIF}DoGetLocation;

  FRoutes := TTMSFNCRouteCalculatorRoutes.Create(Self);
  FOptions := TTMSFNCRouteCalculatorOptions.Create;

  {$IFDEF SENSORSUPPORT}
  FLocationSensor := TLocationSensor.Create(Self);
  FLocationSensor.OnLocationChanged := DoLocationChanged;
  {$ENDIF}

//  auto assign if fncmaps found
//  if IsDesignTime and Assigned(AOwner) and (AOwner is TCustomForm) then
//  begin
//    for I := 0 to AOwner.ComponentCount - 1 do
//    begin
//      if (AOwner.Components[i] is TTMSFNCCustomGrid) then
//      begin
//        Grid := AOwner.Components[i] as TTMSFNCCustomGrid;
//        Break;
//      end;
//    end;
//  end;
end;

function TTMSFNCCustomRouteCalculator.GeocodingReady: Boolean;
begin
  if Assigned(FGeocoding) then
    FGeocoding.APIKey := APIKey;
  Result := Assigned(FGeocoding) and (FGeocoding.APIKey <> '');
end;

procedure TTMSFNCCustomRouteCalculator.DoCalculateRoute(const ARoute: TTMSFNCRouteCalculatorRoute; const ARouteInfo: TTMSFNCRouteCalculatorInfo);
var
  obj: TTMSFNCRouteCalculatorRouteCallBackWrapper;
begin
  if not Assigned(ARoute) or not Assigned(ARouteInfo) then
    Exit;

  if Assigned(ARouteInfo.FCallBack) then
  begin
    obj := TTMSFNCRouteCalculatorRouteCallBackWrapper(ARouteInfo.FCallBack);
    obj.FCallback(ARoute);
    obj.Free;
  end;

  if Assigned(OnCalculateRouteInternal) then
    OnCalculateRouteInternal(Self, ARoute);

  if Assigned(OnCalculateRoute) then
    OnCalculateRoute(Self, ARoute);
end;

procedure TTMSFNCCustomRouteCalculator.DoCreateGPXSegment(
  AEventData: TTMSFNCMapsGPXSegmentEventData);
begin
  if Assigned(OnCreateGPXSegment) then
    OnCreateGPXSegment(Self, AEventData);
end;

procedure TTMSFNCCustomRouteCalculator.DoCreateGPXTrack(
  AEventData: TTMSFNCMapsGPXTrackEventData);
begin
  if Assigned(OnCreateGPXTrack) then
    OnCreateGPXTrack(Self, AEventData);
end;

procedure TTMSFNCCustomRouteCalculator.DoGetDirections(Sender: TObject;
  const ARequest: TTMSFNCDirectionsRequest;
  const ARequestResult: TTMSFNCCloudBaseRequestResult);
var
  o: TTMSFNCRouteCalculatorInfo;
  I: Integer;
  d: TTMSFNCDirectionsItem;
  r: TTMSFNCRouteCalculatorRoute;
  s, seg: TTMSFNCRouteCalculatorSegment;
  l: TTMSFNCDirectionsLeg;

  procedure ApplyLeg(ASegment: TTMSFNCRouteCalculatorSegment; ALeg: TTMSFNCDirectionsLeg);
  var
    K: Integer;
    la: TTMSFNCDirectionsLeg;
    sa: TTMSFNCRouteCalculatorAlternativeSegment;
    da: TTMSFNCDirectionsItem;
  begin
    ASegment.Coordinates.Assign(ALeg.Coordinates);
    if ALeg.StartAddress <> '' then
      ASegment.StartAddress := ALeg.StartAddress
    else
      ASegment.StartAddress := o.FStartAddress;
    if ALeg.EndAddress <> '' then
      ASegment.EndAddress := ALeg.EndAddress
    else
      ASegment.EndAddress := o.FEndAddress;
    ASegment.StartCoordinate.Assign(ALeg.StartLocation);
    ASegment.EndCoordinate.Assign(ALeg.EndLocation);
    ASegment.Duration := ALeg.Duration;
    ASegment.Distance := ALeg.Distance;

    ASegment.AlternativeSegments.Clear;

    for K := 1 to ARequest.Items.Count - 1 do
    begin
      da := ARequest.Items[K];

      if da.Legs.Count > 0 then
      begin
        if (ALeg.Index >= 0) and (ALeg.Index <= da.Legs.Count - 1) then
        begin
          la := da.Legs[ALeg.Index];
          sa := ASegment.AlternativeSegments.Add;
          sa.Coordinates.Assign(la.Coordinates);
          sa.StartCoordinate.Assign(la.StartLocation);
          sa.EndCoordinate.Assign(la.EndLocation);
          sa.Duration := la.Duration;
          sa.Distance := la.Distance;
        end;
      end;
    end;
  end;

begin
  o := nil;
  if ARequestResult.Success then
  begin
    if Assigned(ARequest.DataPointer) and (TObject(ARequest.DataPointer) is TTMSFNCRouteCalculatorInfo) then
    begin
      o := TTMSFNCRouteCalculatorInfo(ARequest.DataPointer);
      if Assigned(o) then
      begin
        if ARequest.Items.Count = 0 then
          DoCalculateRoute(nil, o)
        else
        begin
          if Options.HistoryEnabled then
          begin
            if HistoryManager.Count = 0 then 
              HistoryManager.PushState('Initialize');
          end;

          d := ARequest.Items[0];

          if Assigned(o.FUpdateRoute) then
          begin
            r := o.FUpdateRoute;
            r.Steps.Clear;
            for I := 0 to d.Steps.Count - 1 do
              r.Steps.Add.Assign(d.Steps[I]);
          end
          else
          begin
            r := Routes.Add;
            r.ID := o.FID;
            r.Steps.Assign(d.Steps);
          end;

          if Length(o.FUpdateSegments) > 0 then
          begin
            for I := 0 to Length(o.FUpdateSegments) - 1 do
            begin
              s := o.FUpdateSegments[I];
              if I <= d.Legs.Count - 1 then
                ApplyLeg(s, d.Legs[I]);
            end;

            seg := o.FUpdateSegments[Length(o.FUpdateSegments) - 1];

            for I := Length(o.FUpdateSegments) to d.Legs.Count - 1 do
            begin
              s := r.Segments.Insert(seg.Index + 1);
              ApplyLeg(s, d.Legs[I]);
            end;
          end
          else
          begin
            for I := 0 to d.Legs.Count - 1 do
            begin
              s := r.Segments.Add;
              l := d.Legs[I];
              ApplyLeg(s, l);
            end;
          end;

          if Options.HistoryEnabled then
            HistoryManager.PushState('RouteAction' + IntToStr(HistoryManager.Count));

          DoCalculateRoute(r, o);
        end;
      end;
    end;
  end;

  if Assigned(o) then
  begin
    if Assigned(OnGetRouteDirections) then
      OnGetRouteDirections(Self, ARequest, ARequestResult);

    o.Free;
  end
  else
  begin
    if Assigned(OnGetDirections) then
      OnGetDirections(Self, ARequest, ARequestResult);
  end;
end;

procedure TTMSFNCCustomRouteCalculator.DoGetGeocoding(Sender: TObject; const ARequest: TTMSFNCGeocodingRequest;
  const ARequestResult: TTMSFNCCloudBaseRequestResult);
var
  o: TTMSFNCRouteCalculatorInfo;
  I: Integer;
begin
  o := nil;
  if ARequestResult.Success then
  begin
    if Assigned(ARequest.DataPointer) and (TObject(ARequest.DataPointer) is TTMSFNCRouteCalculatorInfo) then
    begin
      o := TTMSFNCRouteCalculatorInfo(ARequest.DataPointer);

      if ARequest.Items.Count > 0 then
      begin
        if ARequest.ID = 'Start' then
        begin
          o.FStartFound := True;
          o.FStartCoordinate := ARequest.Items[0].Coordinate.ToRec;
          o.FStartAddress := ARequest.Items[0].Address;
        end
        else if ARequest.ID = 'End' then
        begin
          o.FEndFound := True;
          o.FEndCoordinate := ARequest.Items[0].Coordinate.ToRec;
          o.FEndAddress := ARequest.Items[0].Address;
        end
        else if ARequest.ID = 'GPXStart' then
        begin
          o.FStartFound := False;
          o.FStartGeocoded := True;
          o.FStartAddress := ARequest.Items[0].Address;
        end
        else if ARequest.ID = 'GPXEnd' then
        begin
          o.FEndFound := False;
          o.FEndGeocoded := True;
          o.FEndAddress := ARequest.Items[0].Address;
        end;
      end;

      if o.FStartGeocoded and o.FEndGeocoded then
      begin
        if (FGeocoding.GetRequestCount(True) = 0) then
        begin
          //TODO : DoLoadGPXRouteComplete(s.Segments.Route);
          //raise Exception.Create('TODO');
        end;
      end;

      if o.FStartFound and o.FEndFound then
      begin
        if (o.FMode = cimAddDirections) then
          GetDirections(o.FStartCoordinate, o.FEndCoordinate, nil, o.FWayPoints, o.FID, o)
        else if o.FMode = cimUpdateDirections then
        begin
          for I := 0 to Length(o.FUpdateSegments) - 1 do
            UpdateRouteSegment(o.FUpdateSegments[I], o.FStartCoordinate, o.FEndCoordinate, TTMSFNCRouteCalculatorRouteCallBackWrapper(o.FCallBack).FCallback, o.FID, nil);

          o.Free;
        end;
      end;
    end;
  end;

  if Assigned(o) then
  begin
    if o.FMode <> cimDefault then
    begin
      if Assigned(OnGetRouteGeocoding) then
        OnGetRouteGeocoding(Self, ARequest, ARequestResult);
    end
    else
      o.Free;
  end
  else
  begin
    if Assigned(OnGetGeocoding) then
      OnGetGeocoding(Self, ARequest, ARequestResult);
  end;
end;

procedure TTMSFNCCustomRouteCalculator.DoGetLocation(Sender: TObject;
  const ARequest: TTMSFNCLocationRequest;
  const ARequestResult: TTMSFNCCloudBaseRequestResult);
begin
  if not ARequestResult.Success then
    Exit;

  if Assigned(OnGetLocation) then
    OnGetLocation(Self, ARequest.Coordinate.ToRec);
end;

procedure TTMSFNCCustomRouteCalculator.DoLoadGPXRouteComplete(
  ARoute: TTMSFNCRouteCalculatorRoute);
begin
  if Assigned(OnLoadGPXRouteComplete) then
    OnLoadGPXRouteComplete(Self, ARoute);
end;

{$IFDEF SENSORSUPPORT}
procedure TTMSFNCCustomRouteCalculator.DoLocationChanged(Sender: TObject;
  const OldLocation, NewLocation: TLocationCoord2D);
var
  cr: TTMSFNCMapsCoordinateRec;
begin
  if Assigned(OnGetLocation) then
  begin
    FLocationSensor.Active := False;
    cr.Latitude := NewLocation.Latitude;
    cr.Longitude := NewLocation.Longitude;
    OnGetLocation(Self, cr);
  end;
end;
{$ENDIF}

procedure TTMSFNCCustomRouteCalculator.SaveToGPXFile(
  ARoute: TTMSFNCRouteCalculatorRoute; AFileName: string);
begin
  SaveToGPXFile(ARoute, AFileName, DefaultGPXMetaData);
end;

procedure TTMSFNCCustomRouteCalculator.SaveRoutesToFile(AFileName: string);
var
  ms: TMemoryStream;
begin
  ms := TMemoryStream.Create;
  try
    SaveRoutesToStream(ms);
    ms.Position := 0;
    ms.SaveToFile(AFileName);
  finally
    ms.Free;
  end;
end;

procedure TTMSFNCCustomRouteCalculator.SaveRoutesToStream(AStream: TStream);
begin
  TTMSFNCPersistence.SaveSettingsToStream(Routes, AStream);
end;

function TTMSFNCCustomRouteCalculator.SaveRoutesToText: string;
var
  s: TStringStream;
begin
  s := TStringStream.Create('');
  try
    SaveRoutesToStream(s);
    s.Position := 0;
    Result := s.DataString;
  finally
    s.Free;
  end;
end;

procedure TTMSFNCCustomRouteCalculator.SaveToGPXFile(
  ARoute: TTMSFNCRouteCalculatorRoute; AFileName: string;
  AMetaData: TTMSFNCMapsGPXMetaData);
var
  sl: TStringList;
begin
  sl := TStringList.Create;
  try
    sl.Text := InternalSaveGPX(ARoute, AMetaData);
    sl.SaveToFile(AFileName);
  finally
    sl.Free;
  end;
end;

procedure TTMSFNCCustomRouteCalculator.SaveToGPXStream(
  ARoute: TTMSFNCRouteCalculatorRoute; AStream: TStream);
begin
  SaveToGPXStream(ARoute, AStream, DefaultGPXMetaData);
end;

procedure TTMSFNCCustomRouteCalculator.SaveToGPXStream(
  ARoute: TTMSFNCRouteCalculatorRoute; AStream: TStream;
  AMetaData: TTMSFNCMapsGPXMetaData);
var
  ss: TStringStream;
begin
  ss := TStringStream.Create(InternalSaveGPX(ARoute, AMetaData));
  try
    AStream.CopyFrom(ss, ss.Size);
  finally
    ss.Free;
  end;
end;

function TTMSFNCCustomRouteCalculator.SaveToGPXText(
  ARoute: TTMSFNCRouteCalculatorRoute): string;
begin
  Result := InternalSaveGPX(ARoute, DefaultGPXMetaData);
end;

function TTMSFNCCustomRouteCalculator.SaveToGPXText(
  ARoute: TTMSFNCRouteCalculatorRoute;
  AMetaData: TTMSFNCMapsGPXMetaData): string;
begin
  Result := InternalSaveGPX(ARoute, AMetaData);
end;

procedure TTMSFNCCustomRouteCalculator.SetAPIKey(const Value: string);
begin
  if FAPIKey <> Value then
    FAPIKey := Value;
end;

procedure TTMSFNCCustomRouteCalculator.SetRoutes(
  const Value: TTMSFNCRouteCalculatorRoutes);
begin
  FRoutes.Assign(Value);
end;

procedure TTMSFNCCustomRouteCalculator.SetService(const Value: TTMSFNCRouteCalculatorService);
begin
  if FService <> Value then
  begin
    FLocation.Service := lsGoogle;

    FService := Value;
    case FService of
      csAzure:
      begin
        FGeocoding.Service := gsAzure;
        FDirections.Service := dsAzure;
      end;
      csBing:
      begin
        FGeocoding.Service := gsBing;
        FDirections.Service := dsBing;
      end;
      csGoogle:
      begin
        FGeocoding.Service := gsGoogle;
        FDirections.Service := dsGoogle;
      end;
      csHere:
      begin
        FGeocoding.Service := gsHere;
        FDirections.Service := dsHere;
      end;
      csMapBox:
      begin
        FGeocoding.Service := gsMapBox;
        FDirections.Service := dsMapBox;
      end;
      csOpenRouteService:
      begin
        FGeocoding.Service := gsOpenRouteService;
        FDirections.Service := dsOpenRouteService;
      end;
      csGeoApify:
      begin
        FGeocoding.Service := gsGeoApify;
        FDirections.Service := dsGeoApify;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomRouteCalculator.UpdateRouteSegment(
  ASegment: TTMSFNCRouteCalculatorSegment; AStartCoordinate,
  AEndCoordinate: TTMSFNCMapsCoordinateRec;
  ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = '';
  ADataPointer: Pointer = nil);
var
  o: TTMSFNCRouteCalculatorInfo;
  ps, ns: TTMSFNCRouteCalculatorSegment;
  wp: TTMSFNCMapsCoordinateRecArray;
begin
  if not Assigned(ASegment) then
    Exit;

  o := TTMSFNCRouteCalculatorInfo.Create;
  o.FID := AID;
  ps := ASegment.PreviousSegment;
  ns := ASegment.NextSegment;
  o.FUpdateRoute := ASegment.Route;
  o.FMode := cimUpdateDirections;

  if Assigned(ps) and Assigned(ns) then
  begin
    o.FStartCoordinate := ps.StartCoordinate.ToRec;
    o.FEndCoordinate := ns.EndCoordinate.ToRec;
    SetLength(wp, Length(wp) + 1);
    wp[Length(wp) - 1] := AStartCoordinate;
    SetLength(wp, Length(wp) + 1);
    wp[Length(wp) - 1] := AEndCoordinate;
    SetLength(o.FUpdateSegments, 3);
    o.FUpdateSegments[0] := ps;
    o.FUpdateSegments[1] := ASegment;
    o.FUpdateSegments[2] := ns;
  end
  else if Assigned(ps) then
  begin
    o.FStartCoordinate := ps.StartCoordinate.ToRec;
    o.FEndCoordinate := AEndCoordinate;
    SetLength(wp, Length(wp) + 1);
    wp[Length(wp) - 1] := AStartCoordinate;
    SetLength(o.FUpdateSegments, 2);
    o.FUpdateSegments[0] := ps;
    o.FUpdateSegments[1] := ASegment;
  end
  else if Assigned(ns) then
  begin
    o.FStartCoordinate := AStartCoordinate;
    o.FEndCoordinate := ns.EndCoordinate.ToRec;
    SetLength(wp, Length(wp) + 1);
    wp[Length(wp) - 1] := AEndCoordinate;
    SetLength(o.FUpdateSegments, 2);
    o.FUpdateSegments[0] := ASegment;
    o.FUpdateSegments[1] := ns;
  end
  else
  begin
    o.FStartCoordinate := AStartCoordinate;
    o.FEndCoordinate := AEndCoordinate;
    SetLength(o.FUpdateSegments, 1);
    o.FUpdateSegments[0] := ASegment;
  end;

  if Assigned(ACallback) then
    o.FCallBack := TTMSFNCRouteCalculatorRouteCallBackWrapper.Create(ACallback)
  else
    o.FCallBack := nil;

  GetDirections(o.FStartCoordinate, o.FEndCoordinate, nil, wp, AID, o);
end;

procedure TTMSFNCCustomRouteCalculator.UpdateRouteSegmentEndAddress(
  ASegment: TTMSFNCRouteCalculatorSegment; AEndAddress: string;
  ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback; AID: string;
  ADataPointer: Pointer);
begin
  UpdateRouteSegment(ASegment, ASegment.StartAddress, AEndAddress, ACallBack, AID, ADataPointer);
end;

procedure TTMSFNCCustomRouteCalculator.UpdateRouteSegmentEndCoordinate(
  ASegment: TTMSFNCRouteCalculatorSegment;
  AEndCoordinate: TTMSFNCMapsCoordinateRec;
  ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback; AID: string;
  ADataPointer: Pointer);
begin
  UpdateRouteSegment(ASegment, ASegment.StartCoordinate.ToRec, AEndCoordinate, ACallBack, AID, ADataPointer);
end;

procedure TTMSFNCCustomRouteCalculator.UpdateRouteSegmentStartAddress(
  ASegment: TTMSFNCRouteCalculatorSegment; AStartAddress: string;
  ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback; AID: string;
  ADataPointer: Pointer);
begin
  UpdateRouteSegment(ASegment, AStartAddress, ASegment.EndAddress, ACallBack, AID, ADataPointer);
end;

procedure TTMSFNCCustomRouteCalculator.UpdateRouteSegmentStartCoordinate(
  ASegment: TTMSFNCRouteCalculatorSegment;
  AStartCoordinate: TTMSFNCMapsCoordinateRec;
  ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback; AID: string;
  ADataPointer: Pointer);
begin
  UpdateRouteSegment(ASegment, AStartCoordinate, ASegment.EndCoordinate.ToRec, ACallBack, AID, ADataPointer);
end;

procedure TTMSFNCCustomRouteCalculator.UpdateRouteSegment(
  ASegment: TTMSFNCRouteCalculatorSegment; AStartAddress, AEndAddress: string;
  ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil; AID: string = '';
  ADataPointer: Pointer = nil);
var
  o: TTMSFNCRouteCalculatorInfo;
begin
  if not Assigned(ASegment) then
    Exit;

  o := TTMSFNCRouteCalculatorInfo.Create;
  o.FID := AID;

  o.FStartAddress := AStartAddress;
  o.FEndAddress := AEndAddress;
  o.FUpdateRoute := ASegment.Route;
  o.FMode := cimUpdateDirections;

  SetLength(o.FUpdateSegments, 1);
  o.FUpdateSegments[0] := ASegment;

  if Assigned(ACallback) then
    o.FCallBack := TTMSFNCRouteCalculatorRouteCallBackWrapper.Create(ACallback)
  else
    o.FCallBack := nil;

  GetGeocoding(o.FEndAddress, nil, 'End', o);
  GetGeocoding(o.FStartAddress, nil, 'Start', o);
end;

procedure TTMSFNCCustomRouteCalculator.DeleteRoute(
  ARoute: TTMSFNCRouteCalculatorRoute);
begin
  if not Assigned(ARoute) then
    Exit;

  ARoute.Free;
end;

procedure TTMSFNCCustomRouteCalculator.DeleteRouteSegment(
  ASegment: TTMSFNCRouteCalculatorSegment; ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback = nil);
var
  ps, ns: TTMSFNCRouteCalculatorSegment;
  et, st: TTMSFNCMapsCoordinateRec;
  r: TTMSFNCRouteCalculatorRoute;
  o: TTMSFNCRouteCalculatorInfo;
begin
  if not Assigned(ASegment) then
    Exit;

  if Assigned(ASegment.Route) and (ASegment.Route.Segments.Count = 1) then
  begin
    r := ASegment.Route;
    ASegment.Free;
    if Assigned(ACallback) then
      ACallback(r);
    o := TTMSFNCRouteCalculatorInfo.Create;
    DoCalculateRoute(r, o);
    o.Free;
    r.Free;
    Exit;
  end;

  ps := ASegment.PreviousSegment;
  ns := ASegment.NextSegment;

  et := ASegment.EndCoordinate.ToRec;
  st := ASegment.StartCoordinate.ToRec;

  ASegment.Free;

  if Assigned(ps) then
    UpdateRouteSegmentEndCoordinate(ps, st, ACallback)
  else if Assigned(ns) then
    UpdateRouteSegmentStartCoordinate(ns, et, ACallback)
end;

destructor TTMSFNCCustomRouteCalculator.Destroy;
begin
  if Assigned(FHistoryManager) then
    FHistoryManager.Free;
  FGeocoding.Free;
  FGeocoding := nil;
  FDirections.Free;
  FDirections := nil;
  FLocation.Free;
  FLocation := nil;
  FRoutes.Free;
  FOptions.Free;
  {$IFDEF SENSORSUPPORT}
  FLocationSensor.Free;
  FLocationSensor := nil;
  {$ENDIF}
  inherited;
end;

function TTMSFNCCustomRouteCalculator.DirectionsReady: Boolean;
begin
  if Assigned(FDirections) then
    FDirections.APIKey := APIKey;
  Result := Assigned(FDirections) and (FDirections.APIKey <> '');
end;

{ TTMSFNCRouteCalculatorSegment }

procedure TTMSFNCRouteCalculatorSegment.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCRouteCalculatorSegment then
  begin
    FCoordinates.Assign(TTMSFNCRouteCalculatorSegment(Source).Coordinates);
    FDistance := TTMSFNCRouteCalculatorSegment(Source).Distance;
    FDuration := TTMSFNCRouteCalculatorSegment(Source).Duration;
    FID := (Source as TTMSFNCRouteCalculatorSegment).ID;
    FStartAddress := (Source as TTMSFNCRouteCalculatorSegment).StartAddress;
    FEndAddress := (Source as TTMSFNCRouteCalculatorSegment).EndAddress;
    FStartCoordinate.Assign((Source as TTMSFNCRouteCalculatorSegment).StartCoordinate);
    FEndCoordinate.Assign((Source as TTMSFNCRouteCalculatorSegment).EndCoordinate);
  end;
end;

procedure TTMSFNCRouteCalculatorSegment.SetAlternativeSegments(
  const Value: TTMSFNCRouteCalculatorAlternativeSegments);
begin
  FAlternativeSegments.Assign(Value);
end;

procedure TTMSFNCRouteCalculatorSegment.SetCoordinates(
  const Value: TTMSFNCMapsCoordinates);
begin
  FCoordinates.Assign(Value);
end;

constructor TTMSFNCRouteCalculatorSegment.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) and (Collection is TTMSFNCRouteCalculatorSegments) then
    FOwner := (Collection as TTMSFNCRouteCalculatorSegments);

  FID := '';
  FStartAddress := '';
  FEndAddress := '';
  FStartCoordinate := TTMSFNCMapsCoordinate.Create;
  FEndCoordinate := TTMSFNCMapsCoordinate.Create;

  FCoordinates := TTMSFNCMapsCoordinates.Create(Self);
  FDistance := 0;
  FDuration := 0;

  FAlternativeSegments := TTMSFNCRouteCalculatorAlternativeSegments.Create(Self);
end;

destructor TTMSFNCRouteCalculatorSegment.Destroy;
begin
  FAlternativeSegments.Free;
  FCoordinates.Free;
  FStartCoordinate.Free;
  FEndCoordinate.Free;
  inherited;
end;

function TTMSFNCRouteCalculatorSegment.GetPolyline: TTMSFNCMapsCoordinateRecArray;
var
  I: Integer;
  r: TTMSFNCMapsCoordinateRec;
begin
  SetLength(Result, 0);
  SetLength(Result, Coordinates.Count);
  for I := 0 to Coordinates.Count - 1 do
  begin
    r := EmptyCoordinate;
    r.Latitude := Coordinates[I].Latitude;
    r.Longitude := Coordinates[I].Longitude;
    Result[I] := r;
  end;
end;

function TTMSFNCRouteCalculatorSegment.GetRoute: TTMSFNCRouteCalculatorRoute;
begin
  Result := nil;
  if Assigned(Segments) then
    Result := Segments.Route;
end;

function TTMSFNCRouteCalculatorSegment.GetSegments: TTMSFNCRouteCalculatorSegments;
begin
  Result := FOwner;
end;

function TTMSFNCRouteCalculatorSegment.GetNextSegment: TTMSFNCRouteCalculatorSegment;
var
  i: Integer;
begin
  Result := nil;

  if not Assigned(FOwner) then
    Exit;

  i := Self.Index;
  if (i + 1) < FOwner.Count then
    Result := FOwner[i + 1];
end;

function TTMSFNCRouteCalculatorSegment.GetPreviousSegment: TTMSFNCRouteCalculatorSegment;
var
  i: Integer;
begin
  Result := nil;

  if not Assigned(FOwner) then
    Exit;

  i := Self.Index;
  if (i > 0) and (i < FOwner.Count) then
    Result := FOwner[i - 1];
end;

{ TTMSFNCRouteCalculatorSegments }

function TTMSFNCRouteCalculatorSegments.Add: TTMSFNCRouteCalculatorSegment;
begin
  Result := TTMSFNCRouteCalculatorSegment(inherited Add);
end;

constructor TTMSFNCRouteCalculatorSegments.Create(AOwner: TTMSFNCRouteCalculatorRoute);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCRouteCalculatorSegments.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCRouteCalculatorSegment;
end;

function TTMSFNCRouteCalculatorSegments.GetItem(
  Index: Integer): TTMSFNCRouteCalculatorSegment;
begin
  Result := TTMSFNCRouteCalculatorSegment(inherited Items[Index]);
end;

function TTMSFNCRouteCalculatorSegments.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCRouteCalculatorSegments.GetRoute: TTMSFNCRouteCalculatorRoute;
begin
  Result := FOwner;
end;

function TTMSFNCRouteCalculatorSegments.Insert(
  Index: Integer): TTMSFNCRouteCalculatorSegment;
begin
  Result := TTMSFNCRouteCalculatorSegment(inherited Insert(Index));
end;

procedure TTMSFNCRouteCalculatorSegments.SetItem(Index: Integer;
  const Value: TTMSFNCRouteCalculatorSegment);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCRouteCalculatorAlternativeSegment }

procedure TTMSFNCRouteCalculatorAlternativeSegment.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCRouteCalculatorAlternativeSegment then
  begin
    FCoordinates.Assign(TTMSFNCRouteCalculatorAlternativeSegment(Source).Coordinates);
    FDistance := TTMSFNCRouteCalculatorAlternativeSegment(Source).Distance;
    FDuration := TTMSFNCRouteCalculatorAlternativeSegment(Source).Duration;
    FStartCoordinate.Assign((Source as TTMSFNCRouteCalculatorAlternativeSegment).StartCoordinate);
    FEndCoordinate.Assign((Source as TTMSFNCRouteCalculatorAlternativeSegment).EndCoordinate);
  end;
end;

procedure TTMSFNCRouteCalculatorAlternativeSegment.SetCoordinates(
  const Value: TTMSFNCMapsCoordinates);
begin
  FCoordinates.Assign(Value);
end;

constructor TTMSFNCRouteCalculatorAlternativeSegment.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) and (Collection is TTMSFNCRouteCalculatorAlternativeSegments) then
    FOwner := (Collection as TTMSFNCRouteCalculatorAlternativeSegments);

  FStartCoordinate := TTMSFNCMapsCoordinate.Create;
  FEndCoordinate := TTMSFNCMapsCoordinate.Create;

  FCoordinates := TTMSFNCMapsCoordinates.Create(Self);
  FDistance := 0;
  FDuration := 0;
end;

destructor TTMSFNCRouteCalculatorAlternativeSegment.Destroy;
begin
  FCoordinates.Free;
  FStartCoordinate.Free;
  FEndCoordinate.Free;
  inherited;
end;

function TTMSFNCRouteCalculatorAlternativeSegment.GetPolyline: TTMSFNCMapsCoordinateRecArray;
var
  I: Integer;
  r: TTMSFNCMapsCoordinateRec;
begin
  SetLength(Result, 0);
  SetLength(Result, Coordinates.Count);
  for I := 0 to Coordinates.Count - 1 do
  begin
    r := EmptyCoordinate;
    r.Latitude := Coordinates[I].Latitude;
    r.Longitude := Coordinates[I].Longitude;
    Result[I] := r;
  end;
end;

function TTMSFNCRouteCalculatorAlternativeSegment.GetSegment: TTMSFNCRouteCalculatorSegment;
begin
  Result := nil;
  if Assigned(FOwner) then
    Result := FOwner.Segment;
end;

{ TTMSFNCRouteCalculatorAlternativeSegments }

function TTMSFNCRouteCalculatorAlternativeSegments.Add: TTMSFNCRouteCalculatorAlternativeSegment;
begin
  Result := TTMSFNCRouteCalculatorAlternativeSegment(inherited Add);
end;

constructor TTMSFNCRouteCalculatorAlternativeSegments.Create(AOwner: TTMSFNCRouteCalculatorSegment);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCRouteCalculatorAlternativeSegments.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCRouteCalculatorAlternativeSegment;
end;

function TTMSFNCRouteCalculatorAlternativeSegments.GetItem(
  Index: Integer): TTMSFNCRouteCalculatorAlternativeSegment;
begin
  Result := TTMSFNCRouteCalculatorAlternativeSegment(inherited Items[Index]);
end;

function TTMSFNCRouteCalculatorAlternativeSegments.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCRouteCalculatorAlternativeSegments.GetSegment: TTMSFNCRouteCalculatorSegment;
begin
  Result := FOwner;
end;

function TTMSFNCRouteCalculatorAlternativeSegments.Insert(
  Index: Integer): TTMSFNCRouteCalculatorAlternativeSegment;
begin
  Result := TTMSFNCRouteCalculatorAlternativeSegment(inherited Insert(Index));
end;

procedure TTMSFNCRouteCalculatorAlternativeSegments.SetItem(Index: Integer;
  const Value: TTMSFNCRouteCalculatorAlternativeSegment);
begin
  inherited Items[Index] := Value;
end;


{ TTMSFNCRouteCalculatorRoute }

procedure TTMSFNCRouteCalculatorRoute.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCRouteCalculatorRoute then
  begin
    FSegments.Assign((Source as TTMSFNCRouteCalculatorRoute).Segments);
    FID := (Source as TTMSFNCRouteCalculatorRoute).ID;
    FRouteName := (Source as TTMSFNCRouteCalculatorRoute).RouteName;
    FActive := (Source as TTMSFNCRouteCalculatorRoute).Active;
    FSteps.Assign((Source as TTMSFNCRouteCalculatorRoute).Steps);
  end;
end;

procedure TTMSFNCRouteCalculatorRoute.SetSteps(const Value: TTMSFNCDirectionsSteps);
begin
  FSteps.Assign(Value);
end;

constructor TTMSFNCRouteCalculatorRoute.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) and (Collection is TTMSFNCRouteCalculatorRoutes) then
    FOwner := (Collection as TTMSFNCRouteCalculatorRoutes).RouteCalculator;

  FSegments := TTMSFNCRouteCalculatorSegments.Create(Self);
  FID := '';
  FStartAddress := '';
  FEndAddress := '';
  FRouteName := '';
  FActive := True;
  FStartCoordinate := TTMSFNCMapsCoordinate.Create;
  FEndCoordinate := TTMSFNCMapsCoordinate.Create;
  FSteps := TTMSFNCDirectionsSteps.Create(Self);
end;

destructor TTMSFNCRouteCalculatorRoute.Destroy;
begin
  FSteps.Free;
  FStartCoordinate.Free;
  FEndCoordinate.Free;
  FSegments.Free;
  inherited;
end;

function TTMSFNCRouteCalculatorRoute.FirstSegment: TTMSFNCRouteCalculatorSegment;
begin
  if Segments.Count > 0 then
    Result := Segments[0]
  else
    Result := nil;
end;

function TTMSFNCRouteCalculatorRoute.GetDistance: Double;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to Segments.Count - 1 do
    Result := Result + Segments[I].Distance;
end;

function TTMSFNCRouteCalculatorRoute.GetDuration: Double;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to Segments.Count - 1 do
    Result := Result + Segments[I].Duration;
end;

function TTMSFNCRouteCalculatorRoute.GetPolyline: TTMSFNCMapsCoordinateRecArray;
var
  I, J, K: Integer;
  r: TTMSFNCMapsCoordinateRec;
begin
  SetLength(Result, 0);
  K := 0;
  for I := 0 to Segments.Count - 1 do
  begin
    for J := 0 to Segments[I].Coordinates.Count - 1 do
    begin
      SetLength(Result, K + 1);
      r := EmptyCoordinate;
      r.Latitude := Segments[I].Coordinates[J].Latitude;
      r.Longitude := Segments[I].Coordinates[J].Longitude;
      Result[K] := r;
      K := K + 1;
    end;
  end;
end;

function TTMSFNCRouteCalculatorRoute.GetRouteCalculator: TTMSFNCCustomRouteCalculator;
begin
  Result := FOwner;
end;

function TTMSFNCRouteCalculatorRoute.GetWayPoints: TTMSFNCMapsCoordinateRecArray;
var
  I, J: Integer;
  r: TTMSFNCMapsCoordinateRec;
begin
  Result := nil;
  if Segments.Count = 0 then
    Exit;
    
  J := Segments.Count;
  SetLength(Result, J + 1);
  for I := 0 to Segments.Count - 1 do
  begin
    r := EmptyCoordinate;
    r.Latitude := Segments[I].StartCoordinate.Latitude;
    r.Longitude := Segments[I].StartCoordinate.Longitude;
    r.Description := Segments[I].StartAddress;
    Result[I] := r;
  end;

  r := EmptyCoordinate;
  r.Latitude := Segments[J - 1].EndCoordinate.Latitude;
  r.Longitude := Segments[J - 1].EndCoordinate.Longitude;
  r.Description := Segments[J - 1].EndAddress;
  Result[J] := r;
end;

function TTMSFNCRouteCalculatorRoute.LastSegment: TTMSFNCRouteCalculatorSegment;
begin
  if Segments.Count > 0 then
    Result := Segments[Segments.Count - 1]
  else
    Result := nil;
end;

{ TTMSFNCRouteCalculatorRoutes }

function TTMSFNCRouteCalculatorRoutes.Add: TTMSFNCRouteCalculatorRoute;
begin
  Result := TTMSFNCRouteCalculatorRoute(inherited Add);
end;

constructor TTMSFNCRouteCalculatorRoutes.Create(AOwner: TTMSFNCCustomRouteCalculator);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCRouteCalculatorRoutes.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCRouteCalculatorRoute;
end;

function TTMSFNCRouteCalculatorRoutes.GetItem(
  Index: Integer): TTMSFNCRouteCalculatorRoute;
begin
  Result := TTMSFNCRouteCalculatorRoute(inherited Items[Index]);
end;

function TTMSFNCRouteCalculatorRoutes.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCRouteCalculatorRoutes.GetRouteCalculator: TTMSFNCCustomRouteCalculator;
begin
  Result := FOwner;
end;

function TTMSFNCRouteCalculatorRoutes.Insert(
  Index: Integer): TTMSFNCRouteCalculatorRoute;
begin
  Result := TTMSFNCRouteCalculatorRoute(inherited Insert(Index));
end;

procedure TTMSFNCRouteCalculatorRoutes.SetItem(Index: Integer;
  const Value: TTMSFNCRouteCalculatorRoute);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCRouteCalculatorDirectionsItems }

function TTMSFNCRouteCalculatorDirectionsItems.Add: TTMSFNCDirectionsItem;
begin
  Result := TTMSFNCDirectionsItem(inherited Add);
end;

constructor TTMSFNCRouteCalculatorDirectionsItems.Create(AOwner: TTMSFNCRouteCalculatorSegment);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCRouteCalculatorDirectionsItems.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCDirectionsItem;
end;

function TTMSFNCRouteCalculatorDirectionsItems.GetItem(
  Index: Integer): TTMSFNCDirectionsItem;
begin
  Result := TTMSFNCDirectionsItem(inherited Items[Index]);
end;

function TTMSFNCRouteCalculatorDirectionsItems.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCRouteCalculatorDirectionsItems.Insert(
  Index: Integer): TTMSFNCDirectionsItem;
begin
  Result := TTMSFNCDirectionsItem(inherited Insert(Index));
end;

procedure TTMSFNCRouteCalculatorDirectionsItems.SetItem(Index: Integer;
  const Value: TTMSFNCDirectionsItem);
begin
  inherited Items[Index] := Value;
end;


{ TTMSFNCRouteCalculator }

procedure TTMSFNCRouteCalculator.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCRouteCalculator, TTMSFNCRouteCalculatorRoute, TTMSFNCRouteCalculatorSegment,
    TTMSFNCMapsCoordinateItem, TTMSFNCMapsCoordinate, TTMSFNCDirectionsStep, TTMSFNCDirectionsItem, TTMSFNCRouteCalculatorAlternativeSegment]);
end;

{ TTMSFNCRouteCalculatorRouteCallBackWrapper }

constructor TTMSFNCRouteCalculatorRouteCallBackWrapper.Create(
  ACallback: TTMSFNCRouteCalculatorCalculateRouteCallback);
begin
  FCallback := ACallback;
end;

{ TTMSFNCRouteCalculatorOptions }

procedure TTMSFNCRouteCalculatorOptions.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCRouteCalculatorOptions then
  begin
    FAvoidTolls := (Source as TTMSFNCRouteCalculatorOptions).AvoidTolls;
    FIncludeAlternativeRoutes := (Source as TTMSFNCRouteCalculatorOptions).IncludeAlternativeRoutes;
    FTravelMode := (Source as TTMSFNCRouteCalculatorOptions).TravelMode;
    FPolyline.Assign((Source as TTMSFNCRouteCalculatorOptions).Polyline);
    FHistoryEnabled := (Source as TTMSFNCRouteCalculatorOptions).HistoryEnabled;
    FLocale := (Source as TTMSFNCRouteCalculatorOptions).Locale;
    FLocaleMode := (Source as TTMSFNCRouteCalculatorOptions).LocaleMode;
  end;
end;

constructor TTMSFNCRouteCalculatorOptions.Create;
begin
  inherited;
  FHistoryEnabled := False;
  FAvoidTolls := False;
  FIncludeAlternativeRoutes := False;
  FTravelMode := tmDriving;
  FPolyline := TTMSFNCRouteCalculatorPolylineOptions.Create;
  FLocale := '';
  FLocaleMode := mlmDefault;
end;

destructor TTMSFNCRouteCalculatorOptions.Destroy;
begin
  FPolyline.Free;
  inherited;
end;

procedure TTMSFNCRouteCalculatorOptions.SetPolyline(
  const Value: TTMSFNCRouteCalculatorPolylineOptions);
begin
  FPolyline.Assign(Value);
end;

{ TTMSFNCRouteCalculatorPolylineOptions }

procedure TTMSFNCRouteCalculatorPolylineOptions.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCRouteCalculatorPolylineOptions then
  begin
    FStartMarker := (Source as TTMSFNCRouteCalculatorPolylineOptions).FStartMarker;
    FEndMarker := (Source as TTMSFNCRouteCalculatorPolylineOptions).FEndMarker;
    FWayPointMarker := (Source as TTMSFNCRouteCalculatorPolylineOptions).FWayPointMarker;
    FAddWayPointMarker := (Source as TTMSFNCRouteCalculatorPolylineOptions).FAddWayPointMarker;
    FSelectedWayPointMarker := (Source as TTMSFNCRouteCalculatorPolylineOptions).FSelectedWayPointMarker;
    FStrokeColor := (Source as TTMSFNCRouteCalculatorPolylineOptions).FStrokeColor;
    FStrokeWidth := (Source as TTMSFNCRouteCalculatorPolylineOptions).FStrokeWidth;
    FStrokeOpacity := (Source as TTMSFNCRouteCalculatorPolylineOptions).FStrokeOpacity;
    FSelectedStrokeColor := (Source as TTMSFNCRouteCalculatorPolylineOptions).FSelectedStrokeColor;
  end;
end;

constructor TTMSFNCRouteCalculatorPolylineOptions.Create;
begin
  inherited;
  StartMarker := '';
  EndMarker := '';
  WayPointMarker := '';
  AddWayPointMarker := '';
  SelectedWayPointMarker := '';
  StrokeColor := gcBlue;
  StrokeWidth := 5;
  StrokeOpacity := 1.0;
  SelectedStrokeColor := gcRed;
end;

destructor TTMSFNCRouteCalculatorPolylineOptions.Destroy;
begin
  inherited;
end;

function TTMSFNCRouteCalculatorPolylineOptions.IsStrokeOpacityStored: Boolean;
begin
  Result := StrokeOpacity <> 1;
end;

procedure TTMSFNCRouteCalculatorPolylineOptions.SetStrokeOpacity(
  const Value: Single);
begin
  FStrokeOpacity := Value;
end;

{ TTMSFNCRouteCalculatorInfo }

constructor TTMSFNCRouteCalculatorInfo.Create;
begin
  FStartCoordinate := EmptyCoordinate;
  FEndCoordinate := EmptyCoordinate;
  FStartFound := False;
  FStartGeocoded := False;
  FEndFound := False;
  FEndGeocoded := False;
  FMode := cimDefault;
  SetLength(FUpdateSegments, 0);
end;

destructor TTMSFNCRouteCalculatorInfo.Destroy;
begin
  FCallBack := nil;
  inherited;
end;

end.
