{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGeocoding.Google;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterGoogleGeocodingService;
procedure UnRegisterGoogleGeocodingService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCGeocoding,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

const
  PRECISION_ROOFTOP = 'ROOFTOP';
  PRECISION_RANGE_INTERPOLATED = 'RANGE_INTERPOLATED';
  PRECISION_GEOMETRIC_CENTER = 'GEOMETRIC_CENTER';
  PRECISION_APPROXIMATE = 'APPROXIMATE';

type
  TTMSFNCGeocodingGoogleService = class;

  TTMSFNCGeocodingGoogleGeocodingFactoryService = class(TTMSFNCGeocodingFactoryService, ITMSFNCGeocodingServiceGoogle);

  TTMSFNCGeocodingGoogleService = class(TTMSFNCGeocodingGoogleGeocodingFactoryService)
  protected
    function DoCreateGeocoding: ITMSFNCCustomGeocoding; override;
  end;

type
  TTMSFNCGeocodingGoogleGeocoding = class;

  TTMSFNCGeocodingGoogleGeocoding = class(TTMSFNCCustomGeocodingInterfacedObject, ITMSFNCCustomGeocoding)
  protected
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetReverseHost: string;
    function GetPath(AAddress: string): string;
    function GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
    function GetQuery(AAddress: string; ALocale: string): string; overload;
    function GetQuery(ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string; overload;
    function GetPostData: string;
    procedure Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyGeocoding;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  GeocodingService: ITMSFNCGeocodingServiceGoogle;

procedure RegisterGoogleGeocodingService;
begin
  if not TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceGoogle, IInterface(GeocodingService)) then
  begin
    GeocodingService := TTMSFNCGeocodingGoogleService.Create;
    TTMSFNCGeocodingPlatformServices.Current.AddPlatformService(ITMSFNCGeocodingServiceGoogle, GeocodingService);
  end;
end;

procedure UnregisterGoogleGeocodingService;
begin
  TTMSFNCGeocodingPlatformServices.Current.RemovePlatformService(ITMSFNCGeocodingServiceGoogle);
end;

{ TTMSFNCGeocodingGoogleService }

function TTMSFNCGeocodingGoogleService.DoCreateGeocoding: ITMSFNCCustomGeocoding;
begin
  Result := TTMSFNCGeocodingGoogleGeocoding.Create;
end;

procedure TTMSFNCGeocodingGoogleGeocoding.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCGeocodingGoogleGeocoding.Create;
begin
  inherited;
end;

destructor TTMSFNCGeocodingGoogleGeocoding.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGeocodingGoogleGeocoding.DestroyGeocoding;
begin
  GeocodingService.DestroyGeocoding(Self);
end;

function TTMSFNCGeocodingGoogleGeocoding.GetHost: string;
begin
  Result := 'https://maps.googleapis.com';
end;

function TTMSFNCGeocodingGoogleGeocoding.GetReverseHost: string;
begin
  Result := GetHost;
end;

function TTMSFNCGeocodingGoogleGeocoding.GetIdentifier: string;
begin
  Result := 'Google';
end;

function TTMSFNCGeocodingGoogleGeocoding.GetPath(AAddress: string): string;
begin
  Result := '/maps/api/geocode/json';
end;

function TTMSFNCGeocodingGoogleGeocoding.GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
begin
  Result := GetPath('');
end;

function TTMSFNCGeocodingGoogleGeocoding.GetPostData: string;
begin
  Result := '';
end;

function TTMSFNCGeocodingGoogleGeocoding.GetQuery(
  ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string;
begin
  Result := 'key=' + GeocodingProperties.GetAPIKey
    + '&latlng=' + FloatToStrDot(ACoordinate.Latitude) + ',' + FloatToStrDot(ACoordinate.Longitude)
    + '&language=' + ALocale
    ;
end;

function TTMSFNCGeocodingGoogleGeocoding.GetQuery(AAddress: string; ALocale: string): string;
begin
  Result := 'key=' + GeocodingProperties.GetAPIKey
    + '&address=' + AAddress
    + '&language=' + ALocale
    ;
end;

function TTMSFNCGeocodingGoogleGeocoding.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCGeocodingGoogleGeocoding.IsValid: Boolean;
begin
  Result := GeocodingProperties.GetAPIKey <> '';
end;

procedure TTMSFNCGeocodingGoogleGeocoding.Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
var
  d: TTMSFNCGeocodingItems;
  di: TTMSFNCGeocodingItem;

  o, jo, joa: TJSONValue;
  jar, jaa: TJSONArray;
  i, j: Integer;
  ob, oc: TJSONObject;
  atype, streetno, streetname, longname, shortname, precision: string;
begin
  if Assigned(GeocodingProperties) then
  begin
    d := ARequest.Items;

    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'status');
            ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(o, 'error_message');

            jar := TTMSFNCUtils.GetJSONValue(o, 'results') as TJSONArray;
            if Assigned(jar) then
            begin
              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
              begin
                jo := TTMSFNCUtils.GetJSONArrayItem(jar, i);

                di := d.Add;
                di.Address := TTMSFNCUtils.GetJSONProp(jo, 'formatted_address');

                jaa := TTMSFNCUtils.GetJSONValue(jo, 'address_components') as TJSONArray;
                if Assigned(jaa) then
                begin
                  for j := 0 to TTMSFNCUtils.GetJSONArraySize(jaa) - 1 do
                  begin
                    joa := TTMSFNCUtils.GetJSONArrayItem(jaa, j);

                    atype := (TTMSFNCUtils.GetJSONValue(joa, 'types') as TJSONArray).ToString;
                    longname := TTMSFNCUtils.GetJSONProp(joa, 'long_name');
                    shortname := TTMSFNCUtils.GetJSONProp(joa, 'short_name');

                    if Pos('street_number', atype) > 0 then
                      streetno := longname;
                    if Pos('route', atype) > 0 then
                      streetname := longname;
                    if Pos('locality', atype) > 0 then
                      di.City := longname;
                    if Pos('sublocality', atype) > 0 then
                      di.District := longname;
                    if di.District = '' then
                    begin
                      if Pos('political', atype) > 0 then
                        di.District := longname;
                    end;
                    if Pos('administrative_area_level_1', atype) > 0 then
                    begin
                      di.Region := longname;
                      di.RegionCode := shortname
                    end;
                    if Pos('administrative_area_level_2', atype) > 0 then
                    begin
                      di.Province := longname;
                      di.ProvinceCode := shortname
                    end;
                    if Pos('country', atype) > 0 then
                    begin
                      di.Country := longname;
                      di.CountryCode := shortname;
                    end;
                    if (Pos('postal_code', atype) > 0) and not (Pos('postal_code_suffix', atype) > 0) then
                    begin
                      di.PostalCode := longname;
                    end;
                    if Pos('intersection', atype) > 0 then
                    begin
                      di.Intersection := shortname;
                    end;

                    di.Street := streetname + ' ' + streetno;
                    di.StreetName := streetname;
                    di.StreetNumber := streetno;
                  end;
                end;

                ob := TTMSFNCUtils.GetJSONValue(jo, 'geometry') as TJSONObject;
                if Assigned(ob) then
                begin
                  precision  := TTMSFNCUtils.GetJSONProp(ob, 'location_type');
                  if precision = PRECISION_ROOFTOP then
                    di.Precision := gpRoofTop
                  else if precision = PRECISION_RANGE_INTERPOLATED then
                    di.Precision := gpRangeInterpolated
                  else if precision = PRECISION_GEOMETRIC_CENTER then
                    di.Precision := gpGeometricCenter
                  else if precision = PRECISION_APPROXIMATE then
                    di.Precision := gpApproximate
                  else
                    di.Precision := gpUnknown;

                  oc := TTMSFNCUtils.GetJSONValue(ob, 'location') as TJSONObject;
                  if Assigned(oc) then
                  begin
                    di.Coordinate.Latitude := TTMSFNCUtils.GetJSONDoubleValue(oc, 'lat');
                    di.Coordinate.Longitude := TTMSFNCUtils.GetJSONDoubleValue(oc, 'lng');
                  end;
                end;
              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

