{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 20201                              }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGeocoding.MapBox;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterMapBoxGeocodingService;
procedure UnRegisterMapBoxGeocodingService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCGeocoding,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCGeocodingMapBoxService = class;

  TTMSFNCGeocodingMapBoxGeocodingFactoryService = class(TTMSFNCGeocodingFactoryService, ITMSFNCGeocodingServiceMapBox);

  TTMSFNCGeocodingMapBoxService = class(TTMSFNCGeocodingMapBoxGeocodingFactoryService)
  protected
    function DoCreateGeocoding: ITMSFNCCustomGeocoding; override;
  end;

type
  TTMSFNCGeocodingMapBoxGeocoding = class;

  TTMSFNCGeocodingMapBoxGeocoding = class(TTMSFNCCustomGeocodingInterfacedObject, ITMSFNCCustomGeocoding)
  protected
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetReverseHost: string;
    function GetPath(AAddress: string): string;
    function GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
    function GetQuery(AAddress: string; ALocale: string): string; overload;
    function GetQuery(ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string; overload;
    function GetPostData: string;
    procedure Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyGeocoding;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  GeocodingService: ITMSFNCGeocodingServiceMapBox;

procedure RegisterMapBoxGeocodingService;
begin
  if not TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceMapBox, IInterface(GeocodingService)) then
  begin
    GeocodingService := TTMSFNCGeocodingMapBoxService.Create;
    TTMSFNCGeocodingPlatformServices.Current.AddPlatformService(ITMSFNCGeocodingServiceMapBox, GeocodingService);
  end;
end;

procedure UnregisterMapBoxGeocodingService;
begin
  TTMSFNCGeocodingPlatformServices.Current.RemovePlatformService(ITMSFNCGeocodingServiceMapBox);
end;

{ TTMSFNCGeocodingMapBoxService }

function TTMSFNCGeocodingMapBoxService.DoCreateGeocoding: ITMSFNCCustomGeocoding;
begin
  Result := TTMSFNCGeocodingMapBoxGeocoding.Create;
end;

procedure TTMSFNCGeocodingMapBoxGeocoding.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCGeocodingMapBoxGeocoding.Create;
begin
  inherited;
end;

destructor TTMSFNCGeocodingMapBoxGeocoding.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGeocodingMapBoxGeocoding.DestroyGeocoding;
begin
  GeocodingService.DestroyGeocoding(Self);
end;

function TTMSFNCGeocodingMapBoxGeocoding.GetHost: string;
begin
  Result := 'https://api.mapbox.com';
end;

function TTMSFNCGeocodingMapBoxGeocoding.GetReverseHost: string;
begin
  Result := GetHost;
end;

function TTMSFNCGeocodingMapBoxGeocoding.GetIdentifier: string;
begin
  Result := 'MapBox';
end;

function TTMSFNCGeocodingMapBoxGeocoding.GetPath(AAddress: string): string;
begin
  Result := '/geocoding/v5/mapbox.places/' + AAddress + '.json';
end;

function TTMSFNCGeocodingMapBoxGeocoding.GetReversePath(
  ACoordinate: TTMSFNCMapsCoordinateRec): string;
begin
  Result := GetPath(FloatToStrDot(ACoordinate.Longitude) + ',' + FloatToStrDot(ACoordinate.Latitude));
end;

function TTMSFNCGeocodingMapBoxGeocoding.GetPostData: string;
begin
  Result := '';
end;

function TTMSFNCGeocodingMapBoxGeocoding.GetQuery(
  ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string;
begin
  Result := GetQuery('', ALocale);
end;

function TTMSFNCGeocodingMapBoxGeocoding.GetQuery(AAddress: string; ALocale: string): string;
begin
  Result := 'access_token=' + GeocodingProperties.GetAPIKey
  + '&language=' + ALocale
  ;
end;

function TTMSFNCGeocodingMapBoxGeocoding.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCGeocodingMapBoxGeocoding.IsValid: Boolean;
begin
  Result := GeocodingProperties.GetAPIKey <> '';
end;

procedure TTMSFNCGeocodingMapBoxGeocoding.Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
var
  d: TTMSFNCGeocodingItems;
  di: TTMSFNCGeocodingItem;

  o, jo, oa: TJSONValue;
  jav, jas, jaa: TJSONArray;
  i, j: Integer;
  position, cid: string;
  sposition: TStringList;
begin
  if Assigned(GeocodingProperties) then
  begin
    d := ARequest.Items;
    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'message');
            ARequest.ErrorMessage := ARequest.Status;

            jav := TTMSFNCUtils.GetJSONValue(o, 'features') as TJSONArray;
            if Assigned(jav) then
            begin
              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jav) - 1 do
              begin
                di := d.Add;
                jo := TTMSFNCUtils.GetJSONArrayItem(jav, i);

                di.Address := TTMSFNCUtils.GetJSONProp(jo, 'place_name');
                di.Street := TTMSFNCUtils.GetJSONProp(jo, 'address');

                jaa := TTMSFNCUtils.GetJSONValue(jo, 'context') as TJSONArray;
                if Assigned(jaa) then
                begin
                  for j := 0 to TTMSFNCUtils.GetJSONArraySize(jaa) - 1 do
                  begin
                    oa := TTMSFNCUtils.GetJSONArrayItem(jaa, j);
                    cid := TTMSFNCUtils.GetJSONProp(oa, 'id');

                    if (pos('postcode', cid) > 0) then
                      di.PostalCode := TTMSFNCUtils.GetJSONProp(oa, 'text')
                    else if (pos('place', cid) > 0) then
                      di.City := TTMSFNCUtils.GetJSONProp(oa, 'text')
                    else if (pos('region', cid) > 0) then
                      di.Region := TTMSFNCUtils.GetJSONProp(oa, 'text')
                    else if (pos('country', cid) > 0) then
                      di.Country := TTMSFNCUtils.GetJSONProp(oa, 'text')
                    ;
                  end;
                end;

                jas := TTMSFNCUtils.GetJSONValue(jo, 'center') as TJSONArray;
                if Assigned(jas) then
                begin
                  sposition := TStringList.Create;
                  position := StringReplace(jas.ToString, '[', '', [rfReplaceAll]);
                  position := StringReplace(position, ']', '', [rfReplaceAll]);
                  TTMSFNCUtils.Split(',', position, sposition);
                  if sposition.Count > 1 then
                  begin
                    di.Coordinate.Latitude := StrToFloatDot(sposition[1]);
                    di.Coordinate.Longitude := StrToFloatDot(sposition[0]);
                  end;
                  sposition.Free;
                end;
              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

