{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGeocoding.TomTom;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterTomTomGeocodingService;
procedure UnRegisterTomTomGeocodingService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCGeocoding,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$ENDIF}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCGeocodingTomTomService = class;

  TTMSFNCGeocodingTomTomGeocodingFactoryService = class(TTMSFNCGeocodingFactoryService, ITMSFNCGeocodingServiceTomTom);

  TTMSFNCGeocodingTomTomService = class(TTMSFNCGeocodingTomTomGeocodingFactoryService)
  protected
    function DoCreateGeocoding: ITMSFNCCustomGeocoding; override;
  end;

type
  TTMSFNCGeocodingTomTomGeocoding = class;

  TTMSFNCGeocodingTomTomGeocoding = class(TTMSFNCCustomGeocodingInterfacedObject, ITMSFNCCustomGeocoding)
  protected
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetReverseHost: string;
    function GetPath(AAddress: string): string;
    function GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
    function GetQuery(AAddress: string; ALocale: string): string; overload;
    function GetQuery(ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string; overload;
    function GetPostData: string;
    procedure Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyGeocoding;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  GeocodingService: ITMSFNCGeocodingServiceTomTom;

procedure RegisterTomTomGeocodingService;
begin
  if not TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceTomTom, IInterface(GeocodingService)) then
  begin
    GeocodingService := TTMSFNCGeocodingTomTomService.Create;
    TTMSFNCGeocodingPlatformServices.Current.AddPlatformService(ITMSFNCGeocodingServiceTomTom, GeocodingService);
  end;
end;

procedure UnregisterTomTomGeocodingService;
begin
  TTMSFNCGeocodingPlatformServices.Current.RemovePlatformService(ITMSFNCGeocodingServiceTomTom);
end;

{ TTMSFNCGeocodingTomTomService }

function TTMSFNCGeocodingTomTomService.DoCreateGeocoding: ITMSFNCCustomGeocoding;
begin
  Result := TTMSFNCGeocodingTomTomGeocoding.Create;
end;

procedure TTMSFNCGeocodingTomTomGeocoding.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCGeocodingTomTomGeocoding.Create;
begin
  inherited;
end;

destructor TTMSFNCGeocodingTomTomGeocoding.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGeocodingTomTomGeocoding.DestroyGeocoding;
begin
  GeocodingService.DestroyGeocoding(Self);
end;

function TTMSFNCGeocodingTomTomGeocoding.GetHost: string;
begin
  Result := 'https://api.tomtom.com';
end;

function TTMSFNCGeocodingTomTomGeocoding.GetReverseHost: string;
begin
  Result := GetHost;
end;

function TTMSFNCGeocodingTomTomGeocoding.GetIdentifier: string;
begin
  Result := 'TomTom';
end;

function TTMSFNCGeocodingTomTomGeocoding.GetPath(AAddress: string): string;
begin
  Result := '/search/2/geocode/' + AAddress + '.json';
end;

function TTMSFNCGeocodingTomTomGeocoding.GetReversePath(
  ACoordinate: TTMSFNCMapsCoordinateRec): string;
begin
  Result :=  '/search/2/reverseGeocode/' + (FloatToStrDot(ACoordinate.Latitude) + ',' + FloatToStrDot(ACoordinate.Longitude)) + '.json';
end;

function TTMSFNCGeocodingTomTomGeocoding.GetPostData: string;
begin
  Result := '';
end;

function TTMSFNCGeocodingTomTomGeocoding.GetQuery(
  ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string;
begin
  Result := GetQuery('', ALocale);
end;

function TTMSFNCGeocodingTomTomGeocoding.GetQuery(AAddress: string; ALocale: string): string;
begin
  Result := 'key=' + GeocodingProperties.GetAPIKey;

  if ALocale <> '' then
    Result := Result + '&language=' + ALocale;
end;

function TTMSFNCGeocodingTomTomGeocoding.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCGeocodingTomTomGeocoding.IsValid: Boolean;
begin
  Result := GeocodingProperties.GetAPIKey <> '';
end;

procedure TTMSFNCGeocodingTomTomGeocoding.Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
var
  d: TTMSFNCGeocodingItems;
  di: TTMSFNCGeocodingItem;

  o, jo: TJSONValue;
  jav: TJSONArray;
  i: Integer;
  oc, op: TJSONObject;
  isReverse: Boolean;
  position: string;
  sposition: TStringList;
begin
  if Assigned(GeocodingProperties) then
  begin
    d := ARequest.Items;
    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'httpStatusCode');
            ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(o, 'errorText');

            //geocoding
            jav := TTMSFNCUtils.GetJSONValue(o, 'results') as TJSONArray;
            isReverse := False;

            //reversegeocoding
            if not Assigned(jav) then
            begin
              jav := TTMSFNCUtils.GetJSONValue(o, 'addresses') as TJSONArray;
              isReverse := True;
            end;

            if Assigned(jav) then
            begin
              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jav) - 1 do
              begin
                di := d.Add;
                jo := TTMSFNCUtils.GetJSONArrayItem(jav, i);

                oc := TTMSFNCUtils.GetJSONValue(jo, 'address') as TJSONObject;
                if Assigned(oc) then
                begin
                  di.Address := TTMSFNCUtils.GetJSONProp(oc, 'freeformAddress');
                  di.City := TTMSFNCUtils.GetJSONProp(oc, 'municipality');
                  di.Country := TTMSFNCUtils.GetJSONProp(oc, 'country');
                  di.CountryCode := TTMSFNCUtils.GetJSONProp(oc, 'countryCode');
                  di.Region := TTMSFNCUtils.GetJSONProp(oc, 'countrySecondarySubdivision');
                  di.Province := TTMSFNCUtils.GetJSONProp(oc, 'countrySubdivision');
                  di.Street := TTMSFNCUtils.GetJSONProp(oc, 'streetName') + ' ' + TTMSFNCUtils.GetJSONProp(oc, 'streetNumber');
                  di.PostalCode := TTMSFNCUtils.GetJSONProp(oc, 'postalCode');
                end;

                if not isReverse then
                begin
                  op := TTMSFNCUtils.GetJSONValue(jo, 'position') as TJSONObject;
                  if Assigned(op) then
                  begin
                    di.Coordinate.Latitude := TTMSFNCUtils.GetJSONDoubleValue(op, 'lat');
                    di.Coordinate.Longitude := TTMSFNCUtils.GetJSONDoubleValue(op, 'lon');
                  end;
                end
                else
                begin
                  position := TTMSFNCUtils.GetJSONProp(jo, 'position');
                  if position <> '' then
                  begin
                    sposition := TStringList.Create;
                    TTMSFNCUtils.Split(',', position, sposition);
                    if sposition.Count > 1 then
                    begin
                      di.Coordinate.Latitude := StrToFloatDot(sposition[0]);
                      di.Coordinate.Longitude := StrToFloatDot(sposition[1]);
                    end;
                    sposition.Free;
                  end;
                end;
              end;
            end;
          finally
            o.Free;
          end;
        end
        else
        begin
          //error
          ARequest.Status := ARequestResult;
          ARequest.ErrorMessage := ARequest.Status;
        end;
      end;
    end;
  end;
end;

end.

