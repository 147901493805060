{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCDirections.Azure;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterAzureDirectionsService;
procedure UnRegisterAzureDirectionsService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCDirections,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCDirectionsAzureService = class;

  TTMSFNCDirectionsAzureDirectionsFactoryService = class(TTMSFNCDirectionsFactoryService, ITMSFNCDirectionsServiceAzure);

  TTMSFNCDirectionsAzureService = class(TTMSFNCDirectionsAzureDirectionsFactoryService)
  protected
    function DoCreateDirections: ITMSFNCCustomDirections; override;
  end;

type
  TTMSFNCDirectionsAzureDirections = class;

  TTMSFNCDirectionsAzureDirections = class(TTMSFNCCustomDirectionsInterfacedObject, ITMSFNCCustomDirections)
  protected
    FTravelMode: TTMSFNCDirectionsTravelMode;
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; ATravelMode: TTMSFNCDirectionsTravelMode; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
    function GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
    function GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
    procedure Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyDirections;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  DirectionsService: ITMSFNCDirectionsServiceAzure;

procedure RegisterAzureDirectionsService;
begin
  if not TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceAzure, IInterface(DirectionsService)) then
  begin
    DirectionsService := TTMSFNCDirectionsAzureService.Create;
    TTMSFNCDirectionsPlatformServices.Current.AddPlatformService(ITMSFNCDirectionsServiceAzure, DirectionsService);
  end;
end;

procedure UnregisterAzureDirectionsService;
begin
  TTMSFNCDirectionsPlatformServices.Current.RemovePlatformService(ITMSFNCDirectionsServiceAzure);
end;

{ TTMSFNCDirectionsAzureService }

function TTMSFNCDirectionsAzureService.DoCreateDirections: ITMSFNCCustomDirections;
begin
  Result := TTMSFNCDirectionsAzureDirections.Create;
end;

procedure TTMSFNCDirectionsAzureDirections.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCDirectionsAzureDirections.Create;
begin
  inherited;
end;

destructor TTMSFNCDirectionsAzureDirections.Destroy;
begin
  inherited;
end;

procedure TTMSFNCDirectionsAzureDirections.DestroyDirections;
begin
  DirectionsService.DestroyDirections(Self);
end;

function TTMSFNCDirectionsAzureDirections.GetHost: string;
begin
  Result := 'https://atlas.microsoft.com';
end;

function TTMSFNCDirectionsAzureDirections.GetIdentifier: string;
begin
  Result := 'Azure';
end;

function TTMSFNCDirectionsAzureDirections.GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
  ADestinationLatitude, ADestinationLongitude: Double; ATravelMode: TTMSFNCDirectionsTravelMode; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
begin
  Result := '/route/directions/json';
end;

function TTMSFNCDirectionsAzureDirections.GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
begin
  Result := '';
end;

function TTMSFNCDirectionsAzureDirections.GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
  ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
  ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
  AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
var
  TravelMode, WayPoints, Alternatives: string;
  I: Integer;
begin
  if AAlternatives then
    Alternatives := '5'
  else
    Alternatives := '0';

  if ATravelMode in [tmPublicTransport] then
    FTravelMode := tmDriving
  else
    FTravelMode := ATravelMode;

  case ATravelMode of
    tmDriving: TravelMode := 'car';
    tmWalking: TravelMode := 'pedestrian';
    tmBicycling: TravelMode := 'bicycle';
    tmTruck: TravelMode := 'truck';
  end;

  AOrigin := FloatToStrDot(AOriginLatitude) + ',' + FloatToStrDot(AOriginLongitude);
  ADestination := FloatToStrDot(ADestinationLatitude) + ',' + FloatToStrDot(ADestinationLongitude);

  WayPoints := AOrigin;
  if Assigned(AWaypoints) and (Length(AWayPoints) > 0) then
  begin
    for I := 0 to (Length(AWayPoints) - 1) do
      WayPoints := WayPoints + ':' + FloatToStrDot(AWayPoints[I].Latitude) + ',' + FloatToStrDot(AWayPoints[I].Longitude);
  end;
  WayPoints := WayPoints + ':' + ADestination;

  Result := 'api-version=1.0'
  + '&subscription-key=' + DirectionsProperties.GetAPIKey
  + '&query=' + WayPoints
  + '&maxAlternatives=' + Alternatives
  + '&computeBestOrder=' + LowerCase(BoolToStr(AOptimizeWayPoints, True))
  + '&instructionsType=text'
  + '&travelMode=' + TravelMode
  + '&language=' + ALocale;

  if AAvoidTolls then
    Result := Result + '&avoid=tollRoads';
end;

function TTMSFNCDirectionsAzureDirections.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCDirectionsAzureDirections.IsValid: Boolean;
begin
  Result := DirectionsProperties.GetAPIKey <> '';
end;

procedure TTMSFNCDirectionsAzureDirections.Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
var
  d: TTMSFNCDirectionsItems;
  di: TTMSFNCDirectionsItem;
  l: TTMSFNCDirectionsLeg;
  co: TTMSFNCMapsCoordinateItem;
  step: TTMSFNCDirectionsStep;
  wp: TTMSFNCDirectionsWayPoint;

  o, jo, jol, jos: TJSONValue;
  jar, jal, jas: TJSONArray;
  i, j, k: Integer;
  ol, os, oc: TJSONObject;
  wpIndex: array of Integer;
  providedIndex, optimizedIndex: Integer;
begin
  if Assigned(DirectionsProperties) then
  begin
    ARequest.TravelMode := FTravelMode;

    d := ARequest.Items;
    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            oc := TTMSFNCUtils.GetJSONValue(o, 'error') as TJSONObject;
            if Assigned(oc) then
            begin
              ARequest.Status := TTMSFNCUtils.GetJSONProp(oc, 'code');
              ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(oc, 'message');
            end;

            SetLength(wpIndex, 1);
            wpIndex[0] := -1;

            jal := TTMSFNCUtils.GetJSONValue(o, 'optimizedWaypoints') as TJSONArray;
            if Assigned(jal) then
            begin
              SetLength(wpIndex, TTMSFNCUtils.GetJSONArraySize(jal) + 1);

              for j := 0 to TTMSFNCUtils.GetJSONArraySize(jal) - 1 do
              begin
                jol := TTMSFNCUtils.GetJSONArrayItem(jal, j);

                if Assigned(jol) then
                begin
                  providedIndex := TTMSFNCUtils.GetJSONIntegerValue(jol, 'providedIndex');
                  optimizedIndex := TTMSFNCUtils.GetJSONIntegerValue(jol, 'optimizedIndex');
                  wpIndex[providedIndex + 1] := optimizedIndex;
                end;
              end;
            end;

            jar := TTMSFNCUtils.GetJSONValue(o, 'routes') as TJSONArray;
            if Assigned(jar) then
            begin
              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
              begin
                jo := TTMSFNCUtils.GetJSONArrayItem(jar, i);

                di := d.Add;
                di.Summary := 'Route ' + IntToStr(i);

                jal := TTMSFNCUtils.GetJSONValue(jo, 'legs') as TJSONArray;
                if Assigned(jal) then
                begin
                  for j := 0 to TTMSFNCUtils.GetJSONArraySize(jal) - 1 do
                  begin
                    jol := TTMSFNCUtils.GetJSONArrayItem(jal, j);

                    l := di.Legs.Add;

                    wp := di.WayPoints.Add;
                    if Length(wpIndex) > j then
                      wp.OptimizedIndex := wpIndex[j];

                    ol := TTMSFNCUtils.GetJSONValue(jol, 'summary') as TJSONObject;
                    if Assigned(ol) then
                    begin
                      l.Distance := TTMSFNCUtils.GetJSONIntegerValue(ol, 'lengthInMeters');
                      di.Distance := di.Distance + l.Distance;
                      l.Duration := TTMSFNCUtils.GetJSONIntegerValue(ol, 'travelTimeInSeconds');
                      di.Duration := di.Duration + l.Duration;
                      wp.Distance := l.Distance;
                      wp.Duration := l.Duration;
                    end;

                    jas := TTMSFNCUtils.GetJSONValue(jol, 'points') as TJSONArray;
                    if Assigned(jas) then
                    begin
                      for k := 0 to TTMSFNCUtils.GetJSONArraySize(jas) - 1 do
                      begin
                        jos := TTMSFNCUtils.GetJSONArrayItem(jas, k);

                        co := di.Coordinates.Add;
                        co.Latitude := TTMSFNCUtils.GetJSONDoubleValue(jos, 'latitude');
                        co.Longitude := TTMSFNCUtils.GetJSONDoubleValue(jos, 'longitude');
                        l.Coordinates.Add.Assign(co);
                        if k = 0 then
                        begin
                          l.StartLocation.Latitude := co.Latitude;
                          l.StartLocation.Longitude := co.Longitude;
                        end
                        else if k = TTMSFNCUtils.GetJSONArraySize(jas) - 1 then
                        begin
                          l.EndLocation.Latitude := co.Latitude;
                          l.EndLocation.Longitude := co.Longitude;
                        end;
                      end;
                    end;
                  end;
                end;

                ol := TTMSFNCUtils.GetJSONValue(jo, 'guidance') as TJSONObject;
                if Assigned(ol) then
                begin
                  jas := TTMSFNCUtils.GetJSONValue(ol, 'instructions') as TJSONArray;
                  if Assigned(jas) then
                  begin
                    for k := 0 to TTMSFNCUtils.GetJSONArraySize(jas) - 1 do
                    begin
                      jos := TTMSFNCUtils.GetJSONArrayItem(jas, k);

                      step := di.Steps.Add;
                      step.Distance := TTMSFNCUtils.GetJSONIntegerValue(jos, 'routeOffsetInMeters');
                      step.Duration := TTMSFNCUtils.GetJSONIntegerValue(jos, 'travelTimeInSeconds');
                      os := TTMSFNCUtils.GetJSONValue(jos, 'point') as TJSONObject;
                      if Assigned(os) then
                      begin
                        step.StartLocation.Latitude := TTMSFNCUtils.GetJSONDoubleValue(os, 'latitude');
                        step.StartLocation.Longitude := TTMSFNCUtils.GetJSONDoubleValue(os, 'longitude');
                      end;
                      step.Instructions := TTMSFNCUtils.GetJSONProp(jos, 'message');
                    end;
                  end;
                end;

              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

