{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCLocation;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

{$IFNDEF LCLLIB}
{$HINTS OFF}
{$IF COMPILERVERSION > 23}
{$DEFINE UNREGISTER}
{$IFEND}
{$HINTS ON}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE UNREGISTER}
{$ENDIF}

interface

uses
  Classes, Types, WEBLib.Forms
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,web, Contnrs
  {$ENDIF}
  {$IFDEF CMNLIB}
  {$ENDIF}
  ,WEBLib.TMSFNCTypes
  ,WEBLib.TMSFNCMapsCommonTypes
  ,WEBLib.TMSFNCCloudBase;

const
  TTMSFNCLocationDocURL = TTMSFNCBaseDocURL + 'tmsfncmaps/components/ttmsfncmaps/#ttmsfnclocation';
  TTMSFNCLocationTipsURL = 'https://www.tmssoftware.com/site/tmsfncmaps.asp?s=faq';
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 2; // Release nr.
  BLD_VER = 0; // Build nr.

  //v1.0.0.0: First release
  //v1.0.1.0: Connection property added (IPStack only)
  //v1.0.2.0: RegionCode property added (IPStack only)

type
  TTMSFNCCustomLocation = class;
  TTMSFNCLocationRequests = class;
  TTMSFNCLocationRequest = class;

  ITMSFNCCustomLocationProperties = interface(IInterface)
    ['{360FCEAE-9777-47FB-ACCC-4DFCAC859E8C}']
    function GetAPIKey: string;
  end;

  ITMSFNCCustomLocationInstance = interface(IInterface)
    ['{2E68C9D2-AAE4-4D00-B243-BA7EC6BF1946}']
    procedure SetLocationProperties(const Value: ITMSFNCCustomLocationProperties);
  end;

  ITMSFNCCustomLocation = interface(IInterface)
  ['{AC454083-A583-4826-B598-DF46154FD159}']
    function GetIdentifier: string;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetPath: string;
    function GetQuery: string;
    function GetPostData: string;
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure Parse(ARequest: TTMSFNCLocationRequest; ARequestResult: string);
    function IsValid: Boolean;
    procedure DestroyLocation;
  end;

  ITMSFNCLocationService = interface(IInterface)
  ['{E18B6ADA-BA25-4D32-8427-9BA47CDE3B86}']
    function CreateLocation: ITMSFNCCustomLocation;
    procedure DestroyLocation(ALocation: ITMSFNCCustomLocation);
  end;

  ITMSFNCLocationServiceIPStack = interface(ITMSFNCLocationService)
  ['{212D0845-716A-4E06-A5EE-3DB50B1B639F}']
  end;

  ITMSFNCLocationServiceGoogle = interface(ITMSFNCLocationService)
  ['{79722F6E-7BE2-451F-9D22-8F3C3CB5F118}']
  end;

  TTMSFNCLocationService = (lsGoogle, lsIPStack);
  TTMSFNCLocationConnection = (lcHTTP, lcHTTPS);


  TTMSFNCLocationRequest = class(TCollectionItem)
  private
    FID: string;
    FOwner: TTMSFNCCustomLocation;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FCoordinate: TTMSFNCMapsCoordinate;
    FPostalCode: string;
    FCountry: string;
    FCountryCode: string;
    FRegion: string;
    FCity: string;
    FIP: string;
    FIPType: string;
    FContinentCode: string;
    FContinent: string;
    FRegionCode: string;
    procedure SetCoordinate(const Value: TTMSFNCMapsCoordinate);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
  published
    property ID: string read FID;
    property Coordinate: TTMSFNCMapsCoordinate read FCoordinate write SetCoordinate;
    property City: string read FCity write FCity;
    property Country: string read FCountry write FCountry;
    property CountryCode: string read FCountryCode write FCountryCode;
    property Continent: string read FContinent write FContinent;
    property ContinentCode: string read FContinentCode write FContinentCode;
    property Region: string read FRegion write FRegion;
    property RegionCode: string read FRegionCode write FRegionCode;
    property PostalCode: string read FPostalCode write FPostalCode;
    property IP: string read FIP write FIP;
    property IPType: string read FIPType write FIPType;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCLocationRequests = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCLocationRequests = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCLocationRequest>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomLocation;
    function GetItem(Index: Integer): TTMSFNCLocationRequest;
    procedure SetItem(Index: Integer; const Value: TTMSFNCLocationRequest);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomLocation); virtual;
    property Items[Index: Integer]: TTMSFNCLocationRequest read GetItem write SetItem; default;
    function Add: TTMSFNCLocationRequest;
    function Insert(Index: Integer): TTMSFNCLocationRequest;
  end;

  { TTMSFNCCustomLocation }

  TTMSFNCLocationGetLocationCallBack = {$IFNDEF LCLLIB}reference to {$ENDIF}procedure(const ARequest: TTMSFNCLocationRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult){$IFDEF LCLLIB} of object{$ENDIF};
  TTMSFNCLocationGetLocationEvent = procedure(Sender: TObject; const ARequest: TTMSFNCLocationRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult) of object;

  TTMSFNCLocationGetLocationResultCallBack = {$IFNDEF LCLLIB}reference to {$ENDIF}procedure(const AResult: TTMSFNCLocationRequest){$IFDEF LCLLIB} of object{$ENDIF};
  TTMSFNCLocationGetLocationResultEvent = procedure(Sender: TObject; const AResult: TTMSFNCLocationRequest) of object;

  TTMSFNCLocationCallBackWrapper = class
  private
    FCallback: TTMSFNCLocationGetLocationCallBack;
  public
    constructor Create(ACallback: TTMSFNCLocationGetLocationCallBack);
  end;

  TTMSFNCLocationResultCallBackWrapper = class
  private
    FCallback: TTMSFNCLocationGetLocationResultCallBack;
  public
    constructor Create(ACallback: TTMSFNCLocationGetLocationResultCallBack);
  end;

  TTMSFNCCustomLocation = class(TTMSFNCCloudBase, ITMSFNCCustomLocationProperties)
  private
    FLocation: ITMSFNCCustomLocation;
    FLocationInstance: ITMSFNCCustomLocationInstance;
    FLocationProperties: ITMSFNCCustomLocationProperties;
    FService: TTMSFNCLocationService;
    FAPIKey: string;
    FLocationRequests: TTMSFNCLocationRequests;
    FOnGetLocation: TTMSFNCLocationGetLocationEvent;
    FOnGetLocationInternal: TTMSFNCLocationGetLocationEvent;
    FConnection: TTMSFNCLocationConnection;
    FOnGetLocationResultInternal: TTMSFNCLocationGetLocationResultEvent;
    FOnGetLocationResult: TTMSFNCLocationGetLocationResultEvent;
    procedure SetService(const Value: TTMSFNCLocationService);
    procedure SetAPIKey(const Value: string);
    procedure SetLocationRequests(const Value: TTMSFNCLocationRequests);
    procedure SetConnection(const Value: TTMSFNCLocationConnection);
  protected
    procedure InternalGetLocation(ACallback: TTMSFNCLocationGetLocationCallBack = nil; ACallback2: TTMSFNCLocationGetLocationResultCallBack = nil; AID: string = ''); overload; virtual;
    procedure DoRequestGetLocation(const ARequestResult: TTMSFNCCloudBaseRequestResult);
    procedure DoGetLocation(const ARequest: TTMSFNCLocationRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult);
    property OnGetLocation: TTMSFNCLocationGetLocationEvent read FOnGetLocation write FOnGetLocation;
    property OnGetLocationInternal: TTMSFNCLocationGetLocationEvent read FOnGetLocationInternal write FOnGetLocationInternal;
    property OnGetLocationResult: TTMSFNCLocationGetLocationResultEvent read FOnGetLocationResult write FOnGetLocationResult;
    property OnGetLocationResultInternal: TTMSFNCLocationGetLocationResultEvent read FOnGetLocationResultInternal write FOnGetLocationResultInternal;

    procedure InitializeLocation; virtual;
    function GetInstance: NativeUInt; override;
    function GetLocationRequests: TTMSFNCLocationRequests;
    function LocationReady: Boolean;
    function GetAPIKey: string;
    function GetVersionNr: Integer;
    function GetDocURL: string; override;
    function GetTipsURL: string; override;
    function Location: ITMSFNCCustomLocation;
    function GetVersion: string; override;
    property Service: TTMSFNCLocationService read FService write SetService default lsGoogle;
    property APIKey: string read FAPIKey write SetAPIKey;
    property LocationRequests: TTMSFNCLocationRequests read FLocationRequests write SetLocationRequests;

    property LocationInstance: ITMSFNCCustomLocation read FLocation;
    property LocationProperties: ITMSFNCCustomLocationProperties read FLocationProperties;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure GetLocation(ACallback: TTMSFNCLocationGetLocationCallBack = nil; AID: string = ''); overload; virtual;
    procedure GetLocationResult(ACallback: TTMSFNCLocationGetLocationResultCallBack = nil; AID: string = ''); overload; virtual;
  published
    property Connection: TTMSFNCLocationConnection read FConnection write SetConnection;
  end;

  TTMSFNCCustomLocationInterfacedObject = class(TInterfacedObject, ITMSFNCCustomLocationInstance)
  private
    FLocationProperties: ITMSFNCCustomLocationProperties;
    function GetLocationProperties: ITMSFNCCustomLocationProperties;
  protected
    procedure SetLocationProperties(const Value: ITMSFNCCustomLocationProperties);
  public
    property LocationProperties: ITMSFNCCustomLocationProperties read GetLocationProperties;
    destructor Destroy; override;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCLocationList = class(TList)
  private
    function GetItem(Index: Integer): ITMSFNCCustomLocation;
    procedure SetItem(Index: Integer; const Value: ITMSFNCCustomLocation);
  public
    property Items[Index: Integer]: ITMSFNCCustomLocation read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCLocationList = class(TList<ITMSFNCCustomLocation>);
  {$ENDIF}

  TTMSFNCLocationFactoryService = class(TInterfacedObject, ITMSFNCLocationService)
  private
    FLocation: TTMSFNCLocationList;
  protected
    function DoCreateLocation: ITMSFNCCustomLocation; virtual; abstract;
    function CreateLocation: ITMSFNCCustomLocation;
    procedure DestroyLocation(ALocation: ITMSFNCCustomLocation);
  public
    constructor Create;
    destructor Destroy; override;
  end;

  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  TTMSFNCLocation = class(TTMSFNCCustomLocation)
  protected
    procedure RegisterRuntimeClasses; override;
  public
    property LocationInstance;
    property LocationProperties;
  published
    property OnGetLocation;
    property OnGetLocationResult;
    property APIKey;
    property LocationRequests;
    property Service;
    property Version;
  end;

  TTMSFNCLocationPlatformServicesService = class
  private
    FInterface: IInterface;
    FGUID: string;
  public
    constructor Create(AGUID: string; AInterface: IInterface);
    property GUID: string read FGUID;
    property &Interface: IInterface read FInterface;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCLocationPlatformServicesList = class(TObjectList)
  private
    function GetItem(Index: Integer): TTMSFNCLocationPlatformServicesService;
    procedure SetItem(Index: Integer; const Value: TTMSFNCLocationPlatformServicesService);
  public
    property Items[Index: Integer]: TTMSFNCLocationPlatformServicesService read GetItem write SetItem; default;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCLocationPlatformServicesList = class(TObjectList<TTMSFNCLocationPlatformServicesService>)
  {$ENDIF}
  private
    function GetValue(AGUID: string): IInterface;
  public
    function ContainsKey(AGUID: string): Boolean;
    procedure RemoveByGUID(AGUID: string);
    property Interfaces[AGUID: string]: IInterface read GetValue;
  end;

  TTMSFNCLocationPlatformServices = class
  private
    FServicesList: TTMSFNCLocationPlatformServicesList;
    class var FCurrent: TTMSFNCLocationPlatformServices;
    class var FCurrentReleased: Boolean;
{$IFNDEF AUTOREFCOUNT}
    class procedure ReleaseCurrent;
{$ENDIF}
  public
    constructor Create;
    destructor Destroy; override;
    procedure AddPlatformService(const AServiceGUID: TGUID; const AService: IInterface);
    procedure RemovePlatformService(const AServiceGUID: TGUID);
    function GetPlatformService(const AServiceGUID: TGUID): IInterface;
    function SupportsPlatformService(const AServiceGUID: TGUID): Boolean; overload;
    function SupportsPlatformService(const AServiceGUID: TGUID; var AService: IInterface): Boolean; overload;
    class function Current: TTMSFNCLocationPlatformServices;
  end;

implementation

uses
  {$IFDEF MSWINDOWS}
  Windows, Registry,
  {$ENDIF}
  {%H-}Math,
  WEBLib.TMSFNCUtils,
  WEBLib.TMSFNCLocation.IPStack,
  WEBLib.TMSFNCLocation.Google,
  SysUtils
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  ;

{ TTMSFNCCustomLocation }

function TTMSFNCCustomLocation.GetAPIKey: string;
begin
  Result := APIKey;
end;

function TTMSFNCCustomLocation.GetDocURL: string;
begin
  Result := TTMSFNCLocationDocURL;
end;

function TTMSFNCCustomLocation.GetInstance: NativeUInt;
begin
  Result := HInstance;
end;

function TTMSFNCCustomLocation.GetVersion: string;
var
  vn: Integer;
begin
  vn := GetVersionNr;
  Result := IntToStr(Hi(Hiword(vn)))+'.'+IntToStr(Lo(Hiword(vn)))+'.'+IntToStr(Hi(Loword(vn)))+'.'+IntToStr(Lo(Loword(vn)));
end;

function TTMSFNCCustomLocation.GetVersionNr: Integer;
begin
  Result := MakeLong(MakeWord(BLD_VER,REL_VER),MakeWord(MIN_VER,MAJ_VER));
end;

procedure TTMSFNCCustomLocation.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TTMSFNCCustomLocation) then
  begin
    FService := (Source as TTMSFNCCustomLocation).Service;
    FAPIKey := (Source as TTMSFNCCustomLocation).APIKey;
    FLocationRequests.Assign((Source as TTMSFNCCustomLocation).LocationRequests);
  end;
end;

procedure TTMSFNCCustomLocation.GetLocationResult(
  ACallback: TTMSFNCLocationGetLocationResultCallBack; AID: string);
begin
  InternalGetLocation(nil, ACallback, AID);
end;

function TTMSFNCCustomLocation.GetLocationRequests: TTMSFNCLocationRequests;
begin
  Result := LocationRequests;
end;

function TTMSFNCCustomLocation.GetTipsURL: string;
begin
  Result := TTMSFNCLocationTipsURL;
end;

procedure TTMSFNCCustomLocation.GetLocation(ACallback: TTMSFNCLocationGetLocationCallBack = nil; AID: string = '');
begin
  InternalGetLocation(ACallback, nil, AID);
end;

procedure TTMSFNCCustomLocation.InternalGetLocation(ACallback: TTMSFNCLocationGetLocationCallBack = nil; ACallback2: TTMSFNCLocationGetLocationResultCallBack = nil; AID: string = '');
begin
  if not LocationReady then
  begin
    raise Exception.Create('Please set API key!');
    Exit;
  end;

  Request.Clear;
  Request.ClearHeaders;
  FLocation.AddHeaders(Request.Headers);
  Request.Name := 'GET LOCATION';
  Request.DataString := AID;

  if Assigned(ACallback) then
    Request.DataObject := TTMSFNCLocationCallBackWrapper.Create(ACallback)
  else if Assigned(ACallback2) then
    Request.DataObject := TTMSFNCLocationResultCallBackWrapper.Create(ACallback2);

  Request.Method := FLocation.GetRequestMethod;
  Request.Host := FLocation.GetHost;

  if (Service = lsIPStack) and (Connection = lcHTTP) then
    Request.Host := StringReplace(Request.Host, 'https://', 'http://', [rfIgnoreCase]);

  Request.Path := FLocation.GetPath;
  Request.Query := FLocation.GetQuery;
  Request.PostData := FLocation.GetPostData;
  ExecuteRequest({$IFDEF LCLWEBLIB}@{$ENDIF}DoRequestGetLocation);
end;

constructor TTMSFNCCustomLocation.Create(AOwner: TComponent);
begin
  inherited;
  Supports(Self, ITMSFNCCustomLocationProperties, FLocationProperties);
  FLocationRequests := TTMSFNCLocationRequests.Create(Self);
  InitializeLocation;
end;

procedure TTMSFNCCustomLocation.InitializeLocation;
var
  LocationServiceGoogleLocation: ITMSFNCLocationServiceGoogle;
  LocationServiceIPStackLocation: ITMSFNCLocationServiceIPStack;
begin
  if IsDestroying then
    Exit;

  if Assigned(FLocation) then
    FLocation.DestroyLocation;

  FLocation := nil;
  FLocationInstance := nil;

  case Service of
    lsGoogle:
    begin
      if TTMSFNCLocationPlatformServices.Current.SupportsPlatformService(ITMSFNCLocationServiceGoogle, IInterface(LocationServiceGoogleLocation)) then
        FLocation := LocationServiceGoogleLocation.CreateLocation;
    end;
    lsIPStack:
    begin
      if TTMSFNCLocationPlatformServices.Current.SupportsPlatformService(ITMSFNCLocationServiceIPStack, IInterface(LocationServiceIPStackLocation)) then
        FLocation := LocationServiceIPStackLocation.CreateLocation;
    end;
  end;

  if Assigned(FLocation) and Supports(FLocation, ITMSFNCCustomLocationInstance, FLocationInstance) then
    FLocationInstance.SetLocationProperties(FLocationProperties);
end;

function TTMSFNCCustomLocation.LocationReady: Boolean;
begin
  Result := Assigned(FLocation) and Assigned(FLocationInstance) and Assigned(FLocationProperties) and FLocation.IsValid;
end;

procedure TTMSFNCCustomLocation.DoGetLocation(const ARequest: TTMSFNCLocationRequest;
  const ARequestResult: TTMSFNCCloudBaseRequestResult);
begin
  if Assigned(OnGetLocationInternal) then
    OnGetLocationInternal(Self, ARequest, ARequestResult);
  if Assigned(OnGetLocation) then
    OnGetLocation(Self, ARequest, ARequestResult);

  if Assigned(OnGetLocationResultInternal) then
    OnGetLocationResultInternal(Self, ARequest);
  if Assigned(OnGetLocationResult) then
    OnGetLocationResult(Self, ARequest);
end;

procedure TTMSFNCCustomLocation.DoRequestGetLocation(
  const ARequestResult: TTMSFNCCloudBaseRequestResult);
var
  req: TTMSFNCLocationRequest;
  obj: TTMSFNCLocationCallBackWrapper;
  obj2: TTMSFNCLocationResultCallBackWrapper;
begin
  if not LocationReady then
    Exit;

  if ARequestResult.Success then
  begin
    req := LocationRequests.Add;
    req.FID := ARequestResult.DataString;
    FLocation.Parse(req, ARequestResult.ResultString);
    DoGetLocation(req, ARequestResult);
    if Assigned(ARequestResult.DataObject) then
    begin
      if ARequestResult.DataObject is TTMSFNCLocationCallBackWrapper then
      begin
        obj := TTMSFNCLocationCallBackWrapper(ARequestResult.DataObject);
        obj.FCallback(req, ARequestResult);
        obj.Free;
      end
      else if ARequestResult.DataObject is TTMSFNCLocationResultCallBackWrapper then
      begin
        obj2 := TTMSFNCLocationResultCallBackWrapper(ARequestResult.DataObject);
        obj2.FCallback(req);
        obj2.Free;
      end
    end;
  end;
end;

function TTMSFNCCustomLocation.Location: ITMSFNCCustomLocation;
begin
  Result := FLocation;
end;

procedure TTMSFNCCustomLocation.SetAPIKey(const Value: string);
begin
  if FAPIKey <> Value then
    FAPIKey := Value;
end;

procedure TTMSFNCCustomLocation.SetConnection(
  const Value: TTMSFNCLocationConnection);
begin
  FConnection := Value;
end;

procedure TTMSFNCCustomLocation.SetService(const Value: TTMSFNCLocationService);
begin
  if FService <> Value then
  begin
    FService := Value;
    InitializeLocation;
  end;
end;

destructor TTMSFNCCustomLocation.Destroy;
begin
  FLocationRequests.Free;

  if Assigned(FLocation) then
    FLocation.DestroyLocation;

  FLocation := nil;
  FLocationInstance := nil;
  FLocationProperties := nil;
  FConnection := lcHTTP;
  inherited;
end;

procedure TTMSFNCCustomLocation.SetLocationRequests(const Value: TTMSFNCLocationRequests);
begin
  FLocationRequests.Assign(Value);
end;

{ TTMSFNCLocationFactoryService }

constructor TTMSFNCLocationFactoryService.Create;
begin
  inherited Create;
  FLocation := TTMSFNCLocationList.Create;
end;

function TTMSFNCLocationFactoryService.CreateLocation: ITMSFNCCustomLocation;
begin
  Result := DoCreateLocation;
  FLocation.Add(Result);
end;

destructor TTMSFNCLocationFactoryService.Destroy;
begin
  FreeAndNil(FLocation);
  inherited Destroy;
end;

procedure TTMSFNCLocationFactoryService.DestroyLocation(ALocation: ITMSFNCCustomLocation);
begin
  FLocation.Remove(ALocation);
end;

{ TTMSFNCLocationPlatformServices }

procedure TTMSFNCLocationPlatformServices.AddPlatformService(const AServiceGUID: TGUID; const AService: IInterface);
var
  LService: IInterface;
begin
  if not FServicesList.ContainsKey(GUIDToString(AServiceGUID)) then
  begin
    if Supports(AService, AServiceGUID, LService) then
      FServicesList.Add(TTMSFNCLocationPlatformServicesService.Create(GUIDToString(AServiceGUID), AService));
  end;
end;

constructor TTMSFNCLocationPlatformServices.Create;
begin
  inherited;
  FServicesList := TTMSFNCLocationPlatformServicesList.Create;
end;

destructor TTMSFNCLocationPlatformServices.Destroy;
begin
  FreeAndNil(FServicesList);
  inherited;
end;

{$IFNDEF AUTOREFCOUNT}
class procedure TTMSFNCLocationPlatformServices.ReleaseCurrent;
begin
  FreeAndNil(FCurrent);
  FCurrentReleased := True;
end;
{$ENDIF}

class function TTMSFNCLocationPlatformServices.Current: TTMSFNCLocationPlatformServices;
begin
  if (FCurrent = nil) and not FCurrentReleased then
    FCurrent := TTMSFNCLocationPlatformServices.Create;
  Result := FCurrent;
end;

function TTMSFNCLocationPlatformServices.GetPlatformService(const AServiceGUID: TGUID): IInterface;
var
  k: IInterface;
begin
  k := FServicesList.Interfaces[GUIDToString(AServiceGUID)];
  Supports(k, AServiceGUID, Result);
end;

procedure TTMSFNCLocationPlatformServices.RemovePlatformService(const AServiceGUID: TGUID);
begin
  FServicesList.RemoveByGUID(GUIDToString(AServiceGUID));
end;

function TTMSFNCLocationPlatformServices.SupportsPlatformService(const AServiceGUID: TGUID;
  var AService: IInterface): Boolean;
begin
  if FServicesList.ContainsKey(GUIDToString(AServiceGUID)) then
    Result := Supports(FServicesList.Interfaces[GUIDToString(AServiceGUID)], AServiceGUID, AService)
  else
  begin
    AService := nil;
    Result := False;
  end;
end;

function TTMSFNCLocationPlatformServices.SupportsPlatformService(const AServiceGUID: TGUID): Boolean;
begin
  Result := FServicesList.ContainsKey(GUIDToString(AServiceGUID));
end;

{ TTMSFNCLocationPlatformServicesService }

constructor TTMSFNCLocationPlatformServicesService.Create(AGUID: string;
  AInterface: IInterface);
begin
  FGUID := AGUID;
  FInterface := AInterface;
end;

{ TTMSFNCLocationPlatformServicesList }

function TTMSFNCLocationPlatformServicesList.ContainsKey(AGUID: string): Boolean;
var
  I: Integer;
begin
  Result := False;
  for I := 0 to Count - 1 do
  begin
    if Items[I].GUID = AGUID then
    begin
      Result := True;
      Break;
    end;
  end;
end;

function TTMSFNCLocationPlatformServicesList.GetValue(AGUID: string): IInterface;
var
  I: Integer;
begin
  Result := nil;
  for I := 0 to Count - 1 do
  begin
    if Items[I].GUID = AGUID then
    begin
      Result := Items[I].&Interface;
      Break;
    end;
  end;
end;

procedure TTMSFNCLocationPlatformServicesList.RemoveByGUID(AGUID: string);
var
  I: Integer;
begin
  for I := Count - 1 downto 0 do
  begin
    if Items[I].GUID = AGUID then
    begin
      Remove(Items[I]);
      Break;
    end;
  end;
end;

{$IFDEF WEBLIB}
function TTMSFNCLocationPlatformServicesList.GetItem(Index: Integer): TTMSFNCLocationPlatformServicesService;
begin
  Result := TTMSFNCLocationPlatformServicesService(inherited Items[Index]);
end;

procedure TTMSFNCLocationPlatformServicesList.SetItem(Index: Integer; const Value: TTMSFNCLocationPlatformServicesService);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCLocationList.GetItem(Index: Integer): ITMSFNCCustomLocation;
begin
  Result := ITMSFNCCustomLocation(inherited Items[Index]);
end;

procedure TTMSFNCLocationList.SetItem(Index: Integer; const Value: ITMSFNCCustomLocation);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

{ TTMSFNCCustomLocationInterfacedObject }

destructor TTMSFNCCustomLocationInterfacedObject.Destroy;
begin
  FLocationProperties := nil;
  inherited;
end;

function TTMSFNCCustomLocationInterfacedObject.GetLocationProperties: ITMSFNCCustomLocationProperties;
begin
  Result := FLocationProperties;
end;

procedure TTMSFNCCustomLocationInterfacedObject.SetLocationProperties(
  const Value: ITMSFNCCustomLocationProperties);
begin
  FLocationProperties := Value;
end;

{ TTMSFNCLocationRequest }

procedure TTMSFNCLocationRequest.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCLocationRequest then
  begin
    FCity := TTMSFNCLocationRequest(Source).City;
    FCountry := TTMSFNCLocationRequest(Source).Country;
    FCountryCode := TTMSFNCLocationRequest(Source).CountryCode;
    FContinent := TTMSFNCLocationRequest(Source).Continent;
    FContinentCode := TTMSFNCLocationRequest(Source).ContinentCode;
    FRegion := TTMSFNCLocationRequest(Source).Region;
    FRegionCode := TTMSFNCLocationRequest(Source).RegionCode;
    FPostalCode := TTMSFNCLocationRequest(Source).PostalCode;
    FIP := TTMSFNCLocationRequest(Source).IP;
    FIPType := TTMSFNCLocationRequest(Source).IPType;
  end;
end;

constructor TTMSFNCLocationRequest.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCLocationRequests).FOwner;

  FCoordinate := TTMSFNCMapsCoordinate.Create;
  FCity := '';
  FRegion := '';
  FRegionCode := '';
  FCountry := '';
  FCountryCode := '';
  FContinent := '';
  FContinentCode := '';
  FPostalCode := '';
  FIP := '';
  FIPType := '';
end;

destructor TTMSFNCLocationRequest.Destroy;
begin
  FCoordinate.Free;
  inherited;
end;

procedure TTMSFNCLocationRequest.SetCoordinate(
  const Value: TTMSFNCMapsCoordinate);
begin
  FCoordinate.Assign(Value);
end;

{ TTMSFNCLocationRequests }

function TTMSFNCLocationRequests.Add: TTMSFNCLocationRequest;
begin
  Result := TTMSFNCLocationRequest(inherited Add);
end;

constructor TTMSFNCLocationRequests.Create(AOwner: TTMSFNCCustomLocation);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCLocationRequests.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCLocationRequest;
end;

function TTMSFNCLocationRequests.GetItem(
  Index: Integer): TTMSFNCLocationRequest;
begin
  Result := TTMSFNCLocationRequest(inherited Items[Index]);
end;

function TTMSFNCLocationRequests.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCLocationRequests.Insert(
  Index: Integer): TTMSFNCLocationRequest;
begin
  Result := TTMSFNCLocationRequest(inherited Insert(Index));
end;

procedure TTMSFNCLocationRequests.SetItem(Index: Integer;
  const Value: TTMSFNCLocationRequest);
begin
  inherited Items[Index] := Value;
end;


{ TTMSFNCLocationCallBackWrapper }

constructor TTMSFNCLocationCallBackWrapper.Create(
  ACallback: TTMSFNCLocationGetLocationCallBack);
begin
  FCallback := ACallback;
end;

{ TTMSFNCLocation }

procedure TTMSFNCLocation.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCLocation]);
end;

{ TTMSFNCLocationResultCallBackWrapper }

constructor TTMSFNCLocationResultCallBackWrapper.Create(
  ACallback: TTMSFNCLocationGetLocationResultCallBack);
begin
  FCallback := ACallback;
end;

initialization
begin
  TTMSFNCLocationPlatformServices.FCurrentReleased := False;
  RegisterGoogleLocationService;
  RegisterIPStackLocationService;
end;

{$IFNDEF WEBLIB}
finalization
begin
  UnRegisterGoogleLocationService;
  UnRegisterIPStackLocationService;
  {$IFNDEF AUTOREFCOUNT}
  TTMSFNCLocationPlatformServices.ReleaseCurrent;
  {$ENDIF}
end;
{$ENDIF}

end.
