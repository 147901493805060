{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2021                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGeocoding.GeoApify;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterGeoApifyGeocodingService;
procedure UnRegisterGeoApifyGeocodingService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCGeocoding,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCGeocodingGeoApifyService = class;

  TTMSFNCGeocodingGeoApifyGeocodingFactoryService = class(TTMSFNCGeocodingFactoryService, ITMSFNCGeocodingServiceGeoApify);

  TTMSFNCGeocodingGeoApifyService = class(TTMSFNCGeocodingGeoApifyGeocodingFactoryService)
  protected
    function DoCreateGeocoding: ITMSFNCCustomGeocoding; override;
  end;

type
  TTMSFNCGeocodingGeoApifyGeocoding = class;

  TTMSFNCGeocodingGeoApifyGeocoding = class(TTMSFNCCustomGeocodingInterfacedObject, ITMSFNCCustomGeocoding)
  protected
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetReverseHost: string;
    function GetPath(AAddress: string): string;
    function GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
    function GetQuery(AAddress: string; ALocale: string): string; overload;
    function GetQuery(ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string; overload;
    function GetPostData: string;
    procedure Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyGeocoding;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  GeocodingService: ITMSFNCGeocodingServiceGeoApify;

procedure RegisterGeoApifyGeocodingService;
begin
  if not TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceGeoApify, IInterface(GeocodingService)) then
  begin
    GeocodingService := TTMSFNCGeocodingGeoApifyService.Create;
    TTMSFNCGeocodingPlatformServices.Current.AddPlatformService(ITMSFNCGeocodingServiceGeoApify, GeocodingService);
  end;
end;

procedure UnregisterGeoApifyGeocodingService;
begin
  TTMSFNCGeocodingPlatformServices.Current.RemovePlatformService(ITMSFNCGeocodingServiceGeoApify);
end;

{ TTMSFNCGeocodingGeoApifyService }

function TTMSFNCGeocodingGeoApifyService.DoCreateGeocoding: ITMSFNCCustomGeocoding;
begin
  Result := TTMSFNCGeocodingGeoApifyGeocoding.Create;
end;

procedure TTMSFNCGeocodingGeoApifyGeocoding.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCGeocodingGeoApifyGeocoding.Create;
begin
  inherited;
end;

destructor TTMSFNCGeocodingGeoApifyGeocoding.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGeocodingGeoApifyGeocoding.DestroyGeocoding;
begin
  GeocodingService.DestroyGeocoding(Self);
end;

function TTMSFNCGeocodingGeoApifyGeocoding.GetHost: string;
begin
  Result := 'https://api.geoapify.com';
end;

function TTMSFNCGeocodingGeoApifyGeocoding.GetReverseHost: string;
begin
  Result := GetHost;
end;

function TTMSFNCGeocodingGeoApifyGeocoding.GetIdentifier: string;
begin
  Result := 'GeoApify';
end;

function TTMSFNCGeocodingGeoApifyGeocoding.GetPath(AAddress: string): string;
begin
  Result := '/v1/geocode/search';
end;

function TTMSFNCGeocodingGeoApifyGeocoding.GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
begin
  Result := '/v1/geocode/reverse';
end;

function TTMSFNCGeocodingGeoApifyGeocoding.GetPostData: string;
begin
  Result := '';
end;

function TTMSFNCGeocodingGeoApifyGeocoding.GetQuery(
  ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string;
begin
  Result := 'apiKey=' + GeocodingProperties.GetAPIKey
    + '&lat=' + FloatToStrDot(ACoordinate.Latitude)
    + '&lon=' + FloatToStrDot(ACoordinate.Longitude);
end;

function TTMSFNCGeocodingGeoApifyGeocoding.GetQuery(AAddress: string; ALocale: string): string;
begin
  Result := 'apiKey=' + GeocodingProperties.GetAPIKey
    + '&text=' + AAddress;
end;

function TTMSFNCGeocodingGeoApifyGeocoding.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCGeocodingGeoApifyGeocoding.IsValid: Boolean;
begin
  Result := GeocodingProperties.GetAPIKey <> '';
end;

procedure TTMSFNCGeocodingGeoApifyGeocoding.Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
var
  d: TTMSFNCGeocodingItems;
  di: TTMSFNCGeocodingItem;

  o, jo, joar: TJSONValue;
  jar, jab: TJSONArray;
  i, j: Integer;
  ob, oc: TJSONObject;
  street, number: string;
begin
  if Assigned(GeocodingProperties) then
  begin
    d := ARequest.Items;

    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'error');
            ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(o, 'message');

            jar := TTMSFNCUtils.GetJSONValue(o, 'features') as TJSONArray;
            if Assigned(jar) then
            begin
              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
              begin
                jo := TTMSFNCUtils.GetJSONArrayItem(jar, i);

                di := d.Add;

                oc := TTMSFNCUtils.GetJSONValue(jo, 'properties') as TJSONObject;
                if Assigned(oc) then
                begin
                  di.Address := TTMSFNCUtils.GetJSONProp(oc, 'formatted');

                  street := TTMSFNCUtils.GetJSONProp(oc, 'street');
                  if street = '' then
                    street := TTMSFNCUtils.GetJSONProp(oc, 'name');

                  number := TTMSFNCUtils.GetJSONProp(oc, 'housenumber');

                  di.Street := Trim(street + ' ' + number);
                  di.StreetName := street;
                  di.StreetNumber := number;

                  di.PostalCode := TTMSFNCUtils.GetJSONProp(oc, 'postcode');
                  di.City := TTMSFNCUtils.GetJSONProp(oc, 'city');
                  di.Region := TTMSFNCUtils.GetJSONProp(oc, 'county');
                  if di.Region = '' then
                    di.Region := TTMSFNCUtils.GetJSONProp(oc, 'district');
                  if di.Region = '' then
                    di.Region := TTMSFNCUtils.GetJSONProp(oc, 'suburb');
                  di.Province := TTMSFNCUtils.GetJSONProp(oc, 'state');
                  di.ProvinceCode := TTMSFNCUtils.GetJSONProp(oc, 'state_code');
                  di.Country := TTMSFNCUtils.GetJSONProp(oc, 'country');
                  di.CountryCode := TTMSFNCUtils.GetJSONProp(oc, 'country_code');
                end;

                ob := TTMSFNCUtils.GetJSONValue(jo, 'geometry') as TJSONObject;
                if Assigned(ob) then
                begin
                  jab := TTMSFNCUtils.GetJSONValue(ob, 'coordinates') as TJSONArray;
                  if Assigned(jar) then
                  begin
                    for j := 0 to TTMSFNCUtils.GetJSONArraySize(jab) - 1 do
                    begin
                      joar := TTMSFNCUtils.GetJSONArrayItem(jab, j);
                      if j = 0 then
                        di.Coordinate.Longitude := StrToFloatDot(joar.Value);
                      if j = 1 then
                        di.Coordinate.Latitude := StrToFloatDot(joar.Value);
                    end;
                  end;
                end;
              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.
