{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCMaps.GoogleMaps;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

const
  GOOGLEMAPSAPIURL = 'https://maps.googleapis.com/maps/api/';
  MAPSERVICEVAR = 'window.google.maps';
  MAPCALCLAYER = 'calclayer';

procedure RegisterGoogleMapsService;
procedure UnRegisterGoogleMapsService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCMaps, WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCMapsCommonTypes
  {$IFDEF WEBLIB}
  ,Contnrs, Web
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCMapsGoogleMapsService = class;

  TTMSFNCMapsGoogleMapsFactoryService = class(TTMSFNCMapsFactoryService, ITMSFNCMapsServiceGoogleMaps);

  TTMSFNCMapsGoogleMapsService = class(TTMSFNCMapsGoogleMapsFactoryService)
  protected
    function DoCreateMaps: ITMSFNCCustomMaps; override;
  end;

type
  TTMSFNCMapsGoogleMaps = class;

  TTMSFNCMapsGoogleMaps = class(TTMSFNCCustomMapsInterfacedObject, ITMSFNCCustomMaps)
  protected
    procedure GetHeadLinks(const AList: TTMSFNCMapsLinksList);
    procedure RemoveScripts;
    function GetResetMap: string;
    function GetShowPopup: string;
    function GetClosePopup: string;
    function GetHeadStyle: string;
    function GetDelayLoadEvent: string;
    function GetMapsServiceCheck: string;
    function GetIdentifier: string;
    function GetInitializeMap: string;
    function GetInitializeEvents: string;
    function GetAddOrUpdateMarker: string;
    function GetDeleteMarker: string;
    function GetAddOrUpdatePolyElement: string;
    function GetDeletePolyElement: string;
    function GetZoomToBounds: string;
    function GetGetCenterCoordinate: string;
    function GetGetBounds: string;
    function GetGetZoomLevel: string;
    function GetLatLonToXY: string;
    function GetXYToLatLon: string;
    function GetSetCenterCoordinate: string;
    function GetSetZoomLevel: string;
    function GetUpdateOptions: string;
    function GetGlobalVariables: string;
    function GetAddCoordinateToArray: string;
    function GetAddHoleToArray: string;
    function GetInitializeCoordinateArray: string;
    function GetInitializeHolesArray: string;
    function IsValid: Boolean;
    function GetService: TTMSFNCMapsService;
    procedure DestroyMaps;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  MapsService: ITMSFNCMapsServiceGoogleMaps;

procedure RegisterGoogleMapsService;
begin
  if not TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceGoogleMaps, IInterface(MapsService)) then
  begin
    MapsService := TTMSFNCMapsGoogleMapsService.Create;
    TTMSFNCMapsPlatformServices.Current.AddPlatformService(ITMSFNCMapsServiceGoogleMaps, MapsService);
  end;
end;

procedure UnregisterGoogleMapsService;
begin
  TTMSFNCMapsPlatformServices.Current.RemovePlatformService(ITMSFNCMapsServiceGoogleMaps);
end;

{ TTMSFNCMapsGoogleMapsService }

function TTMSFNCMapsGoogleMapsService.DoCreateMaps: ITMSFNCCustomMaps;
begin
  Result := TTMSFNCMapsGoogleMaps.Create;
end;

constructor TTMSFNCMapsGoogleMaps.Create;
begin
  inherited;
end;

destructor TTMSFNCMapsGoogleMaps.Destroy;
begin
  inherited;
end;

function TTMSFNCMapsGoogleMaps.GetMapsServiceCheck: string;
begin
  Result := '!window.google || !' + MAPSERVICEVAR;
end;

function TTMSFNCMapsGoogleMaps.GetResetMap: string;
begin
  Result := '';
end;

function TTMSFNCMapsGoogleMaps.GetService: TTMSFNCMapsService;
begin
  Result := msGoogleMaps;
end;

function TTMSFNCMapsGoogleMaps.GetSetCenterCoordinate: string;
begin
  Result :=
    '  var lat = new ' + MAPSERVICEVAR + '.LatLng(' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"]);' + LB +
    '  ' + MAPVAR + '.panTo(lat);';
end;

function TTMSFNCMapsGoogleMaps.GetSetZoomLevel: string;
begin
  Result :=
    '  ' + MAPVAR + '.setZoom(' + PARAMSNAME + ');';
end;

function TTMSFNCMapsGoogleMaps.GetShowPopup: string;
begin
  Result :=
  '  var lat = new ' + MAPSERVICEVAR + '.LatLng(' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"]);' + LB +
  '  ' + POPUPVAR + ' = new ' + MAPSERVICEVAR + '.InfoWindow({' + LB +
  '    position: lat,' + LB +
  '    pixelOffset: new ' + MAPSERVICEVAR + '.Size(' + PARAMSNAME + '["OffsetX"], ' + PARAMSNAME + '["OffsetY"]),' + LB +
  '    content: ' + PARAMSNAME + '["Text"],' + LB +
  '    disableAutoPan: !' + PARAMSNAME + '["PanMap"],' + LB +
  '  });' + LB +
  '  ' + POPUPVAR + '.open(' + MAPVAR + ');';
end;

function TTMSFNCMapsGoogleMaps.GetUpdateOptions: string;
begin
  Result :=
    '  var opt = {' + LB +
    '    draggable: ' + PARAMSNAME + '["Panning"],' + LB +
    '    scrollwheel: ' + PARAMSNAME + '["ZoomOnWheelScroll"],' + LB +
    '    disableDoubleClickZoom: !' + PARAMSNAME + '["ZoomOnDblClick"],' + LB +
    '    mapTypeControl: ' + PARAMSNAME + '["ShowMapTypeControl"],' + LB +
    '    zoomControl: ' + PARAMSNAME + '["ShowZoomControl"],' + LB +
    '    tilt: ' + PARAMSNAME + '["Tilt"],' + LB +
    '    heading: ' + PARAMSNAME + '["Heading"],' + LB +
    '  }' + LB +
    '  ' + MAPVAR + '.setOptions(opt);';
end;

function TTMSFNCMapsGoogleMaps.GetZoomToBounds: string;
begin
  Result :=
    '  var sw = new ' + MAPSERVICEVAR + '.LatLng(' + PARAMSNAME + '["SouthWest"]["Latitude"], ' + PARAMSNAME + '["SouthWest"]["Longitude"]);' + LB +
    '  var ne = new ' + MAPSERVICEVAR + '.LatLng(' + PARAMSNAME + '["NorthEast"]["Latitude"], ' + PARAMSNAME + '["NorthEast"]["Longitude"]);' + LB +
    '  ' + MAPVAR + '.fitBounds(new ' + MAPSERVICEVAR + '.LatLngBounds(sw, ne));';
end;

procedure TTMSFNCMapsGoogleMaps.DestroyMaps;
begin
  MapsService.DestroyMaps(Self);
end;

function TTMSFNCMapsGoogleMaps.GetAddCoordinateToArray: string;
begin
  Result := '  ' + COORDINATEARRAYVAR + '.push({lat: ' + PARAMSNAME + '["Latitude"], lng: ' + PARAMSNAME + '["Longitude"]});';
end;

function TTMSFNCMapsGoogleMaps.GetAddHoleToArray: string;
begin
  Result := 'var o = ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]];' + LB +
            'if (o){' + LB +
            '  ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]].push({lat: ' + PARAMSNAME + '[1]["Latitude"], lng: ' + PARAMSNAME + '[1]["Longitude"]});' + LB +
            '}else{' + LB +
            '  ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]] = [{lat: ' + PARAMSNAME + '[1]["Latitude"], lng: ' + PARAMSNAME + '[1]["Longitude"]}];' + LB +
            '}';
end;

function TTMSFNCMapsGoogleMaps.GetAddOrUpdateMarker: string;
begin
  Result :=
    '  var animation; ' + LB +
    '  if (' + PARAMSNAME + '["Animation"]){ animation = ' + MAPSERVICEVAR + '.Animation.BOUNCE } else { animation = null }' + LB +
    '  var ic;' + LB +
    '  var sz = null;' + LB +
    '  if (!(typeof ' + PARAMSNAME + '["DefaultIconSize"] == "undefined")) {' + LB +
    '    if (!' + PARAMSNAME + '["DefaultIconSize"]) {' + LB +
    '      sz = new ' + MAPSERVICEVAR + '.Size(' + PARAMSNAME + '["IconWidth"], ' + PARAMSNAME + '["IconHeight"])' + LB +
    '    }' + LB +
    '  }' + LB +
    '  if (' + PARAMSNAME + '["IconURL"] != "" && ' + PARAMSNAME + '["Anchor"] && !' + PARAMSNAME + '["DefaultAnchor"]){' + LB +
    '    ic = {' + LB +
    '      url: ' + PARAMSNAME + '["IconURL"],' + LB +
    '      anchor: new ' + MAPSERVICEVAR + '.Point(' + PARAMSNAME + '["Anchor"]["X"], ' + PARAMSNAME + '["Anchor"]["Y"]),' + LB +
    '      scaledSize: sz' + LB +
    '    }' + LB +
    '  }' + LB +
    '  else if (' + PARAMSNAME + '["IconURL"] != ""){' + LB +
    '    ic = {' + LB +
    '      url: ' + PARAMSNAME + '["IconURL"],' + LB +
    '      scaledSize: sz' + LB +
    '    }' + LB +
    '  }' + LB +
    '  else{' + LB +
    '    ic = null;' + LB +
    '  }' + LB + LB +

    '  var options;' + LB +
    '  if (ic != null) {' + LB +
    '    options = {' + LB +
    '      position: new ' + MAPSERVICEVAR + '.LatLng(' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"]),' + LB +
    '      title: ' + PARAMSNAME + '["Title"],' + LB +
    '      icon: ic,' + LB +
    '      visible: ' + PARAMSNAME + '["Visible"],' + LB +
    '      clickable: ' + PARAMSNAME + '["Clickable"],' + LB +
    '      zIndex: ' + PARAMSNAME + '["ZIndex"],' + LB +
    '      draggable: ' + PARAMSNAME + '["Draggable"],' + LB +
    '      animation: animation,' + LB +
    '      map: ' + MAPVAR + LB +
    '    }' + LB +
    '  }else{' + LB +
    '    options = {' + LB +
    '      position: new ' + MAPSERVICEVAR + '.LatLng(' + PARAMSNAME + '["Latitude"], ' + PARAMSNAME + '["Longitude"]),' + LB +
    '      title: ' + PARAMSNAME + '["Title"],' + LB +
    '      visible: ' + PARAMSNAME + '["Visible"],' + LB +
    '      clickable: ' + PARAMSNAME + '["Clickable"],' + LB +
    '      zIndex: ' + PARAMSNAME + '["ZIndex"],' + LB +
    '      draggable: ' + PARAMSNAME + '["Draggable"],' + LB +
    '      animation: animation,' + LB +
    '      map: ' + MAPVAR + LB +
    '    }' + LB +
    '  }' + LB + LB +

    '  if (!' + MARKERVAR + '){' + LB +
    '    ' + MARKERVAR + ' = new ' + MAPSERVICEVAR + '.Marker(options);' + LB +

    '    ' + MARKERVAR + '.addListener(''click'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.addListener(''rightclick'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerRightClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.addListener(''dblclick'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.addListener(''mouseup'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.addListener(''mousedown'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.addListener(''mouseout'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + MARKERVAR + '.addListener(''mouseover'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +

    '    ' + MARKERVAR + '.addListener(''dragend'', function(event){' + LB +
    '      var ptmarker = this.getPosition()' + LB +
    '      var lat = parseFloat(ptmarker.lat());' + LB +
    '      var lng = parseFloat(ptmarker.lng());' + LB +
    '      var jsonObj = getDefaultCoordinateObject();' + LB +
    '      jsonObj["Latitude"] = lat;' + LB +
    '      jsonObj["Longitude"] = lng;' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerDragEnd", ' + PARAMSNAME + '["ID"]), jsonObj);' + LB +
    '    })' + LB + LB +

    '  }else{' + LB +
    '    ' + MARKERVAR + '.setOptions(options);' + LB +
    '  }';
end;

function TTMSFNCMapsGoogleMaps.GetAddOrUpdatePolyElement: string;
begin
  Result :=
    '  var c = ' + PARAMSNAME + '["$type"];' + LB +
    '  var options;'+ LB +
    '  var symbols = null;'+ LB +

    '  if (!(typeof ' + PARAMSNAME + '["Symbols"] == "undefined")) {' + LB +
    '    symbols = [];' + LB +
    '    for (I = 0; I < ' + PARAMSNAME + '["Symbols"].length; I++){' + LB +
    '      sstrokecolor = ' + PARAMSNAME + '["Symbols"][I]["StrokeColor"];' + LB +
    '      if (sstrokecolor == "gcNull")' + LB +
    '        sstrokecolor = null;' + LB +
    '      sstrokeopacity = ' + PARAMSNAME + '["Symbols"][I]["StrokeOpacity"];' + LB +
    '      if (sstrokeopacity == 0)' + LB +
    '        sstrokeopacity = null;' + LB +
    '      sstrokewidth = ' + PARAMSNAME + '["Symbols"][I]["StrokeWidth"];' + LB +
    '      if (sstrokewidth == 0)' + LB +
    '        sstrokewidth = null;' + LB +
    '      sfillcolor = ' + PARAMSNAME + '["Symbols"][I]["FillColor"];' + LB +
    '      if (sfillcolor == "gcNull")' + LB +
    '        sfillcolor = null;' + LB +
    '      sfillopacity = ' + PARAMSNAME + '["Symbols"][I]["FillOpacity"];' + LB +
    '      if (sfillopacity == 0)' + LB +
    '        sfillopacity = null;' + LB +
    '      sscale = ' + PARAMSNAME + '["Symbols"][I]["Scale"];' + LB +
    '      if (sscale == 0)' + LB +
    '        sscale = null;' + LB +
    '      srotation = ' + PARAMSNAME + '["Symbols"][I]["Rotation"];' + LB +
    '      if (srotation == 0)' + LB +
    '        srotation = null;' + LB +
    '      srepeat = ' + PARAMSNAME + '["Symbols"][I]["RepeatSymbol"];' + LB +
    '      if (' + PARAMSNAME + '["Symbols"][I]["RepeatSymbolUnits"] == 0)' + LB +
    '        srepeat += "%";' + LB +
    '      else if ((' + PARAMSNAME + '["Symbols"][I]["RepeatSymbolUnits"] == 1) && (srepeat != 0))' + LB +
    '        srepeat += "px";' + LB +
    '      soffset = ' + PARAMSNAME + '["Symbols"][I]["Offset"];' + LB +
    '      if (' + PARAMSNAME + '["Symbols"][I]["OffsetUnits"] == 0)' + LB +
    '        soffset += "%";' + LB +
    '      else if ((' + PARAMSNAME + '["Symbols"][I]["OffsetUnits"] == 1) && (soffset != 0))' + LB +
    '        soffset += "px";' + LB +
    '      spath = google.maps.SymbolPath.FORWARD_OPEN_ARROW;' + LB +
    '      switch (' + PARAMSNAME + '["Symbols"][I]["Path"]) {' + LB +
    '        case 0:' + LB +
    '          spath = google.maps.SymbolPath.BACKWARD_CLOSED_ARROW;' + LB +
    '          break;' + LB +
    '        case 1:' + LB +
    '          spath = google.maps.SymbolPath.BACKWARD_OPEN_ARROW;' + LB +
    '          break;' + LB +
    '        case 2:' + LB +
    '          spath = google.maps.SymbolPath.CIRCLE;' + LB +
    '          break;' + LB +
    '        case 3:' + LB +
    '          spath = google.maps.SymbolPath.FORWARD_CLOSED_ARROW;' + LB +
    '          break;' + LB +
    '        case 5:' + LB +
    '          spath = ' + PARAMSNAME + '["Symbols"][I]["CustomPath"];' + LB +
    '          break;' + LB +
    '      }' + LB + LB +
    '      symbols.push({ icon: { path: spath,' + LB +
    '          scale: sscale, ' + LB +
    '          strokeColor: sstrokecolor, ' + LB +
    '          strokeOpacity: sstrokeopacity, ' + LB +
    '          strokeWeight: sstrokewidth,' + LB +
    '          fillColor: sfillcolor,' + LB +
    '          fillOpacity: sfillopacity,' + LB +
    '          rotation: srotation,' + LB +
    '        }, ' + LB +
    '        repeat: srepeat, offset: soffset, fixedRotation: false' + LB +
    '      });' + LB +
    '    }' + LB + LB +
    '  }' + LB + LB +

    '  function getJsonArray(path){' + LB +
    '    var jsonArray = [];' + LB +
    '    for (var i = 0; i < path.getLength(); i++) {' + LB +
    '      var jsonObj = getDefaultCoordinateObject();' + LB +
    '      jsonObj["Latitude"] = parseFloat(path.getAt(i).lat());' + LB +
    '      jsonObj["Longitude"] = parseFloat(path.getAt(i).lng());' + LB +
    '      jsonArray.push(jsonObj);' + LB +
    '    }' + LB +
    '    return jsonArray;' + LB +
    '  }' + LB + LB +

    '  function getJsonBounds(ctrl){' + LB +
    '    var bounds = ctrl.getBounds();' + LB +
    '    var jsonBounds = getDefaultBoundsObject();' + LB +
    '    jsonBounds["NorthEast"]["Latitude"] = bounds.getNorthEast().lat();' + LB +
    '    jsonBounds["NorthEast"]["Longitude"] = bounds.getNorthEast().lng();' + LB +
    '    jsonBounds["SouthWest"]["Latitude"] = bounds.getSouthWest().lat();' + LB +
    '    jsonBounds["SouthWest"]["Longitude"] = bounds.getSouthWest().lng();' + LB +
    '    return jsonBounds;' + LB +
    '  }' + LB + LB +

    '  function getJsonCircle(ctrl){' + LB +
    '    var center = ctrl.getCenter();' + LB +
    '    var jsonCircle = getDefaultCircleObject();' + LB +
    '    jsonCircle["Radius"] = ctrl.getRadius();' + LB +
    '    jsonCircle["Center"]["Latitude"] = center.lat();' + LB +
    '    jsonCircle["Center"]["Longitude"] = center.lng();' + LB +
    '    return jsonCircle;' + LB +
    '  }' + LB + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '    case "TTMSFNCGoogleMapsCircle":' + LB +
    '      options = {' + LB +
    '        center: {lat: ' + PARAMSNAME + '["Center"]["Latitude"], lng: ' + PARAMSNAME + '["Center"]["Longitude"]},' + LB +
    '        fillColor: ' + PARAMSNAME + '["FillColor"],' + LB +
    '        fillOpacity: ' + PARAMSNAME + '["FillOpacity"],' + LB +
    '        strokeColor: ' + PARAMSNAME + '["StrokeColor"],' + LB +
    '        strokeOpacity: ' + PARAMSNAME + '["StrokeOpacity"],' + LB +
    '        strokeWeight: ' + PARAMSNAME + '["StrokeWidth"],' + LB +
    '        radius: ' + PARAMSNAME + '["Radius"],' + LB +
    '        visible: ' + PARAMSNAME + '["Visible"],' + LB +
    '        draggable: ' + PARAMSNAME + '["Draggable"],' + LB +
    '        clickable: ' + PARAMSNAME + '["Clickable"],' + LB +
    '        zIndex: ' + PARAMSNAME + '["ZIndex"],' + LB +
    '        editable: ' + PARAMSNAME + '["Editable"],' + LB +
    '        map: ' + MAPVAR + LB +
    '      };' + LB + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '    case "TTMSFNCGoogleMapsRectangle":' + LB +
    '      options = {' + LB +
    '        fillColor: ' + PARAMSNAME + '["FillColor"],' + LB +
    '        fillOpacity: ' + PARAMSNAME + '["FillOpacity"],' + LB +
    '        strokeColor: ' + PARAMSNAME + '["StrokeColor"],' + LB +
    '        strokeOpacity: ' + PARAMSNAME + '["StrokeOpacity"],' + LB +
    '        strokeWeight: ' + PARAMSNAME + '["StrokeWidth"],' + LB +
    '        draggable: ' + PARAMSNAME + '["Draggable"],' + LB +
    '        visible: ' + PARAMSNAME + '["Visible"],' + LB +
    '        clickable: ' + PARAMSNAME + '["Clickable"],' + LB +
    '        zIndex: ' + PARAMSNAME + '["ZIndex"],' + LB +
    '        editable: ' + PARAMSNAME + '["Editable"],' + LB +
    '        bounds: {' + LB +
    '          north: ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"],' + LB +
    '          south: ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"],' + LB +
    '          east: ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"],' + LB +
    '          west: ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"],' + LB +
    '        },' + LB +
    '        map: ' + MAPVAR + LB +
    '      };' + LB + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsPolygon":' + LB +
    '    case "TTMSFNCGoogleMapsPolygon":' + LB +

    '      var pthArr = [];' + LB +
    '      pthArr.push(' + COORDINATEARRAYVAR + ');' + LB +
    '      for (var key in ' + HOLEARRAYVAR + '){' + LB +
    '        pthArr.push(' + HOLEARRAYVAR + '[key]);' + LB +
    '      }' + LB +

    '      options = {' + LB +
    '        icons: symbols,' + LB +
    '        paths: pthArr,' + LB +
    '        fillColor: ' + PARAMSNAME + '["FillColor"],' + LB +
    '        fillOpacity: ' + PARAMSNAME + '["FillOpacity"],' + LB +
    '        strokeColor: ' + PARAMSNAME + '["StrokeColor"],' + LB +
    '        strokeOpacity: ' + PARAMSNAME + '["StrokeOpacity"],' + LB +
    '        strokeWeight: ' + PARAMSNAME + '["StrokeWidth"],' + LB +
    '        draggable: ' + PARAMSNAME + '["Draggable"],' + LB +
    '        visible: ' + PARAMSNAME + '["Visible"],' + LB +
    '        clickable: ' + PARAMSNAME + '["Clickable"],' + LB +
    '        zIndex: ' + PARAMSNAME + '["ZIndex"],' + LB +
    '        editable: ' + PARAMSNAME + '["Editable"],' + LB +
    '        geodesic: ' + PARAMSNAME + '["Geodesic"],' + LB +
    '        map: ' + MAPVAR + LB +
    '      };' + LB + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsPolyline":' + LB +
    '    case "TTMSFNCGoogleMapsPolyline":' + LB +

    '      options = {' + LB +
    '        icons: symbols,' + LB +
    '        path: ' + COORDINATEARRAYVAR + ',' + LB +
    '        fillColor: ' + PARAMSNAME + '["FillColor"],' + LB +
    '        fillOpacity: ' + PARAMSNAME + '["FillOpacity"],' + LB +
    '        strokeColor: ' + PARAMSNAME + '["StrokeColor"],' + LB +
    '        strokeOpacity: ' + PARAMSNAME + '["StrokeOpacity"],' + LB +
    '        strokeWeight: ' + PARAMSNAME + '["StrokeWidth"],' + LB +
    '        draggable: ' + PARAMSNAME + '["Draggable"],' + LB +
    '        visible: ' + PARAMSNAME + '["Visible"],' + LB +
    '        clickable: ' + PARAMSNAME + '["Clickable"],' + LB +
    '        zIndex: ' + PARAMSNAME + '["ZIndex"],' + LB +
    '        editable: ' + PARAMSNAME + '["Editable"],' + LB +
    '        geodesic: ' + PARAMSNAME + '["Geodesic"],' + LB +
    '        map: ' + MAPVAR + LB +
    '      };' + LB + LB +
    '    break;' + LB +
    '  }' + LB + LB +

    '  if (!' + POLYELEMENTVAR + '){' + LB +
    '    switch(c){' + LB +
    '      case "TTMSFNCMapsCircle":' + LB +
    '      case "TTMSFNCGoogleMapsCircle":' + LB +
    '        ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.Circle(options);' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsRectangle":' + LB +
    '      case "TTMSFNCGoogleMapsRectangle":' + LB +
    '        ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.Rectangle(options);' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsPolyline":' + LB +
    '      case "TTMSFNCGoogleMapsPolyline":' + LB +
    '        ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.Polyline(options);' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsPolygon":' + LB +
    '      case "TTMSFNCGoogleMapsPolygon":' + LB +
    '        ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.Polygon(options);' + LB +
    '      break;' + LB +
    '    }' + LB +

    '    ' + POLYELEMENTVAR + '.addListener(''click'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.addListener(''rightclick'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementRightClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.addListener(''dblclick'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.addListener(''mouseup'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.addListener(''mousedown'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.addListener(''mouseout'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +
    '    ' + POLYELEMENTVAR + '.addListener(''mouseover'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +

    '    ' + POLYELEMENTVAR + '.addListener(''dragend'', function(event){' + LB +
    '    switch(c){' + LB +
    '      case "TTMSFNCGoogleMapsPolyline":' + LB +
    '      case "TTMSFNCGoogleMapsPolygon":' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementDragEnd", ' + PARAMSNAME + '["ID"]), getJsonArray(this.getPath()));' + LB +
    '      break;' + LB +
    '      case "TTMSFNCGoogleMapsRectangle":' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementDragEnd", ' + PARAMSNAME + '["ID"]), getJsonBounds(this));' + LB +
    '      break;' + LB +
    '      case "TTMSFNCGoogleMapsCircle":' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementDragEnd", ' + PARAMSNAME + '["ID"]), getJsonCircle(this));' + LB +
    '      break;' + LB +
    '    }' + LB + LB +
    '    });' + LB + LB +

    '    switch(c){' + LB +
    '      case "TTMSFNCGoogleMapsRectangle":' + LB +
    '        ' + POLYELEMENTVAR + '.addListener(''bounds_changed'', function(event){' + LB +
    '          ' + GETSENDEVENT + '(parseEvent(event, "PolyElementEditEnd", ' + PARAMSNAME + '["ID"]), getJsonBounds(this));' + LB +
    '        });' + LB + LB +
    '      break;' + LB +
    '      case "TTMSFNCGoogleMapsCircle":' + LB +
    '        ' + POLYELEMENTVAR + '.addListener(''radius_changed'', function(event){' + LB +
    '          ' + GETSENDEVENT + '(parseEvent(event, "PolyElementEditEnd", ' + PARAMSNAME + '["ID"]), getJsonCircle(this));' + LB +
    '        });' + LB + LB +
    '        ' + POLYELEMENTVAR + '.addListener(''center_changed'', function(event){' + LB +
    '          ' + GETSENDEVENT + '(parseEvent(event, "PolyElementEditEnd", ' + PARAMSNAME + '["ID"]), getJsonCircle(this));' + LB +
    '        });' + LB + LB +
    '      break;' + LB +
    '      }' + LB + LB +

    '  }else{' + LB +
    '    ' + POLYELEMENTVAR + '.setOptions(options);' + LB +
    '  }' + LB + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCGoogleMapsPolyline":' + LB +
    '    case "TTMSFNCGoogleMapsPolygon":' + LB +
    '      ' + POLYELEMENTVAR + '.getPath().addListener(''set_at'', function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementEditEnd", ' + PARAMSNAME + '["ID"]), getJsonArray(this));' + LB +
    '      });' + LB + LB +
    '      ' + POLYELEMENTVAR + '.getPath().addListener(''insert_at'', function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementEditEnd", ' + PARAMSNAME + '["ID"]), getJsonArray(this));' + LB +
    '      });' + LB + LB +
    '      ' + POLYELEMENTVAR + '.getPath().addListener(''remove_at'', function(event){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementEditEnd", ' + PARAMSNAME + '["ID"]), getJsonArray(this));' + LB +
    '      });' + LB + LB +
    '    break;' + LB +
    '  }';
end;

function TTMSFNCMapsGoogleMaps.GetClosePopup: string;
begin
  Result := '  ' + POPUPVAR + '.close();';
end;

function TTMSFNCMapsGoogleMaps.GetDelayLoadEvent: string;
begin
  Result := '';
end;

function TTMSFNCMapsGoogleMaps.GetDeleteMarker: string;
begin
  Result := '  ' + MARKERVAR + '.setMap(null)';
end;

function TTMSFNCMapsGoogleMaps.GetDeletePolyElement: string;
begin
  Result := '  ' + POLYELEMENTVAR + '.setMap(null)';
end;

function TTMSFNCMapsGoogleMaps.GetGetCenterCoordinate: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getCenter();' + LB +
    '  jsonObj["Latitude"] = loc.lat();' + LB +
    '  jsonObj["Longitude"] = loc.lng();';
end;

function TTMSFNCMapsGoogleMaps.GetGetBounds: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getBounds();' + LB +
    '  jsonObj["NorthEast"]["Latitude"] = loc.getNorthEast().lat();' + LB +
    '  jsonObj["NorthEast"]["Longitude"] = loc.getNorthEast().lng();' + LB +
    '  jsonObj["SouthWest"]["Latitude"] = loc.getSouthWest().lat();' + LB +
    '  jsonObj["SouthWest"]["Longitude"] = loc.getSouthWest().lng();';
end;

function TTMSFNCMapsGoogleMaps.GetGetZoomLevel: string;
begin
  Result := '  var z = ' + MAPVAR + '.getZoom();';
end;

function TTMSFNCMapsGoogleMaps.GetGlobalVariables: string;
begin
   Result :=
    'function parseEvent(event, eventname, id = ''''){' + LB +
    '  var loc = {''Latitude'': 0, ''Longitude'': 0};' + LB +
    '  var x = 0.0;' + LB +
    '  var y = 0.0;' + LB +
    '  if ((eventname == "MapMoveStart") || (eventname == "MapMoveEnd")){ ' +
    '    var ptcenter = ' + MAPVAR + '.getCenter();' + LB +
    '    var lat = ptcenter.lat();' + LB +
    '    var lng = ptcenter.lng();' + LB +
    '    loc = {''Latitude'': lat, ''Longitude'': lng};' + LB +
    '  } else if (event) {' + LB +
    '    if (event.latLng){' + LB +
    '      loc = {''Latitude'': event.latLng.lat(), ''Longitude'': event.latLng.lng()};' + LB +
    '    }' + LB +
    '    if (event.pixel){' + LB +
    '      x = event.pixel.x;' + LB +
    '      y = event.pixel.y;' + LB +
    '    }' + LB +
    '  }' + LB +

    '  var r = {''Coordinate'': loc, ''X'': x, ''Y'': y, ''ID'': id, ''EventName'': eventname};' + LB +
    '  return r;' + LB +
    '}';
end;

procedure TTMSFNCMapsGoogleMaps.GetHeadLinks(
  const AList: TTMSFNCMapsLinksList);
var
  APIVersion: string;
begin
  if MapsProperties.GetAPIVersion = '' then
    APIVersion := '&v=weekly'
  else
    APIVersion := '&v=' + MapsProperties.GetAPIVersion;

  AList.Add(TTMSFNCMapsLink.CreateScript(GOOGLEMAPSAPIURL + 'js?key=' + MapsProperties.GetAPIKey + APIVersion + '&language=' + MapsProperties.GetLocale, 'text/javascript', 'utf-8', '', True, True));
end;

function TTMSFNCMapsGoogleMaps.GetHeadStyle: string;
begin
  Result := '';
end;

function TTMSFNCMapsGoogleMaps.GetIdentifier: string;
begin
  Result := 'Google Maps';
end;

function TTMSFNCMapsGoogleMaps.GetInitializeCoordinateArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsGoogleMaps.GetInitializeHolesArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsGoogleMaps.GetInitializeEvents: string;
begin
  Result :=
  '  ' + MAPVAR + '.addListener(''zoom_changed'', function(){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(null, "ZoomChanged"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addListener(''maptypeid_changed'', function(){' + LB +
  '    var mapType = JSON.stringify(' + MAPVAR + '.getMapTypeId()).replace(/"/g, "");' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(null, "MapTypeChanged"), mapType);' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(null, "UpdateMapType"), mapType);' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addListener(''dragstart'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMoveStart"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addListener(''dragend'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMoveEnd"));' + LB +
  '  })' + LB + LB +
//not implemented yet
//  '  ' + MAPVAR + '.addListener(''drag'', function(event){' + LB +
//  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMove"));' + LB +
//  '  })' + LB + LB +
  '  ' + MAPVAR + '.addListener(''idle'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapIdle"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addListener(''click'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapClick"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addListener(''rightclick'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapRightClick"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addListener(''dblclick'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapDblClick"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addListener(''mouseup'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseUp"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addListener(''mousedown'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseDown"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addListener(''mouseout'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseLeave"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addListener(''mouseover'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseEnter"));' + LB +
  '  })' + LB + LB +
  '  ' + MAPVAR + '.addListener(''mousemove'', function(event){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(event, "MapMouseMove"));' + LB +
  '  })';
end;

function TTMSFNCMapsGoogleMaps.GetInitializeMap: string;
begin
  Result :=
    '  ' + MAPOPTIONS + ' = {' + LB +
    '    draggable: ' + LowerCase(BoolToStr(MapsProperties.GetPanning, True)) + ',' + LB +
    '    scrollwheel: ' + LowerCase(BoolToStr(MapsProperties.GetZoomOnWheelScroll, True)) + ',' + LB +
    '    disableDoubleClickZoom: ' + LowerCase(BoolToStr(not MapsProperties.GetZoomOnDblClick, True)) + ',' + LB +
    '    mapTypeControl: ' + LowerCase(BoolToStr(MapsProperties.GetShowMapTypeControl, True)) + ',' + LB +
    '    mapId: "' + MapsProperties.GetMapID + '",' + LB +
    '    zoomControl: ' + LowerCase(BoolToStr(MapsProperties.GetShowZoomControl, True)) + ',' + LB +
{$IFNDEF WEBLIB}
    '    fullscreenControl: false,' + LB +
{$ENDIF}
    '    center: {lat: ' + MapsProperties.GetDefaultLatitude + ', lng: ' + MapsProperties.GetDefaultLongitude + '},' + LB +
    '    backgroundColor: "' +  TTMSFNCGraphics.ColorToHTml(MapsProperties.GetBackgroundColor) + '",' + LB +
    '    zoom: ' + MapsProperties.GetDefaultZoomLevel + ',' + LB +
    '    heading: ' + FloatToStrDot(MapsProperties.GetHeading) + ',' + LB +
    '    tilt: ' + FloatToStrDot(MapsProperties.GetTilt) + ',' + LB +
    '  }'+ LB + LB +

    '  ' + MAPVAR + ' = new ' + MAPSERVICEVAR + '.Map(document.getElementById("' + MAPID + '"), ' + MAPOPTIONS + ');' + LB + LB +

    '  ' + MAPCALCLAYER + ' = new ' + MAPSERVICEVAR + '.OverlayView();' + LB +
    '  ' + MAPCALCLAYER + '.draw = function() {};' + LB +
    '  ' + MAPCALCLAYER + '.setMap(' + MAPVAR + ');';
end;

function TTMSFNCMapsGoogleMaps.GetLatLonToXY: string;
begin
  Result :=
    '  var projection = ' + MAPCALCLAYER + '.getProjection();' + LB +
    '  if (projection) {' + LB +
    '    myLatLng = new ' + MAPSERVICEVAR + '.LatLng(parseFloat(' + PARAMSNAME + '["Latitude"]), parseFloat(' + PARAMSNAME + '["Longitude"]));' + LB +
    '    var position = projection.fromLatLngToContainerPixel(myLatLng);' + LB +
    '    jsonObj["X"] = parseFloat(position.x);' + LB +
    '    jsonObj["Y"] = parseFloat(position.y);' + LB +
    '  }';
end;

function TTMSFNCMapsGoogleMaps.GetXYToLatLon: string;
begin
  Result :=
    '  var projection = ' + MAPCALCLAYER + '.getProjection();' + LB +
    '  if (projection) {' + LB +
    '    myPoint = new ' + MAPSERVICEVAR + '.Point(parseInt(' + PARAMSNAME + '["X"]), parseInt(' + PARAMSNAME + '["Y"]));' + #13 +
    '    var position = projection.fromContainerPixelToLatLng(myPoint);' + LB +
    '    jsonObj["Latitude"] = position.lat();' + LB +
    '    jsonObj["Longitude"] = position.lng();' + LB +
    '  }';
end;

function TTMSFNCMapsGoogleMaps.IsValid: Boolean;
begin
  Result := MapsProperties.GetAPIKey <> '';
end;

procedure TTMSFNCMapsGoogleMaps.RemoveScripts;
begin
  {$IFDEF WEBLIB}
  asm
    let keywords = ['maps.googleapis'];

    if (window.google){
      window.google.maps = undefined;
    }

    let scripts = document.querySelectorAll('script,link');
    for (let i = scripts.length - 1; i >= 0; i--) {
        let scriptSource = scripts[i].getAttribute('src');
        if (!scriptSource){
          scriptSource = scripts[i].getAttribute('href');
        }
        if (scriptSource != null) {
            if (keywords.filter(item => scriptSource.includes(item)).length) {
                scripts[i].remove();
                // scripts[i].parentNode.removeChild(scripts[i]);
            }
        }
    }
  end;
  {$ENDIF}
end;

end.


