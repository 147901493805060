{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCMaps.OpenLayers;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

const
  //OPENLAYERSAPIVERSION = 'v6.15.1';
  //OPENLAYERSAPIURL = 'https://cdn.jsdelivr.net/gh/openlayers/openlayers.github.io@master/en/';

  //v8
  OPENLAYERSAPIVERSION = 'v8.1.0';
  OPENLAYERSAPIURL = 'https://cdn.jsdelivr.net/npm/ol@';

  MAPSERVICEVAR = 'window.ol';
  MAPSOURCEVAR = '.source.OSM()';

  CLUSTERVAR = 'cluster';
  CLUSTERARRAYVAR = 'clusterarray';
  GETCLUSTERARRAYVAR = 'get' + CLUSTERARRAYVAR + '()';
  ADDORUPDATECLUSTERFUNCTION = 'addOrUpdateCluster';
  DELETECLUSTERFUNCTION = 'deleteCluster';

procedure RegisterOpenLayersService;
procedure UnRegisterOpenLayersService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCMaps, WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes
  {$IFDEF WEBLIB}
  ,Contnrs, Web
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCMapsOpenLayersService = class;

  TTMSFNCMapsOpenLayersFactoryService = class(TTMSFNCMapsFactoryService, ITMSFNCMapsServiceOpenLayers);

  TTMSFNCMapsOpenLayersService = class(TTMSFNCMapsOpenLayersFactoryService)
  protected
    function DoCreateMaps: ITMSFNCCustomMaps; override;
  end;

type
  TTMSFNCMapsOpenLayers = class;

  TTMSFNCMapsOpenLayers = class(TTMSFNCCustomMapsInterfacedObject, ITMSFNCCustomMaps)
  protected
    procedure GetHeadLinks(const AList: TTMSFNCMapsLinksList);
    procedure RemoveScripts;
    function GetResetMap: string;
    function GetShowPopup: string;
    function GetClosePopup: string;
    function GetHeadStyle: string;
    function GetDelayLoadEvent: string;
    function GetMapsServiceCheck: string;
    function GetIdentifier: string;
    function GetInitializeMap: string;
    function GetInitializeEvents: string;
    function GetAddOrUpdateMarker: string;
    function GetDeleteMarker: string;
    function GetAddOrUpdatePolyElement: string;
    function GetDeletePolyElement: string;
    function GetZoomToBounds: string;
    function GetGetBounds: string;
    function GetGetCenterCoordinate: string;
    function GetGetZoomLevel: string;
    function GetLatLonToXY: string;
    function GetXYToLatLon: string;
    function GetSetCenterCoordinate: string;
    function GetSetZoomLevel: string;
    function GetUpdateOptions: string;
    function GetGlobalVariables: string;
    function GetAddCoordinateToArray: string;
    function GetAddHoleToArray: string;
    function GetInitializeCoordinateArray: string;
    function GetInitializeHolesArray: string;
    function IsValid: Boolean;
    procedure DestroyMaps;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  MapsService: ITMSFNCMapsServiceOpenLayers;

const
  MAPZOOMCONTROLVAR = 'mapZoomControl';
  MAPCONTROLARRAYVAR = 'mapControls';
  MAPDOUBLECLICK = 'mapDoubleClick';
  MAPWHEELZOOM = 'mapWheelZoom';
  MAPPANNING = 'mapPanning';

procedure RegisterOpenLayersService;
begin
  if not TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceOpenLayers, IInterface(MapsService)) then
  begin
    MapsService := TTMSFNCMapsOpenLayersService.Create;
    TTMSFNCMapsPlatformServices.Current.AddPlatformService(ITMSFNCMapsServiceOpenLayers, MapsService);
  end;
end;

procedure UnregisterOpenLayersService;
begin
  TTMSFNCMapsPlatformServices.Current.RemovePlatformService(ITMSFNCMapsServiceOpenLayers);
end;

{ TTMSFNCMapsOpenLayersService }

function TTMSFNCMapsOpenLayersService.DoCreateMaps: ITMSFNCCustomMaps;
begin
  Result := TTMSFNCMapsOpenLayers.Create;
end;

constructor TTMSFNCMapsOpenLayers.Create;
begin
  inherited;
end;

destructor TTMSFNCMapsOpenLayers.Destroy;
begin
  inherited;
end;

procedure TTMSFNCMapsOpenLayers.DestroyMaps;
begin
  MapsService.DestroyMaps(Self);
end;

function TTMSFNCMapsOpenLayers.GetAddCoordinateToArray: string;
begin
  Result := '  ' + COORDINATEARRAYVAR + '.push([' + PARAMSNAME + '["Longitude"],' + PARAMSNAME + '["Latitude"]]);';
end;

function TTMSFNCMapsOpenLayers.GetAddHoleToArray: string;
begin
  Result := 'var o = ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]];' + LB +
            'if (o){' + LB +
            '  ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]].push([' + PARAMSNAME + '[1]["Longitude"], ' + PARAMSNAME + '[1]["Latitude"]]);' + LB +
            '}else{' + LB +
            '  ' + HOLEARRAYVAR + '[' + PARAMSNAME + '[0]] = [[' + PARAMSNAME + '[1]["Longitude"], ' + PARAMSNAME + '[1]["Latitude"]]];' + LB +
            '}';
end;

function TTMSFNCMapsOpenLayers.GetAddOrUpdateMarker: string;
begin
  Result :=
    '  var lnlt = ' + MAPSERVICEVAR + '.proj.fromLonLat([' + PARAMSNAME + '["Longitude"], ' + PARAMSNAME + '["Latitude"]]);' + LB +
    '  var loc = new ' + MAPSERVICEVAR + '.geom.Point(lnlt);' + LB +

    '  var iconStyle;' + LB +

    '  if (' + PARAMSNAME + '["IconURL"] != ""){' + LB +
    '    iconStyle = new ' + MAPSERVICEVAR + '.style.Style({' + LB +
    '      image: new ' + MAPSERVICEVAR + '.style.Icon(({' + LB +
    '          anchor: [0.5, 1],' + LB +
    '          src: ' + PARAMSNAME + '["IconURL"]' + LB +
    '      }))' + LB +

    '    });' + LB + LB +
    '  }' + LB + LB +

    '  if (!' + MARKERVAR + '){' + LB +
    '    var f = new ' + MAPSERVICEVAR + '.Feature({' + LB +
    '      geometry: loc,' + LB +
    '    })' + LB + LB +

    '    f.setId(' + PARAMSNAME + '["ID"].toString());' + LB +

    '    function getFeature(event){' + LB +
    '      var feature = ' + MAPVAR + '.forEachFeatureAtPixel(event.pixel,' + LB +
    '      function(ft) {' + LB +
    '        return ft;' + LB +
    '      }, {' + LB +
    '      layerFilter: function(layer) {' + LB +
    '        var foundlayer = false;' + LB +
    '        foundlayer = layer === ' + MARKERVAR + ';' + LB +

    '        if (' + CLUSTERARRAYVAR + ') {' + LB +
    '        for (var key in ' + CLUSTERARRAYVAR + ') {' + LB +
    '          if (' + CLUSTERARRAYVAR + '.hasOwnProperty(key)) {' + LB +
    '            if (layer === ' + CLUSTERARRAYVAR + '[key])' + LB +
    '              foundlayer = true;' + LB +
    '          }' + LB +
    '        }' + LB +
    '        }' + LB +

    '        return foundlayer;' + LB +
    '      }});' + LB +

    '      if (feature) {' +
    '        clusterfeatures = feature.get("features"); ' + LB +
    '        if (clusterfeatures) {' + LB +
    '          if (clusterfeatures.length == 1){' + LB +
    '            if (clusterfeatures[0] === f)' + LB +
    '            feature = clusterfeatures[0];' + LB +
    '          }' + LB +
    '        }' + LB +
    '      }' +

    '      return feature;' + LB +
    '    }' + LB + LB +

    '    var MarkerPopup = null;' + LB + LB +

    '    var MarkerClick = function(event){' + LB +
    '      var ft = getFeature(event);' + LB +
    '      if (ft === f && !activeMarker) {' + LB +
    '        activeMarker = f;' + LB +
    '        if (' + PARAMSNAME + '["Title"] != "" && !MarkerPopup){' + LB +
    '          var el = document.createElement(''div'');' + LB +
    '          var t = ' + PARAMSNAME + '["Title"];' + LB +
    '          el.className = "ol-popup"' + LB +
    '          el.innerHTML = ''<a href="#" id="popup-closer-marker" class="ol-popup-closer"></a><div>'' + t + ''</div>''' + LB +
    '          MarkerPopup = new ' + MAPSERVICEVAR + '.Overlay({element: el, offset: [0, -50]});' + LB +
    '          MarkerPopup.setPosition(activeMarker.getGeometry().getCoordinates());' + LB +
    '          ' + MAPVAR + '.addOverlay(MarkerPopup);' + LB +
    '          var closer = document.getElementById(''popup-closer-marker'');' + LB +
    '          closer.onclick = function() {' + LB +
    '            MarkerPopup.setPosition(undefined);' + LB +
    '            MarkerPopup = null;' + LB +
    '            closer.blur();' + LB +
    '            return false;' + LB +
    '          };' + LB +
    '        }' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "MarkerClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '      }' + LB +
    '    }' + LB + LB +
    '    ' + MAPVAR + '.on(''click'', MarkerClick);' + LB +
    '    var MarkerDblClick = function(event){' + LB +
    '      var ft = getFeature(event);' + LB +
    '      if (ft === f && !activeMarker) {' + LB +
    '        activeMarker = f;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "MarkerDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '      }' + LB +
    '    }' + LB + LB +
    '    ' + MAPVAR + '.on(''dblclick'', MarkerDblClick);' + LB +
    '    var MarkerMouseUp = function(event){' + LB +
    '      var ft = getFeature(event);' + LB +
    '      if (ft === f && !activeMarker) {' + LB +
    '        activeMarker = f;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '      }' + LB +
    '    }' + LB + LB +
    '    ' + MAPVAR + '.on(''pointerup'', MarkerMouseUp);' + LB +
    '    var MarkerMouseDown = function(event){' + LB +
    '      var ft = getFeature(event);' + LB +
    '      if (ft === f && !activeMarker) {' + LB +
    '        activeMarker = f;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '      }' + LB +
    '    }' + LB + LB +
    '    ' + MAPVAR + '.on(''pointerdown'', MarkerMouseDown);' + LB +
    '    var MarkerMouseMove = function(event){' + LB +
    '      var ft = getFeature(event);' + LB +
    '      if (ft && ft === f && ft != hoverFeature) {' + LB +
    '        hoverFeature = ft;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '      }' + LB +
    '      else if (ft != hoverFeature && hoverFeature === f){' + LB +
    '        hoverFeature = null;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '      }' + LB +
    '    }' + LB + LB +
    '    ' + MAPVAR + '.on(''pointermove'', MarkerMouseMove);' + LB +

    '    f.setStyle(iconStyle);' + LB +

    '    var vectorSource = new ' + MAPSERVICEVAR + '.source.Vector({' + LB +
    '      features: [f]' + LB +
    '    });' + LB + LB +

    '    ' + MARKERVAR + ' = new ' + MAPSERVICEVAR + '.layer.Vector({' + LB +
    '      source: vectorSource, ' + LB +
    '      visible: ' + PARAMSNAME + '["Visible"],' + LB +
    '      zIndex: 999' + LB +
    '    });' + LB + LB +

    '    if (' + PARAMSNAME + '["Draggable"]) {' + LB +
    '      const t = new ' + MAPSERVICEVAR + '.interaction.Translate({' + LB +
    '         features: new ' + MAPSERVICEVAR + '.Collection([f]),' + LB +
    '      })' + LB +

    '      t.on(''translatestart'', e => {' + LB +
    '        if (MarkerPopup){' + LB +
    '          MarkerPopup.setPosition(undefined);' + LB +
    '          MarkerPopup = null;' + LB +
    '        }' + LB +
    '      })' + LB +

    '      t.on(''translateend'', e => {' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(e, "MarkerDragEnd", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB +

    '    ' + MAPVAR + '.addInteraction(t);' + LB +
    '    }' + LB + LB +

    '    ' + MARKERVAR + '.MarkerClick = MarkerClick;' + LB +
    '    ' + MARKERVAR + '.MarkerDblClick = MarkerDblClick;' + LB +
    '    ' + MARKERVAR + '.MarkerMouseUp = MarkerMouseUp;' + LB +
    '    ' + MARKERVAR + '.MarkerMouseDown = MarkerMouseDown;' + LB +
    '    ' + MARKERVAR + '.MarkerMouseMove = MarkerMouseMove;' + LB +

    '    ' + MAPVAR + '.addLayer(' + MARKERVAR + ');' + LB +

    '    ' + GetInitializeEvents + LB +
    '  }else{' + LB +
    '    ' + MARKERVAR + '.setVisible(' + PARAMSNAME + '["Visible"]);' + LB +
    '    ' + MARKERVAR + '.getSource().getFeatures()[0].setGeometry(loc);' + LB +
    '    ' + MARKERVAR + '.getSource().getFeatures()[0].setStyle(iconStyle);' + LB +
    '  }';
end;

function TTMSFNCMapsOpenLayers.GetAddOrUpdatePolyElement: string;
begin
  Result :=
    '  var c = ' + PARAMSNAME + '["$type"];' + LB +
    '  var vectorStyle;' + LB +
    '  var labelstyle = null;' + LB +
    '  var d;' + LB +
    '  var co = [];' + LB +
    '  var spot;' +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '    case "TTMSFNCMapsOpenLayersRectangle":' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"]]);' + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '    case "TTMSFNCOpenLayersCircle":' + LB +
    '      co = createCircle(' + PARAMSNAME + '["Center"], ' + PARAMSNAME + '["Radius"], true);' + LB +
    '    break;' + LB +
    '    default:' + LB +
    '      co = ' + COORDINATEARRAYVAR + ';' + LB +
    '    break;' + LB +
    '  }' + LB + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsPolyline":' + LB +
    '    case "TTMSFNCOpenLayersPolyline":' + LB +
    '    d = new ' + MAPSERVICEVAR + '.geom.LineString(co)' + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '    case "TTMSFNCOpenLayersCircle":' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '    case "TTMSFNCOpenLayersRectangle":' + LB +
    '    case "TTMSFNCMapsPolygon":' + LB +
    '    case "TTMSFNCOpenLayersPolygon":' + LB +
    '    d = new ' + MAPSERVICEVAR + '.geom.Polygon([co])' + LB +
    '    for (var key in ' + HOLEARRAYVAR + '){' + LB +
    '      let linear_ring = new ' + MAPSERVICEVAR + '.geom.LinearRing(' + HOLEARRAYVAR + '[key]);' + LB +
    '      d.appendLinearRing(linear_ring);' + LB +
    '    }' + LB +
    '    break;' + LB +
    '  }' + LB + LB +

    '  if ((' + PARAMSNAME + '.hasOwnProperty("Label"))){ ' + LB +

    '    bgFill = new ' + MAPSERVICEVAR + '.style.Fill({color: toRGBA(' + PARAMSNAME + '["Label"]["BackgroundColor"], 1)});' + LB +
    '    if (' + PARAMSNAME + '["Label"]["BackgroundColor"] == "gcNull")' +
    '      bgFill = null;' + LB +

    '    bgStroke = new ' + MAPSERVICEVAR + '.style.Stroke({color: toRGBA(' + PARAMSNAME + '["Label"]["BorderColor"], 1)});' + LB +
    '    if (' + PARAMSNAME + '["Label"]["BorderColor"] == "gcNull")' +
    '      bgStroke = null;' + LB +

    '    labelstyle = '+ LB +
    '    new ' + MAPSERVICEVAR + '.style.Text({ ' + LB +
    '      fill: new ' + MAPSERVICEVAR + '.style.Fill({color: toRGBA(' + PARAMSNAME + '["Label"]["FontColor"], 1)}),' + LB +
    '      backgroundFill: bgFill,' + LB +
    '      backgroundStroke: bgStroke,' + LB +
    '      padding: [' + PARAMSNAME + '["Label"]["Padding"] + 2, ' + PARAMSNAME + '["Label"]["Padding"], ' + PARAMSNAME + '["Label"]["Padding"], ' + PARAMSNAME + '["Label"]["Padding"]], ' + LB +
    '      placement: "point", ' + LB +
    '      overflow: true, ' + LB +
    '      offsetX: ' + PARAMSNAME + '["Label"]["OffsetX"], ' + LB +
    '      offsetY: ' + PARAMSNAME + '["Label"]["OffsetY"], ' + LB +
    '      font: ' + PARAMSNAME + '["Label"]["FontSize"] + "px sans-serif", ' +
    '      text: ' + PARAMSNAME + '["Label"]["Text"]' + LB +
    '    });' + LB +
    '  }' + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsPolyline":' + LB +
    '    case "TTMSFNCOpenLayersPolyline":' + LB +
    '    vectorStyle = new ' + MAPSERVICEVAR + '.style.Style({' + LB +
    '      text: labelstyle, '+ LB +
    '      stroke: new ' + MAPSERVICEVAR + '.style.Stroke({' + LB +
    '        color: toRGBA(' + PARAMSNAME + '["StrokeColor"], ' + PARAMSNAME + '["StrokeOpacity"]),' + LB +
    '        width: ' + PARAMSNAME + '["StrokeWidth"]' + LB +
    '      }),' + LB +
    '    });' + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '    case "TTMSFNCOpenLayersCircle":' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '    case "TTMSFNCOpenLayersRectangle":' + LB +
    '    case "TTMSFNCMapsPolygon":' + LB +
    '    case "TTMSFNCOpenLayersPolygon":' + LB +
    '    vectorStyle = new ' + MAPSERVICEVAR + '.style.Style({' + LB +
    '      text: labelstyle, '+ LB +
    '      stroke: new ' + MAPSERVICEVAR + '.style.Stroke({' + LB +
    '        color: toRGBA(' + PARAMSNAME + '["StrokeColor"], ' + PARAMSNAME + '["StrokeOpacity"]),' + LB +
    '        width: ' + PARAMSNAME + '["StrokeWidth"]' + LB +
    '      }),' + LB +
    '      fill: new ' + MAPSERVICEVAR + '.style.Fill({' + LB +
    '        color: toRGBA(' + PARAMSNAME + '["FillColor"], ' + PARAMSNAME + '["FillOpacity"])' + LB +
    '      })' + LB +
    '    });' + LB +
    '    break;' + LB +
    '  }' + LB + LB +

    '  if (d) {' + LB +
    '    d.transform(''EPSG:4326'', ' + MAPVAR + '.getView().getProjection());' + LB +
    '  }' + LB + LB +

    '  if (!' + POLYELEMENTVAR + '){' + LB +
    '    var f = new ' + MAPSERVICEVAR + '.Feature({' + LB +
    '      geometry: d' + LB +
    '    })' + LB + LB +

    '    function getFeature(event){' + LB +
    '      var feature = ' + MAPVAR + '.forEachFeatureAtPixel(event.pixel,' + LB +
    '      function(ft) {' + LB +
    '        return ft;' + LB +
    '      }, {' + LB +
    '      layerFilter: function(layer) {' + LB +
    '        return layer === ' + POLYELEMENTVAR + ';' + LB +
    '      }});' + LB +
    '      return feature;' + LB +
    '    }' + LB + LB +

    '    var PolyElementClick = function(event){' + LB +
    '      var ft = getFeature(event);' + LB +
    '      if (ft === f && !activePolyElement) {' + LB +
    '        activePolyElement = f;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '      }' + LB +
    '    }' + LB + LB +
    '    ' + MAPVAR + '.on(''click'', PolyElementClick);' + LB +
    '    var PolyElementDblClick = function(event){' + LB +
    '      var ft = getFeature(event);' + LB +
    '      if (ft === f && !activePolyElement) {' + LB +
    '        activePolyElement = f;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '      }' + LB +
    '    }' + LB + LB +
    '    ' + MAPVAR + '.on(''dblclick'', PolyElementDblClick);' + LB +
    '    var PolyElementMouseUp = function(event){' + LB +
    '      var ft = getFeature(event);' + LB +
    '      if (ft === f && !activePolyElement) {' + LB +
    '        activePolyElement = f;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '      }' + LB +
    '    }' + LB + LB +
    '    ' + MAPVAR + '.on(''pointerup'', PolyElementMouseUp);' + LB +
    '    var PolyElementMouseDown = function(event){' + LB +
    '      var ft = getFeature(event);' + LB +
    '      if (ft === f && !activePolyElement) {' + LB +
    '        activePolyElement = f;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '      }' + LB +
    '    }' + LB + LB +
    '    ' + MAPVAR + '.on(''pointerdown'', PolyElementMouseDown);' + LB +
    '    var PolyElementMouseMove = function(event){' + LB +
    '      var ft = getFeature(event);' + LB +
    '      if (ft && ft === f && ft != hoverFeature) {' + LB +
    '        hoverFeature = ft;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '      }' + LB +
    '      else if (ft != hoverFeature && hoverFeature === f){' + LB +
    '        hoverFeature = null;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '      }' + LB +
    '    }' + LB + LB +
    '    ' + MAPVAR + '.on(''pointermove'', PolyElementMouseMove);' + LB +

    '  var vectorSource = new ' + MAPSERVICEVAR + '.source.Vector({' + LB +
    '    features: [f]' + LB +
    '  });' + LB + LB +

    '  ' + POLYELEMENTVAR + ' = new ' + MAPSERVICEVAR + '.layer.Vector({' + LB +
    '    source: vectorSource,' + LB +
    '    style: vectorStyle,' + LB +
    '    visible: ' + PARAMSNAME + '["Visible"]' + LB +
    '  });' + LB + LB +

    '    if (' + PARAMSNAME + '["Draggable"]) {' + LB +
    '      const t = new ' + MAPSERVICEVAR + '.interaction.Translate({' + LB +
    '         features: new ' + MAPSERVICEVAR + '.Collection([f]),' + LB +
    '      })' + LB +

    '      t.on(''translateend'', e => {' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(e, "PolyElementDragEnd", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB +

    '    ' + MAPVAR + '.addInteraction(t);' + LB +
    '    }' + LB + LB +

    '  ' + POLYELEMENTVAR + '.PolyElementClick = PolyElementClick;' + LB +
    '  ' + POLYELEMENTVAR + '.PolyElementDblClick = PolyElementDblClick;' + LB +
    '  ' + POLYELEMENTVAR + '.PolyElementMouseUp = PolyElementMouseUp;' + LB +
    '  ' + POLYELEMENTVAR + '.PolyElementMouseDown = PolyElementMouseDown;' + LB +
    '  ' + POLYELEMENTVAR + '.PolyElementMouseMove = PolyElementMouseMove;' + LB +

    '  ' + MAPVAR + '.addLayer(' + POLYELEMENTVAR + ');' + LB +

    '    ' + GetInitializeEvents + LB +
    '  }else{' + LB +
    '    ' + POLYELEMENTVAR + '.setVisible(' + PARAMSNAME + '["Visible"]);' + LB +
    '    ' + POLYELEMENTVAR + '.getSource().getFeatures()[0].setGeometry(d);' + LB +
    '    ' + POLYELEMENTVAR + '.setStyle(vectorStyle);' + LB +
    '  }';
end;

function TTMSFNCMapsOpenLayers.GetClosePopup: string;
begin
  Result := '  ' + POPUPVAR + '.setPosition(undefined);' + LB +
            '  ' + MAPVAR + '.removeOverlay(' + POPUPVAR + ');' + LB +
            '  ' + POPUPVAR + ' = null;';
end;

function TTMSFNCMapsOpenLayers.GetDelayLoadEvent: string;
begin
  Result :=  '';
end;

function TTMSFNCMapsOpenLayers.GetDeleteMarker: string;
begin
  Result := '  ' + MARKERVAR + '.setSource(null);' + LB;
  Result := Result + '  ' + MAPVAR + '.un(''click'', ' + MARKERVAR + '.MarkerClick);' + LB;
  Result := Result + '  ' + MAPVAR + '.un(''dblclick'', ' + MARKERVAR + '.MarkerDblClick);' + LB;
  Result := Result + '  ' + MAPVAR + '.un(''pointerup'', ' + MARKERVAR + '.MarkerMouseUp);' + LB;
  Result := Result + '  ' + MAPVAR + '.un(''pointerdown'', ' + MARKERVAR + '.MarkerMouseDown);' + LB;
  Result := Result + '  ' + MAPVAR + '.un(''pointermove'', ' + MARKERVAR + '.MarkerMouseMove);';
  Result := Result + '  ' + MAPVAR + '.removeLayer(' + MARKERVAR + ');' + LB;
end;

function TTMSFNCMapsOpenLayers.GetDeletePolyElement: string;
begin
  Result := '  ' + POLYELEMENTVAR + '.setSource(null);' + LB;
  Result := Result + '  ' + MAPVAR + '.un(''click'', ' + POLYELEMENTVAR + '.PolyElementClick);' + LB;
  Result := Result + '  ' + MAPVAR + '.un(''dblclick'', ' + POLYELEMENTVAR + '.PolyElementDblClick);' + LB;
  Result := Result + '  ' + MAPVAR + '.un(''pointerup'', ' + POLYELEMENTVAR + '.PolyElementMouseUp);' + LB;
  Result := Result + '  ' + MAPVAR + '.un(''pointerdown'', ' + POLYELEMENTVAR + '.PolyElementMouseDown);' + LB;
  Result := Result + '  ' + MAPVAR + '.un(''pointermove'', ' + POLYELEMENTVAR + '.PolyElementMouseMove);';
  Result := Result + '  ' + MAPVAR + '.removeLayer(' + POLYELEMENTVAR + ');' + LB;
end;

function TTMSFNCMapsOpenLayers.GetGetBounds: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getView().calculateExtent();' + LB +
    '  var l = ' + MAPSERVICEVAR + '.proj.transformExtent(loc, ' + MAPVAR + '.getView().getProjection(), ''EPSG:4326'');' + LB +
    '  jsonObj["NorthEast"]["Latitude"] = l[3];' + LB +
    '  jsonObj["NorthEast"]["Longitude"] = l[2];' + LB +
    '  jsonObj["SouthWest"]["Latitude"] = l[1];' + LB +
    '  jsonObj["SouthWest"]["Longitude"] = l[0];';
end;

function TTMSFNCMapsOpenLayers.GetGetCenterCoordinate: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getView().getCenter();' + LB +
    '  var l = ' + MAPSERVICEVAR + '.proj.transform(loc, ' + MAPVAR + '.getView().getProjection(), ''EPSG:4326'');' + LB +
    '  jsonObj["Latitude"] = l[1];' + LB +
    '  jsonObj["Longitude"] = l[0];';
end;

function TTMSFNCMapsOpenLayers.GetLatLonToXY: string;
begin
  Result :=
    '  var coordinate = ' + MAPSERVICEVAR + '.proj.fromLonLat([parseFloat(' + PARAMSNAME + '["Longitude"]), parseFloat(' + PARAMSNAME + '["Latitude"])]);' + LB +
    '  var pixel = ' + MAPVAR + '.getPixelFromCoordinate(coordinate);' + LB +
    '  jsonObj["X"] = parseFloat(pixel[0]);' + LB +
    '  jsonObj["Y"] = parseFloat(pixel[1]);';
end;

function TTMSFNCMapsOpenLayers.GetXYToLatLon: string;
begin
  Result := '';
end;

function TTMSFNCMapsOpenLayers.GetGetZoomLevel: string;
begin
  Result := '  var z = ' + MAPVAR + '.getView().getZoom();';
end;

function TTMSFNCMapsOpenLayers.GetGlobalVariables: string;
begin
  Result :=
    'var activePolyElement = null;' + LB +
    'var activeMarker = null;' + LB +
    'var ' + MAPZOOMCONTROLVAR + ';' + LB +
    'var ' + MAPCONTROLARRAYVAR + ' = [];' +
    'var oldZoom;' + LB +
    'var hoverFeature;' + LB +
    'var mouseLatLng = {''Latitude'': 0, ''Longitude'': 0};' + LB +
    'function parseEvent(event, eventname, id = ''''){' + LB +
    '  var loc = {''Latitude'': 0, ''Longitude'': 0};' + LB +
    '  var x = 0.0;' + LB +
    '  var y = 0.0;' + LB +
    '  if ((eventname == "MapMoveStart") || (eventname == "MapMoveEnd")){ ' +
    '    centerpt = ' + MAPVAR + '.getView().getCenter();' + LB +
    '    var l = ' + MAPSERVICEVAR + '.proj.transform(centerpt, ' + MAPVAR + '.getView().getProjection(), ''EPSG:4326'');' + LB +
    '    loc = {''Latitude'': l[1], ''Longitude'': l[0]};' + LB +
    '  } else if (event) {' + LB +
    '    if (event.coordinate){' + LB +
    '      var l = ' + MAPSERVICEVAR + '.proj.transform(event.coordinate, ' + MAPVAR + '.getView().getProjection(), ''EPSG:4326'');' + LB +
    '      loc = {''Latitude'': l[1], ''Longitude'': l[0]};' + LB +
    '      mouseLatLng = loc;' + LB +
    '    }' + LB +
    '    if (event.pixel){' + LB +
    '      x = event.pixel[0];' + LB +
    '      y = event.pixel[1];' + LB +
    '    }' + LB +
    '  }' + LB +

    '  var r = {''Coordinate'': loc, ''X'': x, ''Y'': y, ''ID'': id, ''EventName'': eventname};' + LB +
    '  return r;' + LB +
    '}' + LB + LB +

    'function ZoomChanged(){' + LB +
    '    if (' + MAPVAR + '){' + LB +
    '      if (!oldZoom){' + LB +
    '        oldZoom = ' + MAPVAR + '.getView().getZoom();' + LB +
    '      }' + LB +
    '      var newZoom = ' + MAPVAR + '.getView().getZoom();' + LB +
    '      if(newZoom != oldZoom){' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(null, "ZoomChanged"));' + LB +
    '      }' + LB +
    '      oldZoom = newZoom;' + LB +
    '    }' + LB +
    '  }' + LB + LB +

    'function ResetActive(){' + LB +
    '  if (activeMarker){' + LB +
    '    activeMarker = null;' + LB +
    '    return true;' + LB +
    '  }' + LB +
    '  if (activePolyElement){' + LB +
    '    activePolyElement = null;' + LB +
    '    return true;' + LB +
    '  }' + LB +
    '  return false;' + LB +
    '}' + LB + LB +

    'function MapMoveStart(event){' + LB +
    '  if (ResetActive()){' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMoveStart"));' + LB +
    '}' + LB + LB +
    'function MapMoveEnd(event){' + LB +
    '  if (ResetActive()){' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMoveEnd"));' + LB +
    '}' + LB + LB +
    'function MapClick(event){' + LB +
    '  if (ResetActive()){' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapClick"));' + LB +
    '}' + LB + LB +
    'function MapDblClick(event){' + LB +
    '  if (ResetActive()){' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapDblClick"));' + LB +
    '}' + LB + LB +
    'function MapMouseDown(event){' + LB +
    '  if (ResetActive()){' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMouseDown"));' + LB +
    '}' + LB + LB +
    'function MapMouseUp(event){' + LB +
    '  if (ResetActive()){' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMouseUp"));' + LB +
    '}' + LB + LB +
    'function MapMouseLeave(event){' + LB +
    '  if (ResetActive()){' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMouseLeave"));' + LB +
    '}' + LB + LB +
    'function MapMouseEnter(event){' + LB +
    '  if (ResetActive()){' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMousEnter"));' + LB +
    '}' + LB + LB +
    'function MapMouseMove(event){' + LB +
    '  if (ResetActive()){' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMouseMove"));' + LB +
    '}' + LB + LB +
    'function MapRenderComplete(event){' + LB +
    '  if (ResetActive()){' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapRenderComplete"));' + LB +
    '}' + LB;

  Result := Result +
    'var ' + CLUSTERVAR + ';' + LB +
    'var ' + CLUSTERARRAYVAR + ' = {};' + LB +
    'function ' + GETCLUSTERARRAYVAR + '{' + LB +
    '  var arr = [];' + LB +
    '  for (var key in ' + CLUSTERARRAYVAR + '){' + LB +
    '    var v = key;' + LB +
    '    arr.push(v);' + LB +
    '  }' + LB +
    '  return arr.toString();' + LB +
    '}';
end;

procedure TTMSFNCMapsOpenLayers.GetHeadLinks(
  const AList: TTMSFNCMapsLinksList);
begin
//  AList.Add(TTMSFNCMapsLink.CreateLink(OPENLAYERSAPIURL + OPENLAYERSAPIVERSION + '/css/ol.css', 'text/css', 'stylesheet'));
//  AList.Add(TTMSFNCMapsLink.CreateScript(OPENLAYERSAPIURL + OPENLAYERSAPIVERSION + '/build/ol.js', 'text/javascript'));

  //v8
  AList.Add(TTMSFNCMapsLink.CreateLink(OPENLAYERSAPIURL + OPENLAYERSAPIVERSION + '/ol.css', 'text/css', 'stylesheet'));
  AList.Add(TTMSFNCMapsLink.CreateScript(OPENLAYERSAPIURL + OPENLAYERSAPIVERSION + '/dist/ol.js', 'text/javascript'));
end;

function TTMSFNCMapsOpenLayers.GetHeadStyle: string;
begin
  Result := '.ol-popup {' + LB +
      '  position: absolute;' + LB +
      '  background-color: white;' + LB +
      '  box-shadow: 0 1px 4px rgba(0,0,0,0.2);' + LB +
      '  padding: 15px;' + LB +
      '  border-radius: 10px;' + LB +
      '  border: 1px solid #cccccc;' + LB +
      '  bottom: 12px;' + LB +
      '  left: -50px;' + LB +
      '  min-width: 100px;' + LB +
      '}' + LB +
      '.ol-popup:after, .ol-popup:before {' + LB +
      '  top: 100%;' + LB +
      '  border: solid transparent;' + LB +
      '  content: " ";' + LB +
      '  height: 0;' + LB +
      '  width: 0;' + LB +
      '  position: absolute;' + LB +
      '  pointer-events: none;' + LB +
      '}' + LB +
      '.ol-popup:after {' + LB +
      '  border-top-color: white;' + LB +
      '  border-width: 10px;' + LB +
      '  left: 48px;' + LB +
      '  margin-left: -10px;' + LB +
      '}' + LB +
      '.ol-popup:before {' + LB +
      '  border-top-color: #cccccc;' + LB +
      '  border-width: 11px;' + LB +
      '  left: 48px;' + LB +
      '  margin-left: -11px;' + LB +
      '}' + LB +
      '.ol-popup-closer {' + LB +
      '  text-decoration: none;' + LB +
      '  position: absolute;' + LB +
      '  top: 2px;' + LB +
      '  right: 8px;' + LB +
      '}' + LB +
      '.ol-popup-closer:after {' + LB +
      '  content: "✖";' + LB +
      '}';
end;

function TTMSFNCMapsOpenLayers.GetIdentifier: string;
begin
  Result := 'OpenLayers';
end;

function TTMSFNCMapsOpenLayers.GetInitializeCoordinateArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsOpenLayers.GetInitializeHolesArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsOpenLayers.GetInitializeEvents: string;
begin
  Result :=
  '  ' + MAPVAR + '.addEventListener(''moveend'', ZoomChanged);' + LB +
  '  ' + MAPVAR + '.addEventListener(''rendercomplete'', MapRenderComplete);' + LB +
//  '  ' + MAPVAR + '.on(''maptypeid_changed'', function(){' + LB +
//  '    ' + GETSENDEVENT + '(parseEvent(null, "MapTypeChanged"));' + LB +
//  '  })' + LB + LB +
  '  ' + MAPVAR + '.un(''movestart'', MapMoveStart);' + LB +
  '  ' + MAPVAR + '.on(''movestart'', MapMoveStart);' + LB +
  '  ' + MAPVAR + '.un(''moveend'', MapMoveEnd);' + LB +
  '  ' + MAPVAR + '.on(''moveend'', MapMoveEnd);' + LB +
  '  ' + MAPVAR + '.un(''click'', MapClick);' + LB +
  '  ' + MAPVAR + '.on(''click'', MapClick);' + LB +
  '  ' + MAPVAR + '.un(''dblclick'', MapDblClick);' + LB +
  '  ' + MAPVAR + '.on(''dblclick'', MapDblClick);' + LB +
  '  ' + MAPVAR + '.un(''pointerup'', MapMouseUp);' + LB +
  '  ' + MAPVAR + '.on(''pointerup'', MapMouseUp);' + LB +
  '  ' + MAPVAR + '.un(''pointerdown'', MapMouseDown);' + LB +
  '  ' + MAPVAR + '.on(''pointerdown'', MapMouseDown);' + LB +
  '  ' + MAPVAR + '.un(''pointermove'', MapMouseMove);' + LB +
  '  ' + MAPVAR + '.on(''pointermove'', MapMouseMove);';
end;

function TTMSFNCMapsOpenLayers.GetInitializeMap: string;
begin
  Result :=
    '  var int;' + LB +
    '  ' + MAPDOUBLECLICK + ' = new ' + MAPSERVICEVAR + '.interaction.DoubleClickZoom();' + LB +
    '  ' + MAPPANNING + ' = new ' + MAPSERVICEVAR + '.interaction.DragPan();' + LB +
    '  ' + MAPWHEELZOOM + ' = new ' + MAPSERVICEVAR + '.interaction.MouseWheelZoom();' + LB;

//  Result := Result + '  int = ' + MAPSERVICEVAR + '.interaction.defaults({doubleClickZoom: false, dragPan: false, mouseWheelZoom: false}).extend([' + MAPDOUBLECLICK + ', ' + MAPPANNING + ', ' + MAPWHEELZOOM + '])' + LB;

  //v8
  Result := Result + '  int = ' + MAPSERVICEVAR + '.interaction.defaults.defaults({doubleClickZoom: false, dragPan: false, mouseWheelZoom: false}).extend([' + MAPDOUBLECLICK + ', ' + MAPPANNING + ', ' + MAPWHEELZOOM + '])' + LB;

  Result := Result +
    '  ' + MAPVAR + ' = new ' + MAPSERVICEVAR + '.Map({' + LB +
    '    interactions: int,' + LB +
    '    target: "' + MAPID +'",' + LB +
    '    layers: [' + LB +
    '      new ' + MAPSERVICEVAR + '.layer.Tile({' + LB +
    '        source: new ' + MAPSERVICEVAR + MAPSOURCEVAR + LB +
    '      })' + LB +
    '    ],' + LB +
    '    view: new ' + MAPSERVICEVAR + '.View({' + LB +
    '      center: ' + MAPSERVICEVAR + '.proj.fromLonLat([' + MapsProperties.GetDefaultLongitude + ', ' + MapsProperties.GetDefaultLatitude + ']),' + LB +
    '      zoom: ' + MapsProperties.GetDefaultZoomLevel  + LB +
    '    }),' + LB +
    '    controls:[]' + LB +
    '  });' + LB + LB;

    Result := Result + '  ' + MAPZOOMCONTROLVAR + ' = new ' + MAPSERVICEVAR + '.control.Zoom();' + LB;

    if MapsProperties.GetShowZoomControl then
    begin
      Result := Result +
      '  ' + MAPVAR + '.addControl(' + MAPZOOMCONTROLVAR + ');' + LB +
      '  ' + MAPCONTROLARRAYVAR + '.push(' + MAPZOOMCONTROLVAR + ');' + LB + LB;
    end;

    if not MapsProperties.GetZoomOnDblClick then
    begin
      Result := Result +
      '  ' + MAPDOUBLECLICK + '.setActive(false);' + LB + LB;
    end
    else
    begin
      Result := Result +
      '  ' + MAPDOUBLECLICK + '.setActive(true);' + LB + LB
    end;

    if not MapsProperties.GetZoomOnWheelScroll then
    begin
      Result := Result +
      '  ' + MAPWHEELZOOM + '.setActive(false);' + LB + LB;
    end
    else
    begin
      Result := Result +
      '  ' + MAPWHEELZOOM + '.setActive(true);' + LB + LB
    end;

    if not MapsProperties.GetPanning then
    begin
      Result := Result +
      '  ' + MAPPANNING + '.setActive(false);' + LB + LB;
    end
    else
    begin
      Result := Result +
      '  ' + MAPPANNING + '.setActive(true);' + LB + LB
    end;
end;

function TTMSFNCMapsOpenLayers.GetMapsServiceCheck: string;
begin
  Result := '!' + MAPSERVICEVAR;
end;

function TTMSFNCMapsOpenLayers.GetResetMap: string;
begin
  Result := '  ' + MAPVAR + '.removeEventListener(''moveend'', ZoomChanged);';
end;

function TTMSFNCMapsOpenLayers.GetSetCenterCoordinate: string;
begin
  Result := '  ' + MAPVAR + '.getView().setCenter(' + MAPSERVICEVAR + '.proj.fromLonLat([' + PARAMSNAME + '["Longitude"], '  + PARAMSNAME + '["Latitude"]]));';
end;

function TTMSFNCMapsOpenLayers.GetSetZoomLevel: string;
begin
  Result := '  ' + MAPVAR + '.getView().setZoom(' + PARAMSNAME + ');';
end;

function TTMSFNCMapsOpenLayers.GetShowPopup: string;
begin
  Result :=
    '  var lnlt = ' + MAPSERVICEVAR + '.proj.fromLonLat([' + PARAMSNAME + '["Longitude"], ' + PARAMSNAME + '["Latitude"]]);' + LB +
    '  var el = document.createElement(''div'');' + LB +
    '  var t = ' + PARAMSNAME + '["Text"];' + LB +
    '  el.className = "ol-popup"' + LB +
    '  el.innerHTML = ''<a href="#" id="popup-closer-popup" class="ol-popup-closer"></a><div>'' + t + ''</div>''' + LB +
    '  ' + POPUPVAR + ' = new ' + MAPSERVICEVAR + '.Overlay({element: el, autoPan: ' + PARAMSNAME + '["PanMap"], offset: [' + PARAMSNAME + '["OffsetX"], ' + PARAMSNAME + '["OffsetY"]]});' + LB +
    '  ' + POPUPVAR  + '.setPosition(lnlt);' + LB +
    '  ' + MAPVAR + '.addOverlay(' + POPUPVAR + ');' + LB +
    '          var closer = document.getElementById(''popup-closer-popup'');' + LB +
    '          closer.onclick = function() {' + LB +
    '            ' + POPUPVAR + '.setPosition(undefined);' + LB +
    '            ' + MAPVAR + '.removeOverlay(' + POPUPVAR + ');' + LB +
    '            ' + POPUPVAR + ' = null;' + LB +
    '            closer.blur();' + LB +
    '            return false;' + LB +
    '          };';
end;

function TTMSFNCMapsOpenLayers.GetUpdateOptions: string;
begin
  Result := Result +
    '  ' + MAPCONTROLARRAYVAR + '.forEach(removeElement);' + LB +
    '  function removeElement(value){' + LB +
    '    ' + MAPVAR + '.removeControl(value);' + LB +
    '  }' + LB +
    '  ' + MAPCONTROLARRAYVAR + ' = [];' + LB + LB;

  Result := Result + '  ' + MAPDOUBLECLICK + '.setActive(' + PARAMSNAME + '["ZoomOnDblClick"]);' + LB + LB;
  Result := Result + '  ' + MAPPANNING + '.setActive(' + PARAMSNAME + '["Panning"]);' + LB + LB;
  Result := Result + '  ' + MAPWHEELZOOM + '.setActive(' + PARAMSNAME + '["ZoomOnWheelScroll"]);' + LB + LB;

  Result := Result +
    '  if (' + PARAMSNAME + '["ShowZoomControl"]){' + LB +
    '    ' + MAPVAR + '.addControl(' + MAPZOOMCONTROLVAR + ');' + LB +
    '    ' + MAPCONTROLARRAYVAR + '.push(' + MAPZOOMCONTROLVAR + ');' + LB +
    '  }';
end;

function TTMSFNCMapsOpenLayers.GetZoomToBounds: string;
begin
  Result := '  ' + MAPVAR + '.getView().fit(' + MAPSERVICEVAR + '.proj.transformExtent([' + PARAMSNAME + '["SouthWest"]["Longitude"],' +
    PARAMSNAME + '["SouthWest"]["Latitude"],' + PARAMSNAME + '["NorthEast"]["Longitude"],' + PARAMSNAME + '["NorthEast"]["Latitude"]], ''EPSG:4326'', '
      + MAPVAR + '.getView().getProjection()), { size: ' + MAPVAR + '.getSize() })';
end;

function TTMSFNCMapsOpenLayers.IsValid: Boolean;
begin
  Result := True;
end;

procedure TTMSFNCMapsOpenLayers.RemoveScripts;
begin
  {$IFDEF WEBLIB}
  asm
    if (window.ol){
      window.ol = undefined;
    }
  end;
  {$ENDIF}
end;

end.

