{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2021 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGeocoding.OpenStreetMap;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterOpenStreetMapGeocodingService;
procedure UnRegisterOpenStreetMapGeocodingService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCGeocoding,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCGeocodingOpenStreetMapService = class;

  TTMSFNCGeocodingOpenStreetMapGeocodingFactoryService = class(TTMSFNCGeocodingFactoryService, ITMSFNCGeocodingServiceOpenStreetMap);

  TTMSFNCGeocodingOpenStreetMapService = class(TTMSFNCGeocodingOpenStreetMapGeocodingFactoryService)
  protected
    function DoCreateGeocoding: ITMSFNCCustomGeocoding; override;
  end;

type
  TTMSFNCGeocodingOpenStreetMapGeocoding = class;

  TTMSFNCGeocodingOpenStreetMapGeocoding = class(TTMSFNCCustomGeocodingInterfacedObject, ITMSFNCCustomGeocoding)
  protected
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetReverseHost: string;
    function GetPath(AAddress: string): string;
    function GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
    function GetQuery(AAddress: string; ALocale: string): string; overload;
    function GetQuery(ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string; overload;
    function GetPostData: string;
    procedure Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyGeocoding;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  GeocodingService: ITMSFNCGeocodingServiceOpenStreetMap;

procedure RegisterOpenStreetMapGeocodingService;
begin
  if not TTMSFNCGeocodingPlatformServices.Current.SupportsPlatformService(ITMSFNCGeocodingServiceOpenStreetMap, IInterface(GeocodingService)) then
  begin
    GeocodingService := TTMSFNCGeocodingOpenStreetMapService.Create;
    TTMSFNCGeocodingPlatformServices.Current.AddPlatformService(ITMSFNCGeocodingServiceOpenStreetMap, GeocodingService);
  end;
end;

procedure UnregisterOpenStreetMapGeocodingService;
begin
  TTMSFNCGeocodingPlatformServices.Current.RemovePlatformService(ITMSFNCGeocodingServiceOpenStreetMap);
end;

{ TTMSFNCGeocodingOpenStreetMapService }

function TTMSFNCGeocodingOpenStreetMapService.DoCreateGeocoding: ITMSFNCCustomGeocoding;
begin
  Result := TTMSFNCGeocodingOpenStreetMapGeocoding.Create;
end;

procedure TTMSFNCGeocodingOpenStreetMapGeocoding.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
begin

end;

constructor TTMSFNCGeocodingOpenStreetMapGeocoding.Create;
begin
  inherited;
end;

destructor TTMSFNCGeocodingOpenStreetMapGeocoding.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGeocodingOpenStreetMapGeocoding.DestroyGeocoding;
begin
  GeocodingService.DestroyGeocoding(Self);
end;

function TTMSFNCGeocodingOpenStreetMapGeocoding.GetHost: string;
begin
  Result := 'https://nominatim.openstreetmap.org';
end;

function TTMSFNCGeocodingOpenStreetMapGeocoding.GetReverseHost: string;
begin
  Result := GetHost;
end;

function TTMSFNCGeocodingOpenStreetMapGeocoding.GetIdentifier: string;
begin
  Result := 'OpenStreetMap';
end;

function TTMSFNCGeocodingOpenStreetMapGeocoding.GetPath(AAddress: string): string;
begin
  Result := '/search';
end;

function TTMSFNCGeocodingOpenStreetMapGeocoding.GetReversePath(ACoordinate: TTMSFNCMapsCoordinateRec): string;
begin
  Result := '/reverse';
end;

function TTMSFNCGeocodingOpenStreetMapGeocoding.GetPostData: string;
begin
  Result := '';
end;

function TTMSFNCGeocodingOpenStreetMapGeocoding.GetQuery(
  ACoordinate: TTMSFNCMapsCoordinateRec; ALocale: string): string;
begin
  Result := 'lat=' + FloatToStrDot(ACoordinate.Latitude)
    + '&lon=' + FloatToStrDot(ACoordinate.Longitude)
    + '&accept-language=' + Alocale
    + '&format=jsonv2';
end;

function TTMSFNCGeocodingOpenStreetMapGeocoding.GetQuery(AAddress: string; ALocale: string): string;
begin
  Result := 'q=' + AAddress
    + '&addressdetails=1'
    + '&accept-language=' + Alocale
    + '&format=jsonv2';
end;

function TTMSFNCGeocodingOpenStreetMapGeocoding.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmGet;
end;

function TTMSFNCGeocodingOpenStreetMapGeocoding.IsValid: Boolean;
begin
  Result := True;
end;

procedure TTMSFNCGeocodingOpenStreetMapGeocoding.Parse(ARequest: TTMSFNCGeocodingRequest; ARequestResult: string);
var
  d: TTMSFNCGeocodingItems;
  di: TTMSFNCGeocodingItem;

  o, jo: TJSONValue;
  jar: TJSONArray;
  i: Integer;
  oc: TJSONObject;
  error: string;

  procedure ParseGeocodingItem(Items: TTMSFNCGeocodingItems; JSON: TJSONValue);
  var
    street, number: string;
  begin
    di := Items.Add;
    di.Address := TTMSFNCUtils.GetJSONProp(JSON, 'display_name');
    di.Coordinate.Latitude := StrToFloatDot(TTMSFNCUtils.GetJSONProp(JSON, 'lat'));
    di.Coordinate.Longitude := StrToFloatDot(TTMSFNCUtils.GetJSONProp(JSON, 'lon'));

    oc := TTMSFNCUtils.GetJSONValue(JSON, 'address') as TJSONObject;
    if Assigned(oc) then
    begin

      street := TTMSFNCUtils.GetJSONProp(oc, 'road');
      if street = '' then
        street := TTMSFNCUtils.GetJSONProp(oc, 'locality');
      number := TTMSFNCUtils.GetJSONProp(oc, 'house_number');

      di.Street := Trim(street + number);
      di.StreetNumber := number;
      di.StreetName := street;

      di.PostalCode := TTMSFNCUtils.GetJSONProp(oc, 'postcode');
      di.City := TTMSFNCUtils.GetJSONProp(oc, 'city');
      if di.City = '' then
      begin
        di.City := TTMSFNCUtils.GetJSONProp(oc, 'city_district');
        if di.City = '' then
          di.City := TTMSFNCUtils.GetJSONProp(oc, 'village');
        if di.City = '' then
          di.City := TTMSFNCUtils.GetJSONProp(oc, 'town');
      end;
      di.Region := TTMSFNCUtils.GetJSONProp(oc, 'region');
      if di.Region = '' then
        di.Region := TTMSFNCUtils.GetJSONProp(oc, 'county');
      di.Province := TTMSFNCUtils.GetJSONProp(oc, 'state');
      di.Country := TTMSFNCUtils.GetJSONProp(oc, 'country');
      di.CountryCode := TTMSFNCUtils.GetJSONProp(oc, 'country_code');
    end;
  end;

begin
  if Assigned(GeocodingProperties) then
  begin
    d := ARequest.Items;

    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            error := TTMSFNCUtils.GetJSONProp(o, 'error');
            if error <> '' then
            begin
              ARequest.Status := 'error';
              ARequest.ErrorMessage := error;
            end
            else
            begin
              if o is TJSONArray then
              begin
                jar := o as TJSONArray;
                if Assigned(jar) then
                begin
                  for i := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
                  begin
                    jo := TTMSFNCUtils.GetJSONArrayItem(jar, i);
                    ParseGeocodingItem(d, jo);
                  end;
                end
              end
              else
              begin
                ParseGeocodingItem(d, o);
              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.

