{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCMaps.MapBox;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterMapBoxService;
procedure UnRegisterMapBoxService;

implementation

uses
  Classes, Math, DateUtils, Types, SysUtils, WEBLib.TMSFNCMaps, WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes
  {$IFDEF WEBLIB}
  ,Contnrs, Web
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 0; // Build nr.

  //v1.0.0.0: First release
  //v1.0.1.0: New : API version updated

type
  TTMSFNCMapsMapBoxService = class;

  TTMSFNCMapsMapBoxFactoryService = class(TTMSFNCMapsFactoryService, ITMSFNCMapsServiceMapBox);

  TTMSFNCMapsMapBoxService = class(TTMSFNCMapsMapBoxFactoryService)
  protected
    function DoCreateMaps: ITMSFNCCustomMaps; override;
  end;

type
  TTMSFNCMapsMapBox = class;

  TTMSFNCMapsMapBox = class(TTMSFNCCustomMapsInterfacedObject, ITMSFNCCustomMaps)
  protected
    procedure GetHeadLinks(const AList: TTMSFNCMapsLinksList);
    procedure RemoveScripts;
    function GetResetMap: string;
    function GetShowPopup: string;
    function GetClosePopup: string;
    function GetHeadStyle: string;
    function GetDelayLoadEvent: string;
    function GetMapsServiceCheck: string;
    function GetIdentifier: string;
    function GetInitializeMap: string;
    function GetInitializeEvents: string;
    function GetAddOrUpdateMarker: string;
    function GetDeleteMarker: string;
    function GetAddOrUpdatePolyElement: string;
    function GetDeletePolyElement: string;
    function GetZoomToBounds: string;
    function GetGetBounds: string;
    function GetSetCenterCoordinate: string;
    function GetGetCenterCoordinate: string;
    function GetGetZoomLevel: string;
    function GetSetZoomLevel: string;
    function GetLatLonToXY: string;
    function GetXYToLatLon: string;
    function GetUpdateOptions: string;
    function GetGlobalVariables: string;
    function GetAddCoordinateToArray: string;
    function GetAddHoleToArray: string;
    function GetInitializeCoordinateArray: string;
    function GetInitializeHolesArray: string;
    function IsValid: Boolean;
    procedure DestroyMaps;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  MapsService: ITMSFNCMapsServiceMapBox;

const
  MAPBOXAPIURL = 'https://api.tiles.mapbox.com/mapbox-gl-js/';
  MAPSERVICEVAR = 'window.mapboxgl';
  MAPZOOMCONTROLVAR = 'mapZoomControl';
  MAPCONTROLARRAYVAR = 'mapControls';

procedure RegisterMapBoxService;
begin
  if not TTMSFNCMapsPlatformServices.Current.SupportsPlatformService(ITMSFNCMapsServiceMapBox, IInterface(MapsService)) then
  begin
    MapsService := TTMSFNCMapsMapBoxService.Create;
    TTMSFNCMapsPlatformServices.Current.AddPlatformService(ITMSFNCMapsServiceMapBox, MapsService);
  end;
end;

procedure UnregisterMapBoxService;
begin
  TTMSFNCMapsPlatformServices.Current.RemovePlatformService(ITMSFNCMapsServiceMapBox);
end;

{ TTMSFNCMapsMapBoxService }

function TTMSFNCMapsMapBoxService.DoCreateMaps: ITMSFNCCustomMaps;
begin
  Result := TTMSFNCMapsMapBox.Create;
end;

constructor TTMSFNCMapsMapBox.Create;
begin
  inherited;
end;

destructor TTMSFNCMapsMapBox.Destroy;
begin
  inherited;
end;

procedure TTMSFNCMapsMapBox.DestroyMaps;
begin
  MapsService.DestroyMaps(Self);
end;

function TTMSFNCMapsMapBox.GetAddCoordinateToArray: string;
begin
  Result := '  ' + COORDINATEARRAYVAR + '.push([' + PARAMSNAME + '["Longitude"], ' + PARAMSNAME + '["Latitude"]]);';
end;

function TTMSFNCMapsMapBox.GetAddHoleToArray: string;
begin
  Result := '';
end;

function TTMSFNCMapsMapBox.GetAddOrUpdateMarker: string;
begin
  Result :=
  '  var loc = [' + PARAMSNAME + '["Longitude"], ' + PARAMSNAME + '["Latitude"]]' + LB + LB +

  '  if (!' + MARKERVAR + '){' + LB +
  '    var el = document.createElement(''div'');' + LB +
  '    el.style.width = ''48px'';' + LB +
  '    el.style.height = ''48px'';' + LB +
  '    el.style.zIndex = ' + PARAMSNAME + '["ZIndex"];' + LB +
  '    el.innerHTML = ''<img src="' + DEFAULT_ICONURL + '"></img>'';' + LB +
  '    ' + MARKERVAR + ' = new ' + MAPSERVICEVAR + '.Marker({element: el, draggable:' + PARAMSNAME + '["Draggable"]});' + LB +
  '    ' + MARKERVAR + '.setLngLat(loc).addTo(' + MAPVAR + ');' + LB +
  '    el.addEventListener(''click'', function(event){' + LB +
  '      event.stopPropagation();' + LB +
  '      if (' + PARAMSNAME + '["Title"] != ""){' + LB +
  '        var popup = new ' + MAPSERVICEVAR + '.Popup({ closeOnClick: true, maxWidth: "none", offset: [0, -30]}).setLngLat(loc).setHTML(' + PARAMSNAME + '["Title"]).addTo(' + MAPVAR + ');' + LB +
  '      }' + LB +
  '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerClick", ' + PARAMSNAME + '["ID"]));' + LB +
  '    })' + LB + LB +
  '    el.addEventListener(''dblclick'', function(event){' + LB +
  '      event.stopPropagation();' + LB +
  '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
  '    })' + LB + LB +
  '    el.addEventListener(''mouseup'', function(event){' + LB +
  '      if (!' + PARAMSNAME + '["Draggable"]){' + LB +
  '        event.stopPropagation();' + LB +
  '      }' + LB +
  '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
  '    } )' + LB + LB +
  '    el.addEventListener(''mousedown'', function(event){' + LB +
  '      if (!' + PARAMSNAME + '["Draggable"]){' + LB +
  '        event.stopPropagation();' + LB +
  '      }' + LB +
  '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
  '    })' + LB + LB +
  '    el.addEventListener(''mouseout'', function(event){' + LB +
  '      event.stopPropagation();' + LB +
  '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
  '    })' + LB + LB +
  '    el.addEventListener(''mouseover'', function(event){' + LB +
  '      event.stopPropagation();' + LB +
  '      ' + GETSENDEVENT + '(parseEvent(event, "MarkerMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
  '    })' + LB + LB +
  '  }else{' + LB +
  '    ' + MARKERVAR + '.setLngLat(loc);' + LB +
  '  }' + LB + LB +

  MARKERVAR + '.setDraggable(' + PARAMSNAME + '["Draggable"]);' + LB +

  'function onDragEnd() {' + LB +
  '  const lngLat = ' + MARKERVAR + '.getLngLat();' + LB +
  '  var jsonObj = getDefaultCoordinateObject();' + LB +
  '  jsonObj["Latitude"] = lngLat.latitude;' + LB +
  '  jsonObj["Longitude"] = lngLat.longitude;' + LB +
  '  ' + GETSENDEVENT + '(parseEvent(event, "MarkerDragEnd", ' + PARAMSNAME + '["ID"]), jsonObj);' + LB +
  '}' + LB +

  MARKERVAR + '.on(''dragend'', onDragEnd)' + LB +

  '  var elx = ' + MARKERVAR + '.getElement();' + LB +
  '  if (elx){' + LB +
  '    if (' + PARAMSNAME + '["IconURL"] != ""){' + LB +
  '      elx.innerHTML = "<img src=" + ' + PARAMSNAME + '["IconURL"] + "></img>";' + LB +
  '    }' + LB +
  '    if (' + PARAMSNAME + '["Visible"]){' + LB +
  '      elx.style.visibility = "visible";' + LB +
  '    }else{' + LB +
  '      elx.style.visibility = "hidden";' + LB +
  '    }' + LB +
  '  }';
end;

function TTMSFNCMapsMapBox.GetAddOrUpdatePolyElement: string;
begin
  Result :=
    '  var c = ' + PARAMSNAME + '["$type"];' + LB +
    '  var pg, dpg, pl, dpl, spg, spl;' + LB +
    '  var co = [];' + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["NorthEast"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["SouthWest"]["Latitude"]]);' + LB +
    '      co.push([' + PARAMSNAME + '["Bounds"]["SouthWest"]["Longitude"], ' + PARAMSNAME + '["Bounds"]["NorthEast"]["Latitude"]]);' + LB +
    '    break;' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '      co = createCircle(' + PARAMSNAME + '["Center"], ' + PARAMSNAME + '["Radius"], true);' + LB +
    '    break;' + LB +
    '    default:' + LB +
    '      co = ' + COORDINATEARRAYVAR + ';' + LB +
    '    break;' + LB +
    '  }' + LB + LB +

    '  switch(c){' + LB +
    '    case "TTMSFNCMapsRectangle":' + LB +
    '    case "TTMSFNCMapsCircle":' + LB +
    '    case "TTMSFNCMapsPolygon":' + LB +
    '      dpg = {' + LB +
    '      ''type'': ''Feature'',' + LB +
    '        ''geometry'': {' + LB +
    '          ''type'': ''Polygon'',' + LB +
    '          ''coordinates'': [co]' + LB +
    '        }' + LB +
    '      }' + LB + LB +

    '      spg = {' + LB +
    '        ''type'': ''geojson'',' + LB +
    '        ''data'': dpg' + LB +
    '      };' + LB +

    '      pg = {' + LB +
    '        ''id'': ' + PARAMSNAME + '["ID"] + "_polygon",' + LB +
    '        ''type'': ''fill'',' + LB +
    '        ''source'': spg, ' + LB +
    '        ''layout'': {' + LB +
    '          ''visibility'': (' + PARAMSNAME + '["Visible"] ? ''visible'' : ''none'')' + LB +
    '        },' + LB +
    '        ''paint'': {' + LB +
    '          ''fill-color'': ' + PARAMSNAME + '["FillColor"],' + LB +
    '          ''fill-opacity'': ' + PARAMSNAME + '["FillOpacity"],' + LB +
    '        }' + LB +
    '      };' + LB + LB +

    '      co.push(co[0]);' + LB +
    '    break;' + LB +
    '  }' + LB +

    '  dpl = {' + LB +
    '    ''type'': ''Feature'',' + LB +
    '    ''geometry'': {' + LB +
    '      ''type'': ''LineString'',' + LB +
    '      ''coordinates'': co' + LB +
    '    }' + LB +
    '  }' + LB + LB +

    '  spl = {' + LB +
    '    ''type'': ''geojson'',' + LB +
    '    ''data'': dpl' + LB +
    '  };' + LB +

    '  pl = {' + LB +
    '    ''id'': ' + PARAMSNAME + '["ID"] + "_polyline",' + LB +
    '    ''type'': ''line'',' + LB +
    '    ''source'': spl, ' + LB +
    '    ''layout'': {' + LB +
    '      ''visibility'': (' + PARAMSNAME + '["Visible"] ? ''visible'' : ''none'')' + LB +
    '    },' + LB +
    '    ''paint'': {' + LB +
    '      ''line-color'': ' + PARAMSNAME + '["StrokeColor"],' + LB +
    '        ''line-opacity'': ' + PARAMSNAME + '["StrokeOpacity"],' + LB +
    '        ''line-width'': ' + PARAMSNAME + '["StrokeWidth"],' + LB +
    '    }' + LB +
    '  }' + LB + LB +

    '  if (!' + POLYELEMENTVAR + '){' + LB +
    '    switch(c){' + LB +
    '      case "TTMSFNCMapsPolyline":' + LB +
    '    ' + MAPVAR + '.addLayer(pl);' + LB +
    '      break;' + LB +
    '      case "TTMSFNCMapsCircle":' + LB +
    '      case "TTMSFNCMapsRectangle":' + LB +
    '      case "TTMSFNCMapsPolygon":' + LB +
    '    ' + MAPVAR + '.addLayer(pg);' + LB +
    '    ' + MAPVAR + '.addLayer(pl);' + LB +
    '      break;' + LB +
    '    }' + LB +

    '    if (pg) {' + LB +
    '      ' + MAPVAR + '.on(''click'', pg.id, function(event){' + LB +
    '        activeElement = pl;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.on(''dblclick'', pg.id, function(event){' + LB +
    '        activeElement = pl;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.on(''mouseup'', pg.id, function(event){' + LB +
    '        activeElement = pl;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.on(''mousedown'', pg.id, function(event){' + LB +
    '        activeElement = pl;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '     ' + MAPVAR + '.on(''mouseout'', pg.id, function(event){' + LB +
    '        activeElement = pl;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.on(''mouseover'', pg.id, function(event){' + LB +
    '        activeElement = pl;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB +
    '    }' + LB + LB +

    '    if (pl && !pg) {' + LB +
    '      ' + MAPVAR + '.on(''click'', pl.id, function(event){' + LB +
    '        activeElement = pl;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.on(''dblclick'', pl.id, function(event){' + LB +
    '        activeElement = pl;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementDblClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.on(''mouseup'', pl.id, function(event){' + LB +
    '        activeElement = pl;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseUp", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.on(''mousedown'', pl.id, function(event){' + LB +
    '        activeElement = pl;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseDown", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '     ' + MAPVAR + '.on(''mouseout'', pl.id, function(event){' + LB +
    '        activeElement = pl;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseLeave", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB + LB +
    '      ' + MAPVAR + '.on(''mouseover'', pl.id, function(event){' + LB +
    '        activeElement = pl;' + LB +
    '        ' + GETSENDEVENT + '(parseEvent(event, "PolyElementMouseEnter", ' + PARAMSNAME + '["ID"]));' + LB +
    '      })' + LB +
    '    }' + LB + LB +

    GetInitializeEvents + LB + LB +

    '  }else{' + LB +
    '    if (pg) {' + LB +
    '      var pgu = ' + MAPVAR + '.getLayer(pg.id);' + LB +
    '      if (pgu){' + LB +
    '        ' + MAPVAR + '.setLayoutProperty(pgu.id, ''visibility'', (' + PARAMSNAME + '["Visible"] ? ''visible'' : ''none''));' + LB +
    '        ' + MAPVAR + '.setPaintProperty(pgu.id, ''fill-color'', ' + PARAMSNAME + '["FillColor"]);' + LB +
    '        ' + MAPVAR + '.setPaintProperty(pgu.id, ''fill-opacity'', ' + PARAMSNAME + '["FillOpacity"]);' + LB +
    '        ' + MAPVAR + '.getSource(pgu.id).setData(dpg);' + LB +
    '      }' + LB +
    '    }' + LB +

    '    if (pl) {' + LB +
    '      var plu = ' + MAPVAR + '.getLayer(pl.id);' + LB +
    '      if (plu){' + LB +
    '        ' + MAPVAR + '.setLayoutProperty(plu.id, ''visibility'', (' + PARAMSNAME + '["Visible"] ? ''visible'' : ''none''));' + LB +
    '        ' + MAPVAR + '.setPaintProperty(plu.id, ''line-color'', ' + PARAMSNAME + '["StrokeColor"]);' + LB +
    '        ' + MAPVAR + '.setPaintProperty(plu.id, ''line-opacity'', ' + PARAMSNAME + '["StrokeOpacity"]);' + LB +
    '        ' + MAPVAR + '.setPaintProperty(plu.id, ''line-width'', ' + PARAMSNAME + '["StrokeWidth"]);' + LB +
    '        ' + MAPVAR + '.getSource(plu.id).setData(dpl);' + LB +
    '      }' + LB +
    '    }' + LB +
    '  }' + LB +

    '  ' + POLYELEMENTVAR + ' = ' + PARAMSNAME + '["ID"]';
end;

function TTMSFNCMapsMapBox.GetClosePopup: string;
begin
  Result := '  ' + POPUPVAR + '.remove();';
end;

function TTMSFNCMapsMapBox.GetDelayLoadEvent: string;
begin
  Result := '  ' + MAPVAR + '.on(''load'', function () {';
end;

function TTMSFNCMapsMapBox.GetDeleteMarker: string;
begin
  Result := '  ' + MARKERVAR + '.remove();';
end;

function TTMSFNCMapsMapBox.GetDeletePolyElement: string;
begin
  Result :=
    '  var pgu = ' + MAPVAR + '.getLayer(' + POLYELEMENTVAR + ' + "_polygon");' + LB +
    '  if (pgu){' + LB +
    '    ' + MAPVAR + '.removeLayer(' + POLYELEMENTVAR + ' + "_polygon");' + LB +
    '  }' + LB +

    '  var plu = ' + MAPVAR + '.getLayer(' + POLYELEMENTVAR + ' + "_polyline");' + LB +
    '  if (plu){' + LB +
    '    ' + MAPVAR + '.removeLayer(' + POLYELEMENTVAR + ' + "_polyline");' + LB +
    '  }';
end;

function TTMSFNCMapsMapBox.GetGetBounds: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getBounds();' + LB +
    '  jsonObj["NorthEast"]["Latitude"] = loc.getNorthEast().lat;' + LB +
    '  jsonObj["NorthEast"]["Longitude"] = loc.getNorthEast().lng;' + LB +
    '  jsonObj["SouthWest"]["Latitude"] = loc.getSouthWest().lat;' + LB +
    '  jsonObj["SouthWest"]["Longitude"] = loc.getSouthWest().lng;';
end;

function TTMSFNCMapsMapBox.GetGetCenterCoordinate: string;
begin
  Result :=
    '  var loc = ' + MAPVAR + '.getCenter();' + LB +
    '  jsonObj["Latitude"] = loc.lat;' + LB +
    '  jsonObj["Longitude"] = loc.lng;';
end;

function TTMSFNCMapsMapBox.GetLatLonToXY: string;
begin
  Result :=
    '  var coordinate = [' + PARAMSNAME + '["Longitude"], ' + PARAMSNAME + '["Latitude"]];' + LB +
    '  var point = ' + MAPVAR +'.project(coordinate);' + LB +
    '  jsonObj["X"] = parseFloat(point.x);' + LB +
    '  jsonObj["Y"] = parseFloat(point.y);';
end;

function TTMSFNCMapsMapBox.GetXYToLatLon: string;
begin
  Result :=
    '  var point = [' + PARAMSNAME + '["X"], ' + PARAMSNAME + '["Y"]];' + LB +
    '  var coordinate = ' + MAPVAR +'.unproject(point);' + LB +
    '  jsonObj["Latitude"] = parseFloat(coordinate.lat);' + LB +
    '  jsonObj["Longitude"] = parseFloat(coordinate.lng);';
end;

function TTMSFNCMapsMapBox.GetGetZoomLevel: string;
begin
  Result := '  var z = ' + MAPVAR + '.getZoom();';
end;

function TTMSFNCMapsMapBox.GetGlobalVariables: string;
begin
  Result :=
    'var activeElement = null;' + LB +
    'var ' + MAPZOOMCONTROLVAR + ';' + LB +
    'var ' + MAPCONTROLARRAYVAR + ' = [];' + LB +
    'var mouseLatLng = {''Latitude'': 0, ''Longitude'': 0};' + LB +
    'function parseEvent(event, eventname, id = ''''){' + LB +
    '  var loc = {''Latitude'': 0, ''Longitude'': 0};' + LB +
    '  var x = 0.0;' + LB +
    '  var y = 0.0;' + LB +
    '  if ((eventname == "MapMoveStart") || (eventname == "MapMoveEnd")){ ' +
    '    var ptcenter = ' + MAPVAR + '.getCenter();' + LB +
    '    var lat = ptcenter.lat;' + LB +
    '    var lng = ptcenter.lng;' + LB +
    '  loc = {''Latitude'': lat, ''Longitude'': lng};' + LB +
    '  } else if (event) {' + LB +
    '    if (event.clientX && event.clientY){' + LB +
    '      x = event.clientX;' + LB +
    '      y = event.clientY;' + LB +
    '      loc = mouseLatLng;' + LB +
    '    }' + LB +
    '    else{' + LB +
    '      if (event.lngLat){' + LB +
    '        loc = {''Latitude'': event.lngLat.lat, ''Longitude'': event.lngLat.lng};' + LB +
    '        mouseLatLng = loc;' + LB +
    '      }' + LB +
    '      if (event.point){' + LB +
    '        x = event.point.x;' + LB +
    '        y = event.point.y;' + LB +
    '      }' + LB +
    '    }' + LB +
    '  }' + LB +

    '  var r = {''Coordinate'': loc, ''X'': x, ''Y'': y, ''ID'': id, ''EventName'': eventname};' + LB +
    '  return r;' + LB +
    '}' + LB + LB +

    'function MapMoveStart(event){' + LB +
    '  if (activeElement){' + LB +
    '    activeElement = null;' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMoveStart"));' + LB +
    '}' + LB + LB +
    'function MapMoveEnd(event){' + LB +
    '  if (activeElement){' + LB +
    '    activeElement = null;' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMoveEnd"));' + LB +
    '}' + LB + LB +

    'function MapClick(event){' + LB +
    '  if (activeElement){' + LB +
    '    activeElement = null;' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapClick"));' + LB +
    '}' + LB + LB +
    'function MapDblClick(event){' + LB +
    '  if (activeElement){' + LB +
    '    activeElement = null;' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapDblClick"));' + LB +
    '}' + LB + LB +
    'function MapMouseDown(event){' + LB +
    '  if (activeElement){' + LB +
    '    activeElement = null;' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMouseDown"));' + LB +
    '}' + LB + LB +
    'function MapMouseUp(event){' + LB +
    '  if (activeElement){' + LB +
    '    activeElement = null;' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMouseUp"));' + LB +
    '}' + LB + LB +
    'function MapMouseLeave(event){' + LB +
    '  if (activeElement){' + LB +
    '    activeElement = null;' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMouseLeave"));' + LB +
    '}' + LB + LB +
    'function MapMouseEnter(event){' + LB +
    '  if (activeElement){' + LB +
    '    activeElement = null;' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMousEnter"));' + LB +
    '}' + LB + LB +
    'function MapMouseMove(event){' + LB +
    '  if (activeElement){' + LB +
    '    activeElement = null;' + LB +
    '    return;' + LB +
    '  }' + LB +
    '  ' + GETSENDEVENT + '(parseEvent(event, "MapMouseMove"));' + LB +
    '}';
end;

procedure TTMSFNCMapsMapBox.GetHeadLinks(const AList: TTMSFNCMapsLinksList);
var
  APIVersion: string;
begin
  if MapsProperties.GetAPIVersion = '' then
    APIVersion := 'v2.15.0'
  else
    APIVersion := 'v' + MapsProperties.GetAPIVersion;

  AList.Add(TTMSFNCMapsLink.CreateLink(MAPBOXAPIURL + APIVersion + '/mapbox-gl.css', 'text/css', 'stylesheet'));
  AList.Add(TTMSFNCMapsLink.CreateScript(MAPBOXAPIURL + APIVersion + '/mapbox-gl.js', 'text/javascript'));
end;

function TTMSFNCMapsMapBox.GetHeadStyle: string;
begin
  Result := '';
end;

function TTMSFNCMapsMapBox.GetIdentifier: string;
begin
  Result := 'MapBox';
end;

function TTMSFNCMapsMapBox.GetInitializeCoordinateArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsMapBox.GetInitializeHolesArray: string;
begin
  Result := '[]';
end;

function TTMSFNCMapsMapBox.GetInitializeEvents: string;
begin
  Result :=
  '  ' + MAPVAR + '.on(''zoom'', function(){' + LB +
  '    ' + GETSENDEVENT + '(parseEvent(null, "ZoomChanged"));' + LB +
  '  })' + LB + LB +
//  '  ' + MAPVAR + '.on(''maptypeid_changed'', function(){' + LB +
//  '    ' + GETSENDEVENT + '(parseEvent(null, "MapTypeChanged"));' + LB +
//  '  })' + LB + LB +
  '  ' + MAPVAR + '.off(''dragstart'', MapMoveStart);' + LB +
  '  ' + MAPVAR + '.on(''dragstart'', MapMoveStart);' + LB +
  '  ' + MAPVAR + '.off(''dragend'', MapMoveEnd);' + LB +
  '  ' + MAPVAR + '.on(''dragend'', MapMoveEnd);' + LB +
  '  ' + MAPVAR + '.off(''click'', MapClick);' + LB +
  '  ' + MAPVAR + '.on(''click'', MapClick);' + LB +
  '  ' + MAPVAR + '.off(''dblclick'', MapDblClick);' + LB +
  '  ' + MAPVAR + '.on(''dblclick'', MapDblClick);' + LB +
  '  ' + MAPVAR + '.off(''mouseup'', MapMouseUp);' + LB +
  '  ' + MAPVAR + '.on(''mouseup'', MapMouseUp);' + LB +
  '  ' + MAPVAR + '.off(''mousedown'', MapMouseDown);' + LB +
  '  ' + MAPVAR + '.on(''mousedown'', MapMouseDown);' + LB +
  '  ' + MAPVAR + '.off(''mouseout'', MapMouseLeave);' + LB +
  '  ' + MAPVAR + '.on(''mouseout'', MapMouseLeave);' + LB +
  '  ' + MAPVAR + '.off(''mouseover'', MapMouseEnter);' + LB +
  '  ' + MAPVAR + '.on(''mouseover'', MapMouseEnter);' + LB +
  '  ' + MAPVAR + '.off(''mousemove'', MapMouseMove);' + LB +
  '  ' + MAPVAR + '.on(''mousemove'', MapMouseMove);';
end;

function TTMSFNCMapsMapBox.GetInitializeMap: string;
var
  v: string;
begin
  v := MAPSERVICEVAR;
  Result :=
    '  ' + v + '.accessToken = "' + MapsProperties.GetAPIKey + '";' + LB +
    '  ' + MAPVAR + ' = new ' + v + '.Map({' + LB +
    '    container: "' + MAPID +'", ' + LB +
    '    style: "mapbox://sprites/mapbox/streets-v11",' + LB +
    '    center: [' + MapsProperties.GetDefaultLongitude + ', ' + MapsProperties.GetDefaultLatitude +'], ' + LB +
    '    zoom: ' + MapsProperties.GetDefaultZoomLevel + LB +
    '  });' + LB + LB;

    Result := Result + '  ' + MAPZOOMCONTROLVAR + ' = new ' + MAPSERVICEVAR + '.NavigationControl();' + LB;

    if MapsProperties.GetShowZoomControl then
    begin
      Result := Result +
      '  ' + MAPVAR + '.addControl(' + MAPZOOMCONTROLVAR + ');' + LB +
      '  ' + MAPCONTROLARRAYVAR + '.push(' + MAPZOOMCONTROLVAR + ');' + LB + LB;
    end;

    if MapsProperties.GetZoomOnDblClick then
    begin
      Result := Result +
      '  ' + MAPVAR + '.doubleClickZoom.enable();' + LB + LB;
    end
    else
    begin
      Result := Result +
      '  ' + MAPVAR + '.doubleClickZoom.disable();' + LB + LB;
    end;

    if MapsProperties.GetZoomOnWheelScroll then
    begin
      Result := Result +
      '  ' + MAPVAR + '.scrollZoom.enable();' + LB + LB;
    end
    else
    begin
      Result := Result +
      '  ' + MAPVAR + '.scrollZoom.disable();' + LB + LB;
    end;

    if MapsProperties.GetPanning then
    begin
      Result := Result +
      '  ' + MAPVAR + '.dragPan.enable();' + LB + LB;
    end
    else
    begin
      Result := Result +
      '  ' + MAPVAR + '.dragPan.disable();' + LB + LB;
    end;

    Result := Result + '  ' + MAPVAR + '.on(''load'', function(){' + MAPVAR + '.setLayoutProperty(''country-label'', ''text-field'', [''get'',''name_' + MapsProperties.GetLocale(mlmCountry) + ''']);});;';
end;

function TTMSFNCMapsMapBox.GetMapsServiceCheck: string;
begin
  Result := '!' + MAPSERVICEVAR;
end;

function TTMSFNCMapsMapBox.GetResetMap: string;
begin
  Result := '';
end;

function TTMSFNCMapsMapBox.GetSetCenterCoordinate: string;
begin
  Result := '  ' + MAPVAR + '.flyTo({duration: 0, center: [' + PARAMSNAME + '["Longitude"],' + PARAMSNAME + '["Latitude"]]});';
end;

function TTMSFNCMapsMapBox.GetSetZoomLevel: string;
begin
  Result := '  ' + MAPVAR + '.setZoom(' + PARAMSNAME + ');';
end;

function TTMSFNCMapsMapBox.GetShowPopup: string;
begin
  Result :=
    '  var loc = [' + PARAMSNAME + '["Longitude"], ' + PARAMSNAME + '["Latitude"]]' + LB +
    POPUPVAR + ' = new ' + MAPSERVICEVAR + '.Popup({ closeOnClick: true, maxWidth: "none", offset: [' + PARAMSNAME + '["OffsetX"], ' + PARAMSNAME + '["OffsetY"]]}).setLngLat(loc).setHTML(' + PARAMSNAME + '["Text"]).addTo(' + MAPVAR + ');';
end;

function TTMSFNCMapsMapBox.GetUpdateOptions: string;
begin
  Result := Result +
    '  ' + MAPCONTROLARRAYVAR + '.forEach(removeElement);' + LB +
    '  function removeElement(value){' + LB +
    '    ' + MAPVAR + '.removeControl(value);' + LB +
    '  }' + LB +
    '  ' + MAPCONTROLARRAYVAR + ' = [];' + LB + LB;

  Result := Result +
    '  if (' + PARAMSNAME + '["ShowZoomControl"]){' + LB +
    '    ' + MAPVAR + '.addControl(' + MAPZOOMCONTROLVAR + ');' + LB +
    '    ' + MAPCONTROLARRAYVAR + '.push(' + MAPZOOMCONTROLVAR + ');' + LB +
    '  }' + LB + LB;

  Result := Result +
    '  if (' + PARAMSNAME + '["ZoomOnDblClick"]){' + LB +
    '    ' + MAPVAR + '.doubleClickZoom.enable();' + LB +
    '  }' + LB +
    '  else{' + LB +
    '    ' + MAPVAR + '.doubleClickZoom.disable();' + LB +
    '  }' + LB + LB;

  Result := Result +
    '  if (' + PARAMSNAME + '["ZoomOnWheelScroll"]){' + LB +
    '    ' + MAPVAR + '.scrollZoom.enable();' + LB +
    '  }' + LB +
    '  else{' + LB +
    '    ' + MAPVAR + '.scrollZoom.disable();' + LB +
    '  }' + LB + LB;

  Result := Result +
    '  if (' + PARAMSNAME + '["Panning"]){' + LB +
    '    ' + MAPVAR + '.dragPan.enable();' + LB +
    '  }' + LB +
    '  else{' + LB +
    '    ' + MAPVAR + '.dragPan.disable();' + LB +
    '  }' + LB + LB;

  Result := Result + '  ' + MAPVAR + '.setLayoutProperty(''country-label'', ''text-field'', [''get'',''name_'' + ' + PARAMSNAME + '["Locale"].slice(0, ' + PARAMSNAME + '["Locale"].indexOf(''-'')) + '''']);';
end;

function TTMSFNCMapsMapBox.GetZoomToBounds: string;
begin
  Result :=
    '  var sw = [' + PARAMSNAME + '["SouthWest"]["Longitude"], ' + PARAMSNAME + '["SouthWest"]["Latitude"]];' + LB +
    '  var ne = [' + PARAMSNAME + '["NorthEast"]["Longitude"], ' + PARAMSNAME + '["NorthEast"]["Latitude"]];' + LB +
    '  ' + MAPVAR + '.fitBounds([sw, ne], {duration: 0});';
end;

function TTMSFNCMapsMapBox.IsValid: Boolean;
begin
  Result := (MapsProperties.GetAPIKey <> '');
end;

procedure TTMSFNCMapsMapBox.RemoveScripts;
begin
  {$IFDEF WEBLIB}
  asm
    if (window.mapboxgl){
      window.mapboxgl = undefined;
    }
  end;
  {$ENDIF}
end;

end.


