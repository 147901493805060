{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCMapsCommonTypes;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCTypes
  {$IFNDEF LCLWEBLIB}
  ,XmlIntf, XMLDom, XMLDoc
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,XMLRead, XMLWrite, DOM
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,web
  {$ENDIF}
  ;

const
  DEFAULT_LOCALE = 'en-US';
  DEFAULT_LATITUDE = 40.689247;
  DEFAULT_LONGITUDE = -74.044502;
  DEFAULT_ICONURL = 'https://cdn.mapmarker.io/api/v1/pin?text=P&size=50&hoffset=1';

type
  TTMSFNCMapsAnchorPointRec = record
    X: Single;
    Y: Single;
  end;

  TTMSFNCMapsCoordinateRec = record
    Longitude: Double;
    Latitude: Double;
    Elevation: Double;
    TimeStamp: TDateTime;
    HasElevation: Boolean;
    HasTimeStamp: Boolean;
    DataObject: TObject;
    Description: String;
  end;

  TTMSFNCMapsCoordinateRecArray = array of TTMSFNCMapsCoordinateRec;
  TTMSFNCMapsCoordinateRecArrayArray = array of TTMSFNCMapsCoordinateRecArray;
  TTMSFNCMapsCoordinateRecArrayArrayArray = array of TTMSFNCMapsCoordinateRecArrayArray;

  {$IFDEF WEBLIB}
  TDomNode = TJSNode;
  TXMLDocument = TJSDocument;

  TJSNodeListHelper = class helper for TJSNodeList
    function FindNode(ANodeName: string): TJSNode;
    function IndexOf(const Name: string): NativeInt; overload;
    function GetCount: NativeInt;
    property Count: NativeInt read GetCount;
  end;
  {$ENDIF}

  {$IFNDEF LCLWEBLIB}
  TTMSFNCMapsXMLNode = IXMLNode;
  TTMSFNCMapsXMLDocument = IXMLDocument;
  TTMSFNCMapsXMLDomNode = IDOMNode;
  {$ELSE}
  TTMSFNCMapsXMLNode = TDomNode;
  TTMSFNCMapsXMLDomNode = TDomNode;
  TTMSFNCMapsXMLDocument = TXMLDocument;
  {$ENDIF}

  TTMSFNCMapsGPXTrackRec = record
    Segments: TTMSFNCMapsCoordinateRecArrayArray;
    Name: string;
  end;

  TTMSFNCMapsGPXTrackEventData = class(TPersistent)
  private
    FNode: TTMSFNCMapsXMLDOMNode;
    FData: TTMSFNCMapsGPXTrackRec;
  public
    constructor Create; virtual;
    destructor Destroy; override;
    property Data: TTMSFNCMapsGPXTrackRec read FData write FData;
    property Node: TTMSFNCMapsXMLDOMNode read FNode write FNode;
  end;

  TTMSFNCMapsGPXSegmentEventData = class(TPersistent)
  private
    FNode: TTMSFNCMapsXMLDOMNode;
    FData: TTMSFNCMapsCoordinateRec;
  public
    constructor Create; virtual;
    destructor Destroy; override;
    property Data: TTMSFNCMapsCoordinateRec read FData write FData;
    property Node: TTMSFNCMapsXMLDOMNode read FNode write FNode;
  end;

  TTMSFNCMapsGPXMetaData = record
    AuthorName: string;
    AuthorLink: string;
    TrackName: string;
    TrackType: string;
  end;

  TTMSFNCMapWayPointRec = record
    WayPoint: TTMSFNCMapsCoordinateRec;
    Name: string;
  end;

  TTMSFNCMapsGPXRec = record
    Tracks: array of TTMSFNCMapsGPXTrackRec;
    WayPoints: array of TTMSFNCMapWayPointRec;
  end;

  TTMSFNCMapsGPXTrackEvent = procedure(Sender: TObject; AEventData: TTMSFNCMapsGPXTrackEventData) of object;
  TTMSFNCMapsGPXSegmentEvent = procedure(Sender: TObject; AEventData: TTMSFNCMapsGPXSegmentEventData) of object;

  TTMSFNCMapsLocaleMode = (mlmDefault, mlmCountry, mlmLanguage);

  TTMSFNCMapsBoundsRec = record
    NorthEast: TTMSFNCMapsCoordinateRec;
    SouthWest: TTMSFNCMapsCoordinateRec;
  end;

  TTMSFNCMapsCoordinate = class(TPersistent)
  private
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FLatitude: Double;
    FLongitude: Double;
    FOnChange: TNotifyEvent;
    procedure SetLatitude(const Value: Double);
    procedure SetLongitude(const Value: Double);
    function IsLatitudeStored: Boolean;
    function IsLongitudeStored: Boolean;
    function GetToRec: TTMSFNCMapsCoordinateRec;
    procedure SetToRec(const Value: TTMSFNCMapsCoordinateRec);
  protected
    procedure UpdateCoordinate; virtual;
  public
    constructor Create; overload; virtual;
    constructor Create(ACoordinate: TTMSFNCMapsCoordinateRec); overload; virtual;
    constructor Create(ALatitude: Double; ALongitude: Double); overload; virtual;
    procedure Assign(Source: TPersistent); override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property ToRec: TTMSFNCMapsCoordinateRec read GetToRec write SetToRec;
  published
    property Longitude: Double read FLongitude write SetLongitude stored IsLongitudeStored nodefault;
    property Latitude: Double read FLatitude write SetLatitude stored IsLatitudeStored nodefault;
  end;

  TTMSFNCMapsBounds = class(TPersistent)
  private
    FNorthEast: TTMSFNCMapsCoordinate;
    FSouthWest: TTMSFNCMapsCoordinate;
    FOnChange: TNotifyEvent;
    FDataPointer: Pointer;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    function GetToRec: TTMSFNCMapsBoundsRec;
    procedure SetToRec(const Value: TTMSFNCMapsBoundsRec);
  protected
    procedure DoCoordinateChanged(Sender: TObject);
  public
    constructor Create; overload; virtual;
    constructor Create(ANorthEast: TTMSFNCMapsCoordinateRec; ASouthWest: TTMSFNCMapsCoordinateRec); overload; virtual;
    procedure Assign(Source: TPersistent); override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    destructor Destroy; override;
    property ToRec: TTMSFNCMapsBoundsRec read GetToRec write SetToRec;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property NorthEast: TTMSFNCMapsCoordinate read FNorthEast;
    property SouthWest: TTMSFNCMapsCoordinate read FSouthWest;
  end;

  TTMSFNCMapsCoordinateItem = class(TCollectionItem)
  private
    FOwner: TPersistent;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FCoordinate: TTMSFNCMapsCoordinate;
    procedure SetCoordinate(const Value: TTMSFNCMapsCoordinate);
    function GetLatitude: Double;
    function GetLongitude: Double;
    procedure SetLatitude(const Value: Double);
    procedure SetLongitude(const Value: Double);
    function IsLatitudeStored: Boolean;
    function IsLongitudeStored: Boolean;
  protected
    procedure UpdateCoordinateItem; virtual;
    procedure CoordinateChanged(Sender: TObject);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property Coordinate: TTMSFNCMapsCoordinate read FCoordinate write SetCoordinate;
  published
    property Longitude: Double read GetLongitude write SetLongitude stored IsLongitudeStored nodefault;
    property Latitude: Double read GetLatitude write SetLatitude stored IsLatitudeStored nodefault;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCMapsCoordinates = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCMapsCoordinates = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCMapsCoordinateItem>)
  {$ENDIF}
  private
    FBounds: TTMSFNCMapsBounds;
    FOwner: TPersistent;
    FOnBeginUpdate: TNotifyEvent;
    FOnEndUpdate: TNotifyEvent;
    FOnUpdateCoordinates: TNotifyEvent;
    function GetItem(Index: Integer): TTMSFNCMapsCoordinateItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMapsCoordinateItem);
    function GetBounds: TTMSFNCMapsBounds;
    function GetArray: TTMSFNCMapsCoordinateRecArray;
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
    procedure UpdateCoordinates; virtual;
  public
    constructor Create(AOwner: TPersistent); virtual;
    destructor Destroy; override;
    property Items[Index: Integer]: TTMSFNCMapsCoordinateItem read GetItem write SetItem; default;
    procedure Clear; virtual;
    function Add: TTMSFNCMapsCoordinateItem;
    property Bounds: TTMSFNCMapsBounds read GetBounds;
    function Insert(Index: Integer): TTMSFNCMapsCoordinateItem;
    property OnBeginUpdate: TNotifyEvent read FOnBeginUpdate write FOnBeginUpdate;
    property OnEndUpdate: TNotifyEvent read FOnEndUpdate write FOnEndUpdate;
    property OnUpdateCoordinates: TNotifyEvent read FOnUpdateCoordinates write FOnUpdateCoordinates;
    property ToArray: TTMSFNCMapsCoordinateRecArray read GetArray;
  end;

  TTMSFNCMapsBasePoint = class(TPersistent)
  private
    FX: Single;
    FY: Single;
    FOnChange: TNotifyEvent;
    procedure SetY(const Value: Single);
    procedure SetX(const Value: Single);
    function IsYStored: Boolean;
    function IsXStored: Boolean;
  protected
    procedure UpdatePoint;
  public
    constructor Create; virtual;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  protected
    property X: Single read FX write SetX stored IsXStored;
    property Y: Single read FY write SetY stored IsYStored;
  end;

  TTMSFNCMapsAnchorPoint = class(TTMSFNCMapsBasePoint)
  published
    property X;
    property Y;
  end;

  TTMSFNCMapsPlusCode = class
  private const
    SeparatorPosition: Integer = 8;
    SeparatorCharacter: Char = '+';
    PaddingCharacter: Char = '0';
    GridColumns: Integer = 4;
    GridRows: Integer = 5;
    CodeAlphaBet: array[0..19] of string = ('2', '3', '4', '5', '6', '7', '8', '9', 'C', 'F', 'G', 'H', 'J', 'M', 'P', 'Q', 'R', 'V', 'W', 'X');
    EncodingBase: Integer = 20;
    LatitudeMax: Integer = 90;
    LongitudeMax: Integer = 180;
    GridRowsMultiplier: Integer = 3125;
    GridColumnsMultiplier: Integer = 1024;
    LatIntegerMultiplier: Int64 = 25000000;
    LngIntegerMultiplier: Int64 = 8192000;
    LatMspValue: Int64 = 10000000000;
    LngMspValue: Int64 = 3276800000;
    PairCodeLength: Integer = 10;
    MaxDigitCount: Integer = 15;
    GridCodeLength: Integer = 5;
  protected
    class function DigitValueOf(digit: Char): Integer;
  public
    class function Encode(ACoordinate: TTMSFNCMapsCoordinateRec): string;
    class function Decode(APlusCode: string): TTMSFNCMapsBoundsRec;
  end;

function StrToFloatDot(AValue: string): Double;
function FloatToStrDot(AValue: Double): string;
function TryStrToFloatDot(AValue: string; var AResult: Double): Boolean;
function CenterCoordinate: TTMSFNCMapsCoordinateRec;
function DefaultCoordinate: TTMSFNCMapsCoordinateRec;
function EmptyCoordinate: TTMSFNCMapsCoordinateRec;
function CreateCoordinate(const ALatitude: Double; const ALongitude: Double): TTMSFNCMapsCoordinateRec;
function CreateBounds(ACoordinate1, ACoordinate2: TTMSFNCMapsCoordinateRec): TTMSFNCMapsBoundsRec; overload;
function CreateBounds(ACoordinates: TTMSFNCMapsCoordinateRecArray): TTMSFNCMapsBoundsRec; overload;
function CreateBounds(ACoordinatesArray: TTMSFNCMapsCoordinateRecArrayArray): TTMSFNCMapsBoundsRec; overload;
function CreateBounds(ACoordinatesArrayArray: TTMSFNCMapsCoordinateRecArrayArrayArray): TTMSFNCMapsBoundsRec; overload;
function CreateBounds(ANorthLatitude, AEastLongitude, ASouthLatitude, AWestLongitude: Double): TTMSFNCMapsBoundsRec; overload;
function CreateCircle(ACenter: TTMSFNCMapsCoordinateRec; ARadius: Double): TTMSFNCMapsCoordinateRecArray;
function BoundsCenter(ABounds: TTMSFNCMapsBoundsRec): TTMSFNCMapsCoordinateRec;
function BoundsRectangle(ABounds:  TTMSFNCMapsBoundsRec): TTMSFNCMapsCoordinateRecArray;
function MeasureDistance(ACoordinate1: TTMSFNCMapsCoordinateRec; ACoordinate2: TTMSFNCMapsCoordinateRec): Double;
function MeasureArea(ACoordinatesArray: TTMSFNCMapsCoordinateRecArray): Double;
function IsPointInArea(ALatitude: Double; ALongitude: Double; ACoordinatesArray: TTMSFNCMapsCoordinateRecArray): Boolean;
function CalculateCoordinate(ACoordinate: TTMSFNCMapsCoordinateRec; ABearing: Double; ADistance: Double): TTMSFNCMapsCoordinateRec;
function CalculateBearing(ACoordinate1: TTMSFNCMapsCoordinateRec; ACoordinate2: TTMSFNCMapsCoordinateRec): Double;
function ParseLocale(ALocale: string; AMode: TTMSFNCMapsLocaleMode = mlmDefault): string;
function LoadResourceFile(AResName: string): string;
function DefaultGPXMetaData: TTMSFNCMapsGPXMetaData;
function CreateGPXMetaData(AAuthorName, AAuthorLink, ATrackName, ATrackType: string): TTMSFNCMapsGPXMetaData;
function SaveToGPX(ACoordinates: TTMSFNCMapsCoordinateRecArray; AMetaData: TTMSFNCMapsGPXMetaData;
  IncludeElevation: Boolean = True; IncludeTimeStamp: Boolean = True; AInstructions: string = ''): string; overload;
function SaveToGPX(ASegments: TTMSFNCMapsCoordinateRecArrayArray; AMetaData: TTMSFNCMapsGPXMetaData;
  IncludeElevation: Boolean = True; IncludeTimeStamp: Boolean = True; AInstructions: TStringList = nil): string; overload;

implementation

uses
  WEBLib.TMSFNCUtils, SysUtils, Math;

{$R 'TMSFNCMaps.res'}

const
//  EarthRadius = 6371000;
  EarthRadius = 6378137;

function CreateGPXMetaData(AAuthorName, AAuthorLink, ATrackName, ATrackType: string): TTMSFNCMapsGPXMetaData;
var
  md: TTMSFNCMapsGPXMetaData;
begin
  md.AuthorName := AAuthorName;
  md.AuthorLink := AAuthorLink;
  md.TrackName := ATrackName;
  md.TrackType := ATrackType;
end;

function DefaultGPXMetaData: TTMSFNCMapsGPXMetaData;
begin
  Result := CreateGPXMetaData('TMS', 'http://www.tmssoftware.com', 'MyTrack', 'Track');
end;

function ParseLocale(ALocale: string; AMode: TTMSFNCMapsLocaleMode = mlmDefault): string;
var
  s: string;
  sl: TStringList;
begin
  Result := ALocale;

  case AMode of
    mlmCountry, mlmLanguage:
    begin
      s := Result;
      sl := TStringList.Create;
      try
        TTMSFNCUtils.Split('-', s, sl);
        if sl.Count >= 2 then
        begin
          case AMode of
            mlmCountry: Result := sl[0];
            mlmLanguage: Result := sl[1];
          end;
        end;
      finally
        sl.Free;
      end;
    end;
  end;
end;

function CalculateBearing(ACoordinate1: TTMSFNCMapsCoordinateRec; ACoordinate2: TTMSFNCMapsCoordinateRec): Double;
var
  a1, a2, d1, x, y, a: Double;
begin
  a1 := DegToRad(ACoordinate1.Latitude);
  a2 := DegToRad(ACoordinate2.Latitude);
  d1 := DegToRad(ACoordinate2.Longitude - ACoordinate1.Longitude);
  x := Cos(a1) * Sin(a2) - Sin(a1) * Cos(a2) * Cos(d1);
  y := Sin(d1) * Cos(a2);
  a := ArcTan2(y, x);
  Result := RadToDeg(a);
end;

function CalculateCoordinate(ACoordinate: TTMSFNCMapsCoordinateRec; ABearing: Double; ADistance: Double): TTMSFNCMapsCoordinateRec;
var
  a, ad, r, a1, sina2, a2, y, x, d1, d2: Double;
begin
  r := EarthRadius;
  a := ADistance / r;
  ad := DegToRad(ABearing);

  a1 := DegToRad(ACoordinate.Latitude);
  d1 := DegToRad(ACoordinate.Longitude);
  sina2 := Sin(a1) * Cos(a) + Cos(a1) * Sin(a) * Cos(ad);
  a2 := ArcSin(sina2);
  y := Sin(ad) * Sin(a) * Cos(a1);
  x := Cos(a) - Sin(a1) * sina2;
  d2 := d1 + ArcTan2(y, x);

  Result.Latitude := RadToDeg(a2);
  Result.Longitude := RadToDeg(d2);
end;

function MeasureDistance(ACoordinate1: TTMSFNCMapsCoordinateRec; ACoordinate2: TTMSFNCMapsCoordinateRec): Double;
var
  r, a1, a2, d1, d2, a, c: Double;
begin
  r := EarthRadius;
  a1 := DegToRad(ACoordinate1.Latitude);
  a2 := DegToRad(ACoordinate2.Latitude);
  d1 := DegToRad(ACoordinate2.Latitude - ACoordinate1.Latitude);
  d2 := DegToRad(ACoordinate2.Longitude - ACoordinate1.Longitude);

  a := Sin(d1 / 2) * Sin(d1 / 2) + Cos(a1) * Cos(a2) * Sin(d2 / 2) * Sin(d2 / 2);
  c := 2 * ArcTan2(SQRT(a), SQRT(1 - a));
  Result := r * c;
end;

function MeasureArea(ACoordinatesArray: TTMSFNCMapsCoordinateRecArray): Double;
var
  i, pointsCount: Integer;
  area, d2r, radius: Double;
  p1, p2: TTMSFNCMapsCoordinateRec;
begin
  pointsCount := Length(ACoordinatesArray);
  area := 0;
  d2r := Pi / 180;
  radius := EarthRadius;

  if pointsCount <= 2 then
  begin
    area := 0;
  end
  else
  begin

    for i := 0 to pointsCount - 1 do
    begin
        p1 := ACoordinatesArray[i];
        p2 := ACoordinatesArray[(i + 1) mod pointsCount];
        area := area + ((p2.Longitude - p1.Longitude) * d2r) *
                (2 + Sin(p1.Latitude * d2r) + Sin(p2.Latitude * d2r));
    end;
    area := area * radius * radius / 2.0;
    area := abs(area);
  end;

  Result := area;
end;

function IsPointInArea(ALatitude: Double; ALongitude: Double; ACoordinatesArray: TTMSFNCMapsCoordinateRecArray): Boolean;
var
  i, j, pointsCount: Integer;
  x, xi, xj, y, yi, yj: Double;
begin
  pointsCount := Length(ACoordinatesArray);
  Result := False;

  x := ALatitude;
  y := ALongitude;
  j := pointsCount - 1;

  for i := 0 to pointsCount - 1 do
  begin
    xi := ACoordinatesArray[i].Latitude;
    yi := ACoordinatesArray[i].Longitude;

    xj := ACoordinatesArray[j].Latitude;
    yj := ACoordinatesArray[j].Longitude;

//    Result := ((yi > y) <> (yj > y)) and (x < (xj - xi) * (y - yi) / (yj - yi) + xi);

    if (((yi < y) and (yj >= y) or (yj < y) and (yi >= y)) and ((xi <= x) or (xj <= x))) then
    begin
      Result := Result xor ((xi + (y-yi)*(xj-xi)/(yj-yi)) < x);
    end;

    j := i;
  end;
end;

function BoundsCenter(ABounds: TTMSFNCMapsBoundsRec): TTMSFNCMapsCoordinateRec;
begin
  Result.Longitude := (ABounds.SouthWest.Longitude + ABounds.NorthEast.Longitude) / 2;
  Result.Latitude := (ABounds.SouthWest.Latitude + ABounds.NorthEast.Latitude) / 2;
end;

function BoundsRectangle(ABounds: TTMSFNCMapsBoundsRec): TTMSFNCMapsCoordinateRecArray;
begin
  SetLength(Result, 5);
  Result[0] := CreateCoordinate(ABounds.NorthEast.Latitude, ABounds.SouthWest.Longitude);
  Result[1] := ABounds.NorthEast;
  Result[2] := CreateCoordinate(ABounds.SouthWest.Latitude, ABounds.NorthEast.Longitude);
  Result[3] := ABounds.SouthWest;
  Result[4] := Result[0];
end;

function CreateBounds(ANorthLatitude, AEastLongitude, ASouthLatitude, AWestLongitude: Double): TTMSFNCMapsBoundsRec; overload;
begin
  Result.NorthEast := CreateCoordinate(ANorthLatitude, AEastLongitude);
  Result.SouthWest := CreateCoordinate(ASouthLatitude, AWestLongitude);
end;

function CreateBounds(ACoordinatesArrayArray: TTMSFNCMapsCoordinateRecArrayArrayArray): TTMSFNCMapsBoundsRec;
var
  maxlat, minlat: double;
  maxlon, minlon: double;
  i, j, k: Integer;
begin
  maxlon := -180;
  maxlat := -90;
  minlon := 180;
  minlat := 90;

  for i := 0 to Length(ACoordinatesArrayArray) - 1 do
  begin
    for j := 0 to Length(ACoordinatesArrayArray[I]) - 1 do
    begin
      for k := 0 to Length(ACoordinatesArrayArray[I][J]) - 1 do
      begin
        if ACoordinatesArrayArray[i][j][k].Longitude < minlon then
          minlon := ACoordinatesArrayArray[i][j][k].Longitude;

        if ACoordinatesArrayArray[i][j][k].Latitude < minlat then
          minlat := ACoordinatesArrayArray[i][j][k].Latitude;

        if ACoordinatesArrayArray[i][j][k].Longitude > maxlon then
          maxlon := ACoordinatesArrayArray[i][j][k].Longitude;

        if ACoordinatesArrayArray[i][j][k].Latitude > maxlat then
          maxlat := ACoordinatesArrayArray[i][j][k].Latitude;
      end;
    end;
  end;

  if (maxlon = -180) then
    maxlon := 180;

  if (maxlat = -90) then
    maxlat := 90;

  if (minlon = 180) then
    minlon := -180;

  if (minlat = 90) then
    minlat := -90;

  Result.NorthEast.Latitude := maxlat;
  Result.NorthEast.Longitude := maxlon;

  Result.SouthWest.Latitude := minlat;
  Result.SouthWest.Longitude := minlon;

end;

function CreateBounds(ACoordinatesArray: TTMSFNCMapsCoordinateRecArrayArray): TTMSFNCMapsBoundsRec;
var
  arr: TTMSFNCMapsCoordinateRecArrayArrayArray;
begin
  SetLength(arr, 1);
  arr[0] := ACoordinatesArray;
  Result := CreateBounds(arr);
end;

function CreateBounds(ACoordinate1, ACoordinate2: TTMSFNCMapsCoordinateRec): TTMSFNCMapsBoundsRec;
var
  arr: TTMSFNCMapsCoordinateRecArray;
begin
  SetLength(arr, 2);
  arr[0] := ACoordinate1;
  arr[1] := ACoordinate2;
  Result := CreateBounds(arr);
end;

function CreateBounds(ACoordinates: TTMSFNCMapsCoordinateRecArray): TTMSFNCMapsBoundsRec;
var
  arr: TTMSFNCMapsCoordinateRecArrayArrayArray;
  arrc: TTMSFNCMapsCoordinateRecArrayArray;
begin
  SetLength(arrc, 1);
  arrc[0] := ACoordinates;
  SetLength(arr, 1);
  arr[0] := arrc;
  Result := CreateBounds(arr);
end;

function EmptyCoordinate: TTMSFNCMapsCoordinateRec;
begin
  Result.Latitude := 0;
  Result.Longitude := 0;
  Result.Elevation := 0;
  Result.TimeStamp := 0;
  Result.HasElevation := False;
  Result.HasTimeStamp := False;
  Result.DataObject := nil;
end;

function DefaultCoordinate: TTMSFNCMapsCoordinateRec;
begin
  Result.Latitude := DEFAULT_LATITUDE;
  Result.Longitude := DEFAULT_LONGITUDE;
  Result.Elevation := 0;
  Result.TimeStamp := 0;
  Result.HasElevation := False;
  Result.HasTimeStamp := False;
  Result.DataObject := nil;
end;

function CenterCoordinate: TTMSFNCMapsCoordinateRec;
begin
  Result.Latitude := 0;
  Result.Longitude := 0;
  Result.Elevation := 0;
  Result.TimeStamp := 0;
  Result.HasElevation := False;
  Result.HasTimeStamp := False;
  Result.DataObject := nil;
end;

function TryStrToFloatDot(AValue: string; var AResult: Double): Boolean;
var
  {$IFDEF WEBLIB}
  d, t: string;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  d, t: Char;
  {$ENDIF}
begin
  t := FormatSettings.ThousandSeparator;
  d := FormatSettings.DecimalSeparator;
  FormatSettings.DecimalSeparator := '.';
  FormatSettings.ThousandSeparator := ',';
  Result := TryStrToFloat(AValue, AResult);
  FormatSettings.ThousandSeparator := t;
  FormatSettings.DecimalSeparator := d;
end;

function StrToFloatDot(AValue: string): Double;
var
  {$IFDEF WEBLIB}
  d, t: string;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  d, t: Char;
  {$ENDIF}
begin
  t := FormatSettings.ThousandSeparator;
  d := FormatSettings.DecimalSeparator;
  FormatSettings.DecimalSeparator := '.';
  FormatSettings.ThousandSeparator := ',';
  Result := StrToFloat(AValue);
  FormatSettings.ThousandSeparator := t;
  FormatSettings.DecimalSeparator := d;
end;

function FloatToStrDot(AValue: Double): string;
var
  {$IFDEF WEBLIB}
  d, t: string;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  d, t: Char;
  {$ENDIF}
begin
  t := FormatSettings.ThousandSeparator;
  d := FormatSettings.DecimalSeparator;
  FormatSettings.DecimalSeparator := '.';
  FormatSettings.ThousandSeparator := ',';
  Result := FloatToStr(AValue);
  FormatSettings.ThousandSeparator := t;
  FormatSettings.DecimalSeparator := d;
end;

function CreateCircle(ACenter: TTMSFNCMapsCoordinateRec; ARadius: Double): TTMSFNCMapsCoordinateRecArray;
var
  I: Integer;
const
  stp = 100;
begin
  for I := 0 to stp do
  begin
    SetLength(Result, Length(Result) + 1);
    Result[Length(Result) - 1] := CalculateCoordinate(ACenter, I * -360 / stp, ARadius);
  end;
end;

function CreateCoordinate(const ALatitude: Double; const ALongitude: Double): TTMSFNCMapsCoordinateRec;
begin
  Result.Latitude := ALatitude;
  Result.Longitude := ALongitude;
  Result.Elevation := 0;
  Result.TimeStamp := 0;
  Result.HasElevation := False;
  Result.HasTimeStamp := False;
  Result.DataObject := nil;
end;

{ TTMSFNCMapsCoordinates }

function TTMSFNCMapsCoordinates.Add: TTMSFNCMapsCoordinateItem;
begin
  Result := TTMSFNCMapsCoordinateItem(inherited Add);
end;

procedure TTMSFNCMapsCoordinates.Clear;
var
  ci: TCollectionItem;
begin
  if Assigned(OnBeginUpdate) then
    OnBeginUpdate(Self);

  if Count > 0 then
  begin
    while Count > 0 do
    begin
      ci := TCollectionItem(Items[Count - 1]);
      ci.Free;
    end;
  end;

  if Assigned(OnEndUpdate) then
    OnEndUpdate(Self);
end;

constructor TTMSFNCMapsCoordinates.Create(AOwner: TPersistent);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCMapsCoordinates.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCMapsCoordinateItem;
end;

destructor TTMSFNCMapsCoordinates.Destroy;
begin
  if Assigned(FBounds) then
    FBounds.Free;
  inherited;
end;

function TTMSFNCMapsCoordinates.GetArray: TTMSFNCMapsCoordinateRecArray;
var
  I: Integer;
  r: TTMSFNCMapsCoordinateRec;
begin
  SetLength(Result, Count);
  for I := 0 to Count - 1 do
  begin
    r.Longitude := Self[I].Longitude;
    r.Latitude := Self[I].Latitude;
    r.Elevation := 0;
    r.TimeStamp := 0;
    r.HasElevation := False;
    r.HasTimeStamp := False;
    r.DataObject := nil;
    Result[I] := r;
  end;
end;

function TTMSFNCMapsCoordinates.GetBounds: TTMSFNCMapsBounds;
var
  b: TTMSFNCMapsBoundsRec;
begin
  if not Assigned(FBounds) then
    FBounds := TTMSFNCMapsBounds.Create;

  b := CreateBounds(ToArray);

  FBounds.NorthEast.Longitude := b.NorthEast.Longitude;
  FBounds.NorthEast.Latitude := b.NorthEast.Latitude;
  FBounds.SouthWest.Longitude := b.SouthWest.Longitude;
  FBounds.SouthWest.Latitude := b.SouthWest.Latitude;

  Result := FBounds;
end;

function TTMSFNCMapsCoordinates.GetItem(
  Index: Integer): TTMSFNCMapsCoordinateItem;
begin
  Result := TTMSFNCMapsCoordinateItem(inherited Items[Index]);
end;

function TTMSFNCMapsCoordinates.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCMapsCoordinates.Insert(
  Index: Integer): TTMSFNCMapsCoordinateItem;
begin
  Result := TTMSFNCMapsCoordinateItem(inherited Insert(Index));
end;

procedure TTMSFNCMapsCoordinates.SetItem(Index: Integer;
  const Value: TTMSFNCMapsCoordinateItem);
begin
  inherited Items[Index] := Value;
end;

procedure TTMSFNCMapsCoordinates.UpdateCoordinates;
begin
  if Assigned(OnUpdateCoordinates) then
    OnUpdateCoordinates(Self);
end;

{ TTMSFNCMapsCoordinateItem }

procedure TTMSFNCMapsCoordinateItem.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMapsCoordinateItem then
    FCoordinate.Assign((Source as TTMSFNCMapsCoordinateItem).Coordinate);
end;

constructor TTMSFNCMapsCoordinateItem.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCMapsCoordinates).FOwner;

  FCoordinate := TTMSFNCMapsCoordinate.Create;
  FCoordinate.OnChange := @CoordinateChanged;

  UpdateCoordinateItem;
end;

destructor TTMSFNCMapsCoordinateItem.Destroy;
begin
  FCoordinate.Free;
  inherited;
  UpdateCoordinateItem;
end;

function TTMSFNCMapsCoordinateItem.GetLatitude: Double;
begin
  Result := Coordinate.Latitude;
end;

function TTMSFNCMapsCoordinateItem.GetLongitude: Double;
begin
  Result := Coordinate.Longitude;
end;

function TTMSFNCMapsCoordinateItem.IsLatitudeStored: Boolean;
begin
  Result := Latitude <> DEFAULT_LATITUDE;
end;

function TTMSFNCMapsCoordinateItem.IsLongitudeStored: Boolean;
begin
  Result := Longitude <> DEFAULT_LONGITUDE;
end;

procedure TTMSFNCMapsCoordinateItem.CoordinateChanged(Sender: TObject);
begin
  UpdateCoordinateItem;
end;

procedure TTMSFNCMapsCoordinateItem.SetCoordinate(const Value: TTMSFNCMapsCoordinate);
begin
  if FCoordinate <> Value then
  begin
    FCoordinate.Assign(Value);
    UpdateCoordinateItem;
  end;
end;

procedure TTMSFNCMapsCoordinateItem.SetLatitude(const Value: Double);
begin
  Coordinate.Latitude := Value;
end;

procedure TTMSFNCMapsCoordinateItem.SetLongitude(const Value: Double);
begin
  Coordinate.Longitude := Value;
end;

procedure TTMSFNCMapsCoordinateItem.UpdateCoordinateItem;
begin
  if (Collection is TTMSFNCMapsCoordinates) then
    (Collection as TTMSFNCMapsCoordinates).UpdateCoordinates;
end;

{ TTMSFNCMapsBounds }

procedure TTMSFNCMapsBounds.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMapsBounds then
  begin
    FNorthEast.Assign((Source as TTMSFNCMapsBounds).NorthEast);
    FSouthWest.Assign((Source as TTMSFNCMapsBounds).SouthWest);
  end;
end;

constructor TTMSFNCMapsBounds.Create(ANorthEast: TTMSFNCMapsCoordinateRec; ASouthWest: TTMSFNCMapsCoordinateRec);
begin
  FNorthEast := TTMSFNCMapsCoordinate.Create(ANorthEast);
  FNorthEast.OnChange := @DoCoordinateChanged;
  FSouthWest := TTMSFNCMapsCoordinate.Create(ASouthWest);
  FSouthWest.OnChange := @DoCoordinateChanged;
end;

constructor TTMSFNCMapsBounds.Create;
begin
  FNorthEast := TTMSFNCMapsCoordinate.Create;
  FSouthWest := TTMSFNCMapsCoordinate.Create;
end;

destructor TTMSFNCMapsBounds.Destroy;
begin
  FNorthEast.Free;
  FSouthWest.Free;
  inherited;
end;

procedure TTMSFNCMapsBounds.DoCoordinateChanged(Sender: TObject);
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

function TTMSFNCMapsBounds.GetToRec: TTMSFNCMapsBoundsRec;
var
  r: TTMSFNCMapsBoundsRec;
  ne, sw: TTMSFNCMapsCoordinateRec;
begin
  ne := Self.NorthEast.ToRec;
  sw := Self.SouthWest.ToRec;
  r.NorthEast := ne;
  r.SouthWest := sw;
  Result := r;
end;

procedure TTMSFNCMapsBounds.SetToRec(const Value: TTMSFNCMapsBoundsRec);
begin
  Self.NorthEast.ToRec := Value.NorthEast;
  Self.SouthWest.ToRec := Value.SouthWest;
end;

{ TTMSFNCMapsCoordinate }

procedure TTMSFNCMapsCoordinate.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMapsCoordinate then
  begin
    FLatitude := (Source as TTMSFNCMapsCoordinate).Latitude;
    FLongitude := (Source as TTMSFNCMapsCoordinate).Longitude;
  end;
end;

constructor TTMSFNCMapsCoordinate.Create;
begin
  FLatitude := DEFAULT_LATITUDE;
  FLongitude := DEFAULT_LONGITUDE;
end;

constructor TTMSFNCMapsCoordinate.Create(ACoordinate: TTMSFNCMapsCoordinateRec);
begin
  FLatitude := ACoordinate.Latitude;
  FLongitude := ACoordinate.Longitude;
end;

constructor TTMSFNCMapsCoordinate.Create(ALatitude, ALongitude: Double);
begin
  Create(CreateCoordinate(ALatitude, ALongitude));
end;

function TTMSFNCMapsCoordinate.GetToRec: TTMSFNCMapsCoordinateRec;
var
  c: TTMSFNCMapsCoordinateRec;
begin
  c.Longitude := Longitude;
  c.Latitude := Latitude;
  c.Elevation := 0;
  c.TimeStamp := 0;
  c.HasElevation := False;
  c.HasTimeStamp := False;
  c.DataObject := nil;
  Result := c;
end;

function TTMSFNCMapsCoordinate.IsLatitudeStored: Boolean;
begin
  Result := Latitude <> DEFAULT_LATITUDE;
end;

function TTMSFNCMapsCoordinate.IsLongitudeStored: Boolean;
begin
  Result := Longitude <> DEFAULT_LONGITUDE;
end;

procedure TTMSFNCMapsCoordinate.UpdateCoordinate;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TTMSFNCMapsCoordinate.SetLatitude(const Value: Double);
begin
  if FLatitude <> Value then
  begin
    FLatitude := Value;
    UpdateCoordinate;
  end;
end;

procedure TTMSFNCMapsCoordinate.SetLongitude(const Value: Double);
begin
  if FLongitude <> Value then
  begin
    FLongitude := Value;
    UpdateCoordinate;
  end;
end;

procedure TTMSFNCMapsCoordinate.SetToRec(const Value: TTMSFNCMapsCoordinateRec);
begin
  Self.Latitude := Value.Latitude;
  Self.Longitude := Value.Longitude;
end;

{ TTMSFNCMapsBasePoint }

procedure TTMSFNCMapsBasePoint.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCMapsBasePoint) then
  begin
    FX := (Source as TTMSFNCMapsBasePoint).X;
    FY := (Source as TTMSFNCMapsBasePoint).Y;
  end;
end;

constructor TTMSFNCMapsBasePoint.Create;
begin
  FX := 0;
  FY := 0;
end;

function TTMSFNCMapsBasePoint.IsYStored: Boolean;
begin
  Result := Y <> 0;
end;

function TTMSFNCMapsBasePoint.IsXStored: Boolean;
begin
  Result := X <> 0;
end;

procedure TTMSFNCMapsBasePoint.SetY(const Value: Single);
begin
  if FY <> Value then
  begin
    FY := Value;
    UpdatePoint;
  end;
end;

procedure TTMSFNCMapsBasePoint.SetX(const Value: Single);
begin
  if FX <> Value then
  begin
    FX := Value;
    UpdatePoint;
  end;
end;

procedure TTMSFNCMapsBasePoint.UpdatePoint;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

{ TTMSFNCMapsPlusCode }

class function TTMSFNCMapsPlusCode.Decode(
  APlusCode: string): TTMSFNCMapsBoundsRec;
var
  latVal, lngVal, latPlaceVal, lngPlaceVal: Int64;
  pairPartLength, codeLength: Integer;
  I: Integer;
  digit, row, col: Integer;

  function NormalizeCode(ACode: string): string;
  var
    K: Integer;
  begin
    Result := ACode;
    if Length(ACode) < SeparatorPosition then
    begin
      Result := ACode;
      for K := 0 to SeparatorPosition - Length(ACode) - 1 do
        Result := Result + PaddingCharacter;

      Result := Result + SeparatorCharacter;
    end
    else if Length(ACode) = SeparatorPosition then
      Result := ACode + SeparatorCharacter
    else if ACode[SeparatorPosition{$IFNDEF ZEROSTRINGINDEX}+1{$ENDIF}] <> SeparatorCharacter then
      Result := Copy(ACode, 0, SeparatorPosition) + SeparatorCharacter + Copy(ACode, SeparatorPosition + 1, Length(ACode) - SeparatorPosition);
  end;
begin
  APlusCode := NormalizeCode(UpperCase(APlusCode));
  APlusCode := StringReplace(APlusCode, SeparatorCharacter, '', [rfReplaceAll]);
  APlusCode := StringReplace(APlusCode, PaddingCharacter, '', [rfReplaceAll]);

  latVal := -LatitudeMax * LatIntegerMultiplier;
  lngVal := -LongitudeMax * LngIntegerMultiplier;

  latPlaceVal := LatMspValue;
  lngPlaceVal := LngMspValue;

  pairPartLength := Min(Length(APlusCode), PairCodeLength);
  codeLength := Min(Length(APlusCode), MaxDigitCount);

  i := 0;
  while i < pairPartLength do
  begin
    latPlaceVal := latPlaceVal div EncodingBase;
    lngPlaceVal := lngPlaceVal div EncodingBase;
    latVal := latVal + DigitValueOf(APlusCode[i {$IFNDEF ZEROSTRINGINDEX} + 1 {$ENDIF}]) * latPlaceVal;
    lngVal := lngVal + DigitValueOf(APlusCode[i {$IFNDEF ZEROSTRINGINDEX} + 1 {$ENDIF} + 1]) * lngPlaceVal;
    Inc(i, 2);
  end;

  i := PairCodeLength;
  while i < codeLength do
  begin
    latPlaceVal := latPlaceVal div GridRows;
    lngPlaceVal := lngPlaceVal div GridColumns;
    digit := DigitValueOf(APlusCode[i {$IFNDEF ZEROSTRINGINDEX} + 1 {$ENDIF}]);
    row := digit div GridColumns;
    col := digit mod GridColumns;
    latVal := latVal + row * latPlaceVal;
    lngVal := lngVal + col * lngPlaceVal;
    Inc(I);
  end;

  Result := CreateBounds((latVal + latPlaceVal) / LatIntegerMultiplier, (lngVal + lngPlaceVal) / LngIntegerMultiplier,
    latVal / LatIntegerMultiplier, lngVal / LngIntegerMultiplier);
end;

class function TTMSFNCMapsPlusCode.DigitValueOf(digit: Char): Integer;
begin
  Result := TTMSFNCUtils.IndexOfTextInArray(digit, CodeAlphaBet);
end;

class function TTMSFNCMapsPlusCode.Encode(
  ACoordinate: TTMSFNCMapsCoordinateRec): string;
const
  codeLength: Integer = 10;
var
  latDigit, lngDigit, latitude, longitude, latVal, lngVal: Double;
  I, K, ndx: Integer;
  s: string;

  function ClipLatitude(ALatitude: Double): Double;
  begin
    Result := Min(Max(ALatitude, -LatitudeMax), LatitudeMax);
  end;

  function NormalizeLongitude(ALongitude: Double): Double;
  begin
    Result := ALongitude;
    while Result < -LongitudeMax do
      Result := Result + LongitudeMax * 2;

    while Result >= LongitudeMax do
      Result := Result - LongitudeMax * 2;
  end;

  function ComputeLatitudePrecision(ACodeLength: Integer): Double;
  begin
    if ACodeLength <= 10 then
    begin
      Result := Power(EncodingBase, ACodeLength / -2  + 2);
      Exit;
    end;

    Result := Power(EncodingBase, -2) / Power(GridRows, ACodeLength - PairCodeLength);
  end;

  function FModEx(const ANumerator, ADenominator: Double): Double;
  begin
    Result := ANumerator - Trunc(ANumerator / ADenominator) * ADenominator;
  end;

begin
  Result := '';

  latitude := ClipLatitude(ACoordinate.Latitude);
  longitude := NormalizeLongitude(ACoordinate.Longitude);

  if Int(latitude) = LatitudeMax then
    latitude := latitude - 0.9 * ComputeLatitudePrecision(codeLength);

  latVal := Round((latitude + LatitudeMax) * LatIntegerMultiplier * 1E6) / 1E6;
  lngVal := Round((longitude + LongitudeMax) * LngIntegerMultiplier * 1E6) / 1E6;

  if codeLength > PairCodeLength then
  begin
    for I := 0 to GridCodeLength - 1 do
    begin
      latDigit := FModEx(latVal, GridRows);
      lngDigit := FModEx(lngVal, GridColumns);
      ndx := Round(Int(latDigit * GridColumns + lngDigit));
      s := CodeAlphaBet[ndx] + s;
      latVal := latVal / GridRows;
      lngVal := lngVal / GridColumns;
    end;
  end
  else
  begin
    latVal := latVal / GridRowsMultiplier;
    lngVal := lngVal / GridColumnsMultiplier;
  end;

  for I := 0 to (PairCodeLength div 2) - 1 do
  begin
    s := CodeAlphaBet[Round(Int(FModEx(lngVal, EncodingBase)))] + s;
    s := CodeAlphaBet[Round(Int(FModEx(latVal, EncodingBase)))] + s;
    latVal := latVal / EncodingBase;
    lngVal := lngVal / EncodingBase;
  end;

  s := Copy(s, 0, SeparatorPosition) + SeparatorCharacter + Copy(s, SeparatorPosition + 1, Length(s) - SeparatorPosition);

  if codeLength >= SeparatorPosition then
  begin
    Result := Copy(s, 0, codeLength + 1);
    Exit;
  end;

  Result := Copy(s, 0, codeLength);
  for K := 0 to SeparatorPosition - codeLength - 1 do
    Result := Result + PaddingCharacter;

  Result := Result + SeparatorCharacter;
end;

{ TTMSFNCMapsGPXTrackEventData }

constructor TTMSFNCMapsGPXTrackEventData.Create;
begin
  FNode := nil;
end;

destructor TTMSFNCMapsGPXTrackEventData.Destroy;
begin
  inherited;
end;

{ TTMSFNCMapsGPXSegmentEventData }

constructor TTMSFNCMapsGPXSegmentEventData.Create;
begin
  FNode := nil;
end;

destructor TTMSFNCMapsGPXSegmentEventData.Destroy;
begin
  inherited;
end;

{$IFDEF WEBLIB}
function TJSNodeListHelper.GetCount: NativeInt;
begin
  Result := length;
end;

function TJSNodeListHelper.FindNode(ANodeName: string): TJSNode;
var
  Index: Integer;
begin
  Index := IndexOf(ANodeName);
  if Index >= 0 then
    Result := Nodes[Index]
  else
    Result := nil;
end;

function TJSNodeListHelper.IndexOf(const Name: string): NativeInt;
var
  n: TJSNode;
begin
  for Result := 0 to Count - 1 do
  begin
    n := Nodes[Result];
    if (n.nodeName = Name) then
      Exit;
  end;
  Result := -1;
end;
{$ENDIF}

function LoadResourceFile(AResName: string): string;
{$IFNDEF WEBLIB}
var
  RS: TResourceStream;
  ST: TStringList;
{$ENDIF}
begin
  Result := '';
{$IFNDEF WEBLIB}
  ST := TStringList.Create;
  begin
    RS := TTMSFNCUtils.GetResourceStream(AResName);
    try
      ST.LoadFromStream(RS{$IFNDEF LCLLIB}, TEncoding.UTF8{$ENDIF});
      Result := ST.text;
    finally
      RS.Free;
    end;
  end;
  ST.Free;
{$ENDIF}
end;

function SaveToGPX(ACoordinates: TTMSFNCMapsCoordinateRecArray; AMetaData: TTMSFNCMapsGPXMetaData;
  IncludeElevation: Boolean = True; IncludeTimeStamp: Boolean = True; AInstructions: string = ''): string;
var
  Segments: TTMSFNCMapsCoordinateRecArrayArray;
  Instructions: TStringList;
begin
  Setlength(Segments, 1);
  Segments[0] := ACoordinates;
  Instructions := TStringList.Create;
  Instructions.Add(AInstructions);
  Result := SaveToGPX(Segments, AMetaData, IncludeElevation, IncludeTimeStamp, Instructions);
  Instructions.Free;
end;

function SaveToGPX(ASegments: TTMSFNCMapsCoordinateRecArrayArray; AMetaData: TTMSFNCMapsGPXMetaData;
  IncludeElevation: Boolean = True; IncludeTimeStamp: Boolean = True; AInstructions: TStringList = nil): string;
const
  LB = #13;
var
  i, j: Integer;
  hdr, ftr: string;
  sl: TStringList;
  {$IFDEF WEBLIB}
  d, t: string;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  d, t: Char;
  {$ENDIF}
  ACoordinates: TTMSFNCMapsCoordinateRecArray;
begin
  hdr :=
    '<?xml version="1.0"?>' + LB +
    '<gpx xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" ' + LB +
    'xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd" version="1.1" creator="TMS FNC Maps" xmlns="http://www.topografix.com/GPX/1/1">' + LB +
    '<metadata>' + LB +
    '<author>' + LB +
    '<name>%s</name>' + LB +
    '<link href="%s" />' + LB +
    '</author>' + LB +
    '</metadata>' + LB +
    '<trk>' + LB +
    '<name>%s</name>' + LB +
    '<type>%s</type>';

  ftr :=
    '</trk>' + LB +
    '</gpx>';

  hdr := Format(hdr,[AMetaData.AuthorName, AMetaData.AuthorLink, AMetaData.TrackName, AMetaData.TrackType]);

  t := FormatSettings.ThousandSeparator;
  d := FormatSettings.DecimalSeparator;
  FormatSettings.DecimalSeparator := '.';
  FormatSettings.ThousandSeparator := ',';

  sl := TStringList.Create;
  try
    sl.Add(hdr);

    for i := 0 to Length(ASegments) - 1 do
    begin
      ACoordinates := ASegments[i];
      sl.Add('<trkseg>');

      if Assigned(AInstructions) and (AInstructions.Count > i) then
      begin
        sl.Add(Format('<extensions>' + LB + '<instructions>%s</instructions>' + LB + '</extensions>', [AInstructions[I]]));
      end;

      for j := 0 to Length(ACoordinates) - 1 do
      begin
        sl.Add(Format('<trkpt lat="%.7f" lon="%.7f">', [ACoordinates[j].Latitude, ACoordinates[j].Longitude]));
        if IncludeElevation and ACoordinates[j].HasElevation then
        begin
          sl.Add(Format('<ele>%.7f</ele>', [ACoordinates[j].Elevation]));
        end;
        if IncludeTimeStamp and ACoordinates[j].HasTimeStamp then
        begin
          sl.Add(Format('<time>%s</time>', [TTMSFNCUtils.DateTimeToISO(ACoordinates[j].TimeStamp, True, True)]));
        end;
        sl.Add('</trkpt>');
      end;

      sl.Add('</trkseg>');
    end;

    sl.Add(ftr);

    Result := sl.Text;
  finally
    sl.Free;
  end;

  FormatSettings.ThousandSeparator := t;
  FormatSettings.DecimalSeparator := d;
end;

end.
