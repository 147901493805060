{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCDirections;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

{$IFNDEF LCLLIB}
{$HINTS OFF}
{$IF COMPILERVERSION > 23}
{$DEFINE UNREGISTER}
{$IFEND}
{$HINTS ON}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE UNREGISTER}
{$ENDIF}

interface

uses
  Classes, Types, WEBLib.Forms
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,web, Contnrs
  {$ENDIF}
  {$IFDEF CMNLIB}
  {$ENDIF}
  ,WEBLib.TMSFNCTypes
  ,WEBLib.TMSFNCMapsCommonTypes
  ,WEBLib.TMSFNCCloudBase;

const
  TTMSFNCDirectionsDocURL = TTMSFNCBaseDocURL + 'tmsfncmaps/components/ttmsfncmaps/#ttmsfncdirections';
  TTMSFNCDirectionsTipsURL = 'https://www.tmssoftware.com/site/tmsfncmaps.asp?s=faq';

  MAJ_VER = 1; // Major version nr.
  MIN_VER = 5; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 0; // Build nr.

  //v1.0.0.0: First release
  //v1.0.1.0: New: TravelMode tmPublicTransport, tmTruck added (where available)
  //        : New: Directions result Status and ErrorMessage added
  //v1.1.0.0: New: Here directions service updated to Routing API v8
  //v1.1.1.0: New: GetDirections AvoidTolls parameter
  //v1.1.2.0: New: WayPoints.OptimizedIndex added for Google, Here and TomTom
  //v1.2.0.0: New: Support for OpenRouteService directions
  //v1.3.0.0: New: Support for GeoApify directions
  //v1.3.0.1: Fixed: Here: Issue with Distance and Duration values
  //v1.4.0.0: New: Google: Support for Origin, Destination and WayPoints string values
  //v1.5.0.0: New: SaveToGPXFile, SaveToGPXStream, SaveToGPXText added
  //        : New: Google: Leg StartAddress and EndAddress added


type
  TTMSFNCDirectionsTravelMode = (tmDriving, tmWalking, tmBicycling, tmPublicTransport, tmTruck);
  TTMSFNCCustomDirections = class;
  TTMSFNCDirectionsRequest = class;
  TTMSFNCDirectionsItems = class;
  TTMSFNCDirectionsItem = class;

  ITMSFNCCustomDirectionsProperties = interface(IInterface)
    ['{7E76D35C-397F-4D05-AAAB-3CE44C082FE7}']
    function GetAPIKey: string;
    function GetTruckOptions(var AOptions: string; AAvoidTolls: Boolean): string;
  end;

  ITMSFNCCustomDirectionsInstance = interface(IInterface)
    ['{BB0799B8-FE15-4E10-AC1D-7490C887DB7C}']
    procedure SetDirectionsProperties(const Value: ITMSFNCCustomDirectionsProperties);
  end;

  ITMSFNCCustomDirections = interface(IInterface)
  ['{EDE79341-AAA4-4E89-B471-47365BDC999D}']
    function GetIdentifier: string;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double;
      ATravelMode: TTMSFNCDirectionsTravelMode; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
    function GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; 
      AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
    function GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude, ADestinationLatitude,
      ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil;
      AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = '';
      AAvoidTolls: Boolean = False): string;
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
    function IsValid: Boolean;
    procedure DestroyDirections;
  end;

  ITMSFNCDirectionsService = interface(IInterface)
  ['{74DDA282-D3BD-4883-8A49-CE2F67BF32AB}']
    function CreateDirections: ITMSFNCCustomDirections;
    procedure DestroyDirections(ADirections: ITMSFNCCustomDirections);
  end;

  ITMSFNCDirectionsServiceGoogle = interface(ITMSFNCDirectionsService)
    ['{6A97B0E0-8F24-4E04-9110-E1472169FF20}']
  end;

  ITMSFNCDirectionsServiceGoogleRoutes = interface(ITMSFNCDirectionsService)
    ['{65FF008D-06F9-418D-8399-7DCB12003B17}']
  end;

  ITMSFNCDirectionsServiceHere = interface(ITMSFNCDirectionsService)
    ['{0CD111D1-2C73-47A0-86B2-04CFFD819AAF}']
  end;

  ITMSFNCDirectionsServiceBing = interface(ITMSFNCDirectionsService)
    ['{6A0EED0A-BBE0-4092-956F-37A0055A64B2}']
  end;

  ITMSFNCDirectionsServiceAzure = interface(ITMSFNCDirectionsService)
    ['{A8DBA06B-C950-4B88-B124-58A67600E0F5}']
  end;

  ITMSFNCDirectionsServiceMapBox = interface(ITMSFNCDirectionsService)
    ['{8695A289-6B14-472C-AA32-617B913173FD}']
  end;

  ITMSFNCDirectionsServiceTomTom = interface(ITMSFNCDirectionsService)
    ['{111375A8-5C34-4AA9-A612-E3FB70944928}']
  end;

  ITMSFNCDirectionsServiceOpenRouteService = interface(ITMSFNCDirectionsService)
    ['{ABDB1BFD-AEBF-421A-B744-98E7040803F9}']
  end;

  ITMSFNCDirectionsServiceGeoApify = interface(ITMSFNCDirectionsService)
    ['{BFB8236B-9D74-4F67-BCFB-1641107C4DEA}']
  end;

  TTMSFNCDirectionsService = (dsGoogle, dsHere, dsBing, dsAzure, dsMapBox, dsTomTom, dsOpenRouteService, dsGeoApify);

  TTMSFNCDirectionsStep = class(TCollectionItem)
  private
    FOwner: TCollectionItem;
    FDataBoolean: Boolean;
    FDataString: string;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FDistance: Integer;
    FDuration: Integer;
    FStartLocation: TTMSFNCMapsCoordinate;
    FInstructions: string;
    FEndLocation: TTMSFNCMapsCoordinate;
    FCoordinates: TTMSFNCMapsCoordinates;
    procedure SetCoordinates(const Value: TTMSFNCMapsCoordinates);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: string read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
  published
    property Coordinates: TTMSFNCMapsCoordinates read FCoordinates write SetCoordinates;
    property StartLocation: TTMSFNCMapsCoordinate read FStartLocation write FStartLocation;
    property EndLocation: TTMSFNCMapsCoordinate read FEndLocation write FEndLocation;
    property Distance: Integer read FDistance write FDistance;
    property Duration: Integer read FDuration write FDuration;
    property Instructions: string read FInstructions write FInstructions;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCDirectionsSteps = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCDirectionsSteps = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCDirectionsStep>)
  {$ENDIF}
  private
    FOwner: TCollectionItem;
    function GetItem(Index: Integer): TTMSFNCDirectionsStep;
    procedure SetItem(Index: Integer; const Value: TTMSFNCDirectionsStep);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TCollectionItem); virtual;
    property Items[Index: Integer]: TTMSFNCDirectionsStep read GetItem write SetItem; default;
    function Add: TTMSFNCDirectionsStep;
    function Insert(Index: Integer): TTMSFNCDirectionsStep;
  end;

  TTMSFNCDirectionsLeg = class(TCollectionItem)
  private
    FOwner: TCollectionItem;
    FDataBoolean: Boolean;
    FDataString: string;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FDistance: Integer;
    FDuration: Integer;
    FStartLocation: TTMSFNCMapsCoordinate;
    FEndLocation: TTMSFNCMapsCoordinate;
    FCoordinates: TTMSFNCMapsCoordinates;
    FSteps: TTMSFNCDirectionsSteps;
    FStartAddress: string;
    FEndAddress: string;
    procedure SetCoordinates(const Value: TTMSFNCMapsCoordinates);
    procedure SetSteps(const Value: TTMSFNCDirectionsSteps);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: string read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
  published
    property Coordinates: TTMSFNCMapsCoordinates read FCoordinates write SetCoordinates;
    property StartAddress: string read FStartAddress write FStartAddress;
    property StartLocation: TTMSFNCMapsCoordinate read FStartLocation write FStartLocation;
    property EndAddress: string read FEndAddress write FEndAddress;
    property EndLocation: TTMSFNCMapsCoordinate read FEndLocation write FEndLocation;
    property Distance: Integer read FDistance write FDistance;
    property Duration: Integer read FDuration write FDuration;
    property Steps: TTMSFNCDirectionsSteps read FSteps write SetSteps;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCDirectionsLegs = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCDirectionsLegs = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCDirectionsLeg>)
  {$ENDIF}
  private
    FOwner: TCollectionItem;
    function GetItem(Index: Integer): TTMSFNCDirectionsLeg;
    procedure SetItem(Index: Integer; const Value: TTMSFNCDirectionsLeg);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TCollectionItem); virtual;
    property Items[Index: Integer]: TTMSFNCDirectionsLeg read GetItem write SetItem; default;
    function Add: TTMSFNCDirectionsLeg;
    function Insert(Index: Integer): TTMSFNCDirectionsLeg;
  end;

  TTMSFNCDirectionsWayPoint = class(TCollectionItem)
  private
    FOwner: TCollectionItem;
    FDataBoolean: Boolean;
    FDataString: string;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FDistance: Integer;
    FDuration: Integer;
    FOptimizedIndex: Integer;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: string read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
  published
    property Distance: Integer read FDistance write FDistance;
    property Duration: Integer read FDuration write FDuration;
    property OptimizedIndex: Integer read FOptimizedIndex write FOptimizedIndex;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCDirectionsWayPoints = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCDirectionsWayPoints = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCDirectionsWayPoint>)
  {$ENDIF}
  private
    FOwner: TCollectionItem;
    function GetItem(Index: Integer): TTMSFNCDirectionsWayPoint;
    procedure SetItem(Index: Integer; const Value: TTMSFNCDirectionsWayPoint);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TCollectionItem); virtual;
    property Items[Index: Integer]: TTMSFNCDirectionsWayPoint read GetItem write SetItem; default;
    function Add: TTMSFNCDirectionsWayPoint;
    function Insert(Index: Integer): TTMSFNCDirectionsWayPoint;
  end;

  TTMSFNCDirectionsItem = class(TCollectionItem)
  private
    FOwner: TTMSFNCCustomDirections;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FCoordinates: TTMSFNCMapsCoordinates;
    FSummary: string;
    FDistance: Double;
    FDuration: Integer;
    FSteps: TTMSFNCDirectionsSteps;
    FLegs: TTMSFNCDirectionsLegs;
    FWayPoints: TTMSFNCDirectionsWayPoints;
    procedure SetCoordinates(const Value: TTMSFNCMapsCoordinates);
    function GetBounds: TTMSFNCMapsBounds;
    procedure SetSteps(const Value: TTMSFNCDirectionsSteps);
    procedure SetLegs(const Value: TTMSFNCDirectionsLegs);
    procedure SetWayPoints(const Value: TTMSFNCDirectionsWayPoints);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property Bounds: TTMSFNCMapsBounds read GetBounds;
    property Legs: TTMSFNCDirectionsLegs read FLegs write SetLegs;
  published
    property Coordinates: TTMSFNCMapsCoordinates read FCoordinates write SetCoordinates;
    property Distance: Double read FDistance write FDistance;
    property Duration: Integer read FDuration write FDuration;
    property Summary: string read FSummary write FSummary;
    property WayPoints: TTMSFNCDirectionsWayPoints read FWayPoints write SetWayPoints;
    property Steps: TTMSFNCDirectionsSteps read FSteps write SetSteps;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCDirectionsItems = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCDirectionsItems = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCDirectionsItem>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomDirections;
    function GetItem(Index: Integer): TTMSFNCDirectionsItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCDirectionsItem);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomDirections); virtual;
    property Items[Index: Integer]: TTMSFNCDirectionsItem read GetItem write SetItem; default;
    function Add: TTMSFNCDirectionsItem;
    function Insert(Index: Integer): TTMSFNCDirectionsItem;
  end;

  TTMSFNCDirectionsRequest = class(TCollectionItem)
  private
    FOwner: TTMSFNCCustomDirections;
    FDataPointer: Pointer;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FItems: TTMSFNCDirectionsItems;
    FID: string;
    FTraveLmode: TTMSFNCDirectionsTravelMode;
    FStatus: string;
    FErrorMessage: string;
    procedure SetItems(const Value: TTMSFNCDirectionsItems);
    procedure SetTravelMode(const Value: TTMSFNCDirectionsTravelMode);
    procedure SetErrorMessage(const Value: string);
    procedure SetStatus(const Value: string);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
  published
    property ID: string read FID;
    property Items: TTMSFNCDirectionsItems read FItems write SetItems;
    property TravelMode: TTMSFNCDirectionsTravelMode read FTraveLmode write SetTravelMode;
    property Status: string read FStatus write SetStatus;
    property ErrorMessage: string read FErrorMessage write SetErrorMessage;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCDirectionsRequests = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCDirectionsRequests = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCDirectionsRequest>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomDirections;
    function GetItem(Index: Integer): TTMSFNCDirectionsRequest;
    procedure SetItem(Index: Integer; const Value: TTMSFNCDirectionsRequest);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomDirections); virtual;
    property Items[Index: Integer]: TTMSFNCDirectionsRequest read GetItem write SetItem; default;
    function Add: TTMSFNCDirectionsRequest;
    function Insert(Index: Integer): TTMSFNCDirectionsRequest;
  end;

  { TTMSFNCCustomDirections }

  TTMSFNCDirectionsGetDirectionsCallback = {$IFNDEF LCLLIB}reference to {$ENDIF}procedure(const ARequest: TTMSFNCDirectionsRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult){$IFDEF LCLLIB} of object{$ENDIF};
  TTMSFNCDirectionsGetDirectionsEvent = procedure(Sender: TObject; const ARequest: TTMSFNCDirectionsRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult) of object;

  TTMSFNCDirectionsGetDirectionsResultCallback = {$IFNDEF LCLLIB}reference to {$ENDIF}procedure(const AResult: TTMSFNCDirectionsRequest){$IFDEF LCLLIB} of object{$ENDIF};
  TTMSFNCDirectionsGetDirectionsResultEvent = procedure(Sender: TObject; const AResult: TTMSFNCDirectionsRequest) of object;

  TTMSFNCDirectionsCallBackWrapper = class
  private
    FCallback: TTMSFNCDirectionsGetDirectionsCallback;
  public
    constructor Create(ACallback: TTMSFNCDirectionsGetDirectionsCallback);
  end;

  TTMSFNCDirectionsResultCallBackWrapper = class
  private
    FCallback: TTMSFNCDirectionsGetDirectionsResultCallback;
  public
    constructor Create(ACallback: TTMSFNCDirectionsGetDirectionsResultCallback);
  end;

  TTMSFNCCustomDirections = class(TTMSFNCCloudBase, ITMSFNCCustomDirectionsProperties)
  private
    FDirections: ITMSFNCCustomDirections;
    FDirectionsInstance: ITMSFNCCustomDirectionsInstance;
    FDirectionsProperties: ITMSFNCCustomDirectionsProperties;
    FService: TTMSFNCDirectionsService;
    FAPIKey: string;
    FDirectionsRequests: TTMSFNCDirectionsRequests;
    FOnGetDirections: TTMSFNCDirectionsGetDirectionsEvent;
    FOnGetDirectionsInternal: TTMSFNCDirectionsGetDirectionsEvent;
    FOnGetDirectionsResultInternal: TTMSFNCDirectionsGetDirectionsResultEvent;
    FOnGetDirectionsResult: TTMSFNCDirectionsGetDirectionsResultEvent;
    FIsGoogleRoutes: Boolean;
    procedure SetService(const Value: TTMSFNCDirectionsService);
    procedure SetAPIKey(const Value: string);
    procedure SetDirectionsRequests(const Value: TTMSFNCDirectionsRequests);
    procedure SetIsGoogleRoutes(const Value: Boolean);
  protected
    procedure DoRequestGetDirections(const ARequestResult: TTMSFNCCloudBaseRequestResult);
    procedure DoGetDirections(const ARequest: TTMSFNCDirectionsRequest; const ARequestResult: TTMSFNCCloudBaseRequestResult);
    property OnGetDirections: TTMSFNCDirectionsGetDirectionsEvent read FOnGetDirections write FOnGetDirections;
    property OnGetDirectionsInternal: TTMSFNCDirectionsGetDirectionsEvent read FOnGetDirectionsInternal write FOnGetDirectionsInternal;
    property OnGetDirectionsResult: TTMSFNCDirectionsGetDirectionsResultEvent read FOnGetDirectionsResult write FOnGetDirectionsResult;
    property OnGetDirectionsResultInternal: TTMSFNCDirectionsGetDirectionsResultEvent read FOnGetDirectionsResultInternal write FOnGetDirectionsResultInternal;

    procedure InitializeDirections; virtual;
    function GetTruckOptions(var AOptions: string; AAVoidTolls: Boolean): string; virtual;
    function GetInstance: NativeUInt; override;
    function GetDirectionsRequests: TTMSFNCDirectionsRequests;
    function DirectionsReady: Boolean;
    function GetAPIKey: string;
    function GetVersionNr: Integer;
    function GetDocURL: string; override;
    function GetTipsURL: string; override;
    function Directions: ITMSFNCCustomDirections;
    function GetVersion: string; override;
    property IsGoogleRoutes: Boolean read FIsGoogleRoutes write SetIsGoogleRoutes default False;
    property Service: TTMSFNCDirectionsService read FService write SetService default dsGoogle;
    property APIKey: string read FAPIKey write SetAPIKey;
    property DirectionsRequests: TTMSFNCDirectionsRequests read FDirectionsRequests write SetDirectionsRequests;

    property DirectionsInstance: ITMSFNCCustomDirections read FDirections;
    property DirectionsProperties: ITMSFNCCustomDirectionsProperties read FDirectionsProperties;
    procedure GetDirectionsInt(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude, ADestinationLatitude,
      ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault;
      AID: string = ''; ADataPointer: Pointer = nil; ACallback: TTMSFNCDirectionsGetDirectionsCallback = nil; ACallback2: TTMSFNCDirectionsGetDirectionsResultCallback = nil;
      AAVoidTolls: Boolean = False); overload; virtual;

    function SaveToGPXInt(ACoordinates: TTMSFNCMapsCoordinateRecArray; AMetaData: TTMSFNCMapsGPXMetaData; AInstructions: TTMSFNCDirectionsSteps): string;

  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure GetDirections(AOrigin, ADestination: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCDirectionsGetDirectionsCallback = nil;
      AID: string = ''; ADataPointer: Pointer = nil; AAlternatives: Boolean = False; ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving;
      AWayPoints: TTMSFNCMapsCoordinateRecArray = nil; AOptimizeWayPoints: Boolean = False; ALocale: string = '';
      AMode: TTMSFNCMapsLocaleMode = mlmDefault; AAvoidTolls: Boolean = False); overload; virtual;

    procedure GetDirections(AOrigin, ADestination: string; ACallback: TTMSFNCDirectionsGetDirectionsCallback = nil;
      AID: string = ''; ADataPointer: Pointer = nil; AAlternatives: Boolean = False; ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving;
      AWayPoints: TStringList = nil; AOptimizeWayPoints: Boolean = False; ALocale: string = '';
      AMode: TTMSFNCMapsLocaleMode = mlmDefault; AAvoidTolls: Boolean = False); overload; virtual;

    procedure GetDirectionsResult(AOrigin, ADestination: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCDirectionsGetDirectionsResultCallback = nil;
      AID: string = ''; ADataPointer: Pointer = nil; AAlternatives: Boolean = False; ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving;
      AWayPoints: TTMSFNCMapsCoordinateRecArray = nil; AOptimizeWayPoints: Boolean = False; ALocale: string = '';
      AMode: TTMSFNCMapsLocaleMode = mlmDefault; AAvoidTolls: Boolean = False); overload; virtual;

    procedure GetDirectionsResult(AOrigin, ADestination: string; ACallback: TTMSFNCDirectionsGetDirectionsResultCallback = nil;
      AID: string = ''; ADataPointer: Pointer = nil; AAlternatives: Boolean = False; ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving;
      AWayPoints: TStringList = nil; AOptimizeWayPoints: Boolean = False; ALocale: string = '';
      AMode: TTMSFNCMapsLocaleMode = mlmDefault; AAvoidTolls: Boolean = False); overload; virtual;

    procedure SaveToGPXStream(ACoordinates: TTMSFNCMapsCoordinateRecArray; AStream: TStream; AInstructions: TTMSFNCDirectionsSteps = nil); overload; virtual;
    procedure SaveToGPXStream(ACoordinates: TTMSFNCMapsCoordinateRecArray; AStream: TStream; AMetaData: TTMSFNCMapsGPXMetaData; AInstructions: TTMSFNCDirectionsSteps = nil); overload; virtual;
    procedure SaveToGPXFile(ACoordinates: TTMSFNCMapsCoordinateRecArray; AFileName: string; AInstructions: TTMSFNCDirectionsSteps = nil); overload; virtual;
    procedure SaveToGPXFile(ACoordinates: TTMSFNCMapsCoordinateRecArray; AFileName: string; AMetaData: TTMSFNCMapsGPXMetaData; AInstructions: TTMSFNCDirectionsSteps = nil); overload; virtual;
    function SaveToGPXText(ACoordinates: TTMSFNCMapsCoordinateRecArray; AInstructions: TTMSFNCDirectionsSteps = nil): string; overload; virtual;
    function SaveToGPXText(ACoordinates: TTMSFNCMapsCoordinateRecArray; AMetaData: TTMSFNCMapsGPXMetaData; AInstructions: TTMSFNCDirectionsSteps = nil): string; overload; virtual;
  end;

  TTMSFNCCustomDirectionsInterfacedObject = class(TInterfacedObject, ITMSFNCCustomDirectionsInstance)
  private
    FDirectionsProperties: ITMSFNCCustomDirectionsProperties;
    function GetDirectionsProperties: ITMSFNCCustomDirectionsProperties;
  protected
    procedure SetDirectionsProperties(const Value: ITMSFNCCustomDirectionsProperties);
  public
    property DirectionsProperties: ITMSFNCCustomDirectionsProperties read GetDirectionsProperties;
    destructor Destroy; override;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCDirectionsList = class(TList)
  private
    function GetItem(Index: Integer): ITMSFNCCustomDirections;
    procedure SetItem(Index: Integer; const Value: ITMSFNCCustomDirections);
  public
    property Items[Index: Integer]: ITMSFNCCustomDirections read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCDirectionsList = class(TList<ITMSFNCCustomDirections>);
  {$ENDIF}

  TTMSFNCDirectionsFactoryService = class(TInterfacedObject, ITMSFNCDirectionsService)
  private
    FDirections: TTMSFNCDirectionsList;
  protected
    function DoCreateDirections: ITMSFNCCustomDirections; virtual; abstract;
    function CreateDirections: ITMSFNCCustomDirections;
    procedure DestroyDirections(ADirections: ITMSFNCCustomDirections);
  public
    constructor Create;
    destructor Destroy; override;
  end;

  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  TTMSFNCDirections = class(TTMSFNCCustomDirections)
  protected
    procedure RegisterRuntimeClasses; override;
  public
    property DirectionsInstance;
    property DirectionsProperties;
  published
    property OnGetDirections;
    property OnGetDirectionsResult;
    property APIKey;
    property DirectionsRequests;
    property Service;
    property Version;
  end;

  TTMSFNCDirectionsPlatformServicesService = class
  private
    FInterface: IInterface;
    FGUID: string;
  public
    constructor Create(AGUID: string; AInterface: IInterface);
    property GUID: string read FGUID;
    property &Interface: IInterface read FInterface;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCDirectionsPlatformServicesList = class(TObjectList)
  private
    function GetItem(Index: Integer): TTMSFNCDirectionsPlatformServicesService;
    procedure SetItem(Index: Integer; const Value: TTMSFNCDirectionsPlatformServicesService);
  public
    property Items[Index: Integer]: TTMSFNCDirectionsPlatformServicesService read GetItem write SetItem; default;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCDirectionsPlatformServicesList = class(TObjectList<TTMSFNCDirectionsPlatformServicesService>)
  {$ENDIF}
  private
    function GetValue(AGUID: string): IInterface;
  public
    function ContainsKey(AGUID: string): Boolean;
    procedure RemoveByGUID(AGUID: string);
    property Interfaces[AGUID: string]: IInterface read GetValue;
  end;

  TTMSFNCDirectionsPlatformServices = class
  private
    FServicesList: TTMSFNCDirectionsPlatformServicesList;
    class var FCurrent: TTMSFNCDirectionsPlatformServices;
    class var FCurrentReleased: Boolean;
{$IFNDEF AUTOREFCOUNT}
    class procedure ReleaseCurrent;
{$ENDIF}
  public
    constructor Create;
    destructor Destroy; override;
    procedure AddPlatformService(const AServiceGUID: TGUID; const AService: IInterface);
    procedure RemovePlatformService(const AServiceGUID: TGUID);
    function GetPlatformService(const AServiceGUID: TGUID): IInterface;
    function SupportsPlatformService(const AServiceGUID: TGUID): Boolean; overload;
    function SupportsPlatformService(const AServiceGUID: TGUID; var AService: IInterface): Boolean; overload;
    class function Current: TTMSFNCDirectionsPlatformServices;
  end;

  procedure DecodeValues(AValues: string; ACoordinates: TTMSFNCMapsCoordinates);

implementation

uses
  {$IFDEF MSWINDOWS}
  Windows, Registry,
  {$ENDIF}
  {%H-}Math,
  WEBLib.TMSFNCUtils,
  WEBLib.TMSFNCDirections.Google,
  WEBLib.TMSFNCDirections.GoogleRoutes,
  WEBLib.TMSFNCDirections.Here,
  WEBLib.TMSFNCDirections.Bing,
  WEBLib.TMSFNCDirections.Azure,
  WEBLib.TMSFNCDirections.MapBox,
  WEBLib.TMSFNCDirections.TomTom,
  WEBLib.TMSFNCDirections.OpenRouteService,
  WEBLib.TMSFNCDirections.GeoAPify,
  SysUtils
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  ;

{ TTMSFNCCustomDirections }

function TTMSFNCCustomDirections.GetAPIKey: string;
begin
  Result := APIKey;
end;

function TTMSFNCCustomDirections.GetDocURL: string;
begin
  Result := TTMSFNCDirectionsDocURL;
end;

function TTMSFNCCustomDirections.GetInstance: NativeUInt;
begin
  Result := HInstance;
end;

function TTMSFNCCustomDirections.GetTipsURL: string;
begin
  Result := TTMSFNCDirectionsTipsURL;
end;

function TTMSFNCCustomDirections.GetTruckOptions(var AOptions: string; AAVoidTolls: Boolean): string;
begin
  Result := AOptions;
end;

function TTMSFNCCustomDirections.GetVersion: string;
var
  vn: Integer;
begin
  vn := GetVersionNr;
  Result := IntToStr(Hi(Hiword(vn)))+'.'+IntToStr(Lo(Hiword(vn)))+'.'+IntToStr(Hi(Loword(vn)))+'.'+IntToStr(Lo(Loword(vn)));
end;

function TTMSFNCCustomDirections.GetVersionNr: Integer;
begin
  Result := MakeLong(MakeWord(BLD_VER,REL_VER),MakeWord(MIN_VER,MAJ_VER));
end;

procedure TTMSFNCCustomDirections.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TTMSFNCCustomDirections) then
  begin
    FService := (Source as TTMSFNCCustomDirections).Service;
    FAPIKey := (Source as TTMSFNCCustomDirections).APIKey;
    FDirectionsRequests.Assign((Source as TTMSFNCCustomDirections).DirectionsRequests);
  end;
end;

procedure TTMSFNCCustomDirections.GetDirections(AOrigin, ADestination: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCDirectionsGetDirectionsCallback = nil;
  AID: string = ''; ADataPointer: Pointer = nil; AAlternatives: Boolean = False; ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving;
    AWayPoints: TTMSFNCMapsCoordinateRecArray = nil; AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault;
    AAvoidTolls: Boolean = False);
begin
  GetDirectionsInt('', '', AOrigin.Latitude, AOrigin.Longitude, ADestination.Latitude,
    ADestination.Longitude, AAlternatives, ATravelMode, nil, AWayPoints, AOptimizeWayPoints,
    ALocale, AMode, AID, ADataPointer, ACallback, nil, AAvoidTolls);
end;

procedure TTMSFNCCustomDirections.GetDirectionsResult(AOrigin, ADestination: TTMSFNCMapsCoordinateRec; ACallback: TTMSFNCDirectionsGetDirectionsResultCallback = nil;
  AID: string = ''; ADataPointer: Pointer = nil; AAlternatives: Boolean = False; ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving;
    AWayPoints: TTMSFNCMapsCoordinateRecArray = nil; AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault;
    AAvoidTolls: Boolean = False);
begin
  GetDirectionsInt('', '', AOrigin.Latitude, AOrigin.Longitude, ADestination.Latitude,
    ADestination.Longitude, AAlternatives, ATravelMode, nil, AWayPoints, AOptimizeWayPoints,
    ALocale, AMode, AID, ADataPointer, nil, ACallback, AAvoidTolls);
end;

procedure TTMSFNCCustomDirections.GetDirections(AOrigin, ADestination: string;
  ACallback: TTMSFNCDirectionsGetDirectionsCallback; AID: string;
  ADataPointer: Pointer; AAlternatives: Boolean;
  ATravelMode: TTMSFNCDirectionsTravelMode;
  AWayPoints: TStringList; AOptimizeWayPoints: Boolean;
  ALocale: string; AMode: TTMSFNCMapsLocaleMode; AAvoidTolls: Boolean);
begin
  if (Service in [dsGoogle]) or (IsGoogleRoutes) then
  begin
    GetDirectionsInt(AOrigin, ADestination, 0, 0, 0,
      0, AAlternatives, ATravelMode, AWayPoints, nil, AOptimizeWayPoints,
      ALocale, AMode, AID, ADataPointer, ACallback, nil, AAvoidTolls);
  end
  else
  begin
    raise Exception.Create('Using AOrigin and ADestination string parameters only supported for Google Directions');
  end;
end;

procedure TTMSFNCCustomDirections.GetDirectionsResult(AOrigin,
  ADestination: string; ACallback: TTMSFNCDirectionsGetDirectionsResultCallback;
  AID: string; ADataPointer: Pointer; AAlternatives: Boolean;
  ATravelMode: TTMSFNCDirectionsTravelMode;
  AWayPoints: TStringList; AOptimizeWayPoints: Boolean;
  ALocale: string; AMode: TTMSFNCMapsLocaleMode; AAvoidTolls: Boolean);
begin
  if (Service in [dsGoogle]) or (IsGoogleRoutes) then
  begin
    GetDirectionsInt(AOrigin, ADestination, 0, 0, 0,
      0, AAlternatives, ATravelMode, AWayPoints, nil, AOptimizeWayPoints,
      ALocale, AMode, AID, ADataPointer, nil, ACallback, AAvoidTolls);
  end
  else
  begin
    raise Exception.Create('Using AOrigin and ADestination string parameters only supported for Google Directions');
  end;
end;

procedure TTMSFNCCustomDirections.GetDirectionsInt(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude, ADestinationLatitude, ADestinationLongitude: Double;
  AAlternatives: Boolean = False; ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
    AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AMode: TTMSFNCMapsLocaleMode = mlmDefault; AID: string = '';
    ADataPointer: Pointer = nil; ACallback: TTMSFNCDirectionsGetDirectionsCallback = nil; ACallback2: TTMSFNCDirectionsGetDirectionsResultCallback = nil; AAvoidTolls: Boolean = False);
begin
  {$IFDEF WEBLIB}
  if Service = dsGoogle then
  begin
    raise Exception.Create('The Google Directions API does not support client-side requests from a browser.');
    Exit;
  end;
  {$ENDIF}

  if not DirectionsReady then
  begin
    raise Exception.Create('Please set API key!');
    Exit;
  end;

  Request.Clear;
  Request.ClearHeaders;
  FDirections.AddHeaders(Request.Headers);
  Request.Name := 'GET DIRECTIONS';
  Request.DataString := AID;
  Request.DataPointer := ADataPointer;

  if Assigned(ACallback) then
    Request.DataObject := TTMSFNCDirectionsCallBackWrapper.Create(ACallback)
  else if Assigned(ACallback2) then
    Request.DataObject := TTMSFNCDirectionsResultCallBackWrapper.Create(ACallback2);

  Request.Method := FDirections.GetRequestMethod;
  Request.Host := FDirections.GetHost;
  Request.Path := FDirections.GetPath(TTMSFNCUtils.URLEncode(AOrigin), TTMSFNCUtils.URLEncode(ADestination), AOriginLatitude,
    AOriginLongitude, ADestinationLatitude, ADestinationLongitude, ATravelMode, AWayPoints);
  Request.Query := FDirections.GetQuery(TTMSFNCUtils.URLEncode(AOrigin), TTMSFNCUtils.URLEncode(ADestination), AOriginLatitude,
    AOriginLongitude, ADestinationLatitude, ADestinationLongitude, AAlternatives,
    ATravelMode, AWayPointList, AWayPoints, AOptimizeWayPoints, ParseLocale(ALocale, AMode), AAVoidTolls);
  Request.PostData := FDirections.GetPostData(AOrigin, ADestination, AOriginLatitude, AOriginLongitude, ADestinationLatitude, ADestinationLongitude,
    AAlternatives, ATravelMode, AWayPointList, AWayPoints, AOptimizeWayPoints, ParseLocale(ALocale, AMode), AAVoidTolls);
  ExecuteRequest({$IFDEF LCLWEBLIB}@{$ENDIF}DoRequestGetDirections);
end;

procedure TTMSFNCCustomDirections.SaveToGPXFile(
  ACoordinates: TTMSFNCMapsCoordinateRecArray; AFileName: string; AInstructions: TTMSFNCDirectionsSteps = nil);
begin
  SaveToGPXFile(ACoordinates, AFileName, DefaultGPXMetaData, AInstructions);
end;

procedure TTMSFNCCustomDirections.SaveToGPXFile(
  ACoordinates: TTMSFNCMapsCoordinateRecArray; AFileName: string;
  AMetaData: TTMSFNCMapsGPXMetaData; AInstructions: TTMSFNCDirectionsSteps = nil);
var
  sl: TStringList;
begin
  sl := TStringList.Create;
  try
    sl.Text := SaveToGPXText(ACoordinates, AMetaData, AInstructions);
    sl.SaveToFile(AFileName);
  finally
    sl.Free;
  end;
end;

function TTMSFNCCustomDirections.SaveToGPXInt(
  ACoordinates: TTMSFNCMapsCoordinateRecArray;
  AMetaData: TTMSFNCMapsGPXMetaData; AInstructions: TTMSFNCDirectionsSteps): string;
var
  Instructions: TStringList;
  Steps: TTMSFNCMapsCoordinateRecArrayArray;
  I, J: Integer;
begin
  if Assigned(AInstructions) then
  begin
    SetLength(Steps, AInstructions.Count);
    Instructions := TStringList.Create;
    for I := 0 to AInstructions.Count - 1 do
    begin
      Instructions.Add(AInstructions[I].Instructions);
      Setlength(Steps[I], AInstructions[I].Coordinates.Count);
      for J := 0 to AInstructions[I].Coordinates.Count - 1 do
      begin
        Steps[I][J].Latitude := AInstructions[I].Coordinates[J].Latitude;
        Steps[I][J].Longitude := AInstructions[I].Coordinates[J].Longitude;
      end;
    end;
    Result := SaveToGPX(Steps, AMetaData, True, True, Instructions);
    Instructions.Free;
  end
  else
  begin
    Result := SaveToGPX(ACoordinates, AMetaData);
  end;
end;

procedure TTMSFNCCustomDirections.SaveToGPXStream(
  ACoordinates: TTMSFNCMapsCoordinateRecArray; AStream: TStream; AInstructions: TTMSFNCDirectionsSteps = nil);
begin
  SaveToGPXStream(ACoordinates, AStream, DefaultGPXMetaData, AInstructions);
end;

procedure TTMSFNCCustomDirections.SaveToGPXStream(
  ACoordinates: TTMSFNCMapsCoordinateRecArray; AStream: TStream;
  AMetaData: TTMSFNCMapsGPXMetaData; AInstructions: TTMSFNCDirectionsSteps = nil);
var
  ss: TStringStream;
begin
  ss := TStringStream.Create(SaveToGPXText(ACoordinates, AMetaData, AInstructions));
  try
    AStream.CopyFrom(ss, ss.Size);
  finally
    ss.Free;
  end;
end;

function TTMSFNCCustomDirections.SaveToGPXText(
  ACoordinates: TTMSFNCMapsCoordinateRecArray; AInstructions: TTMSFNCDirectionsSteps = nil): string;
begin
  Result := SaveToGPXInt(ACoordinates, DefaultGPXMetaData, AInstructions);
end;

function TTMSFNCCustomDirections.SaveToGPXText(
  ACoordinates: TTMSFNCMapsCoordinateRecArray;
  AMetaData: TTMSFNCMapsGPXMetaData; AInstructions: TTMSFNCDirectionsSteps = nil): string;
begin
  Result := SaveToGPXInt(ACoordinates, AMetaData, AInstructions);
end;

function TTMSFNCCustomDirections.GetDirectionsRequests: TTMSFNCDirectionsRequests;
begin
  Result := DirectionsRequests;
end;

constructor TTMSFNCCustomDirections.Create(AOwner: TComponent);
begin
  inherited;
  Supports(Self, ITMSFNCCustomDirectionsProperties, FDirectionsProperties);
  FDirectionsRequests := TTMSFNCDirectionsRequests.Create(Self);
  InitializeDirections;
end;

procedure TTMSFNCCustomDirections.InitializeDirections;
var
  DirectionsServiceGoogleDirections: ITMSFNCDirectionsServiceGoogle;
  DirectionsServiceGoogleRoutesDirections: ITMSFNCDirectionsServiceGoogleRoutes;
  DirectionsServiceHereDirections: ITMSFNCDirectionsServiceHere;
  DirectionsServiceBingDirections: ITMSFNCDirectionsServiceBing;
  DirectionsServiceAzureDirections: ITMSFNCDirectionsServiceAzure;
  DirectionsServiceMapBoxDirections: ITMSFNCDirectionsServiceMapBox;
  DirectionsServiceTomTomDirections: ITMSFNCDirectionsServiceTomTom;
  DirectionsServiceOpenRouteServiceDirections: ITMSFNCDirectionsServiceOpenRouteService;
  DirectionsServiceGeoApifyDirections: ITMSFNCDirectionsServiceGeoApify;
begin
  if IsDestroying then
    Exit;

  if Assigned(FDirections) then
    FDirections.DestroyDirections;

  FDirections := nil;
  FDirectionsInstance := nil;

  if IsGoogleRoutes then
  begin
    if TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceGoogleRoutes, IInterface(DirectionsServiceGoogleRoutesDirections)) then
    begin
      FDirections := DirectionsServiceGoogleRoutesDirections.CreateDirections;
    end
  end
  else
  begin
    case Service of
      dsGoogle:
      begin
        if TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceGoogle, IInterface(DirectionsServiceGoogleDirections)) then
          FDirections := DirectionsServiceGoogleDirections.CreateDirections;
      end;
      dsHere:
      begin
        if TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceHere, IInterface(DirectionsServiceHereDirections)) then
          FDirections := DirectionsServiceHereDirections.CreateDirections;
      end;
      dsBing:
      begin
        if TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceBing, IInterface(DirectionsServiceBingDirections)) then
          FDirections := DirectionsServiceBingDirections.CreateDirections;
      end;
      dsAzure:
      begin
        if TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceAzure, IInterface(DirectionsServiceAzureDirections)) then
          FDirections := DirectionsServiceAzureDirections.CreateDirections;
      end;
      dsMapBox:
      begin
        if TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceMapBox, IInterface(DirectionsServiceMapBoxDirections)) then
          FDirections := DirectionsServiceMapBoxDirections.CreateDirections;
      end;
      dsTomTom:
      begin
        if TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceTomTom, IInterface(DirectionsServiceTomTomDirections)) then
          FDirections := DirectionsServiceTomTomDirections.CreateDirections;
      end;
      dsOpenRouteService:
      begin
        if TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceOpenRouteService, IInterface(DirectionsServiceOpenRouteServiceDirections)) then
          FDirections := DirectionsServiceOpenRouteServiceDirections.CreateDirections;
      end;
      dsGeoApify:
      begin
        if TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceGeoApify, IInterface(DirectionsServiceGeoApifyDirections)) then
          FDirections := DirectionsServiceGeoApifyDirections.CreateDirections;
      end;
    end;
  end;

  if Assigned(FDirections) and Supports(FDirections, ITMSFNCCustomDirectionsInstance, FDirectionsInstance) then
    FDirectionsInstance.SetDirectionsProperties(FDirectionsProperties);
end;

function TTMSFNCCustomDirections.DirectionsReady: Boolean;
begin
  Result := Assigned(FDirections) and Assigned(FDirectionsInstance) and Assigned(FDirectionsProperties) and FDirections.IsValid;
end;

procedure TTMSFNCCustomDirections.DoGetDirections(const ARequest: TTMSFNCDirectionsRequest;
  const ARequestResult: TTMSFNCCloudBaseRequestResult);
begin
  if Assigned(OnGetDirectionsInternal) then
    OnGetDirectionsInternal(Self, ARequest, ARequestResult);
  if Assigned(OnGetDirections) then
    OnGetDirections(Self, ARequest, ARequestResult);
  if Assigned(OnGetDirectionsResultInternal) then
    OnGetDirectionsResultInternal(Self, ARequest);
  if Assigned(OnGetDirectionsResult) then
    OnGetDirectionsResult(Self, ARequest);
end;

procedure TTMSFNCCustomDirections.DoRequestGetDirections(
  const ARequestResult: TTMSFNCCloudBaseRequestResult);
var
  req: TTMSFNCDirectionsRequest;
  obj: TTMSFNCDirectionsCallBackWrapper;
  obj2: TTMSFNCDirectionsResultCallBackWrapper;
begin
  if not DirectionsReady then
    Exit;

  //if ARequestResult.Success then
  //if there is an error, trigger the event and parse the error message instead
  begin
    req := DirectionsRequests.Add;
    req.FID := ARequestResult.DataString;
    req.DataPointer := ARequestResult.DataPointer;
    FDirections.Parse(req, ARequestResult.ResultString);
    DoGetDirections(req, ARequestResult);
    if Assigned(ARequestResult.DataObject) then
    begin
      if ARequestResult.DataObject is TTMSFNCDirectionsCallBackWrapper then
      begin
        obj := TTMSFNCDirectionsCallBackWrapper(ARequestResult.DataObject);
        obj.FCallback(req, ARequestResult);
        obj.Free;
      end
      else if ARequestResult.DataObject is TTMSFNCDirectionsResultCallBackWrapper then
      begin
        obj2 := TTMSFNCDirectionsResultCallBackWrapper(ARequestResult.DataObject);
        obj2.FCallback(req);
        obj2.Free;
      end;
    end;
  end;
end;

function TTMSFNCCustomDirections.Directions: ITMSFNCCustomDirections;
begin
  Result := FDirections;
end;

procedure TTMSFNCCustomDirections.SetAPIKey(const Value: string);
begin
  if FAPIKey <> Value then
  begin
    FAPIKey := Value;
  end;
end;

procedure TTMSFNCCustomDirections.SetDirectionsRequests(
  const Value: TTMSFNCDirectionsRequests);
begin
  FDirectionsRequests.Assign(Value);
end;

procedure TTMSFNCCustomDirections.SetIsGoogleRoutes(const Value: Boolean);
begin
  if FIsGoogleRoutes <> Value then
  begin
    FIsGoogleRoutes := Value;
  end;
end;

procedure TTMSFNCCustomDirections.SetService(const Value: TTMSFNCDirectionsService);
begin
  if FService <> Value then
  begin
    FService := Value;
    InitializeDirections;
  end;
end;

procedure DecodeValues(AValues: string;
  ACoordinates: TTMSFNCMapsCoordinates);
var
  lat, lon, lat_f, lon_f: Double;
  index: Integer;
  len: Integer;
  b: Integer;
  shift: Integer;
  decodeResult: Integer;
  encoded: String;
  dlat: Integer;
  dlng: Integer;
  co: TTMSFNCMapsCoordinateItem;
begin
  encoded := AValues;
  {$IFDEF ZEROSTRINGINDEX}
  len := Length(encoded) - 1;
  index := 0;
  {$ELSE}
  len := Length(encoded);
  index := 1;
  {$ENDIF}
  lat := 0;
  lon := 0;

  while (index <= len) do begin
    b := $20;
    shift := 0;
    decodeResult := 0;

    while (b >= $20) do begin
      b := ord(encoded[index]);
      b := b - 63;
      inc(index);
      decodeResult := decodeResult or (b and $1f) Shl shift;
      shift := shift + 5;
    end;

    if (decodeResult and 1) <> 0 then
      dlat := not (decodeResult shr 1)
    else
      dlat := decodeResult shr 1;
    lat := lat + dlat;

    shift := 0;
    decodeResult := 0;
    b := $20;
    while (b >= $20) do begin
      b := ord(encoded[index]);
      b := b-63;
      inc(index);
      decodeResult := decodeResult or (b and $1f) Shl shift;
      shift := shift + 5;
    end;

    if (decodeResult and 1) <> 0 then
      dlng := not (decodeResult shr 1)
    else
      dlng := decodeResult shr 1;
    lon := lon + dlng;

    lon_f := lon / 100000.0;
    lat_f := lat / 100000.0;

    //set coordinates
    co := ACoordinates.Add;
    co.Latitude := lat_f;
    co.Longitude := lon_f;
  end;
end;

destructor TTMSFNCCustomDirections.Destroy;
begin
  FDirectionsRequests.Free;

  if Assigned(FDirections) then
    FDirections.DestroyDirections;

  FDirections := nil;
  FDirectionsInstance := nil;
  FDirectionsProperties := nil;
  inherited;
end;

{ TTMSFNCDirectionsFactoryService }

constructor TTMSFNCDirectionsFactoryService.Create;
begin
  inherited Create;
  FDirections := TTMSFNCDirectionsList.Create;
end;

function TTMSFNCDirectionsFactoryService.CreateDirections: ITMSFNCCustomDirections;
begin
  Result := DoCreateDirections;
  FDirections.Add(Result);
end;

destructor TTMSFNCDirectionsFactoryService.Destroy;
begin
  FreeAndNil(FDirections);
  inherited Destroy;
end;

procedure TTMSFNCDirectionsFactoryService.DestroyDirections(ADirections: ITMSFNCCustomDirections);
begin
  FDirections.Remove(ADirections);
end;

{ TTMSFNCDirectionsPlatformServices }

procedure TTMSFNCDirectionsPlatformServices.AddPlatformService(const AServiceGUID: TGUID; const AService: IInterface);
var
  LService: IInterface;
begin
  if not FServicesList.ContainsKey(GUIDToString(AServiceGUID)) then
  begin
    if Supports(AService, AServiceGUID, LService) then
      FServicesList.Add(TTMSFNCDirectionsPlatformServicesService.Create(GUIDToString(AServiceGUID), AService));
  end;
end;

constructor TTMSFNCDirectionsPlatformServices.Create;
begin
  inherited;
  FServicesList := TTMSFNCDirectionsPlatformServicesList.Create;
end;

destructor TTMSFNCDirectionsPlatformServices.Destroy;
begin
  FreeAndNil(FServicesList);
  inherited;
end;

{$IFNDEF AUTOREFCOUNT}
class procedure TTMSFNCDirectionsPlatformServices.ReleaseCurrent;
begin
  FreeAndNil(FCurrent);
  FCurrentReleased := True;
end;
{$ENDIF}

class function TTMSFNCDirectionsPlatformServices.Current: TTMSFNCDirectionsPlatformServices;
begin
  if (FCurrent = nil) and not FCurrentReleased then
    FCurrent := TTMSFNCDirectionsPlatformServices.Create;
  Result := FCurrent;
end;

function TTMSFNCDirectionsPlatformServices.GetPlatformService(const AServiceGUID: TGUID): IInterface;
var
  k: IInterface;
begin
  k := FServicesList.Interfaces[GUIDToString(AServiceGUID)];
  Supports(k, AServiceGUID, Result);
end;

procedure TTMSFNCDirectionsPlatformServices.RemovePlatformService(const AServiceGUID: TGUID);
begin
  FServicesList.RemoveByGUID(GUIDToString(AServiceGUID));
end;

function TTMSFNCDirectionsPlatformServices.SupportsPlatformService(const AServiceGUID: TGUID;
  var AService: IInterface): Boolean;
begin
  if FServicesList.ContainsKey(GUIDToString(AServiceGUID)) then
    Result := Supports(FServicesList.Interfaces[GUIDToString(AServiceGUID)], AServiceGUID, AService)
  else
  begin
    AService := nil;
    Result := False;
  end;
end;

function TTMSFNCDirectionsPlatformServices.SupportsPlatformService(const AServiceGUID: TGUID): Boolean;
begin
  Result := FServicesList.ContainsKey(GUIDToString(AServiceGUID));
end;

{ TTMSFNCDirectionsPlatformServicesService }

constructor TTMSFNCDirectionsPlatformServicesService.Create(AGUID: string;
  AInterface: IInterface);
begin
  FGUID := AGUID;
  FInterface := AInterface;
end;

{ TTMSFNCDirectionsPlatformServicesList }

function TTMSFNCDirectionsPlatformServicesList.ContainsKey(AGUID: string): Boolean;
var
  I: Integer;
begin
  Result := False;
  for I := 0 to Count - 1 do
  begin
    if Items[I].GUID = AGUID then
    begin
      Result := True;
      Break;
    end;
  end;
end;

function TTMSFNCDirectionsPlatformServicesList.GetValue(AGUID: string): IInterface;
var
  I: Integer;
begin
  Result := nil;
  for I := 0 to Count - 1 do
  begin
    if Items[I].GUID = AGUID then
    begin
      Result := Items[I].&Interface;
      Break;
    end;
  end;
end;

procedure TTMSFNCDirectionsPlatformServicesList.RemoveByGUID(AGUID: string);
var
  I: Integer;
begin
  for I := Count - 1 downto 0 do
  begin
    if Items[I].GUID = AGUID then
    begin
      Remove(Items[I]);
      Break;
    end;
  end;
end;

{$IFDEF WEBLIB}
function TTMSFNCDirectionsPlatformServicesList.GetItem(Index: Integer): TTMSFNCDirectionsPlatformServicesService;
begin
  Result := TTMSFNCDirectionsPlatformServicesService(inherited Items[Index]);
end;

procedure TTMSFNCDirectionsPlatformServicesList.SetItem(Index: Integer; const Value: TTMSFNCDirectionsPlatformServicesService);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCDirectionsList.GetItem(Index: Integer): ITMSFNCCustomDirections;
begin
  Result := ITMSFNCCustomDirections(inherited Items[Index]);
end;

procedure TTMSFNCDirectionsList.SetItem(Index: Integer; const Value: ITMSFNCCustomDirections);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

{ TTMSFNCCustomDirectionsInterfacedObject }

destructor TTMSFNCCustomDirectionsInterfacedObject.Destroy;
begin
  FDirectionsProperties := nil;
  inherited;
end;

function TTMSFNCCustomDirectionsInterfacedObject.GetDirectionsProperties: ITMSFNCCustomDirectionsProperties;
begin
  Result := FDirectionsProperties;
end;

procedure TTMSFNCCustomDirectionsInterfacedObject.SetDirectionsProperties(
  const Value: ITMSFNCCustomDirectionsProperties);
begin
  FDirectionsProperties := Value;
end;

{ TTMSFNCDirectionsItem }

procedure TTMSFNCDirectionsItem.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCDirectionsItem then
  begin
    FCoordinates.Assign(TTMSFNCDirectionsItem(Source).Coordinates);
    FDistance := TTMSFNCDirectionsItem(Source).Distance;
    FDuration := TTMSFNCDirectionsItem(Source).Duration;
    FSummary := TTMSFNCDirectionsItem(Source).Summary;
    FSteps.Assign(TTMSFNCDirectionsItem(Source).Steps);
    FLegs.Assign(TTMSFNCDirectionsItem(Source).Legs);
  end;
end;

constructor TTMSFNCDirectionsItem.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) and (Collection is TTMSFNCDirectionsItems) then
    FOwner := (Collection as TTMSFNCDirectionsItems).FOwner;

  FCoordinates := TTMSFNCMapsCoordinates.Create(Self);
  FDistance := 0;
  FDuration := 0;
  FSummary := '';
  FSteps := TTMSFNCDirectionsSteps.Create(Self);
  FLegs := TTMSFNCDirectionsLegs.Create(Self);
  FWayPoints := TTMSFNCDirectionsWayPoints.Create(Self);
end;

destructor TTMSFNCDirectionsItem.Destroy;
begin
  FCoordinates.Free;
  FSteps.Free;
  FLegs.Free;
  FWayPoints.Free;
  inherited;
end;

function TTMSFNCDirectionsItem.GetBounds: TTMSFNCMapsBounds;
begin
  Result := Coordinates.Bounds;
end;

procedure TTMSFNCDirectionsItem.SetCoordinates(
  const Value: TTMSFNCMapsCoordinates);
begin
  FCoordinates.Assign(Value);
end;

procedure TTMSFNCDirectionsItem.SetLegs(const Value: TTMSFNCDirectionsLegs);
begin
  FLegs.Assign(Value);
end;

procedure TTMSFNCDirectionsItem.SetSteps(const Value: TTMSFNCDirectionsSteps);
begin
  FSteps.Assign(Value);
end;

procedure TTMSFNCDirectionsItem.SetWayPoints(
  const Value: TTMSFNCDirectionsWayPoints);
begin
  FWayPoints.Assign(Value);
end;

{ TTMSFNCDirectionsItems }

function TTMSFNCDirectionsItems.Add: TTMSFNCDirectionsItem;
begin
  Result := TTMSFNCDirectionsItem(inherited Add);
end;

constructor TTMSFNCDirectionsItems.Create(AOwner: TTMSFNCCustomDirections);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCDirectionsItems.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCDirectionsItem;
end;

function TTMSFNCDirectionsItems.GetItem(
  Index: Integer): TTMSFNCDirectionsItem;
begin
  Result := TTMSFNCDirectionsItem(inherited Items[Index]);
end;

function TTMSFNCDirectionsItems.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCDirectionsItems.Insert(
  Index: Integer): TTMSFNCDirectionsItem;
begin
  Result := TTMSFNCDirectionsItem(inherited Insert(Index));
end;

procedure TTMSFNCDirectionsItems.SetItem(Index: Integer;
  const Value: TTMSFNCDirectionsItem);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCDirectionsStep }

procedure TTMSFNCDirectionsStep.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCDirectionsStep then
  begin
    FCoordinates.Assign(TTMSFNCDirectionsStep(Source).Coordinates);
    FStartLocation.Assign(TTMSFNCDirectionsStep(Source).StartLocation);
    FEndLocation.Assign(TTMSFNCDirectionsStep(Source).EndLocation);
    FDistance := TTMSFNCDirectionsStep(Source).Distance;
    FDuration := TTMSFNCDirectionsStep(Source).Duration;
    FInstructions := TTMSFNCDirectionsStep(Source).Instructions;
  end;
end;

constructor TTMSFNCDirectionsStep.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCDirectionsSteps).FOwner;

  FCoordinates := TTMSFNCMapsCoordinates.Create(Self);
  FStartLocation := TTMSFNCMapsCoordinate.Create;
  FEndLocation := TTMSFNCMapsCoordinate.Create;
  FDistance := 0;
  FDuration := 0;
  FInstructions := '';
end;

destructor TTMSFNCDirectionsStep.Destroy;
begin
  FCoordinates.Free;
  FStartLocation.Free;
  FEndLocation.Free;
  inherited;
end;

procedure TTMSFNCDirectionsStep.SetCoordinates(
  const Value: TTMSFNCMapsCoordinates);
begin
  FCoordinates.Assign(Value);
end;

{ TTMSFNCDirectionsLeg }

procedure TTMSFNCDirectionsLeg.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCDirectionsLeg then
  begin
    FCoordinates.Assign(TTMSFNCDirectionsLeg(Source).Coordinates);
    FStartLocation.Assign(TTMSFNCDirectionsLeg(Source).StartLocation);
    FEndLocation.Assign(TTMSFNCDirectionsLeg(Source).EndLocation);
    FDistance := TTMSFNCDirectionsLeg(Source).Distance;
    FDuration := TTMSFNCDirectionsLeg(Source).Duration;
    FSteps.Assign(TTMSFNCDirectionsLeg(Source).Steps);
  end;
end;

constructor TTMSFNCDirectionsLeg.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCDirectionsLegs).FOwner;

  FCoordinates := TTMSFNCMapsCoordinates.Create(Self);
  FStartLocation := TTMSFNCMapsCoordinate.Create;
  FEndLocation := TTMSFNCMapsCoordinate.Create;
  FDistance := 0;
  FDuration := 0;
  FSteps := TTMSFNCDirectionsSteps.Create(Self);
end;

destructor TTMSFNCDirectionsLeg.Destroy;
begin
  FCoordinates.Free;
  FStartLocation.Free;
  FEndLocation.Free;
  FSteps.Free;
  inherited;
end;

procedure TTMSFNCDirectionsLeg.SetCoordinates(
  const Value: TTMSFNCMapsCoordinates);
begin
  FCoordinates.Assign(Value);
end;

procedure TTMSFNCDirectionsLeg.SetSteps(const Value: TTMSFNCDirectionsSteps);
begin
  FSteps := Value;
end;

{ TTMSFNCDirectionsSteps }

function TTMSFNCDirectionsSteps.Add: TTMSFNCDirectionsStep;
begin
  Result := TTMSFNCDirectionsStep(inherited Add);
end;

constructor TTMSFNCDirectionsSteps.Create(AOwner: TCollectionItem);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCDirectionsSteps.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCDirectionsStep;
end;

function TTMSFNCDirectionsSteps.GetItem(Index: Integer): TTMSFNCDirectionsStep;
begin
  Result := TTMSFNCDirectionsStep(inherited Items[Index]);
end;

function TTMSFNCDirectionsSteps.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCDirectionsSteps.Insert(Index: Integer): TTMSFNCDirectionsStep;
begin
  Result := TTMSFNCDirectionsStep(inherited Insert(Index));
end;

procedure TTMSFNCDirectionsSteps.SetItem(Index: Integer;
  const Value: TTMSFNCDirectionsStep);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCDirectionsLegs }

function TTMSFNCDirectionsLegs.Add: TTMSFNCDirectionsLeg;
begin
  Result := TTMSFNCDirectionsLeg(inherited Add);
end;

constructor TTMSFNCDirectionsLegs.Create(AOwner: TCollectionItem);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCDirectionsLegs.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCDirectionsLeg;
end;

function TTMSFNCDirectionsLegs.GetItem(Index: Integer): TTMSFNCDirectionsLeg;
begin
  Result := TTMSFNCDirectionsLeg(inherited Items[Index]);
end;

function TTMSFNCDirectionsLegs.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCDirectionsLegs.Insert(Index: Integer): TTMSFNCDirectionsLeg;
begin
  Result := TTMSFNCDirectionsLeg(inherited Insert(Index));
end;

procedure TTMSFNCDirectionsLegs.SetItem(Index: Integer;
  const Value: TTMSFNCDirectionsLeg);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCDirectionsWayPoint }

procedure TTMSFNCDirectionsWayPoint.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCDirectionsWayPoint then
  begin
    FDistance := TTMSFNCDirectionsWayPoint(Source).Distance;
    FDuration := TTMSFNCDirectionsWayPoint(Source).Duration;
    FOptimizedIndex := TTMSFNCDirectionsWayPoint(Source).OptimizedIndex;
  end;
end;

constructor TTMSFNCDirectionsWayPoint.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCDirectionsWayPoints).FOwner;

  FDistance := 0;
  FDuration := 0;
  FOptimizedIndex := -1;
end;

destructor TTMSFNCDirectionsWayPoint.Destroy;
begin
  inherited;
end;

{ TTMSFNCDirectionsWayPoints }

function TTMSFNCDirectionsWayPoints.Add: TTMSFNCDirectionsWayPoint;
begin
  Result := TTMSFNCDirectionsWayPoint(inherited Add);
end;

constructor TTMSFNCDirectionsWayPoints.Create(AOwner: TCollectionItem);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCDirectionsWayPoints.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCDirectionsWayPoint;
end;

function TTMSFNCDirectionsWayPoints.GetItem(
  Index: Integer): TTMSFNCDirectionsWayPoint;
begin
  Result := TTMSFNCDirectionsWayPoint(inherited Items[Index]);
end;

function TTMSFNCDirectionsWayPoints.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCDirectionsWayPoints.Insert(
  Index: Integer): TTMSFNCDirectionsWayPoint;
begin
  Result := TTMSFNCDirectionsWayPoint(inherited Insert(Index));
end;

procedure TTMSFNCDirectionsWayPoints.SetItem(Index: Integer;
  const Value: TTMSFNCDirectionsWayPoint);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCDirectionsRequest }

procedure TTMSFNCDirectionsRequest.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCDirectionsRequest then
  begin
    FItems.Assign((Source as TTMSFNCDirectionsRequest).Items);
    FTravelMode := (Source as TTMSFNCDirectionsRequest).TravelMode;
  end;
end;

constructor TTMSFNCDirectionsRequest.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCDirectionsRequests).FOwner;

  FItems := TTMSFNCDirectionsItems.Create(FOwner);
  FTravelMode := tmDriving;
  FID := '';
  FStatus := '';
  FErrorMessage := '';
end;

destructor TTMSFNCDirectionsRequest.Destroy;
begin
  FItems.Free;
  inherited;
end;

procedure TTMSFNCDirectionsRequest.SetErrorMessage(const Value: string);
begin
  FErrorMessage := Value;
end;

procedure TTMSFNCDirectionsRequest.SetItems(const Value: TTMSFNCDirectionsItems);
begin
  FItems.Assign(Value);
end;

procedure TTMSFNCDirectionsRequest.SetStatus(const Value: string);
begin
  FStatus := Value;
end;

procedure TTMSFNCDirectionsRequest.SetTravelMode(
  const Value: TTMSFNCDirectionsTravelMode);
begin
  FTraveLmode := Value;
end;

{ TTMSFNCDirectionsRequests }

function TTMSFNCDirectionsRequests.Add: TTMSFNCDirectionsRequest;
begin
  Result := TTMSFNCDirectionsRequest(inherited Add);
end;

constructor TTMSFNCDirectionsRequests.Create(AOwner: TTMSFNCCustomDirections);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCDirectionsRequests.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCDirectionsRequest;
end;

function TTMSFNCDirectionsRequests.GetItem(
  Index: Integer): TTMSFNCDirectionsRequest;
begin
  Result := TTMSFNCDirectionsRequest(inherited Items[Index]);
end;

function TTMSFNCDirectionsRequests.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCDirectionsRequests.Insert(
  Index: Integer): TTMSFNCDirectionsRequest;
begin
  Result := TTMSFNCDirectionsRequest(inherited Insert(Index));
end;

procedure TTMSFNCDirectionsRequests.SetItem(Index: Integer;
  const Value: TTMSFNCDirectionsRequest);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCDirectionsCallBackWrapper }

constructor TTMSFNCDirectionsCallBackWrapper.Create(
  ACallback: TTMSFNCDirectionsGetDirectionsCallback);
begin
  FCallback := ACallback;
end;

{ TTMSFNCDirections }

procedure TTMSFNCDirections.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCDirections]);
end;

{ TTMSFNCDirectionsResultCallBackWrapper }

constructor TTMSFNCDirectionsResultCallBackWrapper.Create(
  ACallback: TTMSFNCDirectionsGetDirectionsResultCallback);
begin
  FCallback := ACallback;
end;

initialization
begin
  TTMSFNCDirectionsPlatformServices.FCurrentReleased := False;
  RegisterGoogleDirectionsService;
  RegisterGoogleRoutesDirectionsService;
  RegisterHereDirectionsService;
  RegisterBingDirectionsService;
  RegisterAzureDirectionsService;
  RegisterMapBoxDirectionsService;
  RegisterTomTomDirectionsService;
  RegisterOpenRouteServiceDirectionsService;
  RegisterGeoApifyDirectionsService;
end;

{$IFNDEF WEBLIB}
finalization
begin
  UnRegisterGoogleDirectionsService;
  UnRegisterGoogleRoutesDirectionsService;
  UnRegisterHereDirectionsService;
  UnRegisterBingDirectionsService;
  UnRegisterAzureDirectionsService;
  UnRegisterMapBoxDirectionsService;
  UnRegisterTomTomDirectionsService;
  UnRegisterOpenRouteServiceDirectionsService;
  {$IFNDEF AUTOREFCOUNT}
  TTMSFNCDirectionsPlatformServices.ReleaseCurrent;
  {$ENDIF}
end;
{$ENDIF}

end.
